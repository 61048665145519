import React, { useEffect, useState } from "react";
import "react-virtualized/styles.css";
import Loader from "react-loader-spinner";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { Checkbox } from "antd";
import { Format } from "src/helpers";
import { camelCase } from "lodash";
import { Table } from "../../../components";
import ZapierIntegrationModal from "./zapier-integration";
import VeracrossModal from "./veracross";
import SisenseIntegrationModal from "./sisense-integration";
import PowerSchoolIntegrationModal from "./power-school-integration";
import ManagebacIntegrationModal from "./managebac-integration";
import IsamsIntegrationModal from "./isams-integration";
import EngageIntegrationModal from "./engage-integration";
import CommonAppModal from "./common-app";

const sortableColumns = {
  active: "active",
  name: "name",
  provider: "provider",
  type: "type",
  used: "used",
  added: "added",
};
const IntegrationScreen = props => {
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [prevParams, setPrevParams] = useState({});
  const [state, setState] = useState({
    zapierIntegration: false,
    veracross: false,
    sisenseIntegration: false,
    powerSchoolIntegration: false,
    managebacIntegration: false,
    isamsIntegration: false,
    engageIntegration: false,
    commonApp: false,
  });

  useEffect(() => {
    load();
  }, []);

  const load = loadMore => {
    const params = {
      search_text: "",
      search_email: "",
      sort_params: [
        {
          sort_by: sortableColumns[sortColumn],
          sort_direction: sortDirection,
        },
      ],
    };

    if (loadMore === true || !_.isEqual(params, prevParams)) {
      if (!loadMore) setPrevParams(params);

      return new Promise((resolve, reject) => {
        props.getProducts(params, resolve, reject);
      });
    } else {
      return new Promise(function (resolve) {
        resolve();
      });
    }
  };

  const loadMore = () => {
    return load(true);
  };

  const onClickHeader = ({ dataKey }) => {
    setSortColumn(dataKey);
    setSortDirection(
      sortColumn === dataKey
        ? sortDirection === "desc"
          ? "asc"
          : "desc"
        : "asc",
    );
  };

  const rowClassName = ({ index }) => {
    if (index < 0) {
      return "";
    } else {
      return "blue-bg-onHover app-table-row";
    }
  };

  const { products, matched, total, isNextPageLoading } = props;
  const hasNextPage = matched > products.length;
  const isRowLoaded = ({ index }) =>
    !hasNextPage || index < products.length;

  const loadMoreRows = isNextPageLoading ? () => {} : loadMore;

  const openModal = name => {
    console.log(camelCase(name));
    const key = camelCase(name);
    setState(prevState => ({
      ...prevState,
      [key]: true,
    }));
  };

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <Table
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        totalRowCount={total}
        rows={products}
        currentRowCount={products.length}
        headerHeight={46}
        rowHeight={62}
        rowClassName={index => {
          return `${rowClassName(index)}`;
        }}
        sortableColumns={sortableColumns}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        noRowsRenderer={() => {
          return isNextPageLoading ? (
            <div className="flex flex-col justify-center items-center  h-full w-full">
              <Loader
                type="Oval"
                color="grey"
                height={50}
                width={50}
              />
            </div>
          ) : null;
        }}
        // onRowClick={({ rowData }) => {
        //   props.history.push({
        //     pathname: `/district/${rowData.id}`,
        //   });
        // }}
        columns={[
          {
            dataKey: "active",
            width: 70,
            type: "Default",
            disableSort: true,
            tooltip: true,
            cellRenderer: ({ rowData }) => {
              return (
                <div className="cursor-pointer">
                  <Checkbox
                    defaultChecked={rowData.is_active}
                    onChange={e => {
                      e.stopPropagation();
                    }}
                  />
                </div>
              );
            },
            header: {
              title: "Active",
              onClick: onClickHeader,
            },
          },
          {
            dataKey: "name",
            width: 300,
            type: "Default",
            disableSort: true,
            tooltip: true,
            cellRenderer: ({ rowData }) => {
              return (
                <div
                  className="cursor-pointer bold-onHover hover:text-blue-700"
                  onClick={() => {
                    openModal(rowData.name);
                  }}
                >
                  {rowData.name}
                </div>
              );
            },
            header: {
              title: "Name",
              onClick: onClickHeader,
            },
          },
          {
            dataKey: "provider",
            width: 200,
            type: "Default",
            disableSort: true,
            tooltip: true,
            valueSelector: rowData => ({
              value: rowData.provider,
            }),
            header: {
              title: "Provider",
              onClick: onClickHeader,
            },
          },

          {
            dataKey: "type",
            width: 200,
            type: "Default",
            disableSort: true,
            tooltip: true,
            valueSelector: rowData => ({
              value: rowData.type,
            }),
            header: {
              title: "Type",
              onClick: onClickHeader,
            },
          },
          {
            dataKey: "used",
            width: 200,
            type: "Default",
            disableSort: true,
            tooltip: true,
            valueSelector: rowData => ({
              value: rowData.id,
            }),
            header: {
              title: "Used",
              onClick: onClickHeader,
            },
          },
          {
            dataKey: "added",
            width: 100,
            type: "Default",
            disableSort: true,
            valueSelector: rowData => ({
              value: Format.date(rowData.created_at),
            }),
            header: {
              title: "Added",
              onClick: onClickHeader,
            },
          },
        ]}
      ></Table>
      {isNextPageLoading && products.length > 0 ? (
        <div
          className={
            "absolute bottom-0 self-center pointer-events-none flex-none flex flex-col justify-center items-center p-1"
          }
        >
          <Loader
            type="ThreeDots"
            color="grey"
            height={75}
            width={75}
          />
        </div>
      ) : null}

      {state.zapierIntegration && (
        <ZapierIntegrationModal
          isOpen={state.zapierIntegration}
          onClose={() =>
            setState(prevState => ({
              ...prevState,
              zapierIntegration: false,
            }))
          }
        />
      )}
      {state.veracross && (
        <VeracrossModal
          isOpen={state.veracross}
          onClose={() =>
            setState(prevState => ({
              ...prevState,
              veracross: false,
            }))
          }
        />
      )}
      {state.sisenseIntegration && (
        <SisenseIntegrationModal
          isOpen={state.sisenseIntegration}
          onClose={() =>
            setState(prevState => ({
              ...prevState,
              sisenseIntegration: false,
            }))
          }
        />
      )}
      {state.powerSchoolIntegration && (
        <PowerSchoolIntegrationModal
          isOpen={state.powerSchoolIntegration}
          onClose={() =>
            setState(prevState => ({
              ...prevState,
              powerSchoolIntegration: false,
            }))
          }
        />
      )}
      {state.managebacIntegration && (
        <ManagebacIntegrationModal
          isOpen={state.managebacIntegration}
          onClose={() =>
            setState(prevState => ({
              ...prevState,
              managebacIntegration: false,
            }))
          }
        />
      )}
      {state.isamsIntegration && (
        <IsamsIntegrationModal
          isOpen={state.isamsIntegration}
          onClose={() =>
            setState(prevState => ({
              ...prevState,
              isamsIntegration: false,
            }))
          }
        />
      )}
      {state.engageIntegration && (
        <EngageIntegrationModal
          isOpen={state.engageIntegration}
          onClose={() =>
            setState(prevState => ({
              ...prevState,
              engageIntegration: false,
            }))
          }
        />
      )}
      {state.commonApp && (
        <CommonAppModal
          isOpen={state.commonApp}
          onClose={() =>
            setState(prevState => ({
              ...prevState,
              commonApp: false,
            }))
          }
        />
      )}
    </div>
  );
};

export default withTranslation()(withRouter(IntegrationScreen));
