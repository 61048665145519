import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

const UnAuthenticated = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const query = new URLSearchParams(
        props.history.location.search,
      );
      return rest.user.authToken ? (
        query.get("redirectTo") ? (
          <Redirect to={query.get("redirectTo")} />
        ) : (
          <Redirect to={"partners/universities"} />
        )
      ) : (
        <Component {...props} />
      );
    }}
  />
);

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(UnAuthenticated);
