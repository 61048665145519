import React, { Component } from "react";
import { Field } from "redux-form";
import { ConfirmationModal } from "../../../../components";
import {
  TextAreaField,
  RichTextInputField,
} from "../../../../form-components";
import { ActionBar } from "../common";
import Switch from "react-switch";
import Testimonial from "./testimonial";
import TestimonialSelectModal from "./testimonial-select-modal";
import _ from "lodash";
import { Notification } from "../../../../helpers";

const FormField = ({
  label,
  labelColor,
  required,
  ...formFieldProps
}) => {
  return (
    <div className="flex flex-row">
      <span
        className={
          "text-sm w-40 mt-2 " + (labelColor || "text-gray-750")
        }
      >
        {label}
        {required ? (
          <span className={"text-red-800 ml-1"}>*</span>
        ) : null}
      </span>

      <div className="flex flex-1 ml-2">
        <Field {...formFieldProps} />
      </div>
    </div>
  );
};

const SwitchField = props => {
  const { input } = props;

  return (
    <Switch
      checked={input.value}
      onChange={value => input.onChange(value)}
      onColor={"#B5C2D0"}
      onHandleColor="#007BE2"
      handleDiameter={22}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
      height={15}
      width={36}
      className="react-switch"
      id="material-switch"
    />
  );
};

const ReasonField = ({
  title,
  textFieldName,
  programContentChanges,
  programTypeChange,
  content,
}) => {
  let isShowThisChanged = false;
  let isTextChanged = false;

  if (programContentChanges && !programTypeChange) {
    var foundContent = programContentChanges.filter(
      obj => obj && obj.title === content.title,
    );

    if (foundContent[0]) {
      if (!(foundContent[0].enabled === String(content.enabled))) {
        isShowThisChanged = true;
      }
      if (!(foundContent[0].text === String(content.text))) {
        isTextChanged = true;
      }
    }
  }

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex flex-row justify-between items-center mb-2 pr-2">
        <Field
          component={({ input }) => (
            <span className={"text-sm font-bold text-gray-900"}>
              Title: {input.value}
            </span>
          )}
          name={textFieldName + ".title"}
        />
        <Field
          component={() => null}
          name={textFieldName + ".icon"}
          className="hidden"
        />
        <div className="flex flex-row">
          <span
            className={
              "text-sm font-regular mr-2 " +
              (isShowThisChanged || programTypeChange
                ? "text-yellow-800"
                : "text-gray-900")
            }
          >
            Show this
          </span>
          <Field
            component={SwitchField}
            name={textFieldName + ".enabled"}
          />
        </div>
      </div>
      <Field
        component={TextAreaField}
        props={{
          classForBorder:
            (isTextChanged || programTypeChange) &&
            "border-yellow-800",
        }}
        maxLength={256}
        showRemainingLimit
        name={textFieldName + ".text"}
        placeholder={""}
      />
    </div>
  );
};

class Content extends Component {
  state = {
    isTestimonialSelectModalVisible: false,
    isSaveAsDraftModalVisible: false,
  };

  isSavedAndValid = () => {
    return (
      this.props.valid &&
      (this.props.dirty ? this.props.submitSucceeded : true)
    );
  };

  _showValidationErros = errors => {
    if (errors && Object.keys(errors).length > 0) {
      const errorMessages = _.map(errors, error => error);
      const errorMessagesComponent = _.map(errors, (error, index) => (
        <div className="h-5" key={index}>
          {error}
        </div>
      ));

      if (errors) {
        Notification.error(
          <div className="flex flex-col">
            {errorMessagesComponent}
          </div>,
          {
            customFields: {
              title: `${errorMessages.length} error${errorMessages.length > 1 ? "s" : ""} in this section`,
            },
          },
        );
      }
    }
  };

  _onSubmit = event => {
    const errors = this.props.handleSubmit(this._save)(event);
    this._showValidationErros(errors);
  };

  _onSubmitDraft = event => {
    const errors = this.props.handleSubmit(
      this._saveAsDraftShowModal,
    )(event);
    this._showValidationErros(errors);
  };

  _save = values => {
    this._submit(values);
  };

  _saveAsDraftShowModal = values => {
    return new Promise((resolve, reject) => {
      this._showSaveAsDraftModal();
      reject();
    });
  };

  _confirmSaveAsDraft = event => {
    const errors = this.props.handleSubmit(values => {
      this._submit(values, true);
    })(event);
    this._showValidationErros(errors);
    this._hideSaveAsDraftModal();
  };

  _submit = (values, saveAsDraft) => {
    const { description, content, testimonial } = values;
    const { id, student_testimonials } = this.props;
    const formData = new FormData();

    formData.append(
      "partner_program[description]",
      description || "",
    );

    Object.keys(content).forEach((contentId, index) => {
      formData.append(
        "partner_program[program_content][][title]",
        content[contentId].title,
      );
      formData.append(
        "partner_program[program_content][][text]",
        content[contentId].text || "",
      );
      formData.append(
        "partner_program[program_content][][icon]",
        content[contentId].icon,
      );
      formData.append(
        "partner_program[program_content][][enabled]",
        content[contentId].enabled,
      );
    });

    if (testimonial) {
      if (testimonial.id)
        formData.append(
          "partner_program[program_testimonials_attributes][][id]",
          testimonial["id"],
        );

      if (
        testimonial["name"] &&
        testimonial["country"] &&
        testimonial["text"]
      ) {
        formData.append(
          "partner_program[program_testimonials_attributes][][name]",
          testimonial["name"] || "",
        );
        formData.append(
          "partner_program[program_testimonials_attributes][][nationality]",
          testimonial["country"] || "",
        );
        formData.append(
          "partner_program[program_testimonials_attributes][][quote]",
          testimonial["text"] || "",
        );

        if (testimonial["image"]) {
          if (testimonial["image"] instanceof Object)
            formData.append(
              "partner_program[program_testimonials_attributes][][avatar]",
              testimonial["image"],
            );
        }
      } else
        formData.append(
          "partner_program[program_testimonials_attributes][][_destroy]",
          true,
        );
    } else if (student_testimonials.length > 0) {
      const testimonial = student_testimonials[0];
      formData.append(
        "partner_program[program_testimonials_attributes][][id]",
        testimonial["id"],
      );
      formData.append(
        "partner_program[program_testimonials_attributes][][_destroy]",
        true,
      );
    }

    if (saveAsDraft) {
      formData.append("partner_program[status]", "draft");
      this.props.saveProgramDraft(id, formData);
    } else this.props.updateProgram(id, formData);
  };

  _showSaveAsDraftModal = () => {
    this.setState({ isSaveAsDraftModalVisible: true });
  };

  _hideSaveAsDraftModal = () => {
    this.setState({ isSaveAsDraftModalVisible: false });
  };

  _showTestimonialSelectModal = () => {
    this.setState({ isTestimonialSelectModalVisible: true });
  };

  _hideTestimonialSelectModal = () => {
    this.setState({ isTestimonialSelectModalVisible: false });
  };

  render() {
    const {
      program_content,
      isUpdating,
      isSavingDraft,
      status,
      t,
      contentChanges,
      initialValues,
    } = this.props;
    const {
      isTestimonialSelectModalVisible,
      isSaveAsDraftModalVisible,
    } = this.state;

    return (
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex flex-1 flex-col overflow-scroll pr-4">
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <span className="text-gray-750 text-lg font-bold mb-6">
                Description & Relevant information
              </span>
              <span className="text-red-800 text-sm font-bold mb-6">
                Required *
              </span>
            </div>

            {/*English Test Req?*/}
            <FormField
              required
              readOnly
              disabled={false}
              label={"Program Description"}
              component={RichTextInputField}
              labelColor={
                contentChanges.description && "text-yellow-800"
              }
              name="description"
              placeHolder={`This program combines language, culture and academics to give students a deep understanding of life in and out of the classroom. As a student, you’ll get: A contextual curriculum that mixes language, culture and academics and a full year’s worth of college credits`}
            />

            <Field
              component={() => null}
              name="program_type_description"
            />

            <div className="h-5" />

            <div className="flex flex-row pointer-events-none">
              <span className={"text-sm w-40 mt-2px text-gray-750"}>
                {"Why you should apply to this program"}
                <span className={"text-red-800 ml-1"}>*</span>
              </span>

              <div className="flex-1 flex flex-col ml-2">
                {program_content.map((content, index) => (
                  <React.Fragment key={index}>
                    <ReasonField
                      textFieldName={`content.${index}`}
                      content={initialValues.content[index]}
                      programContentChanges={
                        contentChanges &&
                        contentChanges.program_content
                      }
                      programTypeChange={
                        contentChanges &&
                        contentChanges.program_type_id
                      }
                    />
                    <div className="h-5" />
                  </React.Fragment>
                ))}
              </div>
            </div>

            <Testimonial
              {...this.props}
              onClickOpenSelectTestimonial={
                this._showTestimonialSelectModal
              }
              onClickDeleteImage={() =>
                this.props.change("testimonial[image]", null)
              }
            />
            <TestimonialSelectModal
              visible={isTestimonialSelectModalVisible}
              onClose={this._hideTestimonialSelectModal}
              onSave={selectedTestimonial => {
                this.props.change(
                  "testimonial[id]",
                  selectedTestimonial.id,
                );
                this.props.change(
                  "testimonial[name]",
                  selectedTestimonial.name,
                );
                this.props.change(
                  "testimonial[country]",
                  selectedTestimonial.nationality,
                );
                this.props.change(
                  "testimonial[text]",
                  selectedTestimonial.quote,
                );
                this.props.change(
                  "testimonial[image]",
                  selectedTestimonial.avatar,
                );
                this._hideTestimonialSelectModal();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export function validate(values, props) {
  const errors = {};
  const { description, content, testimonial } = values;
  const { program_content } = props;

  if (!description)
    errors.description = "Program Description is required";

  errors["content"] = {};

  program_content.forEach((program, i) => {
    if (content && content[i]) {
      if (!content[i]["text"]) {
        errors["content"][i] = {
          text: `${program.title} is required`,
        };
      } else if (content[i]["text"].length > 256) {
        errors["content"][i] = {
          text: `Value should be less than 256 characters`,
        };
      }
    }
  });

  errors.testimonial = {};

  if (
    testimonial &&
    (testimonial.image ||
      testimonial.name ||
      testimonial.country ||
      testimonial.text)
  ) {
    if (!testimonial.name)
      errors["testimonial"]["name"] = `Name is required`;

    if (!testimonial.country)
      errors["testimonial"]["country"] = `Nationality is required`;

    if (!testimonial.text)
      errors["testimonial"]["text"] = `Quote is required`;
    else if (testimonial.text.length > 400)
      errors["testimonial"]["text"] =
        `Quote should be less than 400 characters`;
  }

  return errors;
}

export default Content;
