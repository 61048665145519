import React from "react";
import {
  Link,
  Switch,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";
import DirectApplicationsPage from "src/views/direct-apply/applications";
import DirectApplyTransactionsPage from "src/views/direct-apply/transactions";
import ProgramsPage from "src/views/direct-apply/programs";
import StudentsPage from "src/views/students";

const DirectApplyPage = props => {
  const { pathname, search } = props.location;

  const checkActiveRoute = route => {
    return route === pathname + search;
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex items-center px-6 py-4">
        <span className="text-xl font-bold text-black mr-6">
          Direct Apply
        </span>

        {checkActiveRoute(
          "/direct-apply/applications?tab=submitted",
        ) ||
        checkActiveRoute(
          "/direct-apply/applications?tab=unsubmitted",
        ) ? (
          <div className="flex flex-row  border rounded-20px mr-3 border-gray-300 bg-gray-100">
            <span className="px-4 py-2 text-sm font-bold text-gray-600">
              Applications
            </span>
            <Link
              to="/direct-apply/applications?tab=submitted"
              replace
              exact
            >
              {" "}
              <button
                className={`px-4 py-1 border rounded-20px mr-3 my-1 text-sm font-bold ${checkActiveRoute("/direct-apply/applications?tab=submitted") ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300 bg-white"}`}
              >
                Submitted
              </button>
            </Link>
            <Link
              to="/direct-apply/applications?tab=unsubmitted"
              replace
              exact
            >
              {" "}
              <button
                className={`px-4 py-1 border rounded-20px mr-3 my-1 text-sm font-bold ${checkActiveRoute("/direct-apply/applications?tab=unsubmitted") ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300 bg-white"}`}
              >
                Unsubmitted
              </button>
            </Link>
          </div>
        ) : (
          <Link
            to="/direct-apply/applications?tab=submitted"
            replace
            className="pr-4"
            exact
          >
            <button
              className={`px-4 py-2 text-sm border rounded-20px font-bold ${checkActiveRoute("/direct-apply/applications?tab=submitted") ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300"}`}
            >
              Applications
            </button>
          </Link>
        )}

        <Link
          to="/direct-apply/transactions"
          replace
          className="pr-4"
          exact
        >
          <button
            className={`px-4 py-2 text-sm border rounded-20px font-bold ${checkActiveRoute("/direct-apply/transactions") ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300"}`}
          >
            Transactions
          </button>
        </Link>

        <Link to="/direct-apply/programs" replace className="pr-4">
          <button
            className={`px-4 py-2 text-sm border rounded-20px font-bold ${checkActiveRoute("/direct-apply/programs") ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300"}`}
          >
            Programs
          </button>
        </Link>

        <Link to="/direct-apply/students" replace className="pr-4">
          <button
            className={`px-4 py-2 text-sm border rounded-20px font-bold ${checkActiveRoute("/direct-apply/students") ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300"}`}
          >
            Students
          </button>
        </Link>
      </div>

      <Switch>
        <Route
          path="/direct-apply/applications"
          render={() => <DirectApplicationsPage />}
          exact
        />
        <Route
          path="/direct-apply/transactions"
          render={() => <DirectApplyTransactionsPage />}
          exact
        />
        <Route
          path="/direct-apply/programs"
          render={() => <ProgramsPage />}
          exact
        />
        <Route
          path="/direct-apply/students"
          render={() => <StudentsPage />}
          exact
        />
        <Redirect
          from="/direct-apply"
          to="/direct-apply/applications?tab=submitted"
          render={() => <DirectApplicationsPage />}
          exact
        />
      </Switch>
    </div>
  );
};

export default withRouter(DirectApplyPage);
