import React from "react";
import { Column, Table } from "react-virtualized";
import "react-virtualized/styles.css";
import { TableHeader } from "./header";
import {
  BadgeColumn,
  BlueColumn,
  DefaultColumn,
  VerticalTwoValueColumn,
  VerticalTwoValueColumnBlack,
  VerticalTwoValueColumnBlue,
} from "./columns";

export const VirtualizedTable = ({
  children,
  currentRowCount,
  noRowsRenderer,
  rowClassName,
  rowHeight,
  rowGetter: rowGetterParam,
  onRowClick,
  rows,
  columns,
  sortableColumns,
  sortColumn,
  sortDirection,
  registerChild,
  onRowsRendered,
  height,
  width,
  onScroll,
  scrollTop,
  scrollLeft,
  style,
  gridStyle,
}) => {
  const rowGetter = rowGetterParam
    ? rowGetterParam
    : ({ index }) => rows[index] || {};

  return (
    <Table
      ref={registerChild}
      onRowsRendered={onRowsRendered}
      noRowsRenderer={noRowsRenderer}
      headerHeight={46}
      height={height}
      rowClassName={rowClassName}
      rowHeight={rowHeight}
      rowGetter={rowGetter}
      rowCount={currentRowCount}
      onRowClick={onRowClick}
      width={width}
      onScroll={onScroll}
      scrollTop={scrollTop}
      scrollLeft={scrollLeft}
      style={style}
      gridStyle={gridStyle}
    >
      {columns &&
        columns
          .filter(column => {
            return !column.exclude;
          })
          .map((column, index) => {
            const {
              cellRenderer,
              type,
              valueSelector,
              className,
              header,
              tooltip,
              ...columnProps
            } = column;

            return (
              <Column
                {...columnProps}
                headerRenderer={
                  header.component
                    ? header.component
                    : props => {
                        const { dataKey } = props;
                        const { title, onClick } = header;
                        const isSortable = sortableColumns[dataKey]
                          ? true
                          : false;

                        return (
                          <TableHeader
                            {...props}
                            isSortable={isSortable}
                            isSorted={sortColumn === dataKey}
                            sortDirection={sortDirection}
                            title={title}
                            onClick={() =>
                              onClick({
                                dataKey,
                                sortingKey: sortableColumns[dataKey],
                              })
                            }
                          />
                        );
                      }
                }
                cellRenderer={
                  cellRenderer
                    ? cellRenderer
                    : props => {
                        switch (type) {
                          case "VerticalTwoValue":
                            return (
                              <VerticalTwoValueColumn
                                valueSelector={valueSelector}
                                tooltip={tooltip}
                                className={className}
                                {...props}
                              />
                            );
                          case "VerticalTwoValueBlack":
                            return (
                              <VerticalTwoValueColumnBlack
                                valueSelector={valueSelector}
                                tooltip={tooltip}
                                className={className}
                                {...props}
                              />
                            );
                          case "VerticalTwoValueBlue":
                            return (
                              <VerticalTwoValueColumnBlue
                                valueSelector={valueSelector}
                                tooltip={tooltip}
                                className={className}
                                {...props}
                              />
                            );
                          case "Tag":
                            return (
                              <BadgeColumn
                                valueSelector={valueSelector}
                                tooltip={tooltip}
                                className={className}
                                {...props}
                              />
                            );
                          case "Blue":
                            return (
                              <BlueColumn
                                valueSelector={valueSelector}
                                tooltip={tooltip}
                                className={className}
                                {...props}
                              />
                            );
                          case "Default": // fallthrough
                          default:
                            return (
                              <DefaultColumn
                                valueSelector={valueSelector}
                                tooltip={tooltip}
                                className={className}
                                {...props}
                              />
                            );
                        }
                      }
                }
              />
            );
          })}
      {children}
    </Table>
  );
};
