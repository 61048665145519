import NewRequestsPage from "./new-requests";
import { connect } from "react-redux";
import {
  NewPartnersCreators,
  NewPartnersFiltersCreators,
} from "../../../redux/actions";

function mapStateToProps(state) {
  const {
    newPartners: {
      data,
      total,
      matched,
      loading,
      newPartnersFilterCountries,
      newPartnersFilterStatuses,
    },
    newPartnersSearchState,
  } = state;
  return {
    partners: data,
    total,
    matched,
    isNextPageLoading: loading,
    newPartnersFilterCountries,
    newPartnersFilterStatuses,
    newPartnersSearchState,
  };
}

const mapDispatchToProps = {
  getPartners: NewPartnersCreators.getNewPartnersRequest,
  clearNewPartners: NewPartnersCreators.clearNewPartners,
  setFilters: NewPartnersFiltersCreators.setNewPartnersFilters,
  setSearchText: NewPartnersFiltersCreators.setNewPartnersSearchText,
  getFilterCountries: NewPartnersCreators.getNewPartnersFilterCountriesRequest,
  getFilterStatuses: NewPartnersCreators.getNewPartnersFilterStatusesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewRequestsPage);
