import applications from "./applications.json";
import application from "./application.json";

const directApply = {
  direct_apply: {
    ...applications,
    ...application,
  },
};

export default directApply;
