import { createReducer } from "reduxsauce";
import { StudentsTypes as Types } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.students;

export const getStudentsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getStudentsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getStudentsSuccess = (state, action) => {
  const {
    studentsData: { students, matched, total },
    params,
  } = action;

  return {
    ...state,
    data: params.page_no === 1 ? students : [...state.data, ...students],
    matched,
    total,
    params: { ...state.params, ...params },
    loading: false,
  };
};

export const getStudentEmailFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getStudentEmailRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getStudentEmailSuccess = (state, action) => {
  const { studentData, id } = action;
  const { data } = state;
  const updatedData = data.map((item) => (item.id === id ? studentData : item));

  return {
    ...state,
    data: updatedData,
    loading: false,
  };
};

export const createUcasAccountFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const createUcasAccountRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const createUcasAccountSuccess = (state, action) => {
  const { studentData, id } = action;
  const { data } = state;
  const updatedData = data.map((item) => (item.id === id ? studentData : item));

  return {
    ...state,
    data: updatedData,
    loading: false,
  };
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.GET_STUDENTS_FAILURE]: getStudentsFailure,
  [Types.GET_STUDENTS_REQUEST]: getStudentsRequest,
  [Types.GET_STUDENTS_SUCCESS]: getStudentsSuccess,

  [Types.GET_STUDENT_EMAIL_FAILURE]: getStudentEmailFailure,
  [Types.GET_STUDENT_EMAIL_REQUEST]: getStudentEmailRequest,
  [Types.GET_STUDENT_EMAIL_SUCCESS]: getStudentEmailSuccess,

  [Types.CREATE_UCAS_ACCOUNT_FAILURE]: createUcasAccountFailure,
  [Types.CREATE_UCAS_ACCOUNT_REQUEST]: createUcasAccountRequest,
  [Types.CREATE_UCAS_ACCOUNT_SUCCESS]: createUcasAccountSuccess,
};

export default createReducer(INITIAL_STATE, HANDLERS);
