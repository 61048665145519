import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import Loader from "react-loader-spinner";
import { UcasApplicationFormCreators } from "../../../../../redux/actions";

const FillEducationSection = ({
  application,
  ucasQuickSubmit,
  fillEducationSection,
  isUCASAccountCreated,
  taggingQuickSubmit,
  isJobLoading,
}) => {
  const {
    education_form_job_status,
    onboarding_show_create_button,
    onboarding_complete,
    jobs_running,
    are_qualification_fillable,
    education_form_error_message,
    education_form_complete,
  } = ucasQuickSubmit;

  const isFormFailed = education_form_job_status === "Failed";

  const isEducationAutomationDisabled =
    !are_qualification_fillable || !application.in_cialfo_review;

  const handleSaveForm = () => {
    if (!isJobLoading) {
      return fillEducationSection(application?.id);
    }
  };

  const renderButton = () => {
    if (
      education_form_job_status === "Partially Completed" &&
      !!education_form_error_message &&
      !education_form_complete &&
      !jobs_running
    ) {
      return (
        <>
          <button
            disabled={true}
            data-tip
            data-for={"partially_complete"}
            className="button-contain-loading-primary-disable"
          >
            Partially Completed
          </button>
          <ReactTooltip
            id={"partially_complete"}
            aria-haspopup="true"
            place="bottom"
            effect={"solid"}
            arrowColor={"transparent"}
          >
            <p>Partially Completed</p>
          </ReactTooltip>
        </>
      );
    }

    if (
      isJobLoading ||
      education_form_job_status === "Running" ||
      education_form_job_status === "Pending"
    ) {
      return (
        <div data-tip data-for={"fill_education_section_progress"}>
          <button
            className={"button-contain-loading-primary-disable"}
            disabled
          >
            Fill Education
            <Loader
              className={"ml-2 text-gray-500"}
              type="Oval"
              height={10}
              width={10}
            />
          </button>
          <ReactTooltip
            id={"fill_education_section_progress"}
            aria-haspopup="true"
            place="bottom"
            effect={"solid"}
            arrowColor={"transparent"}
          >
            <p>Fill Education is in progress</p>
          </ReactTooltip>
        </div>
      );
    }

    if (
      (onboarding_show_create_button && !onboarding_complete) ||
      jobs_running ||
      taggingQuickSubmit?.jobs_running
    ) {
      return (
        <>
          <button
            data-tip
            data-for={`tooltip-Complete-Education`}
            disabled
            className={"btn-border-primary mb-6"}
          >
            Fill Education
            <FontAwesomeIcon
              icon={["far", "sparkles"]}
              className={"pl-2"}
            />
          </button>
          {!application.is_ucas_credential_updated &&
            isUCASAccountCreated && (
              <ReactTooltip
                id={"tooltip-Complete-Education"}
                aria-haspopup="true"
                place="bottom"
                effect={"solid"}
                arrowColor={"transparent"}
              >
                <p>
                  Student has indicated they want their own UCAS
                  account. Click the checkbox "I have entered the
                  students UCAS email and password against the
                  student"
                </p>
              </ReactTooltip>
            )}
        </>
      );
    }

    return (
      <div
        data-tip
        data-for={
          isEducationAutomationDisabled
            ? "fill_education_section_button"
            : ""
        }
        className="flex flex-col items-center"
      >
        <button
          onClick={handleSaveForm}
          className={`${
            isFormFailed ? "btn-error" : "btn-primary"
          } mb-2`}
          disabled={isEducationAutomationDisabled}
        >
          {isFormFailed ? "Try again" : "Fill Education"}
          {isFormFailed ? (
            <FontAwesomeIcon icon={"undo"} className={"pl-2"} />
          ) : (
            <FontAwesomeIcon
              icon={["far", "sparkles"]}
              className={"pl-2"}
            />
          )}
        </button>
        {isFormFailed && (
          <span className="text-base text-red-800 mt-2 block text-center mb-6">
            {education_form_error_message ||
              "An error occurred. Please try again. You may also fill this manually or reach out to support."}
          </span>
        )}

        {isEducationAutomationDisabled && (
          <ReactTooltip
            id={"fill_education_section_button"}
            aria-haspopup="true"
            place="bottom"
            effect={"solid"}
            arrowColor={"transparent"}
          >
            {!are_qualification_fillable
              ? "We currently do not have automations to fill all the qualifications. Kindly fill the education section manually on UCAS"
              : !application.in_cialfo_review &&
                "The automation only works if the application is Pending Cialfo Review, Cialfo Reviewing, or More Info Required"}
          </ReactTooltip>
        )}
      </div>
    );
  };

  return <div className="mb-6">{renderButton()}</div>;
};

function mapStateToProps(state) {
  return {
    taggingQuickSubmit: state.directApply.taggingQuickSubmit,
    isJobLoading: state.ucasApplicationForm.isEducationJobLoading,
  };
}

const mapDispatchToProps = {
  fillEducationSection:
    UcasApplicationFormCreators.fillEducationSectionRequest,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(FillEducationSection),
);
