import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  clearTeam:                        null,
  createTeamMemberFailure:          null,
  createTeamMemberRequest:          ['params', 'resolve', 'reject'],
  createTeamMemberSuccess:          ['teamMember'],
  downloadTeamFailure:              null,
  downloadTeamRequest:              ['params'],
  downloadTeamSuccess:              ['url'],
  getTeamFailure:                   null,
  getTeamRequest:                   ['params', 'resolve', 'reject'],
  getTeamSuccess:                   ['teamData', 'params'],
  bulkActionFailure:                null,
  bulkActionRequest:                ['params'],
  bulkActionSuccess:                 ['teamMembers'],
  updateTeamMemberFailure:          null,
  updateTeamMemberRequest:          ['params', 'resolve', 'reject'],
  updateTeamMemberSuccess:          ['teamMember'],
  getTeamMemberFailure:             null,
  getTeamMemberRequest:             ['params'],
  getTeamMemberSuccess:             ['teamMember']
});

export const TeamTypes = Types;
export const TeamCreators = Creators;
