import AdditionalRequirements from "./additional-requirements";
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
const additionalRequirementsSelector = formValueSelector('programAdditionalRequirementsForm');

function mapStateToProps(state) {
  const {
    directApplyProgram: {
      documentTypes,
    }
  } = state;

  return {
    additionalRequirementsValues: additionalRequirementsSelector(
                                    state,
                                    'questions_title',
                                    'questions',
                                  ),
    documentTypes,
  };
}

export default connect(mapStateToProps)(AdditionalRequirements);
