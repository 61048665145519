import { createReducer } from "reduxsauce";
import { MiscTypes as Types } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.misc;

export const getCountriesFailure = (state, action) => ({
  ...state,
  loadingCountries: false,
});
export const getCountriesRequest = (state, action) => ({
  ...state,
  loadingCountries: true,
});
export const getCountriesSuccess = (state, action) => ({
  ...state,
  countries: action.countries,
  loadingCountries: false,
});


// map our action types to our reducer functions
export const HANDLERS = {
  [Types.GET_COUNTRIES_FAILURE]: getCountriesFailure,
  [Types.GET_COUNTRIES_REQUEST]: getCountriesRequest,
  [Types.GET_COUNTRIES_SUCCESS]: getCountriesSuccess,
};

export default createReducer(INITIAL_STATE, HANDLERS);
