import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({

  clearPartnerFilters:  null,

  setPartnerFilters:  ['filters'],

  setPartnerSearchText: ['text']

});

export const PartnerFiltersTypes = Types;
export const PartnerFiltersCreators = Creators;
