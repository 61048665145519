import React, { useState } from "react";
import { ConfirmationModal } from "../../../components";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Format } from "../../../helpers";
import { withTranslation } from "react-i18next";
import ArrowImage from "../../../assets/arrow.png";

const MergePartners = ({
  isFetching,
  fetchSuccessful,
  isMerging,
  originalPartner,
  duplicatePartner,
  onClickClose,
  swapPartners,
  mergePartners,
  t,
}) => {
  const [swapPartnersModalVisible, setSwapPartnerModalVisibility] =
    useState(false);
  const [mergePartnersModalVisible, setMergePartnerModalVisibility] =
    useState(false);
  const requestSwapPartners = () => {
    setSwapPartnerModalVisibility(true);
  };
  const confirmSwapPartners = () => {
    setSwapPartnerModalVisibility(false);
    swapPartners();
  };
  const cancelSwapPartners = () => {
    setSwapPartnerModalVisibility(false);
  };
  const requestMergePartners = () => {
    setMergePartnerModalVisibility(true);
  };
  const confirmMergePartners = () => {
    setMergePartnerModalVisibility(false);
    mergePartners(originalPartner.id, duplicatePartner.id);
  };
  const cancelMergePartners = () => {
    setMergePartnerModalVisibility(false);
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-none flex flex-row justify-between items-center h-70px px-5">
        <p className="text-lg text-gray-750 font-bold">Merge Accounts</p>
        <FontAwesomeIcon
          onClick={onClickClose}
          icon={["fas", "times"]}
          className="text-lg cursor-pointer"
        />
      </div>
      <div className="flex-none flex flex-row h-46px bg-dark-blue-50 px-32 justify-between items-center">
        <p className="text-gray-750 font-bold">Duplicate</p>
        <p className="text-gray-750 font-bold mr-4">Original</p>
      </div>

      <div className="flex-1 flex flex-col mt-12 px-5">
        {!isFetching && fetchSuccessful ? (
          <div className="flex flex-row justify-between">
            <div className="">
              <PartnerCard partner={duplicatePartner} />
            </div>
            <div className="flex flex-col justify-center items-center">
              <img src={ArrowImage} alt="Exchange" className="w-24" />
              <button
                onClick={requestSwapPartners}
                className="btn-border-primary mt-2"
              >
                <FontAwesomeIcon
                  icon={["fas", "exchange-alt"]}
                  className="mr-2"
                />
                {"Swap Accounts"}
              </button>
            </div>
            <div className="">
              <PartnerCard original partner={originalPartner} />
            </div>
          </div>
        ) : null}
      </div>
      <div className="border-t border-gray-300 h-20 flex-none flex flex-row justify-end items-center px-10">
        <button onClick={requestMergePartners} className="btn-primary">
          Merge Accounts
        </button>
      </div>
      <ConfirmationModal
        visible={swapPartnersModalVisible}
        title={"Swap Accounts"}
        message={
          "Are you sure you want to swap accounts?\nOnce merged, the Duplicate account will be merged into the Original account. This action cannot be undone."
        }
        confirmText={t("common.buttons.confirm")}
        cancelText={t("common.buttons.cancel")}
        onConfirm={confirmSwapPartners}
        onCancel={cancelSwapPartners}
      />
      <ConfirmationModal
        destructive
        visible={mergePartnersModalVisible}
        title={"Merge Accounts"}
        message={
          "Are you sure you want to merge accounts?\nOnce merged, the Duplicate account will be merged into the Original account."
        }
        warning={"This action cannot be undone."}
        confirmText={t("common.buttons.confirm")}
        cancelText={t("common.buttons.cancel")}
        onConfirm={confirmMergePartners}
        onCancel={cancelMergePartners}
      />
      {isMerging ? (
        <div
          className={
            "fixed bg-gray-200 top-0 bottom-0 right-0 left-0 flex justify-center items-center z-10"
          }
        >
          <Loader type="Oval" color="grey" height={75} width={75} />
        </div>
      ) : null}
    </div>
  );
};

const PartnerDisplayField = ({ label, value }) => {
  return (
    <div className="flex flex-col">
      <p className="text-xs text-gray-500">{label}</p>
      <p className="text-sm font-bold text-gray-750 mt-1">
        {Format.displayValue(value)}
      </p>
    </div>
  );
};

const PartnerCard = ({ original, partner }) => {
  return (
    <div className="flex-none flex flex-col w-300px shadow-card rounded-lg">
      <div
        className={`flex flex-col h-68px justify-center px-4 ${
          original ? "bg-green-50" : "bg-yellow-50"
        }`}
      >
        <p className="text-sm font-bold text-black">
          {partner.name.substring(0, 35) +
            (partner.name.length > 35 ? "..." : "")}
        </p>
        <p className="text-xs text-gray-500">{partner.address}</p>
      </div>
      <div className="flex flex-col px-4 py-2">
        <div className="flex flex-row">
          <div className="flex-1">
            <PartnerDisplayField
              label="Applicant Name"
              value={partner.applicant_name}
            />
          </div>
          <div className="flex-1">
            <PartnerDisplayField label="Explore ID" value={partner.id} />
          </div>
        </div>
        <div className="flex flex-row mt-3">
          <div className="flex-1">
            <PartnerDisplayField
              label="Applied on"
              value={Format.date(partner.applied_at)}
            />
          </div>
          <div className="flex-1">
            <PartnerDisplayField label="Users" value={partner.user_count} />
          </div>
        </div>
        <div className="flex flex-row mt-3">
          <div className="flex-1">
            <PartnerDisplayField
              label="Upcoming Visits"
              value={partner.visit_count}
            />
          </div>
          <div className="flex-1">
            <PartnerDisplayField
              label="Connections"
              value={partner.connection_count}
            />
          </div>
        </div>
        <div className="flex flex-row mt-3">
          <div className="flex-1">
            <PartnerDisplayField
              label="Applications"
              value={partner.application_count}
            />
          </div>
          <div className="flex-1">
            <PartnerDisplayField
              label="Programs"
              value={partner.program_count}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(MergePartners);
