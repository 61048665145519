import ApplicantsPage from "./applicants";
import { connect } from "react-redux";
import {
  PartnersCreators,
  PartnerFiltersCreators,
} from "../../../redux/actions";

function mapStateToProps(state) {
  const {
    partners: {
      isFetchingMergePartners,
      fetchMergePartnersSuccessful,
      isMergingPartners,
      mergePartnerSuccessful,
      mergeMessage,
      isFetchingProductOfferingOptions,
      productOfferingOptions,
      partnerCountries,
    },
    user: { is_admin },
  } = state;

  return {
    partners: state.partners.data,
    total: state.partners.total,
    matched: state.partners.matched,
    isNextPageLoading: state.partners.loading,
    sso_url: state.partners.data,
    partnerSearchState: state.partnerSearchState,
    isFetchingMergePartners,
    fetchMergePartnersSuccessful,
    isMergingPartners,
    mergePartnerSuccessful,
    mergeMessage,
    isFetchingProductOfferingOptions,
    productOfferingOptions,
    partnerCountries,
    is_admin,
  };
}

const mapDispatchToProps = {
  getPartners: PartnersCreators.getPartnersRequest,
  clearPartners: PartnersCreators.clearPartners,
  getPartnerSso: PartnersCreators.getPartnerSsoRequest,
  setFilters: PartnerFiltersCreators.setPartnerFilters,
  setSearchText: PartnerFiltersCreators.setPartnerSearchText,
  getMergePartners: PartnersCreators.getMergePartnersRequest,
  getProductOfferingOptions: PartnersCreators.getProductOfferingOptionsRequest,
  getCountries: PartnersCreators.getPartnersFilterCountriesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantsPage);
