import React, { useEffect, useState } from "react";
import "react-virtualized/styles.css";
import Loader from "react-loader-spinner";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { Table, Filter } from "../../components";

const sortableColumns = {
  name: "name",
  tenant: "tenant",
};
const DistrictsScreen = props => {
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [prevParams, setPrevParams] = useState({});
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    load();
  }, []);

  const load = loadMore => {
    const params = {
      search_text: "",
      search_email: "",
      sort_params: [
        {
          sort_by: sortableColumns[sortColumn],
          sort_direction: sortDirection,
        },
      ],
    };

    if (loadMore === true || !_.isEqual(params, prevParams)) {
      if (!loadMore) setPrevParams(params);

      return new Promise((resolve, reject) => {
        props.getDistricts(params, resolve, reject);
      });
    } else {
      return new Promise(function (resolve) {
        resolve();
      });
    }
  };

  const loadMore = () => {
    return load(true);
  };

  const onClickHeader = ({ dataKey }) => {
    setSortColumn(dataKey);
    setSortDirection(
      sortColumn === dataKey
        ? sortDirection === "desc"
          ? "asc"
          : "desc"
        : "asc",
    );
  };

  const rowClassName = ({ index }) => {
    if (index < 0) {
      return "";
    } else {
      return "app-table-row";
    }
  };

  const { districts, matched, total, isNextPageLoading } = props;
  const hasNextPage = matched > districts.length;
  const isRowLoaded = ({ index }) =>
    !hasNextPage || index < districts.length;

  const loadMoreRows = isNextPageLoading ? () => {} : loadMore;

  const getWidth = () => {
    return (window.innerWidth - 104) / 2;
  };

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <div className="flex  px-6 py-4 items-center">
        <span className="text-xl font-bold text-black pr-6">
          Districts
        </span>
      </div>

      <div className="px-6">
        <Filter
          searchPlaceholder="Search for District name"
          selectedFilters={{}}
          searchText={searchText}
          filterOptions={[]}
          onChangeSearchText={setSearchText}
        />
      </div>

      <div className="h-px bg-gray-300 mb-3" />

      <div className="applicants-list flex my-2 items-center pl-6">
        {total === 0 && isNextPageLoading ? null : (
          <div className="font-bold">
            {matched} Districts of {total}
          </div>
        )}
      </div>

      <Table
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        totalRowCount={total}
        rows={districts}
        currentRowCount={districts.length}
        headerHeight={46}
        rowHeight={({ index: number }) => {
          if (districts[number]?.tenants?.length > 1) {
            return districts[number].tenants.length * 22 + 40;
          }
          return 62;
        }}
        rowClassName={index => {
          return `${rowClassName(index)}`;
        }}
        sortableColumns={sortableColumns}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        noRowsRenderer={() => {
          return isNextPageLoading ? (
            <div className="flex flex-col justify-center items-center  h-full w-full">
              <Loader
                type="Oval"
                color="grey"
                height={50}
                width={50}
              />
            </div>
          ) : null;
        }}
        onRowClick={({ rowData }) => {
          props.history.push({
            pathname: `/district/${rowData.id}`,
          });
        }}
        columns={[
          {
            dataKey: "name",
            width: getWidth(),
            type: "Default",
            disableSort: true,
            tooltip: true,
            valueSelector: rowData => ({
              value: rowData.name,
            }),
            header: {
              title: "Name",
              onClick: onClickHeader,
            },
          },
          {
            dataKey: "tenant",
            width: getWidth(),
            type: "Default",
            disableSort: true,
            tooltip: true,
            cellRenderer: ({ rowData, cellData }) => {
              return (
                <div className="flex flex-col">
                  {rowData.tenants.map(tenant => {
                    return (
                      <span className="text-xs text-gray-750">
                        {tenant.name}
                      </span>
                    );
                  })}
                </div>
              );
            },

            header: {
              title: "Districts",
              onClick: onClickHeader,
            },
          },
        ]}
      ></Table>
      {isNextPageLoading && districts.length > 0 ? (
        <div
          className={
            "absolute bottom-0 self-center pointer-events-none flex-none flex flex-col justify-center items-center p-1"
          }
        >
          <Loader
            type="ThreeDots"
            color="grey"
            height={75}
            width={75}
          />
        </div>
      ) : null}
    </div>
  );
};

export default withTranslation()(withRouter(DistrictsScreen));
