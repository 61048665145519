import React from "react";
import EventBus from "eventing-bus";
import Loader from "react-loader-spinner";
import { Filter, Table } from "../../components";
import { Alert } from "../../helpers";
import { UsersTypes } from "../../redux/actions";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import "react-virtualized/styles.css";

const sortableColumns = {
  name: "users.first_name",
  email: "users.email",
  status: "users.is_active",
};

class Users extends React.Component {
  state = {
    searchText: "",
    searchEmail: "",
    sortColumn: "name",
    sortDirection: "asc",
    status: "all",
    editState: "view",
    editIndex: -1,
    updateVals: {},
  };

  componentDidMount() {
    this._load(true);
    this._subscribeEvents();
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _subscribeEvents = () => {
    this.updateUsersSuccessSubscription = EventBus.on(
      UsersTypes.UPDATE_USERS_SUCCESS,
      this._updateUsersSuccessHandler,
    );
    this.updateUsersFailureSubscription = EventBus.on(
      UsersTypes.UPDATE_USERS_FAILURE,
      this._updateUserFailureHandler,
    );
  };

  _updateUsersSuccessHandler = () => {
    let u = this.props.users.find(
      element => element.id === this.state.editIndex,
    );
    let oldEmail = u.email;
    let newEmail = this.state.updateVals["email"];
    u.email = newEmail;
    this.setState({
      editState: "view",
      editIndex: "-1",
      updateVals: {},
    });
    Alert.success(
      `User email updated from ${oldEmail} to ${newEmail} `,
      {
        customFields: {
          title: "Updated Successfully!",
        },
      },
    );
  };

  _unsubscribeEvents = () => {
    this.updateUsersSuccessSubscription();
    this.updateUsersFailureSubscription();
  };

  _onStatusChange = ({ value }) => {
    this.setState({ status: value }, this._load);
  };

  _load = loadMore => {
    const {
      searchText,
      searchEmail,
      prevParams,
      sortColumn,
      sortDirection,
    } = this.state;

    const params = {
      search_text: searchText,
      search_email: searchEmail,
      sort_params: [
        {
          sort_by: sortableColumns[sortColumn],
          sort_direction: sortDirection,
        },
      ],
    };

    if (loadMore === true || !_.isEqual(params, prevParams)) {
      if (!loadMore)
        this.setState({ prevParams: params, isLoading: true });

      return new Promise((resolve, reject) => {
        this.props.getUsers(params, resolve, reject);
      });
    } else {
      return new Promise(function (resolve) {
        resolve();
      });
    }
  };

  _loginSuperUser = id => {
    this._getPartnerSso(id);
  };

  _getPartnerSso = _.debounce(id => {
    this.props.getPartnerSso(id);
  });

  _loadMore = () => {
    return this._load(true);
  };

  _searchLoad = _.debounce(this._load, 1000);

  _search = searchText => {
    this.setState(
      {
        searchText,
        users: [],
      },
      this._searchLoad,
    );
  };

  _onChangeSearchEmail = searchEmail => {
    this.setState({
      searchEmail,
    });
  };

  _rowGetter = ({ index }) => this.props.users[index] || {};

  _rowClassName = ({ index }) => {
    if (index < 0) {
      return "";
    } else {
      return "app-table-row";
    }
  };

  toggleEditState = userData => {
    this.setState({
      editState: "edit",
      editIndex: userData.id,
      updateVals: { email: userData.email },
    });
  };

  save = () => {
    let data = Object.assign(this.state.updateVals, {
      userIds: this.state.editIndex,
    });

    return new Promise((resolve, reject) => {
      this.props.updateUsers(data, resolve, reject);
    });
  };

  _updateUserFailureHandler = () => {};

  handleChange = event => {
    let tempState = Object.assign(this.state.updateVals, {
      email: event.target.value,
    });
    this.setState({
      updateVals: tempState,
    });
  };

  _onClickHeader = ({ dataKey }) => {
    const { sortColumn, sortDirection } = this.state;

    this.setState(
      {
        sortColumn: dataKey,
        sortDirection:
          sortColumn === dataKey
            ? sortDirection === "desc"
              ? "asc"
              : "desc"
            : "asc",
      },
      this._load,
    );
  };

  render() {
    const { users, matched, total, isNextPageLoading } = this.props;
    const { searchText, sortColumn, sortDirection, searchEmail } =
      this.state;
    const hasNextPage = matched > users.length;
    const isRowLoaded = ({ index }) =>
      !hasNextPage || index < users.length;
    const loadMoreRows = isNextPageLoading
      ? () => {}
      : this._loadMore;

    return (
      <div className="flex-1 flex flex-col">
        <span className="text-xl font-bold text-black px-6 pt-4">
          Users
        </span>
        <div className="pl-6 pt-4">
          <Filter
            searchPlaceholder="Search for user name"
            selectedFilters={{}}
            searchText={searchText}
            filterOptions={[]}
            onChangeSearchText={this._search}
          />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-4 max-w-xl items-center">
            <div className="col-span-2">
              <input
                type="text"
                className={
                  "input-field focus:outline-none focus:border-blue-700 placeholder-gray-500 "
                }
                id={"text-input-search"}
                placeholder="Search for complete email"
                onChange={event => {
                  this._onChangeSearchEmail(event.target.value);
                }}
                value={searchEmail}
                maxLength={150}
              />
            </div>
            <div className="w-max col-span-1">
              <button
                className="btn-primary w-max"
                onClick={() => this._load()}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="h-px bg-gray-300 mb-3" />

        <div className="applicants-list flex mt-2 items-center pl-6">
          {total === 0 && isNextPageLoading ? null : (
            <div className="font-bold">
              {matched} Applicants of {total}
            </div>
          )}
        </div>
        <div className="flex flex-1 table-container mt-5">
          <Table
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            totalRowCount={matched}
            rows={users}
            currentRowCount={users.length}
            headerHeight={46}
            rowHeight={62}
            rowClassName={this._rowClassName}
            sortableColumns={sortableColumns}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            noRowsRenderer={() => {
              return isNextPageLoading ? (
                <div className="flex flex-col justify-center items-center  h-full w-full">
                  <Loader
                    type="Oval"
                    color="grey"
                    height={50}
                    width={50}
                  />
                </div>
              ) : null;
            }}
            columns={[
              {
                dataKey: "name",
                width: 300,
                flexGrow: 1,
                type: "VerticalTwoValueBlack",
                disableSort: true,
                tooltip: true,
                valueSelector: rowData => ({
                  topValue:
                    rowData.first_name + "  " + rowData.last_name,
                  bottomValue: rowData.partner_name,
                }),
                header: {
                  title: "Name",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "role",
                width: 200,
                type: "Default",
                disableSort: true,
                valueSelector: rowData => ({
                  value: rowData.role?.name,
                }),
                header: {
                  title: "Role",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "countries",
                width: 200,
                disableSort: true,
                cellRenderer: ({ rowData, cellData }) => {
                  if (cellData.length === 0) {
                    return;
                  }

                  const countries = cellData.map(
                    country => ` ${country.name}`,
                  );
                  const renderCountry =
                    cellData.length === 1
                      ? cellData[0].name
                      : `${cellData[0].name} + ${cellData.length - 1}`;

                  return (
                    <div className="flex flex-row">
                      <span
                        data-tip={countries.toString().trim()}
                        className={"badge-primary mr-1"}
                      >
                        {renderCountry}
                      </span>
                    </div>
                  );
                },
                header: {
                  title: "Country",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "status",
                width: 150,
                type: "Tag",
                disableSort: true,
                valueSelector: rowData => ({ value: rowData.status }),
                className: rowData =>
                  rowData.status === "active"
                    ? "badge-success"
                    : "badge-warning",
                header: {
                  title: "Status",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "email",
                width: 350,
                type: "Default",
                disableSort: true,
                cellRenderer: ({ rowData }) => {
                  return (
                    <div className="flex flex-col">
                      {this.state.editState === "edit" &&
                      this.state.editIndex === rowData.id ? (
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-750 leading-tight focus:outline-none focus:shadow-outline"
                          id="username"
                          type="text"
                          value={this.state.updateVals["email"]}
                          onChange={this.handleChange}
                        />
                      ) : (
                        <span
                          className="text-sm text-blue-700 font-normal"
                          data-tip={rowData.email}
                          data-iscapture={true}
                        >
                          {rowData.email}
                        </span>
                      )}
                    </div>
                  );
                },
                header: {
                  title: "Email",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "",
                width: 100,
                type: "Default",
                disableSort: true,
                cellRenderer: ({ cellData, rowData }) => {
                  return (
                    <div className="flex justify-start items-center">
                      {this.state.editState === "edit" &&
                      this.state.editIndex === rowData.id ? (
                        <Link
                          target="_blank"
                          onClick={event => {
                            event.preventDefault();
                            this.save();
                          }}
                        >
                          <button className="btn-primary-alt">
                            Save
                          </button>
                        </Link>
                      ) : (
                        <Link
                          target="_blank"
                          onClick={event => {
                            event.preventDefault();
                            this.toggleEditState(rowData);
                          }}
                        >
                          <button className="btn-primary-alt">
                            Edit
                          </button>
                        </Link>
                      )}
                    </div>
                  );
                },
                header: {
                  // title: "Email",
                  // onClick: this._onClickHeader,
                },
              },
            ]}
          ></Table>
          {isNextPageLoading && users.length > 0 ? (
            <div
              className={
                "absolute bottom-0 right-0 left-0 self-center pointer-events-none flex-none flex flex-col justify-center items-center p-1"
              }
            >
              <Loader
                type="ThreeDots"
                color="grey"
                height={75}
                width={75}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Users));
