import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import Loader from "react-loader-spinner";
import { DirectApplyCreators } from "../../../../../redux/actions";

const PENDING = "Running";
const RUNNING = "Pending";

const FillFormCTA = ({
  t,
  application,
  taggingQuickSubmit,
  fillTaggingForm,
  ucasQuickSubmit,
}) => {
  const { tagging_job_status } = taggingQuickSubmit;

  const isFormFailed = tagging_job_status === "Failed";

  const handleSaveForm = () => {
    fillTaggingForm(application?.id);
  };

  if (taggingQuickSubmit?.tagging_complete) return null;

  const renderButton = () => {
    switch (true) {
      case tagging_job_status === PENDING:
      case tagging_job_status === RUNNING:
        return (
          <div data-tip data-for="fill_application_form_button">
            <button
              className="button-contain-loading-primary-disable"
              disabled
            >
              Tagging form filling
              <Loader
                className="ml-2 text-gray-500"
                type="Oval"
                height={10}
                width={10}
              />
            </button>
            <ReactTooltip
              id="fill_application_form_button"
              aria-haspopup="true"
              place="bottom"
              effect="solid"
              arrowColor="transparent"
            >
              <p>Tagging form filling is in progress</p>
            </ReactTooltip>
          </div>
        );
      case ucasQuickSubmit?.jobs_running:
        return (
          <>
            <button disabled className="btn-border-primary mb-6">
              Fill on Portal
              <FontAwesomeIcon
                icon={["far", "sparkles"]}
                className="pl-2"
              />
            </button>
          </>
        );
      case isFormFailed:
        return (
          <div>
            <div className="flex justify-center">
              <button
                onClick={handleSaveForm}
                className="button-contain-error mb-2"
              >
                Try again
                <FontAwesomeIcon icon="undo" className="pl-2" />
              </button>
            </div>
            <span className="text-base text-red-800 mt-2 block text-center mb-6">
              {application.ucas_form_filling_error_message ||
                "An error occurred. Please try again. You may also fill this manually or reach out to support."}
            </span>
          </div>
        );
      default:
        return (
          <button
            onClick={handleSaveForm}
            className="button-contain-primary"
          >
            Fill on Portal
            <FontAwesomeIcon
              icon={["far", "sparkles"]}
              className="pl-2"
            />
          </button>
        );
    }
  };

  return <div className="mb-6">{renderButton()}</div>;
};

function mapStateToProps(state) {
  return {
    ucasQuickSubmit: state.directApply.ucasQuickSubmit,
    taggingQuickSubmit: state.directApply.taggingQuickSubmit,
    application: state.directApply.selected,
  };
}

const mapDispatchToProps = {
  fillTaggingForm: DirectApplyCreators.fillTaggingFormRequest,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(FillFormCTA),
);
