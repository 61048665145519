import { call, put, takeEvery, select } from "redux-saga/effects";
import { UsersCreators, UsersTypes } from "../actions";
import { getUsers, updateUsers } from "../../web-services";
import _ from "lodash";
const authTokenSelector = (state) => state.user.authToken;

export function* watchUsersRequests() {
  yield takeEvery(UsersTypes.GET_USERS_REQUEST, requestGetUsers);
  yield takeEvery(UsersTypes.UPDATE_USERS_REQUEST, requestUpdateUsers);
}

export function* requestGetUsers(action) {
  try {
    const { params, resolve } = action;
    const usersSelector = (state) => state.users;
    const authToken = yield select(authTokenSelector);
    const { data: currentUsers, params: currentParams } = yield select(
      usersSelector
    );
    const shouldLoadMore =
      currentUsers.length > 0 &&
      _.isEqual(_.omit(currentParams, ["page_no"]), params);

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;

    const requestParams = { ...params };
    requestParams["page_no"] = page;
    const response = yield call(getUsers, authToken, requestParams);
    resolve && resolve();
    yield put(UsersCreators.getUsersSuccess(response.data, requestParams));
  } catch (error) {
    yield put(UsersCreators.getUsersFailure());
  }
}

export function* requestUpdateUsers(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);

    yield call(updateUsers, authToken, params);
    yield put(UsersCreators.updateUsersSuccess());
  } catch (error) {
    yield put(UsersCreators.updateUsersFailure());
  }
}
