import React from "react";
import { uniq, isEqual, debounce } from "lodash";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import "react-virtualized/styles.css";
import {
  Table,
  ConfirmationModal,
  ExpandedFilter,
} from "../../../components";
import { Format } from "../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EventBus from "eventing-bus";
import { DirectApplyTypes } from "../../../redux/actions";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import Switch from "react-switch";
import NoResultsFound from "../applications/no-results-found";

const sortableColumns = {
  name: "program_name",
  program_type_id: "program_types.code",
  submission_type_id: "program_submission_types.code",
  id: "id",
  partner_name: "partners.name",
  start_date: "start_date",
  opening_date: "opening_date",
  deadline: "deadline_date",
  applicant_count: "applicant_count",
  new: "newly_created",
};

class ProgramsPage extends React.Component {
  state = {
    searchText: "",
    sortColumn: "name",
    sortDirection: "asc",
    filters: {},
    downloadingPrograms: false,
    includePastPrograms: false,
  };

  componentDidMount() {
    this.props.clearPrograms();
    this._load();
    this._fetchFilters();
    this._subscribeEvents();
  }

  _load = loadMore => {
    const {
      searchText,
      prevParams,
      sortColumn,
      sortDirection,
      filters,
      includePastPrograms,
    } = this.state;
    const universityFilter =
      filters.university_name && filters.university_name.length > 0
        ? {
            offer_for_id: filters.university_name.map(
              university => university.id,
            ),
          }
        : {};

    let countryIds = [];

    if (filters.country && filters.country.length > 0) {
      countryIds = filters.country.map(country => country.id);
    }

    if (filters.citizenship && filters.citizenship.length > 0) {
      countryIds = countryIds.concat(
        filters.citizenship.map(citizenship => citizenship.id),
      );
    }

    const countryAndCitizenshipFilter = countryIds.length
      ? {
          countries: {
            id: uniq(countryIds),
          },
        }
      : {};

    const partnerFilter =
      filters.partner && filters.partner.length > 0
        ? {
            partner_id: filters.partner.map(partner => partner.id),
          }
        : {};
    const statusFilter =
      filters.statuses && filters.statuses.length > 0
        ? { status: filters.statuses.map(status => status.id) }
        : {};
    const programTypeFilter =
      filters.program_types && filters.program_types.length > 0
        ? {
            program_type_id: filters.program_types.map(
              programType => programType.id,
            ),
          }
        : {};
    const submissionTypeFilter =
      filters.submission_types && filters.submission_types.length > 0
        ? {
            program_submission_type_id: filters.submission_types.map(
              SubmissionType => SubmissionType.id,
            ),
          }
        : {};
    const roundsFilter =
      filters.rounds && filters.rounds.length > 0
        ? { deadline_name: filters.rounds.map(round => round.id) }
        : {};
    const deadlineDateFilter = filters.deadline_date
      ? {
          deadline: {
            from: moment(filters.deadline_date.startDate)
              .startOf("day")
              .format("DD/MM/YYYY"),
            to: moment(filters.deadline_date.endDate)
              .endOf("day")
              .format("DD/MM/YYYY"),
          },
        }
      : {};
    const matriculationDateFilter = filters.matriculation_date
      ? {
          matriculation: {
            from: moment(filters.matriculation_date.startDate)
              .startOf("day")
              .format("DD/MM/YYYY"),
            to: moment(filters.matriculation_date.endDate)
              .endOf("day")
              .format("DD/MM/YYYY"),
          },
        }
      : {};

    const params = {
      search_text: searchText,
      sort_params: [],
      past: includePastPrograms,
      conditions: {
        ...universityFilter,
        ...statusFilter,
        ...roundsFilter,
        ...matriculationDateFilter,
        ...deadlineDateFilter,
        ...partnerFilter,
        ...countryAndCitizenshipFilter,
        ...programTypeFilter,
        ...submissionTypeFilter,
      },
    };

    if (sortColumn && sortableColumns[sortColumn]) {
      params["sort_params"].push({
        sort_by: sortableColumns[sortColumn],
        sort_direction: sortDirection,
      });
    }

    if (loadMore === true || !isEqual(params, prevParams)) {
      if (!loadMore)
        this.setState({ prevParams: params, isLoading: true });

      return new Promise((resolve, reject) => {
        this.props.getPrograms(params, resolve, reject);
      });
    } else {
      return new Promise(function (resolve, reject) {
        resolve();
      });
    }
  };

  _fetchFilters = () => {
    this.props.getFilterUniversities();
    this.props.getFilterStatuses();
    this.props.getFilterRounds();
    this.props.getPartners();
    this.props.getProgramTypes();
    this.props.getProgramSubmissionTypes();
    this.props.getProgramCountries();
    this.props.getProgramCitizenships();
    this.props.getProgramPartners();
  };

  _downloadApplicationsRequest = () => {
    const {
      searchText,
      sortColumn,
      sortDirection,
      filters,
      includePastPrograms,
    } = this.state;
    const universityFilter =
      filters.university_name && filters.university_name.length > 0
        ? {
            offer_for_id: filters.university_name.map(
              university => university.id,
            ),
          }
        : {};

    let countryIds = [];

    if (filters.country && filters.country.length > 0) {
      countryIds = filters.country.map(country => country.id);
    }

    if (filters.citizenship && filters.citizenship.length > 0) {
      countryIds = countryIds.concat(
        filters.citizenship.map(citizenship => citizenship.id),
      );
    }

    const countryAndCitizenshipFilter = countryIds.length
      ? {
          countries: {
            id: uniq(countryIds),
          },
        }
      : {};

    const partnerFilter =
      filters.partner && filters.partner.length > 0
        ? {
            partner_id: filters.partner.map(partner => partner.id),
          }
        : {};
    const statusFilter =
      filters.statuses && filters.statuses.length > 0
        ? { status: filters.statuses.map(status => status.id) }
        : {};
    const roundsFilter =
      filters.rounds && filters.rounds.length > 0
        ? { deadline_name: filters.rounds.map(round => round.id) }
        : {};
    const deadlineDateFilter = filters.deadline_date
      ? {
          deadline: {
            from: moment(filters.deadline_date.startDate)
              .startOf("day")
              .format("DD/MM/YYYY"),
            to: moment(filters.deadline_date.endDate)
              .endOf("day")
              .format("DD/MM/YYYY"),
          },
        }
      : {};
    const matriculationDateFilter = filters.matriculation_date
      ? {
          matriculation: {
            from: moment(filters.matriculation_date.startDate)
              .startOf("day")
              .format("DD/MM/YYYY"),
            to: moment(filters.matriculation_date.endDate)
              .endOf("day")
              .format("DD/MM/YYYY"),
          },
        }
      : {};
    const params = {
      search_text: searchText,
      sort_params: [],
      past: includePastPrograms,
      conditions: {
        ...universityFilter,
        ...statusFilter,
        ...roundsFilter,
        ...matriculationDateFilter,
        ...deadlineDateFilter,
        ...countryAndCitizenshipFilter,
        ...partnerFilter,
      },
    };

    if (sortColumn && sortableColumns[sortColumn]) {
      params["sort_params"].push({
        sort_by: sortableColumns[sortColumn],
        sort_direction: sortDirection,
      });
    }

    this.setState({ prevParams: params, isLoading: true });
    return new Promise((resolve, reject) => {
      this.props.downloadList(params, resolve, reject);
    });
  };

  _loadMore = params => {
    return this._load(true);
  };

  _searchLoad = debounce(this._load, 1000);

  _search = searchText => {
    this.setState(
      {
        searchText,
      },
      this._searchLoad,
    );
  };

  _rowClassName = ({ index }) => {
    if (index < 0) {
      return "";
    } else {
      return "app-table-row";
    }
  };

  _getDownloadSuccess = () => {
    const { programDownloadUrl } = this.props;

    if (programDownloadUrl) window.open(programDownloadUrl);
  };

  _subscribeEvents = () => {
    this.downloadRequestSubscription = EventBus.on(
      DirectApplyTypes.GET_PROGRAMS_LIST_DOWNLOAD_URL_REQUEST,
      () => this.setState({ downloadingPrograms: true }),
    );
    this.downloadSuccessSubscription = EventBus.on(
      DirectApplyTypes.GET_PROGRAMS_LIST_DOWNLOAD_URL_SUCCESS,
      () =>
        this.setState(
          { downloadingPrograms: false },
          this._getDownloadSuccess,
        ),
    );
    this.downloadFailureSubscription = EventBus.on(
      DirectApplyTypes.GET_PROGRAMS_LIST_DOWNLOAD_URL_FAILURE,
      () => this.setState({ downloadingPrograms: false }),
    );
  };

  _unsubscribeEvents = () => {
    this.downloadRequestSubscription();
    this.downloadSuccessSubscription();
    this.downloadFailureSubscription();
  };

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _onClickHeader = ({ dataKey }) => {
    const { sortColumn, sortDirection } = this.state;

    this.setState(
      {
        sortColumn: dataKey,
        sortDirection:
          sortColumn === dataKey
            ? sortDirection === "desc"
              ? "asc"
              : "desc"
            : "asc",
      },
      this._load,
    );
  };

  render() {
    const {
      t,
      programs,
      matched,
      total,
      isNextPageLoading,
      programsFilterUniversities,
      programsFilterStatuses,
      programsFilterRounds,
      programTypes,
      programSubmissionTypes,
      programCountries,
      programCitizenships,
      programPartners,
    } = this.props;
    const {
      confirmationModalVisible,
      searchText,
      filters,
      sortColumn,
      sortDirection,
    } = this.state;
    const { downloadingPrograms } = this.state;
    const hasNextPage = matched > programs.length;
    const isRowLoaded = ({ index }) =>
      !hasNextPage || index < programs.length;
    const loadMoreRows = isNextPageLoading
      ? () => {}
      : this._loadMore;

    const getFilterText = key => {
      return t(`direct_apply_programs.filters.${key}`);
    };

    const filterOptions = [
      {
        id: "filters",
        filterTypes: [
          {
            id: "matriculation_date",
            type: "date-range",
            text: getFilterText("matriculation_date"),
            icon: ["fas", "user-clock"],
            isMulti: true,
            options: [],
            open: true,
          },
          {
            id: "deadline_date",
            type: "date-range",
            text: getFilterText("deadline_date"),
            icon: ["fas", "hourglass-end"],
            isMulti: true,
            options: [],
            open: true,
          },
          {
            id: "rounds",
            text: getFilterText("rounds"),
            icon: ["fas", "calendar-minus"],
            isMulti: true,
            options:
              programsFilterRounds &&
              programsFilterRounds.map(round => ({
                id: round.name,
                text: round.name,
              })),
            open: true,
          },
          {
            id: "program_types",
            text: getFilterText("program_type"),
            open: true,
            icon: ["fas", "file-alt"],
            isMulti: true,
            options: programTypes.map(type => ({
              id: type.id,
              text: type.code,
            })),
          },
          {
            id: "submission_types",
            text: getFilterText("submission_type"),
            open: true,
            icon: ["fas", "file-alt"],
            isMulti: true,
            options: programSubmissionTypes.map(type => ({
              id: type.id,
              text: type.code,
            })),
          },
          {
            id: "statuses",
            text: getFilterText("statuses"),
            icon: ["fas", "clipboard-check"],
            isMulti: true,
            options:
              programsFilterStatuses &&
              programsFilterStatuses.map(status => ({
                id: status.id,
                text: status.name,
              })),
            open: true,
          },
          {
            id: "university_name",
            text: getFilterText("university_name"),
            icon: ["fas", "university"],
            isMulti: true,
            isSearchable: true,
            options:
              programsFilterUniversities &&
              programsFilterUniversities.map(university => ({
                id: university.id,
                text: university.name,
              })),
            open: true,
          },
          {
            id: "partner",
            text: "Partner Name",
            icon: ["fas", "school"],
            isMulti: true,
            isSearchable: true,
            options:
              programPartners &&
              programPartners.map(partners => ({
                id: partners.id,
                text: partners.name,
              })),
          },
          {
            id: "country",
            text: "Country",
            icon: ["fas", "location-arrow"],
            isMulti: true,
            isSearchable: true,
            options: programCountries.map(country => ({
              id: country.id,
              text: country.name,
            })),
          },
          {
            id: "citizenship",
            text: "Citizenship",
            icon: ["fas", "location-arrow"],
            isMulti: true,
            isSearchable: true,
            options: programCitizenships.map(country => ({
              id: country.id,
              text: country.name,
            })),
          },
        ],
      },
    ];

    return (
      <div className="flex-1 flex flex-col">
        <div className="pl-6">
          <ExpandedFilter
            searchPlaceholder="Search by program/university name"
            selectedFilters={filters}
            searchText={searchText}
            filterOptions={filterOptions}
            onChangeSearchText={this._search}
            onSelection={filters => {
              this.setState(
                {
                  filters,
                },
                this._load,
              );
            }}
          />
        </div>

        <div class="h-px bg-gray-300 mb-3"></div>

        <div className="applicants-list flex mt-2 items-center pl-6">
          {total === 0 && isNextPageLoading ? null : (
            <div className="font-bold">
              {matched} programs match of {total}
            </div>
          )}
          <button
            onClick={this._downloadApplicationsRequest}
            className="btn-secondary w-48 ml-3"
            data-tip
            data-for="download_applications_tip"
          >
            {downloadingPrograms ? (
              <Loader
                type="Oval"
                color="grey"
                height={18}
                width={18}
              />
            ) : (
              <>
                <FontAwesomeIcon
                  icon={["fas", "download"]}
                  className="mr-2"
                />
                Download Programs
              </>
            )}
          </button>
          <ReactTooltip
            id={"download_applications_tip"}
            aria-haspopup="true"
            place="top"
            effect="solid"
          >
            <p>
              Apply different filters to download specific filtered
              data.
            </p>
          </ReactTooltip>

          <div className="flex-1" />
          <span className="text-sm text-gray-750 font-bold leading-none mr-2">
            Include past programs
          </span>
          <Switch
            checked={this.state.includePastPrograms}
            onChange={includePastPrograms =>
              this.setState({ includePastPrograms }, this._load)
            }
            onColor="#B5C2D0"
            onHandleColor="#007BE2"
            handleDiameter={22}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
            height={15}
            width={36}
            className="react-switch mr-4"
            id="material-switch"
          />
        </div>
        <div className="mt-5" />
        {!isNextPageLoading && matched === 0 ? (
          <NoResultsFound
            title={"Search not found"}
            detail={
              "There are no programs matching the search. Please try again with different keywords or filters"
            }
          />
        ) : (
          <Table
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            totalRowCount={matched}
            rows={programs}
            currentRowCount={programs.length}
            headerHeight={46}
            rowHeight={62}
            rowClassName={this._rowClassName}
            sortableColumns={sortableColumns}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onRowClick={({ rowData, event, index }) => {
              this.props.history.push("/programs/" + rowData.id);
            }}
            noRowsRenderer={() => {
              return isNextPageLoading ? (
                <div className="flex flex-col justify-center items-center h-full w-full">
                  <Loader
                    type="Oval"
                    color="grey"
                    height={50}
                    width={50}
                  />
                </div>
              ) : null;
            }}
            columns={[
              {
                dataKey: "name",
                width: 200,
                flexGrow: 1,
                tooltip: true,
                type: "VerticalTwoValueBlue",
                disableSort: false,
                valueSelector: rowData => ({
                  topValue: rowData.name,
                  bottomValue: rowData.university_name,
                }),
                header: {
                  title: "Program",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "program_type_id",
                width: 200,
                type: "Default",
                disableSort: true,
                valueSelector: rowData => ({
                  value: rowData.program_type_code,
                }),
                header: {
                  title: t("Program Type"),
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "submission_type_id",
                width: 200,
                type: "Default",
                disableSort: true,
                valueSelector: rowData => ({
                  value: rowData.program_submission_type_code,
                }),
                header: {
                  title: t("Submission Type"),
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "new",
                width: 100,
                type: "Tag",
                disableSort: false,
                valueSelector: rowData => ({
                  value: rowData.is_new && "New",
                }),
                className: rowData => "badge-primary",
                header: {
                  title: "New",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "id",
                width: 100,
                type: "Default",
                disableSort: false,
                valueSelector: rowData => ({ value: rowData.id }),
                header: {
                  title: "ID",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "partner_name",
                width: 150,
                type: "Default",
                disableSort: false,
                tooltip: true,
                valueSelector: rowData => ({
                  value: rowData.partner,
                }),
                header: {
                  title: "Partner",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "round",
                width: 100,
                type: "Tag",
                disableSort: true,
                valueSelector: rowData => ({
                  value: rowData.deadline_name,
                }),
                className: rowData => "badge-info",
                header: {
                  title: "Round",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "start_date",
                width: 200,
                type: "Default",
                disableSort: false,
                valueSelector: rowData => ({
                  value: Format.date(rowData.start_date),
                }),
                header: {
                  title: "Start Date",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "opening_date",
                width: 200,
                type: "Default",
                disableSort: false,
                valueSelector: rowData => ({
                  value: Format.date(rowData.opening_date),
                }),
                header: {
                  title: "Opening Date",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "deadline",
                width: 200,
                type: "Default",
                disableSort: false,
                valueSelector: rowData => ({
                  value: Format.date(rowData.deadline_date),
                }),
                header: {
                  title: "Application Deadline",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "status",
                width: 150,
                type: "Tag",
                disableSort: true,
                valueSelector: rowData => ({ value: rowData.status }),
                className: rowData => rowData.status_class_name,
                header: {
                  title: "Status",
                  onClick: this._onClickHeader,
                },
              },
            ]}
          />
        )}
        <ConfirmationModal
          visible={confirmationModalVisible}
          title={"Change status of program application?"}
          // message={t(""))}
          cancelText={""}
          confirmText={"Confirm change or program application?"}
          onCancel={() => {}}
          onConfirm={() => {}}
        />
        {isNextPageLoading && programs.length > 0 ? (
          <div
            className={
              "absolute bottom-0 right-0 left-0 self-center pointer-events-none flex-none flex flex-col justify-center items-center p-1"
            }
          >
            <Loader
              type="ThreeDots"
              color="grey"
              height={75}
              width={75}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(withRouter(ProgramsPage));
