import React from "react";
import DirectApplyEmptyImage from "../../../assets/direct-apply/empty.png";

const Empty = (props) => {
  return (
    <div className="flex-1 flex flex-col pt-12">
      <img
        style={{ height: 476, width: 590, right: 50, top: 50 }}
        className="absolute object-contain"
        src={DirectApplyEmptyImage}
        alt="background"
      />

      <div
        style={{ width: 407 }}
        className="font-domaine text-4xl text-black font-semibold leading-tight"
      >
        Direct Apply - Student Applications for your programs will arrive here
      </div>
      <div
        style={{ width: 436 }}
        className="font-lato text-lg text-gray-750 font-normal leading-tight mt-12"
      >
        Talk to our students and answer all the questions they have about your
        college.
      </div>
    </div>
  );
};

export default Empty;
