import DirectApplicationDetailsScreen from "./direct-application-detail-screen";
import { connect } from "react-redux";
import {
  DirectApplyCreators,
  UcasApplicationFormCreators,
} from "../../../redux/actions";

function mapStateToProps(state) {
  return {
    application: state.directApply.selected,
    applicationResults: state.directApply.applicationResults,
    statusList: state.directApply.statusList,
    applicationResultsList: state.directApply.resultList,
    userData: state.user.data,
    applications: state.directApply.data,
    matchedApplications: state.directApply.matched,
  };
}

const mapDispatchToProps = {
  getApplication: DirectApplyCreators.getApplicationRequest,
  updateApplicationUcasData:
    DirectApplyCreators.updateApplicationUcasDataRequest,
  updateApplicationStatus:
    DirectApplyCreators.updateApplicationStatusRequest,
  updateApplicationResult:
    DirectApplyCreators.updateApplicationResultRequest,
  updateApplicationDepositPaid:
    DirectApplyCreators.updateApplicationDepositPaidRequest,
  getApplicationResults:
    DirectApplyCreators.getDirectApplicationResultsRequest,
  deleteDirectApplyDocument:
    DirectApplyCreators.deleteDirectApplicationDocumentRequest,
  setApplicationStatus:
    DirectApplyCreators.setApplicationStatusRequest,
  getDirectApplicationStatusList:
    DirectApplyCreators.getDirectApplicationStatusListRequest,
  getDirectApplicationResultList:
    DirectApplyCreators.getDirectApplicationResultListRequest,
  getPartnerSso: DirectApplyCreators.getDirectApplySsoRequest,
  saveUcasApplicationForm:
    UcasApplicationFormCreators.saveUcasApplicationFormRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DirectApplicationDetailsScreen);
