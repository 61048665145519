import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Format } from "src/helpers";

export const DisplayDocuments = props => {
  const {
    label,
    value,
    isBadge,
    beforeIcon,
  } = props;

  return (
    <div className="flex-none flex flex-row items-center min-h-42 px-3 py-2 display-field">
      <div className="flex-none w-56">
        <h3 className="text-sm text-gray-700 ">{label}</h3>
      </div>
      <div className="flex-1 flex flex-row pl-4">
        <div
          className="flex flex-col flex-wrap"
        >
          {value && beforeIcon ? (
            <FontAwesomeIcon
              icon={beforeIcon}
              className="text-sm leading-none text-blue-700 mr-px self-center"
            />
          ) : null}
          {Array.isArray(value) ? (
            value.map((document, index) => (
              <div
                key={document.id}
                className={
                  isBadge
                    ? "badge-primary mt-1 mr-1"
                    : `text-sm font-bold cursor-pointer text-blue-700`
                }
                onClick={() => {
                  if (document.file) window.open(document.file);
                }}
              >
                {Format.displayValue(document.name)}
              </div>
            ))
          ) : React.isValidElement(value) || value === null ? (
            value
          ) : (
            <div
              className={
                isBadge
                  ? "badge-primary mt-1"
                  : `text-sm font-bold text-gray-900`
              }
            >
              {Format.displayValue(value)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
