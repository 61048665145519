import MergePartners from "./merge-partners";
import { connect } from "react-redux";
import { PartnersCreators } from '../../../redux/actions';

function mapStateToProps(state) {
  const {
    partners: {
      mergePartners: {
        originalPartner,
        duplicatePartner,
      },
      isFetchingMergePartners,
      fetchMergePartnersSuccessful,
      isMergingPartners,
      mergePartnersSuccessful,
    },
  } = state;

  return {
    originalPartner,
    duplicatePartner,
    isFetching: isFetchingMergePartners,
    fetchSuccessful: fetchMergePartnersSuccessful,
    isMerging: isMergingPartners,
    mergeSuccessful: mergePartnersSuccessful,
  }
}

const mapDispatch = {
  mergePartners: PartnersCreators.mergePartnersRequest,
  swapPartners: PartnersCreators.swapMergePartners,
}

export default connect(mapStateToProps, mapDispatch)(MergePartners);