import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeadShake from "react-reveal/HeadShake";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { UserCreators } from "../redux/actions";
import { admin } from "../constants/user-roles.json";

class DropOverComponent extends React.Component {
  logOut() {
    this.props.logout();
  }

  render() {
    const { userRole, userName } = this.props;
    return (
      <div>
        <HeadShake>
          <div
            className="bg-white absolute flex flex-col px-4 py-2 rounded-lg border border-gray-200 shadow-md z-20"
            style={{
              width: "max-content",
              left: 0,
              bottom: 50,
            }}
          >
            <div className="flex items-center font-bold text-black py-3">
              <div className="flex items-center justify-center rounded-full bg-blue-200 border-none w-6 h-6 mr-2">
                <FontAwesomeIcon
                  icon={["fas", "user"]}
                  className="w-4 h-4 text-lg text-blue-700 leading-none "
                />
              </div>
              <div className="text-base">{userName}</div>
            </div>

            {userRole === admin && (
              <div className="py-4 flex flex-col border-t border-b border-gray-300">
                <div className="flex items-center">
                  <Link className="" to={"/team"}>
                    <FontAwesomeIcon
                      icon={["fal", "users"]}
                      className="text-base text-gray-750 mr-4"
                    />
                    <span className="text-sm text-gray-750">
                      Team
                    </span>
                  </Link>
                </div>
              </div>
            )}

            <div className="flex flex-col pt-4 pb-2">
              <div
                onClick={() => {
                  this.logOut();
                }}
              >
                <FontAwesomeIcon
                  icon={["fal", "arrow-right-from-bracket"]}
                  className="text-base text-gray-750 mr-4"
                />
                <span className="text-sm text-gray-750">Logout</span>
              </div>
            </div>
          </div>
        </HeadShake>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userRole: state.user?.data?.role?.name,
    userName: state.user?.data?.role?.fullname,
  };
};

const mapDispatchToProps = {
  logout: UserCreators.logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DropOverComponent);
