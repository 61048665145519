import React, { Component } from 'react';
import ReactModal from 'react-modal';

class ConfirmationModal extends Component {
  render() {
    const {
      title,
      message,
      warning,
      cancelText,
      confirmText,
      onConfirm,
      onCancel,
      visible,
      destructive,
    } = this.props;

    return (
      <ReactModal
        isOpen={visible}
        onRequestClose={() => {}}
        ariaHideApp={false}
        className="modal"
        overlayClassName="modalOverlay"
      >
        <div className="absolute left-0 right-0 top-0 bottom-0 bg-overlay-500 flex justify-center items-center z-20">
          <div style={{minHeight: 200}} className="rounded-lg bg-white pt-6 pb-4 px-6 flex flex-col relative w-500px">
            <span className="font-bold text-xl text-gray-750 mb-4">
              {title}
            </span>

            <span className="font-normal text-sm text-gray-750 mb-4 whitespace-pre-wrap">
              {message}
            </span>
            {warning ? (
              <span className="font-bold text-sm text-red-800 mb-4 whitespace-pre-wrap">
                {warning}
              </span>
            ) : null}

            <div className="flex-1" />
            <div className="flex flex-row justify-end mt-4">
              {!!onCancel || !!cancelText ? (
                <button onClick={onCancel} className="btn-secondary mr-3">
                  {cancelText}
                </button>
              ) : null}
              <button onClick={onConfirm} className={destructive ? "btn-error" : "btn-primary"}>
                {confirmText}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    )
  }
};

export default ConfirmationModal;
