import React from "react";
import PrimaryTabs from "../../components/PrimaryTabs/PrimaryTabs";

const BaseLayout = props => {
  const { children, location } = props;
  const selection = location.pathname.split("/")[1];

  return (
    <div className="flex flex-row justify-between h-full relative">
      <PrimaryTabs selection={selection} />

      <div className="flex-1 flex flex-col h-full">{children}</div>
    </div>
  );
};

export default BaseLayout;
