import ResetPasswordScreen from './reset-password-screen';
import { UserCreators } from '../../redux/actions';
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  resetPassword: (params) => UserCreators.resetPasswordRequest(params),
  updateAppLocale: UserCreators.updateAppLocale
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordScreen);
