import React, { useState } from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

const Testimonial = ({ testimonial, isSelected, onClick }) => (
  <div onClick={onClick} className="flex-1 flex flex-row text-blue-700 border rounded overflow-hidden h-20 cursor-pointer">
    <div className="flex flex-col px-3 pt-3">
      {isSelected ? (
        <div className={`flex flex-none w-4 h-4 rounded-full bg-green-500 border border-green-500 justify-center items-center mr-2`}>
          <FontAwesomeIcon icon={['fas', 'check']} className="text-white text-xs leading-none"/>
        </div>
      ) : (
        <div className={"w-4 h-4 rounded-full bg-white border border-black mr-2"} />
      )}
    </div>

    <div className="flex-1 flex flex-col pt-3 overflow-hidden">
      <span className="text-sm text-black font-bold leading-none mt-px truncate">{testimonial.name}'s Testimonial</span>
      <span className="text-xs text-black font-regular leading-none mt-2 truncate">{testimonial.quote}</span>
    </div>

    {testimonial.avatar ? (
      <div className="flex-none h-20 w-20 flex flex-col ml-3">
        <img
          alt={"Testimonial"}
          src={testimonial.avatar}
          className="flex-1 object-cover"
        />
      </div>
    ) : null}
  </div>
);


const TestimonialSelectModal = ({ visible, onClose, onSave, testimonials }) => {
  const [selectedTestimonial, setSelectedTestimonial] = useState(-1);

  const onClickSave = () => {
    onSave && onSave(selectedTestimonial);
  };

  return (
    <ReactModal
      isOpen={visible}
      onRequestClose={() => {}}
      ariaHideApp={false}
      className="modal"
      overlayClassName="modalOverlay"
    >
      <div className="absolute left-0 right-0 top-0 bottom-0 bg-overlay-500 flex justify-center items-center z-20">
        <div style={{width: 500}} className="rounded-lg bg-white p-3 flex flex-col relative w-460px">
          <div className={"flex flex-row justify-between"}>
            <span className="font-bold text-base text-gray-750 mb-6 pt-2 pl-2">
              {"Select an existing testimonial"}
            </span>

            <FontAwesomeIcon
              onClick={onClose}
              icon={['fas', 'times']}
              className={"text-lg leading-none text-gray-750 cursor-pointer mt-2"}
            />
          </div>

          <div className={"flex flex-col pl-3"}>
            {_.take(testimonials, 2).map((testimonial, index, list) => (
              <React.Fragment key={testimonial.id}>
                <Testimonial
                  onClick={() => setSelectedTestimonial(testimonial)}
                  isSelected={selectedTestimonial.id === testimonial.id}
                  testimonial={testimonial}
                />
                {
                  index < list.length - 1 ? <div className="h-2" /> : null
                }
              </React.Fragment>
            ))}
          </div>

          <div className={"flex flex-row justify-end mt-3"}>
            <button onClick={onClose} className={"btn-border-primary"}>{"Cancel"}</button>
            <div className={"w-3"} />
            <button
              onClick={onClickSave}
              className={"btn-primary"}
            >
              {"Select"}
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default TestimonialSelectModal;
