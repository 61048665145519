import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropOverComponent from "./DropOverComponent";

class ProfileMenuComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showDropOver: false };
    this.componentRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  toggleMenu() {
    const { showDropOver } = this.state;

    this.setState({ showDropOver: !showDropOver });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener(
      "mousedown",
      this.handleClickOutside,
    );
  }

  handleClickOutside(event) {
    if (
      this.componentRef.current &&
      !this.componentRef.current.contains(event.target)
    ) {
      const { showDropOver } = this.state;
      if (showDropOver) {
        this.toggleMenu();
      }
    }
  }

  render() {
    const { showDropOver } = this.state;
    return (
      <div
        ref={this.componentRef}
        onClick={() => {
          this.toggleMenu();
        }}
        className="flex items-center mb-6 cursor-pointer relative rounded-20px border border-gray-300 p-2"
      >
        <div className="flex items-center justify-center rounded-full bg-blue-200 border-none w-6 h-6 mr-2">
          <FontAwesomeIcon
            icon={["fas", "user"]}
            className="w-4 h-4 text-lg text-blue-700 leading-none "
          />
        </div>
        <FontAwesomeIcon
          className="text-base text-gray-600"
          icon={["far", "bars"]}
        />

        {showDropOver && <DropOverComponent show={showDropOver} />}
      </div>
    );
  }
}

export default ProfileMenuComponent;
