import { call, put, takeEvery, select } from "redux-saga/effects";
import { PartnersCreators, PartnersTypes } from "../actions";
import {
  getPartners,
  getPartner,
  updatePartner,
  updatePartnerName,
  getPartnerSso,
  getMergePartners,
  mergePartners,
  getProductOfferings,
  savePartner,
  getPartnersFilterCountries,
} from "../../web-services";
import { isEqual, omit } from "lodash";

const {
  clearPartners,
  getPartnersSuccess,
  getPartnersFailure,
  getPartnerSuccess,
  getPartnerFailure,
  savePartnerSuccess,
  savePartnerFailure,
  approvePartnerSuccess,
  approvePartnerFailure,
  declinePartnerSuccess,
  declinePartnerFailure,
  updatePartnerNameSuccess,
  updatePartnerNameFailure,
  getPartnerSsoSuccess,
  getPartnerSsoFailure,
  getMergePartnersSuccess,
  getMergePartnersFailure,
  mergePartnersSuccess,
  mergePartnersFailure,
  getProductOfferingOptionsSuccess,
  getProductOfferingOptionsFailure,
} = PartnersCreators;

const authTokenSelector = (state) => state.user.authToken;

export function* watchPartnersRequests() {
  yield takeEvery(PartnersTypes.GET_PARTNERS_REQUEST, requestGetPartners);
  yield takeEvery(PartnersTypes.GET_PARTNER_REQUEST, requestGetPartner);
  yield takeEvery(PartnersTypes.APPROVE_PARTNER_REQUEST, requestApprovePartner);
  yield takeEvery(PartnersTypes.DECLINE_PARTNER_REQUEST, requestDeclinePartner);
  yield takeEvery(
    PartnersTypes.UPDATE_PARTNER_NAME_REQUEST,
    requestUpdatePartnerName
  );
  yield takeEvery(PartnersTypes.GET_PARTNER_SSO_REQUEST, requestGetPartnerSso);
  yield takeEvery(
    PartnersTypes.GET_MERGE_PARTNERS_REQUEST,
    requestGetMergePartners
  );
  yield takeEvery(PartnersTypes.MERGE_PARTNERS_REQUEST, requestMergePartners);
  yield takeEvery(
    PartnersTypes.GET_PRODUCT_OFFERING_OPTIONS_REQUEST,
    requestGetProductOfferings
  );
  yield takeEvery(PartnersTypes.SAVE_PARTNER_REQUEST, requestSavePartner);
  yield takeEvery(
    PartnersTypes.GET_PARTNERS_FILTER_COUNTRIES_REQUEST,
    requestPartnersGetCountriesFilter
  );
}

export function* requestGetPartners(action) {
  try {
    const { params, resolve } = action;
    const partnersSelector = (state) => state.partners;
    const authToken = yield select(authTokenSelector);
    const { data: currentPartners, params: currentParams } = yield select(
      partnersSelector
    );
    const shouldLoadMore =
      currentPartners.length > 0 &&
      isEqual(omit(currentParams, ["page_no"]), params);

    if (!shouldLoadMore) yield put(clearPartners());

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;

    const requestParams = { ...params };
    requestParams["page_no"] = page;

    const response = yield call(getPartners, authToken, requestParams);
    resolve && resolve();
    yield put(getPartnersSuccess(response.data, requestParams));
  } catch (error) {
    const { reject } = action;
    reject && reject();
    yield put(getPartnersFailure());
  }
}

export function* requestGetPartner(action) {
  try {
    const { partnerId } = action;
    const authToken = yield select(authTokenSelector);

    const response = yield call(getPartner, authToken, partnerId);
    yield put(getPartnerSuccess(response.data.partner));
  } catch (error) {
    yield put(getPartnerFailure());
  }
}

export function* requestSavePartner(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(savePartner, authToken, action.partner);
    yield put(savePartnerSuccess(response.data.partner));
  } catch (error) {
    yield put(savePartnerFailure());
  }
}

export function* requestApprovePartner(action) {
  try {
    const { partnerId, params } = action;
    const authToken = yield select(authTokenSelector);

    yield call(updatePartner, authToken, partnerId, params);
    yield put(approvePartnerSuccess());
  } catch (error) {
    yield put(approvePartnerFailure());
  }
}

export function* requestDeclinePartner(action) {
  try {
    const { partnerId, params } = action;
    const authToken = yield select(authTokenSelector);

    yield call(updatePartner, authToken, partnerId, params);
    yield put(declinePartnerSuccess());
  } catch (error) {
    yield put(declinePartnerFailure());
  }
}

export function* requestUpdatePartnerName(action) {
  try {
    const { partnerId, params } = action;
    const authToken = yield select(authTokenSelector);

    yield call(updatePartnerName, authToken, partnerId, params);
    yield put(updatePartnerNameSuccess());
  } catch (error) {
    yield put(updatePartnerNameFailure());
  }
}

export function* requestGetPartnerSso(action) {
  try {
    const { partnerId, params } = action;
    const authToken = yield select(authTokenSelector);

    const response = yield call(getPartnerSso, authToken, partnerId, params);
    yield put(getPartnerSsoSuccess(response.data.sso_url));
    window.open(response.data.sso_url, "_blank");
  } catch (error) {
    yield put(getPartnerSsoFailure());
  }
}

export function* requestGetMergePartners(action) {
  try {
    const { originalId, duplicateId } = action;
    const authToken = yield select(authTokenSelector);
    const requestParams = {
      original_partner_id: originalId,
      duplicate_partner_id: duplicateId,
    };

    const response = yield call(getMergePartners, authToken, requestParams);
    yield put(
      getMergePartnersSuccess(
        response.data.original_partner,
        response.data.duplicate_partner
      )
    );
  } catch (error) {
    yield put(getMergePartnersFailure());
  }
}

export function* requestMergePartners(action) {
  try {
    const { originalId, duplicateId } = action;
    const authToken = yield select(authTokenSelector);
    const requestParams = {
      original_partner_id: originalId,
      duplicate_partner_id: duplicateId,
    };

    const response = yield call(mergePartners, authToken, requestParams);
    yield put(mergePartnersSuccess(response.data.message));
  } catch (error) {
    yield put(mergePartnersFailure());
  }
}

export function* requestGetProductOfferings(action) {
  try {
    const authToken = yield select(authTokenSelector);

    const response = yield call(getProductOfferings, authToken);
    yield put(getProductOfferingOptionsSuccess(response.data.options));
  } catch (error) {
    yield put(getProductOfferingOptionsFailure());
  }
}

function* requestPartnersGetCountriesFilter(action) {
  try {
    const { params } = action;
    const authTokenSelector = (state) => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getPartnersFilterCountries, authToken, params);
    yield put(
      PartnersCreators.getPartnersFilterCountriesSuccess(
        response.data.countries
      )
    );
  } catch (error) {
    yield put(PartnersCreators.getPartnersFilterCountriesFailure());
  }
}
