import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  clearNewPartners: null,

  getNewPartnersFailure: null,
  getNewPartnersRequest: ["params", "resolve", "reject"],
  getNewPartnersSuccess: ["partnersData", "params"],

  getNewPartnersFilterCountriesFailure: null,
  getNewPartnersFilterCountriesRequest: ["params"],
  getNewPartnersFilterCountriesSuccess: ["countries"],

  getNewPartnersFilterStatusesFailure: null,
  getNewPartnersFilterStatusesRequest: ["params"],
  getNewPartnersFilterStatusesSuccess: ["statuses"],

  getNewPartnerFailure: null,
  getNewPartnerRequest: ["partnerId"],
  getNewPartnerSuccess: ["partner"],

  approveNewPartnerFailure: null,
  approveNewPartnerRequest: ["partnerId", "params"],
  approveNewPartnerSuccess: null,

  declineNewPartnerFailure: null,
  declineNewPartnerRequest: ["partnerId", "params"],
  declineNewPartnerSuccess: null,
});

export const NewPartnersTypes = Types;
export const NewPartnersCreators = Creators;
