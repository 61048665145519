const TAB_TYPE = {
    applicant: "Applicant",
    university: "University",
    school: "School",
    user: "User",
    settings: "My Settings"
};

const PARTNER_TYPES = {
  public_partner: "Public",
  private_partner: "Private",
  international_partner: "International",
}

const PARTNER_TYPES_OPTIONS = [{ label: "Public", value: 'public_partner'}, { label: "Private", value: 'private_partner'}]

const ROUTES = {
  LOGIN: "/login",
};

export { TAB_TYPE, PARTNER_TYPES, PARTNER_TYPES_OPTIONS, ROUTES };
