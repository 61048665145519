import GuardianScreen from "./guardian";
import { connect } from "react-redux";
import { ClientsCreators } from "src/redux/actions";

function mapStateToProps(state) {
  return {
    guardians: state.clients.guardians.data,
    total: state.clients.guardians.total,
    matched: state.clients.guardians.matched,
    isNextPageLoading: state.clients.guardians.loading,
  };
}

const mapDispatchToProps = {
  getGuardians: ClientsCreators.getGuardiansRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GuardianScreen);
