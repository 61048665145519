import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import SectionFilter from './section-filter';
import FlatFilter from './flat-filter';
import DateRangeFilter from './date-range-filter';
import _ from 'lodash';

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFilterTypes: [],
    };
  }

  componentDidMount() {
    const { selectedFilters } = this.props;
    const filterTypeToSectionObject = {};
    const filterSectionsObject = {};

    this.props.filterOptions.forEach(filterSection => {
      filterSectionsObject[filterSection.id] = filterSection;

      filterSection.filterTypes.forEach(filterType => {
        filterTypeToSectionObject[filterType.id] = filterSection.id;
      })
    });

    const selectedFilterTypeIds = Object.keys(selectedFilters);
    const selectedFilterTypes = selectedFilterTypeIds.map((filterTypeId, i) => {
      const filterSectionId = filterTypeToSectionObject[filterTypeId];
      const filterSection = filterSectionsObject[filterSectionId];
      let filterType = filterSection.filterTypes.find(filterType => filterType.id === filterTypeId);

      if (!filterType.icon) {
        filterType = {...filterType, icon: filterSection.icon};
      }

      return filterType;
    });

    this.setState({ selectedFilterTypes });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedFilterTypes } = this.state;
    const { selectedFilters } = this.props;

    if (
      (prevProps.filterOptions !== this.props.filterOptions &&
      !_.isEqual(prevProps.filterOptions, this.props.filterOptions)) ||
      (selectedFilterTypes.length === 0  && Object.keys(selectedFilters).length > 0)
    ) {
      const filterTypeToSectionObject = {};
      const filterSectionsObject = {};

      this.props.filterOptions.forEach(filterSection => {
        filterSectionsObject[filterSection.id] = filterSection;

        filterSection.filterTypes.forEach(filterType => {
          filterTypeToSectionObject[filterType.id] = filterSection.id;
        })
      });

      const selectedFilterTypeIds = Object.keys(selectedFilters);
      const selectedFilterTypes = selectedFilterTypeIds.map((filterTypeId, i) => {
        const filterSectionId = filterTypeToSectionObject[filterTypeId];
        const filterSection = filterSectionsObject[filterSectionId];
        let filterType = filterSection.filterTypes.find(filterType => filterType.id === filterTypeId);

        if (!filterType.icon) {
          filterType = {...filterType, icon: filterSection.icon};
        }

        return filterType;
      });

      this.setState({ selectedFilterTypes });
    }
  }

  _handleSelection = (value, text) => {
    const { selectedFilterTypes } = this.state;

    if (!selectedFilterTypes.find((selectedFilter) => selectedFilter.id === value.id))
      this.setState({ selectedFilterTypes: [...this.state.selectedFilterTypes, value] });
  }

  _triggerUpdate = (selectedFilters) => {
    this.props.onSelection && this.props.onSelection(selectedFilters);
  }

  render() {
    const { t, filterOptions, searchPlaceholder, selectedFilters } = this.props;
    const { selectedFilterTypes } = this.state;

    return (
      <div className="flex flex-col">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-4 max-w-xl ">
          <div className="col-span-2">
            <input
              type="text"
              className={"input-field focus:outline-none focus:border-blue-700 placeholder-gray-500 "}
              id={"text-input-search"}
              placeholder={searchPlaceholder}
              onChange={event => {
                this.props.onChangeSearchText(event.target.value);
              }}
              value={this.props.searchText}
              maxLength={150}
            />
          </div>
          {filterOptions.length > 0 ? (
            <div className="col-span-1">
              <SectionFilter
                button={(
                  <button className="btn-text-primary ml-1">
                    <FontAwesomeIcon icon={['fas', 'plus']} className="mr-1"/>
                    {t('common.buttons.add_filters')}
                  </button>
                )}
                filterSections={filterOptions}
                selectedFilterTypes={selectedFilterTypes}
                onSelection={this._handleSelection}
              />
            </div>
          ) : null}
        </div>
        <div className={"flex flex-row flex-wrap pr-48 " + (selectedFilterTypes.length > 0 ? "mb-1" : "")}>
          {
            selectedFilterTypes.map((selectedFilterType, selectedFilterTypeIndex) => {
              return selectedFilterType.type ==="date-range" ? (
                <DateRangeFilter
                  key={selectedFilterType.id}
                  id={selectedFilterType.id}
                  onSelection={(dateRange) => {
                    const selectedFiltersCopy = {
                      ...selectedFilters,
                    };

                    if (dateRange)
                      selectedFiltersCopy[selectedFilterType.id] = dateRange;
                    else {
                      _.remove(selectedFilterTypes, (filterCategory) => filterCategory.id === selectedFilterType.id)
                      delete selectedFiltersCopy[selectedFilterType.id];
                    }

                    this._triggerUpdate(selectedFiltersCopy);
                  }}
                  selectedRange={selectedFilters[selectedFilterType.id]}
                  openOnRender={true}
                  button={(
                    <div className={"flex-none flex flex-row items-center h-8 mb-2" + (selectedFilterTypeIndex > 0 ? " ml-3" : "")}>
                      <FontAwesomeIcon icon={selectedFilterType.icon} className="text-sm text-blue-700 mr-1"/>
                      <span className="text-sm font-bold text-blue-700 leading-snug ">
                        {selectedFilterType.text}
                        <span className="font-normal">{" is from"}</span>
                      </span>
                    </div>
                  )}
                />
              ) : (
                <React.Fragment key={selectedFilterType.id}>
                  <FlatFilter
                    id={selectedFilterType.id}
                    isSearchable={selectedFilterType.isSearchable}
                    button={(
                      <div className={"flex-none flex flex-row items-center h-8 mb-2 " + (selectedFilterTypeIndex > 0 ? " ml-3" : "")}>
                        <FontAwesomeIcon icon={selectedFilterType.icon} className="text-sm text-blue-700 mr-1"/>
                        <span className="text-sm font-bold text-blue-700 leading-snug ">
                          {selectedFilterType.text}
                          <span className="font-normal">{" is"}</span>
                        </span>
                      </div>
                    )}
                    isMulti={selectedFilterType.isMulti}
                    filterOptions={selectedFilterType.options}
                    selectedFilters={selectedFilters[selectedFilterType.id]}
                    emptyText={selectedFilterType.emptyText}
                    onSelection={(filters) => {
                      const selectedFiltersCopy = {
                        ...selectedFilters,
                      };

                      if (!filters || filters.length === 0) {
                        _.remove(selectedFilterTypes, (filterCategory) => filterCategory.id === selectedFilterType.id)
                        delete selectedFiltersCopy[selectedFilterType.id];
                      }
                      else {
                        selectedFiltersCopy[selectedFilterType.id] = filters;
                      }

                      this._triggerUpdate(selectedFiltersCopy);
                    }}
                    openOnRender={true}
                  />
                  {
                    selectedFilters[selectedFilterType.id] ? (
                      selectedFilters[selectedFilterType.id].map((selectedItem, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="w-2" />
                            <div className="h-42px flex-none flex flex-row bg-blue-200 h-8 rounded items-center px-2 mb-2">
                              <span className="text-sm font-normal text-dark-blue-800 leading-none ">{selectedItem.text}</span>
                              <FontAwesomeIcon
                                onClick={() => {
                                  const selectedCopy = [...selectedFilters[selectedFilterType.id]];
                                  _.remove(selectedCopy, (sItem) => sItem === selectedItem);

                                  const selectedFiltersCopy = {
                                    ...selectedFilters,
                                  };

                                  if (!selectedCopy || selectedCopy.length === 0) {
                                    _.remove(selectedFilterTypes, (filterCategory) => filterCategory.id === selectedFilterType.id)
                                    delete selectedFiltersCopy[selectedFilterType.id];
                                  }
                                  else {
                                    selectedFiltersCopy[selectedFilterType.id] = selectedCopy;
                                  }

                                  this._triggerUpdate(selectedFiltersCopy);
                                }}
                                icon={['fas', 'times']}
                                className="text-sm text-dark-blue-800 ml-2 cursor-pointer"
                              />
                            </div>
                          </React.Fragment>
                        );
                      })
                    ) : null
                  }
                </React.Fragment>
              );
            })
          }
        </div>
      </div>
    );
  }
}

Filter.defaultProps = {
  searchPlaceholder: "Search",
};

export default withTranslation()(Filter);
