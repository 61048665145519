import { API } from "../helpers";
import { SERVER_URL } from "../config";

export function getApplication(authToken, applicationId) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/${applicationId}`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getDocumentList(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/follow_ups/documents`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getFollowUps(authToken, applicationId) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/follow_ups/${applicationId}/list`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function addFollowUp(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/follow_ups`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function uploadCounselorDocument(authToken, userId, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/users/${userId}/upload_counselor_docs`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function updateApplicationUcasData(authToken, userId, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/users/${userId}/ucas_credentials`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function updatedUcasCredential(authToken, userId, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/students/${userId}/ucas_credentials`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function updateApplication(authToken, applicationId, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/student_applications/${applicationId}`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getApplications(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/student_applications`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getDirectApplicationActivities(
  authToken,
  applicationId,
) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/${applicationId}/activities`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getDirectApplicationNotes(authToken, applicationId) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/${applicationId}/someurl`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getDirectApplicationStatusList(
  authToken,
  applicationId,
) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/status_list`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getDirectApplicationResultList(
  authToken,
  applicationId,
) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/results_list`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function saveDirectApplicationNotes(authToken, notes) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/student_applications/someurl`,
    headers: {
      authentication_token: authToken,
    },
    notes,
  });
}

export function getDirectApplyFilterCountries(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/countries`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterProgramsCountries(
  authToken,
  params,
) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/programs/countries`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterProgramsCitizenships(
  authToken,
  params,
) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/programs/citizenships`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterProgramsPartners(
  authToken,
  params,
) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/programs/partners`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterCourses(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/courses`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterPrograms(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/programs`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterResults(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/results`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterStatus(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/statuses`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterFeeStatus(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/fee_statuses`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterFeeWaived(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/fee_waived_statuses`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterSchools(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/schools`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getTransactions(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/transactions`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getTransaction(authToken, id, data) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/transactions/${id}`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getRefund(authToken, id, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/transactions/${id}/refund`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getPartnerProgramUniversities(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/programs/universities`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getProgramsFilterStatuses(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/programs/statuses`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getProgramsFilterRounds(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/programs/rounds`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getPrograms(authToken, params) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/programs/list`,
    headers: {
      authentication_token: authToken,
    },
    data: params,
  });
}

export function getDirectApplyFilterUniversitites(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/universities`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterTransactionsUniversitites(
  authToken,
  params,
) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/transactions/universities`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterPartners(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/partners`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function setApplicationStatus(authToken, applicationId, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/student_applications/${applicationId}/update_status`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function submitApplication(authToken, applicationId) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/student_applications/${applicationId}/submit`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function unsubmitApplication(authToken, applicationId) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/student_applications/${applicationId}/unsubmit`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export const saveUcasApplicationForm = (
  authToken,
  application_id,
) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/student_applications/${application_id}/fill_ucas_form`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export const fillEducationSection = (authToken, application_id) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/student_applications/${application_id}/fill_education_section`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export const fillTaggingForm = (authToken, application_id) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/student_applications/${application_id}/fill_tagging_form`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export const saveNotes = (authToken, application_id, data) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/ucas_infos/${application_id}/add_notes`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const getUcasApplicationForm = (authToken, application_id) => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/ucas_infos/${application_id}`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export const getUcasQuickSubmit = (authToken, application_id) => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/${application_id}/quick_submit`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export const getTaggingQuickSubmit = (authToken, application_id) => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/student_applications/${application_id}/tagging_submit`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export const finalizeTask = (authToken, application_id, data) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/student_applications/${application_id}/mark_complete`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const markTaggingComplete = (
  authToken,
  application_id,
  data,
) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/student_applications/${application_id}/mark_tagging_complete`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export function fetchAppReviewLogs(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/application_review_logs`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getIgnoreReviewData(authToken, appId, params) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/ucas_infos/${appId}/ignore_review_data`,
    headers: {
      authentication_token: authToken,
    },
    data: params,
  });
}

export const getUcasChoices = (authToken, application_id) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/student_applications/${application_id}/fill_ucas_choice_form`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export const fillPersonalStatement = (authToken, application_id) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/student_applications/${application_id}/fill_personal_statement`,
    headers: {
      authentication_token: authToken,
    },
  });
};
