import BrochureSelectModal from "./brochure-select-modal";
import { connect } from "react-redux";

function mapStateToProps(state) {
  const {
    directApplyProgram: {
      brochures,
    }
  } = state;

  return {
    brochures,
  };
}

export default connect(mapStateToProps)(BrochureSelectModal);
