import React, { useState } from "react";
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cropper from 'react-easy-crop';
import _ from "lodash";
import Clipper from "image-clipper";
import { Notification } from "../../../../../helpers";
const MAX_FILE_SIZE = 20971520;

const PictureSelectModal = ({ visible, onClose, onSave, banners }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState();
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [aspect] = useState(800 / 202);
  const [croppedImageData, setCroppedImageData] = useState();

  const onFileSelected = (e) => {
    const validFiles = _.filter(e.target.files, file => file.size <= MAX_FILE_SIZE);

    if (validFiles.length < e.target.files.length)
      Notification.error("Please select files less than 20 MB in size");

    if (validFiles.length > 0) {
      setSelectedImage(validFiles[0]);
      setSelectedImageURL(URL.createObjectURL(validFiles[0]));
    }
  };

  const onClickSave = () => {
    const { x, y, width, height } = croppedImageData;

    Clipper(selectedImageURL, function() {
      this.crop(x, y, width, height)
     .toDataURL(function(dataUrl) {
       onSave && onSave(dataUrl);
      });
    });
  };

  return (
    <ReactModal
      isOpen={visible}
      onRequestClose={() => {}}
      ariaHideApp={false}
      className="modal"
      overlayClassName="modalOverlay"
    >
      <div className="absolute left-0 right-0 top-0 bottom-0 bg-overlay-500 flex justify-center items-center z-20">
        <div style={{width: 500}} className="rounded-lg bg-white p-3 flex flex-col relative w-460px">
          <div className={"flex flex-row justify-between"}>
            <span className="font-bold text-base text-gray-750 mb-4">
              {"Upload Image "}
            </span>

            <FontAwesomeIcon
              onClick={onClose}
              icon={['fas', 'times']}
              className={"text-lg leading-none text-gray-750 cursor-pointer"}
            />
          </div>
          <input
            accept='.jpg, .png, .jpeg'
            name="document"
            id="document"
            type='file'
            // multiple
            className={"hidden"}
            onChange={onFileSelected}
          />
          {selectedImage ? (
            <div className={"flex h-150px bg-blue-50 border border-dashed border-black mt-3 relative"}>
              <Cropper
                image={selectedImageURL}
                cropSize={{ width:475, height:120 }}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={(croppedArea, croppedAreaPixels) => {
                  setCroppedImageData(croppedAreaPixels);
                }}
                onMediaLoaded={(mediaSize) => {
                  if (mediaSize.width / mediaSize.height > (800 / 202)) {
                    setZoom(120 / mediaSize.height);
                  }
                  else {
                    setZoom(475 / mediaSize.width);
                  }
                }}
              />
            </div>
          ) : (
            <div className={""}>
              <label htmlFor="document" className="cursor-pointer">
                <div  className={"flex justify-center items-center h-150px bg-blue-50 border border-dashed border-blue-700 mt-3"}>
                  <div className={"btn-base text-blue-700 cursor-default"}>
                    <FontAwesomeIcon
                      icon={['fas', 'upload']}
                      className="mr-1"
                    />
                    {"Upload Image"}
                  </div>
                </div>
              </label>
            </div>
          )}
          <div className={"flex flex-row justify-end mt-3"}>
            <button onClick={onClose} className={"btn-border-primary"}>{"Cancel"}</button>
            <div className={"w-3"} />
            <button
              disabled={!selectedImage}
              onClick={onClickSave}
              className={"btn-primary"}
            >
              {"Save"}
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default PictureSelectModal;
