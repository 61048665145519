import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class NotificationBanner extends Component {
  render() {
    const {
      handleClose,
      message,
      type,
      title,
      messageBold,
      btnText
    } = this.props;
    let icon = null;
    let btnClass = "";

    switch(type) {
      case "error":
        icon = ['fas', 'exclamation-circle'];
        btnClass = "error";
        break
      case "warning":
        icon = ['fas', 'exclamation-triangle'];
        btnClass = "warning";
        break
      case "info":
        icon = ['fas', 'info-circle'];
        btnClass = "primary";
        break
      case "search":
        icon = ['fas', 'search-minus'];
        break
      case "success":
      default:
        icon = ['fas', 'check-circle'];
        btnClass = "success";
        break
    }

    return (
      <div className={"notification-banner flex flex-row bg-blue-200 p-3 " + type}>
        <div className="mr-3">
          <FontAwesomeIcon icon={icon} className="notification-icon mt-1"/>
        </div>

        <div className='flex-1 flex flex-col'>
          <span className="text-xl text-black font-bold leading-none">{title}</span>
          <div className="flex flex-row items-center">
            <div className="mt-1">
              <span className="text-sm text-black font-normal leading-none">{message}</span>
              <span className="text-sm text-black font-bold leading-none">{messageBold}</span>
            </div>
            {
              btnText ? (
                <button
                  onClick={this.handleConfirm.bind(this)}
                  className={"ml-5 btn-" + btnClass}
                >
                  {btnText}
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="flex-none flex flex-col">
          <FontAwesomeIcon onClick={handleClose} icon={['fas', "times"]} className="text-xl text-gray-750 cursor-pointer"/>
        </div>
      </div>
    );
  }
}

NotificationBanner.defaultProps = {
  handleClose: () => {},
  type: "success"
}

export default NotificationBanner;
