import React from "react";
import { Switch } from "antd";

const SwitchField = props => {
  const { input } = props;

  return (
    <Switch
      size="small"
      onChange={checked => input.onChange(checked)}
      checked={input.value}
    />
  );
};

export default SwitchField;
