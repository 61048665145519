import React from "react";
import { FileInputField } from "../../../../../components";
import {
  TextInputField,
  TextAreaField,
} from "../../../../../form-components";
import { Field } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Testimonial = ({
  testimonials,
  testimonial_image,
  onClickOpenSelectTestimonial,
  onClickDeleteImage,
  change,
  contentChanges,
}) => (
  <div className="flex flex-col pointer-events-none">
    <h2 className="text-gray-750 text-lg font-bold mb-4 mt-2">
      Testimonials
    </h2>
    <p className="text-black text-sm mb-5 leading-none">
      You don’t have any existing testimonial. Upload one below.
    </p>
    {/*
    <p className="text-black text-sm ">You can choose from your profile or upload your own.</p>

    <div className="flex flex-row justify-start my-3">
      <button disabled={testimonials.length < 1} onClick={onClickOpenSelectTestimonial} className="btn-primary">
        Select Testimonial
      </button>
    </div>
    */}

    <div className="flex flex-col border border-gray-500 rounded-lg">
      <div className="flex-1 flex flex-row m-4">
        <div
          className={
            "flex bg-gray-200 w-40 relative " +
            (contentChanges.avatar && "border border-yellow-800")
          }
        >
          {testimonial_image ? (
            <img
              className={"flex-1 object-cover"}
              src={
                typeof testimonial_image === "string"
                  ? testimonial_image
                  : URL.createObjectURL(testimonial_image)
              }
              alt="Testimonial"
            />
          ) : (
            <label
              htmlFor="testimonial_image"
              className={
                "flex-1 bg-blue-50 flex flex-col justify-center items-center cursor-pointer"
              }
            >
              <FontAwesomeIcon
                icon={["fas", "images"]}
                className="text-white text-2xl text-gray-750 leading-none"
              />
              <span className="text-sm text-blue-700 font-bold mt-4">
                Upload pictures
              </span>
            </label>
          )}
          <Field
            name="testimonial[id]"
            id="testimonial_image"
            component={() => null}
            className={"hidden"}
          />
          <Field
            name="testimonial[image]"
            id="testimonial_image"
            component={FileInputField}
            className={"hidden"}
          />
          {testimonial_image ? (
            <div
              onClick={onClickDeleteImage}
              className="absolute bottom-0 right-0 left-0 h-8 flex flex-row bg-overlay-500"
            >
              <div className="flex-1 flex flex-row justify-center items-center text-sm text-white leading-none cursor-pointer">
                Delete
              </div>
              <label
                htmlFor="testimonial_image"
                className="flex-1 flex flex-row justify-center items-center text-sm text-white leading-none cursor-pointer"
              >
                Change
              </label>
            </div>
          ) : null}
        </div>

        <div className="flex-1 flex flex-col ml-3">
          <div>
            <Field
              component={TextInputField}
              classForBorder={
                contentChanges.name && "border-yellow-800"
              }
              name="testimonial[name]"
              placeholder="Student Name"
            />
          </div>

          <div className="h-4" />

          <div>
            <Field
              component={TextInputField}
              classForBorder={
                contentChanges.nationality && "border-yellow-800"
              }
              name="testimonial[country]"
              placeholder="Student Nationality"
            />
          </div>
        </div>
      </div>

      <div className="mx-4 mb-4">
        <Field
          component={TextAreaField}
          props={{
            classForBorder:
              contentChanges.quote && "border-yellow-800",
          }}
          maxLength={400}
          showRemainingLimit
          name="testimonial[text]"
          placeholder="Student Quote"
        />
      </div>

      <div className="h-px bg-gray-500" />

      <div className="flex flex-row justify-end pr-4 py-3">
        <button
          onClick={() => {
            change("testimonial", null);
          }}
          className="btn-text-secondary"
        >
          Clear content
        </button>
      </div>
    </div>
  </div>
);

export default Testimonial;
