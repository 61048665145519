import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field } from "redux-form";
import { Format, Notification } from "../../../../../helpers";
import {
  Select,
  TextInput,
  ConfirmationModal,
} from "../../../../../components";
import { BROCHURE_SELECTION_STATUS } from "../../../../../constants";
const MAX_FILE_SIZE = 26214400;

export const Tooltip = ({ title, text, tooltipId }) => {
  return (
    <span className="relative">
      <FontAwesomeIcon
        data-tip
        data-for={tooltipId}
        icon={["fas", "question-circle"]}
        className={"text-lg text-gray-750 leading-none"}
      />
      <ReactTooltip
        id={tooltipId}
        aria-haspopup="true"
        place="top"
        effect="solid"
      >
        <div className="flex flex-col my-2 max-w-64">
          {title ? (
            <span className={"text-lg mb-4"}>{title}</span>
          ) : null}
          <p className="text-sm">{text}</p>
        </div>
      </ReactTooltip>
    </span>
  );
};

export const FormField = ({
  label,
  alignLabelTop,
  required,
  tooltip,
  labelColor,
  ...formFieldProps
}) => {
  return (
    <div
      className={`flex flex-row ${alignLabelTop ? "mt-2" : "items-center"}`}
    >
      <span
        className={
          "flex-none text-sm w-40 " + (labelColor || "text-gray-750")
        }
      >
        {label}
        {required ? (
          <span className={"text-red-800 ml-1"}>*</span>
        ) : null}
        {tooltip ? (
          <span className={"ml-2"}>
            <Tooltip
              tooltipId={formFieldProps.name}
              title={tooltip.title}
              text={tooltip.text}
            />
          </span>
        ) : null}
      </span>

      <div className="flex flex-1">
        <Field {...formFieldProps} />
      </div>
    </div>
  );
};

export const CostField = props => {
  const {
    options,
    input,
    meta: { touched, error },
  } = props;
  const showError = touched && error;

  return (
    <div className="flex flex-col">
      <div className="flex flex-1 flex-row">
        <div className="w-24">
          <Select
            value={input.value.currency}
            options={options}
            classForBorder={props.classForBorder}
            placeholder="USD"
            onChange={selectedOption =>
              input.onChange({
                ...input.value,
                currency: selectedOption,
              })
            }
          />
        </div>

        <div className="w-2" />

        <div className="flex flex-1">
          <TextInput
            type="number"
            value={input.value.cost}
            placeholder="90.00"
            classForBorder={props.className}
            onChange={event => {
              input.onChange({
                ...input.value,
                cost: event.target.value,
              });
            }}
          />
        </div>
      </div>
      {showError ? (
        <span className="input-field-error-text">{error}</span>
      ) : null}
    </div>
  );
};

export const CostRangeField = props => {
  const {
    options,
    input,
    meta: { touched, error },
  } = props;
  const showError = touched && error;

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex flex-row">
        <div className="w-24">
          <Select
            value={input.value.currency}
            classForBorder={props.classForBorder}
            options={options}
            placeholder="USD"
            onChange={selectedOption =>
              input.onChange({
                ...input.value,
                currency: selectedOption,
              })
            }
          />
        </div>

        <div className="w-2" />

        <div className="flex flex-1">
          <div
            className={`flex flex-row border ${
              showError && error.toLowerCase().includes("min")
                ? "border-red-800"
                : props.classForMinBorder || "border-gray-500"
            } rounded w-full overflow-hidden`}
          >
            <div className="flex-none flex w-12 bg-gray-50 border-r border-gray-500 justify-center items-center">
              <span className="text-xs text-gray-500 leading-none">
                Min
              </span>
            </div>
            <div className="flex-1 flex flex-col">
              <input
                type="number"
                onChange={event => {
                  input.onChange({
                    ...input.value,
                    min: event.target.value,
                  });
                }}
                value={input.value.min || ""}
                className={`input-field-with-label focus:outline-none`}
                placeholder="58.00"
                min="0"
                max={input.value.max ? input.value.max : "0"}
              />
            </div>
          </div>
        </div>

        <div className="w-2" />

        <div className="flex flex-1">
          <div
            className={`flex flex-row border ${
              showError && error.toLowerCase().includes("max")
                ? "border-red-800"
                : props.classForMaxBorder || "border-gray-500"
            } rounded w-full overflow-hidden`}
          >
            <div className="flex-none flex w-12 bg-gray-50 border-r border-gray-500 justify-center items-center">
              <span className="text-xs text-gray-500 leading-none">
                Max
              </span>
            </div>
            <div className="flex-1 flex flex-col">
              <input
                type="number"
                onChange={event => {
                  input.onChange({
                    ...input.value,
                    max: event.target.value,
                  });
                }}
                value={input.value.max || ""}
                className={`input-field-with-label focus:outline-none`}
                placeholder="62.00"
                min={input.value.min ? input.value.min : "0"}
              />
            </div>
          </div>
        </div>
      </div>
      {showError ? (
        <span className="input-field-error-text">{error}</span>
      ) : null}
    </div>
  );
};

const Brochure = ({
  name,
  id,
  size,
  fileName,
  downloadUrl,
  onClickRemove,
}) => (
  <div className="flex-1 flex flex-row text-blue-700 overflow-hidden h-20 border-t border-gray-300">
    <div className="flex-none flex w-10 h-20 justify-center items-center bg-blue-50">
      <FontAwesomeIcon
        icon={["fas", "file-pdf"]}
        className={"text-2xl leading-none"}
      />
    </div>
    <div className="flex-1 flex flex-col ml-3 pt-2 overflow-hidden">
      <p className="text-sm text-black font-bold truncate">{name}</p>
      {id ? (
        <p className="text-sm text-blue-700 font-bold truncate mt-px">
          {"ID"} {id}
        </p>
      ) : null}
      {size ? (
        <p className="text-sm text-blue-700 font-bold truncate mt-px">
          {Format.bytes(size)}
        </p>
      ) : null}
      <p className="text-xs text-blue-700 truncate">{fileName}</p>
    </div>
    {downloadUrl ? (
      <div
        onClick={() => {
          if (downloadUrl) window.open(downloadUrl);
        }}
        className="flex-none flex w-10 h-20 justify-center items-center cursor-pointer mr-3"
      >
        <FontAwesomeIcon
          icon={["fas", "download"]}
          className={"text-lg text-gray-750 leading-none"}
        />
      </div>
    ) : null}
    <div
      onClick={onClickRemove}
      className="flex-none flex w-10 h-20 justify-center items-center cursor-pointer"
    >
      <FontAwesomeIcon
        icon={["fas", "trash-can"]}
        className={"text-lg text-gray-750 leading-none"}
      />
    </div>
  </div>
);

export const BrochureField = props => {
  const {
    existingBrochures,
    showExistingBrochures,
    onPressSelectBrochure,
    input,
    meta: { touched, error },
  } = props;
  const [
    confirmExistingModalVisible,
    setConfirmExistingModalVisibility,
  ] = useState(false);
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisibility] =
    useState(false);
  const brochure_status = input.value?.brochure_status;
  const brochure = input.value?.brochure;

  return (
    <div className="flex flex-1 flex-col overflow-hidden pointer-events-auto">
      <div className="flex-1 flex flex-col overflow-hidden">
        {input.value.brochure_status ===
          BROCHURE_SELECTION_STATUS.EXISTING &&
        input.value &&
        input.value.brochure &&
        input.value.brochure.brochure ? (
          <div className={"max-w-md mb-2"}>
            <Brochure
              name={input.value.brochure.name.split(".")[0]}
              id={input.value.brochure.id}
              fileName={input.value.brochure.name}
              onClickRemove={() =>
                setConfirmDeleteModalVisibility(true)
              }
              downloadUrl={input.value.brochure.brochure}
            />
          </div>
        ) : null}

        {brochure_status ===
          BROCHURE_SELECTION_STATUS.NEWLY_CREATED && brochure ? (
          <div className={"max-w-md mb-2"}>
            {brochure.id ? (
              <Brochure
                name={brochure.name.split(".")[0]}
                id={brochure.id}
                fileName={brochure.name}
                onClickRemove={() =>
                  setConfirmDeleteModalVisibility(true)
                }
                downloadUrl={brochure.brochure}
              />
            ) : (
              <Brochure
                name={
                  brochure.name ? brochure.name.split(".")[0] : ""
                }
                size={brochure.size}
                fileName={brochure.name}
                onClickRemove={() =>
                  setConfirmDeleteModalVisibility(true)
                }
              />
            )}
          </div>
        ) : null}
      </div>
      {touched && error ? (
        <span className="input-field-error-text mt-px">{error}</span>
      ) : null}
      <input
        accept=".pdf"
        name="brochures_input"
        id="brochures_input"
        type="file"
        className={"hidden"}
        onChange={e => {
          const selectedFile = e.target.files[0];

          if (selectedFile.size > MAX_FILE_SIZE) {
            Notification.error(
              "Please upload a file below 25 MB in size",
            );
          } else {
            input.onChange({
              ...input.value,
              brochure: selectedFile,
            });
          }
        }}
      />
    </div>
  );
};

export const RadioBoxMinMax = props => {
  const {
    input,
    items,
    showMinMax,
    meta: { touched, error },
  } = props;
  const selection = input.value.selection;

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex-none flex flex-row items-center">
        {items.map(item => (
          <div
            className="cursor-pointer text-gray-750 text-sm"
            onClick={() => {
              input.onChange({
                ...input.value,
                selection: item.value,
              });
            }}
            key={item.value}
          >
            <FontAwesomeIcon
              icon={
                selection === item.value
                  ? ["fas", "check-circle"]
                  : ["far", "circle"]
              }
              className={
                "text-sm " +
                (selection === item.value ? "text-blue-700" : "")
              }
            />
            <span
              className={
                "ml-2 mr-8 " +
                (selection === item.value && props.className)
              }
            >
              {item.label}
            </span>
          </div>
        ))}
      </div>
      {selection === "required" && showMinMax ? (
        <div className="flex flex-col bg-gray-50 rounded-md self-start p-3 mt-2">
          <div className="flex-none flex flex-row items-center">
            <span className="text-gray-750 text-sm w-64">
              Minimum letters of recommendations
            </span>
            <div className="w-32 ml-5">
              <TextInput
                type="number"
                placeholder="0"
                value={input.value.min}
                classForBorder={props.minCountChange}
                onChange={event => {
                  input.onChange({
                    ...input.value,
                    min: event.target.value,
                  });
                }}
                min="0"
                max={input.value.max ? input.value.max : "0"}
              />
            </div>
          </div>
          <div className="flex-none flex flex-row items-center mt-2">
            <span className="text-gray-750 text-sm w-64">
              Maximum letters of recommendations
            </span>
            <div className="w-32 ml-5">
              <TextInput
                type="number"
                placeholder="0"
                value={input.value.max}
                classForBorder={props.maxCountChange}
                onChange={event => {
                  input.onChange({
                    ...input.value,
                    max: event.target.value,
                  });
                }}
                min={input.value.min ? input.value.min : "0"}
              />
            </div>
          </div>
        </div>
      ) : null}
      {touched && error ? (
        <span className="input-field-error-text flex-none mt-px">
          {error}
        </span>
      ) : null}
    </div>
  );
};
