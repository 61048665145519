import React from 'react';

const TextAreaField = (props) => {
  const {
    input,
    meta: { error, touched },
    error: oldError,
    maxLength,
    showRemainingLimit,
    ...rest
  } = props;
  const showError = touched && error;

  return (
    <div className="flex flex-col flex-1">
      <textarea
        className={`text-area-field ${showError? 'input-field-error' : ''} ${props.classForBorder}`}
        {...input}
        {...rest}
      />
      {showRemainingLimit || showError ? (
        <div className={`flex flex-row mt-1 ${showError ? "justify-between" : "justify-end"}`}>
          {
            showError ? (
              <span className="input-field-error-text">
                {error}
              </span>
            ) : null
          }
          {
            showRemainingLimit ? (
              <span className={`${input.value.length > maxLength ? "text-red-800" : "text-gray-750"} text-xs font-regular`}><span className="font-bold">{maxLength - input.value.length}</span> characters left</span>
            ) : null
          }
        </div>
      ) : null}
    </div>
  );
}

TextAreaField.defaultProps = {
  maxLength: 9999,
}

export default TextAreaField;
