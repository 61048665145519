export const APPLICATION_STATUS = {
  toReview: "To Review",
  Withdrawn: "Withdrawn",
};

export const APPLICATION_TYPE = {
  UCAS: "UCAS",
};

export const APPLICATION_SECTION = {
  UCAS_ACCOUNT: "ucas_account",
  APP_FORM: "application_form",
  EDUCATION: "education",
  PERSONAL_STATEMENT: "personal_statement",
  CHOICES: "choices",
  TAGGING: "tagging",
};

export const FIELD_TYPE = {
  singleUpload: "single_file_upload",
  multipleUpload: "multiple_file_upload",
  list: "list",
  tabulation: "tabulation",
};
