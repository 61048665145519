import React from "react";
import { resolveValueSelector, getTooltipAttrs } from "../helpers";

export const VerticalTwoValueColumnBlack = props => {
  const { rowData, valueSelector, tooltip } = props;
  const { topValue, bottomValue, icon } = resolveValueSelector(
    rowData,
    valueSelector,
  );
  const topTooltipAttrs = getTooltipAttrs(topValue, tooltip);
  const bottomTooltipAttrs = getTooltipAttrs(bottomValue, tooltip);

  return (
    <div className={"flex-1 flex flex-row overflow-hidden"}>
      {icon ? <div className={"mr-2"}>{icon}</div> : null}
      <div
        className={
          "flex-1 flex flex-col overflow-hidden justify-center"
        }
      >
        <span
          className="text-xs font-bold text-gray-750 truncate"
          {...topTooltipAttrs}
        >
          {topValue}
        </span>
        <span
          className="text-xs font-normal text-gray-750 truncate mt-2px"
          {...bottomTooltipAttrs}
        >
          {bottomValue}
        </span>
      </div>
    </div>
  );
};
