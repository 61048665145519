import React from "react";
import { resolveValueSelector, getTooltipAttrs } from "../helpers";

export const VerticalTwoValueColumnBlue = props => {
  const { rowData, valueSelector, tooltip } = props;
  const { topValue, bottomValue, icon } = resolveValueSelector(
    rowData,
    valueSelector,
  );
  const topTooltipAttrs = getTooltipAttrs(topValue, tooltip);
  const bottomTooltipAttrs = getTooltipAttrs(bottomValue, tooltip);

  return (
    <div className="flex flex-row">
      {icon ? <div className={"mr-2"}>{icon}</div> : null}
      <div className="flex flex-col overflow-hidden justify-center">
        <span
          className="text-xs font-bold text-blue-700 truncate"
          {...topTooltipAttrs}
        >
          {topValue}
        </span>
        <span
          className={`text-xs text-gray-700 leading-none truncate mt-3px`}
          {...bottomTooltipAttrs}
        >
          {bottomValue}
        </span>
      </div>
    </div>
  );
};
