import React, { Component } from "react";
import { TextInputField } from "../../form-components";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import validator from "validator";
import { withTranslation } from "react-i18next";

class LoginForm extends Component {
  render() {
    const { handleSubmit, onSubmit } = this.props;

    return (
      <form
        className="w-30per bg-white flex flex-col items-center z-10 shadow-xl h-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="relative mt-16">
          <div
            style={{
              width: "254px",
              height: "62px",
              background: "url(/images/login_logo.png)",
            }}
            className="logo my-3"
          ></div>
        </div>

        <div className="p-6 w-full">
          <div className={"flex flex-col mt-4"}>
            <div className={"text-gray-750 font-light"}>Email</div>
            <Field
              className="mt-2"
              name="email"
              component={TextInputField}
              type="text"
              placeholder="Email"
            />
          </div>
          <div className={"flex flex-col mt-8"}>
            <div className={"text-gray-750 font-light"}>Password</div>
            <Field
              className="mt-2"
              name="password"
              component={TextInputField}
              type="password"
              placeholder="Password"
            />
          </div>
          <div className="flex flex-row items-center justify-between mt-5">
            <button className="w-64 px-2 py-2 cursor-pointer flex items-center justify-center rounded h-36px text-white bg-blue-700">
              Sign In
            </button>

            <Link to="/forgot-password">
              <span className="font-bold text-sm text-blue-700" href="#">
                Forgot password?
              </span>
            </Link>
          </div>
        </div>
      </form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  const { email, password } = values;
  const { t } = props;

  if (!email) errors["email"] = `Email ${t("validation.error.required")}`;
  else if (!validator.isEmail(email))
    errors["email"] = t("validation.error.email_invalid");

  if (!password)
    errors["password"] = `Password ${t("validation.error.required")}`;

  return errors;
};

export default withTranslation()(
  reduxForm({
    form: "loginForm",
    validate: validate,
  })(LoginForm)
);
