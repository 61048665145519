import React, { Component } from "react";
import { TextInputField } from "../../form-components";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";

class ResetPasswordForm extends Component {
  render() {
    const { handleSubmit, onSubmit } = this.props;

    return (
      <form
        className="w-30per bg-white flex flex-col items-center z-10 shadow-xl h-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="relative mt-16">
          <div
            style={{
              width: "254px",
              height: "62px",
              background: "url(/images/login_logo.png)",
            }}
            className="logo my-3"
          ></div>
        </div>

        <div className="p-6 w-full">
          <div className={"flex flex-col mt-4"}>
            <div className={"text-gray-750 font-light"}>Password</div>
            <Field
              name="password"
              component={TextInputField}
              type={"password"}
              placeholder={"Password"}
            />
          </div>
          <div className={"flex flex-col mt-8"}>
            <div className={"text-gray-750 font-light"}>Confirm Password</div>
            <Field
              name="confirm_password"
              component={TextInputField}
              type={"password"}
              placeholder={"Confirm Password"}
            />
          </div>
          <div className="flex flex-row mt-5">
            <button className="w-64 px-2 py-2 cursor-pointer flex items-center justify-center rounded h-36px text-white bg-blue-700">
              Reset Password
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  const { password, confirm_password } = values;
  const { t } = props;

  if (!password)
    errors["password"] = `Password ${t("validation.error.required")}`;

  if (!confirm_password)
    errors["confirm_password"] = `Password ${t("validation.error.required")}`;

  if (password && confirm_password && password !== confirm_password)
    errors["confirm_password"] = "Passwords do not match";

  return errors;
};

export default withTranslation()(
  reduxForm({
    form: "resetPasswordForm",
    validate: validate,
  })(ResetPasswordForm)
);
