import React from "react";

const ImageQuoteComponent = (props) => {
  const {
    bgColor,
    bgImage,
    quoteTitle,
    designation,
    quoteText,
    designationwidth,
  } = props;

  return (
    <div className="flex flex-col items-center" style={{ height: "400px" }}>
      <div
        className="circle relative"
        style={{ height: "160px", width: "160px", backgroundColor: bgColor }}
      >
        <div
          className="absolute"
          style={{
            backgroundImage: "url(/images/" + bgImage + ")",
            height: "200px",
            width: "215px",
            backgroundPosition: "center",
            backgroundSize: "cover",
            left: "-27px",
            bottom: "-2px",
          }}
        ></div>
      </div>
      <div className="text-dark-blue-800 text-lg font-bold mt-5 text-center">
        {quoteTitle}
      </div>
      <div
        className={`text-gray-750 text-baseH3 mt-2 text-center ${designationwidth}`}
      >
        {designation}
      </div>
      <div className="text-black mt-4 text-center w-180px">{quoteText}</div>
    </div>
  );
};

export default ImageQuoteComponent;
