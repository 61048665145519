import { persistCombineReducers } from "redux-persist";
import { reducer as formReducer } from "redux-form";
import storage from "redux-persist/es/storage";
import clientsReducer from "./clients.reducer";
import directApplyReducer from "./direct-apply.reducer";
import UcasApplicationFormReducer from "./ucas-application-form.reducer";
import directApplyProgramReducer from "./direct-apply-program.reducer";
import directApplyFiltersReducer from "./direct-apply-filters.reducer";
import partnersReducer from "./partners.reducer";
import newPartnersReducer from "./new-partners.reducer";
import newPartnersFiltersReducer from "./new-partners-filters.reducer";
import partnerFeaturesReducer from "./partner-features.reducer";
import partnerFilterReducer from "./partner-filters.reducer";
import universitiesReducer from "./universities.reducer";
import userReducer from "./user.reducer";
import usersReducer from "./users.reducer";
import studentsReducer from "./students.reducer";
import teamReducer from "./team.reducer";
import miscReducer from "./misc.reducer";
import districtsReducer from "./districts.reducer";

const config = {
  key: "cialfo-explore-admin",
  storage,
  blacklist: [
    "form",
    "partners",
    "universities",
    "users",
    "directApply",
    "team",
    "students",
  ],
};

const rootReducer = persistCombineReducers(config, {
  clients: clientsReducer,
  directApply: directApplyReducer,
  ucasApplicationForm: UcasApplicationFormReducer,
  directApplyListState: directApplyFiltersReducer,
  directApplyProgram: directApplyProgramReducer,
  form: formReducer,
  partnerSearchState: partnerFilterReducer,
  newPartnersSearchState: newPartnersFiltersReducer,
  partners: partnersReducer,
  newPartners: newPartnersReducer,
  partnerFeatures: partnerFeaturesReducer,
  universities: universitiesReducer,
  user: userReducer,
  users: usersReducer,
  students: studentsReducer,
  team: teamReducer,
  misc: miscReducer,
  districts: districtsReducer,
});

export default rootReducer;
