import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  clearUsers:              null,
  getUsersFailure:         null,
  getUsersRequest:         ['params', 'resolve', 'reject'],
  getUsersSuccess:         ['usersData', 'params'],
  
  updateUsersFailure:       null,
  updateUsersRequest:       ['params', 'resolve', 'reject'],
  updateUsersSuccess:       null,
});

export const UsersTypes = Types;
export const UsersCreators = Creators;
