import React, { useEffect, useState } from "react";
import { ScrollSync } from "react-virtualized";
import "react-virtualized/styles.css";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import InfiniteLoader from "react-virtualized/dist/commonjs/InfiniteLoader";
import Loader from "react-loader-spinner";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VirtualizedTable } from "src/components";
import { Format } from "src/helpers";

const sortableColumns = {
  name: "name",
  email: "email",
  phone: "phone",
  status: "status",
  type: "type",
  added: "added",
  last_login: "last_login",
};
const CounselorsScreen = props => {
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [prevParams, setPrevParams] = useState({});

  useEffect(() => {
    load();
  }, []);

  const load = loadMore => {
    const params = {
      search_text: "",
      search_email: "",
      sort_params: [
        {
          sort_by: sortableColumns[sortColumn],
          sort_direction: sortDirection,
        },
      ],
    };

    if (loadMore === true || !_.isEqual(params, prevParams)) {
      if (!loadMore) setPrevParams(params);

      return new Promise((resolve, reject) => {
        props.getCounselors(params, resolve, reject);
      });
    } else {
      return new Promise(function (resolve) {
        resolve();
      });
    }
  };

  const loadMore = () => {
    return load(true);
  };

  const onClickHeader = ({ dataKey }) => {
    setSortColumn(dataKey);
    setSortDirection(
      sortColumn === dataKey
        ? sortDirection === "desc"
          ? "asc"
          : "desc"
        : "asc",
    );
  };

  const rowClassName = ({ index }) => {
    if (index < 0) {
      return "";
    } else {
      return "app-table-row";
    }
  };

  const getWidth = () => {
    const width = window.screen.width;

    const scrollableWidth = (width - 104) * 0.8;
    if (scrollableWidth > 1100) {
      return scrollableWidth;
    }
    return 1100;
  };

  const { counselors, matched, total, isNextPageLoading } = props;
  const hasNextPage = matched > counselors.length;
  const isRowLoaded = ({ index }) =>
    !hasNextPage || index < counselors.length;

  const loadMoreRows = isNextPageLoading ? () => {} : loadMore;

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <ScrollSync>
        {({ onScroll, scrollLeft, scrollTop }) => (
          <div
            className="flex"
            style={{ display: "flex", height: "100%" }}
          >
            <div className="table-container" style={{ width: "20%" }}>
              <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={() => {}}
                rowCount={total}
              >
                {({ onRowsRendered, registerChild }) => {
                  return (
                    <AutoSizer>
                      {({ width, height }) => (
                        <>
                          <VirtualizedTable
                            isRowLoaded={isRowLoaded}
                            totalRowCount={total}
                            rows={counselors}
                            currentRowCount={counselors.length}
                            headerHeight={46}
                            rowHeight={62}
                            rowClassName={index => {
                              return `${rowClassName(index)} border-r`;
                            }}
                            sortableColumns={sortableColumns}
                            sortColumn={sortColumn}
                            sortDirection={sortDirection}
                            onRowsRendered={onRowsRendered}
                            registerChild={registerChild}
                            width={width}
                            height={height}
                            onScroll={onScroll}
                            scrollTop={scrollTop}
                            scrollLeft={scrollLeft}
                            gridStyle={{ "scrollbar-width": "none" }}
                            noRowsRenderer={() => {
                              return isNextPageLoading ? (
                                <div className="flex flex-col justify-center items-center  h-full w-full">
                                  <Loader
                                    type="Oval"
                                    color="grey"
                                    height={50}
                                    width={50}
                                  />
                                </div>
                              ) : null;
                            }}
                            columns={[
                              {
                                dataKey: "name",
                                width: 300,
                                type: "Default",
                                disableSort: true,
                                tooltip: true,
                                valueSelector: rowData => ({
                                  value: rowData.full_name,
                                }),
                                header: {
                                  title: "Name",
                                  onClick: onClickHeader,
                                },
                              },
                            ]}
                          ></VirtualizedTable>
                        </>
                      )}
                    </AutoSizer>
                  );
                }}
              </InfiniteLoader>
            </div>
            <div
              className="overflow-scroll table-container"
              style={{ width: "80%" }}
            >
              <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={loadMoreRows}
                rowCount={total}
              >
                {({ onRowsRendered, registerChild }) => {
                  return (
                    <AutoSizer>
                      {({ width, height }) => (
                        <VirtualizedTable
                          isRowLoaded={isRowLoaded}
                          totalRowCount={total}
                          rows={counselors}
                          currentRowCount={counselors.length}
                          headerHeight={46}
                          rowHeight={62}
                          rowClassName={rowClassName}
                          headerClassName="border-l"
                          sortableColumns={sortableColumns}
                          sortColumn={sortColumn}
                          sortDirection={sortDirection}
                          onRowsRendered={onRowsRendered}
                          registerChild={registerChild}
                          width={getWidth()}
                          height={height}
                          onScroll={onScroll}
                          scrollTop={scrollTop}
                          scrollLeft={scrollLeft}
                          noRowsRenderer={() => {
                            return isNextPageLoading ? (
                              <div className="flex flex-col justify-center items-center  h-full w-full">
                                <Loader
                                  type="Oval"
                                  color="grey"
                                  height={50}
                                  width={50}
                                />
                              </div>
                            ) : null;
                          }}
                          columns={[
                            {
                              dataKey: "email",
                              width: 300,
                              type: "Default",
                              disableSort: true,
                              tooltip: true,
                              valueSelector: rowData => ({
                                value: rowData.email,
                              }),
                              header: {
                                title: "Email",
                                onClick: onClickHeader,
                              },
                            },

                            {
                              dataKey: "phone",
                              width: 150,
                              type: "Default",
                              disableSort: true,
                              tooltip: true,

                              valueSelector: rowData => ({
                                value: rowData.mobile,
                              }),
                              header: {
                                title: "Phone",
                                onClick: onClickHeader,
                              },
                            },
                            {
                              dataKey: "status",
                              width: 100,
                              type: "Default",
                              disableSort: true,
                              valueSelector: rowData => ({
                                value: rowData.is_active.toString(),
                              }),
                              header: {
                                title: "Status",
                                onClick: onClickHeader,
                              },
                            },
                            {
                              dataKey: "type",
                              width: 250,
                              type: "Default",
                              disableSort: true,
                              valueSelector: rowData => ({
                                value: rowData.user_type.join(" | "),
                              }),
                              header: {
                                title: "Type",
                                onClick: onClickHeader,
                              },
                            },

                            {
                              dataKey: "added",
                              width: 100,
                              type: "Default",
                              disableSort: true,
                              valueSelector: rowData => ({
                                value: Format.date(
                                  rowData.created_at,
                                ),
                              }),
                              header: {
                                title: "Added",
                                onClick: onClickHeader,
                              },
                            },
                            {
                              dataKey: "last_login",
                              width: 100,
                              type: "Default",
                              disableSort: true,
                              valueSelector: rowData => ({
                                value: Format.date(
                                  rowData.last_login,
                                ),
                              }),
                              header: {
                                title: "Last login",
                                onClick: onClickHeader,
                              },
                            },
                            {
                              dataKey: "actions",
                              width: 100,
                              type: "Default",
                              disableSort: true,
                              tooltip: true,

                              cellRenderer: ({
                                rowData,
                                cellData,
                              }) => {
                                return (
                                  <button className="btn-secondary pl-1 pr-1">
                                    <FontAwesomeIcon
                                      icon={["fas", "user-secret"]}
                                      className={
                                        "pr-2 text-gray-750 leading-none"
                                      }
                                    />
                                    Login
                                  </button>
                                );
                              },
                              header: {
                                title: "Actions",
                                onClick: onClickHeader,
                              },
                            },
                          ]}
                        ></VirtualizedTable>
                      )}
                    </AutoSizer>
                  );
                }}
              </InfiniteLoader>
            </div>
          </div>
        )}
      </ScrollSync>
      {isNextPageLoading && counselors.length > 0 ? (
        <div
          className={
            "absolute bottom-0 self-center pointer-events-none flex-none flex flex-col justify-center items-center p-1"
          }
        >
          <Loader
            type="ThreeDots"
            color="grey"
            height={75}
            width={75}
          />
        </div>
      ) : null}
    </div>
  );
};

export default withTranslation()(withRouter(CounselorsScreen));
