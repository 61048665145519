import Information, { validate } from "./information";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import { DirectApplyProgramCreators } from "../../../../redux/actions";
import { snakeCase } from "lodash";

function mapStateToProps(state) {
  const {
    directApplyProgram: {
      programTypes,
      currencies,
      universities,
      documentTypes,
      brochures,
      intakeRounds,
      isUpdating,
      isSavingDraft,
      program: {
        id,
        documents,
        status,
        banner,
        program_partnerships,
        program_intakes,
      },
    },
    user: {
      data: { institute_type },
    },
    misc: { countries },
  } = state;

  return {
    id,
    status: snakeCase(status),
    programTypeOptions: programTypes.map(programType => ({
      value: programType.id,
      label: programType.name,
    })),
    currencyOptions: currencies.map(currency => ({
      value: currency.id,
      label: currency.name,
    })),
    intakeOptions: intakeRounds.map(round => ({
      value: round.name,
      label: round.name,
    })),
    universityOptions: universities.map(university => ({
      value: university.id,
      label: university.name,
    })),
    editProgramFormValues: state.form?.programInformationForm?.values,
    isUniversity: institute_type === "University",
    isApplicationClient: institute_type === "ApplicationClient",
    documentTypes,
    documents,
    isUpdating,
    isSavingDraft,
    brochures,
    countries,
    banner,
    program_partnerships,
    program_intakes,
  };
}

const mapDispatchToProps = {
  updateProgram: DirectApplyProgramCreators.updateProgramRequest,
  saveProgramDraft:
    DirectApplyProgramCreators.saveProgramDraftRequest,
};

export default withTranslation(null, { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
  })(
    reduxForm({
      form: "programInformationForm",
      validate,
      enableReinitialize: true,
    })(Information),
  ),
);
