import React, { Component } from "react";
import { Notification } from "../../helpers";
import EditUserForm from "./edit-user-form";
import { pick } from "lodash";
import Loader from "react-loader-spinner";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TeamTypes } from "../../redux/actions";
import EventBus from "eventing-bus";

class EditUserScreen extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this._subscribeEvents();
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _subscribeEvents = () => {
    this.updateRequestSubscription = EventBus.on(
      TeamTypes.UPDATE_TEAM_MEMBER_REQUEST,
      () => this.setState({ loading: true })
    );
    this.updateSuccessSubscription = EventBus.on(
      TeamTypes.UPDATE_TEAM_MEMBER_SUCCESS,
      () =>
        this.setState({ loading: false }, this._teamMemberCreatedSuccessfully)
    );
    this.updateFailureSubscription = EventBus.on(
      TeamTypes.UPDATE_TEAM_MEMBER_FAILURE,
      () => this.setState({ loading: false })
    );
  };

  _unsubscribeEvents = () => {
    this.updateRequestSubscription();
    this.updateSuccessSubscription();
    this.updateFailureSubscription();
  };

  _teamMemberCreatedSuccessfully = () => {
    const { t } = this.props;
    Notification.success(t("alerts.success.team_member_updated"));
    this._closeScreen();
  };

  _closeScreen = () => {
    this.props.closeButtonPressed && this.props.closeButtonPressed();
  };

  _backToViewScreen = () => {
    this.props.onPressBackButton && this.props.onPressBackButton();
  };

  _onSubmit = (values) => {
    const { first_name, last_name, email, role, is_active } = values;
    const { updateTeamMember, teamMember } = this.props;

    const params = new FormData();

    params.append("id", teamMember.id);
    params.append("admin[first_name]", first_name);
    params.append("admin[last_name]", last_name);
    params.append("admin[email]", email);
    params.append("admin[is_active]", is_active === "1");
    params.append("admin[role_id]", role);

    return new Promise(function (resolve, reject) {
      updateTeamMember(params, resolve, reject);
    });
  };

  render() {
    const { loading } = this.state;
    const { t, teamMember, isAdmin } = this.props;

    const initialValues = {
      ...pick(teamMember, ["first_name", "last_name", "email"]),
      role: String(teamMember.role.name),
      is_active: teamMember.is_active ? "1" : "0",
    };

    return (
      <div className="h-screen flex flex-col side-screen bg-white border border-gray-300 overflow-hidden">
        <header className="flex-none flex flex-row h-20 p-4 border-b border-gray-300 items-center">
          <div className="ml-1 flex-1 flex flex-col">
            <span className="text-lg font-bold text-gray-750">
              {teamMember.first_name} {teamMember.last_name}
            </span>
          </div>
          <div
            onClick={this._closeScreen}
            className="flex flex-col justify-center cursor-pointer"
          >
            <FontAwesomeIcon icon={["fas", "times"]} className="text-xl" />
          </div>
        </header>

        <EditUserForm
          showStatusAndRole={isAdmin}
          onPressBackButton={this._backToViewScreen}
          backButtonText={t("common.buttons.back_to_overview")}
          submitButtonText={t("common.buttons.save_changes")}
          initialValues={initialValues}
          disableEmail={true}
          onSubmit={this._onSubmit}
        />
        {loading ? (
          <div className="flex justify-center items-center z-20 absolute inset-0 bg-gray-200">
            <Loader type="Oval" color="grey" height={50} width={50} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(EditUserScreen);
