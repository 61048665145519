import React, { useEffect } from "react";
import Dialog from "src/components/dialog/dialog";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import EventBus from "eventing-bus";
import { Notification } from "../../../helpers";
import { DirectApplyCreators } from "../../../redux/actions";

const UndoSubmissionDialog = ({
  id,
  visible,
  onClose,
  unsubmitApplication,
  getApplication,
}) => {
  useEffect(() => {
    const submitApplicationSubscription = EventBus.on(
      DirectApplyCreators.unsubmitApplicationSuccess().type,
      unsubmitApplicationSuccess,
    );
    return () => {
      submitApplicationSubscription();
    };
  }, []);

  const unsubmitApplicationSuccess = () => {
    getApplication(id);
    onClose();
    Notification.success("Undo submission successfully");
  };

  return (
    <Dialog
      isModalVisible={visible}
      body={
        <>
          <span className="text-base text-gray-1000 pb-2">
            By clicking on 'Undo Submission', the application will be
            reverted back to student for revision.
          </span>
        </>
      }
      title="Undo Submission"
      okButtonText="Undo Submit"
      okButtonType="contain"
      okButtonVariant="error"
      cancelButtonText="Cancel"
      cancelButtonVariant="secondary"
      cancelButtonType="outline"
      handleCancel={() => onClose()}
      handleCloseIcon={() => onClose()}
      handleOk={() => unsubmitApplication(id)}
    />
  );
};

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  unsubmitApplication: DirectApplyCreators.unsubmitApplicationRequest,
  getApplication: DirectApplyCreators.getApplicationRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(UndoSubmissionDialog));
