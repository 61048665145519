import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getUniversitiesFailure:     null,
  getUniversitiesRequest:     ['searchText'],
  getUniversitiesSuccess:     ['univerities'],

  getUniversityRepresentativesFailure:     null,
  getUniversityRepresentativesRequest:     ['universityId'],
  getUniversityRepresentativesSuccess:     ['representatives'],
});

export const UniversitiesTypes = Types;
export const UniversitiesCreators = Creators;
