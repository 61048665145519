import StudentsPage from "./students";
import { connect } from "react-redux";
import { StudentsCreators } from "../../redux/actions";

function mapStateToProps(state) {
  return {
    students: state.students.data,
    total: state.students.total,
    matched: state.students.matched,
    isNextPageLoading: state.students.loading,
    sso_url: state.students.data,
  };
}

const mapDispatchToProps = {
  getStudents: StudentsCreators.getStudentsRequest,
  getStudentEmail: StudentsCreators.getStudentEmailRequest,
  createUcasAccount: StudentsCreators.createUcasAccountRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentsPage);
