import { createReducer } from "reduxsauce";
import { DistrictsTypes as Types } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.districts;

export const getDistrictsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getDistrictsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getDistrictsSuccess = (state, action) => {
  const {
    districtsData: { districts, matched, total },
    params,
  } = action;

  return {
    ...state,
    data:
      params.page_no === 1
        ? districts
        : [...state.data, ...districts],
    matched,
    total,
    params: { ...state.params, ...params },
    loading: false,
  };
};

export const HANDLERS = {
  [Types.GET_DISTRICTS_FAILURE]: getDistrictsFailure,
  [Types.GET_DISTRICTS_REQUEST]: getDistrictsRequest,
  [Types.GET_DISTRICTS_SUCCESS]: getDistrictsSuccess,
};

export default createReducer(INITIAL_STATE, HANDLERS);
