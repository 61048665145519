import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import Loader from "react-loader-spinner";
import { Select, TextArea, TextInput } from "../../../components";
import { withTranslation } from "react-i18next";
import { Alert } from "../../../helpers";
import { useSelector } from "react-redux";

const RefundModal = (props) => {
  const {
    visible,
    onClose,
    t,
    transactionId,
    refundRequest,
    transactionName,
    clearData,
    refreshTransactions,
  } = props;
  const [refundObject, setRefundObject] = useState({
    selectedReason: null,
    declineNote: "",
    amount: 0,
    selectedReasonObject: {},
    transactionId: "",
  });

  const activities = useSelector((state) => state.directApply.refundActivities);

  const [enabled, setEnabled] = useState(false);
  const [amountValid, setAmountValid] = useState(false);
  const [amountError, setAmountError] = useState("");
  const [reasonValid, setReasonValid] = useState(false);
  const [reasonError, setReasonError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    if (activities.error) {
      onClose();
      clearData();
      Alert.error(activities.error, {
        customFields: {
          title: "Error with Refund",
        },
      });
    }
    if (activities?.activities?.length > 0) {
      onClose();
      clearData();
      refreshTransactions(true);
      Alert.success("", {
        customFields: {
          title: "Refund has been successfully submitted!",
        },
      });
    }
    setRefundObject({
      ...refundObject,
      selectedReason: "",
      selectedReasonObject: [],
    });
    setAmountError("");
    setReasonError("");
    setAmountValid(false);
    setReasonValid(false);
  }, [activities]);

  useEffect(() => {
    clearData();
    return () => {
      clearData();
    };
  }, []);

  useEffect(() => {
    setRefundObject({ ...refundObject, transactionId: transactionId });
  }, [transactionId]);

  useEffect(() => {
    if (refundObject.amount > 0 && refundObject.selectedReason) {
      setEnabled(true);
    }
  }, [refundObject]);

  function _setRefund() {
    if (amountValid && reasonValid) {
      setLoading(true);
      refundRequest(transactionId, refundObject);
    }
    if (!amountValid) {
      setAmountError("Please enter a valid amount");
      setAmountValid(false);
    }
    if (!reasonValid) {
      setReasonError("Please select a reason");
      setReasonValid(false);
    }
  }

  function handleAmountChange({ target }) {
    const match = target.value.match(/^\+?[1-9]\d*$/);
    const value = target.value;

    if (match && parseFloat(value) > 0) {
      setRefundObject({ ...refundObject, amount: target.value });
      setAmountValid(true);
      setAmountError("");
    } else {
      setAmountError("Please enter a valid amount");
      setAmountValid(false);
    }
  }

  function handleReasonChange(selectedReason) {
    if (selectedReason.value) {
      setRefundObject({
        ...refundObject,
        selectedReason: selectedReason.value,
        selectedReasonObject: selectedReason,
      });
      setReasonValid(true);
      setReasonError("");
    } else {
      setReasonValid(false);
      setReasonError("Please select a reason");
    }
  }

  function cancelRefund() {
    setRefundObject({
      ...refundObject,
      selectedReason: "",
      selectedReasonObject: [],
    });
    setAmountError("");
    setReasonError("");
    setAmountValid(false);
    setReasonValid(false);
    onClose();
  }

  return (
    <Modal
      isOpen={visible}
      style={{
        content: {
          width: "600px",
          margin: "200px auto",
          outline: "none",
        },
      }}
      contentLabel="Example Modal"
      overlayClassName="modal-overlay"
      className="modal-content"
    >
      <div className="bg-white px-5 py-5 rounded-md">
        <div className="flex justify-between">
          <span className="font-bold text-base">
            {t("transaction_refund.header.title")}
          </span>
          <div
            className="cursor-pointer"
            onClick={() => {
              cancelRefund();
            }}
          >
            <FontAwesomeIcon icon="times" className="text-xl" />
          </div>
        </div>
        <div className="flex flex-col mt-5 w-full">
          <div className="flex flex-row bg-yellow-50 p-4">
            <FontAwesomeIcon
              icon={["fas", "exclamation-triangle"]}
              className="text-lg text-yellow-800 mr-2"
            />
            <p className="text-sm text-black">{`You are about to refund to ${transactionName}. This action is irreversible.`}</p>
          </div>
          <div className="flex items-center mt-3">
            <div style={{ width: 150 }} className="">
              <p className="text-sm text-gray-750">
                {t("transaction_refund.labels.amount")}
              </p>
            </div>

            <div className="w-40">
              <TextInput
                placeholder={"0"}
                onChange={(event) => handleAmountChange(event)}
                rightIcon={
                  <div
                    className={
                      "flex w-16 bg-gray-50 border-l border-gray-300 justify-center items-center"
                    }
                  >
                    <FontAwesomeIcon
                      icon={["fas", "dollar-sign"]}
                      className="text-lg text-gray-500"
                    />
                  </div>
                }
                error={amountValid === false ? amountError : ""}
              />
            </div>
          </div>

          <div className="flex items-center mt-3">
            <div style={{ width: 150 }} className="">
              <p className="text-sm text-gray-750">
                {t("transaction_refund.labels.reason")}
              </p>
            </div>

            <div className="flex-1">
              <Select
                options={[
                  { label: "Duplicate Payment", value: "Duplicate Payment" },
                  {
                    label: "Application Rejected",
                    value: "Application Rejected",
                  },
                  {
                    label: "Requested by Customer",
                    value: "Requested by Customer",
                  },
                  { label: "Fraudulent", value: "Fraudulent" },
                  { label: "Other", value: "Other" },
                ]}
                onChange={(selectedReason) =>
                  handleReasonChange(selectedReason)
                }
                value={refundObject.selectedReasonObject}
                error={reasonValid === false ? reasonError : ""}
              />
            </div>
          </div>

          <div className="flex mt-3">
            <div style={{ width: 150 }} className="mt-2">
              <p className="text-sm text-gray-750">
                {t("transaction_refund.labels.note")}
              </p>
            </div>

            <div className="flex-1">
              <TextArea
                onChange={(event) =>
                  setRefundObject({
                    ...refundObject,
                    declineNote: event.target.value,
                  })
                }
                required
              />
            </div>
          </div>

          <div className="flex flex-row justify-end mt-4">
            <button
              className="btn-secondary"
              onClick={() => {
                cancelRefund();
              }}
            >
              Cancel
            </button>
            <div className={"w-4"} />
            <button
              className={`btn-primary ${enabled} ? '' : 'disabled'`}
              onClick={() => _setRefund()}
            >
              Refund
            </button>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center z-20 absolute inset-0 bg-gray-200">
          <Loader type="Oval" color="grey" height={50} width={50} />
        </div>
      ) : null}
    </Modal>
  );
};

RefundModal.defaultProps = {
  onClose: () => {},
};

export default withTranslation()(RefundModal);
