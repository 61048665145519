import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CompleteAutomation from "./complete-automation";
import FillFormCTA from "./fill-form-cta";
import ShowLastStatus from "../show-last-status";

const FillForm = ({ t, ucasQuickSubmit, isUCASAccountCreated }) => {
  return (
    <div
      style={{ width: "25%" }}
      className=" flex flex-col border rounded bg-white mx-2 relative"
    >
      <div className="border-b text-base font-bold p-4 text-center truncate">
        {t(
          "direct_apply_apps.quick_submit.fill_application_form.title",
        )}
      </div>
      <div className="flex flex-col items-center p-4">
        <CompleteAutomation
          isUCASAccountCreated={isUCASAccountCreated}
        />
        <FillFormCTA isUCASAccountCreated={isUCASAccountCreated} />
        <div className="mt-6" />
        <ShowLastStatus
          time={ucasQuickSubmit?.application_form_completed_at}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    ucasQuickSubmit: state.directApply.ucasQuickSubmit,
  };
}

export default withTranslation()(connect(mapStateToProps)(FillForm));
