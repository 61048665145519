import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  authenticationFailure:            null,
  authenticationRequest:            ['params', 'resolve', 'reject'],
  authenticationSuccess:            ['response'],

  resetPasswordFailure:             null,
  resetPasswordRequest:             ['params'],
  resetPasswordSuccess:             null,

  checkSessionFailure:              null,
  checkSessionRequest:              null,
  checkSessionSuccess:              ['response'],

  sendResetPasswordMailFailure:     null,
  sendResetPasswordMailRequest:     ['params'],
  sendResetPasswordMailSuccess:     null,
  
  logout: null,
});

export const UserTypes = Types;
export const UserCreators = Creators;