import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  clearNewPartnersFilters: null,

  setNewPartnersFilters: ["filters"],

  setNewPartnersSearchText: ["text"],
});

export const NewPartnersFiltersTypes = Types;
export const NewPartnersFiltersCreators = Creators;
