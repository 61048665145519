import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "antd";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { isEmpty } from "lodash";
import EventBus from "eventing-bus";
import PDF from "react-pdf-js-infinite";
import {
  UcasApplicationFormCreators,
  UcasApplicationFormTypes,
} from "../../../redux/actions";
import { Format } from "../../../helpers";

const REVIEW_MANUALLY = "Review manually";
const APPLICATION_DATA = "application_data";
const UCAS_DATA = "ucas_data";
const EDUCATION = "Education";
const CHOICES = "Choices";
const UNIVERSITY = "University";
const IGNORE = "ignore";

const isManualReview = (sectionName) =>
  sectionName === "UCAS Account & Nomination";

const calculateDiffernceCount = (sectionDetails) => {
  const newSectionDetail = Array.isArray(sectionDetails)
    ? sectionDetails
    : [sectionDetails];

  return newSectionDetail?.reduce(
    (accumulatedDifferenceCount, sectionDetail) => {
      if (sectionDetail && typeof sectionDetail === "object") {
        const values = Object.values(sectionDetail);

        const filteredValues = values?.filter(
          ({ application_data, ucas_data, ignore }) =>
            application_data !== ucas_data && ignore === ""
        );

        const differenceCount = filteredValues ? filteredValues.length : 0;

        return accumulatedDifferenceCount + differenceCount;
      }
      return accumulatedDifferenceCount;
    },
    0
  );
};

const calculateDiffernceCountFromCode = (sectionDetails) => {
  const newSectionDetail = Array.isArray(sectionDetails)
    ? sectionDetails
    : [sectionDetails];

  return newSectionDetail?.reduce(
    (accumulatedDifferenceCount, sectionDetail) => {
      const values = Object.values(sectionDetail);

      const filteredValues = values.filter(
        ({ application_uni_code, ucas_uni_code }) =>
          application_uni_code !== ucas_uni_code
      );

      const differenceCount = filteredValues.length;

      return accumulatedDifferenceCount + differenceCount;
    },
    0
  );
};

const calculateEducationSectionDifferences = (data, field1, field2, field3) => {
  let difference = 0;

  function recursiveCalculate(obj) {
    if (Array.isArray(obj)) {
      for (let item of obj) {
        recursiveCalculate(item);
      }
    } else if (typeof obj === "object") {
      if (obj?.[field1] !== obj?.[field2] && obj?.[field3] === "") {
        difference++;
      }
      for (let key in obj) {
        recursiveCalculate(obj[key]);
      }
    }
  }

  recursiveCalculate(data);
  return difference;
};

const differences = (applicationData, ucasData) => {
  const applicationDataArray = applicationData.split(" ");
  const ucasDataArray = ucasData.split(" ");

  const differentWordIndexes = [];
  for (
    let i = 0;
    i < Math.max(applicationDataArray.length, ucasDataArray.length);
    i++
  ) {
    if (applicationDataArray[i] !== ucasDataArray[i]) {
      differentWordIndexes.push(i);
    }
  }
  return differentWordIndexes;
};

const isHighlightedInEducation = (applicationData, ucasData) => {
  return applicationData !== ucasData;
};

const EducationSectionDetails = (props) => {
  const { fieldDetails, fieldName, isModule } = props;

  const onClickIgnoreMismatch = (fieldName, isDataAvailable) => {
    let data = props.reviewData;
    const name =
      props?.userData?.role?.fullname ||
      `Admin-${props?.userData?.id}` ||
      props?.userData?.email;

    const mismatchMessage = isDataAvailable
      ? `${name} on ${Format.date(new Date())}`
      : ``;
    const ignore = isDataAvailable ? true : false;

    const updatedEducation = updateIgnoreKey(
      data[EDUCATION],
      mismatchMessage,
      props.selectedFieldName,
      fieldName
    );

    const params = {
      application_review_datum: {
        data,
      },
    };
    console.log(updatedEducation);
    props.ignoreMismatch(params, props.application?.id, ignore);
  };

  const updateIgnoreKey = (obj, newIgnoreValue, parentKey, targetKey) => {
    if (typeof obj === "object") {
      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          obj[i] = updateIgnoreKey(
            obj[i],
            newIgnoreValue,
            parentKey,
            targetKey
          );
        }
      } else {
        if (
          obj.hasOwnProperty(parentKey) &&
          obj[parentKey].hasOwnProperty(targetKey) &&
          obj[parentKey][targetKey].hasOwnProperty("ignore")
        ) {
          obj[parentKey][targetKey].ignore = newIgnoreValue;
        }

        if (obj[parentKey] && Array.isArray(obj[parentKey])) {
          obj[parentKey] = obj[parentKey].map((item) => {
            if (
              item.hasOwnProperty(targetKey) &&
              item[targetKey].hasOwnProperty("ignore")
            ) {
              item[targetKey].ignore = newIgnoreValue;
            }

            if (
              item.hasOwnProperty("subjects") &&
              Array.isArray(item.subjects)
            ) {
              item.subjects = updateIgnoreKey(
                item.subjects,
                newIgnoreValue,
                targetKey,
                targetKey
              );
            }

            return item;
          });
        }

        for (const key in obj) {
          if (
            Array.isArray(obj[key]) ||
            (obj[key] && typeof obj[key] === "object")
          ) {
            obj[key] = updateIgnoreKey(
              obj[key],
              newIgnoreValue,
              parentKey,
              targetKey
            );
          }
        }
      }
    }
    return obj;
  };

  const renderIgnoreButton = (fieldDetails, fieldName) => {
    const isUniCodeMatch =
      fieldDetails?.application_uni_code === fieldDetails?.ucas_uni_code &&
      fieldName === UNIVERSITY;

    if (isUniCodeMatch) return null;

    const isHighlightedMismatch = !fieldDetails?.ignore;

    if (isHighlightedMismatch) {
      return (
        <div className="col-span-1 text-blue-800 p-4 font-lato text-base font-semibold leading-5 tracking-normal text-right">
          <a
            onClick={() => onClickIgnoreMismatch(fieldName, true)}
            className="ignore-button"
          >
            Ignore mismatch
          </a>
        </div>
      );
    } else if (fieldDetails?.ignore) {
      return (
        <div className="col-span-1 p-4">
          <span className="text-left">
            <span className="font-bold text-sm leading-4 tracking-normal text-left">
              Mismatch ignored by
            </span>
            <span className="text-sm leading-4 tracking-normal text-left">
              {` ${fieldDetails.ignore} `}
            </span>
            <a
              onClick={() => onClickIgnoreMismatch(fieldName, false)}
              className="undo-button text-blue-800 font-bold text-sm leading-4 tracking-normal text-left"
            >
              Undo
            </a>
          </span>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={`grid grid-cols-10 
                  ${
                    isHighlightedInEducation(
                      fieldDetails?.application_data,
                      fieldDetails?.ucas_data
                    ) &&
                    !fieldDetails?.ignore &&
                    "border border-red-800"
                  }
                  ${
                    isModule
                      ? "border-b border-gray-300"
                      : "bg-white border border-gray-300"
                  }
                  `}
    >
      <div className="col-span-2 text-gray-750 p-4 text-base">{fieldName}</div>
      <>
        <div
          className={`col-span-3 font-bold text-base text-gray-750 py-4 pr-4 text-justify ${
            isHighlightedInEducation(
              fieldDetails?.application_data,
              fieldDetails?.ucas_data
            ) && !fieldDetails?.ignore
              ? "text-red-800"
              : "text-gray-750"
          }`}
        >
          {fieldDetails?.application_data}
        </div>
        <div
          className={`col-span-3 font-bold text-base text-gray-750 py-4 pr-4 text-justify ${
            isHighlightedInEducation(
              fieldDetails?.application_data,
              fieldDetails?.ucas_data
            ) && !fieldDetails?.ignore
              ? "text-red-800"
              : "text-gray-750"
          }`}
        >
          {fieldDetails?.ucas_data}
        </div>
        <div
          className={`col-span-2 font-bold text-base text-gray-750 py-4 pr-4 text-justify ${
            isHighlightedInEducation(
              fieldDetails?.application_data,
              fieldDetails?.ucas_data
            ) && !fieldDetails?.ignore
              ? "text-red-800"
              : "text-gray-750"
          }`}
        >
          {isHighlightedInEducation(
            fieldDetails?.application_data,
            fieldDetails?.ucas_data
          ) && renderIgnoreButton(fieldDetails, fieldName)}
        </div>
      </>
    </div>
  );
};

const Qualification = (props) => {
  const { qualificationName, qualificationDetails } = props;
  const [expand, setExpand] = useState(false);
  let differencesCountQualification = 0;
  if (qualificationDetails) {
    differencesCountQualification = calculateEducationSectionDifferences(
      qualificationDetails,
      APPLICATION_DATA,
      UCAS_DATA,
      IGNORE
    );
  }
  return (
    <>
      <div
        className={`flex flex-row justify-between items-center py-4 px-6 cursor-pointer bg-gray-100 mt-4 ${
          expand ? "rounded-t-lg" : "border-b border-gray-200 rounded-lg"
        }`}
        onClick={() => setExpand(!expand)}
      >
        <div className="flex items-center ">
          <FontAwesomeIcon
            icon={["far", "university"]}
            className="text-xs text-gray-500"
          />
          <span className="text-base font-bold pl-2">{qualificationName}</span>
          {differencesCountQualification > 0 ? (
            <div className="text-red-800 text-sm pl-4 font-bold flex items-center">
              {differencesCountQualification} mismatch{" "}
              <FontAwesomeIcon
                icon={["fas", "circle-xmark"]}
                className="text-base text-red-800 leading-none pl-2"
              />
            </div>
          ) : (
            <div>
              <FontAwesomeIcon
                icon={["fas", "circle-check"]}
                className="text-base leading-none text-blue-800 pl-4"
              />
            </div>
          )}
        </div>

        <FontAwesomeIcon
          icon={expand ? ["fas", "chevron-up"] : ["fas", "chevron-down"]}
          className="text-base text-gray-750"
        />
      </div>
      <div
        className={`flex flex-col justify-center h-auto bg-gray-100 rounded-b-lg p-4 ${
          expand ? "" : "hidden"
        }`}
      >
        {" "}
        <div className="grid grid-cols-10 pb-4">
          <div className="col-span-2 text-sm text-gray-750">Details</div>
          <div className="col-span-3 text-sm text-gray-750">Explore data</div>
          <div className="col-span-3 text-sm text-gray-750">UCAS data</div>
          <div className="col-span-2 text-sm text-gray-750" />
        </div>
        {Object.entries(qualificationDetails).map(
          ([fieldName, fieldDetails]) => {
            if (Array.isArray(fieldDetails)) {
              return (
                <div>
                  {fieldDetails.map((subject) => (
                    <>
                      <div className="grid grid-cols-10 pt-8">
                        <div className="col-span-2 text-sm text-gray-750">
                          Module
                        </div>
                        <div className="col-span-3 text-sm text-gray-750">
                          Explore data
                        </div>
                        <div className="col-span-3 text-sm text-gray-750">
                          UCAS data
                        </div>
                        <div className="col-span-2 text-sm text-gray-750" />
                      </div>
                      <div className="rounded-lg border border-gray-300 mt-4 bg-white">
                        {Object.entries(subject).map(
                          ([fieldName, fieldDetails]) => (
                            <EducationSectionDetails
                              fieldName={fieldName}
                              fieldDetails={fieldDetails}
                              reviewData={props.reviewData}
                              ignoreMismatch={props.ignoreMismatch}
                              selectedFieldName={"subjects"}
                              userData={props?.userData}
                              application={props?.application}
                              isModule
                            />
                          )
                        )}
                      </div>
                    </>
                  ))}
                </div>
              );
            } else {
              return (
                <EducationSectionDetails
                  fieldName={fieldName}
                  fieldDetails={fieldDetails}
                  reviewData={props.reviewData}
                  selectedFieldName={qualificationName}
                  ignoreMismatch={props.ignoreMismatch}
                  userData={props?.userData}
                  application={props?.application}
                />
              );
            }
          }
        )}
      </div>
    </>
  );
};

const SectionDetails = (props) => {
  const { sectionDetails, sectionName, application } = props;
  const isHighlighted = (applicationData, ucasData) => {
    if (isManualReview(sectionName)) {
      return false;
    }
    return applicationData !== ucasData;
  };

  let differencesIndex = [];

  if (sectionName === "Personal statement") {
    const {
      "UK personal statement": { application_data, ucas_data },
    } = sectionDetails;
    differencesIndex = differences(application_data, ucas_data);
  }

  const onClickIgnoreMismatch = (fieldName, isDataAvailable) => {
    let data = props.reviewData;

    const name =
      props?.userData?.role?.fullname ||
      `Admin-${props?.userData?.id}` ||
      props?.userData?.email;

    const mismatchMessage = isDataAvailable
      ? `${name} on ${Format.date(new Date())}`
      : ``;
    const ignore = isDataAvailable ? true : false;

    if (sectionName === CHOICES) {
      data[sectionName][props.sectionIndex][fieldName][IGNORE] =
        mismatchMessage;
    } else if (sectionName === EDUCATION) {
      data[sectionName].forEach((education) => {
        if (education[fieldName]) {
          education[fieldName][IGNORE] = mismatchMessage;
        }
      });
    } else {
      data[sectionName][fieldName][IGNORE] = mismatchMessage;
    }
    const params = {
      application_review_datum: {
        data,
      },
    };
    props.ignoreMismatch(params, application?.id, ignore);
  };

  const renderIgnoreButton = (fieldDetails, fieldName) => {
    const isUniCodeMatch =
      fieldDetails?.application_uni_code === fieldDetails?.ucas_uni_code &&
      fieldName === UNIVERSITY;

    if (isUniCodeMatch) return null;

    const isHighlightedMismatch =
      isHighlighted(fieldDetails.application_data, fieldDetails.ucas_data) &&
      !fieldDetails?.ignore;

    if (isHighlightedMismatch) {
      return (
        <div className="col-span-1 text-blue-800 p-4 font-lato text-base font-semibold leading-5 tracking-normal text-right">
          <a
            onClick={() => onClickIgnoreMismatch(fieldName, true)}
            className="ignore-button"
          >
            Ignore mismatch
          </a>
        </div>
      );
    } else if (fieldDetails?.ignore) {
      return (
        <div className="col-span-1 p-4">
          <span className="text-left">
            <span className="font-bold text-sm leading-4 tracking-normal text-left">
              Mismatch ignored by
            </span>
            <span className="text-sm leading-4 tracking-normal text-left">
              {` ${fieldDetails.ignore} `}
            </span>
            <a
              onClick={() => onClickIgnoreMismatch(fieldName, false)}
              className="undo-button text-blue-800 font-bold text-sm leading-4 tracking-normal text-left"
            >
              Undo
            </a>
          </span>
        </div>
      );
    }

    return null;
  };
  if (!sectionDetails || typeof sectionDetails !== "object") {
    return null;
  } else {
    return (
      <>
        <div className="rounded-lg border border-gray-300 mb-4">
          {Object.entries(sectionDetails).map(([fieldName, fieldDetails]) => (
            <div
              className={`grid grid-cols-10  ${"border-b border-gray-300"}
                  ${
                    fieldDetails?.application_uni_code ===
                      fieldDetails?.ucas_uni_code && fieldName === UNIVERSITY
                      ? "border-b border-gray-300"
                      : isHighlighted(
                          fieldDetails.application_data,
                          fieldDetails.ucas_data
                        ) &&
                        fieldDetails.ignore === "" &&
                        "border border-red-800"
                  }`}
            >
              <div className="col-span-3 text-gray-750 p-4 text-left">
                {fieldName}
              </div>
              {differencesIndex.length > 0 ? (
                <>
                  <div className="col-span-3 font-bold text-base text-gray-750 py-4 pr-4 text-justify">
                    {fieldDetails.application_data
                      .split(" ")
                      .map((word, index) => (
                        <span
                          className={`${
                            differencesIndex.includes(index) &&
                            fieldDetails.ignore === ""
                              ? "text-red-800"
                              : "text-gray-750"
                          }`}
                        >
                          {word}{" "}
                        </span>
                      ))}
                  </div>
                  <div className="col-span-3 font-bold text-base text-gray-750 py-4 pr-4 text-justify">
                    {fieldDetails.ucas_data.split(" ").map((word, index) => (
                      <span
                        className={`${
                          differencesIndex.includes(index) &&
                          fieldDetails.ignore === ""
                            ? "text-red-800"
                            : "text-gray-750"
                        }`}
                      >
                        {word}{" "}
                      </span>
                    ))}
                  </div>
                  <div>{renderIgnoreButton(fieldDetails, fieldName)}</div>
                </>
              ) : (
                <>
                  <div
                    className={`col-span-3 font-bold text-base text-gray-750 py-4 pr-4 text-justify ${
                      fieldDetails?.application_uni_code ===
                        fieldDetails?.ucas_uni_code && fieldName === UNIVERSITY
                        ? "text-gray-750"
                        : isHighlighted(
                            fieldDetails.application_data,
                            fieldDetails.ucas_data
                          ) && fieldDetails.ignore === ""
                        ? "text-red-800"
                        : "text-gray-750"
                    }`}
                  >
                    {fieldDetails.application_data}
                  </div>
                  <div
                    className={`col-span-3 font-bold text-base text-gray-750 py-4 pr-4 text-justify ${
                      fieldDetails?.application_uni_code ===
                        fieldDetails?.ucas_uni_code && fieldName === UNIVERSITY
                        ? "text-gray-750"
                        : isHighlighted(
                            fieldDetails.application_data,
                            fieldDetails.ucas_data
                          ) && fieldDetails.ignore === ""
                        ? "text-red-800"
                        : "text-gray-750"
                    }`}
                  >
                    {fieldDetails.ucas_data}
                  </div>
                  <div>{renderIgnoreButton(fieldDetails, fieldName)}</div>
                </>
              )}
            </div>
          ))}
        </div>
      </>
    );
  }
};

const Section = (props) => {
  const {
    sectionName,
    sectionDetails,
    expandAll,
    manualReview,
    profilePdfUrl,
    ucasCounselorReport,
    isEmployment,
  } = props;
  const [expand, setExpand] = useState(expandAll);
  const [ucasFile, setUcasFile] = useState(null);

  let differencesCount = 0;

  if (sectionDetails && !manualReview) {
    differencesCount = calculateDiffernceCount(sectionDetails);
  }

  const RenderIgnoreInfo = ({ ignore }) => {
    if (ignore && ignore.length > 0) {
      return (
        <>
          <span className="font-lato text-base font-bold leading-4 tracking-wide text-center text-gray-600 pr-1">
            Mismatch ignored:
          </span>
          <span className="font-lato text-sm font-normal leading-5 text-center text-gray-600">
            by {ignore[0]}
          </span>
        </>
      );
    }
    return null;
  };

  const RenderNotification = ({ count, ignore }) => (
    <span className="flex items-center pl-1">
      <div className="flex items-center justify-center w-5 h-5 rounded-full bg-gray-100 mr-1">
        <span className="font-lato text-base font-bold leading-4 tracking-wide text-center text-gray-600">
          {count}
        </span>
      </div>
      <RenderIgnoreInfo ignore={ignore} />
    </span>
  );

  const notification = (sectionName) => {
    const selectedSection = props.resultArray?.find(
      (item) => item?.title === sectionName
    );

    return (
      <div className="flex flex-row">
        <div className="space-x-4">
          <FontAwesomeIcon
            icon={["fas", "circle-check"]}
            className="text-base leading-none text-blue-800 pl-4"
          />
        </div>
        {selectedSection && <RenderNotification {...selectedSection} />}
      </div>
    );
  };

  useEffect(() => {
    setExpand(expandAll);
  }, [expandAll]);

  if (sectionName === "Compare") {
    return (
      <>
        <div
          className={`flex flex-row justify-between items-center py-6 cursor-pointer ${
            expand ? "" : "border-b border-gray-200"
          }`}
          onClick={() => setExpand(!expand)}
        >
          <div className="flex items-center ">
            <span className="text-lg font-bold">{sectionName}</span>
            <span className="text-sm text-red-800 pl-4 font-bold">
              {REVIEW_MANUALLY}
            </span>
          </div>

          <FontAwesomeIcon
            icon={expand ? ["fas", "chevron-up"] : ["fas", "chevron-down"]}
            className="text-base text-gray-750"
          />
        </div>
        <div
          className={`flex flex-col justify-center h-auto bg-white rounded-lg ${
            expand ? "" : "hidden"
          }`}
        >
          {ucasFile || ucasCounselorReport ? (
            <div className="flex w-full">
              <div className="flex flex-col w-50% mr-4">
                <div className="text-sm text-gray-750 mb-2">Explore Data</div>
                <div className="overflow-auto h-screen rounded-lg border border-gray-300">
                  <div className="mr-2">
                    {profilePdfUrl && <PDF file={profilePdfUrl} scale={1.25} />}
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-50%">
                <div className="text-sm text-gray-750 mb-2">UCAS Data</div>
                <div className="overflow-auto h-screen rounded-lg border border-gray-300">
                  <div className="mr-2">
                    {ucasCounselorReport ? (
                      <PDF file={ucasCounselorReport} scale={1.25} />
                    ) : (
                      <PDF file={ucasFile} scale={1.25} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <input
                name={"label"}
                id={"label"}
                type="file"
                accept=".pdf"
                className="hidden"
                onChange={(e) => {
                  const file = e.target.files[0];
                  const fileURL = URL.createObjectURL(file);
                  setUcasFile(fileURL);
                }}
              />
              <label htmlFor={"label"} className="cursor-pointer">
                <div
                  className="w-full border-2 border-dashed bg-blue-50 flex items-center justify-center rounded border-dark-blue-50"
                  style={{ height: 240 }}
                >
                  <div className="flex flex-col">
                    <span className="text-sm font-bold pb-4">
                      Upload UCAS data
                    </span>
                    <div className="btn-primary">Upload PDF</div>
                  </div>
                </div>
              </label>
            </div>
          )}
        </div>
      </>
    );
  }

  if (isEmployment) {
    return (
      <>
        <div
          className={`flex flex-row justify-between items-center py-6 cursor-pointer ${
            expand ? "" : "border-b border-gray-200"
          }`}
          onClick={() => setExpand(!expand)}
        >
          <div className="flex items-center ">
            <span className="text-lg font-bold">{sectionName}</span>
            <span className="text-sm text-red-800 pl-4 font-bold">
              {REVIEW_MANUALLY}
            </span>
          </div>

          <FontAwesomeIcon
            icon={expand ? ["fas", "chevron-up"] : ["fas", "chevron-down"]}
            className="text-base text-gray-750"
          />
        </div>
        <div
          className={`flex flex-col justify-center h-auto bg-white rounded-lg ${
            expand ? "" : "hidden"
          }`}
        >
          {ucasFile ? (
            <div className="flex w-full">
              <div className="flex flex-col w-50% mr-4">
                <div className="text-sm text-gray-750 mb-2">Explore Data</div>
                <div className="overflow-auto h-screen rounded-lg border border-gray-300">
                  <div className="mr-2">
                    {profilePdfUrl && <PDF file={profilePdfUrl} scale={1.25} />}
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-50%">
                <div className="text-sm text-gray-750 mb-2">UCAS Data</div>
                <div className="overflow-auto h-screen rounded-lg border border-gray-300">
                  <div className="mr-2">
                    <PDF file={ucasFile} scale={1.25} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <input
                name={"label"}
                id={"label"}
                type="file"
                accept=".pdf"
                className="hidden"
                onChange={(e) => {
                  const file = e.target.files[0];
                  const fileURL = URL.createObjectURL(file);
                  setUcasFile(fileURL);
                }}
              />
              <label htmlFor={"label"} className="cursor-pointer">
                <div
                  className="w-full border-2 border-dashed bg-blue-50 flex items-center justify-center rounded border-dark-blue-50"
                  style={{ height: 240 }}
                >
                  <div className="flex flex-col">
                    <span className="text-sm font-bold pb-4">
                      Upload UCAS data
                    </span>
                    <div className="btn-primary">Upload PDF</div>
                  </div>
                </div>
              </label>
            </div>
          )}
        </div>
      </>
    );
  }

  if (sectionName === EDUCATION) {
    let differencesCountEducation = 0;
    if (sectionDetails && !manualReview) {
      differencesCountEducation = calculateEducationSectionDifferences(
        sectionDetails,
        APPLICATION_DATA,
        UCAS_DATA,
        IGNORE
      );
    }
    return (
      <>
        <div
          className={`flex flex-row justify-between items-center py-6 cursor-pointer ${
            expand ? "" : "border-b border-gray-200"
          }`}
          onClick={() => setExpand(!expand)}
        >
          <div className="flex items-center ">
            <span className="text-lg font-bold">{sectionName}</span>
            {!manualReview &&
              (differencesCountEducation > 0 ? (
                <div className="text-red-800 text-sm pl-4 font-bold flex items-center">
                  {differencesCountEducation} mismatch{" "}
                  <FontAwesomeIcon
                    icon={["fas", "circle-xmark"]}
                    className="text-base text-red-800 leading-none pl-2"
                  />
                </div>
              ) : (
                notification(sectionName)
              ))}
          </div>

          <FontAwesomeIcon
            icon={expand ? ["fas", "chevron-up"] : ["fas", "chevron-down"]}
            className="text-base text-gray-750"
          />
        </div>
        <div
          className={`flex flex-col justify-center h-auto bg-white rounded-lg ${
            expand ? "" : "hidden"
          }`}
        >
          {sectionDetails.map((sec) => {
            return (
              <div>
                {Object.entries(sec).map(([schoolName, schoolDetails]) => {
                  let differencesCountSchool = 0;
                  if (schoolDetails && !manualReview) {
                    differencesCountSchool =
                      calculateEducationSectionDifferences(
                        schoolDetails,
                        APPLICATION_DATA,
                        UCAS_DATA,
                        IGNORE
                      );
                  }
                  return (
                    <div className="rounded-lg border border-gray-300 mb-4 p-8">
                      <div className="flex items-center pb-6">
                        <span className="text-lg font-bold ">{schoolName}</span>
                        {differencesCountSchool > 0 ? (
                          <div className="text-red-800 text-sm pl-4 font-bold flex items-center">
                            {differencesCountSchool} mismatch{" "}
                            <FontAwesomeIcon
                              icon={["fas", "circle-xmark"]}
                              className="text-base text-red-800 leading-none pl-2"
                            />
                          </div>
                        ) : (
                          <div>
                            <FontAwesomeIcon
                              icon={["fas", "circle-check"]}
                              className="text-base leading-none text-blue-800 pl-4"
                            />
                          </div>
                        )}
                      </div>

                      <div className="grid grid-cols-10 pb-4">
                        <div className="col-span-2 text-sm text-gray-750">
                          Details
                        </div>
                        <div className="col-span-3 text-sm text-gray-750">
                          Explore data
                        </div>
                        <div className="col-span-3 text-sm text-gray-750">
                          UCAS data
                        </div>
                        <div className="col-span-2 text-sm text-gray-750" />
                      </div>
                      {Object.entries(schoolDetails).map(
                        ([fieldName, fieldDetails]) => {
                          if (Array.isArray(fieldDetails)) {
                            return (
                              <div>
                                <div className="col-span-4 text-sm text-gray-750 pt-8">
                                  {fieldDetails.length > 0 && "Qualifications"}
                                </div>
                                {fieldDetails.map((qualification) =>
                                  Object.entries(qualification).map(
                                    ([
                                      qualificationName,
                                      qualificationDetails,
                                    ]) => {
                                      return (
                                        <Qualification
                                          qualificationName={qualificationName}
                                          qualificationDetails={
                                            qualificationDetails
                                          }
                                          selectedFieldName={qualification}
                                          reviewData={props.reviewData}
                                          ignoreMismatch={props.ignoreMismatch}
                                          userData={props?.userData}
                                          application={props?.application}
                                        />
                                      );
                                    }
                                  )
                                )}
                              </div>
                            );
                          } else {
                            return (
                              <EducationSectionDetails
                                fieldName={fieldName}
                                fieldDetails={fieldDetails}
                                reviewData={props.reviewData}
                                selectedFieldName={schoolName}
                                ignoreMismatch={props.ignoreMismatch}
                                userData={props?.userData}
                                application={props?.application}
                              />
                            );
                          }
                        }
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </>
    );
  }

  if (sectionName === CHOICES) {
    let differencesCountChoices = 0;
    if (sectionDetails && !manualReview) {
      differencesCountChoices =
        calculateDiffernceCount(sectionDetails) -
        calculateDiffernceCountFromCode(sectionDetails);
    }

    return (
      <>
        <div
          className={`flex flex-row justify-between items-center py-6 cursor-pointer ${
            expand ? "" : "border-b border-gray-200"
          }`}
          onClick={() => setExpand(!expand)}
        >
          <div className="flex items-center ">
            <span className="text-lg font-bold">{sectionName}</span>
            {!manualReview &&
              (differencesCountChoices > 0 ? (
                <div className="text-red-800 text-sm pl-4 font-bold flex items-center">
                  {differencesCountChoices} mismatch{" "}
                  <FontAwesomeIcon
                    icon={["fas", "circle-xmark"]}
                    className="text-base text-red-800 leading-none pl-2"
                  />
                </div>
              ) : (
                notification(sectionName)
              ))}
          </div>

          <FontAwesomeIcon
            icon={expand ? ["fas", "chevron-up"] : ["fas", "chevron-down"]}
            className="text-base text-gray-750"
          />
        </div>
        <div
          className={`flex flex-col justify-center h-auto bg-white rounded-lg ${
            expand ? "" : "hidden"
          }`}
        >
          <div className="grid grid-cols-10 pb-4">
            <div className="col-span-3 text-sm text-gray-750">Details</div>
            <div className="col-span-3 text-sm text-gray-750">Explore data</div>
            <div className="col-span-3 text-sm text-gray-750">UCAS data</div>
            <div className="col-span-1" />
          </div>
          {Array.isArray(sectionDetails) ? (
            sectionDetails.map((sec, index) => (
              <SectionDetails
                sectionDetails={sec}
                sectionIndex={index}
                application={props.application}
                userData={props?.userData}
                ignoreMismatch={props.ignoreMismatch}
                reviewData={props.reviewData}
                sectionName={sectionName}
              />
            ))
          ) : (
            <SectionDetails
              sectionDetails={sectionDetails}
              sectionName={sectionName}
              application={props.application}
              userData={props?.userData}
              ignoreMismatch={props.ignoreMismatch}
              reviewData={props.reviewData}
            />
          )}
        </div>
      </>
    );
  }

  return (
    <div>
      <div
        className={`flex flex-row justify-between items-center py-6 cursor-pointer ${
          expand ? "" : "border-b border-gray-200"
        }`}
        onClick={() => setExpand(!expand)}
      >
        <div className="flex items-center ">
          <span className="text-lg font-bold">{sectionName}</span>
          {manualReview && (
            <span className="text-sm font-bold text-red-800 pl-4">
              {REVIEW_MANUALLY}
            </span>
          )}

          {!manualReview &&
            (differencesCount > 0 ? (
              <div className="text-red-800 text-sm pl-4 font-bold flex items-center">
                {differencesCount} mismatch{" "}
                <FontAwesomeIcon
                  icon={["fas", "circle-xmark"]}
                  className="text-base text-red-800 leading-none pl-2"
                />
              </div>
            ) : (
              notification(sectionName)
            ))}
        </div>
        <FontAwesomeIcon
          icon={expand ? ["fas", "chevron-up"] : ["fas", "chevron-down"]}
          className="text-base text-gray-750"
        />
      </div>
      <div
        className={`flex flex-col justify-center h-auto bg-white rounded-lg ${
          expand ? "" : "hidden"
        }`}
      >
        <div className="grid grid-cols-10 pb-4">
          <div className="col-span-3 text-sm text-gray-750">Details</div>
          <div className="col-span-3 text-sm text-gray-750">Explore data</div>
          <div className="col-span-3 text-sm text-gray-750">UCAS data</div>
          <div className="col-span-1" />
        </div>
        {Array.isArray(sectionDetails) ? (
          <>
            {sectionDetails.map((sec, index) => (
              <SectionDetails
                sectionDetails={sec}
                sectionIndex={index}
                userData={props?.userData}
                application={props.application}
                ignoreMismatch={props.ignoreMismatch}
                reviewData={props.reviewData}
                sectionName={sectionName}
              />
            ))}
          </>
        ) : (
          <>
            <SectionDetails
              sectionDetails={sectionDetails}
              sectionName={sectionName}
              application={props.application}
              userData={props?.userData}
              ignoreMismatch={props.ignoreMismatch}
              reviewData={props.reviewData}
            />
          </>
        )}
      </div>
    </div>
  );
};

const ExploreData = (props) => {
  const {
    visible,
    onClose,
    applicationId,
    getUcasApplicationForm,
    notes,
    reviewData,
    saveNotes,
    loading,
    syncDate,
    profilePdfUrl,
    ucasCounselorReport,
  } = props;
  const [expandAll, setExpandAll] = useState(false);
  const [differencesCountPerSection, setDifferencesCountPerSection] = useState(
    []
  );
  const [totalDifferences, setTotalDifferences] = useState(0);
  const [note, setNote] = useState(notes);
  const [hasNotesValueChanged, setHasNotesValueChanged] = useState(false);
  const [isNoteTextBoxActive, setIsNoteTextBoxActive] = useState(false);

  useEffect(() => {
    setNote(notes);
  }, [notes]);

  useEffect(() => {
    getUcasApplicationForm(applicationId);
  }, [applicationId, getUcasApplicationForm]);

  useEffect(() => {
    const applyFilter = EventBus.on(
      UcasApplicationFormTypes.SAVE_NOTES_SUCCESS,
      () => {
        getUcasApplicationForm(applicationId);
      }
    );
    return () => {
      applyFilter();
    };
  }, []);

  useEffect(() => {
    const differencesPerSection = Object.entries(reviewData)
      .map(([sectionName, sectionDetails]) => {
        let differences = 0;

        if (sectionName === EDUCATION) {
          differences = calculateEducationSectionDifferences(
            sectionDetails,
            APPLICATION_DATA,
            UCAS_DATA,
            IGNORE
          );
        } else if (!isManualReview(sectionName)) {
          if (sectionName === CHOICES) {
            differences =
              calculateDiffernceCount(sectionDetails) -
              calculateDiffernceCountFromCode(sectionDetails);
          } else {
            differences = calculateDiffernceCount(sectionDetails);
          }
        }
        return { sectionName, differences };
      })
      .filter((sec) => sec.differences > 0);

    if (differencesPerSection.length > 0) {
      const totalDifferencesCount = differencesPerSection.reduce(
        (total, section) => total + section.differences,
        0
      );
      setTotalDifferences(totalDifferencesCount);
    }
    setDifferencesCountPerSection(differencesPerSection);
  }, [reviewData]);

  useEffect(() => {
    const sectionErrors = differencesCountPerSection.reduce(
      (acc, { sectionName, differences }) => {
        acc[sectionName] = differences;
        return acc;
      },
      {}
    );

    const defaultPayload = {
      student_application_id: props.application?.id,
      student_id: props.application?.user_id,
      total_errors_count: totalDifferences,
      section_errors: {},
      error_details: "",
      data_synced: false,
      review_mismatch_count: totalDifferences,
      review_mismatch_ignored: resultArray.reduce(
        (acc, item) => acc + item.count,
        0
      ),
    };

    const onSuccess = EventBus.on(
      UcasApplicationFormTypes.GET_UCAS_APPLICATION_FORM_SUCCESS,
      () => {
        const updatedPayload = {
          ...defaultPayload,
          total_errors_count: totalDifferences,
          section_errors: sectionErrors,
          data_synced: true,
        };
        props.getAppReviewLogs({
          application_review_logs: updatedPayload,
        });
      }
    );

    const onFailure = EventBus.on(
      UcasApplicationFormTypes.GET_UCAS_APPLICATION_FORM_FAILURE,
      () => {
        props.getAppReviewLogs({ application_review_logs: defaultPayload });
      }
    );

    return () => {
      onSuccess();
      onFailure();
    };
  }, [differencesCountPerSection, reviewData]);

  function extractIgnoreCount(obj) {
    const resultObject = {};

    function processNestedObject(nestedObj, path) {
      for (const key in nestedObj) {
        if (
          nestedObj.hasOwnProperty(key) &&
          typeof nestedObj[key] === "object"
        ) {
          const currentPath = path ? `${path}.${key}` : key;
          if (
            nestedObj[key]?.hasOwnProperty("ignore") &&
            nestedObj[key]["ignore"] !== ""
          ) {
            if (!resultObject[currentPath]) {
              resultObject[currentPath] = {
                title: currentPath.split(".")[0],
                count: 1,
                ignore: [nestedObj[key]["ignore"]],
                nestedObj: nestedObj,
                nestedKey: nestedObj[key],
                path: path,
              };
            } else {
              resultObject[currentPath].count++;
              resultObject[currentPath].ignore.push(nestedObj[key]["ignore"]);
            }
          }
          processNestedObject(nestedObj[key], currentPath);
        }
      }
    }

    processNestedObject(obj, "");

    const resultArray = Object.values(resultObject).reduce((acc, item) => {
      const existingItem = acc.find((i) => i.title === item.title);
      if (existingItem) {
        existingItem.count += item.count;
        existingItem.ignore = [...existingItem.ignore, ...item.ignore];
      } else {
        acc.push(item);
      }
      return acc;
    }, []);

    return resultArray;
  }

  const resultArray = extractIgnoreCount(reviewData);

  return (
    <ReactModal
      isOpen={visible}
      onRequestClose={() => {}}
      ariaHideApp={false}
      className="modal"
      overlayClassName="modalOverlay"
    >
      <div className="absolute left-0 right-0 top-0 bottom-0 bg-overlay-500 flex flex-col justify-center items-center z-20">
        <div className="relative w-full overflow-auto">
          <div
            className="my-6 mx-5 rounded bg-white pt-6 pb-4 flex flex-col"
            style={{ height: "calc(100vh - 50px)" }}
          >
            <div className="flex flex-row justify-between px-8 border-b border-gray-300 pb-6">
              <div className="flex items-center">
                <span className="text-xl font-bold text-gray-1000 pr-6">
                  Compare Explore and UCAS data
                </span>
                <span className="text-base font-bold text-gray-1000">
                  {syncDate && (
                    <>
                      Last synced :{" "}
                      {Format.datetime(syncDate, "ddd, DD MMM \u00B7 hh:mma")}{" "}
                    </>
                  )}
                </span>
              </div>

              <FontAwesomeIcon
                onClick={onClose}
                icon={["fas", "times"]}
                className={"text-lg leading-none text-gray-750 cursor-pointer"}
              />
            </div>
            {loading ? (
              <div className="flex-1 flex flex-col justify-center items-center">
                <Loader type="Oval" color="grey" height={50} width={50} />
              </div>
            ) : (
              <div className="h-full">
                <div
                  className="overflow-auto"
                  style={{ height: "calc(100% - 50px)" }}
                >
                  <div className="px-8">
                    <Section
                      application={props.application}
                      userData={props?.userData}
                      ignoreMismatch={props.ignoreMismatch}
                      sectionName="Compare"
                      profilePdfUrl={profilePdfUrl}
                      ucasCounselorReport={ucasCounselorReport}
                      expandAll={expandAll}
                      reviewData={reviewData}
                      resultArray={resultArray}
                    />
                  </div>
                  {!isEmpty(reviewData) && (
                    <>
                      {differencesCountPerSection.length > 0 && (
                        <div className="my-6 p-4 bg-red-50 mx-8 rounded-lg flex flex-col">
                          <div>
                            <FontAwesomeIcon
                              icon={["far", "circle-info"]}
                              className={
                                "text-base leading-none text-red-800 pr-2"
                              }
                            />
                            <span className="text-base font-bold text-gray-1000">
                              Information mismatched total : {totalDifferences}
                            </span>
                          </div>
                          {differencesCountPerSection.map((section) => (
                            <span className="text-base text-gray-750 pl-6">
                              {section.sectionName} : {section.differences}
                            </span>
                          ))}
                        </div>
                      )}
                      {differencesCountPerSection.length === 0 && (
                        <div className="my-6 p-4 bg-green-50 mx-8 rounded-lg flex flex-col">
                          <div>
                            <FontAwesomeIcon
                              icon={["far", "circle-info"]}
                              className={
                                "text-base leading-none text-green-800 pr-2"
                              }
                            />
                            <span className="text-base font-bold text-gray-1000">
                              All information matched.
                            </span>
                          </div>

                          <span className="text-base text-gray-750 pl-6">
                            Please review Education & Employment sections
                            explicitly.
                          </span>
                        </div>
                      )}
                      {resultArray.length > 0 && (
                        <div className="my-6 p-4 bg-blue-200 mx-8 rounded-lg flex flex-col">
                          <div>
                            <FontAwesomeIcon
                              icon={["far", "circle-info"]}
                              className={
                                "text-base leading-none text-blue-800 pr-2"
                              }
                            />
                            <span className="text-base font-bold text-gray-1000">
                              Information mismatched ignored:{" "}
                              {resultArray.reduce(
                                (acc, item) => acc + item.count,
                                0
                              )}
                            </span>
                          </div>
                          {resultArray.map((section) => (
                            <span className="pl-6" key={section?.title}>
                              <span className="text-base text-black-800">
                                {`${section?.title} : `}
                              </span>
                              {section?.count > 1 ? (
                                <span className="text-base text-black-800">
                                  {section?.count}
                                </span>
                              ) : (
                                <span className="text-base text-black-800">
                                  {`${section?.count} ignored by ${section?.ignore?.[0]}`}
                                </span>
                              )}
                            </span>
                          ))}
                        </div>
                      )}
                      <div className="flex flex-row justify-between items-center px-8">
                        {false ? (
                          <div className="flex items-center text-blue-700 cursor-pointer font-bold text-sm">
                            <FontAwesomeIcon
                              icon={["fas", "plus"]}
                              className="pr-2"
                            />
                            Add Note
                          </div>
                        ) : (
                          <div className="flex flex-row items-center w-full">
                            <div className="text-sm text-gray-750 whitespace-nowrap pr-4">
                              Notes :
                            </div>

                            <Input.TextArea
                              className="ant-text-area-field"
                              style={{
                                resize: "none",
                                width: "50%",
                                minHeight: 36,
                              }}
                              autoSize={{ minRows: 1, maxRows: 6 }}
                              value={note}
                              onChange={(event) => {
                                setNote(event.target.value);
                                setHasNotesValueChanged(
                                  event.target.value !== notes
                                );
                              }}
                              onFocus={() => setIsNoteTextBoxActive(true)}
                              onBlur={() => setIsNoteTextBoxActive(false)}
                            />
                            {(hasNotesValueChanged || isNoteTextBoxActive) && (
                              <div className="flex flex-row pl-4">
                                <button
                                  className="btn-secondary mr-2"
                                  onClick={() => {
                                    setNote(notes);
                                    setHasNotesValueChanged(false);
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn-primary"
                                  onClick={() => {
                                    setHasNotesValueChanged(false);
                                    setIsNoteTextBoxActive(false);
                                    saveNotes(applicationId, note);
                                    setNote(note);
                                  }}
                                  disabled={!hasNotesValueChanged}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </div>
                        )}

                        <div
                          onClick={() => setExpandAll(!expandAll)}
                          className="text-sm text-blue-700 font-bold cursor-pointer min-w-min"
                          style={{ minWidth: "max-content" }}
                        >
                          {expandAll ? "Collapse all" : "Expand all"}

                          <FontAwesomeIcon
                            icon={
                              expandAll
                                ? ["fas", "chevron-up"]
                                : ["fas", "chevron-down"]
                            }
                            className="pl-2"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="px-8">
                    {Object.entries(reviewData)?.map(
                      ([sectionName, sectionDetails]) => (
                        <Section
                          application={props.application}
                          userData={props?.userData}
                          ignoreMismatch={props.ignoreMismatch}
                          sectionName={sectionName}
                          sectionDetails={sectionDetails}
                          expandAll={expandAll}
                          manualReview={isManualReview(sectionName)}
                          reviewData={reviewData}
                          resultArray={resultArray}
                        />
                      )
                    )}
                  </div>
                  <div className="px-8">
                    <Section
                      application={props.application}
                      userData={props?.userData}
                      ignoreMismatch={props.ignoreMismatch}
                      sectionName="Employment"
                      profilePdfUrl={profilePdfUrl}
                      expandAll={expandAll}
                      isEmployment={true}
                      reviewData={reviewData}
                      resultArray={resultArray}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

function mapStateToProps(state) {
  const {
    ucasApplicationForm: { notes, loading, syncDate, reviewData },
  } = state;

  return {
    notes,
    reviewData,
    loading,
    syncDate,
  };
}

const mapDispatchToProps = {
  getUcasApplicationForm:
    UcasApplicationFormCreators.getUcasApplicationFormRequest,
  saveNotes: UcasApplicationFormCreators.saveNotesRequest,
  getAppReviewLogs: UcasApplicationFormCreators.getAppReviewLogs,
  ignoreMismatch: UcasApplicationFormCreators.requestIgnoreReviewData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExploreData);
