import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import Loader from "react-loader-spinner";
import ArrowImage from "../../../assets/arrow.png";

const AcceptPartnerModal = (props) => {
  const { visible, onClose, partner, onApprove, showLoader } = props;

  return (
    <Modal
      isOpen={visible}
      style={{
        content: {
          width: "600px",
          margin: "130px auto",
          outline: "none",
        },
      }}
      contentLabel="Example Modal"
      overlayClassName="modal-overlay"
      className="modal-content"
    >
      <div className="bg-white px-5 py-5 rounded-md">
        <div className="flex justify-between">
          <span className="font-bold text-xl">Approve Applicant</span>
          <div
            className="cursor-pointer"
            onClick={() => {
              onClose();
            }}
          >
            <FontAwesomeIcon icon="times" className="text-xl" />
          </div>
        </div>
        <div className="flex flex-col mt-5 w-full">
          <div className="flex flex-row bg-blue-50 p-4">
            <FontAwesomeIcon
              icon={["fas", "info-circle"]}
              className="text-lg text-dark-blue-800 mr-2"
            />
            <p className="text-sm text-black">
              You are about to aprove{" "}
              {partner.first_name + " " + partner.last_name} as a key
              representative of {partner.name} within the Cialfo Platform. This
              user would then have access to Admin function for this University
              within the Cialfo Platform.
            </p>
          </div>

          <div className="flex flex-row mt-8 justify-center">
            <div className="flex flex-col">
              <div className="flex-none flex justify-center items-center bg-blue-50 rounded-full h-88px w-88px">
                <FontAwesomeIcon
                  icon={["fas", "user"]}
                  className="text-4xl text-dark-blue-800"
                />
              </div>
              <p className="text-black text-base mt-2 text-center">
                {partner.first_name + " " + partner.last_name}
              </p>
            </div>
            <div className="flex-none h-88px flex flex-col justify-center">
              <img
                style={{ width: 93, height: 20 }}
                className="mx-9"
                src={ArrowImage}
                alt="arrow"
              />
            </div>

            <div
              style={{ maxWidth: 145 }}
              className="flex flex-col items-center"
            >
              <div className="flex-none flex justify-center items-center bg-blue-50 rounded-full h-88px w-88px">
                <FontAwesomeIcon
                  icon={["fas", "school"]}
                  className="text-4xl text-dark-blue-800"
                />
              </div>
              <p className="text-black text-base mt-2 text-center">
                {partner.name}
              </p>
            </div>
          </div>

          <div className="flex flex-row justify-end mt-8">
            <button
              onClick={onApprove}
              className="px-3 py-2 cursor-pointer flex items-center justify-center rounded h-36px text-white bg-dark-blue-800"
            >
              <FontAwesomeIcon icon={["fas", "envelope"]} className="mr-1" />
              Send Approval
            </button>
          </div>
        </div>
      </div>
      {showLoader ? (
        <div className="flex justify-center items-center z-20 absolute inset-0 bg-gray-200">
          <Loader type="Oval" color="grey" height={50} width={50} />
        </div>
      ) : null}
    </Modal>
  );
};

AcceptPartnerModal.defaultProps = {
  onClose: () => {},
};

export default AcceptPartnerModal;
