import React, { Component } from 'react';
import {
  Checkbox,
  Select
} from '../../../components';
import { withTranslation } from 'react-i18next';
import { ConfirmationModal } from "../../../components";
import FEATURES_META_DATA from './features-meta-data.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FeatureHeader = () => (
  <div className="flex flex-row bg-gray-50 border-b border-t border-gray-300 p-4">
    <div className={`flex flex-row items-center space-x-4 w-2/5`}>
      <Checkbox
        colorClass="text-blue-700 invisible"
      />
      <div className="text-gray-900 font-bold">
        Title
      </div>
    </div>
    <div className="text-gray-900 font-bold w-3/5">
      Users
    </div>
  </div>
);

const RadioBox = ({ items, onChange, selected }) => {
  return (<div className="flex-none flex flex-col space-y-4 items-start">
    {items.map((item) => (
      <div className="cursor-pointer text-gray-750 text-sm" onClick={() => onChange(item.value)} key={item.value}>
        <FontAwesomeIcon
          icon={selected === item.value ? ['fas', 'check-circle'] : ['far', 'circle']}
          className={"text-sm " + (selected === item.value ? "text-blue-700" : "")}
        />
        <span className="ml-2 mr-8">{item.label}</span>
      </div>
    ))}
  </div>)
};

class FeaturesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: {},
      users: [],
      ed_users: [],
      selectedUsers: [],
      confirmationModalVisible: false,
    }
  }

  componentDidMount() {
    const { partner, users, ed_users } = this.props;
    this.setState({
      partner,
      users,
      ed_users,
      selectedUsers: ed_users.map(u => ({ label: u.name, value: u.id, })),
    });
  }

  _onCheckboxChange = (key) => {
    this.setState({
      partner: {
        ...this.state.partner,
        [key]: !this.state.partner[key],
      },
    });
  }

  _openConfirmationModal = () => {
    this.setState({
      confirmationModalVisible: true,
    });
  }

  _closeConfirmationModal = () => {
    this.setState({
      confirmationModalVisible: false,
    });
  }

  _updatedValues = () => {
    const updated = {};
    const { partner } = this.state;
    Object.keys(partner).forEach(k => {
      if (partner[k] !== this.props.partner[k]) {
        updated[k] = partner[k];
      }
    });
    return updated;
  };

  render() {
    const {
      onSubmit,
      id,
      is_admin,
      partnerName,
      partnerCountry,
    } = this.props;
    const {
      partner,
      selectedUsers,
      users,
      confirmationModalVisible,
    } = this.state;

    const freeFeatures = Object.keys(partner).filter(p => FEATURES_META_DATA[p] && FEATURES_META_DATA[p].free).sort((a, b) => FEATURES_META_DATA[a].rank - FEATURES_META_DATA[b].rank);
    const paidFeatures = Object.keys(partner).filter(p => FEATURES_META_DATA[p] && !FEATURES_META_DATA[p].free).sort((a, b) => FEATURES_META_DATA[a].rank - FEATURES_META_DATA[b].rank);

    return (
      <div className="flex-1 flex flex-col bg-white overflow-y-scroll">
        <div className="flex flex-row bg-blue-100 p-2 pb-8 space-x-2">
          <div className="flex-initial pt-1">
            <FontAwesomeIcon className="text-blue-700 text-lg font-normal" icon={['fas', 'info-circle']} />
          </div>

          <div className="flex-1">
            <div className="text-base font-bold text-black"> How it works</div>

            <table className="table-auto mt-2">
              <tbody>
                <tr>
                  <td>
                    <FontAwesomeIcon className="opacity-25 text-dark-blue-800 text-sm font-normal" icon={['fas', 'hand-point-right']} />
                  </td>
                  <td className="pl-2">
                    <span className="font-bold text-black">Free features:</span>
                  </td>
                  <td className="pl-1">
                    <span className="text-gray-750">Enabled users will see the product on dashboard & menu.</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table-auto mt-2">
              <tbody>
                <tr>
                  <td>
                    <FontAwesomeIcon className="opacity-25 text-dark-blue-800 text-sm font-normal" icon={['fas', 'hand-point-right']} />
                  </td>
                  <td className="pl-2">
                    <span className="font-bold text-black">Paid features:</span>
                  </td>
                  <td className="pl-1"></td>
                </tr>
                <tr className="h-2"></tr>
                <tr>
                  <td>
                  </td>
                  <td className="pl-2">
                    <span className="font-bold text-black">DA, Connections:</span>
                  </td>
                  <td className="pl-1">
                    <span className="text-gray-750">Enabled for all users and see the product on dashboard & menu.</span>
                  </td>
                </tr>
                <tr className="h-2"></tr>
                <tr>
                  <td>
                  </td>
                  <td className="pl-2">
                    <span className="font-bold text-black">Premium profile:</span>
                  </td>
                  <td className="pl-1">
                    <span className="text-gray-750">Enabled for all users and see the product on dashboard</span>
                  </td>
                </tr>
                <tr className="h-2"></tr>
                <tr>
                  <td>
                  </td>
                  <td className="pl-2">
                    <span className="font-bold text-black">Higher Eds:</span>
                  </td>
                  <td className="pl-1">
                    <span className="text-gray-750">Enabled for all users and see the product on dashboard</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      <div class={`${!is_admin ? "opacity-50 select-none pointer-events-none" : ""}`}>
        <div className="flex-1 flex flex-col bg-white p-6">
          {freeFeatures.length > 0 && <div>
            <div className="text-black font-bold mb-3">
              Free features
            </div>

            <FeatureHeader />

            {freeFeatures.map((key) => {
              const { label } = FEATURES_META_DATA[key];
              return <div key={key} className={`flex flex-row items-center space-x-4 border-b border-gray-300 p-4`}>
                <Checkbox
                  checked={partner[key]}
                  onChange={() => {
                    this._onCheckboxChange(key);
                  }}
                  colorClass={`${partner[key] ? 'text-blue-700' : 'text-gray-750'}`}
                />
                <div className="text-black">
                  {label}
                </div>
              </div>
            })}
          </div>
          }

          {paidFeatures.length > 0 && <div className="mt-6">
            <div className="text-black font-bold mb-3">
              Paid features
            </div>

            <FeatureHeader />

            {paidFeatures.map((key) => {
              const { label } = FEATURES_META_DATA[key];
              return <div key={key} className={`flex flex-row border-b border-gray-300 p-4`}>
                <div className={`flex flex-row items-start space-x-4 w-2/5`}>
                  <Checkbox
                    checked={partner[key]}
                    onChange={() => {
                      this._onCheckboxChange(key);
                    }}
                    colorClass={`${partner[key] ? 'text-blue-700' : 'text-gray-750'}`}
                  />
                  <div className="text-black">
                    {label}
                  </div>
                </div>
                {
                  key === "is_higher_ed_visible" &&
                  partner.is_higher_ed_visible &&
                  !partner.is_higher_ed_all_users &&
                  selectedUsers.length > 0 &&
                  <div className="flex flex-wrap w-3/5">
                    {selectedUsers.map(u => <div className="flex-initial text-gray-500 font-bold bg-gray-300 rounded-md pl-1 pr-1 mr-1 mb-1">{u.label}</div>)}
                  </div>
                }
              </div>
            })}
          </div>
          }

          {partner.is_higher_ed_visible &&
            <div className={`flex flex-col items-start space-y-4 bg-blue-50 mt-4 p-4 rounded-lg shadow-md`}>
              <RadioBox
                items={[
                  { value: true, label: "Enable for all users for the university" },
                  { value: false, label: "Enable for specific users" },
                ]}
                selected={this.state.partner.is_higher_ed_all_users}
                onChange={(selectedValue) => {
                  this.setState({
                    partner: {
                      ...this.state.partner,
                      is_higher_ed_all_users: selectedValue
                    }
                  })
                }}
              />

              <div className="flex-1 w-full">
                {partner.is_higher_ed_visible &&
                  !partner.is_higher_ed_all_users &&
                  <Select
                    placeholder="Select Users"
                    options={users.map(u => ({ label: u.name, value: u.id }))}
                    onChange={selectedUsers => this.setState({ selectedUsers })}
                    value={this.state.selectedUsers}
                    isMulti
                    isClearable={false}
                  />
                }
              </div>
            </div>
          }
          {/* leave empty space at the bottom for better view of user autocomplete dropdown  */}
          <div className="h-32"></div>
        </div>

        <div className="flex-none flex p-5 justify-end border-t border-gray-300">
          <button
            disabled={
              partner.is_higher_ed_visible &&
              !partner.is_higher_ed_all_users &&
              !this.state.selectedUsers.length
            }
            onClick={this._openConfirmationModal}
            className="btn-primary">
            Save
          </button>
        </div>
      </div>

        <ConfirmationModal
          visible={confirmationModalVisible}
          title={`Products of ${this.props.partnerName}`}
          message={'Are you sure you want to save these changes?'}
          cancelText={"Cancel"}
          confirmText={"Confirm"}
          onCancel={this._closeConfirmationModal}
          onConfirm={() => {
            this._closeConfirmationModal();
            onSubmit({
              ...partner,
              is_higher_ed_all_users: !partner.is_higher_ed_visible ? false : partner.is_higher_ed_all_users, // to keep is_higher_ed_all_users false by default next time to avoid accidental & costly 'all' selection
              user_ids: this.state.selectedUsers.map(u => u.value)
            }, id)
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(FeaturesForm);
