import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getDistrictsFailure: null,
  getDistrictsRequest: ["params", "resolve", "reject"],
  getDistrictsSuccess: ["districtsData", "params"],
});

export const DistrictsTypes = Types;
export const DistrictsCreators = Creators;
