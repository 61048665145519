import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import CompletedCTA from "../completed-cta";
import MarkAsCompleteCTA from "../mark-as-complete-cta";
import { APPLICATION_SECTION } from "src/constants/applications.constants";

const CompleteAutomation = ({
  t,
  application,
  ucasQuickSubmit,
  isUCASAccountCreated,
}) => {
  const {
    education_form_complete,
    education_form_job_status,
    onboarding_show_create_button,
    onboarding_complete,
    jobs_running,
  } = ucasQuickSubmit;

  if (education_form_complete) {
    return (
      <CompletedCTA
        status={education_form_complete}
        type={APPLICATION_SECTION.EDUCATION}
      />
    );
  }

  if (
    !education_form_job_status ||
    education_form_job_status === "Pending" ||
    education_form_job_status === "Running"
  ) {
    return null;
  }

  if (
    (onboarding_show_create_button && !onboarding_complete) ||
    education_form_job_status === "Running" ||
    jobs_running
  ) {
    return (
      <>
        <button
          data-tip
          data-for={`tooltip-Completed`}
          disabled={true}
          className={"btn-border-primary mb-6"}
        >
          {t(
            "direct_apply_apps.quick_submit.account_and_onboarding.mark_as_complete_cta",
          )}
        </button>
        {!application.is_ucas_credential_updated &&
          isUCASAccountCreated && (
            <ReactTooltip
              id={`tooltip-Completed`}
              aria-haspopup="true"
              place="bottom"
              effect={"solid"}
              arrowColor={"transparent"}
            >
              <p>
                {t("direct_apply_apps.quick_submit.ucas_credential")}
              </p>
            </ReactTooltip>
          )}
      </>
    );
  }

  return (
    <MarkAsCompleteCTA
      status={education_form_complete}
      type={APPLICATION_SECTION.EDUCATION}
    />
  );
};

const mapStateToProps = state => ({
  ucasQuickSubmit: state.directApply.ucasQuickSubmit,
  application: state.directApply.selected,
});

export default withTranslation()(
  connect(mapStateToProps)(CompleteAutomation),
);
