import React, { Component } from "react";
import { ExpandedFilter, Table } from "../../../components";
import { withTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import Switch from "react-switch";
import { Format } from "../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Empty from "./empty";
import NoResultsFound from "./no-results-found";
import _ from "lodash";
import EventBus from "eventing-bus";
import { withRouter } from "react-router-dom";
import { DirectApplyTypes } from "../../../redux/actions";
import PlaceholderLogo from "../../../assets/direct-apply/university-placeholder.png";

const sortableColumns = {
  students: "first_name",
  program: "program_name",
  program_type_id: "program_types.code",
  submission_type_id: "program_submission_types.code",
  submitted_at: "submitted_at",
  start_date: "partner_programs.start_date",
  fee_waived: "fee_waived",
  status: "status",
  result: "result",
  updated_at: "student_applications.updated_at",
  choice: "choice",
  document_upload_date: "student_application_documents.created_at",
  fee_status: "fee_status",
  approved_at: "approved_at",
};

const tabs = {
  submitted: "submitted",
  unsubmitted: "unsubmitted",
};

class DirectApplicationsPage extends Component {
  state = {
    downloadingApplications: false,
    searchText: "",
    selectionRange: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      showDateDisplay: false,
    },
  };

  componentDidMount() {
    const queryParams = new URLSearchParams(
      this.props.location.search,
    );

    const currentTab = queryParams.get("tab");
    this.props.clearApplications();
    this.props.setTab(currentTab);
    this._fetchFilters(currentTab === "submitted");

    this._load();
    this._subscribeEvents();
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pageState !== this.props.pageState &&
      !_.isEqual(prevProps.pageState, this.props.pageState)
    ) {
      if (
        prevProps.pageState.searchText !==
        this.props.pageState.searchText
      )
        this._searchLoadApplications();
      else {
        this._load();
      }
    }

    if (prevProps.location.search !== this.props.location.search) {
      const queryParams = new URLSearchParams(
        this.props.location.search,
      );

      const currentTab = queryParams.get("tab");
      if (currentTab === "submitted") {
        this.props.setTab("submitted");
        this._fetchFilters(true);
        this.props.setFilters({});
      }

      if (currentTab === "unsubmitted") {
        this.props.setTab("unsubmitted");
        this._fetchFilters(false);
        this.props.setFilters({});
      }
      this._load();
    }
  }

  _subscribeEvents = () => {
    this.downloadRequestSubscription = EventBus.on(
      DirectApplyTypes.GET_DIRECT_APPLY_LIST_DOWNLOAD_URL_REQUEST,
      () => this.setState({ downloadingApplications: true }),
    );
    this.downloadSuccessSubscription = EventBus.on(
      DirectApplyTypes.GET_DIRECT_APPLY_LIST_DOWNLOAD_URL_SUCCESS,
      () =>
        this.setState(
          { downloadingApplications: false },
          this._getDownloadSuccess,
        ),
    );
    this.downloadFailureSubscription = EventBus.on(
      DirectApplyTypes.GET_DIRECT_APPLY_LIST_DOWNLOAD_URL_FAILURE,
      () => this.setState({ downloadingApplications: false }),
    );
  };

  _unsubscribeEvents = () => {
    this.downloadRequestSubscription();
    this.downloadSuccessSubscription();
    this.downloadFailureSubscription();
  };

  _fetchFilters = submitted => {
    this.props.getFilterCountries({ submitted });
    this.props.getFilterCourses({ submitted });
    this.props.getFilterPrograms({ submitted });
    this.props.getFilterResults({ submitted });
    this.props.getFilterStatus({ submitted });
    this.props.getFilterFeeWaived({ submitted });
    this.props.getFilterSchools({ submitted });
    this.props.getFilterUniversities({ submitted });
    this.props.getPartners({ submitted });
    this.props.getProgramTypes();
    this.props.getProgramSubmissionTypes();
    this.props.getFilterFeeStatus({ submitted });
  };

  _getDownloadSuccess = () => {
    const { downloadUrl } = this.props;

    if (downloadUrl) window.open(downloadUrl);
  };

  _selectCurrent = () => {
    this.props.setTab("submitted");
    this._fetchFilters(true);
    this.props.setFilters({});
  };

  _selectPast = () => {
    this.props.setTab("unsubmitted");
    this._fetchFilters(false);
    this.props.setFilters({});
  };

  _downloadApplicationsRequest = () => {
    this.props.downloadList();
  };

  _load = () => {
    return new Promise((resolve, reject) => {
      this.props.getApplications(resolve, reject);
    }).catch(err => {});
  };

  _searchLoadApplications = _.debounce(this._load, 1000);

  _search = searchText => {
    this.props.setSearchText(searchText);
  };

  _rowClassName = ({ index }) => {
    const {
      pageState: { tab },
    } = this.props;
    if (index < 0) {
      return "";
    } else {
      return (
        "app-table-row" +
        (index === this.state.selectedMemberIndex ? " selected" : "")
      );
    }
  };

  handleSelect = item => {
    this.setState({
      selectionRange: [item.selection],
    });
  };

  _onClickHeader = ({ dataKey, sortingKey }) => {
    const {
      pageState: {
        sortBy: { column: sortColumn, direction: sortDirection },
      },
    } = this.props;

    this.props.setSortBy({
      column: dataKey,
      identifier: sortingKey,
      direction:
        sortColumn === dataKey
          ? sortDirection === "desc"
            ? "asc"
            : "desc"
          : "asc",
    });
  };

  _loginSuperUser = (applicationId, partnerId) => {
    this.props.getPartnerSso(partnerId, applicationId);
  };

  render() {
    const {
      countries,
      programs,
      results,
      programTypes,
      programSubmissionTypes,
      status,
      schools,
      applications,
      matchedApplications,
      studentsCount,
      totalApplications,
      totalPastApplications,
      isNextPageLoading,
      pageState,
      universities,
      partners,
      feeStatus,
      pageState: {
        sortBy: { column: sortColumn, direction: sortDirection },
      },
      t,
      userData,
    } = this.props;

    const queryParams = new URLSearchParams(
      this.props.location.search,
    );

    const currentTab = queryParams.get("tab");
    const { downloadingApplications } = this.state;

    const hasNextPage = matchedApplications > applications.length;
    const isRowLoaded = ({ index }) =>
      !hasNextPage || index < applications.length;
    const loadMoreRows = isNextPageLoading ? () => {} : this._load;
    const counselorReviewOptions = [
      {
        id: true,
        text: "Enabled",
      },
      { id: false, text: "Disabled" },
    ];
    const booleanOptions = [
      {
        id: true,
        text: "Yes",
      },
      { id: false, text: "No" },
    ];
    const isDemoOptions = [
      {
        id: true,
        text: "Demo",
      },
      {
        id: false,
        text: "Not demo",
      },
    ];
    const filterOptions = [
      {
        id: "application_attributes",
        title: "Application Attributes",
        filterTypes: [
          {
            id: "status",
            text: "Application Status",
            icon: ["fas", "certificate"],
            isMulti: true,
            options: status.map(s => ({ id: s.id, text: s.name })),
          },
          {
            id: "program_types",
            text: "Program Type",
            open: true,
            icon: ["fas", "file-alt"],
            isMulti: true,
            options: programTypes.map(type => ({
              id: type.id,
              text: type.code,
            })),
          },
          {
            id: "submission_types",
            text: "Submission Type",
            open: true,
            icon: ["fas", "file-alt"],
            isMulti: true,
            options: programSubmissionTypes.map(type => ({
              id: type.id,
              text: type.code,
            })),
          },
        ],
      },
      {
        id: "school",
        title: "Student Information",
        filterTypes: [
          {
            id: "university",
            text: "University Name",
            icon: ["fas", "school"],
            open: true,
            isMulti: true,
            isSearchable: true,
            options: universities.map(university => ({
              id: university.id,
              text: university.name,
            })),
          },

          {
            id: "school",
            text: "School Name",
            open: true,
            icon: ["fas", "school"],
            isMulti: true,
            isSearchable: true,
            options: schools.map(school => ({
              id: school.id,
              text: school.name,
            })),
          },
          {
            id: "country",
            text: "Country",
            icon: ["fas", "location-arrow"],
            isMulti: true,
            isSearchable: true,
            open: true,
            options: countries.map(country => ({
              id: country.id,
              text: country.name,
            })),
          },
          {
            id: "is_demo",
            text: "Demo School",
            icon: ["fas", "file-alt"],
            isMulti: true,
            open: true,
            options: isDemoOptions,
          },
          // { id: "course", text: "Preferred Course/Major", icon: ['fas', 'file'], isMulti: true, options: courses.map((course) => ({id: course.id, text: course.name}) ) },
        ],
      },
      {
        id: "university",
        title: "Institute Type",
        filterTypes: [
          {
            id: "partner",
            text: "Partner Name",
            icon: ["fas", "school"],
            isMulti: true,
            isSearchable: true,
            options:
              partners &&
              partners.map(partners => ({
                id: partners.id,
                text: partners.name,
              })),
          },
          {
            id: "program",
            text: "Program Name",
            icon: ["fas", "file-alt"],
            isMulti: true,
            isSearchable: true,
            options: programs.map(program => ({
              id: program.id,
              text: program.name,
            })),
          },
          {
            id: "is_counselor_review_enabled",
            text: "Counselor Review",
            icon: ["fas", "file-alt"],
            options: counselorReviewOptions,
          },
          {
            id: "is_counsellor_support_enabled",
            text: "Counselor Availability",
            icon: ["fas", "file-alt"],
            options: booleanOptions,
          },
        ],
      },
    ];

    if (currentTab === tabs.submitted) {
      filterOptions.push({
        id: "counselor",
        title: "Institute Type",
        filterTypes: [
          {
            id: "is_reviewed_by_counselor",
            text: "Reviewed by Counselor",
            icon: ["fas", "file-alt"],
            options: booleanOptions,
          },
          {
            id: "approved_at",
            text: "Approved on",
            icon: ["fas", "calendar-check"],
            isMulti: true,
            options: [],
            type: "date-range",
          },
        ],
      });
    }

    return (
      <div className="flex flex-1">
        <div
          style={{ minWidth: "900px" }}
          className="flex-1 flex flex-col"
        >
          {totalApplications === 0 && !isNextPageLoading ? (
            <Empty />
          ) : (
            <>
              <div className="flex flex-col">
                {currentTab === "unsubmitted" &&
                totalApplications === 0 ? null : (
                  <>
                    <div className="px-6">
                      <ExpandedFilter
                        searchPlaceholder={
                          "Search by student/program name"
                        }
                        selectedFilters={pageState.filters}
                        searchText={pageState.searchText}
                        filterOptions={filterOptions}
                        onSelection={filters => {
                          this.props.setFilters(filters);
                        }}
                        onChangeSearchText={this._search}
                      />
                    </div>

                    <div className="h-px bg-gray-300 mb-3" />
                    <div className="flex mb-3 items-center px-6">
                      <div className="flex flex-col">
                        <span className="flex-none text-sm text-gray-900 font-bold mr-3">{`${matchedApplications} applications match of ${totalApplications}`}</span>
                        <span className="flex-none text-sm text-gray-900 font-bold mr-3">{`${studentsCount} students in total`}</span>
                      </div>
                      <button
                        onClick={this._downloadApplicationsRequest}
                        className="btn-secondary w-48"
                      >
                        {downloadingApplications ? (
                          <Loader
                            type="Oval"
                            color="grey"
                            height={18}
                            width={18}
                          />
                        ) : (
                          <>
                            <FontAwesomeIcon
                              icon={["fas", "download"]}
                              className="mr-2"
                            />
                            {t("common.buttons.download_students")}
                          </>
                        )}
                      </button>
                      {this.props.isAdmin ? (
                        <div
                          onClick={this._openAddUserScreen}
                          className="flex-none flex flex-row items-center cursor-pointer bg-blue-700 rounded h-36px px-2 ml-3"
                        >
                          <FontAwesomeIcon
                            icon={["fas", "plus"]}
                            className="text-sm text-white mr-2"
                          />
                          <span className="text-sm font-bold text-white leading-none ">
                            {t("common.buttons.add_member")}
                          </span>
                        </div>
                      ) : null}
                      <div className="flex-1" />
                      <span className="text-sm text-gray-750 font-bold leading-none mr-2">
                        Include past applications
                      </span>
                      <Switch
                        checked={pageState.isPast}
                        onChange={includePastApplications => {
                          this.props.setIsPast(
                            includePastApplications,
                          );
                        }}
                        onColor="#B5C2D0"
                        onHandleColor="#007BE2"
                        handleDiameter={22}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                        height={15}
                        width={36}
                        className="react-switch mr-4"
                        id="material-switch"
                      />
                    </div>
                  </>
                )}
              </div>

              {currentTab === "unsubmitted" &&
              !isNextPageLoading &&
              totalApplications === 0 ? (
                <NoResultsFound
                  title={"No unsubmitted applications found"}
                  detail={
                    "All the applications will be moved to this section with their current Result & Status, once program has been started."
                  }
                />
              ) : !isNextPageLoading && matchedApplications === 0 ? (
                <NoResultsFound
                  title={"Search not found"}
                  detail={
                    "There are no applications matching the search. Please try again with different keywords or filters"
                  }
                />
              ) : (
                <>
                  <Table
                    isRowLoaded={isRowLoaded}
                    loadMoreRows={loadMoreRows}
                    totalRowCount={matchedApplications}
                    rows={applications}
                    currentRowCount={applications.length}
                    headerHeight={46}
                    rowHeight={62}
                    rowClassName={this._rowClassName}
                    sortableColumns={sortableColumns}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    onRowClick={({ rowData, event, index }) => {
                      this.props.history.push({
                        pathname: `/applications/${rowData.id}`,
                        state: { applicationIndex: index },
                      });
                    }}
                    noRowsRenderer={() => {
                      return isNextPageLoading ? (
                        <div className="flex flex-col justify-center items-center  h-full w-full">
                          <Loader
                            type="Oval"
                            color="grey"
                            height={50}
                            width={50}
                          />
                        </div>
                      ) : null;
                    }}
                    columns={[
                      {
                        dataKey: "students",
                        width: 300,
                        type: "VerticalTwoValueBlack",
                        disableSort: true,
                        tooltip: true,
                        valueSelector: rowData => ({
                          topValue: Format.displayValue(
                            rowData.first_name +
                              " " +
                              rowData.last_name,
                          ),
                          bottomValue: Format.displayValue(
                            rowData.school_name,
                          ),
                        }),
                        header: {
                          title: t(
                            "direct_apply.applications.table.header.students",
                          ),
                          onClick: this._onClickHeader,
                        },
                      },
                      {
                        dataKey: "program",
                        width: 400,
                        type: "VerticalTwoValueBlack",
                        disableSort: true,
                        tooltip: true,
                        valueSelector: rowData => ({
                          topValue: rowData.program_name,
                          bottomValue:
                            rowData.partner === ""
                              ? rowData.university_name
                              : `${rowData.partner} - ${rowData.university_name}`,
                          icon: (
                            <img
                              src={
                                rowData["university_logo"] ||
                                PlaceholderLogo
                              }
                              alt="Uni"
                              className="table-user-image object-cover"
                            />
                          ),
                        }),
                        header: {
                          title: t(
                            "direct_apply.applications.table.header.program_and_institution",
                          ),
                          onClick: this._onClickHeader,
                        },
                      },
                      {
                        dataKey: "program_type_id",
                        width: 200,
                        type: "Default",
                        disableSort: true,
                        valueSelector: rowData => ({
                          value: rowData.program_type_code,
                        }),
                        header: {
                          title: t("Program Type"),
                          onClick: this._onClickHeader,
                        },
                      },
                      {
                        dataKey: "submission_type_id",
                        width: 200,
                        type: "Default",
                        disableSort: true,
                        valueSelector: rowData => ({
                          value: rowData.program_submission_type_code,
                        }),
                        header: {
                          title: t("Submission Type"),
                          onClick: this._onClickHeader,
                        },
                      },
                      currentTab === tabs.submitted && {
                        dataKey: "submitted_at",
                        width: 200,
                        type: "Default",
                        disableSort: true,
                        valueSelector: rowData => ({
                          value: Format.date(rowData.submitted_at),
                        }),
                        header: {
                          title: "Submitted on",
                          onClick: this._onClickHeader,
                        },
                      },
                      currentTab === tabs.submitted && {
                        dataKey: "approved_at",
                        width: 200,
                        type: "Default",
                        disableSort: true,
                        valueSelector: rowData => ({
                          value: Format.date(rowData.approved_at),
                        }),
                        header: {
                          title: "Approved on",
                          onClick: this._onClickHeader,
                        },
                      },
                      currentTab === tabs.unsubmitted && {
                        dataKey: "start_date",
                        width: 200,
                        type: "Default",
                        disableSort: true,
                        valueSelector: rowData => ({
                          value: Format.date(rowData.start_date),
                        }),
                        header: {
                          title: t(
                            "direct_apply.applications.table.header.start_date",
                          ),
                          onClick: this._onClickHeader,
                        },
                      },
                      currentTab === tabs.unsubmitted && {
                        dataKey: "deadline_date",
                        width: 200,
                        type: "Default",
                        disableSort: true,
                        valueSelector: rowData => ({
                          value: Format.date(rowData.deadline_date),
                        }),
                        header: {
                          title: "Application Deadline",
                          onClick: this._onClickHeader,
                        },
                      },
                      currentTab === tabs.submitted && {
                        dataKey: "fee_status",
                        width: 200,
                        type: "Tag",
                        disableSort: false,
                        valueSelector: rowData => ({
                          value: rowData.fee_status,
                        }),
                        className: rowData =>
                          rowData.fee_status_badge,
                        header: {
                          title: "Fee Status",
                          onClick: this._onClickHeader,
                        },
                      },
                      {
                        dataKey: "status",
                        width: 200,
                        type: "Tag",
                        disableSort: true,
                        tooltip: true,
                        valueSelector: rowData => ({
                          value: rowData.status,
                        }),
                        className: rowData =>
                          rowData.status_class_name,
                        header: {
                          title: "Application Status",
                          onClick: this._onClickHeader,
                        },
                      },
                      {
                        dataKey: "is_counselor_review_enabled",
                        width: 200,
                        type: "Tag",
                        disableSort: true,
                        tooltip: true,
                        valueSelector: rowData => ({
                          value: rowData.is_counselor_review_enabled,
                        }),
                        className: rowData =>
                          rowData.is_counselor_review_enabled ===
                          "Enabled"
                            ? "application-badge-success"
                            : "application-badge-secondary",
                        header: {
                          title: "Counselor Review",
                          onClick: this._onClickHeader,
                        },
                      },
                      {
                        dataKey: "is_counsellor_support_enabled",
                        width: 200,
                        type: "Tag",
                        disableSort: true,
                        tooltip: true,
                        valueSelector: rowData => ({
                          value:
                            rowData.is_counsellor_support_enabled,
                        }),
                        className: rowData =>
                          rowData.is_counsellor_support_enabled ===
                          "Yes"
                            ? "application-badge-success"
                            : "application-badge-secondary",
                        header: {
                          title: "Counselor Availability",
                          onClick: this._onClickHeader,
                        },
                      },
                      currentTab === tabs.submitted && {
                        dataKey: "is_reviewed_by_counselor",
                        width: 225,
                        type: "Tag",
                        disableSort: true,
                        tooltip: true,
                        valueSelector: rowData => ({
                          value: rowData.is_reviewed_by_counselor,
                        }),
                        className: rowData =>
                          rowData.is_reviewed_by_counselor === "Yes"
                            ? "application-badge-success"
                            : "application-badge-secondary",
                        header: {
                          title: "Reviewed by Counselor",
                          onClick: this._onClickHeader,
                        },
                      },
                      currentTab === tabs.submitted && {
                        dataKey: "result",
                        width: 200,
                        type: "Tag",
                        disableSort: true,
                        tooltip: true,
                        valueSelector: rowData => ({
                          value: rowData.result,
                        }),
                        className: rowData =>
                          rowData.result_class_name,
                        header: {
                          title: t(
                            "direct_apply.applications.table.header.results",
                          ),
                          onClick: this._onClickHeader,
                        },
                      },
                      currentTab === tabs.submitted && {
                        dataKey: "submitted_at",
                        width: 175,
                        type: "Tag",
                        disableSort: true,
                        tooltip: true,
                        valueSelector: rowData => ({
                          value: rowData.aging,
                        }),
                        className: rowData =>
                          rowData.aging_class_name,
                        header: {
                          title: "Aging",
                          onClick: this._onClickHeader,
                        },
                      },
                      currentTab === tabs.submitted && {
                        dataKey: "choice",
                        width: 150,
                        type: "default",
                        disableSort: true,
                        tooltip: true,
                        valueSelector: rowData => ({
                          value: rowData.choice,
                        }),
                        header: {
                          title: "Choice",
                          onClick: this._onClickHeader,
                        },
                      },
                      currentTab === tabs.submitted && {
                        dataKey: "updated_at",
                        width: 175,
                        type: "default",
                        tooltip: true,
                        disableSort: true,
                        valueSelector: rowData => ({
                          value: Format.date(rowData.updated_at),
                        }),
                        className: rowData =>
                          rowData.result_class_name,
                        header: {
                          title: "Last Updated",
                          onClick: this._onClickHeader,
                        },
                      },
                      currentTab === tabs.submitted && {
                        dataKey: "document_upload_date",
                        width: 200,
                        type: "default",
                        tooltip: true,
                        disableSort: true,
                        valueSelector: rowData => ({
                          value: Format.date(
                            rowData.document_uploaded_at,
                          ),
                        }),
                        header: {
                          title: "Doc Upload",
                          onClick: this._onClickHeader,
                        },
                      },
                      {
                        dataKey: "",
                        width: 150,
                        type: "Default",
                        disableSort: true,
                        cellRenderer: ({ rowData, cellData }) => {
                          return (userData.role.name === "admin" ||
                            userData.role.name === "da_support") &&
                            currentTab === "submitted" ? (
                            <div className="flex justify-start items-center">
                              <div
                                onClick={event => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  this._loginSuperUser(
                                    rowData.id,
                                    rowData.partner_id,
                                  );
                                }}
                              >
                                <button className="btn-primary-alt pl-1 pr-1">
                                  Update
                                </button>
                              </div>
                            </div>
                          ) : null;
                        },
                        header: {
                          title: "",
                          onClick: this._onClickHeader,
                        },
                      },
                    ].filter(Boolean)}
                  />
                </>
              )}
            </>
          )}
        </div>
        {isNextPageLoading && applications.length > 0 ? (
          <div
            className={
              "absolute bottom-0 right-0 left-0 self-center pointer-events-none flex-none flex flex-col justify-center items-center p-1"
            }
          >
            <Loader
              type="ThreeDots"
              color="grey"
              height={75}
              width={75}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(withRouter(DirectApplicationsPage));
