import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Checkbox } from "antd";
import EventBus from "eventing-bus";
import { DirectApplyCreators, DirectApplyTypes } from "../../redux/actions";

const validEmailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EditStudentDetailsModal = ({
  id,
  visible,
  onClose,
  haveCheckBox,
  value,
  isEmail,
  header,
  updateApplicationUcasData,
  fieldToBeUpdated,
  upadateData,
  isUcasId,
}) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [fieldValue, setFieldValue] = useState(value);

  const fieldError = isEmail && !new RegExp(validEmailRegex).test(fieldValue);

  useEffect(() => {
    const ucasDataUpdateSuccess = EventBus.on(
      DirectApplyTypes.UPDATE_APPLICATION_UCAS_DATA_SUCCESS,
      () => {
        upadateData(fieldValue);
        onClose();
      }
    );
    return () => {
      ucasDataUpdateSuccess();
    };
  }, [fieldValue, onClose, upadateData]);

  return (
    <ReactModal
      isOpen={visible}
      onRequestClose={() => {}}
      ariaHideApp={false}
      className="modal"
      overlayClassName="modalOverlay"
    >
      <div className="absolute left-0 right-0 top-0 bottom-0 bg-overlay-500 flex flex-col justify-center items-center z-20">
        <div className="my-20 rounded bg-white pt-6 pb-4 px-8 flex flex-col relative w-500px overflow-hidden">
          <div className={"flex flex-row justify-between"}>
            <span className="font-bold text-black mb-6 text-22">{header}</span>

            <FontAwesomeIcon
              onClick={onClose}
              icon={["fas", "times"]}
              className={"text-lg leading-none text-gray-750 cursor-pointer"}
            />
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col">
              <div className="flex flex-col">
                <Input
                  status={fieldError && fieldValue ? "error" : ""}
                  value={fieldValue}
                  onChange={(e) => setFieldValue(e.target.value)}
                  style={{ width: 300 }}
                  maxLength={isUcasId ? 10 : ""}
                />
                {haveCheckBox && (
                  <div className="pt-4">
                    {" "}
                    <Checkbox
                      onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                      value={isCheckboxChecked}
                    >
                      Update email account password
                    </Checkbox>
                  </div>
                )}
              </div>

              <button
                className="btn-primary mt-4"
                style={{ width: "fit-content" }}
                disabled={fieldError || !fieldValue}
                onClick={() => {
                  const data = {
                    student: {
                      [fieldToBeUpdated]: fieldValue,
                      ...(haveCheckBox && {
                        update_external_password: isCheckboxChecked,
                      }),
                    },
                  };
                  updateApplicationUcasData(id, data);
                }}
              >
                Save
              </button>
              {isUcasId && (
                <button
                  className="btn-primary mt-4"
                  style={{ width: "fit-content" }}
                  disabled={fieldError || !fieldValue}
                  onClick={() => {
                    const data = {
                      student: {
                        [fieldToBeUpdated]: fieldValue,
                        send_ucas_email: true,
                      },
                    };
                    updateApplicationUcasData(id, data);
                  }}
                >
                  Save and send email
                </button>
              )}
            </div>

            {fieldError && fieldValue && (
              <span className="text-red-800 text-xs">Email is not correct</span>
            )}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

function mapStateToProps(state) {
  return {
    application: state.directApply.selected,
  };
}

const mapDispatchToProps = {
  updateApplicationUcasData:
    DirectApplyCreators.updateApplicationUcasDataRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EditStudentDetailsModal));
