import { call, put, takeEvery, select } from "redux-saga/effects";
import { NewPartnersCreators, NewPartnersTypes } from "../actions";
import {
  getNewPartners,
  getNewPartnersFiltersCountries,
  getNewPartnersFilterStatuses,
  getNewPartner,
  updateNewPartner,
} from "../../web-services";
import { isEqual, omit } from "lodash";

const {
  clearNewPartners,
  getNewPartnersSuccess,
  getNewPartnersFailure,
  getNewPartnersFilterCountriesSuccess,
  getNewPartnersFilterCountriesFailure,
  getNewPartnersFilterStatusesSuccess,
  getNewPartnersFilterStatusesFailure,
  getNewPartnerSuccess,
  getNewPartnerFailure,
  approveNewPartnerSuccess,
  approveNewPartnerFailure,
  declineNewPartnerSuccess,
  declineNewPartnerFailure,
} = NewPartnersCreators;

const authTokenSelector = (state) => state.user.authToken;

export function* watchNewPartnersRequests() {
  yield takeEvery(
    NewPartnersTypes.GET_NEW_PARTNERS_REQUEST,
    requestGetNewPartners
  );
  yield takeEvery(
    NewPartnersTypes.GET_NEW_PARTNERS_FILTER_COUNTRIES_REQUEST,
    requestGetNewPartnersFilterCountries
  );
  yield takeEvery(
    NewPartnersTypes.GET_NEW_PARTNERS_FILTER_STATUSES_REQUEST,
    requestGetNewPartnersFilterStatuses
  );
  yield takeEvery(
    NewPartnersTypes.GET_NEW_PARTNER_REQUEST,
    requestGetNewPartner
  );
  yield takeEvery(
    NewPartnersTypes.APPROVE_NEW_PARTNER_REQUEST,
    requestApproveNewPartner
  );
  yield takeEvery(
    NewPartnersTypes.DECLINE_NEW_PARTNER_REQUEST,
    requestDeclineNewPartner
  );
}

export function* requestGetNewPartners(action) {
  try {
    const { params, resolve } = action;
    const partnersSelector = (state) => state.newPartners;
    const authToken = yield select(authTokenSelector);
    const { data: currentPartners, params: currentParams } = yield select(
      partnersSelector
    );
    const shouldLoadMore =
      currentPartners.length > 0 &&
      isEqual(omit(currentParams, ["page_no"]), params);

    if (!shouldLoadMore) yield put(clearNewPartners());

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;

    const requestParams = { ...params };
    requestParams["page_no"] = page;

    const response = yield call(getNewPartners, authToken, requestParams);
    resolve && resolve();
    yield put(getNewPartnersSuccess(response.data, requestParams));
  } catch (error) {
    const { reject } = action;
    reject && reject();
    yield put(getNewPartnersFailure());
  }
}

function* requestGetNewPartnersFilterCountries(action) {
  try {
    const { params } = action;
    const authTokenSelector = (state) => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getNewPartnersFiltersCountries,
      authToken,
      params
    );
    yield put(getNewPartnersFilterCountriesSuccess(response.data.countries));
  } catch (error) {
    yield put(getNewPartnersFilterCountriesFailure());
  }
}

function* requestGetNewPartnersFilterStatuses(action) {
  try {
    const { params } = action;
    const authTokenSelector = (state) => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getNewPartnersFilterStatuses,
      authToken,
      params
    );
    yield put(getNewPartnersFilterStatusesSuccess(response.data.statuses));
  } catch (error) {
    yield put(getNewPartnersFilterStatusesFailure());
  }
}

export function* requestGetNewPartner(action) {
  try {
    const { partnerId } = action;
    const authToken = yield select(authTokenSelector);

    const response = yield call(getNewPartner, authToken, partnerId);
    yield put(getNewPartnerSuccess(response.data));
  } catch (error) {
    yield put(getNewPartnerFailure());
  }
}

export function* requestApproveNewPartner(action) {
  try {
    const { partnerId, params } = action;
    const authToken = yield select(authTokenSelector);

    yield call(updateNewPartner, authToken, partnerId, params);
    yield put(approveNewPartnerSuccess());
  } catch (error) {
    yield put(approveNewPartnerFailure());
  }
}

export function* requestDeclineNewPartner(action) {
  try {
    const { partnerId, params } = action;
    const authToken = yield select(authTokenSelector);

    yield call(updateNewPartner, authToken, partnerId, params);
    yield put(declineNewPartnerSuccess());
  } catch (error) {
    yield put(declineNewPartnerFailure());
  }
}
