import { API } from '../helpers';
import { SERVER_URL } from '../config';

export const signIn = (data) => {
  return API.request({
    method: 'post',
    url: `${SERVER_URL}/api/v1/admins/sessions`,
    data: data
  });
}

export const resetPassword = ({password, reset_password_token}) => {
  return API.request({
    method: 'put',
    url: `${SERVER_URL}/api/v1/admins/passwords/reset_password?reset_password_token=${reset_password_token}`,
    data: { password }
  });
}

export const sendResetPasswordMail = (data) => {
  return API.request({
    method: 'post',
    url: `${SERVER_URL}/api/v1/admins/passwords/forgot_password`,
    data: data
  });
}