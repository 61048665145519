import React, { Component } from 'react';
import PropTypes from "prop-types";

class TextInput extends Component {
  render() {
    const {
      error,
      icon,
      rightIcon,
      ...rest
    } = this.props;

    return (
      <div className={"flex flex-col flex-1 relative ".concat(this.props.className)}>
        <div className={"w-full h-42px flex-none flex flex-row rounded border focus:border-gray-750 overflow-hidden " + (error ? " border-red-800" : "") + (this.props.classForBorder || " border-gray-500")}>
          <input
            {...rest}
            className={`p-2 w-full h-full disabled:bg-gray-200`}
          />
          {rightIcon && rightIcon}
        </div>
        {icon && icon}
        {
          error ? (
            <span className="text-red-800 text-xs font-bold mt-1">
              {error}
            </span>
          ) : null
        }
      </div>
    );
  }
}

TextInput.defaultProps = {

}

TextInput.propTypes = {
  error: PropTypes.string
}

export default TextInput;
