import EventRescheduleScreen from './features-screen';
import { connect } from 'react-redux';
import { PartnerFeaturesCreators } from '../../../redux/actions';

function mapStateToProps(state) {
  const { loading, partner, users, ed_users, id } = state.partnerFeatures;
  const {
    user: {
      is_admin,
      data: { email },
    },
  } = state;

  return {
    loading,
    partner,
    users,
    ed_users,
    id,
    is_admin,
    email,
  };
}

const mapDispatchToProps = {
  getFeatures: PartnerFeaturesCreators.getPartnerFeaturesRequest,
  updateFeatures: PartnerFeaturesCreators.updatePartnerFeaturesRequest,
  clearFeatures: PartnerFeaturesCreators.clearPartnerFeatures
}

export default connect(mapStateToProps, mapDispatchToProps)(EventRescheduleScreen);
