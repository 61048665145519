import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { reduxForm, Field } from "redux-form";
import SwitchField from "src/components/switch-field";

const ConfiguartionHtml = props => {
  const { heading, fieldName, border } = props;

  return (
    <div
      className={`flex justify-between py-3 ${border ? "border-b border-gray-300" : ""}`}
    >
      <span className="text-sm text-gray-750">{heading}</span>
      <Field component={SwitchField} name={fieldName} />
    </div>
  );
};

const ConfigurationScreen = props => {
  const { getConfiguration, t, handleSubmit } = props;

  useEffect(() => {
    getConfiguration();
  }, []);

  const onSubmit = values => {
    console.log(values);
  };

  return (
    <div className="flex flex-col h-full bg-blue-50 items-center">
      <div
        className="border border-gray-300 rounded-lg  my-6 bg-white"
        style={{ width: 500 }}
      >
        <div
          className="overflow-scroll px-6 py-4"
          style={{ height: "calc(100vh - 200px)" }}
        >
          <div className="text-base font-bold text-black">cDocs</div>
          <ConfiguartionHtml
            border
            heading="cDocs Automatic Recommendation Sending"
            fieldName="auto_recommendation_sending_allowed"
          />
          <ConfiguartionHtml
            border
            heading="Allow Parchment Activation"
            fieldName="allow_parchment_activation"
          />
          <ConfiguartionHtml
            border
            heading="Allow Common App Activation"
            fieldName="enable_cdocs"
          />
          <ConfiguartionHtml
            border
            heading="Allow Common App Activation"
            fieldName="enable_cdocs"
          />
          <ConfiguartionHtml
            border
            heading="Enable PA authorization for students"
            fieldName="enable_pa_authorization"
          />
          <ConfiguartionHtml
            border
            heading="Share UCAS account password to students"
            fieldName="show_ucas_password_to_student"
          />

          <div className="text-base font-bold text-black pt-10">
            Registration
          </div>

          <ConfiguartionHtml
            border={false}
            heading="Can student signup"
            fieldName="can_student_sign_up"
          />

          <div className="text-base font-bold text-black pt-7">
            Misc
          </div>

          <ConfiguartionHtml
            border
            heading="Enable Captcha"
            fieldName="enable_captcha"
          />
          <ConfiguartionHtml
            border
            heading="Enable Survey"
            fieldName="enable_surveys"
          />
          <ConfiguartionHtml
            border
            heading="Enable Broadcast"
            fieldName="enable_broadcast"
          />
          <ConfiguartionHtml
            border
            heading="Enable Onboarding Questions"
            fieldName="enable_onboarding_questions"
          />
          <ConfiguartionHtml
            border
            heading="Make phone number mandatory during registration"
            fieldName="enable_stu_onboarding_phone_no_question"
          />
          <ConfiguartionHtml
            border
            heading="Collect guardian/parent phone number at onboarding"
            fieldName="enable_guardian_info_onboarding"
          />

          <div className="text-base font-bold text-black pt-10">
            Connections
          </div>
          <ConfiguartionHtml
            border={false}
            heading="Enable Creating Connections - Beta"
            fieldName="enable_connections"
          />

          <div className="text-base font-bold text-black pt-10">
            Scholarships
          </div>

          <ConfiguartionHtml
            border={false}
            heading="Enable Cialfo Scholarship"
            fieldName="enable_cialfo_scholarship"
          />
        </div>

        <div className="flex justify-end py-4 px-6 ">
          <button
            className="btn-primary ml-4"
            type={"submit"}
            onClick={handleSubmit(onSubmit)}
          >
            {t("clients.save_changes")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(
  reduxForm({
    form: "configurationForm",
    enableReinitialize: true,
  })(ConfigurationScreen),
);
