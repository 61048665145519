import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  clearApplications: null,
  clearTransactions: null,
  clearTransaction: null,
  clearRefundActivities: null,
  clearPrograms: null,

  getApplicationFailure: null,
  getApplicationRequest: ["id"],
  getApplicationSuccess: ["application"],

  updateApplicationUcasDataFailure: null,
  updateApplicationUcasDataRequest: ["userId", "data"],
  updateApplicationUcasDataSuccess: null,

  updatedUcasCredentialFailure: null,
  updatedUcasCredentialRequest: ["userId", "data"],
  updatedUcasCredentialSuccess: ["data"],

  getDocumentListFailure: null,
  getDocumentListRequest: null,
  getDocumentListSuccess: ["documents"],

  getFollowUpsFailure: null,
  getFollowUpsRequest: ["applicationId"],
  getFollowUpsSuccess: ["followUps"],

  addFollowUpFailure: null,
  addFollowUpRequest: ["data"],
  addFollowUpSuccess: null,

  uploadCounselorDocumentFailure: null,
  uploadCounselorDocumentRequest: ["userId", "data"],
  uploadCounselorDocumentSuccess: null,

  getApplicationsFailure: null,
  getApplicationsRequest: ["resolve", "reject"],
  getApplicationsSuccess: [
    "applications",
    "matched",
    "total",
    "pastTotal",
    "params",
    "studentsCount",
  ],

  getDirectApplicationActivitiesFailure: null,
  getDirectApplicationActivitiesRequest: ["applicationId"],
  getDirectApplicationActivitiesSuccess: ["activities"],

  getDirectApplyFilterCountriesFailure: null,
  getDirectApplyFilterCountriesRequest: ["params"],
  getDirectApplyFilterCountriesSuccess: ["countries"],

  getDirectApplyFilterCoursesFailure: null,
  getDirectApplyFilterCoursesRequest: ["params"],
  getDirectApplyFilterCoursesSuccess: ["courses"],

  getDirectApplyFilterProgramsFailure: null,
  getDirectApplyFilterProgramsRequest: ["params"],
  getDirectApplyFilterProgramsSuccess: ["programs"],

  getDirectApplyFilterResultsFailure: null,
  getDirectApplyFilterResultsRequest: ["params"],
  getDirectApplyFilterResultsSuccess: ["results"],

  getDirectApplyFilterStatusFailure: null,
  getDirectApplyFilterStatusRequest: ["params"],
  getDirectApplyFilterStatusSuccess: ["status"],

  getDirectApplyFilterFeeStatusFailure: null,
  getDirectApplyFilterFeeStatusRequest: ["params"],
  getDirectApplyFilterFeeStatusSuccess: ["statuses"],

  getDirectApplyFilterFeeWaivedFailure: null,
  getDirectApplyFilterFeeWaivedRequest: ["params"],
  getDirectApplyFilterFeeWaivedSuccess: ["statuses"],

  getDirectApplyFilterSchoolsFailure: null,
  getDirectApplyFilterSchoolsRequest: ["params"],
  getDirectApplyFilterSchoolsSuccess: ["schools"],

  getDirectApplyListDownloadUrlFailure: null,
  getDirectApplyListDownloadUrlRequest: null,
  getDirectApplyListDownloadUrlSuccess: ["url"],

  getTransactionsFailure: null,
  getTransactionsRequest: ["resolve", "reject"],
  getTransactionsSuccess: [
    "transactions",
    "matched",
    "total",
    "params",
  ],

  getTransactionFailure: null,
  getTransactionRequest: ["transactionId"],
  getTransactionSuccess: ["transaction"],

  refundFailure: null,
  refundRequest: ["transactionId", "params", "resolve", "reject"],
  refundSuccess: ["refund"],

  getProgramsFailure: null,
  getProgramsRequest: ["params", "resolve", "reject"],
  getProgramsSuccess: ["programs", "matched", "total", "params"],

  getDirectApplyFilterUniversitiesFailure: null,
  getDirectApplyFilterUniversitiesRequest: ["params"],
  getDirectApplyFilterUniversitiesSuccess: ["universities"],

  getDirectApplyFilterTransactionsUniversitiesFailure: null,
  getDirectApplyFilterTransactionsUniversitiesRequest: ["params"],
  getDirectApplyFilterTransactionsUniversitiesSuccess: [
    "universities",
  ],

  getDirectApplyFilterPartnersFailure: null,
  getDirectApplyFilterPartnersRequest: ["params"],
  getDirectApplyFilterPartnersSuccess: ["partners"],

  getDirectApplyFilterProgramTypesFailure: null,
  getDirectApplyFilterProgramTypesRequest: ["params"],
  getDirectApplyFilterProgramTypesSuccess: ["programTypes"],

  getDirectApplyFilterProgramSubmissionTypesFailure: null,
  getDirectApplyFilterProgramSubmissionTypesRequest: ["params"],
  getDirectApplyFilterProgramSubmissionTypesSuccess: [
    "programSubmissionTypes",
  ],

  updateApplicationStatusFailure: null,
  updateApplicationStatusRequest: ["id", "updateData"],
  updateApplicationStatusSuccess: ["updatedApplicationAttributes"],

  updateApplicationResultFailure: null,
  updateApplicationResultRequest: ["id", "updateData"],
  updateApplicationResultSuccess: ["updatedApplicationAttributes"],

  updateApplicationDepositPaidFailure: null,
  updateApplicationDepositPaidRequest: ["id", "updateData"],
  updateApplicationDepositPaidSuccess: [
    "updatedApplicationAttributes",
  ],

  getDirectApplicationResultsFailure: null,
  getDirectApplicationResultsRequest: null,
  getDirectApplicationResultsSuccess: ["results"],

  deleteDirectApplicationDocumentFailure: null,
  deleteDirectApplicationDocumentRequest: ["document"],
  deleteDirectApplicationDocumentSuccess: ["id"],

  getProgramsListDownloadUrlFailure: null,
  getProgramsListDownloadUrlRequest: ["params", "resolve", "reject"],
  getProgramsListDownloadUrlSuccess: ["url"],

  getProgramsFilterUniversitiesFailure: null,
  getProgramsFilterUniversitiesRequest: ["params"],
  getProgramsFilterUniversitiesSuccess: ["universities"],

  getProgramsFilterStatusesFailure: null,
  getProgramsFilterStatusesRequest: ["params"],
  getProgramsFilterStatusesSuccess: ["statuses"],

  getProgramsFilterRoundsFailure: null,
  getProgramsFilterRoundsRequest: ["params"],
  getProgramsFilterRoundsSuccess: ["rounds"],

  setApplicationStatusFailure: null,
  setApplicationStatusRequest: ["id", "status"],
  setApplicationStatusSuccess: ["updatedApplicationAttributes"],

  submitApplicationFailure: null,
  submitApplicationRequest: ["id"],
  submitApplicationSuccess: ["updatedApplicationAttributes"],

  unsubmitApplicationFailure: null,
  unsubmitApplicationRequest: ["id"],
  unsubmitApplicationSuccess: ["updatedApplicationAttributes"],

  getDirectApplicationNotesFailure: null,
  getDirectApplicationNotesRequest: ["applicationId"],
  getDirectApplicationNotesSuccess: ["note"],

  saveDirectApplicationNotesFailure: null,
  saveDirectApplicationNotesRequest: ["note"],
  saveDirectApplicationNotesSuccess: ["activities"],

  getDirectApplicationStatusListFailure: null,
  getDirectApplicationStatusListRequest: null,
  getDirectApplicationStatusListSuccess: ["statuses"],

  getDirectApplicationResultListFailure: null,
  getDirectApplicationResultListRequest: null,
  getDirectApplicationResultListSuccess: ["results"],
  getDirectApplyProgramsCountriesFailure: null,
  getDirectApplyProgramsCountriesRequest: ["params"],
  getDirectApplyProgramsCountriesSuccess: ["countries"],

  getDirectApplyProgramsCitizenshipsFailure: null,
  getDirectApplyProgramsCitizenshipsRequest: ["params"],
  getDirectApplyProgramsCitizenshipsSuccess: ["citizenships"],

  getDirectApplyProgramsPartnersFailure: null,
  getDirectApplyProgramsPartnersRequest: ["params"],
  getDirectApplyProgramsPartnersSuccess: ["partners"],

  getDirectApplySsoFailure: null,
  getDirectApplySsoRequest: ["partnerId", "applicationId"],
  getDirectApplySsoSuccess: ["partnerData"],

  getUcasQuickSubmit: ["appId"],
  getUcasQuickSubmitFailure: ["error"],
  getUcasQuickSubmitSuccess: ["info"],

  getTaggingQuickSubmit: ["appId"],
  getTaggingQuickSubmitFailure: ["error"],
  getTaggingQuickSubmitSuccess: ["info"],

  requestMarkComplete: ["appId", "params"],
  requestMarkCompleteFailure: ["error"],
  requestMarkCompleteSuccess: ["info"],

  requestMarkTaggingComplete: ["appId", "params"],
  requestMarkTaggingCompleteFailure: ["error"],
  requestMarkTaggingCompleteSuccess: ["info"],

  requestUcasChoices: ["appId"],
  requestUcasChoicesFailure: ["error"],
  requestUcasChoicesSuccess: ["info"],

  fillTaggingFormRequest: ["appId"],
  fillTaggingFormFailure: ["error"],
  fillTaggingFormSuccess: ["info"],
});

export const DirectApplyTypes = Types;
export const DirectApplyCreators = Creators;
