import { createReducer } from "reduxsauce";
import { NewPartnersFiltersTypes as Types, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.newPartnersSearchState;

export const clearNewPartnersFilters = (state, action) => INITIAL_STATE;

export const setNewPartnersFilters = (state, action) => ({
  ...state,
  filters: action.filters,
  filtersChanged: true,
});

export const setNewPartnersSearchText = (state, action) => ({
  ...state,
  searchText: action.text,
});

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CLEAR_NEW_PARTNERS_FILTERS]: clearNewPartnersFilters,

  [Types.SET_NEW_PARTNERS_FILTERS]: setNewPartnersFilters,

  [Types.SET_NEW_PARTNERS_SEARCH_TEXT]: setNewPartnersSearchText,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
