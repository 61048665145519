import { call, put, takeEvery, select } from "redux-saga/effects";
import { StudentsCreators, StudentsTypes } from "../actions";
import {
  getStudents,
  getStudentEmail,
  createUcasAccount,
} from "../../web-services";
import _ from "lodash";
import { Notification } from "../../helpers";

const authTokenSelector = (state) => state.user.authToken;

export function* watchStudentsRequests() {
  yield takeEvery(StudentsTypes.GET_STUDENTS_REQUEST, requestGetStudents);
  yield takeEvery(
    StudentsTypes.GET_STUDENT_EMAIL_REQUEST,
    requestGetStudentEmail
  );
  yield takeEvery(
    StudentsTypes.CREATE_UCAS_ACCOUNT_REQUEST,
    requestCreateUcasAccount
  );
}

export function* requestGetStudents(action) {
  try {
    const { params, resolve } = action;
    const studentsSelector = (state) => state.students;
    const authToken = yield select(authTokenSelector);
    const { data: currentStudents, params: currentParams } = yield select(
      studentsSelector
    );
    const shouldLoadMore =
      currentStudents.length > 0 &&
      _.isEqual(_.omit(currentParams, ["page_no"]), params);

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;

    const requestParams = { ...params };
    requestParams["page_no"] = page;
    const response = yield call(getStudents, authToken, requestParams);
    resolve && resolve();
    yield put(
      StudentsCreators.getStudentsSuccess(response.data, requestParams)
    );
  } catch (error) {
    yield put(StudentsCreators.getStudentsFailure());
  }
}

export function* requestGetStudentEmail(action) {
  try {
    const { id } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getStudentEmail, authToken, id);
    yield put(StudentsCreators.getStudentEmailSuccess(response.data, id));
  } catch (error) {
    yield put(StudentsCreators.getStudentEmailFailure());
  }
}

export function* requestCreateUcasAccount(action) {
  try {
    const { id } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(createUcasAccount, authToken, id);
    yield put(StudentsCreators.createUcasAccountSuccess(response.data, id));
    if (response?.data) {
      Notification.success("Ucas account creation request queued successfully");
    }
  } catch (error) {
    yield put(StudentsCreators.createUcasAccountFailure());
  }
}
