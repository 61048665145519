import React from "react";
import { resolveValueSelector, getTooltipAttrs } from "../helpers";

export const BlueColumn = props => {
  const { rowData, valueSelector, tooltip } = props;
  const { value } = resolveValueSelector(rowData, valueSelector);
  const tooltipAttrs = getTooltipAttrs(value, tooltip);

  return (
    <div className={"flex flex-col overflow-hidden"}>
      <span
        className="text-xs font-normal text-blue-700 truncate"
        {...tooltipAttrs}
      >
        {value}
      </span>
    </div>
  );
};
