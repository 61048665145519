import { call, put, takeEvery, select } from "redux-saga/effects";
import { PartnerFeaturesCreators, PartnerFeaturesTypes } from "../actions";
import { getPartnerFeatures, updatePartnerFeatures } from "../../web-services";
const authTokenSelector = (state) => state.user.authToken;

export function* watchPartnerFeaturesRequests() {
  yield takeEvery(
    PartnerFeaturesTypes.GET_PARTNER_FEATURES_REQUEST,
    requestGetPartnerFeatures
  );
  yield takeEvery(
    PartnerFeaturesTypes.UPDATE_PARTNER_FEATURES_REQUEST,
    requestUpdatePartnerFeatures
  );
}

export function* requestGetPartnerFeatures(action) {
  try {
    const { partnerId } = action;
    const authToken = yield select(authTokenSelector);

    const response = yield call(getPartnerFeatures, authToken, partnerId);
    yield put(
      PartnerFeaturesCreators.getPartnerFeaturesSuccess(
        { ...response.data },
        partnerId
      )
    );
  } catch (error) {
    yield put(PartnerFeaturesCreators.getPartnerFeaturesFailure());
  }
}

export function* requestUpdatePartnerFeatures(action) {
  try {
    const { partnerId, partner } = action;
    const authToken = yield select(authTokenSelector);

    yield call(updatePartnerFeatures, authToken, partnerId, partner);
    yield put(
      PartnerFeaturesCreators.getPartnerFeaturesSuccess(
        partner.partner,
        partnerId
      )
    ); //to update the store
    yield put(PartnerFeaturesCreators.updatePartnerFeaturesSuccess());
  } catch (error) {
    yield put(PartnerFeaturesCreators.updatePartnerFeaturesFailure());
  }
}
