import React from "react";
import {
  APPLICATION_STATUS,
  FIELD_TYPE,
} from "src/constants/applications.constants";
import { DisplayField } from "./direct-application-detail-screen";
import { DisplayDocuments } from "./display-documents";
import { DisplayList } from "./display-list";

const HeadingLarge = ({ text }) => (
  <h2 className="text-xl text-gray-750 font-bold mb-4">{text}</h2>
);

const HeadingMedium = ({ text }) => (
  <h2 className="text-lg text-gray-750 font-bold">{text}</h2>
);

const DirectApplicationDetailNew = ({ application, isWithdrawn, isNew }) => {
  return (
    <>
      {application.v2_questionnaire &&
        application.v2_questionnaire.sections.map((section) => {
          return (
            <div
              className={`flex flex-col display-section ml-2 mb-6 ${
                isNew ||
                isWithdrawn ||
                application.status === APPLICATION_STATUS.toReview
                  ? "opacity-50 select-none pointer-events-none"
                  : ""
              }`}
            >
              <HeadingLarge text={section.name} />
              {section.sub_sections.map((subSection, index) => {
                return (
                  subSection && (
                    <>
                      {index !== 0 && <div className="mt-4" />}
                      <HeadingMedium text={subSection.name} />
                      <div className="flex flex-col display-field-container">
                        {subSection.questions.map((question) => {
                          if (
                            question.field_type === FIELD_TYPE.singleUpload ||
                            question.field_type === FIELD_TYPE.multipleUpload
                          ) {
                            return (
                              <DisplayDocuments
                                label={question.label}
                                value={question.files}
                                copyable
                              />
                            );
                          }
                          if (question.field_type === FIELD_TYPE.list) {
                            return (
                              <DisplayList
                                value={question.item_questions}
                                item_name={question.item_name}
                                predicted_grades_sent={
                                  application.predicted_grades_sent
                                }
                              />
                            );
                          }
                          return (
                            <DisplayField
                              label={question.label}
                              value={question.answer_value}
                              isVerified={question.is_verified}
                              copyable
                            />
                          );
                        })}
                      </div>
                    </>
                  )
                );
              })}
            </div>
          );
        })}
    </>
  );
};

export { DirectApplicationDetailNew };
