import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { DirectApplyCreators } from "../../../../redux/actions";

const CompletedCTA = ({
  t,
  application,
  type,
  requestMarkComplete,
  status,
  requestMarkTaggingComplete,
  tagging,
}) => {
  const toggleCompletion = () => {
    const params = {
      section: type,
      complete: !status,
    };
    if (tagging) {
      requestMarkTaggingComplete(application?.id, params);
    } else {
      requestMarkComplete(application?.id, params);
    }
  };

  return (
    <button
      onClick={toggleCompletion}
      className="bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition duration-300 ease-in-out px-4 py-2 rounded-md flex items-center justify-center mb-6"
    >
      <span className="font-bold">
        {t(
          "direct_apply_apps.quick_submit.account_and_onboarding.completed_cta",
        )}{" "}
        <FontAwesomeIcon
          icon={faCheck}
          className="text-white w-4 h-4 ml-1"
        />
      </span>
    </button>
  );
};

const mapStateToProps = state => ({
  application: state.directApply.selected,
});

const mapDispatchToProps = {
  requestMarkComplete: DirectApplyCreators.requestMarkComplete,
  requestMarkTaggingComplete:
    DirectApplyCreators.requestMarkTaggingComplete,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CompletedCTA),
);
