import React from "react";
import { Modal } from "antd";
import Button from "src/components/ButtonAntDesign/button";

const Dialog = (props) => {
  const {
    body,
    handleOk,
    handleCancel,
    isModalVisible,
    title,
    okButtonText,
    showCloseIcon = true,
    cancelButtonText,
    overlayClick,
    okButtonVariant,
    okButtonType,
    cancelButtonVariant,
    cancelButtonType,
    handleCloseIcon,
    isCancelButton,
    width,
    hideButtons,
  } = props;

  return (
    <Modal
      title={title}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCloseIcon || handleCancel}
      closable={showCloseIcon}
      maskClosable={overlayClick}
      centered
      footer={
        hideButtons
          ? []
          : [
              isCancelButton && (
                <Button
                  text={cancelButtonText}
                  type={cancelButtonType}
                  variant={cancelButtonVariant}
                  onClick={handleCancel}
                  key="cancel"
                />
              ),
              <Button
                text={okButtonText}
                type={okButtonType}
                variant={okButtonVariant}
                onClick={handleOk}
                key="ok"
              />,
            ]
      }
      width={width}
      data-testid="dialog"
    >
      {body}
    </Modal>
  );
};

Dialog.defaultProps = {
  showCloseIcon: true,
  overlayClick: false,
  handleCloseIcon: undefined,
  isCancelButton: true,
  width: 500,
  hideButtons: false,
};

export default Dialog;
