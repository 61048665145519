import { connect } from "react-redux";
import { PartnersCreators, MiscCreators } from "../../../redux/actions";
import AddPartner from "./add-partner";
import { withTranslation } from "react-i18next";
import { reduxForm } from "redux-form";
import validator from "validator";

function mapStateToProps(state) {
  return {
    countries: state.misc.countries,
    partnerAdded: state.partners.partnerAdded,
    loading: state.partners.addingPartner,
  };
}

const mapDispatchToProps = {
  getCountries: MiscCreators.getCountriesRequest,
  savePartner: PartnersCreators.savePartnerRequest,
};

const validate = (values, props) => {
  const errors = {};
  const { name, institute_type, email, country_id, phone, website_url } =
    values;
  const { t } = props;

  if (email && !validator.isEmail(email))
    errors["email"] = t("validation.error.email_invalid");

  if (!name) errors["name"] = `Name ${t("validation.error.required")}`;

  if (!institute_type || !institute_type.value)
    errors["institute_type"] = `Institute type ${t(
      "validation.error.required"
    )}`;

  if (!country_id || !country_id.value)
    errors["country_id"] = `Country ${t("validation.error.required")}`;

  if (website_url && !validator.isURL(website_url, { require_protocol: true }))
    errors.website_url = "Invalid URL";

  if (phone && !validator.isNumeric(phone || ""))
    errors["phone"] = t("validation.error.phone_invalid");

  return errors;
};
export default withTranslation()(
  reduxForm({
    form: "partnerForm",
    initialValues: {},
    validate: validate,
    enableReinitialize: true,
  })(connect(mapStateToProps, mapDispatchToProps)(AddPartner))
);
