import React, { Component } from 'react';
import {
  TextInputField
} from '../../form-components';
import validator from 'validator';
import { Field } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RadioBox = (props) => {
  const {
    input,
    items,
    meta: { touched, error }
  } = props;

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex-1 flex items-center">
        {items.map((item) => (
          <div className="cursor-pointer text-gray-750 text-sm pr-8" onClick={() => input.onChange(item.value)} key={item.value}>
            <FontAwesomeIcon
              icon={input.value === item.value ? ['fas', 'check-circle'] : ['far', 'circle']}
              className={"text-sm " + (input.value === item.value ? "text-blue-700" : "")}
            />
            <span className="ml-2 mr-6">{item.label}</span>
          </div>
        ))}
      </div>
      {
        touched && error ? (
          <span className="input-field-error-text mt-px">{error}</span>
        ) : null
      }
    </div>
  )
}

const RenderInputField = (props) => {
  const {
    input,
    meta: { error, touched },
    ...rest
  } = props;
  const showError = touched && error;

  return (
    <div className="flex flex-col">
      <div className={`flex flex-row border ${showError ? 'border-red-800' : 'border-gray-500'} rounded w-full overflow-hidden`}>
        <div className="flex-none flex w-12 bg-gray-50 border-r border-gray-500 justify-center items-center">
          <span className="text-xs text-gray-500 leading-none">{rest.placeholder}</span>
        </div>
        <div className="flex flex-col">
          <input
            {...input}
            {...rest}
            placeholder=''
            className={`input-field-with-label ${showError ? 'input-field-error' : ''} focus:outline-none`}
          />
        </div>
      </div>
      {
        showError ? (
          <span className="input-field-error-text">
            {error}
          </span>
        ) : null
      }
    </div>
  );
}

class UserForm extends Component {
  render() {
    const {
      showStatusAndRole,
      handleSubmit,
      onSubmit,
      submitButtonText,
      disableEmail,
      t
    } = this.props;

    return (
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex-1 flex flex-col bg-white overflow-y-scroll">
          <div className="flex flex-none flex-col p-5">
            <span className="text-sm text-gray-900 font-bold mb-2">{t('common.labels.personal_information')}</span>

            <div className="flex flex-row pt-6">
              <div className="flex-initial w-48 pt-3">
                <span className="text-sm text-gray-750 mb-2 leading-none">{t('common.labels.name')}</span>
                <span className="text-sm text-red-800 mb-2 ml-1 leading-none">({t('common.labels.required')})</span>
              </div>
              <div className="flex-1 flex-row">
                <div className="flex">
                  <Field
                    name="first_name"
                    component={RenderInputField}
                    placeholder={t('common.labels.first')}
                  />

                  <div className="w-5 flex-none" />

                  <Field
                    name="last_name"
                    component={RenderInputField}
                    placeholder={t('common.labels.last')}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row mt-5">
              <div className="flex-initial w-48 pt-3">
                <span className="text-sm text-gray-750 mb-2 leading-none">{t('common.labels.email')}</span>
                <span className="text-sm text-red-800 mb-2 ml-1 leading-none">({t('common.labels.required')})</span>
              </div>
              <div className="flex-1">
                <Field
                  disabled={disableEmail}
                  name="email"
                  component={TextInputField}
                  type="email"
                  placeholder={t('common.placeholders.email')}
                  style={{ borderColor: '#A5A5A5' }}
                />
              </div>
            </div>
          </div>

          {
            showStatusAndRole ? (
              <div className="flex flex-none flex-col p-5 mt-5">
                <span className="text-sm text-gray-900 font-bold mb-2">{t('common.labels.status_and_role')}</span>

                <div className="flex flex-row mb-4 mt-3">
                  <div className="flex-initial w-48 flex items-center">
                    <span className="text-sm text-gray-750 mb-2">{t('common.labels.account_status')}</span>
                    <span className="text-sm text-red-800 mb-2 ml-1 leading-none">({t('common.labels.required')})</span>
                  </div>
                  <div className="flex-1">
                    <Field
                      name="is_active"
                      component={RadioBox}
                      items={[
                        { value: "1", label: t('common.labels.active') },
                        { value: "0", label: t('common.labels.inactive') }
                      ]}
                    />
                  </div>
                </div>

                <div className="flex flex-row mt-3">
                  <div className="flex-initial w-48 flex items-center">
                    <span className="text-sm text-gray-750 mb-2">{t('common.labels.role')}</span>
                    <span className="text-sm text-red-800 mb-2 ml-1 leading-none">({t('common.labels.required')})</span>
                  </div>
                  <div className="flex-1">
                    <Field
                      name="role"
                      component={RadioBox}
                      items={[
                        { value: "admin", label: t('common.roles.admin') },
                        { value: "cs", label: t('common.roles.cs') },
                        { value: "da_support", label: t('common.roles.da_support') }
                      ]}
                    />
                  </div>
                </div>
              </div>
            ) : null
          }
        </div>

        <div className="flex flex-row justify-end p-5 border-t border-gray-300">
          <button onClick={handleSubmit(onSubmit)} type={"submit"} className="btn-primary">
            {submitButtonText}
          </button>
        </div>
      </div>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  const {
    is_active,
    first_name,
    last_name,
    email,
    role,
  } = values;
  const { t } = props;


  if (!email)
    errors['email'] = `Email ${t('validation.error.required')}`;
  else if (!validator.isEmail(email))
    errors['email'] = t('validation.error.email_invalid');

  if (!first_name)
    errors['first_name'] = `First Name ${t('validation.error.required')}`;

  if (!last_name)
    errors['last_name'] = `Last Name ${t('validation.error.required')}`;

  if (!is_active)
    errors['is_active'] = `Account Status ${t('validation.error.required')}`;

  if (!role)
    errors['role'] = `Role ${t('validation.error.required')}`;

  return errors
}

export { UserForm, validate };
