import React, { Component } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
} from "react-beautiful-dnd";
import { cloneDeep, map, findIndex } from "lodash";
import {
  FormQuestion,
  ConfirmationModal,
} from "../../../../components";
import { TextInputField } from "../../../../form-components";
import { Field } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PROGRAM_STATUS } from "../../../../constants";
import { Notification } from "../../../../helpers";
import { ActionBar } from "../common";

const addOldOrderField = questions =>
  questions.map((q, index) => ({
    ...q,
    oldOrder: index,
  }));

const shuffleQuestions = (event, questions) => {
  const { source, destination } = event;
  const newQuestions = [...questions];
  const sourceQn = newQuestions[source.index];
  sourceQn.sort_order = destination.index;

  if (source.index < destination.index) {
    for (let i = source.index; i < destination.index; i += 1) {
      newQuestions[i] = newQuestions[i + 1];
      newQuestions[i].sort_order = i;
    }
  } else {
    for (let i = source.index; i > destination.index; i -= 1) {
      newQuestions[i] = newQuestions[i - 1];
      newQuestions[i].sort_order = i;
    }
  }

  newQuestions[destination.index] = sourceQn;

  return newQuestions;
};

const getOldToNewOrderMapping = questions => {
  const oldToNewOrderMapping = {};
  questions.forEach((q, index) => {
    oldToNewOrderMapping[q.oldOrder] = index;
  });
  return oldToNewOrderMapping;
};

const updateConditionalReferences = (
  questions,
  oldToNewOrderMapping,
) => {
  const newQuestions = [...questions];
  newQuestions.forEach((q, index) => {
    if (
      q.field_type === "conditional" &&
      q.question_meta &&
      q.question_meta.length
    ) {
      q.question_meta.forEach(qm => {
        if (qm.child_sort_number) {
          if (
            parseInt(oldToNewOrderMapping[qm.child_sort_number]) <=
            index
          ) {
            qm.child_sort_number = null;
          } else {
            qm.child_sort_number = parseInt(
              oldToNewOrderMapping[qm.child_sort_number],
            );
          }
        }
      });
    }

    delete q.oldOrder;
  });

  return newQuestions;
};

const QuestionsField = props => {
  const { input, reorderQuestions } = props;
  const questions = cloneDeep(input.value);

  return (
    <Droppable droppableId="droppable">
      {provided => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {input.value.map((item, index) => (
            <Draggable
              draggableId={`additionalReq_${index}`}
              index={index}
              key={index}
            >
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <FormQuestion
                    index={index}
                    onChange={question => {
                      questions[index] = { ...item, value: question };
                      input.onChange([...questions]);
                    }}
                    onRemove={() => {
                      reorderQuestions(
                        null,
                        questions.map(q => q.value),
                        index,
                      );
                    }}
                    {...item}
                  />
                </div>
              )}
            </Draggable>
          ))}

          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

class AdditionalRequirements extends Component {
  state = {
    isSaveAsDraftModalVisible: false,
  };

  isSavedAndValid = () => {
    const { valid, dirty, submitSucceeded } = this.props;
    return valid && (dirty ? submitSucceeded : true);
  };

  _isSubsetOfConditional = index => {
    const { questions } = this.props;
    const conditionalQns = questions.filter(
      qn => qn.field_type === "conditional",
    );
    return (
      findIndex(
        conditionalQns,
        item =>
          findIndex(
            item.question_meta,
            meta => parseInt(meta.child_sort_number) === index,
          ) >= 0,
      ) >= 0
    );
  };

  _showValidationErros = errors => {
    let numberOfErrors = 0;

    if (errors && Object.keys(errors).length > 0) {
      const errorMessagesComponent = map(errors, (error, index) => {
        if (typeof error !== "string") {
          numberOfErrors += Object.keys(error).length;

          return (
            <>
              {Object.keys(error).map(errorQuestionIndex => (
                <div
                  className="h-5"
                  key={index + "" + errorQuestionIndex}
                >
                  Question {Number(errorQuestionIndex) + 1}:{" "}
                  {error[errorQuestionIndex]}
                </div>
              ))}
            </>
          );
        } else {
          numberOfErrors += 1;

          return (
            <div className="h-5" key={index}>
              {error}
            </div>
          );
        }
      });

      if (errors) {
        Notification.error(
          <div className="flex flex-col">
            {errorMessagesComponent}
          </div>,
          {
            customFields: {
              title: `${numberOfErrors} error${
                numberOfErrors > 1 ? "s" : ""
              } in this section`,
            },
          },
        );
      }
    }
  };

  _onSubmit = event => {
    const errors = this.props.handleSubmit(this._save)(event);
    this._showValidationErros(errors);
  };

  _onSubmitDraft = event => {
    const errors = this.props.handleSubmit(
      this._saveAsDraftShowModal,
    )(event);
    this._showValidationErros(errors);
  };

  _save = values => {
    this._submit(values);
  };

  _saveAsDraftShowModal = values => {
    return new Promise((resolve, reject) => {
      this._showSaveAsDraftModal();
      reject();
    });
  };

  _confirmSaveAsDraft = event => {
    const errors = this.props.handleSubmit(values => {
      this._submit(values, true);
    })(event);
    this._showValidationErros(errors);
    this._hideSaveAsDraftModal();
  };

  _submit = (values, saveAsDraft) => {
    const { questions_title, questions } = values;
    const { id, questions: currentQuestions } = this.props;
    const questionsToRemove = currentQuestions.filter(
      question => !questions.find(q => q.value.id === question.id),
    );
    const formData = new FormData();

    const getChildSortNumber = val => {
      if (val === "skip") {
        return -2;
      }

      if (val === "end") {
        return -1;
      }

      if (parseInt(val) >= 0) {
        return parseInt(val);
      }

      return "";
    };

    formData.append(
      "partner_program[questions_title]",
      questions_title || "",
    );

    questions.forEach((question, index) => {
      if (question.value.id)
        formData.append(
          "partner_program[program_questions_attributes][][id]",
          question.value.id,
        );

      formData.append(
        "partner_program[program_questions_attributes][][title]",
        question.value.title || "",
      );
      formData.append(
        "partner_program[program_questions_attributes][][field_type]",
        question.value.field_type,
      );
      formData.append(
        "partner_program[program_questions_attributes][][is_required]",
        question.value.is_required,
      );
      formData.append(
        "partner_program[program_questions_attributes][][sort_order]",
        index,
      );

      question.value.question_meta.forEach((meta_option, i) => {
        switch (question.value.field_type) {
          case "checkboxes":
          case "multiple_choice":
            formData.append(
              "partner_program[program_questions_attributes][][question_meta][][text]",
              meta_option.text || "",
            );
            break;
          case "linear_scale":
            formData.append(
              "partner_program[program_questions_attributes][][question_meta][][from_range_label]",
              meta_option.from_range_label || "",
            );
            formData.append(
              "partner_program[program_questions_attributes][][question_meta][][to_range_label]",
              meta_option.to_range_label || "",
            );
            formData.append(
              "partner_program[program_questions_attributes][][question_meta][][from_range]",
              meta_option.from_range,
            );
            formData.append(
              "partner_program[program_questions_attributes][][question_meta][][to_range]",
              meta_option.to_range,
            );
            break;
          case "conditional":
            formData.append(
              "partner_program[program_questions_attributes][][question_meta][][text]",
              meta_option.text || "",
            );
            formData.append(
              "partner_program[program_questions_attributes][][question_meta][][child_sort_number]",
              getChildSortNumber(meta_option.child_sort_number),
            );
            break;
          default:
        }
      });
    });

    questionsToRemove.forEach((question, i) => {
      formData.append(
        "partner_program[program_questions_attributes][][id]",
        question.id,
      );
      formData.append(
        "partner_program[program_questions_attributes][][is_deleted]",
        true,
      );
    });

    if (saveAsDraft) {
      formData.append(
        "partner_program[status]",
        PROGRAM_STATUS.DRAFT,
      );
      this.props.saveProgramDraft(id, formData);
    } else {
      formData.append(
        "partner_program[status]",
        PROGRAM_STATUS.PENDING_REVIEW,
      );
      this.props.updateProgram(id, formData);
    }
  };

  _showSaveAsDraftModal = () => {
    this.setState({ isSaveAsDraftModalVisible: true });
  };

  _hideSaveAsDraftModal = () => {
    this.setState({ isSaveAsDraftModalVisible: false });
  };

  _addQuestion = () => {
    this.props.change("questions", [
      ...this.props.currentQuestions,
      {
        key: this.props.currentQuestions.length,
        value: {
          title: "",
          field_type: "string",
          question_meta: [],
          is_required: false,
        },
      },
    ]);
  };

  _copy = (question, index) => {
    const newCollection = cloneDeep(this.state.collection);
    newCollection.splice(index, 0, question);
    this.setState({ collection: newCollection });
  };

  /**
   * @description - Shuffle questions on drag/drop event or after deletion, update conditional questions references, and change redux form value
   * @param {Object} event
   * @param {Array} prevQuestions
   * @param {Number} index
   */
  reorderQuestions = (event, prevQuestions, index) => {
    const { change } = this.props;
    let newQuestions = [...prevQuestions];

    newQuestions = addOldOrderField(newQuestions); // to have the record of original order of each question before the shuffle

    if (event) {
      newQuestions = shuffleQuestions(event, newQuestions);
    } else {
      newQuestions.splice(index, 1);
    }

    const oldToNewOrderMapping =
      getOldToNewOrderMapping(newQuestions);

    newQuestions = updateConditionalReferences(
      newQuestions,
      oldToNewOrderMapping,
    );

    change(
      "questions",
      newQuestions.map((item, key) => ({
        key,
        value: item,
      })),
    );
  };

  render() {
    const {
      questions_title,
      isUpdating,
      isSavingDraft,
      status,
      t,
      application_available,
      currentQuestions,
    } = this.props;
    const { isSaveAsDraftModalVisible } = this.state;
    const isEditable = status !== PROGRAM_STATUS.PENDING_REVIEW;

    return (
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex flex-1 flex-col overflow-scroll mr-4">
          <div
            className={`flex flex-col pointer-events-none ${
              application_available ? "opacity-50 select-none" : ""
            }`}
          >
            <span className="text-gray-750 text-lg font-bold mb-6">
              Additional Requirements
            </span>

            <div className="flex border border-gray-300 rounded-lg px-8 py-6">
              <span className="text-sm text-gray-750 mr-20 mt-2">
                Title
              </span>

              <div
                className={`flex flex-col flex-1 ${
                  isEditable
                    ? ""
                    : "opacity-50 select-none pointer-events-none"
                }`}
              >
                <Field
                  name="questions_title"
                  type="text"
                  component={TextInputField}
                  placeholder="Title"
                  maxLength={70}
                />
                <div className="flex flex-row justify-end mt-1">
                  <span className="text-gray-750 text-xs font-regular">
                    <span className="font-bold">
                      {70 -
                        (questions_title
                          ? questions_title.length
                          : 0)}
                    </span>{" "}
                    characters left
                  </span>
                </div>
              </div>
            </div>

            <div className="h-4"></div>

            <DragDropContext
              onDragEnd={event =>
                this.reorderQuestions(
                  event,
                  currentQuestions.map(q => q.value),
                )
              }
            >
              <Field
                name="questions"
                onCopy={this._Copy}
                component={QuestionsField}
                reorderQuestions={this.reorderQuestions}
              />
            </DragDropContext>
          </div>
        </div>
      </div>
    );
  }
}

export function validate(values, props) {
  const errors = {};
  const { questions_title, questions } = values;

  if (!questions_title)
    errors.questions_title = "Questions Title is required";

  questions &&
    questions.forEach((question, questionIndex) => {
      if (!question.value.title) {
        errors.questions = errors.questions || {};
        errors.questions[questionIndex] =
          "Question Title is required";
      } else {
        switch (question.value.field_type) {
          case "single_choice":
          case "multiple_choice":
            question.value.question_meta.forEach(
              (qMeta, qMetaIndex) => {
                if (!qMeta.text) {
                  errors.questions = errors.questions || {};
                  errors.questions[questionIndex] =
                    "Please fill all options";
                }
              },
            );

            break;
          case "range":
            if (question.value.question_meta.length === 0) {
              errors.questions = errors.questions || {};
              errors.questions[questionIndex] =
                "Please fill all options";
            } else {
              question.value.question_meta.forEach(
                (qMeta, qMetaIndex) => {
                  if (
                    !qMeta.from_range_label ||
                    !qMeta.to_range_label ||
                    !qMeta.from_range ||
                    !qMeta.to_range
                  ) {
                    errors.questions = errors.questions || {};
                    errors.questions[questionIndex] =
                      "Please fill all options";
                  }
                },
              );
            }

            break;
          case "conditional":
            if (question.value.question_meta.length < 2) {
              errors.questions = errors.questions || {};
              errors.questions[questionIndex] =
                "Please add at least two choices";
            } else if (
              question.value.question_meta.filter(
                ({ text }) => text === "",
              ).length > 0
            ) {
              errors.questions = errors.questions || {};
              errors.questions[questionIndex] =
                "Please fill all options";
            } else if (
              question.value.question_meta.filter(
                ({ child_sort_number }) =>
                  [undefined, ""].includes(child_sort_number),
              ).length > 0
            ) {
              errors.questions = errors.questions || {};
              errors.questions[questionIndex] =
                "Please fill in all conditions";
            }

            break;
          default:
        }
      }
    });

  return errors;
}

export default AdditionalRequirements;
