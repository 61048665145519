import React, { Component } from "react";
import ThirdPartySelect, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const singleOption = (props: OptionProps<any>) => (
  <components.Option {...props}>
    <div className="flex flex-row items-center">
      {props.data.icon && (
        <div className="w-6 h-6 object-contain">
          <FontAwesomeIcon
            icon={props.data.icon.icon}
            className={props.data.icon.className}
          />
        </div>
      )}

      <span className="ml-2 text-sm text-gray-750">{props.data.label}</span>
    </div>
  </components.Option>
);

const singleValue = (props: any) => (
  <components.SingleValue {...props}>
    <div className="ml-1 flex flex-row items-center">
      {props.data.icon && (
        <div className="w-6 h-6 object-contain">
          <FontAwesomeIcon
            icon={props.data.icon.icon}
            className={props.data.icon.className}
          />
        </div>
      )}

      <span className="text-sm text-gray-750">{props.data.label}</span>
    </div>
  </components.SingleValue>
);

class Select extends Component {
  constructor(props) {
    super(props);

    this.customStyles = {
      control: (provided, state) => {
        return {
          ...provided,
          borderWidth: props.borderNone ? 0 : 1,
          borderColor: state.isFocused ? "#017BE2" : "#D8D8D8",
          boxShadow: "none",
          minHeight: 40,
          flex: 1,
        };
      },
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isFocused ? "#EBF9FF" : null,
          color: "#4A4A4A",
        };
      },
      menu: (provided, state) => ({
        ...provided,
        fontFamily: props.fontFamily,
        fontSize: props.fontSize || 14,
        color: props.textColor || "#1E1E1E",
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        fontFamily: props.fontFamily,
        fontSize: props.fontSize || 14,
        paddingLeft: props.paddingLeft || undefined,
        color: props.textColor || "#1E1E1E",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        fontFamily: props.fontFamily,
        color: props.textColor || "#1E1E1E",
      }),
      multiValue: (provided, state) => ({
        ...provided,
        fontFamily: props.fontFamily,
        backgroundColor: "#EAF5FF",
        color: "#FFFFFF",
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        color: "#0B3664",
        fontFamily: props.fontFamily,
        fontSize: 14,
      }),
      multiValueRemove: (provided, state) => ({
        ...provided,
        color: "#0B3664",
        ":hover": {
          backgroundColor: "#EAF5FF",
        },
      }),
      placeholder: (provided, state) => ({
        ...provided,
        color: "#979797",
        fontFamily: props.fontFamily,
      }),
    };
    this.customTheme = {
      borderRadius: 4,
    };
  }

  render() {
    const { error, borderNone, dropdownIndicator, height, classForBorder } =
      this.props;
    this.customStyles.control = (provided, state) => {
      return {
        ...provided,
        borderWidth: borderNone ? 0 : 1,
        borderColor:
          classForBorder ||
          (error ? "#D0021B" : state.isFocused ? "#017BE2" : "#979797"),
        // backgroundColor: error ? '#FFE1E4' : '#000000',
        boxShadow: "none",
        minHeight: height || 42,
        flex: 1,
      };
    };

    return (
      <div className="flex flex-col flex-1">
        <ThirdPartySelect
          styles={this.customStyles}
          theme={this.customTheme}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => (
              <div className="px-4">{dropdownIndicator}</div>
            ),
            SingleValue: singleValue,
            Option: singleOption,
          }}
          {...this.props}
        />
        {error ? (
          <span className="input-field-error-text mt-2 m-px">{error}</span>
        ) : null}
      </div>
    );
  }
}

Select.defaultProps = {
  dropdownIndicator: (
    <FontAwesomeIcon
      icon={["fas", "sort-down"]}
      className="text-sm text-gray-500 leading-none mb-2px"
    />
  ),
};

export default Select;
