import AdditionalRequirements, { validate } from "./additional-requirements";
import { connect } from "react-redux";
import { reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { DirectApplyProgramCreators } from "../../../../redux/actions";
import { withTranslation } from 'react-i18next';
import { snakeCase } from "lodash";
const formSelector = formValueSelector('programAdditionalRequirementsForm');

function mapStateToProps(state) {
  const {
    directApplyProgram: {
      isUpdating,
      isSavingDraft,
      program: {
        id,
        status,
        questions,
        application_available,
      },
    },
  } = state;

  return {
    id,
    status: snakeCase(status),
    questions,
    questions_title: formSelector(state, 'questions_title'),
    currentQuestions: formSelector(state, 'questions'),
    isUpdating,
    isSavingDraft,
    formValues: getFormValues('programAdditionalRequirementsForm')(state),
    application_available,
  };
};

const mapDispatchToProps = {
  updateProgram: DirectApplyProgramCreators.updateProgramRequest,
  saveProgramDraft: DirectApplyProgramCreators.saveProgramDraftRequest,
};

export default withTranslation(null, { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    reduxForm({
      form: "programAdditionalRequirementsForm",
      validate,
      enableReinitialize: true,
    })(AdditionalRequirements)
  )
);
