import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = (props) => {
  const {
    text,
    icon,
    disabled,
    type,
    variant,
    iconOnright,
    loading,
    onClick,
    submit,
    deadlinePassed,
    classes,
    ...rest
  } = props;

  return (
    <button
      {...rest}
      className={`${classes} 
        ${
          deadlinePassed
            ? "button-disabled"
            : disabled
            ? `button-${type}-disabled`
            : loading
            ? `button-${type}-loading-${variant}`
            : `button-${type}-${variant}`
        }
          `}
      disabled={disabled}
      onClick={onClick}
      type={submit ? "submit" : "button"}
    >
      <span>
        {loading ? (
          <span className="w-1.25rem h-1.25rem mr-1">
            <FontAwesomeIcon
              icon={["fas", "spinner"]}
              className="min-w-14 fa-pulse"
              title="loaderIcon"
            />
          </span>
        ) : null}
        {icon && !iconOnright ? (
          <span className="w-1.25rem h-1.25rem mr-1">
            <FontAwesomeIcon icon={icon} className="min-w-14" title="icon" />
          </span>
        ) : null}

        {text}
        {icon && iconOnright ? (
          <span className="w-1.25rem h-1.25rem ml-1">
            <FontAwesomeIcon icon={icon} className="min-w-14" title="icon" />{" "}
          </span>
        ) : null}
      </span>
    </button>
  );
};

export default Button;
