import React, { Component } from "react";
import "antd/dist/antd.min.css";
import "./App.css";
import Routes from "./routes";
import "./icons";
import { BrowserRouter } from "react-router-dom";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { ErrorBoundary } from "@appsignal/react";
import Appsignal from "@appsignal/javascript";
import { UserCreators } from "./redux/actions";
import { APP_SIGNAL_KEY } from "./config";

const appsignal = new Appsignal({
  key: APP_SIGNAL_KEY,
});

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.sessionListener = EventBus.on(
      "session_expired",
      this.props.logout,
    );
  }
  render() {
    return (
      // <div className="flex-row items-center justify-between h-full relative">
      <ErrorBoundary instance={appsignal}>
        <BrowserRouter>
          <React.Fragment>
            <Routes {...this.props} />
          </React.Fragment>
        </BrowserRouter>
      </ErrorBoundary>

      // </div>
    );
  }
}

// function mapStateToProps
const mapDispatchToProps = {
  logout: () => UserCreators.logout(),
};

export default connect(null, mapDispatchToProps)(App);
