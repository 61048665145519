import React, { useEffect } from "react";
import Dialog from "src/components/dialog/dialog";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EventBus from "eventing-bus";
import { Notification } from "../../../helpers";
import { DirectApplyCreators } from "../../../redux/actions";

const isDeadlinePassed = deadline => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return new Date(deadline) < today;
};

const SubmissionDialog = ({
  id,
  application,
  visible,
  onClose,
  submitApplication,
  getApplication,
}) => {
  useEffect(() => {
    const submitApplicationSubscription = EventBus.on(
      DirectApplyCreators.submitApplicationSuccess().type,
      submitApplicationSuccess,
    );
    return () => {
      submitApplicationSubscription();
    };
  }, []);

  const submitApplicationSuccess = () => {
    getApplication(id);
    onClose();
    Notification.success("Application submitted successfully");
  };
  return (
    <Dialog
      isModalVisible={visible}
      body={
        <>
          {(isDeadlinePassed(application?.deadline_date) ||
            application?.submission_limit_reached) && (
            <div className="flex items-start text-base text-gray-1000 mb-2 bg-yellow-100 py-2">
              <FontAwesomeIcon
                icon={["far", "exclamation-triangle"]}
                className="text-base px-4 text-orange-800 leading-none pt-1"
              />
              {application?.submission_limit_reached
                ? "Submitting this application will result in exceeding this student's DA application limit"
                : "The deadline for this application has passed. Please make sure the application can be processed before submiting it."}
            </div>
          )}
          <span className="text-base text-gray-1000 pb-2">
            By clicking on 'Submit application' this application and
            documents will be sent to the college for review. You
            cannot undo this action.
          </span>
        </>
      }
      title="Submit application"
      okButtonText="Submit"
      okButtonType="contain"
      okButtonVariant="primary"
      cancelButtonText="Cancel"
      cancelButtonVariant="secondary"
      cancelButtonType="outline"
      handleCancel={() => onClose()}
      handleCloseIcon={() => onClose()}
      handleOk={() => submitApplication(id)}
    />
  );
};

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  submitApplication: DirectApplyCreators.submitApplicationRequest,
  getApplication: DirectApplyCreators.getApplicationRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SubmissionDialog));
