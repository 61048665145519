import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  clearApplicationFilters: null,

  setApplicationFilters: ["filters"],

  clearTransactionFilters: null,

  setTransactionFilters: ["filters"],

  setDirectApplyApplicationsSearchText: ["text"],

  setDirectApplySortBy: ["sortBy"],

  setDirectApplyTransactionsSortBy: ["sortBy"],

  setDirectApplyTab: ["tab"],

  setDirectApplyTransactionsSearchText: ["text"],

  setDirectApplyIsPast: ["isPast"],
});

export const DirectApplyFiltersTypes = Types;
export const DirectApplyFiltersCreators = Creators;
