import { createReducer } from 'reduxsauce'
import { UserTypes as Types } from '../actions';
import storeInitialState from '../store/initial-state';

export const INITIAL_STATE = storeInitialState.user;

// reducer functions
export const authenticationFailure = (state, action) => ({ ...state, authentication_token: false });
export const authenticationRequest = (state, action) => ({ ...state, authentication_token: true });
export const authenticationSuccess = (state, action) => {
  const { response } = action;

  return {
    ...state,
    data: response,
    authToken: response.authentication_token,
    authentication_token: false,
    is_admin: response.is_admin,
  };
}

export const resetPasswordFailure = (state, action) => state;
export const resetPasswordRequest = (state, action) => state;
export const resetPasswordSuccess = (state, action) => state;

export const checkSessionFailure = (state, action) => ({ ...state, loading: false });
export const checkSessionRequest = (state, action) => ({ ...state, loading: true });
export const checkSessionSuccess = (state, action) => {
  const { response } = action;

  return {
    ...state,
    data: response,
    showOnboarding: response.last_login ? false : true,
    authToken: response.authentication_token,
    loading: false
  };
};

export const sendResetPasswordMailFailure = (state, action) => state;
export const sendResetPasswordMailRequest = (state, action) => state;
export const sendResetPasswordMailSuccess = (state, action) => state;

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.AUTHENTICATION_FAILURE]:             authenticationFailure,
  [Types.AUTHENTICATION_REQUEST]:             authenticationRequest,
  [Types.AUTHENTICATION_SUCCESS]:             authenticationSuccess,

  [Types.CHECK_SESSION_FAILURE]:              checkSessionFailure,
  [Types.CHECK_SESSION_REQUEST]:              checkSessionRequest,
  [Types.CHECK_SESSION_SUCCESS]:              checkSessionSuccess,

  [Types.SEND_RESET_PASSWORD_MAIL_FAILURE]:   sendResetPasswordMailFailure,
  [Types.SEND_RESET_PASSWORD_MAIL_REQUEST]:   sendResetPasswordMailRequest,
  [Types.SEND_RESET_PASSWORD_MAIL_SUCCESS]:   sendResetPasswordMailSuccess,

  [Types.LOGOUT]:                             logout
};

export default createReducer(INITIAL_STATE, HANDLERS);
