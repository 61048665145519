import React from "react";
import { Format } from "../../../helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from "lodash";

const FileUpload = ({question}) => {
  const { file, file_name } = question;

  return (
    <div className="rounded-md flex flex-col px-3 w-64">
      <span className="text-gray-750 text-sm mb-2 font-bold">{question.question_name}{question.is_required ? <span className="text-red-800 ml-px">*</span> : null}</span>
      <div className={`flex-1 flex flex-row overflow-hidden ${!file ? "bg-gray-50 text-gray-500" : "text-blue-700"}`}>
        <div className={`flex-none flex w-10 h-10 justify-center items-center ${!file ? "" : "bg-blue-50"}`}>
          <FontAwesomeIcon
            icon={['fas', 'file-pdf']}
            className={"text-2xl leading-none"}
          />
        </div>
        <div className="flex-1 flex flex-row items-center ml-3 overflow-hidden">
          <p className="text-xs truncate">{!file_name ? "No file uploaded" : question.file_name}</p>
        </div>
        {!question.file
          ? null : (
          <div
            onClick={() => {
              if (question.file)
                window.open(question.file);
            }}
            className="flex-none flex w-10 justify-center items-center cursor-pointer">
            <FontAwesomeIcon
              icon={['fas', 'download']}
              className={"text-lg text-gray-750 leading-none"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const ShortAnswer = ({question}) => {
  return (
    <div className="rounded-md flex flex-col px-3">
      <span className="text-gray-750 text-sm mb-2 font-bold">{question.question_name}{question.is_required ? <span className="text-red-800 ml-px">*</span> : null}</span>
      <div className="flex flex-row">
        <div className="text-sm text-gray-800 mb-3">{Format.displayValue(question.answer)}</div>
      </div>
    </div>
  );
}

const ParagraphAnswer = ({question}) => {
  return (
    <div className="rounded-md flex flex-col px-3">
      <span className="text-gray-750 text-sm mb-2 font-bold">{question.question_name}{question.is_required ? <span className="text-red-800 ml-px">*</span> : null}</span>
      <div className="flex flex-row">
        <div className="text-sm text-gray-800 mb-3">{Format.displayValue(question.answer)}</div>
      </div>
    </div>
  );
}

const SingleChoice = ({question}) => {
  return (
    <div className="rounded-md flex flex-col px-3">
      <span className="text-gray-750 text-sm mb-2 font-bold">{question.question_name}{question.is_required ? <span className="text-red-800 ml-px">*</span> : null}</span>
      {
        question.question_meta.map((q_meta, i) => (
          <div key={i} className="cursor-pointer text-gray-750 text-sm mb-1">
            <FontAwesomeIcon
              icon={question.answer === q_meta.text ? ['fas', 'check-circle'] : ['far', 'circle']}
              className={"text-sm " + (question.answer === q_meta.text ? "text-blue-700"  : "")}
            />
            <span className="ml-2 mr-8">{q_meta.text}</span>
          </div>
        ))
      }
    </div>
  );
}

const MultipleChoice = ({question}) => {
  const selectedOptions = JSON.parse(question.answer) || [];

  return (
    <div className="rounded-md flex flex-col px-3">
      <span className="text-gray-750 text-sm mb-2 font-bold">
        {question.question_name}{question.is_required ? <span className="text-red-800 ml-px">*</span> : null}
      </span>
      {
        question.question_meta.map((q_meta, i) => (
          <div key={i} className="cursor-pointer text-gray-750 text-sm mb-1">
            <FontAwesomeIcon
              icon={selectedOptions.findIndex(x => x === q_meta.text)>-1 ? ['fas', 'check-square'] : ['far', 'square']}
              className={"text-sm " + (selectedOptions.findIndex(x => x === q_meta.text)>-1 ? "text-blue-700"  : "")}
            />
            <span className="ml-2 mr-8">{q_meta.text}</span>
          </div>
        ))
      }
    </div>
  );
}

const DateQuestion = ({question}) => {
  return (
    <div className="rounded-md flex flex-col px-3">
      <span className="text-gray-750 text-sm mb-2 font-bold">{question.question_name}{question.is_required ? <span className="text-red-800 ml-px">*</span> : null}</span>

      <div className="flex flex-row">
        <div className="text-sm text-gray-800 mb-3">{Format.displayValue(question.answer)}</div>
      </div>
    </div>
  );
}

const Range = ({question}) => {
  const range = question.question_meta[0];

  return (
    <div className="rounded-md flex flex-col px-3">
      <span className="text-gray-750 text-sm mb-2 font-bold">{question.question_name}{question.is_required ? <span className="text-red-800 ml-px">*</span> : null}</span>

      <div className="flex flex-row items-end">
        <span className="text-sm mr-3 text-gray-800">{range ? range.from_range_label : ""}</span>
        {range && typeof range.from_range !== "undefined" && typeof range.to_range !== "undefined" ? (
          _.range(Number(range.from_range), Number(range.to_range) + 1).map(val => (
            <div key={val} className="flex flex-col w-8 items-center cursor-pointer">
              <span className="text-sm text-gray-800 mb-2">{val}</span>
              <FontAwesomeIcon
              icon={Number(question.answer) === val ? ['fas', 'check-circle'] : ['far', 'circle']}
              className={"text-sm " + (Number(question.answer) === val ? "text-blue-700"  : "text-gray-800")}
            />
            </div>
          ))
        ) : null}
        <span className="text-sm ml-3 text-gray-800">{range ? range.to_range_label : ""}</span>
      </div>
    </div>
  );
}

export const AdditionalRequirements = ({ question }) => {
  const { field_type } = question;

  switch(field_type) {
    case "string":
      return <ShortAnswer question={question} />
    case "text":
      return <ParagraphAnswer question={question} />
    case "multiple_choice":
      return <SingleChoice question={question} />
    case "checkboxes":
      return <MultipleChoice question={question} />
    case "date":
      return <DateQuestion question={question} />
    case "linear_scale":
      return <Range question={question} />
    case "file":
      return <FileUpload question={question} />
    default:
      return null;
  }
};