import StudentsScreen from "./students";
import { connect } from "react-redux";
import { ClientsCreators } from "src/redux/actions";

function mapStateToProps(state) {
  return {
    students: state.clients.students.data,
    total: state.clients.students.total,
    matched: state.clients.students.matched,
    isNextPageLoading: state.clients.students.loading,
  };
}

const mapDispatchToProps = {
  getStudents: ClientsCreators.getSchoolStudentsRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StudentsScreen);
