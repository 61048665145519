import CounselorsScreen from "./configuration";
import { connect } from "react-redux";
import { ClientsCreators } from "src/redux/actions";

function mapStateToProps(state) {
  return {
    initialValues: state.clients.configuration,
  };
}

const mapDispatchToProps = {
  getConfiguration: ClientsCreators.getConfigurationRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CounselorsScreen);
