import React from "react";
import { Redirect, Switch } from "react-router-dom";
import UnAuthenticated from "./un-authenticated";
import ProtectedRoute from "./protected";
import NewRequestDetail from "../views/partners/new-request-detail";
import UsersPage from "../views/users";
import LoginPage from "../views/login";
import ResetPasswordScreen from "../views/reset-password";
import ForgotPasswordScreen from "../views/forgot-password";
import DirectApplyPage from "../views/direct-apply/direct-apply.jsx";
import PartnersPage from "../views/partners/partners.jsx";
import DirectApplicationDetailScreen from "../views/direct-apply/direct-application-detail";
import EditProgramScreen from "../views/direct-apply/edit-program";
import TeamScreen from "../views/team";
import AddPartner from "../views/partners/add-partner";
import ClientsScreen from "../views/clients";
import ClientDetailsScreen from "../views/clients/client-details-screen.jsx";
import DistrictsScreen from "../views/districts";
import DistrictDetailsScreen from "../views/districts/district-details-screen";
import { admin, cs, da_support } from "../constants/user-roles.json";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <UnAuthenticated exact path="/login" component={LoginPage} />

        <UnAuthenticated
          exact
          path="/forgot-password"
          component={ForgotPasswordScreen}
        />

        <UnAuthenticated
          exact
          path="/reset-password"
          component={ResetPasswordScreen}
        />

        <ProtectedRoute
          exact
          path="/users"
          component={UsersPage}
          allowedRoles={[admin, cs]}
        />

        <ProtectedRoute
          path="/direct-apply"
          component={DirectApplyPage}
          allowedRoles={[admin, da_support]}
        />

        <ProtectedRoute
          exact
          path="/applications/:id"
          component={DirectApplicationDetailScreen}
          allowedRoles={[admin, da_support]}
        />

        <ProtectedRoute
          exact
          path="/programs/:id"
          component={EditProgramScreen}
          allowedRoles={[admin, da_support]}
        />

        <ProtectedRoute
          path="/partners"
          component={PartnersPage}
          allowedRoles={[admin, cs]}
        />

        <ProtectedRoute
          exact
          path="/universities/:id"
          component={NewRequestDetail}
          allowedRoles={[admin, cs]}
        />

        <ProtectedRoute
          exact
          path="/schools/:id"
          component={NewRequestDetail}
          allowedRoles={[admin, cs]}
        />

        <ProtectedRoute
          exact
          path="/other-partners/:id"
          component={NewRequestDetail}
          allowedRoles={[admin, cs]}
        />

        <ProtectedRoute
          exact
          path="/new-requests/:id"
          component={NewRequestDetail}
          allowedRoles={[admin, cs]}
        />

        <ProtectedRoute
          path="/team"
          component={TeamScreen}
          allowedRoles={[admin]}
        />

        <ProtectedRoute
          exact
          path="/add-partner"
          component={AddPartner}
          allowedRoles={[admin, cs]}
        />

        {/* <ProtectedRoute
          path="/tenants"
          component={ClientsScreen}
          allowedRoles={[admin]}
        /> */}

        {/* <ProtectedRoute
          path="/tenant/:id"
          component={ClientDetailsScreen}
          allowedRoles={[admin]}
        /> */}

        {/* <ProtectedRoute
          path="/districts"
          component={DistrictsScreen}
          allowedRoles={[admin]}
        /> */}

        {/* <ProtectedRoute
          path="/district/:id"
          component={DistrictDetailsScreen}
          allowedRoles={[admin]}
        /> */}

        <Redirect from="*" to="/login" />
      </Switch>
    );
  }
}

export default Routes;
