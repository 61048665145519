import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { Filter, Table } from "../../components";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import copy from "clipboard-copy";
import _ from "lodash";
import "react-virtualized/styles.css";
import { Format } from "src/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditStudentDetailsModal from "./edit-student-details-modal";

const sortableColumns = {
  name: "users.first_name",
  email: "users.email",
  school_name: "partners.name",
  graduation_year: "students.graduation_year",
};

const BluredCellData = ({
  data,
  isEmail,
  id,
  fieldToBeUpdated,
  header,
  haveCheckBox,
  isUcasId,
  getStudentEmail,
  emailId,
  createUcasAccount,
  ucasAccountJobStatus,
}) => {
  const [isvisible, setIsvisible] = useState(false);
  const [updatedData, setUpdatedData] = useState(data);
  const [
    isEditStudentDetailsModalVisible,
    setIsEditStudentDetailsModalVisible,
  ] = useState(false);

  useEffect(() => {
    setUpdatedData(data);
  }, [data]);

  return (
    <div className="flex flex-col items-start">
      <div className="flex flex-row w-full">
        <span
          data-tip={isvisible ? updatedData : ""}
          className={`overflow-hidden truncate text-xs ${
            !isvisible && updatedData ? "filter blur" : ""
          }`}
        >
          {updatedData || "-"}
        </span>
      </div>

      <div className="flex pt-1 items-center">
        {updatedData && (
          <>
            <FontAwesomeIcon
              onClick={() => {
                setIsvisible(!isvisible);
              }}
              icon={isvisible ? ["fal", "eye-slash"] : ["fal", "eye"]}
              className={
                "text-lg leading-none text-gray-750 cursor-pointer"
              }
            />
            <FontAwesomeIcon
              onClick={() => copy(updatedData)}
              icon={["far", "copy"]}
              className={
                "text-lg leading-none text-gray-750 ml-3 cursor-pointer"
              }
            />
          </>
        )}

        <FontAwesomeIcon
          onClick={() => {
            setIsEditStudentDetailsModalVisible(true);
          }}
          icon={["fas", "edit"]}
          className={
            "text-lg leading-none text-gray-750 cursor-pointer ml-3"
          }
        />
        {!updatedData && isEmail && (
          <button
            className="btn-primary ml-4"
            onClick={() => {
              getStudentEmail(id);
            }}
          >
            Request email
          </button>
        )}
        {!updatedData && emailId && isUcasId && (
          <button
            className="btn-primary ml-2"
            onClick={() => {
              createUcasAccount(id);
            }}
            disabled={ucasAccountJobStatus === "Running"}
          >
            Request UCAS account
          </button>
        )}
      </div>

      {isEditStudentDetailsModalVisible && (
        <EditStudentDetailsModal
          id={id}
          visible={isEditStudentDetailsModalVisible}
          onClose={() => setIsEditStudentDetailsModalVisible(false)}
          value={updatedData}
          header={header}
          isEmail={isEmail}
          fieldToBeUpdated={fieldToBeUpdated}
          haveCheckBox={haveCheckBox}
          upadateData={e => {
            setUpdatedData(e);
          }}
          isUcasId={isUcasId}
        />
      )}
    </div>
  );
};

class Students extends React.Component {
  state = {
    searchText: "",
    searchEmail: "",
    sortColumn: "",
    sortDirection: "",
  };

  componentDidMount() {
    this._load(true);
  }

  _load = loadMore => {
    const {
      searchText,
      searchEmail,
      prevParams,
      sortColumn,
      sortDirection,
    } = this.state;

    const params = {
      search_text: searchText,
      search_email: searchEmail,
      ...(sortColumn && {
        sort_params: [
          {
            sort_by: sortableColumns[sortColumn],
            sort_direction: sortDirection,
          },
        ],
      }),
    };

    if (loadMore === true || !_.isEqual(params, prevParams)) {
      if (!loadMore)
        this.setState({ prevParams: params, isLoading: true });

      return new Promise((resolve, reject) => {
        this.props.getStudents(params, resolve, reject);
      });
    } else {
      return new Promise(function (resolve) {
        resolve();
      });
    }
  };

  _loadMore = () => {
    return this._load(true);
  };

  _searchLoad = _.debounce(this._load, 1000);

  _search = searchText => {
    this.setState(
      {
        searchText,
        students: [],
      },
      this._searchLoad,
    );
  };

  _onChangeSearchEmail = searchEmail => {
    this.setState({
      searchEmail,
    });
  };

  _rowGetter = ({ index }) => this.props.students[index] || {};

  _rowClassName = ({ index }) => {
    if (index < 0) {
      return "";
    } else {
      return "app-table-row";
    }
  };

  _onClickHeader = ({ dataKey }) => {
    const { sortColumn, sortDirection } = this.state;

    this.setState(
      {
        sortColumn: dataKey,
        sortDirection:
          sortColumn === dataKey
            ? sortDirection === "desc"
              ? "asc"
              : "desc"
            : "asc",
      },
      this._load,
    );
  };

  render() {
    const {
      students,
      matched,
      total,
      isNextPageLoading,
      getStudentEmail,
      createUcasAccount,
    } = this.props;
    const { searchText, sortColumn, sortDirection, searchEmail } =
      this.state;
    const hasNextPage = matched > students.length;
    const isRowLoaded = ({ index }) =>
      !hasNextPage || index < students.length;
    const loadMoreRows = isNextPageLoading
      ? () => {}
      : this._loadMore;

    return (
      <div className="flex-1 flex flex-col">
        <div className="pl-6">
          <Filter
            searchPlaceholder="Search for student name"
            selectedFilters={{}}
            searchText={searchText}
            filterOptions={[]}
            onChangeSearchText={this._search}
          />

          <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-4 max-w-xl items-center">
            <div className="col-span-2">
              <input
                type="text"
                className={
                  "input-field focus:outline-none focus:border-blue-700 placeholder-gray-500 "
                }
                id={"text-input-search"}
                placeholder="Search for complete email"
                onChange={event => {
                  this._onChangeSearchEmail(event.target.value);
                }}
                value={searchEmail}
                maxLength={150}
              />
            </div>
            <div className="w-max col-span-1">
              <button
                className="btn-primary w-max"
                onClick={() => this._load()}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div class="h-px bg-gray-300 mb-3"></div>

        <div className="applicants-list flex mt-2 items-center pl-6">
          {total === 0 && isNextPageLoading ? null : (
            <div className="font-bold">
              {matched} Students of {total}
            </div>
          )}
        </div>
        <div className="flex flex-1 table-container mt-5">
          <Table
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            totalRowCount={matched}
            rows={students}
            currentRowCount={students.length}
            headerHeight={46}
            rowHeight={80}
            rowClassName={this._rowClassName}
            sortableColumns={sortableColumns}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            noRowsRenderer={() => {
              return isNextPageLoading ? (
                <div className="flex flex-col justify-center items-center  h-full w-full">
                  <Loader
                    type="Oval"
                    color="grey"
                    height={50}
                    width={50}
                  />
                </div>
              ) : null;
            }}
            columns={[
              {
                dataKey: "name",
                width: 300,
                type: "VerticalTwoValueBlack",
                disableSort: true,
                tooltip: true,
                valueSelector: rowData => ({
                  topValue: rowData.name,
                  bottomValue: Format.displayValue(
                    rowData.school_name,
                  ),
                }),
                header: {
                  title: "Name",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "id",
                width: 150,
                type: "Default",
                disableSort: true,
                valueSelector: rowData => ({ value: rowData.id }),
                header: {
                  title: "Student Id",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "email",
                width: 300,
                type: "Default",
                disableSort: true,
                tooltip: true,
                valueSelector: rowData => ({ value: rowData.email }),
                header: {
                  title: "Email",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "graduation_year",
                width: 150,
                type: "Default",
                disableSort: true,
                valueSelector: rowData => ({
                  value: rowData.graduation_year,
                }),
                header: {
                  title: "Grad Year",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "ucas_id",
                width: 400,
                type: "Default",
                disableSort: true,
                cellRenderer: ({ rowData }) => {
                  return (
                    <BluredCellData
                      data={rowData.ucas_id}
                      id={rowData.id}
                      fieldToBeUpdated={"ucas_id"}
                      header="Edit Ucas Id"
                      isUcasId
                      emailId={rowData.cialfo_email}
                      ucasAccountJobStatus={
                        rowData.ucas_account_job_status
                      }
                      createUcasAccount={createUcasAccount}
                    />
                  );
                },
                header: {
                  title: "Ucas Id",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "cialfo_email",
                width: 400,
                type: "Default",
                disableSort: true,
                cellRenderer: ({ rowData }) => {
                  return (
                    <BluredCellData
                      data={rowData.cialfo_email}
                      isEmail
                      id={rowData.id}
                      fieldToBeUpdated={"external_da_email"}
                      header="Edit Email"
                      getStudentEmail={getStudentEmail}
                    />
                  );
                },
                header: {
                  title: "Cialfo Email",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "cialfo_password",
                width: 200,
                type: "Default",
                disableSort: true,
                cellRenderer: ({ rowData }) => {
                  return (
                    <BluredCellData
                      data={rowData.cialfo_password}
                      fieldToBeUpdated={"external_da_email_password"}
                      id={rowData.id}
                      header="Edit Password"
                      haveCheckBox
                    />
                  );
                },
                header: {
                  title: "Cialfo Password",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "ucas_account_requested_at",
                width: 200,
                type: "Default",
                disableSort: true,

                valueSelector: rowData => ({
                  value: Format.date(
                    rowData.ucas_account_requested_at,
                  ),
                }),
                header: {
                  title: "Ucas Account Requested At",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "ucas_account_status",
                width: 200,
                type: "Default",
                disableSort: true,
                valueSelector: rowData => ({
                  value: rowData.ucas_account_status,
                }),
                header: {
                  title: "Ucas Account Status",
                  onClick: this._onClickHeader,
                },
              },
              {
                dataKey: "ucas_account_job_status",
                width: 200,
                type: "Default",
                disableSort: true,
                valueSelector: rowData => ({
                  value: rowData.ucas_account_job_status,
                }),
                header: {
                  title: "Ucas Account Creation Status",
                  onClick: this._onClickHeader,
                },
              },
            ]}
          ></Table>
          {isNextPageLoading && students.length > 0 ? (
            <div
              className={
                "absolute bottom-0 right-0 left-0 self-center pointer-events-none flex-none flex flex-col justify-center items-center p-1"
              }
            >
              <Loader
                type="ThreeDots"
                color="grey"
                height={75}
                width={75}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Students));
