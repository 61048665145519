import React, { useEffect } from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reduxForm, Field } from "redux-form";
import { SelectField, TextAreaField, RadioBox } from "src/form-components";
import { DatePickerField } from "src/components";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { DirectApplyCreators, DirectApplyTypes } from "src/redux/actions";
import EventBus from "eventing-bus";

const selector = formValueSelector("requestDocumentForm");

const RequestDocumentModal = (props) => {
  const {
    id,
    isOpen,
    onClose,
    handleSubmit,
    dueDate,
    studentMessage,
    documents,
    documentsList,
    getDocumentList,
    addFollowUp,
    t,
    getFollowUps,
    requestFrom,
  } = props;

  useEffect(() => {
    getDocumentList();
  }, []);

  useEffect(() => {
    const followUpAdded = EventBus.on(
      DirectApplyTypes.ADD_FOLLOW_UP_SUCCESS,
      () => {
        onClose();
        getFollowUps(id);
      }
    );
    return () => {
      followUpAdded();
    };
  }, []);

  const onSubmit = (values) => {
    if (!props.isFollowUpLoading) {
      const { documents, student_message, due_date, request_from } = values;
      const data = {
        follow_ups: {
          request_from: request_from,
          due_date: due_date,
          doc_types: documents.map((document) => ({
            id: document.value,
            name: document.label,
          })),
          note: student_message,
          student_application_id: id,
        },
      };
      addFollowUp(data);
    }
  };

  const isSaveButtonDisabled =
    !requestFrom ||
    !dueDate ||
    ((!documents || documents.length === 0) && !studentMessage);

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: {
          width: "500px",
          margin: "100px auto",
          outline: "none",
        },
      }}
      overlayClassName="modal-overlay"
      className="modal-content"
    >
      <div className="bg-white p-8 rounded-md">
        <div className={"flex items-center flex-row justify-between mb-4"}>
          <span className="font-bold text-black text-xl">
            {t("direct_apply.application.follow_ups.create_request")}
          </span>

          <FontAwesomeIcon
            onClick={onClose}
            icon={["fas", "times"]}
            className={"text-lg leading-none text-gray-750 cursor-pointer"}
          />
        </div>

        <div className="flex flex-col">
          <div className="text-sm text-gray-750 pb-1">
            {t("direct_apply.application.follow_ups.request_for")}
          </div>
          <Field
            component={RadioBox}
            name="request_from"
            items={[
              { value: "student", label: "Student" },
              { value: "counselor", label: "School Counselor" },
            ]}
          />
        </div>

        <div className="flex flex-col">
          <div className="text-sm text-gray-750 pb-1 pt-4">
            {t("direct_apply.application.follow_ups.document_req")}
          </div>
          <Field
            name="documents"
            component={SelectField}
            placeholder="Select documents"
            isMulti
            options={documentsList?.map((document) => ({
              label: document.name,
              value: document.id,
            }))}
          />
          <div className="text-xs text-gray-700">
            Leave empty if no documents required
          </div>
        </div>

        <div className="flex flex-col">
          <div className="text-sm text-gray-750 pb-1 pt-4">
            {t("direct_apply.application.follow_ups.message_for_student")}
          </div>
          <Field
            component={TextAreaField}
            name="student_message"
            placeholder={"Enter message here"}
          />
        </div>

        <div className="flex flex-col">
          <div className="text-sm text-gray-750 pb-1 pt-4">
            {t("direct_apply.application.follow_ups.due_date")}
          </div>
          <Field
            name="due_date"
            component={DatePickerField}
            placeholder={"Select due date"}
            minDate={new Date()}
            readOnly
          />
        </div>
        <div className="flex justify-end pt-10">
          <button
            className="btn-primary ml-4"
            disabled={isSaveButtonDisabled}
            type={"submit"}
            onClick={handleSubmit(onSubmit)}
          >
            {t("direct_apply.application.follow_ups.send")}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

const validate = (values, props) => {
  const errors = {};
  const { documents, student_message, due_date, request_from } = values;
  const { t } = props;

  if (!request_from)
    errors["request_from"] = t(
      "direct_apply.application.follow_ups.request_for_error_message"
    );

  if (!due_date)
    errors["due_date"] = t(
      "direct_apply.application.follow_ups.due_date_error_message"
    );

  if ((!documents || documents.length === 0) && !student_message) {
    errors["student_message"] = t(
      "direct_apply.application.follow_ups.document_error_message"
    );
    errors["documents"] = t(
      "direct_apply.application.follow_ups.document_error_message"
    );
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    dueDate: selector(state, "due_date"),
    studentMessage: selector(state, "student_message"),
    documents: selector(state, "documents"),
    requestFrom: selector(state, "request_from"),
    documentsList: state.directApply.documentsList,
    isFollowUpLoading: state.directApply.isFollowUpLoading,
  };
};

const mapDispatchToProps = {
  getDocumentList: DirectApplyCreators.getDocumentListRequest,
  addFollowUp: DirectApplyCreators.addFollowUpRequest,
  getFollowUps: DirectApplyCreators.getFollowUpsRequest,
};

export default withTranslation()(
  reduxForm({
    form: "requestDocumentForm",
    enableReinitialize: true,
    validate: validate,
    initialValues: { documents: [], student_message: "", due_date: "" },
  })(connect(mapStateToProps, mapDispatchToProps)(RequestDocumentModal))
);
