export const PROGRAM_STATUS = {
  DECLINED: "declined",
  ACTIVE: "active",
  INACTIVE: "inactive",
  PENDING_REVIEW: "pending_review",
  DRAFT: "draft",
};

export const BROCHURE_SELECTION_STATUS = {
  EXISTING: "existing",
  NEWLY_CREATED: "newly_created",
  DONT_SHOW: "dont_show"
};
