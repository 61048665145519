import { API } from "../helpers";
import { SERVER_URL } from "../config";

export function getTeam(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/teams/list`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function searchTeamMembers(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/teams/members_list`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function bulkRequest(authToken, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/teams/bulk_operation`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getTeamMember(authToken, id) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/teams/${id}`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function createTeamMember(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/teams`,
    headers: {
      authentication_token: authToken,
    },
    data,
    silent: true,
  });
}

export function updateTeamMember(authToken, id, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/teams/${id}`,
    headers: {
      authentication_token: authToken,
      "Content-Type": "multipart/form-data",
    },
    data,
  });
}
