import { createReducer } from "reduxsauce";
import { PartnersTypes as Types, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.partners;

export const clearPartners = (state, action) => ({ ...state, data: [] });

export const getPartnersFailure = (state, action) => ({
  ...state,
  loading: false,
});

export const getPartnersRequest = (state, action) => {
  return { ...state, loading: true };
};

export const getPartnersSuccess = (state, action) => {
  const {
    partnersData: { partners, matched, total },
    params,
  } = action;

  return {
    ...state,
    data: params.page_no === 1 ? partners : [...state.data, ...partners],
    matched,
    total,
    params: { ...state.params, ...params },
    loading: false,
  };
};

export const getPartnerFailure = (state, action) => ({
  ...state,
  loadingSelected: false,
  selected: null,
});

export const getPartnerRequest = (state, action) => ({
  ...state,
  loadingSelected: true,
});

export const getPartnerSuccess = (state, action) => ({
  ...state,
  loadingSelected: false,
  selected: action.partner,
});

export const getMergePartnersFailure = (state, action) => ({
  ...state,
  isFetchingMergePartners: false,
  fetchMergePartnersSuccessful: false,
  mergePartners: {
    originalPartner: null,
    duplicatePartner: null,
  },
});

export const getMergePartnersRequest = (state, action) => ({
  ...state,
  isFetchingMergePartners: true,
  fetchMergePartnersSuccessful: false,
  mergePartners: {
    originalPartner: null,
    duplicatePartner: null,
  },
});

export const getMergePartnersSuccess = (state, action) => ({
  ...state,
  isFetchingMergePartners: false,
  fetchMergePartnersSuccessful: true,
  mergePartners: {
    originalPartner: action.originalPartner,
    duplicatePartner: action.duplicatePartner,
  },
});

export const mergePartnersFailure = (state, action) => ({
  ...state,
  isMergingPartners: false,
  mergePartnerSuccessful: false,
  mergeMessage: "",
});

export const mergePartnersRequest = (state, action) => ({
  ...state,
  isMergingPartners: true,
  mergePartnerSuccessful: false,
  mergeMessage: "",
});

export const mergePartnersSuccess = (state, action) => ({
  ...state,
  isMergingPartners: false,
  mergePartnerSuccessful: true,
  mergeMessage: action.message,
});

export const swapMergePartners = (state, action) => ({
  ...state,
  mergePartners: {
    originalPartner: state.mergePartners.duplicatePartner,
    duplicatePartner: state.mergePartners.originalPartner,
  },
});

export const getProductOfferingOptionsFailure = (state, action) => ({
  ...state,
  isFetchingProductOfferingOptions: false,
  productOfferingOptions: [],
});

export const getProductOfferingOptionsRequest = (state, action) => ({
  ...state,
  isFetchingProductOfferingOptions: true,
  productOfferingOptions: [],
});

export const getProductOfferingOptionsSuccess = (state, action) => ({
  ...state,
  isFetchingProductOfferingOptions: false,
  productOfferingOptions: action.productOfferingOptions,
});

export const savePartnerFailure = (state, action) => ({
  ...state,
  addingPartner: false,
  partnerAdded: false,
});

export const savePartnerRequest = (state, action) => ({
  ...state,
  addingPartner: true,
  partnerAdded: false,
});

export const savePartnerSuccess = (state, action) => ({
  ...state,
  addingPartner: false,
  partnerAdded: true,
});

export const getPartnersFilterCountriesFailure = (state, action) => ({
  ...state,
});
export const getPartnersFilterCountriesRequest = (state, action) => ({
  ...state,
});
export const getPartnersFilterCountriesSuccess = (state, action) => ({
  ...state,
  partnerCountries: action.countries,
});

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CLEAR_PARTNERS]: clearPartners,

  [Types.GET_PARTNERS_FAILURE]: getPartnersFailure,
  [Types.GET_PARTNERS_REQUEST]: getPartnersRequest,
  [Types.GET_PARTNERS_SUCCESS]: getPartnersSuccess,

  [Types.GET_PARTNER_FAILURE]: getPartnerFailure,
  [Types.GET_PARTNER_REQUEST]: getPartnerRequest,
  [Types.GET_PARTNER_SUCCESS]: getPartnerSuccess,

  [Types.GET_MERGE_PARTNERS_FAILURE]: getMergePartnersFailure,
  [Types.GET_MERGE_PARTNERS_REQUEST]: getMergePartnersRequest,
  [Types.GET_MERGE_PARTNERS_SUCCESS]: getMergePartnersSuccess,

  [Types.MERGE_PARTNERS_FAILURE]: mergePartnersFailure,
  [Types.MERGE_PARTNERS_REQUEST]: mergePartnersRequest,
  [Types.MERGE_PARTNERS_SUCCESS]: mergePartnersSuccess,

  [Types.SWAP_MERGE_PARTNERS]: swapMergePartners,

  [Types.GET_PRODUCT_OFFERING_OPTIONS_FAILURE]:
    getProductOfferingOptionsFailure,
  [Types.GET_PRODUCT_OFFERING_OPTIONS_REQUEST]:
    getProductOfferingOptionsRequest,
  [Types.GET_PRODUCT_OFFERING_OPTIONS_SUCCESS]:
    getProductOfferingOptionsSuccess,

  [Types.SAVE_PARTNER_FAILURE]: savePartnerFailure,
  [Types.SAVE_PARTNER_REQUEST]: savePartnerRequest,
  [Types.SAVE_PARTNER_SUCCESS]: savePartnerSuccess,

  [Types.GET_PARTNERS_FILTER_COUNTRIES_FAILURE]:
    getPartnersFilterCountriesFailure,
  [Types.GET_PARTNERS_FILTER_COUNTRIES_REQUEST]:
    getPartnersFilterCountriesRequest,
  [Types.GET_PARTNERS_FILTER_COUNTRIES_SUCCESS]:
    getPartnersFilterCountriesSuccess,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
