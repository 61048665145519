import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import CompletedCTA from "../completed-cta";
import MarkAsCompleteCTA from "../mark-as-complete-cta";
import { APPLICATION_SECTION } from "src/constants/applications.constants";

const CompleteAutomation = ({
  t,
  application,
  ucasQuickSubmit,
  isUCASAccountCreated,
}) => {
  const isOnboardingComplete = ucasQuickSubmit?.onboarding_complete;
  const isRunningJob =
    ucasQuickSubmit?.onboarding_job_status === "Running" ||
    ucasQuickSubmit?.jobs_running;
  const isUCASCredentialUpdateRequired =
    !application.is_ucas_credential_updated && isUCASAccountCreated;

  if (isOnboardingComplete) {
    return (
      <CompletedCTA
        status={isOnboardingComplete}
        type={APPLICATION_SECTION.UCAS_ACCOUNT}
      />
    );
  } else if (isRunningJob || isUCASCredentialUpdateRequired) {
    return (
      <>
        <button
          data-tip
          data-for={`tooltip-Completes-right`}
          disabled={true}
          className={"btn-border-primary mb-6"}
        >
          {isOnboardingComplete ? (
            <>
              {`${t("direct_apply_apps.quick_submit.account_and_onboarding.completed_cta")} `}
              <FontAwesomeIcon
                icon={faCheck}
                className="text-white w-4 h-4 ml-1"
              />
            </>
          ) : (
            t(
              "direct_apply_apps.quick_submit.account_and_onboarding.mark_as_complete_cta",
            )
          )}
        </button>
        {!application.is_ucas_credential_updated &&
          isUCASAccountCreated && (
            <ReactTooltip
              id={`tooltip-Completes-right`}
              aria-haspopup="true"
              place="right"
              effect={"solid"}
              arrowColor={"transparent"}
            >
              <p>
                {t("direct_apply_apps.quick_submit.ucas_credential")}
              </p>
            </ReactTooltip>
          )}
      </>
    );
  } else {
    return (
      <MarkAsCompleteCTA
        status={isOnboardingComplete}
        type={APPLICATION_SECTION.UCAS_ACCOUNT}
      />
    );
  }
};

const mapStateToProps = state => ({
  ucasQuickSubmit: state.directApply.ucasQuickSubmit,
  application: state.directApply.selected,
});

export default withTranslation()(
  connect(mapStateToProps)(CompleteAutomation),
);
