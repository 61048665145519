import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NoResultsFound = (props) => {
  return (
    <div className="flex flex-row bg-gray-200 py-3 px-4 mb-3">
      <div className="flex-none mr-5 pt-1">
        <FontAwesomeIcon
          icon={["fas", "search-minus"]}
          className="alert-icon mt-1"
        />
      </div>

      <div className="s-alert-box-inner flex-1 flex flex-col">
        <span className="text-xl text-gray-750 font-bold leading-nnone">
          {props.title}
        </span>
        <div className="flex flex-row items-center">
          <div className="mt-1">
            <span className="text-sm text-black font-normal leading-none">
              {props.detail}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoResultsFound;
