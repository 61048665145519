import TeamScreen from "./team-screen";
import { connect } from "react-redux";
import { TeamCreators } from "../../redux/actions";

function mapStateToProps(state) {
  return {
    team: state.team.data,
    totalMembers: state.team.total,
    matchedMembers: state.team.matched,
    isNextPageLoading: state.team.loading,
    downloadingTeam: state.team.downloading,
    downloadUrl: state.team.downloadUrl,
    isAdmin: state.user.authToken && state.user.data.is_admin,
  };
}

const mapDispatchToProps = {
  getTeam: (params, resolve, reject) =>
    TeamCreators.getTeamRequest(params, resolve, reject),
  clearTeam: TeamCreators.clearTeam,
  downloadTeam: TeamCreators.downloadTeamRequest,
  bulkAction: TeamCreators.bulkActionRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamScreen);
