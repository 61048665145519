import React, { Component, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Format, Notification } from "../../../helpers";
import ActivityHistoryModal from "./activity-history";
import ReactTooltip from "react-tooltip";
import { withTranslation } from "react-i18next";
import copy from "clipboard-copy";
import { map, each, startCase, camelCase } from "lodash";
import EventBus from "eventing-bus";
import { sanitize } from "dompurify";
import { withRouter } from "react-router-dom";
import { Input, Checkbox } from "antd";
import {
  DirectApplyTypes,
  UcasApplicationFormTypes,
  StudentsTypes,
} from "../../../redux/actions";
import Loader from "react-loader-spinner";
import { ConfirmationModal } from "../../../components";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import { AdditionalRequirements } from "./additional-requirements";
import {
  APPLICATION_STATUS,
  APPLICATION_TYPE,
} from "src/constants/applications.constants";
import NotesModal from "./notes-modal";
import SubmissionDialog from "./submission-dialog";
import UndoSubmissionDialog from "./undo-submission-dialog";
import PlaceholderLogo from "../../../assets/direct-apply/university-placeholder.png";
import { DirectApplicationDetailNew } from "./direct-application-detail-new";
import ExploreData from "./explore-data";
import RequestDocument from "./request-document";
import RequestedDocumentsListing from "./requested-documents-listing";
import UploadCounselorDocument from "./upload-counselor-document";
import QuickSubmit from "./quick-submit-accordin/quick-submit-component";
import QuickSubmitTagging from "./quick-submit-accordin/quick-submit-tagging";

const badge_type = {
  Pending: "application-badge-warning",
  Completed: "application-badge-success",
  Failed: "application-badge-error",
  Unauthorized: "application-badge-info",
  Running: "application-badge-primary",
};

const validEmailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DisplayField = ({
  label,
  value,
  copyable,
  copyText,
  isBadge,
  isEmail,
  beforeIcon,
  isVerified,
  isBlur,
  isEdit,
  updateApplicationUcasData,
  fieldToBeUpdated,
  userId,
  haveCheckBox,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isvisible, setIsvisible] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [fieldValue, setFieldValue] = useState(value);

  const fieldError =
    isEmail && !new RegExp(validEmailRegex).test(fieldValue);

  useEffect(() => {
    const ucasDataUpdateSuccess = EventBus.on(
      DirectApplyTypes.UPDATE_APPLICATION_UCAS_DATA_SUCCESS,
      () => {
        setIsEditing(false);
      },
    );
    return () => {
      ucasDataUpdateSuccess();
    };
  }, []);

  return (
    <div className="flex-none flex flex-row items-center min-h-42 px-3 py-2 display-field">
      <div className={"flex-none w-56"}>
        <h3 className="text-sm text-gray-700 ">{label}</h3>
      </div>
      <div className={"flex-1 flex flex-row"}>
        <div
          className={
            "flex-1 flex flex-row pl-4" +
            (Array.isArray(value) ? "flex-wrap" : "")
          }
        >
          {value && beforeIcon ? (
            <FontAwesomeIcon
              icon={beforeIcon}
              className={
                "text-sm leading-none text-blue-700 mr-px self-center"
              }
            />
          ) : null}
          {Array.isArray(value) ? (
            value.map((v, index) => (
              <div
                key={index}
                className={
                  isBadge
                    ? "badge-primary mt-1 mr-1"
                    : `text-sm font-bold ${
                        isEmail ? "text-blue-700" : "text-gray-900"
                      }`
                }
              >
                {Format.displayValue(v)}
              </div>
            ))
          ) : (React.isValidElement(value) || value === null) &&
            !isEdit ? (
            value
          ) : (
            <div
              className={
                isBadge
                  ? "badge-primary mt-1"
                  : `text-sm font-bold ${
                      isEmail ? "text-blue-700" : "text-gray-900"
                    }`
              }
            >
              {typeof value === "string" || isEdit ? (
                <div className="flex items-center">
                  {isEditing ? (
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <Input
                          status={fieldError ? "error" : ""}
                          value={fieldValue}
                          onChange={e =>
                            setFieldValue(e.target.value)
                          }
                          style={{ width: 300 }}
                        />
                        {haveCheckBox && (
                          <div className="pl-4">
                            {" "}
                            <Checkbox
                              onChange={e =>
                                setIsCheckboxChecked(e.target.checked)
                              }
                              value={isCheckboxChecked}
                            >
                              Update email account password
                            </Checkbox>
                          </div>
                        )}
                        <button
                          className="btn-primary ml-4"
                          disabled={fieldError || !fieldValue}
                          onClick={() => {
                            const data = {
                              student: {
                                [fieldToBeUpdated]: fieldValue,
                                ...(haveCheckBox && {
                                  update_external_password:
                                    isCheckboxChecked,
                                }),
                              },
                            };
                            updateApplicationUcasData(userId, data);
                          }}
                        >
                          Save
                        </button>
                      </div>

                      {fieldError && (
                        <span className="text-red-800 text-xs">
                          Email is not correct
                        </span>
                      )}
                    </div>
                  ) : (
                    <span
                      className={` ${
                        isBlur && !isvisible ? "filter blur" : ""
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: Format.displayValue(
                          sanitize(
                            value &&
                              value.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />",
                              ),
                          ),
                        ),
                      }}
                    />
                  )}

                  {isVerified && (
                    <div className="flex items-center pl-4">
                      <FontAwesomeIcon
                        icon={["fas", "circle-check"]}
                        className="text-sm leading-none text-green-700"
                      />
                      <div className="pl-1 ">Verified by ETS</div>
                    </div>
                  )}
                </div>
              ) : (
                Format.displayValue(value)
              )}
            </div>
          )}
        </div>
      </div>
      <div className={"flex-none"}>
        {isEdit && (
          <>
            {isBlur && (
              <FontAwesomeIcon
                onClick={() => {
                  setIsvisible(!isvisible);
                }}
                icon={
                  isvisible ? ["fal", "eye-slash"] : ["fal", "eye"]
                }
                className={
                  "text-lg leading-none text-gray-750 cursor-pointer ml-3"
                }
              />
            )}
            <FontAwesomeIcon
              onClick={() => {
                if (isEditing) {
                  setIsEditing(false);
                } else {
                  setIsEditing(true);
                }
              }}
              icon={isEditing ? ["fas", "xmark"] : ["fas", "edit"]}
              className={
                "text-lg leading-none text-gray-750 cursor-pointer ml-3"
              }
            />
          </>
        )}
        {value && copyable ? (
          <FontAwesomeIcon
            onClick={() => (copyText ? copy(copyText) : copy(value))}
            icon={["far", "copy"]}
            className={
              "text-lg leading-none text-gray-750 ml-3 cursor-pointer"
            }
          />
        ) : null}
      </div>
    </div>
  );
};

const FileAttachment = ({
  fileName,
  disabled,
  downloadUrl,
  openModal,
  is_deleted,
  index,
}) => (
  <div
    data-tip
    data-for={is_deleted ? `delete_document${index}` : ""}
    className={`flex-1 flex flex-row overflow-hidden ${
      disabled | is_deleted
        ? "bg-gray-50 text-gray-500"
        : "border-t border-gray-300 text-blue-700"
    }`}
  >
    <div
      className={`flex-none flex w-10 h-10 justify-center items-center ${
        disabled | is_deleted ? "" : "bg-blue-50"
      }`}
    >
      <FontAwesomeIcon
        icon={["fas", "file-pdf"]}
        className={"text-2xl leading-none"}
      />
    </div>
    <div className="flex-1 flex flex-row items-center ml-3 overflow-hidden">
      <p className="text-xs truncate">{fileName}</p>
    </div>

    {disabled ? null : (
      <>
        <div
          onClick={() => {
            if (downloadUrl) window.open(downloadUrl);
          }}
          className="flex-none flex w-10 justify-center items-center cursor-pointer"
        >
          <FontAwesomeIcon
            icon={["fas", "download"]}
            className={"text-lg text-gray-750 leading-none"}
          />
        </div>
        {!is_deleted && (
          <div
            // onClick={openModal}
            className="flex-none flex w-10 justify-center items-center cursor-default"
          >
            <FontAwesomeIcon
              icon={["fas", "trash-can"]}
              className="text-lg text-gray-750 leading-none"
            />
          </div>
        )}
      </>
    )}
    {is_deleted && (
      <ReactTooltip
        id={"delete_document" + index}
        aria-haspopup="true"
        place="bottom"
        effect={"solid"}
        arrowColor={"transparent"}
      >
        <p>
          This document has been deleted and won’t be visible to
          student anymore{" "}
        </p>
      </ReactTooltip>
    )}
  </div>
);

const PredictedGradeDocument = ({ predictedGradesSentAt }) => (
  <div>
    <div className="flex-1 flex flex-row text-blue-700 border-t border-gray-300 overflow-hidden">
      <div className="flex-none flex w-10 justify-center" />
      <div className="flex-1 flex flex-col ml-3 pt-2 overflow-hidden">
        <p className="text-sm text-black font-bold truncate">
          Predicted Grades
        </p>

        <p className="text-sm text-dark-blue-300">
          Predicted grades are shown in Education section of the form
          for each qualification
        </p>
        <p className="text-xs text-dark-blue-300 truncate mt-3 mb-2">
          {Format.datetime(predictedGradesSentAt)}
        </p>
      </div>
    </div>
  </div>
);

const DocumentDownload = ({
  documentName,
  id,
  fileName,
  downloadUrl,
  uploaded_at,
  skipCounselorUpload,
}) => (
  <div>
    {skipCounselorUpload ? (
      <div className="flex-1 flex flex-row text-blue-700 border-t border-gray-300 overflow-hidden">
        <div className="flex-none flex w-10 justify-center"></div>
        <div className="flex-1 flex flex-col ml-3 pt-2 overflow-hidden">
          <p className="text-sm text-black font-bold truncate">
            {documentName}
          </p>

          <p className="text-sm text-dark-blue-300 mb-2">
            {"Predicted Grades not available"}
          </p>
        </div>
      </div>
    ) : (
      <div className="flex-1 flex flex-row text-blue-700 border-t border-gray-300 overflow-hidden ">
        <div className="flex-none flex w-10 justify-center items-center bg-blue-50">
          <FontAwesomeIcon
            icon={["fas", "file-pdf"]}
            className={"text-2xl leading-none"}
          />
        </div>
        <div className="flex-1 flex flex-col ml-3 pt-2 overflow-hidden">
          <p className="text-sm text-black font-bold truncate">
            {documentName}
          </p>
          <p className="text-sm text-blue-700 font-bold truncate">
            {fileName}
          </p>
          <p className="text-xs text-blue-700 truncate">
            {"ID"} {id}
          </p>
          <p className="text-xs text-dark-blue-300 truncate mt-3 mb-2">
            {Format.datetime(uploaded_at)}
          </p>
        </div>
        <div
          onClick={() => {
            if (downloadUrl) window.open(downloadUrl);
          }}
          className="flex-none flex w-10 h-20 justify-center items-center cursor-pointer"
        >
          <FontAwesomeIcon
            icon={["fas", "download"]}
            className={"text-lg text-gray-750 leading-none"}
          />
        </div>
      </div>
    )}
  </div>
);

const DisplayScoresField = ({ label, scores }) => (
  <div className="flex-1 flex flex-row items-start display-field">
    <div className="flex-none w-56 pl-3">
      <h3 className="text-sm text-gray-700 mb-2">{label}</h3>
    </div>
    <div className={"flex-1 flex flex-col"}>
      {scores.map(({ label, value }) => (
        <div key={label + value} className="flex flex-row mb-2">
          <div className="text-sm text-gray-800 font-normal w-32">
            {label}
            {": "}
          </div>
          <div className="text-sm text-gray-800 font-bold">
            {Format.displayValue(value)}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export const HeadingLarge = ({ text }) => (
  <h2 className="text-xl text-gray-750 font-bold mb-4">{text}</h2>
);

const HeadingMedium = ({ text }) => (
  <h2 className="text-base text-gray-750 font-bold">{text}</h2>
);

const ReviewButton = ({
  text,
  onClick,
  finished,
  disabled,
  step,
}) => {
  let icon = null;

  if (finished) {
    icon = (
      <div
        className={`flex flex-none w-6 h-6 rounded-full bg-green-500 justify-center items-center`}
      >
        <FontAwesomeIcon
          icon={["fas", "check"]}
          className="text-white text-sm leading-none"
        />
      </div>
    );
  } else if (disabled) {
    icon = (
      <div
        className={`flex flex-none w-6 h-6 rounded-full bg-gray-500 justify-center items-center`}
      >
        <span className="text-white text-sm leading-none">
          {step}
        </span>
      </div>
    );
  } else if (!finished) {
    icon = (
      <div
        className={`flex flex-none w-6 h-6 rounded-full bg-blue-700 justify-center items-center`}
      >
        <span className="text-white text-sm leading-none">
          {step}
        </span>
      </div>
    );
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled || finished}
      className={`flex-1 flex flex-row text-left focus:outline-none cursor-default`}
    >
      <div className={"mr-4"}>{icon}</div>
      <div className={"text-sm text-gray-750"}>{text}</div>
    </button>
  );
};
class DirectApplicationDetailScreen extends Component {
  state = {
    isUploadModalVisible: false,
    isActivityModalVisible: false,
    isExploreDataVisible: false,
    confirmationModalVisible: false,
    depositChangeRequestValue: null,
    application: null,
    startingReview: false,
    updatingApplication: false,
    isDownloadingDocuments: false,
    isReviewBannerVisible: true,
    deleteConfirmationModalVisible: false,
    documentToDelete: null,
    isNotesModalVisible: false,
    applicationStatus: null,
    isSubmissionDialogVisible: false,
    isUndoSubmissionDialogVisible: false,
    showFormIncompleteBanner: true,
    UCASFile: null,
    fileURL: null,
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getApplication(id);
    this.props.getApplicationResults();
    this._subscribeEvents();
    this.props.getDirectApplicationStatusList();
    //this.props.getDirectApplicationResultList(); // API not ready yet
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  componentDidUpdate(prevProps) {
    const id = this.props.match.params.id;
    const prevId = prevProps.match.params.id;

    if (id !== prevId) {
      this.props.getApplication(id);
      this.props.getApplicationResults();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.application) {
    }

    return null;
  }

  _subscribeEvents = () => {
    this.updateApplicationStatusFailureSubscription = EventBus.on(
      DirectApplyTypes.UPDATE_APPLICATION_STATUS_FAILURE,
      () => this.setState({ startingReview: false }),
    );
    this.updateApplicationStatusRequestSubscription = EventBus.on(
      DirectApplyTypes.UPDATE_APPLICATION_STATUS_REQUEST,
      () => this.setState({ startingReview: true }),
    );
    this.updateApplicationStatusSuccessSubscription = EventBus.on(
      DirectApplyTypes.UPDATE_APPLICATION_STATUS_SUCCESS,
      () => this.setState({ startingReview: false }),
    );

    this.updateApplicationDepositPaidFailureSubscription =
      EventBus.on(
        DirectApplyTypes.UPDATE_APPLICATION_DEPOSIT_PAID_FAILURE,
        () => this.setState({ updatingApplication: false }),
      );
    this.updateApplicationDepositPaidRequestSubscription =
      EventBus.on(
        DirectApplyTypes.UPDATE_APPLICATION_DEPOSIT_PAID_REQUEST,
        () => this.setState({ updatingApplication: true }),
      );
    this.updateApplicationDepositPaidSuccessSubscription =
      EventBus.on(
        DirectApplyTypes.UPDATE_APPLICATION_DEPOSIT_PAID_SUCCESS,
        this._depositStatusUpdateSuccess,
      );

    this.saveUcasApplicationFormSuccessSubscription = EventBus.on(
      UcasApplicationFormTypes.SAVE_UCAS_APPLICATION_FORM_SUCCESS,
      this._saveUcasApplicationFormSuccess,
    );

    this.fillEducationSectionSuccessSubscription = EventBus.on(
      UcasApplicationFormTypes.FILL_EDUCATION_SECTION_SUCCESS,
      this._saveUcasApplicationFormSuccess,
    );

    this.fillTaggingFormSuccessSubscription = EventBus.on(
      DirectApplyTypes.FILL_TAGGING_FORM_SUCCESS,
      this._saveUcasApplicationFormSuccess,
    );

    this.saveUcasChoicesSuccessSubscription = EventBus.on(
      DirectApplyTypes.REQUEST_UCAS_CHOICES_SUCCESS,
      this._saveUcasChoicesSuccess,
    );

    this.fillEducationSectionSuccessSubscription = EventBus.on(
      UcasApplicationFormTypes.FILL_PERSONAL_STATEMENT_SUCCESS,
      this._saveUcasApplicationFormSuccess,
    );

    this.createUcasAccountSuccessSubscription = EventBus.on(
      StudentsTypes.CREATE_UCAS_ACCOUNT_SUCCESS,
      this._createUcasAccountSuccess,
    );

    this.updateApplicationDataSuccessSubscription = EventBus.on(
      DirectApplyTypes.UPDATE_APPLICATION_UCAS_DATA_SUCCESS,
      this._updateApplicationDataSuccess,
    );

    this.setApplicationStatusSubscription = EventBus.on(
      DirectApplyTypes.SET_APPLICATION_STATUS_SUCCESS,
      this._setStatusSuccess,
    );

    this.updateApplicationResultFailureSubscription = EventBus.on(
      DirectApplyTypes.UPDATE_APPLICATION_RESULT_FAILURE,
      () => this.setState({ updatingApplication: false }),
    );
    this.updateApplicationResultRequestSubscription = EventBus.on(
      DirectApplyTypes.UPDATE_APPLICATION_RESULT_REQUEST,
      () => this.setState({ updatingApplication: true }),
    );
    this.updateApplicationResultSuccessSubscription = EventBus.on(
      DirectApplyTypes.UPDATE_APPLICATION_RESULT_SUCCESS,
      () =>
        this.setState({
          updatingApplication: false,
          isUploadModalVisible: true,
        }),
    );
  };

  _unsubscribeEvents = () => {
    this.updateApplicationStatusFailureSubscription();
    this.updateApplicationStatusRequestSubscription();
    this.updateApplicationStatusSuccessSubscription();

    this.updateApplicationDepositPaidFailureSubscription();
    this.updateApplicationDepositPaidRequestSubscription();
    this.updateApplicationDepositPaidSuccessSubscription();

    this.updateApplicationResultFailureSubscription();
    this.updateApplicationResultRequestSubscription();
    this.updateApplicationResultSuccessSubscription();
    this.setApplicationStatusSubscription();

    this.saveUcasApplicationFormSuccessSubscription();
    this.fillEducationSectionSuccessSubscription();
    this.fillTaggingFormSuccessSubscription();
    this.saveUcasChoicesSuccessSubscription();
    this.createUcasAccountSuccessSubscription();
    this.updateApplicationDataSuccessSubscription();
  };

  _setDeposit = paid => {
    this.setState({
      confirmationModalVisible: true,
      depositChangeRequestValue: paid,
    });
  };

  _cancelDepositChange = () => {
    this.setState({ confirmationModalVisible: false });
  };

  _confirmDepositChange = () => {
    const { match } = this.props;
    const { depositChangeRequestValue: paid } = this.state;
    const id = match.params.id;
    const appUpdate = {
      student_application: {
        is_deposit_paid: paid,
      },
    };
    this.setState({ confirmationModalVisible: false });
    this.props.updateApplicationDepositPaid(id, appUpdate);
  };

  _depositStatusUpdateSuccess = () => {
    this.setState({ updatingApplication: false });
    Notification.success(
      this.props.t("alerts.success.deposit_status_changed"),
    );
  };
  _setStatusSuccess = () => {
    if (!this.state.isNotesModalVisible) {
      Notification.success("Application status updated");
      const id = this.props.match.params.id;
      this.props.getApplication(id);
    } else {
      Notification.success("Notes updated successfully");
    }
  };

  _saveUcasApplicationFormSuccess = () => {
    const id = this.props.match.params.id;
    this.props.getApplication(id);
  };

  _saveUcasChoicesSuccess = () => {
    const id = this.props.match.params.id;
    this.props.getApplication(id);
  };

  _createUcasAccountSuccess = () => {
    const id = this.props.match.params.id;
    this.props.getApplication(id);
  };

  _updateApplicationDataSuccess = () => {
    const id = this.props.match.params.id;
    this.props.getApplication(id);
  };

  _changeResult = resultId => {
    //this.props.setApplicationStatus(id, { status: resultId.name });
    console.log("API not ready");
  };

  _showUploadModal = () => {
    this.setState({ isUploadModalVisible: true });
  };

  _hideUploadModal = () => {
    this.setState({ isUploadModalVisible: false });
  };

  _showActivityModal = () => {
    this.setState({ isActivityModalVisible: true });
  };

  _hideActivityModal = () => {
    this.setState({ isActivityModalVisible: false });
  };

  _hideExploreDataModal = () => {
    this.setState({ isExploreDataVisible: false });
  };

  _showNotesModal = () => {
    this.setState({ isNotesModalVisible: true });
  };

  _hideNotesModal = string => {
    this.setState({ isNotesModalVisible: false });
  };

  _hideSubmissionDialog = () => {
    this.setState({ isSubmissionDialogVisible: false });
  };

  _showSubmissionDialog = () => {
    this.setState({ isSubmissionDialogVisible: true });
  };

  _hideUndoSubmissionDialog = () => {
    this.setState({ isUndoSubmissionDialogVisible: false });
  };

  _showUndoSubmissionDialog = () => {
    this.setState({ isUndoSubmissionDialogVisible: true });
  };

  _hideFormIncompleteBanner = () => {
    this.setState({ showFormIncompleteBanner: false });
  };

  openConfirmationModal = document => {
    this.setState({
      deleteConfirmationModalVisible: true,
      documentToDelete: document,
    });
  };

  _hideConfirmationModal = () => {
    this.setState({
      deleteConfirmationModalVisible: false,
      documentToDelete: null,
    });
  };

  _deleteDocument = () => {
    this.props.deleteDirectApplyDocument(this.state.documentToDelete);
    this._hideConfirmationModal();
  };

  _startReview = () => {
    console.log("Api not ready yet");
  };

  handleSelection = (v, e) => {
    const { match } = this.props;
    const id = match.params.id;
    this.props.setApplicationStatus(id, { status: v.name });
  };

  _zipAndDownloadDocuments = () => {
    const { application } = this.props;
    let count = 0;
    const zip = new JSZip();
    const folder = zip.folder(
      application.first_name + " " + application.last_name,
    );
    const allDocuments = [];

    this.setState({ isDownloadingDocuments: true });

    each(
      application.client_documents,
      (documents, category, thirdParam) => {
        documents.forEach(document => {
          allDocuments.push(document);
        });
      },
    );

    allDocuments.forEach(file => {
      const filename = `${file.id}-${file.type} ${file.name}`;

      JSZipUtils.getBinaryContent(file.url, (err, data) => {
        if (err) {
          this.setState({ isDownloadingDocuments: false });
          throw err;
        }

        folder.file(filename, data, { binary: true });
        count++;

        if (count === allDocuments.length) {
          zip
            .generateAsync({ type: "blob" })
            .then(blob => {
              this.setState({ isDownloadingDocuments: false });
              saveAs(
                blob,
                application.first_name +
                  " " +
                  application.last_name +
                  ".zip",
              );
            })
            .catch(error => {
              this.setState({ isDownloadingDocuments: false });
            });
        }
      });
    });
  };

  _applicationApprovedOn = () => {
    const { application } = this.props;
    return application.approved_at === "N/A"
      ? application.approved_at
      : Format.date(application.approved_at);
  };

  _loginSuperUser = (applicationId, partnerId) => {
    const { getPartnerSso } = this.props;
    getPartnerSso(partnerId, applicationId);
  };

  _isSubmissionAllowed = () => {
    const {
      application: { status_key, is_form_complete },
    } = this.props;

    const statusesAllowed = [
      "form_completed",
      "submitted_to_counselor",
      "revision_requested_by_cialfo",
      "revision_required",
    ];

    if (statusesAllowed.includes(status_key) && is_form_complete) {
      return false;
    }
    return true;
  };

  _isUndoSubmissionAllowed = () => {
    const {
      application: { status_key },
    } = this.props;

    const statusesAllowed = ["to_review", "reviewing"];
    if (statusesAllowed.includes(status_key)) {
      return false;
    }
    return true;
  };

  uploadUCASDataFile = e => {
    const file = e.target.files[0];
    if (e.target.files) {
      const fileURL = URL.createObjectURL(file);
      this.setState({ UCASFile: file, fileURL: fileURL });
    }
  };

  fillApplicationForm = applicationId => {
    this.props.saveUcasApplicationForm(applicationId);
  };

  isFormFillingInProgress = fillingStatus => {
    return ["Pending", "Running"].includes(fillingStatus);
  };

  render() {
    const {
      isActivityModalVisible,
      isDownloadingDocuments,
      confirmationModalVisible,
      updatingApplication,
      isReviewBannerVisible,
      deleteConfirmationModalVisible,
      isExploreDataVisible,
    } = this.state;
    const {
      t,
      application,
      match,
      userData,
      history,
      applications,
      matchedApplications,
    } = this.props;

    const applicationIndex = applications?.findIndex(app => {
      return app.id === Number(match?.params?.id);
    });
    const isNew = application?.is_new;
    // const isPast = application?.is_past_application;
    const isWithdrawn =
      application?.status === APPLICATION_STATUS.Withdrawn;
    const isSubmitted = application?.is_fully_submitted;
    // const isReadOnly = isPast || isWithdrawn;
    const depositPaid = application?.is_deposit_paid;
    const applicationId = match.params.id;
    const inputRef = React.createRef(null);
    const isQuickSubmitVisible =
      isSubmitted &&
      !isWithdrawn &&
      application?.is_ucas &&
      !application?.is_offline_app;

    const isQuickSubmitTagingVisible =
      application?.is_ucas &&
      application?.tagging_automation_enabled &&
      application?.portal === "KC";

    return (
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex-1 flex flex-col overflow-hidden">
          {!application ? (
            <div
              className={
                "flex-1 max-w-screen-lgfl flex flex-col justify-center items-center"
              }
            >
              <Loader
                type="Oval"
                color={"grey"}
                height={50}
                width={50}
              />
            </div>
          ) : (
            <>
              <div>
                {!(
                  application.school?.is_ucas_da_enabled &&
                  application.school
                    ?.is_cialfo_buzzword_for_ucas_da_enabled
                ) &&
                  application.is_ucas && (
                    <div className="bg-orange-100 p-2">
                      <div className="flex items-center">
                        <FontAwesomeIcon
                          icon={["far", "exclamation-triangle"]}
                          className="text-lg text-orange-800 leading-none pl-2"
                        />
                        <div className="pl-4 text-gray-1000">
                          {`The counselor for this school has ${
                            application.school?.is_ucas_da_enabled
                              ? "not explicitly acknowledged to using Cialfo buzzword for UK applications."
                              : "opted out from using Direct Apply for UK universities."
                          } Please confirm with them before processing this application.`}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              {this.state.showFormIncompleteBanner &&
                !application.is_form_complete && (
                  <div className="bg-red-100 p-2">
                    <div className="flex items-center">
                      <FontAwesomeIcon
                        icon={["far", "circle-xmark"]}
                        className="text-lg text-red-800 leading-none pl-2"
                      />
                      <div className="pl-4 text-gray-1000">
                        This application cannot be submitted as the
                        form is incomplete
                      </div>
                      <div className="flex-1" />
                      <div
                        onClick={this._hideFormIncompleteBanner}
                        className="cursor-pointer"
                      >
                        <FontAwesomeIcon
                          icon={["fas", "xmark"]}
                          className="text-lg leading-none pl-2"
                        />
                      </div>
                    </div>
                  </div>
                )}

              <div
                className={
                  "flex-1 flex flex-row overflow-hidden pl-4 pt-5 pb-3 pr-3"
                }
              >
                <div
                  className={"flex-1 flex flex-col overflow-hidden"}
                >
                  <div className="flex-none flex flex-row items-center mb-3 pr-2">
                    <span
                      className={
                        "text-lg text-gray-1000 font-bold leading-none ml-2"
                      }
                    >
                      {
                        <FontAwesomeIcon
                          onClick={() => history.goBack()}
                          icon={["fas", "chevron-left"]}
                          className="text-base mr-4 cursor-pointer"
                        />
                      }
                      {application.first_name +
                        " " +
                        application.last_name}{" "}
                      :
                    </span>
                    <span
                      className={
                        "text-lg text-gray-1000 font-normal ml-1 leading-none"
                      }
                    >
                      {application.school?.name}
                    </span>
                    {application.is_demo ? (
                      <span className="text-lg text-gray-1000 ml-2 leading-none font-bold">
                        {" "}
                        [DEMO]
                      </span>
                    ) : null}
                    <div className={"flex-1"} />
                    {applications?.length > 0 && (
                      <div
                        className={"flex flex-row items-center mx-8"}
                      >
                        <FontAwesomeIcon
                          onClick={() => {
                            const nextAppId =
                              applications[applicationIndex - 1]?.id;

                            if (nextAppId) {
                              this.props.history.push(
                                `/applications/${nextAppId}`,
                              );
                            }
                          }}
                          icon={["fas", "chevron-left"]}
                          className={`text-lg text-gray-750 leading-none cursor-pointer ${
                            applications[applicationIndex - 1]
                              ? ""
                              : "opacity-25"
                          }`}
                        />
                        <span
                          className={"text-sm text-gray-750 mx-5"}
                        >
                          {applicationIndex + 1}/{matchedApplications}
                        </span>
                        <FontAwesomeIcon
                          onClick={() => {
                            const nextAppId =
                              applications[applicationIndex + 1]?.id;

                            if (nextAppId) {
                              this.props.history.push(
                                `/applications/${nextAppId}`,
                              );
                            }
                          }}
                          icon={["fas", "chevron-right"]}
                          className={`text-lg text-gray-750 leading-none cursor-pointer ${
                            applications[applicationIndex + 1]
                              ? ""
                              : "opacity-25"
                          }`}
                        />
                      </div>
                    )}
                    <div
                      className={
                        "flex flex-row self-end items-center " +
                        (isNew || isWithdrawn
                          ? "opacity-50 select-none pointer-events-none"
                          : "")
                      }
                    >
                      {application.v2_questionnaire ? (
                        <div className="flex justify-start items-center">
                          {isSubmitted && application.is_ucas && (
                            <div
                              onClick={() => {
                                this.setState({
                                  isExploreDataVisible: true,
                                });
                              }}
                            >
                              <button className="btn-primary mr-4">
                                Quick compare
                                <FontAwesomeIcon
                                  icon={["fas", "sparkles"]}
                                  className={"text-white pl-2"}
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      ) : null}
                      {(userData.role.name === "admin" ||
                        userData.role.name === "da_support") &&
                      application.is_fully_submitted ? (
                        <div className="flex justify-start items-center">
                          <div
                            onClick={() => {
                              this._loginSuperUser(
                                application.id,
                                application.partner_id,
                              );
                            }}
                          >
                            <button className="btn-border-primary">
                              Update
                            </button>
                          </div>
                        </div>
                      ) : null}
                      <div
                        data-tip
                        data-for="edit_information"
                        onClick={this._showNotesModal}
                        className="flex w-8 h-8 justify-center items-center cursor-pointer rounded-md hover:bg-gray-200"
                      >
                        <FontAwesomeIcon
                          icon={["fas", "file-edit"]}
                          className={
                            "text-lg leading-none text-gray-750"
                          }
                        />
                        <ReactTooltip
                          id="edit_information"
                          aria-haspopup="true"
                          place="bottom"
                          effect={"solid"}
                          arrowColor={"transparent"}
                        >
                          <p className={"text-xs"}>Add notes</p>
                        </ReactTooltip>
                      </div>
                      <div
                        data-tip
                        data-for="activity_information"
                        onClick={this._showActivityModal}
                        className="flex w-8 h-8 justify-center ml-2 items-center cursor-pointer rounded-md hover:bg-gray-200"
                      >
                        <FontAwesomeIcon
                          icon={["fas", "user-clock"]}
                          className={
                            "text-lg leading-none text-gray-750"
                          }
                        />
                        <ReactTooltip
                          id="activity_information"
                          aria-haspopup="true"
                          place="bottom"
                          effect={"solid"}
                          arrowColor={"transparent"}
                        >
                          <p className={"text-xs"}>
                            {t(
                              "direct_apply.application.activity_information",
                            )}
                          </p>
                        </ReactTooltip>
                      </div>
                      <a
                        target={"_blank"}
                        data-tip
                        data-for="messages"
                        className="flex w-8 h-8 justify-center items-center cursor-default ml-2 mr-2 rounded-md hover:bg-gray-200"
                      >
                        <FontAwesomeIcon
                          icon={["fas", "envelope"]}
                          className={
                            "text-lg leading-none text-gray-750"
                          }
                        />
                        <ReactTooltip
                          id="messages"
                          aria-haspopup="true"
                          place="bottom"
                          effect={"solid"}
                          arrowColor={"transparent"}
                        >
                          <p className={"text-xs"}>
                            {t("direct_apply.application.send_email")}
                          </p>
                        </ReactTooltip>
                      </a>
                      {application.is_fully_submitted &&
                        !application.is_offline_app && (
                          <div
                            onClick={() => {
                              if (application.profile_pdf_url)
                                window.open(
                                  application.profile_pdf_url,
                                );
                            }}
                            data-tip
                            data-for="download"
                            className="flex w-8 h-8 justify-center items-center cursor-pointer mr-3 rounded-md hover:bg-gray-200"
                          >
                            <FontAwesomeIcon
                              icon={["fas", "download"]}
                              className={
                                "text-lg leading-none text-gray-750"
                              }
                            />
                            <ReactTooltip
                              id="download"
                              aria-haspopup="true"
                              place="left"
                              effect={"solid"}
                              arrowColor={"transparent"}
                            >
                              <p className={"text-xs"}>
                                {t(
                                  "direct_apply.application.download",
                                )}
                              </p>
                            </ReactTooltip>
                          </div>
                        )}
                      {application.is_fully_submitted === false && (
                        <div className="flex justify-start items-center">
                          <div onClick={this._showSubmissionDialog}>
                            <button
                              className="btn-primary"
                              disabled={this._isSubmissionAllowed()}
                            >
                              Submit Application
                            </button>
                          </div>
                        </div>
                      )}
                      {application.is_fully_submitted === true && (
                        <div className="flex justify-start items-center">
                          <div
                            onClick={this._showUndoSubmissionDialog}
                          >
                            <button
                              className="button-outline-error"
                              disabled={this._isUndoSubmissionAllowed()}
                            >
                              Undo Submission
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={"overflow-scroll"}>
                    {isQuickSubmitVisible && <QuickSubmit />}
                    {isQuickSubmitTagingVisible && (
                      <QuickSubmitTagging />
                    )}
                    <div className={"flex flex-row"}>
                      <div
                        style={{ minWidth: 700 }}
                        className={
                          "flex-1 max-w-screen-lg flex flex-col"
                        }
                      >
                        <div className={"flex-none flex flex-col"}>
                          <div
                            className={
                              "flex flex-col display-section ml-2 mb-6 mt-3 p-0 " +
                              (isWithdrawn
                                ? "opacity-50 select-none pointer-events-none"
                                : "")
                            }
                          >
                            {isReviewBannerVisible ? (
                              <div
                                className={
                                  "flex flex-col bg-blue-50 p-5"
                                }
                              >
                                {application.result !== "Accepted" ? (
                                  <div
                                    className={
                                      "text-base text-black font-bold"
                                    }
                                  >
                                    {t(
                                      "direct_apply.application.progress.review_applications",
                                    )}
                                  </div>
                                ) : null}
                                <div
                                  className={
                                    "flex flex-row items-center"
                                  }
                                >
                                  <div
                                    className={"text-sm text-black"}
                                  >
                                    {application.result !== "Accepted"
                                      ? t(
                                          "direct_apply.application.progress.in_simple_steps",
                                        )
                                      : t(
                                          "direct_apply.application.progress.banner_result_accepted",
                                        )}
                                  </div>
                                  <button
                                    onClick={() =>
                                      this.setState({
                                        isReviewBannerVisible: false,
                                      })
                                    }
                                    className={
                                      "btn-text-primary ml-2"
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={["fas", "thumbs-up"]}
                                      className="mr-1"
                                    />
                                    {t(
                                      "direct_apply.application.buttons.ok_got_it",
                                    )}
                                  </button>
                                </div>
                              </div>
                            ) : null}
                            <div
                              className={
                                "flex flex-row px-5 mt-4 pb-4 border-b border-gray-300"
                              }
                            >
                              <ReviewButton
                                step={1}
                                text={t(
                                  "direct_apply.application.progress.start_reviewing",
                                )}
                                finished={!isNew}
                                disabled={!isNew}
                              />
                              <ReviewButton
                                step={2}
                                text={t(
                                  "direct_apply.application.progress.choose_result",
                                )}
                                finished={application.result}
                                disabled={isNew}
                              />
                              <ReviewButton
                                step={3}
                                text={t(
                                  "direct_apply.application.progress.upload_supporting_document",
                                )}
                                finished={
                                  application.university_documents
                                    .length > 0
                                }
                                disabled={
                                  isNew || !application.result
                                }
                              />
                              {application.result === "Accepted" ? (
                                <ReviewButton
                                  step={4}
                                  text={t(
                                    "direct_apply.application.progress.check_deposit",
                                  )}
                                  finished={
                                    application.is_deposit_paid
                                  }
                                  disabled={
                                    isNew ||
                                    !application.result ||
                                    application.university_documents
                                      .length === 0
                                  }
                                />
                              ) : null}
                            </div>
                            <div
                              className={
                                "flex flex-col px-5 py-4 " +
                                (isNew || isWithdrawn
                                  ? "opacity-50 select-none pointer-events-none"
                                  : "")
                              }
                            >
                              <HeadingLarge
                                text={t(
                                  "direct_apply.application.section_headings.program",
                                )}
                              />
                              <div className="flex flex-col">
                                <DisplayField
                                  label={t(
                                    "direct_apply.application.labels.results",
                                  )}
                                  value={
                                    application.result && (
                                      <div
                                        className={
                                          application.result_class_name
                                        }
                                      >
                                        {Format.titleCase(
                                          application.result,
                                        )}
                                      </div>
                                    )
                                  }
                                />
                                <DisplayField
                                  label={t(
                                    "direct_apply.application.labels.university_decision",
                                  )}
                                  value={
                                    application.university_decision_label && (
                                      <div
                                        className={
                                          application.uni_dec_badge_class
                                        }
                                      >
                                        {Format.titleCase(
                                          application.university_decision_label,
                                        )}
                                      </div>
                                    )
                                  }
                                />
                                <DisplayField
                                  label={t(
                                    "direct_apply.application.labels.student_decision",
                                  )}
                                  value={
                                    application.student_decision_label && (
                                      <div
                                        className={
                                          application.stu_dec_badge_class
                                        }
                                      >
                                        {Format.titleCase(
                                          application.student_decision_label,
                                        )}
                                      </div>
                                    )
                                  }
                                />
                                <DisplayField
                                  label="Submitted by"
                                  value={application.submitter_name}
                                  copyable
                                />
                                <DisplayField
                                  label="Application approved on"
                                  value={this._applicationApprovedOn()}
                                  copyable
                                />
                                <DisplayField
                                  label="Approved by"
                                  value={application.approver_name}
                                  copyable
                                />
                                <DisplayField
                                  label={t(
                                    "direct_apply.application.labels.status",
                                  )}
                                  value={
                                    <div
                                      className={
                                        application.status_class_name
                                      }
                                    >
                                      {Format.titleCase(
                                        application.status,
                                      )}
                                    </div>
                                  }
                                  copyText={Format.titleCase(
                                    application.status,
                                  )}
                                />
                                <>
                                  <DisplayField
                                    label="Application added on"
                                    value={Format.date(
                                      application.added_at,
                                    )}
                                    copyable
                                  />
                                  <DisplayField
                                    label="Application submitted on"
                                    value={Format.date(
                                      application.applied_at,
                                    )}
                                    copyable
                                  />
                                  <DisplayField
                                    label="Cialfo email Account"
                                    value={application.cialfo_email}
                                    copyable
                                    isEdit
                                    isBlur
                                    isEmail
                                    updateApplicationUcasData={
                                      this.props
                                        .updateApplicationUcasData
                                    }
                                    fieldToBeUpdated={
                                      "external_da_email"
                                    }
                                    userId={application.user_id}
                                  />
                                  <DisplayField
                                    label="Password"
                                    value={
                                      application.cialfo_password
                                    }
                                    copyable
                                    isEdit
                                    isBlur
                                    updateApplicationUcasData={
                                      this.props
                                        .updateApplicationUcasData
                                    }
                                    fieldToBeUpdated={
                                      "external_da_email_password"
                                    }
                                    userId={application.user_id}
                                    haveCheckBox
                                  />
                                  <DisplayField
                                    label="Forwarder Status"
                                    value={
                                      application.forwarder_status
                                    }
                                    copyable
                                  />
                                  <DisplayField
                                    label="Forwarder Emails"
                                    value={
                                      application?.forwarder_emails
                                        ?.length > 0
                                        ? application.forwarder_emails.join(
                                            ", ",
                                          )
                                        : "No Forwarder Emails"
                                    }
                                    copyable
                                  />
                                  <DisplayField
                                    label="UCAS ID"
                                    value={application.ucas_id}
                                    copyable
                                    isEdit
                                    updateApplicationUcasData={
                                      this.props
                                        .updateApplicationUcasData
                                    }
                                    fieldToBeUpdated={"ucas_id"}
                                    userId={application.user_id}
                                  />
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.start_date",
                                    )}
                                    value={Format.date(
                                      application.start_date,
                                    )}
                                    copyable
                                  />
                                </>
                                <DisplayField
                                  label={t(
                                    "direct_apply.application.labels.organization",
                                  )}
                                  value={
                                    <div className="flex flex-row items-center">
                                      <img
                                        src={
                                          application.university_logo ||
                                          PlaceholderLogo
                                        }
                                        alt="Uni"
                                        className="w-8 h-8 rounded-full object-cover"
                                      />
                                      <span className="text-sm text-gray-800 font-bold ml-1">
                                        {application.university_name}
                                      </span>
                                    </div>
                                  }
                                  copyable
                                  copyText={
                                    application.university_name
                                  }
                                />
                                <DisplayField
                                  label={t(
                                    "direct_apply.application.labels.application_fee",
                                  )}
                                  copyable
                                  value={
                                    <div
                                      className={`${
                                        application.is_application_fee
                                          ? "text-sm font-bold text-gray-900"
                                          : "badge-disabled"
                                      }`}
                                    >
                                      {application.is_application_fee
                                        ? `${application.application_fee}`
                                        : t(
                                            "direct_apply.application.values.not_applicable",
                                          )}
                                    </div>
                                  }
                                />
                                <DisplayField
                                  label={"Fee Payment Status"}
                                  copyable
                                  value={
                                    <div className="flex flex-row items-center">
                                      <div
                                        className={
                                          application.fee_status_badge
                                        }
                                      >
                                        {application.fee_status
                                          ? application.fee_status
                                          : t(
                                              "direct_apply.application.values.not_applicable",
                                            )}
                                      </div>
                                      {application.fee_waived ? (
                                        <span className="text-sm font-bold text-gray-900 ml-3">
                                          {
                                            application.fee_waived_text
                                          }
                                        </span>
                                      ) : null}
                                    </div>
                                  }
                                />
                                <DisplayField
                                  label={t(
                                    "direct_apply.application.labels.program",
                                  )}
                                  value={application.program_name}
                                  copyable
                                />
                                {application.submission_type ===
                                  APPLICATION_TYPE.UCAS && (
                                  <>
                                    <DisplayField
                                      label="Course Code"
                                      value={application.course_code}
                                      copyable
                                    />
                                    <DisplayField
                                      label="Location option"
                                      value={application.campus_name}
                                      copyable
                                    />
                                    {!application.is_2024 && (
                                      <>
                                        <DisplayField
                                          label="Deferred entry"
                                          value={
                                            application.deferred_entry
                                              ? "Yes"
                                              : "No"
                                          }
                                          copyable
                                        />
                                        <DisplayField
                                          label="Deferred start year"
                                          value={
                                            application.deferred_entry_start_year ||
                                            "-"
                                          }
                                          copyable
                                        />
                                        <DisplayField
                                          label="Point of entry"
                                          value={startCase(
                                            camelCase(
                                              application.point_of_entry,
                                            ),
                                          )}
                                          copyable
                                        />
                                        <DisplayField
                                          label="Live at home while studying"
                                          value={
                                            application.live_at_home
                                              ? "Yes"
                                              : "No"
                                          }
                                          copyable
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>

                          {application.is_old_application ? (
                            <>
                              {application.submission_type ===
                              APPLICATION_TYPE.UCAS ? (
                                <div
                                  className={`flex flex-col display-section ml-2 mb-6 ${
                                    isNew || isWithdrawn
                                      ? "opacity-50 select-none pointer-events-none"
                                      : ""
                                  }`}
                                >
                                  <HeadingLarge text="UCAS course details" />
                                  <div className="flex flex-col">
                                    <DisplayField
                                      label="Choice"
                                      value={application.choice}
                                      copyable
                                    />
                                    <DisplayField
                                      label="Application Form ID"
                                      value={
                                        application.application_form_id
                                      }
                                      copyable
                                    />
                                    <DisplayField
                                      label="Course Code"
                                      value={application.course_code}
                                      copyable
                                    />
                                    <DisplayField
                                      label="Institution Code"
                                      value={
                                        application.institution_code
                                      }
                                      copyable
                                    />
                                    <DisplayField
                                      label="Campus Name"
                                      value={application.campus_name}
                                      copyable
                                    />
                                    <DisplayField
                                      label="Start Date"
                                      value={Format.date(
                                        application.start_date,
                                      )}
                                      copyable
                                    />
                                    <DisplayField
                                      label="Study Mode"
                                      value={application.study_mode}
                                      copyable
                                    />
                                  </div>
                                </div>
                              ) : null}
                              <div
                                className={`flex flex-col display-section ml-2 mb-6 ${
                                  isNew || isWithdrawn
                                    ? "opacity-50 select-none pointer-events-none"
                                    : ""
                                }`}
                              >
                                <HeadingLarge
                                  text={t(
                                    "direct_apply.application.section_headings.personal_information",
                                  )}
                                />
                                <div className="flex flex-col display-field-container">
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.first_name",
                                    )}
                                    value={application.first_name}
                                    copyable
                                  />
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.surname_family_name",
                                    )}
                                    value={application.last_name}
                                    copyable
                                  />
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.preferred_name",
                                    )}
                                    value={application.preferred_name}
                                    copyable
                                  />
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.dob",
                                    )}
                                    value={application.dob}
                                    copyable
                                  />
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.postal_address",
                                    )}
                                    value={application.postal_address}
                                    copyable
                                  />
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.mobile_number",
                                    )}
                                    value={
                                      application.telephone_country_code +
                                      application.mobile_number
                                    }
                                    copyable
                                  />
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.email_address",
                                    )}
                                    value={application.email}
                                    copyable
                                    isEmail
                                  />
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.timezone",
                                    )}
                                    value={application.timezone}
                                    copyable
                                  />
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.gender",
                                    )}
                                    value={application.gender}
                                    copyable
                                  />
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.current_address",
                                    )}
                                    value={
                                      application.current_address +
                                      (application.zip_code
                                        ? ", " + application.zip_code
                                        : "")
                                    }
                                    copyable
                                  />
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.city",
                                    )}
                                    value={application.city}
                                    copyable
                                  />
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.country_of_residence",
                                    )}
                                    value={application.country}
                                    copyable
                                    isBadge
                                  />
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.citizenships",
                                    )}
                                    value={application.citizenships.map(
                                      citizenship => citizenship.name,
                                    )}
                                    copyable
                                    isBadge
                                  />
                                </div>

                                <div className="mt-6" />
                                <HeadingMedium
                                  text={t(
                                    "direct_apply.application.section_headings.guardian_information",
                                  )}
                                />
                                <div className="h-3" />
                                {application.contacts.map(
                                  (contact, index) => (
                                    <React.Fragment key={index}>
                                      <DisplayField
                                        label={t(
                                          "direct_apply.application.labels.full_name",
                                        )}
                                        value={
                                          contact.first_name +
                                          " " +
                                          contact.last_name
                                        }
                                        copyable
                                      />
                                      <DisplayField
                                        label={t(
                                          "direct_apply.application.labels.relationship",
                                        )}
                                        value={
                                          contact.relationship_title
                                        }
                                        copyable
                                      />
                                      <DisplayField
                                        label={t(
                                          "direct_apply.application.labels.email_address",
                                        )}
                                        value={contact.email}
                                        copyable
                                        isEmail
                                      />
                                      <DisplayField
                                        label={t(
                                          "direct_apply.application.labels.telephone_number",
                                        )}
                                        value={
                                          contact.telephone_country_code +
                                          contact.telephone_number
                                        }
                                        copyable
                                      />
                                    </React.Fragment>
                                  ),
                                )}
                              </div>

                              <div
                                className={`flex flex-col display-section ml-2 mb-6 ${
                                  isNew || isWithdrawn
                                    ? "opacity-50 select-none pointer-events-none"
                                    : ""
                                }`}
                              >
                                <HeadingLarge
                                  text={t(
                                    "direct_apply.application.section_headings.education",
                                  )}
                                />
                                <div className="flex flex-col">
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.school_name",
                                    )}
                                    value={application.school?.name}
                                    copyable
                                  />
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.ceeb",
                                    )}
                                    value={
                                      application.school?.ceeb_code
                                    }
                                    copyable
                                  />
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.school_address",
                                    )}
                                    value={
                                      application.school?.address
                                    }
                                    copyable
                                  />
                                </div>
                              </div>

                              <div
                                className={`flex flex-col display-section ml-2 mb-6 ${
                                  isNew || isWithdrawn
                                    ? "opacity-50 select-none pointer-events-none"
                                    : ""
                                }`}
                              >
                                <HeadingLarge
                                  text={t(
                                    "direct_apply.application.section_headings.academics",
                                  )}
                                />
                                <div className="flex flex-col">
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.graduation_year",
                                    )}
                                    value={
                                      application.graduation_year
                                    }
                                    copyable
                                  />
                                  <DisplayField
                                    label={t(
                                      "direct_apply.application.labels.preferred_course_major",
                                    )}
                                    value={application.preferred_courses
                                      .map(pc => pc.name)
                                      .join(", ")}
                                    copyable
                                  />
                                </div>

                                {application.tests.english.length >
                                0 ? (
                                  <>
                                    <div className="mt-4" />
                                    <HeadingMedium
                                      text={t(
                                        "direct_apply.application.section_headings.english_tests",
                                      )}
                                    />
                                    <div className="h-3" />
                                    <div>
                                      {application.tests.english.map(
                                        (test, index, list) => (
                                          <DisplayScoresField
                                            key={index}
                                            label={test.name}
                                            scores={[
                                              {
                                                label: t(
                                                  "direct_apply.application.labels.test_date",
                                                ),
                                                value: test.date,
                                              },
                                              ...(test.total
                                                ? [
                                                    {
                                                      label: t(
                                                        "direct_apply.application.labels.total_score",
                                                      ),
                                                      value:
                                                        test.total,
                                                    },
                                                  ]
                                                : []),
                                              ...test.sections.map(
                                                section => ({
                                                  label: section.name,
                                                  value:
                                                    section.value,
                                                }),
                                              ),
                                            ]}
                                          />
                                        ),
                                      )}
                                    </div>
                                  </>
                                ) : null}
                                {application.tests.general.length >
                                0 ? (
                                  <>
                                    <div className="mt-4" />
                                    <HeadingMedium
                                      text={t(
                                        "direct_apply.application.section_headings.tests",
                                      )}
                                    />
                                    <div className="h-3" />
                                    <div>
                                      {application.tests.general.map(
                                        (test, index, list) => (
                                          <DisplayScoresField
                                            key={index}
                                            label={test.name}
                                            scores={[
                                              {
                                                label: t(
                                                  "direct_apply.application.labels.test_date",
                                                ),
                                                value: test.date,
                                              },
                                              ...(test.total
                                                ? [
                                                    {
                                                      label: t(
                                                        "direct_apply.application.labels.total_score",
                                                      ),
                                                      value:
                                                        test.total,
                                                    },
                                                  ]
                                                : []),
                                              ...test.sections.map(
                                                section => ({
                                                  label: section.name,
                                                  value:
                                                    section.value,
                                                }),
                                              ),
                                            ]}
                                          />
                                        ),
                                      )}
                                    </div>
                                  </>
                                ) : null}
                              </div>

                              <div
                                className={`flex flex-col display-section ml-2 mb-6 ${
                                  isNew || isWithdrawn
                                    ? "opacity-50 select-none pointer-events-none"
                                    : ""
                                }`}
                              >
                                <HeadingMedium
                                  text={t(
                                    "direct_apply.application.section_headings.disclaimer",
                                  )}
                                />

                                <div className="h-6" />

                                {application.additional_requirement_answers.map(
                                  (additionalRequirement, index) => (
                                    <div
                                      key={index}
                                      className="flex flex-col"
                                    >
                                      <AdditionalRequirements
                                        key={index}
                                        question={
                                          additionalRequirement
                                        }
                                      />
                                      <div className="h-px bg-gray-300 my-6" />
                                    </div>
                                  ),
                                )}
                              </div>
                            </>
                          ) : (
                            <DirectApplicationDetailNew
                              application={application}
                              isWithdrawn={isWithdrawn}
                              isNew={isNew}
                            />
                          )}
                        </div>
                      </div>

                      <div
                        style={{ width: 400 }}
                        className={
                          "flex-none flex flex-col ml-6 mt-3 pr-3 "
                        }
                      >
                        <RequestDocument id={applicationId} />

                        <RequestedDocumentsListing
                          id={applicationId}
                        />

                        <div
                          className={
                            isNew || isWithdrawn
                              ? "opacity-50 select-none pointer-events-none"
                              : ""
                          }
                        >
                          <HeadingLarge text={"Documents"} />
                          <div className="flex flex-col display-section">
                            <div
                              className={
                                "flex flex-row items-center justify-between mb-2"
                              }
                            >
                              <HeadingMedium
                                text={t(
                                  "direct_apply.application.section_headings.supporting_documents",
                                )}
                              />
                              <div
                                data-tip
                                data-for="upload_document"
                                onClick={this._showUploadModal}
                                className=""
                              >
                                <button className="btn-text-primary w-40 cursor-default">
                                  <FontAwesomeIcon
                                    icon={["fas", "upload"]}
                                    className="mr-1"
                                  />
                                  {t(
                                    "direct_apply.application.buttons.upload_document",
                                  )}
                                </button>
                                <ReactTooltip
                                  id="upload_document"
                                  aria-haspopup="true"
                                  place="bottom"
                                  effect={"solid"}
                                  arrowColor={"transparent"}
                                >
                                  <p className={"text-xs w-56"}>
                                    {t(
                                      "direct_apply.application.tool_tips.upload_document",
                                    )}
                                  </p>
                                </ReactTooltip>
                              </div>
                            </div>
                            <div className="flex flex-col">
                              {application.university_documents
                                .length > 0 ? (
                                application.university_documents
                                  .sort((a, b) =>
                                    a.is_deleted > b.is_deleted
                                      ? 1
                                      : b.is_deleted > a.is_deleted
                                        ? -1
                                        : 0,
                                  )
                                  .map((document, index, list) => (
                                    <FileAttachment
                                      key={index}
                                      fileName={document.name}
                                      downloadUrl={document.url}
                                      is_deleted={document.is_deleted}
                                      index={index}
                                      openModal={() =>
                                        this.openConfirmationModal(
                                          document,
                                        )
                                      }
                                    />
                                  ))
                              ) : (
                                <FileAttachment
                                  fileName={t(
                                    "direct_apply.application.no_file_uploaded",
                                  )}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                          {(!application.client_documents ||
                            !application.client_documents
                              ?.Counselor) &&
                            !application.predicted_grades_sent &&
                            !application?.reference_letter?.id &&
                            application.is_ucas && (
                              <div className="w-full pt-4">
                                <UploadCounselorDocument
                                  userId={application.user_id}
                                  buttonWidth="220px"
                                  getApplication={
                                    this.props.getApplication
                                  }
                                  id={applicationId}
                                  predictedGradesSent={
                                    application.predicted_grades_sent
                                  }
                                  isReferenceLetterAvailable={
                                    application?.reference_letter?.id
                                  }
                                />
                              </div>
                            )}

                          {(!application.client_documents ||
                            !application.client_documents
                              .Counselor) && (
                            <div>
                              {(application.predicted_grades_sent ||
                                application?.reference_letter?.id) &&
                                application.is_ucas && (
                                  <>
                                    <div className="flex flex-col mt-3 border-b border-gray-300 mt-4 relative display-section">
                                      <div className="flex flex-row items-center justify-between mb-2">
                                        <HeadingMedium
                                          text={t(
                                            "direct_apply.application.section_headings.application_documents",
                                          )}
                                        />
                                      </div>
                                      <div>
                                        <div className="flex flex-row justify-between mb-1 items-center">
                                          <h2 className="text-sm text-gray-750 font-bold">
                                            Counselor Documents
                                          </h2>
                                          {!(
                                            application.predicted_grades_sent &&
                                            application
                                              ?.reference_letter?.id
                                          ) &&
                                            application.is_ucas && (
                                              <UploadCounselorDocument
                                                userId={
                                                  application.user_id
                                                }
                                                buttonWidth="160px"
                                                getApplication={
                                                  this.props
                                                    .getApplication
                                                }
                                                id={applicationId}
                                                predictedGradesSent={
                                                  application.predicted_grades_sent
                                                }
                                                isReferenceLetterAvailable={
                                                  application
                                                    ?.reference_letter
                                                    ?.id
                                                }
                                              />
                                            )}
                                        </div>
                                        {application.predicted_grades_sent &&
                                          !application
                                            ?.predicted_grades
                                            ?.uploaded_by_cialfo &&
                                          application.is_ucas && (
                                            <PredictedGradeDocument
                                              predictedGradesSentAt={
                                                application.predicted_grades_sent_at
                                              }
                                            />
                                          )}
                                        {application?.predicted_grades
                                          ?.id &&
                                          application
                                            ?.predicted_grades
                                            ?.uploaded_by_cialfo &&
                                          application.is_ucas && (
                                            <DocumentDownload
                                              documentName={
                                                application
                                                  .predicted_grades
                                                  .type
                                              }
                                              id={
                                                application
                                                  .predicted_grades.id
                                              }
                                              fileName={
                                                application
                                                  .predicted_grades
                                                  .name
                                              }
                                              downloadUrl={
                                                application
                                                  .predicted_grades
                                                  .url
                                              }
                                              uploaded_at={
                                                application
                                                  .predicted_grades
                                                  .uploaded_at
                                              }
                                            />
                                          )}
                                        {application?.reference_letter
                                          ?.id &&
                                          application.is_ucas && (
                                            <DocumentDownload
                                              documentName={
                                                application
                                                  .reference_letter
                                                  .type
                                              }
                                              id={
                                                application
                                                  .reference_letter.id
                                              }
                                              fileName={
                                                application
                                                  .reference_letter
                                                  .name
                                              }
                                              downloadUrl={
                                                application
                                                  .reference_letter
                                                  .url
                                              }
                                              uploaded_at={
                                                application
                                                  .reference_letter
                                                  .uploaded_at
                                              }
                                              skipCounselorUpload={
                                                application
                                                  .reference_letter
                                                  .skip_counselor_upload
                                              }
                                            />
                                          )}
                                      </div>
                                    </div>
                                  </>
                                )}
                            </div>
                          )}

                          {application.client_documents &&
                          Object.keys(application.client_documents)
                            .length > 0 ? (
                            <div className="flex flex-col mt-3 border-b border-gray-300 mt-4 relative display-section">
                              <div
                                className={
                                  "flex flex-row items-center justify-between mb-2"
                                }
                              >
                                <HeadingMedium
                                  text={t(
                                    "direct_apply.application.section_headings.application_documents",
                                  )}
                                />
                                <button
                                  onClick={
                                    this._zipAndDownloadDocuments
                                  }
                                  className="btn-text-primary w-40"
                                >
                                  {isDownloadingDocuments ? (
                                    <Loader
                                      type="Oval"
                                      color={"#007BE2"}
                                      height={20}
                                      width={20}
                                    />
                                  ) : (
                                    <>
                                      <FontAwesomeIcon
                                        icon={["fas", "download"]}
                                        className="mr-1"
                                      />
                                      <span>
                                        {t(
                                          "direct_apply.application.buttons.all_documents",
                                        )}
                                      </span>
                                    </>
                                  )}
                                </button>
                              </div>
                              {map(
                                application.client_documents,
                                (documents, category) => (
                                  <div key={category}>
                                    <div
                                      className={
                                        "flex flex-row justify-between mb-1 w-full items-center"
                                      }
                                    >
                                      <h2 className="text-sm text-gray-750 font-bold">
                                        {category + " Documents"}
                                      </h2>
                                      {category === "Counselor" &&
                                        !(
                                          application.predicted_grades_sent &&
                                          application
                                            ?.reference_letter?.id
                                        ) &&
                                        application.is_ucas && (
                                          <UploadCounselorDocument
                                            userId={
                                              application.user_id
                                            }
                                            buttonWidth="160px"
                                            getApplication={
                                              this.props
                                                .getApplication
                                            }
                                            id={applicationId}
                                            predictedGradesSent={
                                              application.predicted_grades_sent
                                            }
                                            isReferenceLetterAvailable={
                                              application
                                                ?.reference_letter?.id
                                            }
                                          />
                                        )}
                                    </div>
                                    {documents.map(
                                      (document, index, list) => (
                                        <DocumentDownload
                                          key={category + index}
                                          documentName={document.type}
                                          id={document.id}
                                          fileName={document.name}
                                          downloadUrl={document.url}
                                          uploaded_at={
                                            document.uploaded_at
                                          }
                                          skipCounselorUpload={
                                            document.skip_counselor_upload
                                          }
                                        />
                                      ),
                                    )}
                                    {category === "Counselor" && (
                                      <div>
                                        {application.predicted_grades_sent &&
                                          !application
                                            ?.predicted_grades
                                            ?.uploaded_by_cialfo &&
                                          application.is_ucas && (
                                            <PredictedGradeDocument
                                              predictedGradesSentAt={
                                                application.predicted_grades_sent_at
                                              }
                                            />
                                          )}
                                        {application?.reference_letter
                                          ?.id &&
                                          application.is_ucas && (
                                            <DocumentDownload
                                              documentName={
                                                application
                                                  .reference_letter
                                                  .type
                                              }
                                              id={
                                                application
                                                  .reference_letter.id
                                              }
                                              fileName={
                                                application
                                                  .reference_letter
                                                  .name
                                              }
                                              downloadUrl={
                                                application
                                                  .reference_letter
                                                  .url
                                              }
                                              uploaded_at={
                                                application
                                                  .reference_letter
                                                  .uploaded_at
                                              }
                                              skipCounselorUpload={
                                                application
                                                  .reference_letter
                                                  .skip_counselor_upload
                                              }
                                            />
                                          )}
                                        {application?.predicted_grades
                                          ?.id &&
                                          application
                                            ?.predicted_grades
                                            ?.uploaded_by_cialfo &&
                                          application.is_ucas && (
                                            <DocumentDownload
                                              documentName={
                                                application
                                                  .predicted_grades
                                                  .type
                                              }
                                              id={
                                                application
                                                  .predicted_grades.id
                                              }
                                              fileName={
                                                application
                                                  .predicted_grades
                                                  .name
                                              }
                                              downloadUrl={
                                                application
                                                  .predicted_grades
                                                  .url
                                              }
                                              uploaded_at={
                                                application
                                                  .predicted_grades
                                                  .uploaded_at
                                              }
                                            />
                                          )}
                                      </div>
                                    )}
                                  </div>
                                ),
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {/* <UploadDocumentModal
          applicationId={applicationId}
          visible={isUploadModalVisible}
          onCancel={this._hideUploadModal}
        /> */}
        {isExploreDataVisible && (
          <ExploreData
            userData={userData}
            application={application}
            applicationId={applicationId}
            lastSyncDate={application.ucas_form_last_filled_at}
            profilePdfUrl={application.profile_pdf_url}
            ucasCounselorReport={application.ucas_counselor_report}
            visible={isExploreDataVisible}
            onClose={this._hideExploreDataModal}
          />
        )}

        <ActivityHistoryModal
          application={application}
          visible={isActivityModalVisible}
          onClose={this._hideActivityModal}
        />
        <NotesModal
          id={applicationId}
          visible={this.state.isNotesModalVisible}
          onClose={this._hideNotesModal}
        />
        <SubmissionDialog
          id={applicationId}
          application={application}
          visible={this.state.isSubmissionDialogVisible}
          onClose={this._hideSubmissionDialog}
        />
        <UndoSubmissionDialog
          id={applicationId}
          visible={this.state.isUndoSubmissionDialogVisible}
          onClose={this._hideUndoSubmissionDialog}
        />
        <ConfirmationModal
          visible={confirmationModalVisible}
          title={
            depositPaid
              ? t(
                  "direct_apply.application.confirmation_modal.deposit_not_paid_title",
                )
              : t(
                  "direct_apply.application.confirmation_modal.deposit_paid_title",
                )
          }
          // message={t(""))}
          cancelText={t(
            "direct_apply.application.confirmation_modal.cancel",
          )}
          confirmText={t(
            "direct_apply.application.confirmation_modal.confirm",
          )}
          onCancel={this._cancelDepositChange}
          onConfirm={this._confirmDepositChange}
        />
        {updatingApplication ? (
          <div className="flex justify-center items-center z-20 absolute inset-0 bg-gray-200">
            <Loader type="Oval" color="grey" height={50} width={50} />
          </div>
        ) : null}
        <ConfirmationModal
          key="conform"
          visible={deleteConfirmationModalVisible}
          message="Are you sure want to delete this document?"
          title="Delete"
          cancelText={"Cancel"}
          confirmText={"Delete"}
          onCancel={this._hideConfirmationModal}
          onConfirm={this._deleteDocument}
        />
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(DirectApplicationDetailScreen),
);
