import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { DirectApplyCreators, DirectApplyTypes } from "src/redux/actions";
import { Button, Wrapper, Menu, MenuItem } from "react-aria-menubutton";
import Loader from "react-loader-spinner";
import EventBus from "eventing-bus";

const MenuItems = (props) => {
  const { document_type, setIsOpen, setSelectedDocument } = props;
  return (
    <MenuItem>
      <div className="w-full">
        <button
          onClick={() => {
            setIsOpen(true);
            setSelectedDocument(document_type);
          }}
          className="dropdown-menu-item"
        >
          {document_type}
        </button>
      </div>
    </MenuItem>
  );
};

const UploadCounselorDocument = (props) => {
  const {
    uploadCounselorDocument,
    t,
    userId,
    buttonWidth,
    getApplication,
    id,
    predictedGradesSent,
    isReferenceLetterAvailable,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const documentUploadSuccess = EventBus.on(
      DirectApplyTypes.UPLOAD_COUNSELOR_DOCUMENT_SUCCESS,
      () => {
        setUploading(false);
        setIsOpen(false);
        setFile(null);
        getApplication(id);
      }
    );
    return () => {
      documentUploadSuccess();
    };
  }, []);

  return (
    <>
      <div>
        <Wrapper className="relative flex-1 flex">
          <Button className="flex flex-row items-center focus:outline-none">
            <button className="btn-text-primary" style={{ width: buttonWidth }}>
              <FontAwesomeIcon icon={["fas", "upload"]} className="mr-1" />
              Upload Counselor Document
            </button>
          </Button>
          <Menu open className="dropdown-menu" style={{ width: 180 }}>
            {!predictedGradesSent && (
              <MenuItems
                document_type="Predictive Grades"
                setIsOpen={setIsOpen}
                setSelectedDocument={setSelectedDocument}
              />
            )}

            {!isReferenceLetterAvailable && (
              <MenuItems
                document_type="UK Reference Letter"
                setIsOpen={setIsOpen}
                setSelectedDocument={setSelectedDocument}
              />
            )}
          </Menu>{" "}
        </Wrapper>
      </div>
      {isOpen && (
        <ReactModal
          isOpen={isOpen}
          style={{
            content: {
              width: "500px",
              margin: "200px auto",
              outline: "none",
            },
          }}
          overlayClassName="modal-overlay"
          className="modal-content"
        >
          <div className="bg-white p-8 rounded-md">
            <div className={"flex items-center flex-row justify-between mb-4"}>
              <span className="font-bold text-black text-xl">
                Upload {selectedDocument}
              </span>

              <FontAwesomeIcon
                onClick={() => {
                  setIsOpen(false);
                  setUploading(false);
                  setFile(null);
                }}
                icon={["fas", "times"]}
                className={"text-lg leading-none text-gray-750 cursor-pointer"}
              />
            </div>

            {file ? (
              <div className="flex-1 flex flex-row text-blue-700 border-t border-b border-gray-300">
                <div className="flex-none flex w-10 justify-center items-center bg-blue-50">
                  <FontAwesomeIcon
                    icon={["fas", "file-pdf"]}
                    className={"text-2xl leading-none"}
                  />
                </div>
                <div className="flex-1 flex flex-col justify-center ml-3 py-2">
                  <p className="text-xs mb-0">{file.name}</p>
                </div>
                {uploading ? (
                  <div className="flex-none flex w-10 justify-center items-center">
                    <Loader
                      type="Oval"
                      color={"#007BE2"}
                      height={20}
                      width={20}
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => setFile(null)}
                    className="flex-none flex w-10 h-10 justify-center items-center cursor-pointer"
                  >
                    <FontAwesomeIcon
                      icon={["fas", "trash-can"]}
                      className={"text-lg text-gray-750 leading-none"}
                    />
                  </div>
                )}
              </div>
            ) : (
              <>
                <input
                  accept=".pdf"
                  name="document"
                  id="document"
                  type="file"
                  className={"hidden"}
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                />
                <label htmlFor="document" className="cursor-pointer">
                  <div
                    className={
                      "flex justify-center items-center h-32 bg-blue-50 border border-dashed border-blue-700 mt-3"
                    }
                  >
                    <div className={"btn-base text-blue-700 cursor-pointer"}>
                      <FontAwesomeIcon
                        icon={["fas", "upload"]}
                        className="mr-1"
                      />
                      {t(
                        "direct_apply_programs.upload_document.upload_document"
                      )}
                    </div>
                  </div>
                </label>
              </>
            )}
            <div className="flex flex-none justify-end mt-4">
              <button
                disabled={!file}
                onClick={() => {
                  setUploading(true);
                  uploadCounselorDocument(userId, {
                    document_type: selectedDocument,
                    file,
                    file_name: file.name,
                  });
                }}
                className="btn-primary"
              >
                <span className="">Upload</span>
              </button>
            </div>
          </div>
        </ReactModal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  uploadCounselorDocument: DirectApplyCreators.uploadCounselorDocumentRequest,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(UploadCounselorDocument)
);
