import { API } from "../helpers";
import { SERVER_URL } from "../config";

export function getProgram(authToken, id) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/programs/${id}`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function updateProgram(authToken, id, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/programs/${id}`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
    data,
  });
}

export function deleteProgram(authToken, id) {
  return API.request({
    method: "delete",
    url: `${SERVER_URL}/api/v1/admins/programs/${id}`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
  });
}

export function createProgramCopy(authToken, id) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/programs/${id}/copy`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
  });
}

export function getProgramsTypes(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/programs/program_types`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getProgramsSubmissionTypes(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/programs/program_submission_types`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getProgramsTypeContent(authToken, programType) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/programs/program_types_content?name=${programType}`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getCurrencies(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/programs/currencies`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getBrochures(authToken, id) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/programs/${id}/brochures`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getDocumentTypes(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/document_types`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getBanners(authToken, id) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/programs/${id}/banners`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function changeProgramStatus(authToken, programId, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/programs/${programId}`,
    headers: {
      authentication_token: authToken,
    },
    data: data,
  });
}

export function getIntakeRounds(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/programs/rounds`,
    headers: {
      authentication_token: authToken,
    },
  });
}
