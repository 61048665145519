import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  UcasApplicationFormCreators,
  UcasApplicationFormTypes,
} from "../actions";
import {
  saveUcasApplicationForm,
  fillEducationSection,
  fillPersonalStatement,
  getUcasApplicationForm,
  saveNotes,
  fetchAppReviewLogs,
  getIgnoreReviewData,
} from "../../web-services";
import { Notification } from "../../helpers";

const authTokenSelector = (state) => state.user.authToken;

export function* watchUcasApplicationFormRequests() {
  yield takeEvery(
    UcasApplicationFormTypes.SAVE_UCAS_APPLICATION_FORM_REQUEST,
    requestSaveUcasApplicationForm
  );

  yield takeEvery(
    UcasApplicationFormTypes.FILL_EDUCATION_SECTION_REQUEST,
    requestFillEducationSection
  );

  yield takeEvery(
    UcasApplicationFormTypes.SAVE_NOTES_REQUEST,
    requestSaveNotes
  );

  yield takeEvery(
    UcasApplicationFormTypes.GET_UCAS_APPLICATION_FORM_REQUEST,
    requestUcasApplicationForm
  );

  yield takeEvery(
    UcasApplicationFormTypes.GET_APP_REVIEW_LOGS,
    requestAppReviewLogs
  );

  yield takeEvery(
    UcasApplicationFormTypes.REQUEST_IGNORE_REVIEW_DATA,
    requestIgnoreReviewData
  );

  yield takeEvery(
    UcasApplicationFormTypes.FILL_PERSONAL_STATEMENT_REQUEST,
    requestFillPersonalStatement,
  );
}

export function* requestSaveUcasApplicationForm(action) {
  try {
    const { applicationId } = action;
    const authToken = yield select(authTokenSelector);

    const response = yield call(
      saveUcasApplicationForm,
      authToken,
      applicationId
    );
    if (response?.data) {
      Notification.success(response.data?.message);
    }
    yield put(
      UcasApplicationFormCreators.saveUcasApplicationFormSuccess(response.data)
    );
  } catch (error) {
    yield put(UcasApplicationFormCreators.saveUcasApplicationFormFailure());
  }
}

export function* requestFillEducationSection(action) {
  try {
    const { applicationId } = action;
    const authToken = yield select(authTokenSelector);

    const response = yield call(
      fillEducationSection,
      authToken,
      applicationId
    );

    yield put(
      UcasApplicationFormCreators.fillEducationSectionSuccess(response.data)
    );
  } catch (error) {
    yield put(UcasApplicationFormCreators.fillEducationSectionFailure());
  }
}

export function* requestSaveNotes(action) {
  try {
    const { applicationId, notes } = action;
    const authToken = yield select(authTokenSelector);

    const data = {
      ucas_student_info: {
        notes: notes,
      },
    };

    const response = yield call(saveNotes, authToken, applicationId, data);
    if (response?.data) {
      Notification.success(response.data?.message);
    }
    yield put(UcasApplicationFormCreators.saveNotesSuccess(response.data));
  } catch (error) {
    yield put(UcasApplicationFormCreators.saveNotesFailure());
  }
}

export function* requestUcasApplicationForm(action) {
  try {
    const authToken = yield select((state) => state.user.authToken);
    const response = yield call(
      getUcasApplicationForm,
      authToken,
      action?.applicationId
    );

    yield put(
      UcasApplicationFormCreators.getUcasApplicationFormSuccess(response.data)
    );
  } catch (error) {
    yield put(UcasApplicationFormCreators.getUcasApplicationFormFailure(error));
  }
}

export function* requestAppReviewLogs(action) {
  try {
    const authToken = yield select((state) => state.user.authToken);
    yield call(fetchAppReviewLogs, authToken, action.data);
  } catch (error) { }
}

function* requestIgnoreReviewData(action) {
  try {
    const { params, appId, ignore } = action;
    const authToken = yield select((state) => state.user.authToken);
    const response = yield call(getIgnoreReviewData, authToken, appId, params);
    if (response?.data && ignore) {
      Notification.success("Mismatch ignored successfully.");
    }
    yield put(
      UcasApplicationFormCreators.requestIgnoreReviewDataSuccess(response?.data)
    );
  } catch (error) {
    yield put(UcasApplicationFormCreators.requestIgnoreReviewDataFailure());
  }
}

export function* requestFillPersonalStatement(action) {
  try {
    const { applicationId } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      fillPersonalStatement,
      authToken,
      applicationId
    );

    yield put(
      UcasApplicationFormCreators.fillPersonalStatementSuccess(response.data)
    );
  } catch (error) {
    yield put(UcasApplicationFormCreators.fillPersonalStatementFailure());
  }
}
