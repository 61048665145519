import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CompleteAutomation from "./complete-automation";
import FillEducationSection from "./fill-education-section";
import ShowLastStatus from "../show-last-status";
import { Checkbox } from "antd";
import { APPLICATION_SECTION } from "src/constants/applications.constants";
import { DirectApplyCreators } from "src/redux/actions";
import ReactTooltip from "react-tooltip";

const Education = ({
  application,
  ucasQuickSubmit,
  isUCASAccountCreated,
  t,
  requestMarkComplete,
}) => {
  const {
    education_form_complete,
    education_form_error_message,
    education_form_job_status,
    education_form_completed_at,
    jobs_running,
    unsupported_qualification_names,
  } = ucasQuickSubmit;

  const [hasUcasCredentials, setHasUcasCredentials] = useState(false);

  const handleCheckboxChange = e => {
    setHasUcasCredentials(e.target.checked);
    const params = {
      section: APPLICATION_SECTION.EDUCATION,
      complete: !education_form_complete,
    };
    requestMarkComplete(application?.id, params);
  };

  return (
    <div
      style={{ width: "25%" }}
      className="flex flex-col border rounded bg-white mx-2 relative"
    >
      <div className="border-b text-base font-bold p-4 text-center truncate">
        {t("direct_apply_apps.quick_submit.education.title")}
      </div>
      <div className="flex flex-col items-center p-4">
        {education_form_job_status === "Partially Completed" &&
        !!education_form_error_message &&
        !education_form_complete &&
        !jobs_running ? (
          <div className="mb-4">
            <div className="flex">
              <div className="w-6 flex">
                <Checkbox
                  onChange={handleCheckboxChange}
                  defaultChecked={education_form_complete}
                  className="gray-900"
                />
              </div>
              <div className="flex-1 pl-1 flex">
                <span className="font-body font-normal text-base text-gray-750 leading-6 whitespace-pre-wrap text-left">
                  I have verified details in this section
                </span>
              </div>
            </div>
            {unsupported_qualification_names?.length > 0 && (
              <div
                data-tip
                data-for="few_qualifications_not_filled"
                className="items-center"
              >
                <span className="text-sm text-gray-500 pb-4 text-center ignore-button">
                  A few qualifications could not be filled. Please
                  fill them manually.
                </span>
                <ReactTooltip
                  id={"few_qualifications_not_filled"}
                  aria-haspopup="true"
                  place="top"
                  effect={"solid"}
                  arrowColor={"transparent"}
                >
                  <span className="text-sm text-white-500 pb-4 text-center">
                    {unsupported_qualification_names.join(",  ")}.
                  </span>
                </ReactTooltip>
              </div>
            )}
          </div>
        ) : (
          <CompleteAutomation
            isUCASAccountCreated={isUCASAccountCreated}
          />
        )}
        {!education_form_complete && (
          <FillEducationSection
            application={application}
            ucasQuickSubmit={ucasQuickSubmit}
            isUCASAccountCreated={isUCASAccountCreated}
          />
        )}
        {education_form_job_status === "Partially Completed" &&
          !!education_form_error_message &&
          !education_form_complete &&
          !jobs_running && (
            <span className="text-base text-red-800 block mb-4 text-center">
              {education_form_error_message}
            </span>
          )}
        <ShowLastStatus time={education_form_completed_at} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ucasQuickSubmit: state.directApply.ucasQuickSubmit,
  application: state.directApply.selected,
});

const mapDispatchToProps = {
  requestMarkComplete: DirectApplyCreators.requestMarkComplete,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Education));
