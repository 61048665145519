import { API } from "../helpers";
import { SERVER_URL } from "../config";

export const getStudents = (authToken, data) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins//students`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const getStudentEmail = (authToken, id) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/students/${id}/request_email_account`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export const createUcasAccount = (authToken, id) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/users/${id}/request_ucas_account`,
    headers: {
      authentication_token: authToken,
    },
  });
};
