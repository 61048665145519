import React from "react";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";

const DatePickerField = props => {
  const {
    input,
    meta: { error, touched },
    error: oldError,
    ...rest
  } = props;
  const showError = touched && error;

  return (
    <div className="flex flex-col">
      <div className="w-52">
        <DatePickerInput
          {...rest}
          onChange={event => input.onChange(event)}
          value={input.value}
          placeholder={rest.placeholder || "Select"}
          inputReadOnly={true}
        />
      </div>
      {showError ? (
        <span className="input-field-error-text">{error}</span>
      ) : null}
    </div>
  );
};

export default DatePickerField;
