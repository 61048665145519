import ProgramsPage from "./programs";
import { connect } from "react-redux";
import {
  DirectApplyCreators,
  DirectApplyProgramCreators,
} from "../../../redux/actions";

function mapStateToProps(state) {
  const {
    directApply: {
      program,
      programs,
      total,
      matched,
      loading,
      shouldReloadPrograms,
      programDownloadUrl,
      programsFilterUniversities,
      programsFilterStatuses,
      programTypes,
      programSubmissionTypes,
      programsFilterRounds,
      partners,
      countries,
      programCountries,
      programCitizenships,
      programPartners,
    },
  } = state;

  return {
    program,
    programs,
    total,
    matched,
    isNextPageLoading: loading,
    shouldReloadPrograms,
    programDownloadUrl,
    programsFilterUniversities,
    programsFilterStatuses,
    programTypes,
    programSubmissionTypes,
    programsFilterRounds,
    partners,
    countries,
    programCountries,
    programCitizenships,
    programPartners,
  };
}

const mapDispatchToProps = {
  getPrograms: DirectApplyCreators.getProgramsRequest,
  clearPrograms: DirectApplyCreators.clearPrograms,
  editProgramStatus: DirectApplyProgramCreators.editProgramStatusRequest,
  downloadList: DirectApplyCreators.getProgramsListDownloadUrlRequest,
  getFilterUniversities:
    DirectApplyCreators.getProgramsFilterUniversitiesRequest,
  getFilterStatuses: DirectApplyCreators.getProgramsFilterStatusesRequest,
  getFilterRounds: DirectApplyCreators.getProgramsFilterRoundsRequest,
  getPartners: DirectApplyCreators.getDirectApplyFilterPartnersRequest,
  getProgramCountries:
    DirectApplyCreators.getDirectApplyProgramsCountriesRequest,
  getProgramCitizenships:
    DirectApplyCreators.getDirectApplyProgramsCitizenshipsRequest,
  getProgramPartners: DirectApplyCreators.getDirectApplyProgramsPartnersRequest,
  getProgramTypes: DirectApplyCreators.getDirectApplyFilterProgramTypesRequest,
  getProgramSubmissionTypes:
    DirectApplyCreators.getDirectApplyFilterProgramSubmissionTypesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramsPage);
