import React from "react";
import { CountryCodeSelect } from "../../country-code-select";

const CountrySelectField = props => {
  const { input, meta, ...rest } = props;

  return (
    <CountryCodeSelect
      {...input}
      {...rest}
      error={meta.touched && meta.error}
      onChange={value => input.onChange(value)}
      onBlur={value => input.onBlur()}
      onFocus={() => input.onFocus()}
    />
  );
};

export default CountrySelectField;
