import { createReducer } from 'reduxsauce'
import { PartnerFiltersTypes as Types, UserTypes } from '../actions';
import storeInitialState from '../store/initial-state';

export const INITIAL_STATE = storeInitialState.partnerSearchState;

export const clearPartnerFilters = (state, action) => INITIAL_STATE;

export const setPartnerFilters = (state, action) => ({
  ...state,
  filters: action.filters,
  filtersChanged: true
});

export const setPartnerSearchText = (state, action) => ({
  ...state,
  searchText: action.text
});


const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CLEAR_PARTNER_FILTERS]: clearPartnerFilters,

  [Types.SET_PARTNER_FILTERS]: setPartnerFilters,

  [Types.SET_PARTNER_SEARCH_TEXT]: setPartnerSearchText,

  [UserTypes.LOGOUT]: logout
};

export default createReducer(INITIAL_STATE, HANDLERS);
