import React, { useState } from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Input } from "antd";

const ZapierIntegrationModal = props => {
  const { isOpen, onClose, t } = props;
  const [keyValue, setKeyValue] = useState("");

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: {
          width: "500px",
          margin: "200px auto",
          outline: "none",
        },
      }}
      overlayClassName="modal-overlay"
      className="modal-content"
    >
      <div className="bg-white rounded-md p-8">
        <div
          className={
            "flex items-center flex-row justify-between mb-4"
          }
        >
          <span className="font-bold text-black text-xl">
            {t("clients.zapier_integration")}
          </span>

          <FontAwesomeIcon
            onClick={onClose}
            icon={["fas", "times"]}
            className={
              "text-lg leading-none text-gray-750 cursor-pointer"
            }
          />
        </div>
        <div className="flex flex-col">
          <div className="text-sm text-gray-750 pb-1">Api key</div>
          <Input
            value={keyValue}
            onChange={e => setKeyValue(e.target.value)}
            style={{ height: "40px" }}
          />
        </div>
        <div className="flex justify-end pt-8 ">
          <button className="btn-secondary ml-4" onClick={onClose}>
            {t("clients.cancel")}
          </button>
          <button className="btn-primary ml-4">
            {" "}
            {t("clients.activate")}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ZapierIntegrationModal),
);
