import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Information from "./information";
import Tests from "./test";
import Content from "./content";
import CoursesMajors from "./courses-majors";
import AdditionalRequirements from "./additional-requirements";
import AdditionalRequirementsPreview from "./preview/additional-requirements";
import Preview from "./preview";
import { Notification } from "../../../helpers";
import Loader from "react-loader-spinner";
import _ from "lodash";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ConfirmationModal } from "../../../components";
import { Prompt } from "react-router-dom";

class EditProgramScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingProgram: true,
      firstContentFetchRequestSent: false,
      preventLeavingPage: true,
      leavePageLocation: null,
      isProgramSubmitModalVisible: false,
      isProgramDeleteModalVisible: false,
      isProgramActivateModalVisible: false,
      isProgramDeactivateModalVisible: false,
      isProgramApproveModalVisible: false,
      isProgramDeclineModalVisible: false,
      isLeavePageModalVisible: false,
      tabIndex: 0,
    };

    this.informationRef = React.createRef();
    this.testsRef = React.createRef();
    this.contentRef = React.createRef();
    this.coursesMajorsRef = React.createRef();
    this.additionalRequirementsRef = React.createRef();
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getProgramTypes();
    this.props.getCurrencies();
    this.props.getUniversities();
    this.props.getBrochures(id);
    this.props.getDocumentTypes();
    this.props.getBanners(id);
    this.props.getProgram(id);
    this.props.getIntakeRounds();
    this.props.getCountries();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoadingProgram && !this.props.isLoadingProgram)
      this.setState({ isLoadingProgram: false });

    if (!prevProps.updateSuccess && this.props.updateSuccess) {
      const { programTypes, program, isApproving } = this.props;
      const selectedProgramType = programTypes.find(
        programType => programType.id === program.program_type_id,
      );

      if (!isApproving)
        Notification.success(
          `${selectedProgramType.name} Program saved successfully`,
        );

      if (this.state.tabIndex < 4)
        this.setState({ tabIndex: this.state.tabIndex + 1 });
      else {
        this._showProgramSubmitModal();
      }
    }

    if (
      !prevProps.programLoadFailed &&
      this.props.programLoadFailed
    ) {
      this._navigateToListing();
    }

    if (!prevProps.deleteSuccess && this.props.deleteSuccess) {
      const { programTypes, program } = this.props;
      const selectedProgramType = programTypes.find(
        programType => programType.id === program.program_type_id,
      );

      Notification.success(
        `${selectedProgramType.name} Program deleted successfully`,
      );
      this._navigateToListing();
    }

    if (!prevProps.activateSuccess && this.props.activateSuccess) {
      const { programTypes, program } = this.props;
      const selectedProgramType = programTypes.find(
        programType => programType.id === program.program_type_id,
      );

      Notification.success(
        `${selectedProgramType.name} Program activated successfully`,
      );
      this._navigateToListing();
    }

    if (
      !prevProps.deactivateSuccess &&
      this.props.deactivateSuccess
    ) {
      const { programTypes, program } = this.props;
      const selectedProgramType = programTypes.find(
        programType => programType.id === program.program_type_id,
      );

      Notification.success(
        `${selectedProgramType.name} Program deactivated successfully`,
      );
      this._navigateToListing();
    }

    if (!prevProps.approveSuccess && this.props.approveSuccess) {
      const { programTypes, program } = this.props;
      const selectedProgramType = programTypes.find(
        programType => programType.id === program.program_type_id,
      );

      Notification.success(
        `${
          selectedProgramType ? selectedProgramType.name : ""
        } Program approved successfully`,
      );
      this._navigateToListing();
    }

    if (!prevProps.declineSuccess && this.props.declineSuccess) {
      const { programTypes, program } = this.props;
      const selectedProgramType = programTypes.find(
        programType => programType.id === program.program_type_id,
      );

      Notification.success(
        `${
          selectedProgramType ? selectedProgramType.name : ""
        } Program declined successfully`,
      );
      this._navigateToListing();
    }

    if (!prevProps.saveDraftSuccess && this.props.saveDraftSuccess) {
      const { programTypes, program } = this.props;
      const selectedProgramType = programTypes.find(
        programType => programType.id === program.program_type_id,
      );

      Notification.success(
        `${selectedProgramType.name} Program successfully saved as draft.`,
      );
      this.props.history.push({
        pathname: `/direct-apply/programs`,
      });
    }

    if (
      prevProps.program_type !== this.props.program_type &&
      prevProps.program_type?.label !== this.props.program_type?.label
    ) {
      this.props.getProgramTypeContent(
        this.props.program_type?.label,
      );

      if (this.props.program_type && prevProps.program_type)
        this.setState({ firstContentFetchRequestSent: true });
    }
  }

  _navigateToListing = () => {
    this.props.history.push({
      pathname: `/direct-apply/programs`,
    });
  };

  _deleteProgram = () => {
    const id = this.props.match.params.id;
    this.props.deleteProgram(id);
    this._hideProgramDeleteModal();
  };

  _activateProgram = () => {
    const id = this.props.match.params.id;
    this.props.activateProgram(id);
    this._hideProgramActivateModal();
  };

  _deactivateProgram = () => {
    const id = this.props.match.params.id;
    this.props.deactivateProgram(id);
    this._hideProgramDeactivateModal();
  };

  _approveProgram = () => {
    const id = this.props.match.params.id;
    const { tabIndex } = this.state;

    switch (tabIndex) {
      case 0: // information tab
        this.informationRef.current.wrappedInstance._submit(
          this.informationRef.current.values,
        );
        break;
      case 1: // tests tab
        this.testsRef.current.wrappedInstance._submit(
          this.testsRef.current.values,
        );
        break;
      case 2: // Content tab
        this.contentRef.current.wrappedInstance._submit(
          this.contentRef.current.values,
        );
        break;
      case 3: // Courses/Majors tab
        this.coursesMajorsRef.current.wrappedInstance._submit(
          this.coursesMajorsRef.current.values,
        );
        break;
      case 4: // Additional requirements tab
        this.additionalRequirementsRef.current.wrappedInstance._submit(
          this.additionalRequirementsRef.current.values,
        );
        break;
      default:
    }
    this.props.approveProgram(id);
    this._hideProgramApproveModal();
  };

  _declineProgram = () => {
    const id = this.props.match.params.id;
    this.props.declineProgram(id);
    this._hideProgramDeclineModal();
  };

  _changeTab = index => {
    let location = null;

    switch (index) {
      case 0:
        location = this.props.history.location;
        location.hash = "information";
        this.props.history.push(location);
        break;
      case 1:
        location = this.props.history.location;
        location.hash = "tests";
        this.props.history.push(location);
        break;
      case 2:
        location = this.props.history.location;
        location.hash = "content";
        this.props.history.push(location);
        break;
      case 3:
        location = this.props.history.location;
        location.hash = "courses";
        this.props.history.push(location);
        break;
      default:
    }

    this.setState({ tabIndex: index });
  };

  _handleBlockedNavigation = nextLocation => {
    const { approveSuccess, declineSuccess } = this.props;
    const { tabIndex, preventLeavingPage } = this.state;

    let isValid = true;

    switch (tabIndex) {
      case 0: // information tab
        isValid =
          this.informationRef.current.wrappedInstance.isSavedAndValid();
        break;
      case 1: // tests tab
        isValid =
          this.testsRef.current.wrappedInstance.isSavedAndValid();
        break;
      case 2: // Content tab
        isValid =
          this.contentRef.current.wrappedInstance.isSavedAndValid();
        break;
      case 3: // Courses/Majors tab
        isValid =
          this.coursesMajorsRef.current.wrappedInstance.isSavedAndValid();
        break;
      case 4: // Additional requirements tab
        isValid =
          this.additionalRequirementsRef.current.wrappedInstance.isSavedAndValid();
        break;
      default:
    }

    if (
      isValid ||
      !preventLeavingPage ||
      approveSuccess ||
      declineSuccess
    ) {
      return true;
    } else {
      this.setState({ leavePageLocation: nextLocation });
      this._showLeavePageModal();
      return false;
    }
  };

  _leavePage = () => {
    const { leavePageLocation } = this.state;

    this.setState({ preventLeavingPage: false }, () => {
      // this._hideLeavePageModal();
      this.props.history.push(leavePageLocation);
    });
  };

  _stayOnPage = () => {
    this._hideLeavePageModal();
    this.setState({
      preventLeavingPage: true,
      leavePageLocation: null,
    });
  };

  _hideProgramSubmitModal = () => {
    this.setState({ isProgramSubmitModalVisible: false });
  };

  _showProgramSubmitModal = () => {
    this.setState({ isProgramSubmitModalVisible: true });
  };

  _hideProgramDeleteModal = () => {
    this.setState({ isProgramDeleteModalVisible: false });
  };

  _showProgramDeleteModal = () => {
    this.setState({ isProgramDeleteModalVisible: true });
  };

  _hideProgramActivateModal = () => {
    this.setState({ isProgramActivateModalVisible: false });
  };

  _showProgramActivateModal = () => {
    this.setState({ isProgramActivateModalVisible: true });
  };

  _hideProgramDeactivateModal = () => {
    this.setState({ isProgramDeactivateModalVisible: false });
  };

  _showProgramDeactivateModal = () => {
    this.setState({ isProgramDeactivateModalVisible: true });
  };

  _hideProgramApproveModal = () => {
    this.setState({ isProgramApproveModalVisible: false });
  };

  _showProgramApproveModal = () => {
    this.setState({ isProgramApproveModalVisible: true });
  };

  _hideProgramDeclineModal = () => {
    this.setState({ isProgramDeclineModalVisible: false });
  };

  _showProgramDeclineModal = () => {
    this.setState({ isProgramDeclineModalVisible: true });
  };

  _hideLeavePageModal = () => {
    this.setState({ isLeavePageModalVisible: false });
  };

  _showLeavePageModal = () => {
    this.setState({ isLeavePageModalVisible: true });
  };

  render() {
    const {
      t,
      program,
      programTypes,
      currencies,
      universities,
      isUniversity,
      partnerName,
      programTypeContent,
      isDeleting,
      isActivating,
      isDeactivating,
      isApproving,
      isDeclining,
      isFetchingProgramTypes,
      isFetchingCurrencies,
      isFetchingUniversities,
      isFetchingBrochures,
      isFetchingDocumentTypes,
      isFetchingBanners,
      isFetchingIntakeRounds,
      countries,
      history,
    } = this.props;
    const {
      isLoadingProgram,
      firstContentFetchRequestSent,
      tabIndex,
      isProgramSubmitModalVisible,
      isProgramDeleteModalVisible,
      isProgramActivateModalVisible,
      isProgramDeactivateModalVisible,
      isProgramApproveModalVisible,
      isProgramDeclineModalVisible,
      isLeavePageModalVisible,
    } = this.state;

    if (this.props.programLoadFailed) return null;

    if (
      isLoadingProgram ||
      isFetchingProgramTypes ||
      isFetchingCurrencies ||
      isFetchingUniversities ||
      isFetchingBrochures ||
      isFetchingDocumentTypes ||
      isFetchingBanners ||
      isFetchingIntakeRounds
    ) {
      return (
        <div className="flex-1 flex flex-row justify-center items-center">
          <Loader type="Oval" color={"grey"} height={50} width={50} />
        </div>
      );
    }

    const countryOptions = _.map(countries, country => ({
      value: country.id,
      label: country.name,
    }));
    const selectedProgramType = programTypes.find(
      programType => programType.id === program.program_type_id,
    );
    const selectedFeeCurrency = currencies.find(
      currency => currency.id === program.fee_currency_id,
    );
    const selectedCurrency = currencies.find(
      currency => currency.id === program.cost_currency_id,
    );
    const selectedUniversity = universities.find(
      university => university.id === program.offer_for_id,
    );
    const selectedBrochure = program.brochure
      ? {
          id: program.brochure_id,
          brochure: program.brochure,
          name: program.brochure_name,
        }
      : null;

    const selectedDocuments = {};
    program.documents.forEach(doc => {
      selectedDocuments[doc.document_type_id] = {
        selection: doc.is_required ? "required" : "optional",
        min: doc.required_count,
        max: doc.max_count,
      };
    });

    const programContent = {};
    program.program_content.forEach((content, index) => {
      programContent[index] = {
        enabled:
          content.enabled === "true" || content.enabled === true,
        text: content.text,
        title: content.title,
        icon: content.icon,
      };
    });

    const informationInitialValues = {
      ..._.pick(program, [
        "program_name",
        "id",
        "is_application_fee",
        "course_duration",
      ]),
      partner_name: isUniversity
        ? partnerName
        : selectedUniversity
          ? {
              label: selectedUniversity.name,
              value: selectedUniversity.id,
            }
          : null,
      program_website: program.official_website,
      is_app_fee_req: program.is_application_fee,
      round_name: program.deadline_name
        ? {
            value: program.deadline_name,
            label: program.deadline_name,
          }
        : { value: "", label: "" },
      matriculation_date: program.start_date,
      opening_date: program.opening_date,
      deadline_date: program.deadline_date,
      deadline_: program.start_date,
      program_type: selectedProgramType
        ? {
            value: selectedProgramType.id,
            label: selectedProgramType.name,
          }
        : null,
      application_fee: {
        cost: program.fee,
        currency: selectedFeeCurrency
          ? {
              value: selectedFeeCurrency.id,
              label: selectedFeeCurrency.name,
            }
          : null,
      },
      program_fee: {
        currency: selectedCurrency
          ? {
              value: selectedCurrency.id,
              label: selectedCurrency.name,
            }
          : null,
        min: program.min_cost,
        max: program.max_cost,
      },
      program_picture: program.banner,
      document_type: selectedDocuments,
      brochure: {
        brochure_status: _.snakeCase(program.brochure_status),
        brochure: selectedBrochure,
      },
      is_exclusive_to_countries: program.is_exclusive_to_countries,
      is_exclusive_to_citizenships:
        program.is_exclusive_to_citizenships,
      program_partnerships: program.program_partnerships?.map(
        partnership => {
          const countries = countryOptions.filter(country =>
            partnership.countries.find(c => c.id === country.value),
          );
          const citizenships = countryOptions.filter(country =>
            partnership.citizenships.find(
              c => c.id === country.value,
            ),
          );
          return { ...partnership, citizenships, countries };
        },
      ),
    };

    const programChanges = program.changes;

    const testInitialValues = {
      is_eng_test_req: program.is_english_language_test_required,
      eng_test_desc: program.english_language_requirement,
      toefl: program.toefl_score,
      toefl_min: program.toefl_min_section_score,
      ielts: program.ielts_score,
      duolingo: program.duolingo_score,
      gaokao: program.gaokao_score,
      other_english_test_names: program.other_english_test,
      other_english_test_scores: program.other_english_test_score,
      is_gpa_required: program.is_gpa_required,
      gpa_4: program.gpa_out_of_4,
      gpa_100: program.gpa_out_of_100,
      test_description: program.test_description,
    };

    const contentInitialValues = {
      description: program.description,
      content: programContent,
    };

    const additionalInformationInitialValues = {
      questions_title: program.questions_title,
      questions: program.questions.map((q, i) => ({
        key: i,
        value: {
          ...q,
          questionsChange: programChanges && programChanges.questions,
        },
      })),
    };

    if (programTypeContent) {
      contentInitialValues["program_type_description"] =
        programTypeContent.program_type_description;

      if (firstContentFetchRequestSent)
        contentInitialValues["content"] =
          programTypeContent.program_content;
    }

    if (program.student_testimonials.length > 0) {
      const firstTestimonial = program.student_testimonials[0];
      contentInitialValues["testimonial"] = {
        id: firstTestimonial.id,
        name: firstTestimonial.name,
        country: firstTestimonial.nationality,
        text: firstTestimonial.quote,
        image: firstTestimonial.avatar,
      };
    }

    const coursesMajorInitialValues = {
      ..._.pick(program, [
        "courses_link",
        "courses_list",
        "course_link_available",
      ]),
    };

    let informationChanges = {};
    let numberOfInformationChanges = 0;

    if (programChanges) {
      programChanges.banner &&
        (informationChanges.banner = programChanges.banner);
      programChanges.program_type_id &&
        (informationChanges.program_type =
          programChanges.program_type_id);
      programChanges.program_name &&
        (informationChanges.program_name =
          programChanges.program_name);
      programChanges.official_website &&
        (informationChanges.program_website =
          programChanges.official_website);
      programChanges.fee &&
        (informationChanges.application_fee = programChanges.fee);
      programChanges.min_cost &&
        (informationChanges.min_cost = programChanges.min_cost);
      programChanges.max_cost &&
        (informationChanges.max_cost = programChanges.max_cost);
      programChanges.deadline_name &&
        (informationChanges.round_name =
          programChanges.deadline_name);
      programChanges.course_duration &&
        (informationChanges.course_duration =
          programChanges.course_duration);
      programChanges.program_countries &&
        (informationChanges.countryIds =
          programChanges.program_countries);
      programChanges.program_citizenships &&
        (informationChanges.citizenIds =
          programChanges.program_citizenships);
      programChanges.is_application_fee &&
        (informationChanges.is_app_fee_req =
          programChanges.is_application_fee);
      programChanges.is_exclusive_to_countries &&
        (informationChanges.is_exclusive_to_countries =
          programChanges.is_exclusive_to_countries);
      programChanges.is_exclusive_to_citizenships &&
        (informationChanges.is_exclusive_to_citizenships =
          programChanges.is_exclusive_to_citizenships);
      programChanges.visible_to_citizenships &&
        (informationChanges.visible_to_citizenships =
          programChanges.visible_to_citizenships);
      programChanges.brochure_status &&
        (informationChanges.brochure_status =
          programChanges.brochure_status);
      programChanges.documents &&
        (informationChanges.documents = programChanges.documents);
      programChanges.cost_currency_id &&
        (informationChanges.cost_currency_id =
          programChanges.cost_currency_id);
      programChanges.fee_currency_id &&
        (informationChanges.fee_currency_id =
          programChanges.fee_currency_id);
      programChanges.deadline_date &&
        (informationChanges.deadline_date =
          programChanges.deadline_date);
      programChanges.opening_date &&
        (informationChanges.opening_date =
          programChanges.opening_date);
      programChanges.start_date &&
        (informationChanges.start_date = programChanges.start_date);
      programChanges.letter_changes &&
        programChanges.letter_changes[0]?.max_count &&
        (informationChanges.max_count =
          programChanges.letter_changes[0].max_count);
      programChanges.letter_changes &&
        programChanges.letter_changes[0]?.required_count &&
        (informationChanges.min_count =
          programChanges.letter_changes[0].required_count);
      numberOfInformationChanges = Object.keys(
        informationChanges,
      ).length;
      informationChanges.documents &&
        (numberOfInformationChanges =
          numberOfInformationChanges +
          informationChanges.documents.length -
          1);
    }

    let testChanges = {};
    let numberOfTestChanges = 0;

    if (programChanges) {
      programChanges.toefl_score &&
        (testChanges.toefl = programChanges.toefl_score);
      programChanges.ielts_score &&
        (testChanges.ielts = programChanges.ielts_score);
      programChanges.duolingo_score &&
        (testChanges.duolingo = programChanges.duolingo_score);
      programChanges.gaokao_score &&
        (testChanges.gaokao = programChanges.gaokao_score);
      programChanges.other_english_test_score &&
        (testChanges.other_english_test_scores =
          programChanges.other_english_test_score);
      programChanges.other_english_test &&
        (testChanges.other_english_test_names =
          programChanges.other_english_test);
      programChanges.gpa_out_of_4 &&
        (testChanges.gpa_4 = programChanges.gpa_out_of_4);
      programChanges.gpa_out_of_100 &&
        (testChanges.gpa_100 = programChanges.gpa_out_of_100);
      programChanges.is_english_language_test_required &&
        (testChanges.is_eng_test_req =
          programChanges.is_english_language_test_required);
      programChanges.is_gpa_required &&
        (testChanges.is_gpa_required =
          programChanges.is_gpa_required);
      programChanges.english_language_requirement &&
        (testChanges.eng_test_desc =
          programChanges.english_language_requirement);
      programChanges.test_description &&
        (testChanges.test_description =
          programChanges.test_description);
      numberOfTestChanges = Object.keys(testChanges).length;
    }

    let contentChanges = {};
    let numberOfContentChanges = 0;

    if (programChanges) {
      if (programChanges.student_testimonials) {
        programChanges.student_testimonials[0].name &&
          (contentChanges.name =
            programChanges.student_testimonials[0].name);
        programChanges.student_testimonials[0].nationality &&
          (contentChanges.nationality =
            programChanges.student_testimonials[0].nationality);
        programChanges.student_testimonials[0].quote &&
          (contentChanges.quote =
            programChanges.student_testimonials[0].quote);
        programChanges.student_testimonials[0].avatar &&
          (contentChanges.avatar =
            programChanges.student_testimonials[0].avatar);
      }
      programChanges.program_content &&
        (contentChanges.program_content =
          programChanges.program_content);
      programChanges.description &&
        (contentChanges.description = programChanges.description);
      programChanges.program_type_id &&
        (contentChanges.program_type_id =
          programChanges.program_type_id);
      var programContentChangesCount = 0;

      if (
        !contentChanges.program_type_id &&
        contentChanges.program_content
      ) {
        program.program_content.forEach(content => {
          var foundContent = contentChanges.program_content.filter(
            obj => obj.title === content.title,
          );

          if (foundContent[0]) {
            if (
              !(foundContent[0].enabled === String(content.enabled))
            ) {
              programContentChangesCount++;
            }
            if (!(foundContent[0].text === String(content.text))) {
              programContentChangesCount++;
            }
          }
        });
      }

      numberOfContentChanges = Object.keys(contentChanges).length;
      !contentChanges.program_type_id &&
        contentChanges.program_content &&
        (numberOfContentChanges =
          numberOfContentChanges + (programContentChangesCount - 1));
      contentChanges.program_type_id &&
        (numberOfContentChanges =
          numberOfContentChanges +
          (program.program_content.length * 2 - 2));
    }

    let coursesChanges = {};
    let numberOfCoursesChanges = 0;

    if (programChanges) {
      programChanges.course_link_available &&
        (coursesChanges.course_link_available =
          programChanges.course_link_available);
      programChanges.courses_link &&
        (coursesChanges.courses_link = programChanges.courses_link);
      programChanges.courses_list &&
        (coursesChanges.courses_list = programChanges.courses_list);
      numberOfCoursesChanges = Object.keys(coursesChanges).length;
    }

    let additionalChanges = {};
    let numberOfAdditionalChanges = 0;

    if (programChanges) {
      programChanges.questions_title &&
        (additionalChanges.questions_title =
          programChanges.questions_title);
      programChanges.questions &&
        (additionalChanges.questions = programChanges.questions);
      numberOfAdditionalChanges =
        Object.keys(additionalChanges).length;
      additionalChanges.questions &&
        (numberOfAdditionalChanges =
          numberOfAdditionalChanges +
          additionalChanges.questions.length -
          1);
    }

    return (
      <>
        <div className=" flex py-6 pl-4 height-70px shadow-md items-center">
          <FontAwesomeIcon
            onClick={() =>
              history.push({
                pathname: "/direct-apply/programs",
              })
            }
            icon={["fas", "chevron-left"]}
            className="text-base mr-4 cursor-pointer"
          />
          <div className="text-xl font-bold text-black">
            {program.program_name}
          </div>
        </div>
        <div className="flex-1 flex flex-row overflow-hidden pr-8 pl-4 pt-5 h-full pb-5">
          <Tabs
            forceRenderTabPanel
            className="flex-1 flex flex-col overflow-hidden"
            selectedIndex={tabIndex}
            onSelect={this._changeTab}
          >
            <TabList className="flex flex-row">
              <Tab className="tab">
                <div className="pr-1">
                  {t(
                    "direct_apply_programs.edit.information.tabLabel",
                  )}
                </div>
                {numberOfInformationChanges > 0 && (
                  <div className="badge-warning">
                    {numberOfInformationChanges}
                  </div>
                )}
              </Tab>
              <Tab className="tab">
                <div className="pr-1">
                  {t("direct_apply_programs.edit.tests.tabLabel")}
                </div>
                {numberOfTestChanges > 0 && (
                  <div className="badge-warning">
                    {numberOfTestChanges}
                  </div>
                )}
              </Tab>
              <Tab className="tab">
                <div className="pr-1">
                  {t("direct_apply_programs.edit.content.tabLabel")}
                </div>
                {numberOfContentChanges > 0 && (
                  <div className="badge-warning">
                    {numberOfContentChanges}
                  </div>
                )}
              </Tab>
              <Tab className="tab">
                <div className="pr-1">
                  {t("direct_apply_programs.edit.majors.tabLabel")}
                </div>
                {numberOfCoursesChanges > 0 && (
                  <div className="badge-warning">
                    {numberOfCoursesChanges}
                  </div>
                )}
              </Tab>
              <Tab className="tab">
                <div className="pr-1">
                  {t(
                    "direct_apply_programs.edit.additionalRequirements.tabLabel",
                  )}
                </div>
                {numberOfAdditionalChanges > 0 && (
                  <div className="badge-warning">
                    {numberOfAdditionalChanges}
                  </div>
                )}
              </Tab>
            </TabList>
            <TabPanel>
              <Information
                ref={this.informationRef}
                initialValues={informationInitialValues}
                onDelete={this._showProgramDeleteModal}
                onActivate={this._showProgramActivateModal}
                onDeactivate={this._showProgramDeactivateModal}
                onDecline={this._showProgramDeclineModal}
                onApprove={this._showProgramApproveModal}
                informationChanges={informationChanges}
              />
            </TabPanel>
            <TabPanel>
              <Tests
                ref={this.testsRef}
                initialValues={testInitialValues}
                onDelete={this._showProgramDeleteModal}
                onActivate={this._showProgramActivateModal}
                onDeactivate={this._showProgramDeactivateModal}
                onDecline={this._showProgramDeclineModal}
                onApprove={this._showProgramApproveModal}
                testChanges={testChanges}
              />
            </TabPanel>
            <TabPanel>
              <Content
                ref={this.contentRef}
                initialValues={contentInitialValues}
                onDelete={this._showProgramDeleteModal}
                onActivate={this._showProgramActivateModal}
                onDeactivate={this._showProgramDeactivateModal}
                onDecline={this._showProgramDeclineModal}
                onApprove={this._showProgramApproveModal}
                contentChanges={contentChanges}
              />
            </TabPanel>
            <TabPanel>
              <CoursesMajors
                ref={this.coursesMajorsRef}
                initialValues={coursesMajorInitialValues}
                onDelete={this._showProgramDeleteModal}
                onActivate={this._showProgramActivateModal}
                onDeactivate={this._showProgramDeactivateModal}
                onDecline={this._showProgramDeclineModal}
                onApprove={this._showProgramApproveModal}
                coursesChanges={coursesChanges}
              />
            </TabPanel>
            <TabPanel>
              <AdditionalRequirements
                ref={this.additionalRequirementsRef}
                initialValues={additionalInformationInitialValues}
                onDelete={this._showProgramDeleteModal}
                onActivate={this._showProgramActivateModal}
                onDeactivate={this._showProgramDeactivateModal}
                onDecline={this._showProgramDeclineModal}
                onApprove={this._showProgramApproveModal}
                additionalChanges={additionalChanges}
              />
            </TabPanel>
          </Tabs>
          <div className="flex-none w-300px xxxl:w-800px" />
          {/* {tabIndex !== 4 ? (
            <Preview location={this.props.history.location} />
          ) : (
            <AdditionalRequirementsPreview />
          )} */}
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(EditProgramScreen));
