import CoursesMajors, { validate } from "./courses-majors";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { DirectApplyProgramCreators } from "../../../../redux/actions";
import { snakeCase } from "lodash";
const coursesMajorsSelector = formValueSelector('programCoursesMajorsForm');

function mapStateToProps(state) {
  const {
    directApplyProgram: {
      isUpdating,
      isSavingDraft,
      program: {
        id,
        status,
      },
    },
  } = state;

  return {
    id,
    status: snakeCase(status),
    isUpdating,
    isSavingDraft,
    course_link_available: coursesMajorsSelector(state, 'course_link_available'),
  }
}

const mapDispatchToProps = {
  updateProgram: DirectApplyProgramCreators.updateProgramRequest,
  saveProgramDraft: DirectApplyProgramCreators.saveProgramDraftRequest,
};

export default withTranslation(null, { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    reduxForm({
      form: "programCoursesMajorsForm",
      validate,
      enableReinitialize: true,
    })(CoursesMajors)
  )
);
