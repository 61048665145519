import React, { Component } from "react";
import EventBus from "eventing-bus";
import { withRouter } from "react-router-dom";
import { NewPartnersTypes } from "../../../redux/actions";
import AdminSubmitButton from "../../../components/AdminSubmitButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import countryPhoneCodes from "../../../constants/country-phone-codes";
import DeclinePartnerModal from "./decline-request-modal";
import AcceptPartnerModal from "./accept-request-modal";
import Loader from "react-loader-spinner";
import { Alert } from "../../../helpers";
import {
  INSTITUTE_TYPE,
  PARTNER_STATE,
  PARTNER,
} from "../../../constants/partner";

const flag = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

class NewPartnerDetailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      approveModalVisible: false,
      declineModalVisible: false,
      decliningPartner: false,
      approvingPartner: false,
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    const { state } = this.props.location;
    const { getExistingPartnerRequest, getNewPartnerRequest } =
      this.props;

    if (state === "new") {
      getNewPartnerRequest(id);
    } else {
      getExistingPartnerRequest(id);
    }

    this._subscribeEvents();
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _subscribeEvents = () => {
    this.approveNewPartnerRequestSubscription = EventBus.on(
      NewPartnersTypes.APPROVE_NEW_PARTNER_REQUEST,
      () => this.setState({ approvingPartner: true }),
    );
    this.approveNewPartnerSuccessSubscription = EventBus.on(
      NewPartnersTypes.APPROVE_NEW_PARTNER_SUCCESS,
      this._partnerApprovedSuccessfully,
    );
    this.approveNewPartnerFailureSubscription = EventBus.on(
      NewPartnersTypes.APPROVE_NEW_PARTNER_FAILURE,
      () =>
        this.setState({
          approvingPartner: false,
          approveModalVisible: false,
        }),
    );
    this.declineNewPartnerRequestSubscription = EventBus.on(
      NewPartnersTypes.DECLINE_NEW_PARTNER_REQUEST,
      () => this.setState({ decliningPartner: true }),
    );
    this.declineNewPartnerSuccessSubscription = EventBus.on(
      NewPartnersTypes.DECLINE_NEW_PARTNER_SUCCESS,
      this._partnerDeclinedSuccessfully,
    );
    this.declineNewPartnerFailureSubscription = EventBus.on(
      NewPartnersTypes.DECLINE_NEW_PARTNER_FAILURE,
      () => this.setState({ decliningPartner: false }),
    );
  };

  _unsubscribeEvents = () => {
    this.approveNewPartnerRequestSubscription();
    this.approveNewPartnerSuccessSubscription();
    this.approveNewPartnerFailureSubscription();
    this.declineNewPartnerRequestSubscription();
    this.declineNewPartnerSuccessSubscription();
    this.declineNewPartnerFailureSubscription();
  };

  _partnerApprovedSuccessfully = () => {
    this.setState({
      approvingPartner: false,
      approveModalVisible: false,
    });
    this.props.clearNewPartners();

    Alert.success(
      "The email with the information was send to the applicant",
      {
        customFields: {
          title: "Approval Sent!",
        },
        onClose: () => {
          window.location.replace(
            "/" + this.props.location.pathname.split("/")[1],
          );
        },
      },
    );
  };

  _partnerDeclinedSuccessfully = () => {
    this.setState({
      decliningPartner: false,
      declineModalVisible: false,
    });
    this.props.clearNewPartners();

    Alert.error(
      "The email with information was sent to the applicant",
      {
        customFields: {
          title: "Approval declined!",
        },
        onClose: () => {
          window.location.replace(
            "/" + this.props.location.pathname.split("/")[1],
          );
        },
      },
    );
  };

  _showAcceptModal = () => {
    this.setState({ approveModalVisible: true });
  };

  _closeDelineModal = () => {
    this.setState({ declineModalVisible: false });
  };

  _closeAcceptModal = () => {
    this.setState({ approveModalVisible: false });
  };

  _onDeclineHandler = values => {
    const { declineReason, declineNote } = values;
    const params = {
      partner_request: {
        status: "declined",
        decline_reason_id: declineReason
          ? declineReason.value
          : undefined,
        decline_note: declineNote || "",
      },
    };
    const { partner } = this.props;

    this.props.declinePartner(partner.id, params);
  };

  _onApproveHandler = () => {
    const { partner } = this.props;
    const params = {
      partner_request: {
        status: "approved",
      },
    };

    this.props.approvePartner(partner.id, params);
  };

  render() {
    const {
      fetchingNewPartner,
      partner: newPartner,
      fetchingExistingPartner,
      existingPartner,
      history,
    } = this.props;
    const { state } = this.props.location;
    let label = PARTNER.UNIVERSITY;
    const partner =
      state === PARTNER_STATE.NEW
        ? newPartner
        : existingPartner &&
          existingPartner.user && {
            ...existingPartner,
            ...existingPartner.user,
            applicant_email: existingPartner.user.email,
          };

    if (state !== PARTNER_STATE.NEW && existingPartner) {
      if (
        existingPartner.institute_type ===
        INSTITUTE_TYPE.APPLICATON_CLIENT
      ) {
        label = PARTNER.APPLICATON_CLIENT;
      } else if (
        existingPartner.institute_type === INSTITUTE_TYPE.SCHOOL
      ) {
        label = PARTNER.SCHOOL;
      }
    }

    const {
      decliningPartner,
      declineModalVisible,
      approveModalVisible,
      approvingPartner,
    } = this.state;

    if (!partner) {
      return null;
    }

    const countryCode = flag(countryPhoneCodes, partner.phone_code);

    return (
      <div className="flex-1 flex mt-4">
        <div
          className="bg-white mb-8 ml-6 border shadow"
          style={{ width: "960px", height: "90%" }}
        >
          <div className="h-full overflow-scroll">
            <div
              className="flex items-center font-bold text-22 pb-4 pt-4 pl-6"
              style={{ borderBottomWidth: 0.3 }}
            >
              <FontAwesomeIcon
                onClick={() => history.goBack()}
                icon={["fas", "chevron-left"]}
                className="text-base mr-4 cursor-pointer"
              />
              Review Account
            </div>

            <div className="flex flex-1  flex-col pb-8 pl-8 pt-8 border-b">
              <span className="text-gray-750  text-lg font-bold  pb-5 font-normal leading-none">
                {label} details
              </span>
              <div className="flex flex-1 flex-row pb-4">
                <div className="flex-1 flex flex-col">
                  <span className="text-base font-normal leading-normal">
                    {label} Name
                  </span>
                  <span
                    className={`text-base text-gray-750 font-norma leading-normal`}
                  >
                    {partner.name || "-"}
                  </span>
                </div>
              </div>
              <div className="flex flex-1 flex-row pb-4">
                <div className="flex-1 flex flex-col">
                  <span className="text-base font-normal leading-normal">
                    Country
                  </span>
                  <span
                    className={`text-base text-gray-750 leading-normal`}
                  >
                    {partner.country || "-"}
                  </span>
                </div>
                <div className="flex-1 flex flex-col">
                  <span className="text-base font-normal leading-normal">
                    State
                  </span>
                  <span
                    className={`text-base text-gray-750 leading-normal`}
                  >
                    {partner.state_name || "-"}
                  </span>
                </div>
                <div className="flex-1 flex flex-col">
                  <span className="text-base font-normal leading-normal">
                    City
                  </span>
                  <span
                    className={`text-base text-gray-750 leading-normal`}
                  >
                    {partner.city || "-"}
                  </span>
                </div>
              </div>
              <div className="flex flex-1 flex-row pb-4">
                <div className="flex-1 flex flex-col">
                  <span className="text-base font-normal leading-normal">
                    Address
                  </span>
                  <span
                    className={`text-base text-gray-750 leading-normal`}
                  >
                    {partner.address || "-"}
                  </span>
                </div>
              </div>
              <div className="flex flex-1 flex-row pb-4">
                <div className="flex-1 flex flex-col">
                  <span className="text-base font-normal leading-normal">
                    {label} URL
                  </span>
                  <span
                    className={`text-base text-gray-750 leading-normal`}
                  >
                    {partner.website_url || "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-1 flex-col pb-8 pl-8 pt-5">
              <span className="text-gray-750 text-lg font-bold pb-5 font-normal leading-none">
                Personal details
              </span>
              <div className="flex flex-1 flex-row pb-4">
                <div className="flex-1 flex flex-col">
                  <span className="text-base font-normal leading-normal">
                    First name
                  </span>
                  <span
                    className={`text-base text-gray-750 font-norma leading-normal`}
                  >
                    {partner.first_name || "-"}
                  </span>
                </div>
                <div className="flex-1 flex flex-col">
                  <span className="text-base font-normal leading-normal">
                    Last name
                  </span>
                  <span
                    className={`text-base text-gray-750 font-norma leading-normal`}
                  >
                    {partner.last_name || "-"}
                  </span>
                </div>
              </div>
              <div className="flex flex-1 flex-row pb-4">
                <div className="flex-1 flex flex-col">
                  <span className="text-base font-normal leading-normal">
                    Email (work)
                  </span>
                  <span
                    className={`text-base text-gray-750 leading-normal`}
                  >
                    {partner.applicant_email || "-"}
                  </span>
                </div>
                <div className="flex-1 flex flex-col">
                  <span className="text-base font-normal leading-normal">
                    Phone number
                  </span>
                  <div className="flex flex-row">
                    <img
                      style={{ height: 19, width: 32 }}
                      className="mr-2"
                      src={
                        (partner.country_code || countryCode) &&
                        require(
                          `../../../assets/flags-png/${
                            partner.country_code || countryCode
                          }.png`,
                        ).default
                      }
                      alt="flag"
                    />
                    <span className="text-base font-normal leading-normal">
                      {`   ${partner.phone_code}-${partner.phone}`}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-1 flex-row pb-4">
                <div className="flex-1 flex flex-col">
                  <span className="text-base font-normal leading-normal">
                    Linkedin profile
                  </span>
                  <span
                    className={`text-base text-gray-750 leading-normal`}
                  >
                    {partner.linked_in || "-"}
                  </span>
                </div>
                <div className="flex-1 flex flex-col">
                  <span className="text-base font-normal leading-normal">
                    Sign up
                  </span>
                  <span
                    className={`text-base text-gray-750 leading-normal`}
                  >
                    {partner.sign_up}
                  </span>
                </div>
              </div>
            </div>

            {state === "new" ? (
              <div
                className="border-t flex flex-row items-center pl-8 pr-8"
                style={{ width: "960px", height: "90px" }}
              >
                <AdminSubmitButton
                  onClick={() => {
                    this.setState({ declineModalVisible: true });
                  }}
                  icon={
                    <FontAwesomeIcon icon="ban" color="#CE0000" />
                  }
                  className={
                    "red-text-btn text-red-800 font-bold mr-12px h-36px"
                  }
                  text={"Decline Applicant"}
                />
                <div className="flex-1"></div>
                <button
                  onClick={this._showAcceptModal}
                  className="btn-primary"
                >
                  Approve Applicant
                </button>
              </div>
            ) : null}
          </div>
        </div>

        <AcceptPartnerModal
          onClose={this._closeAcceptModal}
          onApprove={this._onApproveHandler}
          partner={partner}
          visible={approveModalVisible}
          showLoader={approvingPartner}
        />

        <DeclinePartnerModal
          onClose={this._closeDelineModal}
          onDecline={this._onDeclineHandler}
          partner={partner}
          visible={declineModalVisible}
          showLoader={decliningPartner}
        />

        {fetchingNewPartner || fetchingExistingPartner ? (
          <div className="flex justify-center items-center z-20 absolute inset-0 bg-gray-200">
            <Loader type="Oval" color="grey" height={50} width={50} />
          </div>
        ) : null}
      </div>
    );
  }
}
export default withRouter(NewPartnerDetailPage);
