import React, { useState } from "react";
import {
  Link,
  Switch,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";
import ApplicantsPage from "src/views/partners/applicants";
import NewRequests from "src/views/partners/new-requests";
import QrCodeModal from "./qr-code-modal";

const Partners = props => {
  const pathname = props.location.pathname;
  const search = props.location.search;

  const [printQrModal, setPrintQrModal] = useState(false);

  const checkActiveRoute = route => {
    return route === pathname + search;
  };
  return (
    <div className="flex-1 flex flex-col">
      <div className="flex justify-between items-center">
        <div className="flex items-center px-6 py-4">
          <span className="text-xl font-bold text-black mr-6">
            Partners
          </span>

          <Link
            to="/partners/universities"
            replace
            className="pr-4"
            exact
          >
            <button
              className={`px-4 py-2 text-sm border rounded-20px font-bold ${checkActiveRoute("/partners/universities") ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300"}`}
            >
              Universities
            </button>
          </Link>

          <Link to="/partners/schools" replace className="pr-4" exact>
            <button
              className={`px-4 py-2 text-sm border rounded-20px font-bold ${checkActiveRoute("/partners/schools") ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300"}`}
            >
              Schools
            </button>
          </Link>

          <Link
            to="/partners/other-partners"
            replace
            className="pr-4"
          >
            <button
              className={`px-4 py-2 text-sm border rounded-20px font-bold ${checkActiveRoute("/partners/other-partners") ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300"}`}
            >
              Other Partners
            </button>
          </Link>

          <Link to="/partners/new-requests" replace className="pr-4">
            <button
              className={`px-4 py-2 text-sm border rounded-20px font-bold ${checkActiveRoute("/partners/new-requests") ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300"}`}
            >
              New Requests
            </button>
          </Link>
        </div>
        <div>
          <button
            className="btn-secondary mr-8"
            onClick={() => {
              setPrintQrModal(true);
            }}
          >
            Print QR code
          </button>
        </div>
      </div>

      <Switch>
        <Route
          path="/partners/universities"
          render={() => <ApplicantsPage />}
          exact
        />
        <Route
          path="/partners/schools"
          render={() => <ApplicantsPage />}
          exact
        />
        <Route
          path="/partners/other-partners"
          render={() => <ApplicantsPage />}
          exact
        />
        <Route
          path="/partners/new-requests"
          render={() => <NewRequests />}
          exact
        />

        <Redirect
          from="/partners"
          to="/partners/universities"
          render={() => <ApplicantsPage />}
          exact
        />
      </Switch>

      {printQrModal && (
        <QrCodeModal
          isOpen={printQrModal}
          onClose={() => setPrintQrModal(false)}
        />
      )}
    </div>
  );
};

export default withRouter(Partners);
