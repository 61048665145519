import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

const RenderCreationError = ({ application, ucasQuickSubmit }) => {
  const {
    onboarding_complete,
    onboarding_job_status,
    onboarding_show_create_button,
    onboarding_error_message,
    jobs_running,
  } = ucasQuickSubmit;

  const isError =
    !onboarding_complete &&
    onboarding_job_status === "Failed" &&
    onboarding_show_create_button;

  return (
    isError &&
    !jobs_running &&
    application.is_ucas_credential_updated && (
      <span className="text-base text-red-800 block text-center mb-4">
        {onboarding_error_message ||
          "An error occurred. Please try again."}
      </span>
    )
  );
};

const mapStateToProps = state => ({
  application: state.directApply.selected,
  ucasQuickSubmit: state.directApply.ucasQuickSubmit,
});

const ConnectedRenderCreationError = connect(mapStateToProps)(
  RenderCreationError,
);

export default withTranslation()(ConnectedRenderCreationError);
