import React from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { TextInputField } from "src/form-components";
import { reduxForm, Field } from "redux-form";

const IsamsIntegrationModal = props => {
  const { isOpen, onClose, handleSubmit, t } = props;

  const onSubmit = values => {
    console.log(values);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: {
          width: "500px",
          margin: "200px auto",
          outline: "none",
        },
      }}
      overlayClassName="modal-overlay"
      className="modal-content"
    >
      <div className="bg-white rounded-md p-8">
        <div
          className={
            "flex items-center flex-row justify-between mb-4"
          }
        >
          <span className="font-bold text-black text-xl">
            {t("clients.isams_integration")}
          </span>

          <FontAwesomeIcon
            onClick={onClose}
            icon={["fas", "times"]}
            className={
              "text-lg leading-none text-gray-750 cursor-pointer"
            }
          />
        </div>
        <div className="flex flex-col pb-4">
          <div className="text-sm text-gray-750 pb-1">URL</div>
          <Field name="url" component={TextInputField} />
        </div>

        <div className="flex flex-col pb-4">
          <div className="text-sm text-gray-750 pb-1">Api key</div>
          <Field name="api_key" component={TextInputField} />
        </div>

        <div className="flex justify-end pt-8 ">
          <button className="btn-secondary ml-4" onClick={onClose}>
            {t("clients.cancel")}
          </button>
          <button
            className="btn-primary ml-4"
            onClick={handleSubmit(onSubmit)}
          >
            {" "}
            {t("clients.activate")}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {};

export default withTranslation()(
  reduxForm({
    form: "isamsIntegrationForm",
    enableReinitialize: true,
  })(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(IsamsIntegrationModal),
  ),
);
