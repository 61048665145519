import Preview from "./preview";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
const informationSelector = formValueSelector("programInformationForm");
const testSelector = formValueSelector("programTestsForm");
const contentSelector = formValueSelector("programContentForm");
const coursesMajorsSelector = formValueSelector("programCoursesMajorsForm");

function mapStateToProps(state) {
  const {
    directApplyProgram: { documentTypes },
  } = state;

  return {
    programInformationValues: informationSelector(
      state,
      "program_type",
      "program_name",
      "program_website",
      "opening_date",
      "deadline_date",
      "matriculation_date",
      "round_name",
      "is_app_fee_req",
      "application_fee",
      "program_picture",
      "course_duration",
      "program_fee",
      "document_type"
    ),

    programTestValues: testSelector(
      state,
      "is_eng_test_req",
      "eng_test_desc",
      "is_gpa_required",
      "toefl",
      "toefl_min",
      "ielts",
      "duolingo",
      "gaokao",
      "other_english_test_names",
      "other_english_test_scores",
      "gpa_4",
      "gpa_100",
      "test_description"
    ),
    programContentValues: contentSelector(
      state,
      "testimonial",
      "description",
      "content",
      "program_type_description"
    ),
    programCoursesMajorsValues: coursesMajorsSelector(
      state,
      "courses_link",
      "courses_list",
      "course_link_available"
    ),
    documentTypes,
  };
}

export default connect(mapStateToProps)(Preview);
