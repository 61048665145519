import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  saveUcasApplicationFormFailure: null,
  saveUcasApplicationFormRequest: ["applicationId"],
  saveUcasApplicationFormSuccess: null,

  fillEducationSectionFailure: null,
  fillEducationSectionRequest: ["applicationId"],
  fillEducationSectionSuccess: null,

  getUcasApplicationFormFailure: null,
  getUcasApplicationFormRequest: ["applicationId"],
  getUcasApplicationFormSuccess: ["applicationData"],

  fillPersonalStatementFailure: null,
  fillPersonalStatementRequest: ["applicationId"],
  fillPersonalStatementSuccess: null,

  saveNotesFailure: null,
  saveNotesRequest: ["applicationId", "notes"],
  saveNotesSuccess: null,

  getAppReviewLogs: ["data"],

  requestIgnoreReviewData: ["params", "appId", "ignore"],
  requestIgnoreReviewDataSuccess: ["info"],
  requestIgnoreReviewDataFailure: ["error"],
});

export const UcasApplicationFormTypes = Types;
export const UcasApplicationFormCreators = Creators;
