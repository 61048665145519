import AddUserScreen from './add-user-screen';
import { connect } from 'react-redux';
import { TeamCreators } from '../../redux/actions';

function mapStateToProps(state) {
  return {
    isAdmin: state.user.authToken && state.user.data.is_admin
  };
}

const mapDispatchToProps = {
  createTeamMember: TeamCreators.createTeamMemberRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserScreen);
