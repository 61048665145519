import { call, put, takeLatest, select } from 'redux-saga/effects';
import { UniversitiesCreators, UniversitiesTypes } from '../actions';
import {
  getUniversities,
  getUniversiyRepresentatives
} from '../../web-services';
const authTokenSelector = (state) => state.user.authToken;

export function* watchUniversitiesRequests() {
  yield takeLatest(UniversitiesTypes.GET_UNIVERSITIES_REQUEST, requestGetUniversities);
  yield takeLatest(UniversitiesTypes.GET_UNIVERSITY_REPRESENTATIVES_REQUEST, requestGetUniversityRepresentatives);
}

export function* requestGetUniversities(action) {
  try {
    const { searchText } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getUniversities, authToken, searchText);

    yield put(UniversitiesCreators.getUniversitiesSuccess(response.data.schools));
  } catch (error) {
    yield put(UniversitiesCreators.getUniversitiesFailure());
  }
}
export function* requestGetUniversityRepresentatives(action) {
  try {
    const { universityId } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getUniversiyRepresentatives, authToken, universityId);

    yield put(UniversitiesCreators.getUniversityRepresentativesSuccess(response.data.representative));
  } catch (error) {
    yield put(UniversitiesCreators.getUniversityRepresentativesFailure());
  }
}
