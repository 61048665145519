import React, { Component } from "react";
import { Notification } from "../../helpers";
import AddUserForm from "./add-user-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "react-loader-spinner";
import { withTranslation } from "react-i18next";
import { TeamTypes } from "../../redux/actions";
import EventBus from "eventing-bus";

class AddUserScreen extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this._subscribeEvents();
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _subscribeEvents = () => {
    this.createRequestSubscription = EventBus.on(
      TeamTypes.CREATE_TEAM_MEMBER_REQUEST,
      () => this.setState({ loading: true })
    );
    this.createSuccessSubscription = EventBus.on(
      TeamTypes.CREATE_TEAM_MEMBER_SUCCESS,
      () =>
        this.setState({ loading: false }, this._teamMemberCreatedSuccessfully)
    );
    this.createFailureSubscription = EventBus.on(
      TeamTypes.CREATE_TEAM_MEMBER_FAILURE,
      () => this.setState({ loading: false })
    );
  };

  _unsubscribeEvents = () => {
    this.createRequestSubscription();
    this.createSuccessSubscription();
    this.createFailureSubscription();
  };

  _teamMemberCreatedSuccessfully = () => {
    const { t } = this.props;
    Notification.success(t("alerts.success.team_member_added"));
    this._closeScreen();
  };

  _closeScreen = () => {
    this.props.closeButtonPressed && this.props.closeButtonPressed();
  };

  _onSubmit = (values) => {
    const { first_name, last_name, email, role, is_active } = values;
    const { createTeamMember } = this.props;

    const params = new FormData();

    params.append("admin[first_name]", first_name);
    params.append("admin[last_name]", last_name);
    params.append("admin[email]", email);
    params.append("admin[is_active]", is_active === "1");
    params.append("admin[role_id]", role);

    return new Promise(function (resolve, reject) {
      createTeamMember(params, resolve, reject);
    });
  };

  render() {
    const { t, isAdmin } = this.props;
    const { loading } = this.state;

    return (
      <div className="h-screen flex flex-col side-screen bg-white border border-gray-300 overflow-hidden">
        <header className="h-18 flex items-center p-4 border-b border-gray-300">
          <div className="flex-1 flex flex-row items-center">
            <span className="ml-1 text-lg font-bold text-gray-750">
              {t("add_team_member.labels.add_team_member")}
            </span>
          </div>
          <div
            onClick={this._closeScreen}
            className="flex-none flex flex-col justify-center cursor-pointer"
          >
            <FontAwesomeIcon
              icon={["fas", "times"]}
              className="text-xl text-gray-750"
            />
          </div>
        </header>

        <AddUserForm
          showStatusAndRole={isAdmin}
          submitButtonText={t("common.buttons.send_registration")}
          onSubmit={this._onSubmit}
        />

        {loading ? (
          <div className="flex justify-center items-center z-20 absolute inset-0 bg-gray-200">
            <Loader type="Oval" color="grey" height={50} width={50} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(AddUserScreen);
