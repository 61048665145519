import React, { Component } from 'react';
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class TextInput extends Component {
  render() {
    const {
      error,
      ...rest
    } = this.props;

    return (
      <div className="flex flex-col flex-1">
        <div className={`flex flex-row input-field ${error? 'input-field-error' : ''} focus:outline-none focus:border-blue-700`}>
          <input
            {...rest}
            className={`flex-1 focus:outline-none`}
          />
          <div className={"flex-none flex justify-center items-center w-6"}>
            <FontAwesomeIcon icon={['fas', 'search']} className="text-lg text-dark-blue-300 leading-none"/>
          </div>
        </div>
        {
          error ? (
            <span className="input-field-error-text">
              {error}
            </span>
          ) : null
        }
      </div>
    );
  }
}

TextInput.defaultProps = {

}

TextInput.propTypes = {
  error: PropTypes.string
}

export default TextInput;
