import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FeaturesForm from "./features-form";
import { Notification } from "../../../helpers";
import { PartnerFeaturesTypes } from "../../../redux/actions";
import EventBus from "eventing-bus";
import Loader from "react-loader-spinner";

class FeaturesScreen extends Component {
  state = {
    loading: false,
    partner: {},
    confirmationModalVisible: false,
  };

  componentDidMount() {
    this._load();
    this._subscribeEvents();
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _load = () => {
    this.props.getFeatures(this.props.partnerId);
  };

  _subscribeEvents = () => {
    this.submitSuccessSubscription = EventBus.on(
      PartnerFeaturesTypes.UPDATE_PARTNER_FEATURES_SUCCESS,
      this._success
    );
    this.submitFailureSubscription = EventBus.on(
      PartnerFeaturesTypes.UPDATE_PARTNER_FEATURES_FAILURE,
      this._failure
    );
  };

  _unsubscribeEvents = () => {
    this.submitSuccessSubscription();
    this.submitFailureSubscription();
  };

  _success = () => {
    Notification.success(
      `Products of ${this.props.partnerName} saved successfully`
    );
    this._closeScreen();
  };

  _failure = () => {
    Notification.error("Something Went Wrong");
  };

  _closeScreen = () => {
    this.props.closeButtonPressed && this.props.closeButtonPressed();
  };

  _backButtonPressed = () => {
    this.props.onPressBackButton && this.props.onPressBackButton();
  };

  _onSubmit = (partner, partnerId) => {
    const { updateFeatures } = this.props;

    updateFeatures(partner, partnerId);
  };

  _getUniqueFeaturesKey = (id, features) =>
    `${id}${Object.keys(features).map((key) => features[key])}`; //hack to re-render nested changes in partner/features

  render() {
    const {
      loading,
      partner,
      users,
      ed_users,
      id,
      partnerName,
      partnerCountry,
      is_admin,
      email,
    } = this.props;

    return (
      <div
        key={this._getUniqueFeaturesKey(id, partner)}
        className="h-screen flex flex-col side-screen bg-white border border-gray-300 overflow-hidden"
      >
        <header className="h-18 flex flex-none p-4 border-b border-gray-300 items-center">
          <div className="flex-1 flex flex-col">
            <span className="ml-4 text-lg font-bold text-gray-750">{`${
              !this.state.higherEdOpen
                ? "Products of"
                : "Higher Ed Insights for"
            } ${partnerName}`}</span>
          </div>
          <div
            onClick={this._closeScreen}
            className="flex flex-col justify-center cursor-pointer"
          >
            <FontAwesomeIcon
              icon={["fas", "times"]}
              className="text-xl text-gray-750 mt-2px"
            />
          </div>
        </header>

        <FeaturesForm
          onSubmit={this._onSubmit}
          partner={partner}
          partnerName={partnerName}
          partnerCountry={partnerCountry}
          users={users}
          ed_users={ed_users}
          id={id}
          is_admin={is_admin}
          email={email}
        />

        {loading ? (
          <div className="flex justify-center items-center z-20 absolute inset-0 bg-gray-200">
            <Loader type="Oval" color="grey" height={50} width={50} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(FeaturesScreen);
