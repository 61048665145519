import ResetPasswordScreen from './forgot-password-screen';
import { UserCreators } from '../../redux/actions';
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  sendResetPasswordMail: (params) => UserCreators.sendResetPasswordMailRequest(params)
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordScreen);
