import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Format } from "../../../../helpers";
import { map, filter, isNaN, each, take } from "lodash";
import Courses from "./courses";
import FlowChart from "./flow-chart";
import ReactTooltip from "react-tooltip";

const DisplayField = ({ icon, label, value }) => {
  return (
    <div
      className={
        "flex flex-row items-center preview-display-field min-h-50px py-2"
      }
    >
      <FontAwesomeIcon
        style={{ width: 42 }}
        icon={icon}
        className="text-gray-600 text-lg"
      />
      <div
        style={{ width: 150 }}
        className={"text-sm text-black mr-2 flex-none "}
      >
        {label}
      </div>
      <div className={"text-sm text-gray-750 flex-1"}>{value}</div>
    </div>
  );
};

const ContentCard = ({ heading, detail, icon }) => (
  <div
    style={{ maxHeight: 244 }}
    className={
      "flex-none flex flex-row rounded-lg shadow-card p-4 h-64 overflow-hidden"
    }
  >
    {icon ? (
      <div className={"flex-none w-16 pt-4"}>
        <img src={icon} alt={heading} className="w-16 h-16 object-contain" />
      </div>
    ) : null}

    <div className={"flex-1 flex flex-col ml-3 overflow-hidden"}>
      <h2 className={"flex-none text-lg text-gray-750 font-bold mb-2"}>
        {heading}
      </h2>
      <p
        className={
          "flex-none text-sm text-dark-blue-300 font-regular overflow-hidden whitespace-pre-wrap break-all-words"
        }
      >
        {detail}
      </p>
    </div>
  </div>
);

const Preview = (props) => {
  const {
    programInformationValues: {
      program_name,
      program_type,
      opening_date,
      deadline_date,
      matriculation_date,
      round_name,
      is_app_fee_req,
      application_fee,
      program_picture,
      course_duration,
      program_fee,
      document_type,
    },
    programTestValues: {
      is_eng_test_req,
      eng_test_desc,
      toefl,
      ielts,
      duolingo,
      gaokao,
      other_english_test_names,
      other_english_test_scores,
      is_gpa_required,
      gpa_4,
      gpa_100,
      test_description,
    },
    programContentValues: {
      testimonial,
      description,
      content,
      program_type_description,
    },
    programCoursesMajorsValues: {
      courses_link,
      courses_list,
      course_link_available,
    },
    documentTypes,
    location,
  } = props;
  const [isCoursesViewVisible, setCoursesViewVisibility] = useState(false);
  const [programTypeDescriptionVisible, setProgramTypeDescriptionVisibility] =
    useState(false);
  const requiredDocuments = [];
  const ref = useRef(null);
  const EMPTY_FIELD_TEXT = "-";
  const [GPADescriptionVisible, setGPADescriptionVisible] = useState(false);
  const [programDescriptionVisible, setProgramDescriptionVisibility] =
    useState(false);
  const [testDescriptionCheck, setTestDescriptionCheck] = useState(0);
  const [descriptionCheck, setDescriptionCheck] = useState(0);
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (ref && ref.current)
      setTestDescriptionCheck(
        ref.current.clientHeight < ref.current.scrollHeight
      );

    if (descriptionRef && descriptionRef.current)
      setDescriptionCheck(
        descriptionRef.current.clientHeight <
          descriptionRef.current.scrollHeight
      );
  });

  const _programFeeValueFormatter = (program_fee) => {
    if (program_fee && program_fee.currency) {
      const { min, max } = program_fee;
      return `${program_fee.currency.label} ${_valueFormatterHelper(
        parseInt(min),
        parseInt(max),
        EMPTY_FIELD_TEXT
      )}`;
    }

    return EMPTY_FIELD_TEXT;
  };

  const _requiredDocValueFormatter = (doc) => {
    if (doc.min || doc.max) {
      return `${_valueFormatterHelper(
        parseInt(doc.min),
        parseInt(doc.max),
        ""
      )} ${doc.name}`;
    } else {
      return doc.name;
    }
  };

  const _valueFormatterHelper = (min, max, emptyText) => {
    if (!isNaN(min) && !isNaN(max)) {
      if (min === max) {
        return min;
      } else {
        return `${min} ~ ${max}`;
      }
    } else if (min) {
      return min;
    } else if (max) {
      return max;
    }

    return emptyText;
  };

  useEffect(() => {
    switch (location.hash) {
      case "#information":
        document.getElementById("information-preview").scrollIntoView();
        break;
      case "#tests":
        document.getElementById("tests-preview").scrollIntoView();
        break;
      case "#content":
        document.getElementById("content-preview").scrollIntoView();
        break;
      case "#courses":
        document.getElementById("courses-preview").scrollIntoView();
        break;
      default:
    }
  }, [location.hash]);

  each(documentTypes, (documentCategoryObject) => {
    each(documentCategoryObject, (docTypes, documentCategory) => {
      docTypes.forEach((docType) => {
        if (
          document_type &&
          document_type[docType.id]?.selection === "required"
        ) {
          if (docType.id === 8) {
            requiredDocuments.push({
              ...docType,
              min: document_type[docType.id].min,
              max: document_type[docType.id].max,
            });
          } else requiredDocuments.push(docType);
        }
      });
    });
  });

  const courses = courses_list.split(/\n/).filter((i) => !!i);

  if (isCoursesViewVisible) {
    return (
      <Courses
        courses={courses}
        onBackClick={() => setCoursesViewVisibility(false)}
        onCloseClick={() => setCoursesViewVisibility(false)}
      />
    );
  }

  return (
    <div className={`preview-container`}>
      <div className={`overflow-scroll`}>
        <div className={"flex flex-col justify-center h-100px px-5"}>
          <div className="preview-expand-button">
            <FontAwesomeIcon
              icon={["fas", "arrow-left"]}
              className="text-base text-gray-750"
            />
          </div>
          <h1 className={"text-black text-2xl"}>Preview of your program</h1>
          <h3 className={"text-black text-sm mt-2"}>
            As you add and customise information, you can see the preview of how
            it will look like in your univsersity's program profile
          </h3>
        </div>
        <div
          id="information-preview"
          style={{ height: 202 }}
          className={
            "flex1 flex justify-center items-center h-100px bg-gray-300 overflow-hidden"
          }
        >
          {program_picture ? (
            <img alt={"Program"} className={"flex-1"} src={program_picture} />
          ) : (
            <FontAwesomeIcon
              icon={["fas", "images"]}
              className="text-5xl text-gray-750 leading-none"
            />
          )}
        </div>

        <div className={"h-full flex flex-col px-5 py-2"}>
          <div className={"flex flex-row justify-between mb-2"}>
            <div className={"flex flex-col"}>
              <p className={"text-lg text-gray-750 font-bold"}>
                Program: {program_name}
              </p>
              <p className={"text-sm text-gray-750 font-regular"}>
                {typeof program_type === "string"
                  ? program_type
                  : program_type?.label}
              </p>
            </div>

            <div className={"flex-none flex flex-row"}>
              <button
                data-tip
                data-for={"website-button"}
                onClick="javascript:void(0)"
                className={"btn-secondary"}
              >
                {"Website"}
              </button>
              <ReactTooltip
                id={"website-button"}
                aria-haspopup="true"
                place="top"
                effect="solid"
              >
                <div className="flex flex-col my-2 max-w-17">
                  This button will be clickable for users and clicking it will
                  open your website in new tab.
                </div>
              </ReactTooltip>
              <div className={"w-3"} />
              <button
                data-tip
                data-for={"brochure-button"}
                onClick="javascript:void(0)"
                className={"btn-border-primary"}
              >
                <FontAwesomeIcon icon={["fas", "download"]} className="mr-1" />
                {"Brochure"}
              </button>
              <ReactTooltip
                id={"brochure-button"}
                aria-haspopup="true"
                place="top"
                effect="solid"
              >
                <div className="flex flex-col my-2 max-w-17">
                  This button will be clickable for users and clicking it will
                  download your brochure.
                </div>
              </ReactTooltip>
              <div className={"w-3"} />
              <button
                className={"btn-primary"}
                data-tip
                data-for={"application-button"}
                onClick="javascript:void(0)"
              >
                {"Add Application"}
              </button>
              <ReactTooltip
                id={"application-button"}
                aria-haspopup="true"
                place="left"
                effect="solid"
              >
                <div className="flex flex-col my-2 max-w-64">
                  This button will be clickable for the users and clicking it
                  will add this program in their list.
                </div>
              </ReactTooltip>
            </div>
          </div>

          <h2 className={"text-lg text-gray-750 font-bold mb-2"}>
            Key Information
          </h2>
          <div className={"flex flex-col rounded-lg shadow-card p-3"}>
            <h2 className={"text-base text-gray-750 font-bold mb-2"}>
              Details
            </h2>

            {/*Key Info*/}
            <div className={"flex-1 flex flex-row"}>
              <div className={"flex-1"}>
                <DisplayField
                  icon={["fas", "hourglass-end"]}
                  label={"Opening Date"}
                  value={
                    opening_date ? Format.date(opening_date) : EMPTY_FIELD_TEXT
                  }
                />
                <DisplayField
                  icon={["fas", "hourglass-end"]}
                  label={"Application Deadline"}
                  value={
                    deadline_date
                      ? Format.date(deadline_date)
                      : EMPTY_FIELD_TEXT
                  }
                />
                <DisplayField
                  icon={["fas", "user-clock"]}
                  label={"Start Date"}
                  value={
                    matriculation_date
                      ? Format.date(matriculation_date)
                      : EMPTY_FIELD_TEXT
                  }
                />
                <DisplayField
                  icon={["fas", "calendar-week"]}
                  label={"Intake"}
                  value={Format.displayValue(round_name?.label)}
                />
              </div>

              <div className={"w-5"} />

              <div className={"flex-1"}>
                <DisplayField
                  icon={["fas", "dollar-sign"]}
                  label={"Application Fees"}
                  value={
                    is_app_fee_req &&
                    application_fee &&
                    application_fee.currency &&
                    application_fee.cost
                      ? application_fee.currency.label +
                        " " +
                        application_fee.cost
                      : EMPTY_FIELD_TEXT
                  }
                />
                <DisplayField
                  icon={["fas", "coins"]}
                  label={"Annual Tuition Fees"}
                  // value={"USD 53 ~ 65000"}
                  value={_programFeeValueFormatter(program_fee)}
                />
                <DisplayField
                  icon={["fas", "calendar-alt"]}
                  label={"Course Duration"}
                  value={
                    course_duration
                      ? course_duration + " months"
                      : EMPTY_FIELD_TEXT
                  }
                />
              </div>
            </div>
          </div>

          {/*Application Requirements*/}
          <div
            id="tests-preview"
            className={"flex flex-col rounded-lg shadow-card p-3 mt-5"}
          >
            <h2 className={"text-base text-gray-750 font-bold mb-2"}>
              Application Requirements
            </h2>

            <div className={"flex-1 flex flex-col"}>
              <DisplayField
                icon={["fas", "language"]}
                label={"English Language Requirements"}
                value={
                  is_eng_test_req ? (
                    <div className={"flex flex-col"}>
                      {eng_test_desc ? (
                        <div className={"mb-2"}>{eng_test_desc}</div>
                      ) : null}
                      {toefl ? (
                        <div className={"mb-2"}>TOEFL: {toefl}</div>
                      ) : null}
                      {ielts ? (
                        <div className={"mb-2"}>IELTS: {ielts}</div>
                      ) : null}
                      {duolingo ? (
                        <div className={"mb-2"}>Duolingo: {duolingo}</div>
                      ) : null}
                      {gaokao ? (
                        <div className={"mb-2"}>Gaokao Test: {gaokao}</div>
                      ) : null}
                      {other_english_test_scores ? (
                        <div className={""}>
                          {other_english_test_names}:{" "}
                          {other_english_test_scores}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    "Not required"
                  )
                }
              />
              <DisplayField
                icon={["fas", "pen-alt"]}
                label={"GPA Requirements"}
                value={
                  is_gpa_required ? (
                    <div className={"flex flex-col"}>
                      {gpa_4 ? (
                        <div className="mb-2">GPA 4.0: {gpa_4}</div>
                      ) : null}
                      {gpa_100 ? (
                        <div className="mb-2">GPA 100: {gpa_100}</div>
                      ) : null}
                      {test_description ? (
                        <p
                          ref={ref}
                          dangerouslySetInnerHTML={{
                            __html: Format.displayValue(test_description),
                          }}
                          className={`mb-1  font-regular gpa_description rich-text ${
                            GPADescriptionVisible ? "" : "line-clamp-4"
                          }`}
                        ></p>
                      ) : null}
                      {ref &&
                        ref.current &&
                        (testDescriptionCheck || GPADescriptionVisible) && (
                          <span
                            onClick={() => {
                              setGPADescriptionVisible(!GPADescriptionVisible);
                            }}
                            className="flex flex-row cursor-pointer mb-2"
                          >
                            <FontAwesomeIcon
                              icon={[
                                "fas",
                                GPADescriptionVisible
                                  ? "chevron-up"
                                  : "chevron-down",
                              ]}
                              className="text-sm text-blue-700 font-bold leading-none mr-1"
                            />
                            <span className="text-sm text-blue-700 leading-none">
                              {GPADescriptionVisible ? "See less" : "See more"}
                            </span>
                          </span>
                        )}
                    </div>
                  ) : (
                    "Not required"
                  )
                }
              />
              <DisplayField
                icon={["fas", "passport"]}
                label={"Required Documents"}
                value={
                  requiredDocuments.length > 0
                    ? requiredDocuments
                        .map(_requiredDocValueFormatter)
                        .join(", ")
                    : "Not yet"
                }
              />
            </div>
          </div>

          <div className={"mt-5"}>
            <h2 className={"text-lg text-gray-750 font-bold mb-2"}>
              What is a {program_type?.label} Program?
            </h2>
            <p
              ref={descriptionRef}
              dangerouslySetInnerHTML={{
                __html: Format.displayValue(description),
              }}
              className={`text-sm text-gray-800 font-regular rich-text whitespace-pre-line ${
                !programDescriptionVisible ? "line-clamp-6" : ""
              }`}
            ></p>
            {descriptionRef &&
              descriptionRef.current &&
              (descriptionCheck || programDescriptionVisible) && (
                <div
                  onClick={() => {
                    setProgramDescriptionVisibility(!programDescriptionVisible);
                  }}
                  className="flex flex-row cursor-pointer mt-3 mb-2"
                >
                  <FontAwesomeIcon
                    icon={[
                      "fas",
                      programDescriptionVisible ? "chevron-up" : "chevron-down",
                    ]}
                    className="text-sm text-blue-700 font-bold leading-none mr-1"
                  />
                  <span className="text-sm text-blue-700 leading-none">
                    {programDescriptionVisible ? "See less" : "See more"}
                  </span>
                </div>
              )}

            <div className={"h-5"} />

            <FlowChart programTypeId={program_type?.value} />

            {/*Program Intro*/}
            <div id="content-preview" />
            {program_type_description ? (
              <>
                <div
                  onClick={() => {
                    setProgramTypeDescriptionVisibility(
                      !programTypeDescriptionVisible
                    );
                  }}
                  className="flex flex-row cursor-pointer mt-3 mb-2"
                >
                  <FontAwesomeIcon
                    icon={[
                      "fas",
                      programTypeDescriptionVisible
                        ? "chevron-up"
                        : "chevron-down",
                    ]}
                    className="text-sm text-blue-700 font-bold leading-none mr-1"
                  />
                  <span className="text-sm text-blue-700 leading-none">
                    {programTypeDescriptionVisible ? "See less" : "See more"}
                  </span>
                </div>

                {programTypeDescriptionVisible ? (
                  <p className={"text-sm text-gray-800 font-regular"}>
                    {program_type_description}
                  </p>
                ) : null}
              </>
            ) : null}

            {/*Program content / info cards*/}
            {filter(
              content,
              (ci) => ci.enabled === "true" || ci.enabled === true
            ).length > 0 ? (
              <>
                <div className={"h-5"} />
                <h2 className={"text-lg text-gray-750 font-bold mb-4"}>
                  Why do I need a {program_type?.label} Program?
                </h2>
                <div className={"flex flex-row flex-wrap"}>
                  {map(
                    filter(
                      content,
                      (ci) => ci.enabled === "true" || ci.enabled === true
                    ),
                    (contentItem, index) => (
                      <div
                        style={{ flex: "0 50%" }}
                        className={"pr-2 mb-3"}
                        key={index}
                      >
                        <ContentCard
                          heading={contentItem.title}
                          detail={Format.displayValue(contentItem.text)}
                          icon={contentItem.icon}
                        />
                      </div>
                    )
                  )}
                </div>
              </>
            ) : null}

            {/*Courses Offered*/}
            <div id="courses-preview" className="mt-5 flex flex-col">
              <h2 className={"text-lg text-gray-750 font-bold mb-3"}>
                Courses Offered
              </h2>
              {course_link_available && courses_link ? (
                <span className="text-sm text-gray-800 leading-none mb-2">
                  All courses are available. Link to view all courses{" "}
                  <a
                    href={courses_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-700 cursor-pointer"
                  >
                    here.
                  </a>
                </span>
              ) : null}
              {!course_link_available ? (
                <span className="text-sm text-gray-800 leading-none mb-2">
                  Only these courses are available.
                </span>
              ) : null}
              <span className="text-xs text-black leading-none mb-2">
                *If you are sure about your major, check with the university rep
                to confirm your eligibility.
              </span>
              {!course_link_available ? (
                <div className="flex flex-row flex-wrap mt-3">
                  {courses_list ? (
                    courses_list.split(/\n/).filter((i) => !!i).length > 9 ? (
                      <>
                        {take(
                          courses_list.split(/\n/).filter((i) => !!i),
                          9
                        ).map((courseName) => (
                          <div
                            style={{ flex: "0 50%" }}
                            className={
                              "text-sm font-bold leading-none mb-4 pr-2"
                            }
                          >
                            {courseName}
                          </div>
                        ))}
                        <div
                          style={{ flex: "0 50%" }}
                          onClick={() => setCoursesViewVisibility(true)}
                          className={
                            "text-sm font-bold text-blue-700 leading-none mb-4 pr-2 cursor-pointer"
                          }
                        >
                          See{" "}
                          {courses_list.split(/\n/).filter((i) => !!i).length -
                            9}{" "}
                          more courses here
                        </div>
                      </>
                    ) : (
                      courses_list
                        .split(/\n/)
                        .filter((i) => !!i)
                        .map((courseName) => (
                          <div
                            style={{ flex: "0 50%" }}
                            className={
                              "text-sm font-bold leading-none mb-4 pr-2"
                            }
                          >
                            {courseName}
                          </div>
                        ))
                    )
                  ) : null}
                </div>
              ) : null}
            </div>

            <div id="testimonial-preview" />
            {testimonial ? (
              <>
                <div className={"h-5"} />

                <h2 className={"text-lg text-gray-750 font-bold mb-2"}>
                  Testimonials
                </h2>
                <div className={"flex flex-row mt-2 relative mb-12"}>
                  <div className="w-64 h-64 flex flex-none z-10 rounded-lg bg-gray-500">
                    {testimonial && testimonial.image ? (
                      <img
                        className="object-cover flex-1 rounded-lg"
                        src={
                          typeof testimonial.image === "string"
                            ? testimonial.image
                            : URL.createObjectURL(testimonial.image)
                        }
                        alt={"Testimonial"}
                      />
                    ) : testimonial.name ? (
                      <div
                        className={"flex-1 flex justify-center items-center"}
                      >
                        <span className="font-black text-dark-blue-800 text-7xl">
                          {take(testimonial.name.split(" "), 2).map(
                            (n) => n[0]
                          )}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div
                    style={{ left: 28, top: 28, bottom: -28 }}
                    className={
                      "absolute right-0 bottom-0 flex flex-row bg-blue-50 rounded-lg"
                    }
                  >
                    <div className="flex-none w-56" />

                    <div className={"flex flex-col"}>
                      <div className={"flex flex-row ml-4 mt-6"}>
                        <div className={"relative w-8 flex-none"}>
                          <span
                            style={{ top: -10 }}
                            className={
                              "text-6.5xl text-blue-250 leading-none absolute"
                            }
                          >
                            {"“"}
                          </span>
                        </div>
                        <p
                          className={
                            "text-sm text-gray-800 font-regular mx-3 whitespace-pre-wrap"
                          }
                        >
                          {Format.displayValue(testimonial.text)}
                        </p>
                        <div className={"relative w-8 flex-none"}>
                          <span
                            style={{ bottom: -50 }}
                            className={
                              "text-6.5xl text-blue-250 leading-none absolute"
                            }
                          >
                            {"”"}
                          </span>
                        </div>
                      </div>

                      <div className={"flex flex-row ml-4 mt-6"}>
                        <div className={"relative w-8 flex-none"} />
                        <div className={"flex flex-col"}>
                          <p
                            className={
                              "text-lg text-gray-750 font-bold leading-none mx-3"
                            }
                          >
                            {Format.displayValue(testimonial.name)}
                          </p>
                          <p
                            className={
                              "text-lg text-gray-750 font-regular leading-none mx-3 mt-2"
                            }
                          >
                            {Format.displayValue(testimonial.country)}
                          </p>
                        </div>
                        <div className={"relative w-8 flex-none"} />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <div className={"h-5"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
