import React, { Component } from "react";
import { Button, Wrapper, Menu, openMenu } from "react-aria-menubutton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Format } from "../../helpers";
import { isEqual } from "lodash";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

class DateRangeFilter extends Component {
  constructor(props) {
    super(props);

    const { selectedRange } = props;

    this.state = {
      ranges: [
        {
          startDate: selectedRange
            ? new Date(selectedRange.startDate)
            : new Date(),
          endDate: selectedRange ? new Date(selectedRange.endDate) : new Date(),
          key: "date_range",
        },
      ],
    };
  }

  componentDidMount() {
    const { openOnRender, id, selectedRange } = this.props;

    if (openOnRender && id && !selectedRange) openMenu(id);

    if (!selectedRange) {
      this.props.onSelection({
        startDate: new Date(),
        endDate: new Date(),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.selectedRange, prevProps.selectedRange)) {
      this.setState({
        ranges: [{ ...this.props.selectedRange, key: "date_range" }],
      });
    }
  }

  _handleFilterToggle = ({ isOpen }) => {};

  _handleSelect = (ranges, secondParam) => {
    this.props.onSelection({
      startDate: ranges.date_range.startDate,
      endDate: ranges.date_range.endDate,
    });
  };

  _handleDeselect = () => {
    this.props.onSelection(null);
  };

  render() {
    const { button, id } = this.props;
    const { ranges } = this.state;

    return (
      <div className="flex-none flex flex-col">
        <Wrapper
          id={id}
          className="relative flex-1 flex flex-row"
          onSelection={this._handleSelection}
          onMenuToggle={this._handleFilterToggle}
        >
          <Button className="flex flex-row items-center focus:outline-none leading-none pt-1">
            {button}
          </Button>
          <div
            className={
              "h-42px flex flex-row bg-blue-200 rounded px-2 items-center ml-2"
            }
          >
            <FontAwesomeIcon
              icon={["far", "calendar-alt"]}
              className="text-lg text-blue-700 leading-none mr-1"
            />
            {ranges.length > 0 ? (
              <span className="text-sm text-black w-24 text-center">
                {Format.date(ranges[0].startDate)}
              </span>
            ) : (
              <span className="text-sm text-gray-500 w-24 text-center">
                {"Start Date"}
              </span>
            )}
            <FontAwesomeIcon
              icon={["fa", "arrow-right"]}
              className="text-lg text-gray-750 leading-none mx-1"
            />
            {ranges.length > 0 ? (
              <span className="text-sm text-black w-24 text-center">
                {Format.date(ranges[0].endDate)}
              </span>
            ) : (
              <span className="text-sm text-gray-500 w-24 text-center">
                {"End Date"}
              </span>
            )}
            <FontAwesomeIcon
              onClick={this._handleDeselect}
              icon={["fas", "times"]}
              className="text-sm text-dark-blue-800 ml-2 cursor-pointer"
            />
          </div>

          <Menu className="date-range-filter-container py-2">
            <div className="flex flex-col">
              <DateRangePicker
                months={2}
                className={"date-range-picker"}
                onChange={this._handleSelect}
                showMonthArrow={false}
                showDateDisplay={false}
                ranges={this.state.ranges}
                direction="horizontal"
              />
            </div>
          </Menu>
        </Wrapper>
      </div>
    );
  }
}

DateRangeFilter.defaultProps = {
  selectedFilters: [],
};

export default DateRangeFilter;
