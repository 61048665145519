import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import CompletedCTA from "../completed-cta";
import MarkAsCompleteCTA from "../mark-as-complete-cta";
import { APPLICATION_SECTION } from "src/constants/applications.constants";

const CompleteAutomation = ({
  t,
  application,
  ucasQuickSubmit: {
    application_form_complete,
    application_form_job_status,
    onboarding_show_create_button,
    onboarding_complete,
    jobs_running,
  },
  isUCASAccountCreated,
}) => {
  if (application_form_complete) {
    return (
      <CompletedCTA
        status={application_form_complete}
        type={APPLICATION_SECTION.APP_FORM}
      />
    );
  } else if (
    !application_form_job_status ||
    ["Pending", "Running"].includes(application_form_job_status)
  ) {
    return null;
  } else if (
    (onboarding_show_create_button && !onboarding_complete) ||
    ["Running", true].includes(application_form_job_status) ||
    jobs_running
  ) {
    return (
      <>
        <button
          data-tip
          data-for={`tooltip-Completed`}
          disabled={true}
          className={"btn-border-primary mb-6"}
        >
          {t(
            "direct_apply_apps.quick_submit.account_and_onboarding.mark_as_complete_cta",
          )}
        </button>
        {!application.is_ucas_credential_updated &&
          isUCASAccountCreated && (
            <ReactTooltip
              id={`tooltip-Completed`}
              aria-haspopup="true"
              place="bottom"
              effect={"solid"}
              arrowColor={"transparent"}
            >
              <p>
                {t("direct_apply_apps.quick_submit.ucas_credential")}
              </p>
            </ReactTooltip>
          )}
      </>
    );
  } else {
    return (
      <MarkAsCompleteCTA
        status={application_form_complete}
        type={APPLICATION_SECTION.APP_FORM}
      />
    );
  }
};

const mapStateToProps = state => ({
  ucasQuickSubmit: state.directApply.ucasQuickSubmit,
  application: state.directApply.selected,
});

export default withTranslation()(
  connect(mapStateToProps)(CompleteAutomation),
);
