import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import Loader from "react-loader-spinner";

import { StudentsCreators } from "../../../../../redux/actions";

const CreateAccountCTA = ({
  t,
  application,
  ucasQuickSubmit,
  createUcasAccount,
  isUcasAccountCreated,
  isUCASAccountAvailable,
}) => {
  const {
    onboarding_job_status,
    jobs_running,
    onboarding_complete,
    onboarding_show_create_button,
  } = ucasQuickSubmit;

  const isOnboardingStatus = onboarding_job_status;

  const onCreateUcasAccount = () => {
    if (!isUcasAccountCreated) {
      createUcasAccount(application?.user_id);
    }
  };

  if (!onboarding_complete && onboarding_show_create_button) {
    switch (true) {
      case !application.is_ucas_credential_updated &&
        isUCASAccountAvailable:
        return (
          <>
            <button
              data-tip
              data-for="Completes-right"
              disabled
              className="btn-border-primary mb-4"
            >
              {t(
                "direct_apply_apps.quick_submit.account_and_onboarding.create_account_cta",
              )}
              <FontAwesomeIcon
                icon={["far", "sparkles"]}
                className="pl-2"
              />
            </button>
            <ReactTooltip
              id="Completes-right"
              aria-haspopup="true"
              place="right"
              effect="solid"
              arrowColor="transparent"
            >
              <p>
                Student has indicated they want their own UCAS
                account. Click the checkbox "I have entered the
                students UCAS email and password against the student"
              </p>
            </ReactTooltip>
          </>
        );
      case isUcasAccountCreated ||
        isOnboardingStatus === "Running" ||
        isOnboardingStatus === "Pending":
        return (
          <>
            <div data-tip data-for="create_account_button">
              <button
                disabled
                className="button-contain-loading-primary-disable mb-4"
              >
                {t(
                  "direct_apply_apps.quick_submit.account_and_onboarding.create_account_cta",
                )}
                <Loader
                  className="ml-2 text-gray-500"
                  type="Oval"
                  height={10}
                  width={10}
                />
              </button>
            </div>
            <ReactTooltip
              id="create_account_button"
              aria-haspopup="true"
              place="bottom"
              effect="solid"
              arrowColor="transparent"
            >
              <p>Account Creating is in progress</p>
            </ReactTooltip>
          </>
        );
      case jobs_running:
        return (
          <button disabled className="btn-border-primary mb-4">
            {t(
              "direct_apply_apps.quick_submit.account_and_onboarding.create_account_cta",
            )}
            <FontAwesomeIcon
              icon={["far", "sparkles"]}
              className="pl-2"
            />
          </button>
        );
      case isOnboardingStatus === "Failed":
        return (
          <button
            onClick={onCreateUcasAccount}
            className="button-contain-error mb-4"
          >
            Try again
            <FontAwesomeIcon icon="undo" className="pl-2" />
          </button>
        );
      default:
        return (
          <button
            onClick={onCreateUcasAccount}
            className="button-contain-primary mb-4"
          >
            {t(
              "direct_apply_apps.quick_submit.account_and_onboarding.create_account_cta",
            )}
            <FontAwesomeIcon
              icon={["far", "sparkles"]}
              className="pl-2"
            />
          </button>
        );
    }
  } else {
    return null;
  }
};

function mapStateToProps(state) {
  return {
    ucasQuickSubmit: state.directApply.ucasQuickSubmit,
    application: state.directApply.selected,
    isUcasAccountCreated: state.students.loading,
  };
}

const mapDispatchToProps = {
  createUcasAccount: StudentsCreators.createUcasAccountRequest,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CreateAccountCTA),
);
