import { call, put, takeEvery } from "redux-saga/effects";
import { UserCreators, UserTypes } from "../actions";
import { Notification } from "../../helpers";
import {
  signIn,
  resetPassword,
  sendResetPasswordMail,
} from "../../web-services";
import { SubmissionError } from "redux-form";
import { each } from "lodash";

export function* watchUserRequests() {
  yield takeEvery(UserTypes.AUTHENTICATION_REQUEST, requestSignIn);
  yield takeEvery(UserTypes.RESET_PASSWORD_REQUEST, requestResetPassword);
  yield takeEvery(
    UserTypes.SEND_RESET_PASSWORD_MAIL_REQUEST,
    requestSendResetPasswordMail
  );
}

export function* requestSignIn(action) {
  try {
    const { params, resolve } = action;
    const response = yield call(signIn, params);
    resolve();
    yield put(UserCreators.authenticationSuccess(response.data));
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 404)
    ) {
      const { reject } = action;
      let errorMessages = {};

      each(error.response.data.error, (error, key) => {
        errorMessages[key] = error instanceof Array ? error : [error];
      });

      reject(new SubmissionError(errorMessages));
    }
    yield put(UserCreators.authenticationFailure());
  }
}

export function* requestResetPassword(action) {
  try {
    const { params } = action;
    yield call(resetPassword, params);
    Notification.success("Password has been reset successfully!");
    yield put(UserCreators.resetPasswordSuccess());
  } catch (error) {
    console.log(error);
    yield put(UserCreators.resetPasswordFailure());
  }
}

export function* requestSendResetPasswordMail(action) {
  try {
    const { params } = action;
    const response = yield call(sendResetPasswordMail, params);
    Notification.success(response.data);
    yield put(UserCreators.sendResetPasswordMailSuccess());
  } catch (error) {
    yield put(UserCreators.sendResetPasswordMailFailure());
  }
}
