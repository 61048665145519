import React, { useEffect, useRef } from "react";
import { Field } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Notification } from "../../../helpers";
import { map } from "lodash";
import {
  TextInputField,
  CountrySelectField,
  SelectField,
} from "../../../form-components";

const RenderInputField = props => {
  const {
    input,
    meta: { error, touched },
    ...rest
  } = props;
  const showError = touched && error;

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex flex-row border border-gray-500 rounded w-full overflow-hidden">
        <div className="flex-none flex w-16 bg-gray-50 border-r border-gray-500 justify-center items-center">
          <span className="text-xs text-gray-500 leading-none">
            {rest.placeholder}
          </span>
        </div>
        <div className="flex flex-col">
          <input
            {...input}
            {...rest}
            className={`input-field-with-label ${
              showError ? "input-field-error" : ""
            } focus:outline-none`}
          />
        </div>
      </div>
      {showError ? (
        <span className="input-field-error-text">{error}</span>
      ) : null}
    </div>
  );
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const AddPartner = props => {
  const { countries, partnerAdded, loading, history } = props;
  const countryOptions = map(countries, country => ({
    value: country.id,
    label: country.name,
  }));
  const instituteOptions = [
    {
      value: "university",
      label: "University",
    },
    {
      value: "school",
      label: "School",
    },
    {
      value: "application_client",
      label: "Application Client",
    },
  ];

  useEffect(() => {
    props.getCountries();
  }, []);

  const prevAmount = usePrevious({ partnerAdded, loading });

  useEffect(() => {
    if (
      prevAmount &&
      !prevAmount.partnerAdded &&
      partnerAdded &&
      !loading
    ) {
      Notification.success(`Partner added successfully`);
      props.reset();
    }
  }, [partnerAdded, loading]);

  const _onSubmit = values => {
    const {
      name,
      institute_type,
      email,
      country_id,
      phone,
      website_url,
      city,
      address,
      phone_code,
      size,
    } = values;
    const params = new FormData();

    params.append("name", name);
    params.append("institute_type", institute_type.value);
    params.append("country_id", country_id.value);
    params.append("website_url", website_url);
    params.append("country_id", country_id.value);
    params.append("city", city);
    params.append("address", address);

    let partner = {
      name: name,
      institute_type: institute_type.value,
      country_id: country_id.value,
    };

    if (phone_code) partner.phone_code = phone_code.value;

    if (phone) partner.phone = phone;

    if (email) partner.email = email;

    if (city) partner.city = city;

    if (address) partner.address = address;

    if (website_url) partner.website_url = website_url;

    if (size) partner.size = size;

    props.savePartner({ partner: partner });
  };

  return (
    <div className="flex flex-1 overflow-auto">
      <div
        className="flex flex-col p-4 w-1/2 "
        style={{ width: "100%" }}
      >
        <div className="flex flex-row items-center mb-4">
          <FontAwesomeIcon
            onClick={() => history.goBack()}
            icon={["fas", "chevron-left"]}
            className="text-base mr-4 cursor-pointer"
          />
          <div className="font-bold flex  text-22">New Applicant</div>
        </div>

        <div className="flex  pt-4 border border-l-0 border-r-0 border-b-1 border-t-1 border-gray-300">
          <div className="flex flex-col">
            <div className="flex flex-col ">
              <div className="flex flex-none flex-col">
                <span className="text-sm text-gray-900 font-bold mb-2 text-lg text-gray-750">
                  Partner information
                </span>

                <div className="form-row">
                  <div className="flex-1 flex pt-3">
                    <span className="text-sm text-gray-750 mb-2 leading-none">
                      Partner name
                      <span class="text-red-800 ml-1">*</span>
                    </span>
                  </div>
                  <div className="flex-1_6">
                    <Field
                      required
                      name="name"
                      component={TextInputField}
                      placeholder="Navitas"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="flex-1 flex pt-3">
                    <span className="text-sm text-gray-750 mb-2 leading-none">
                      Institute Type
                      <span class="text-red-800 ml-1">*</span>
                    </span>
                  </div>
                  <div className="flex-1_6">
                    <Field
                      label={"Institute Type"}
                      name="institute_type"
                      component={SelectField}
                      isSearchable={false}
                      placeholder={"Select institute type"}
                      options={instituteOptions}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="flex-1 flex pt-3">
                    <span className="text-sm text-gray-750 mb-2 leading-none">
                      Country
                      <span class="text-red-800 ml-1">*</span>
                    </span>
                  </div>
                  <div className="flex-1_6">
                    <Field
                      name="country_id"
                      component={SelectField}
                      label="Add Country"
                      options={countryOptions}
                      isClearable={false}
                      placeholder="Select Country"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="flex-1 flex mt-4"></div>
                  <div className="flex-1_6">
                    <div className="flex">
                      <Field
                        name="city"
                        component={RenderInputField}
                        placeholder={"City"}
                      />

                      <div className="w-5 flex-none" />

                      <Field
                        name="address"
                        component={RenderInputField}
                        placeholder={"Address"}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="flex-1 flex pt-3">
                    <span className="text-sm text-gray-750 mb-2 leading-none">
                      Website URL
                    </span>
                  </div>
                  <div className="flex-1_6">
                    <Field
                      label={"Website"}
                      name="website_url"
                      component={TextInputField}
                      placeholder={"https://www.google.com"}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="flex-1 flex  pt-3">
                    <span className="text-sm text-gray-750 mb-2 leading-none">
                      Size
                    </span>
                  </div>
                  <div className="flex-1_6">
                    <Field
                      label={"Size"}
                      name="size"
                      component={TextInputField}
                      type="number"
                      placeholder={"0"}
                      min="0"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="flex-1 flex  pt-3">
                    <span className="text-sm text-gray-750 mb-2 leading-none">
                      Work Phone
                    </span>
                  </div>
                  <div className="flex ">
                    <div className="flex flex-1 gap-4">
                      <div className="flex-1">
                        <Field
                          name="phone_code"
                          component={CountrySelectField}
                          placeholder={"+89"}
                        />
                      </div>

                      <div className="flex-auto">
                        <Field
                          name="phone"
                          component={TextInputField}
                          type="text"
                          placeholder={"1234 5678"}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-row mb-12">
                  <div className="flex-1 flex  pt-3">
                    <span className="text-sm text-gray-750 mb-2 leading-none">
                      Work Email
                    </span>
                  </div>
                  <div className="flex-1_6">
                    <Field
                      name="email"
                      component={TextInputField}
                      type="email"
                      placeholder={"test@cialfo.com.sg"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-end  p-5">
          <button
            onClick={props.handleSubmit(_onSubmit)}
            type={"submit"}
            className="btn-primary w-40 text-sm"
          >
            Add Partner
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddPartner;
