import DirectApplyTransactionDetail from './transaction-detail';
import { connect } from 'react-redux';
import { DirectApplyCreators } from '../../../redux/actions';

function mapStateToProps(state) {
  const {
    directApply: {
      transaction,
      isFetchingTransaction,
      transcationFetchSuccessful,
    } ,
  } = state;
  return {
    transaction,
    isFetchingTransaction,
    transcationFetchSuccessful,
  };
}

const mapDispatchToProps = {
  getTransaction: DirectApplyCreators.getTransactionRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(DirectApplyTransactionDetail);
