import Courses from "./courses";
import { connect } from "react-redux";

function mapStateToProps(state) {
  const {
    directApplyProgram: {
      program: {
        partner,
      }
    },
  } = state;

  return {
    partnerName: partner,
  }
}

export default connect(mapStateToProps)(Courses);
