import React, { useState } from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "antd";
import { withTranslation } from "react-i18next";

const UcasCredentialModal = props => {
  const {
    t,
    onClose,
    onClickIsUcasCredentialUpdated,
    visible,
    application,
  } = props;
  const ucasCredential = application.is_ucas_credential_updated;

  const [hasUcasCredentials, setHasUcasCredentials] =
    useState(ucasCredential);

  const handleCheckboxChange = e => {
    setHasUcasCredentials(e.target.checked);
  };

  return (
    <ReactModal
      isOpen={visible}
      className="modal"
      overlayClassName="modalOverlay"
    >
      <div className="absolute left-0 right-0 top-0 bottom-0 bg-overlay-500 flex justify-center items-center z-20">
        <div
          style={{ minHeight: 200 }}
          className="rounded-lg bg-white pt-6 pb-4 px-6 flex flex-col relative w-500px"
        >
          <div className="flex justify-between mb-5">
            <span className="font-bold text-2xl text-gray-750">
              {t(
                "direct_apply_apps.quick_submit.account_and_onboarding.ucas_credential_modal.title",
              )}
            </span>
            <div
              className="cursor-pointer mt-1"
              onClick={() => {
                onClose();
                setHasUcasCredentials(ucasCredential);
              }}
            >
              <FontAwesomeIcon
                icon="times"
                className="text-xl icon-large-outline text-gray-500"
              />
            </div>
          </div>
          <div className="flex">
            <div className="w-6 pt-3 flex">
              <Checkbox
                onChange={handleCheckboxChange}
                defaultChecked={ucasCredential}
                className="gray-900"
              />
            </div>
            <div className="flex-1 pl-1 flex">
              <span className="font-body font-normal text-base text-gray-750 leading-6 whitespace-pre-wrap text-left">
                {t(
                  "direct_apply_apps.quick_submit.account_and_onboarding.ucas_credential_modal.description",
                )}
              </span>
            </div>
          </div>
          <div className="flex-1" />
          <div className="flex justify-end mt-4">
            <button
              onClick={() => {
                onClose();
                onClickIsUcasCredentialUpdated(hasUcasCredentials);
              }}
              className={"btn-primary"}
            >
              {t(
                "direct_apply_apps.quick_submit.account_and_onboarding.ucas_credential_modal.cta",
              )}
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default withTranslation()(UcasCredentialModal);
