import React, { Component } from "react";
import { Field } from "redux-form";
import { ConfirmationModal } from "../../../../components";
import {
  TextInputField,
  TextAreaField,
} from "../../../../form-components";
import { ActionBar } from "../common";
import { Notification } from "../../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PROGRAM_STATUS } from "../../../../constants";

import validator from "validator";
import ReactTooltip from "react-tooltip";
import _ from "lodash";

const Tooltip = ({ title, text, tooltipId }) => {
  return (
    <span className="relative">
      <FontAwesomeIcon
        data-tip
        data-for={tooltipId}
        icon={["fas", "question-circle"]}
        className={"text-lg text-gray-750 leading-none"}
      />
      <ReactTooltip
        id={tooltipId}
        aria-haspopup="true"
        place="top"
        effect="solid"
      >
        <div className="flex flex-col my-2">
          {title ? (
            <span className={"text-lg mb-4"}>{title}</span>
          ) : null}
          <p className="text-sm">{text}</p>
        </div>
      </ReactTooltip>
    </span>
  );
};

class CoursesMajors extends Component {
  state = {
    isSaveAsDraftModalVisible: false,
  };

  isSavedAndValid = event => {
    return (
      this.props.valid &&
      (this.props.dirty ? this.props.submitSucceeded : true)
    );
  };

  _showValidationErros = errors => {
    if (errors && Object.keys(errors).length > 0) {
      const errorMessages = _.map(errors, error => error);
      const errorMessagesComponent = _.map(errors, (error, index) => (
        <div className="h-5" key={index}>
          {error}
        </div>
      ));

      if (errors) {
        Notification.error(
          <div className="flex flex-col">
            {errorMessagesComponent}
          </div>,
          {
            customFields: {
              title: `${errorMessages.length} error${errorMessages.length > 1 ? "s" : ""} in this section`,
            },
          },
        );
      }
    }
  };

  _onSubmit = event => {
    const errors = this.props.handleSubmit(this._save)(event);
    this._showValidationErros(errors);
  };

  _onSubmitDraft = event => {
    const errors = this.props.handleSubmit(
      this._saveAsDraftShowModal,
    )(event);
    this._showValidationErros(errors);
  };

  _save = values => {
    this._submit(values);
  };

  _saveAsDraftShowModal = values => {
    return new Promise((resolve, reject) => {
      this._showSaveAsDraftModal();
      reject();
    });
  };

  _confirmSaveAsDraft = event => {
    const errors = this.props.handleSubmit(values => {
      this._submit(values, true);
    })(event);
    this._showValidationErros(errors);
    this._hideSaveAsDraftModal();
  };

  _submit = (values, saveAsDraft) => {
    const { course_link_available, courses_link, courses_list } =
      values;
    const { id } = this.props;
    const formData = new FormData();

    formData.append(
      "partner_program[course_link_available]",
      course_link_available,
    );
    formData.append(
      "partner_program[courses_link]",
      courses_link || "",
    );
    formData.append(
      "partner_program[courses_list]",
      courses_list || "",
    );

    if (saveAsDraft) {
      formData.append(
        "partner_program[status]",
        PROGRAM_STATUS.DRAFT,
      );
      this.props.saveProgramDraft(id, formData);
    } else this.props.updateProgram(id, formData);
  };

  _showSaveAsDraftModal = () => {
    this.setState({ isSaveAsDraftModalVisible: true });
  };

  _hideSaveAsDraftModal = () => {
    this.setState({ isSaveAsDraftModalVisible: false });
  };

  render() {
    const {
      course_link_available,
      isUpdating,
      isSavingDraft,
      status,
      t,
      coursesChanges,
    } = this.props;
    const { isSaveAsDraftModalVisible } = this.state;

    return (
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex flex-1 flex-col overflow-scroll pr-4">
          <div className="flex flex-col pointer-events-none">
            <span className="text-gray-750 text-lg font-bold mb-6">
              Add Courses/Majors
            </span>

            <div className="flex flex-col">
              <div
                onClick={() => {
                  this.setState({ selectedIndex: 0 });
                  this.props.change("course_link_available", true);
                }}
                className="flex flex-row cursor-pointer text-gray-750 text-sm"
              >
                <div className={"w-6"}>
                  <FontAwesomeIcon
                    icon={
                      course_link_available
                        ? ["fas", "check-circle"]
                        : ["far", "circle"]
                    }
                    className={
                      "text-sm " +
                      (course_link_available ? "text-blue-700" : "")
                    }
                  />
                </div>
                <span
                  className={
                    "mr-2 " +
                    (coursesChanges.course_link_available &&
                      coursesChanges.course_link_available[1] &&
                      "text-yellow-800")
                  }
                >
                  {"All courses and majors are available"}
                </span>
                <Tooltip
                  tooltipId={"courses_link"}
                  text={
                    "If the program is applicable to all courses on your school's website"
                  }
                />
              </div>
              <div className={"flex flex-col pl-6 mt-2"}>
                <span className="text-gray-750 text-sm mb-1">
                  {"Link to list of courses"}
                </span>
                <Field
                  disabled={!course_link_available}
                  name="courses_link"
                  component={TextInputField}
                  classForBorder={
                    coursesChanges.courses_link && "border-yellow-800"
                  }
                  placeholder="https://www.program.com"
                />
              </div>
            </div>

            <div className="h-6" />

            <div className="flex flex-col">
              <div
                onClick={() => {
                  this.setState({ selectedIndex: 1 });
                  this.props.change("course_link_available", false);
                }}
                className="flex flex-row cursor-pointer text-gray-750 text-sm"
              >
                <div className={"w-6"}>
                  <FontAwesomeIcon
                    icon={
                      !course_link_available
                        ? ["fas", "check-circle"]
                        : ["far", "circle"]
                    }
                    className={
                      "text-sm " +
                      (!course_link_available ? "text-blue-700" : "")
                    }
                  />
                </div>
                <span
                  className={
                    "mr-2 " +
                    (coursesChanges.course_link_available &&
                      coursesChanges.course_link_available[0] &&
                      "text-yellow-800")
                  }
                >
                  {"Only these courses are available"}
                </span>
                <Tooltip
                  tooltipId={"available_courses"}
                  text={
                    "If the program is applicable to only selected courses. List them below."
                  }
                />
              </div>
              <div className={"flex flex-col pl-6 mt-2"}>
                <span className="text-gray-750 text-sm mb-1">
                  {
                    "Copy and paste ONE course per line onto the text area. "
                  }
                </span>
                <Field
                  disabled={course_link_available}
                  name="courses_list"
                  component={TextAreaField}
                  props={{
                    classForBorder:
                      coursesChanges.courses_list &&
                      "border-yellow-800",
                  }}
                  placeholder="Course Names"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export function validate(values, props) {
  const errors = {};
  const { courses_link } = values;

  if (
    props.course_link_available &&
    !validator.isURL(courses_link || "", { require_protocol: true })
  )
    errors.courses_link = "Invalid URL";

  return errors;
}

export default CoursesMajors;
