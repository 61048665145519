import Content, { validate } from "./content";
import { connect } from "react-redux";
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { DirectApplyProgramCreators } from "../../../../redux/actions";
import { snakeCase } from "lodash";

function mapStateToProps(state) {
  const {
    directApplyProgram: {
      isUpdating,
      isSavingDraft,
      program: {
        id,
        status,
        student_testimonials,
      },
      programTypeContent,
    },
  } = state;

  return {
    id,
    status: snakeCase(status),
    isUpdating,
    isSavingDraft,
    student_testimonials,
    program_content: programTypeContent ? programTypeContent.program_content : [],
  };
}

const mapDispatchToProps = {
  updateProgram: DirectApplyProgramCreators.updateProgramRequest,
  saveProgramDraft: DirectApplyProgramCreators.saveProgramDraftRequest,
};

export default withTranslation(null, { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    reduxForm({
      form: "programContentForm",
      validate,
      enableReinitialize: true,
    })(Content)
  )
);
