import React, { Component } from 'react';
import PropTypes from "prop-types";

class TextInput extends Component {
  render() {
    const {
      error,
      icon,
      ...rest
    } = this.props;

    return (
      <div className={"flex flex-col relative ".concat(this.props.className)}>
        <textarea
          className={`text-area-field`}
          {...rest}
        />
        {icon && icon}
        {
          error ? (
            <span className="text-red-800 text-xs font-bold mt-1">
              {error}
            </span>
          ) : null
        }
      </div>
    );
  }
}

TextInput.defaultProps = {

}

TextInput.propTypes = {
  error: PropTypes.string
}

export default TextInput;
