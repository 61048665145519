import React, { useEffect, useState } from "react";
import { TextInput, DatePickerField } from "../../../../../components";
import { TextAreaField } from "../../../../../form-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { range, union, find, isEmpty } from "lodash";

const FileUpload = ({ question }) => {
  return (
    <div className="rounded-md bg-blue-50 flex flex-col px-3 py-4 mb-3">
      <span className="text-gray-750 text-sm mb-2 font-bold">
        {question.title}
        {question.is_required ? (
          <span className="text-red-800 ml-px">*</span>
        ) : null}
      </span>
      <button className={"btn-text-primary mt-2 cursor-pointer self-start"}>
        <FontAwesomeIcon icon={["fas", "upload"]} className={"mr-1"} />
        {"Upload file here"}
      </button>
    </div>
  );
};

const ShortAnswer = ({ question }) => {
  return (
    <div className="rounded-md bg-blue-50 flex flex-col px-3 py-4 mb-3">
      <span className="text-gray-750 text-sm mb-2 font-bold">
        {question.title}
        {question.is_required ? (
          <span className="text-red-800 ml-px">*</span>
        ) : null}
      </span>
      <TextInput placeholder="Please write here" />
    </div>
  );
};

const ParagraphAnswer = ({ question }) => {
  return (
    <div className="rounded-md bg-blue-50 flex flex-col px-3 py-4 mb-3">
      <span className="text-gray-750 text-sm mb-2 font-bold">
        {question.title}
        {question.is_required ? (
          <span className="text-red-800 ml-px">*</span>
        ) : null}
      </span>
      <TextAreaField meta={{}} input={{}} placeholder="Please write here" />
    </div>
  );
};

const SingleChoice = ({ question }) => {
  const [selectedOption, setSelectedOption] = useState(-1);

  return (
    <div className="rounded-md bg-blue-50 flex flex-col px-3 py-4 mb-3">
      <span className="text-gray-750 text-sm mb-2 font-bold">
        {question.title}
        {question.is_required ? (
          <span className="text-red-800 ml-px">*</span>
        ) : null}
      </span>

      {question.question_meta.map((q_meta, i) => (
        <div
          className="cursor-pointer text-gray-750 text-sm mb-1"
          onClick={() => setSelectedOption(i)}
          key={i}
        >
          <FontAwesomeIcon
            icon={
              selectedOption === i ? ["fas", "check-circle"] : ["far", "circle"]
            }
            className={
              "text-sm " + (selectedOption === i ? "text-blue-700" : "")
            }
          />
          <span className="ml-2 mr-8">{q_meta.text}</span>
        </div>
      ))}
    </div>
  );
};

const MultipleChoice = ({ question }) => {
  const [selectedOptions, setSelectedOptions] = useState(-1);

  return (
    <div className="rounded-md bg-blue-50 flex flex-col px-3 py-4 mb-3">
      <span className="text-gray-750 text-sm mb-2 font-bold">
        {question.title}
        {question.is_required ? (
          <span className="text-red-800 ml-px">*</span>
        ) : null}
      </span>

      {question.question_meta.map((q_meta, i) => (
        <div
          className="cursor-pointer text-gray-750 text-sm mb-1"
          onClick={() => setSelectedOptions(i)}
          key={i}
        >
          <FontAwesomeIcon
            icon={
              selectedOptions === i
                ? ["fas", "check-square"]
                : ["far", "square"]
            }
            className={
              "text-sm " + (selectedOptions === i ? "text-blue-700" : "")
            }
          />
          <span className="ml-2 mr-8">{q_meta.text}</span>
        </div>
      ))}
    </div>
  );
};

const ConditionalQuestion = ({ question, index, updateVisbilityMeta }) => {
  const [selectedOption, setSelectedOption] = useState(-1);

  const selectOption = (val, meta, allMeta) => {
    const { child_sort_number } = meta;

    if (child_sort_number === "end") {
      updateVisbilityMeta({ from: index, to: "end" });
    } else {
      const skipped = allMeta
        .filter(
          (obj) =>
            obj.child_sort_number !== child_sort_number &&
            parseInt(obj.child_sort_number) >= 0
        )
        .map((obj) => parseInt(obj.child_sort_number));
      // update goto list
      updateVisbilityMeta({ from: index, skipped });
    }

    setSelectedOption(val);
  };

  return (
    <div className="rounded-md bg-blue-50 flex flex-col px-3 py-4 mb-3">
      <span className="text-gray-750 text-sm mb-2 font-bold">
        {question.title}
        {question.is_required ? (
          <span className="text-red-800 ml-px">*</span>
        ) : null}
      </span>

      {question.question_meta
        .sort(
          (a, b) =>
            parseInt(a.child_sort_number) - parseInt(b.child_sort_number)
        )
        .map((q_meta, i) => (
          <div
            className="cursor-pointer text-gray-750 text-sm mb-1"
            onClick={() =>
              selectOption(i, question.question_meta[i], question.question_meta)
            }
            key={`conditional_${index}_${i}`}
          >
            <FontAwesomeIcon
              icon={
                selectedOption === i
                  ? ["fas", "check-circle"]
                  : ["far", "circle"]
              }
              className={`text-sm ${
                selectedOption === i ? "text-blue-700" : ""
              }`}
            />
            <span className="ml-2 mr-8">{q_meta.text}</span>
          </div>
        ))}
    </div>
  );
};

const DateQuestion = ({ question }) => {
  return (
    <div className="rounded-md bg-blue-50 flex flex-col px-3 py-4 mb-3">
      <span className="text-gray-750 text-sm mb-2 font-bold">
        {question.title}
        {question.is_required ? (
          <span className="text-red-800 ml-px">*</span>
        ) : null}
      </span>

      <div className="w-56">
        <DatePickerField
          meta={{}}
          input={{
            onChange: () => {},
          }}
          placeholder="mm/dd/yyyy"
          onChange={() => {}}
        />
      </div>
    </div>
  );
};

const Range = ({ question }) => {
  const rangeVal = question.question_meta[0];

  return (
    <div className="rounded-md bg-blue-50 flex flex-col px-3 py-4 mb-3">
      <span className="text-gray-750 text-sm mb-2 font-bold">
        {question.title}
        {question.is_required ? (
          <span className="text-red-800 ml-px">*</span>
        ) : null}
      </span>
      <div className="flex flex-row items-end">
        <span className="text-xs mr-3">
          {rangeVal ? rangeVal.from_range_label : ""}
        </span>
        {rangeVal &&
        typeof rangeVal.from_range !== "undefined" &&
        typeof rangeVal.to_range !== "undefined"
          ? range(Number(rangeVal.from_range), Number(rangeVal.to_range) + 1).map(
              (val) => (
                <div
                  key={val}
                  className="flex flex-col w-8 items-center cursor-pointer"
                >
                  <span className="text-xs mb-2">{val}</span>
                  <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
                </div>
              )
            )
          : null}
        <span className="text-xs ml-3">
          {rangeVal ? rangeVal.to_range_label : ""}
        </span>
      </div>
    </div>
  );
};

const AdditionalRequirements = ({
  additionalRequirementsValues: { questions, questions_title },
}) => {
  const [visibilityMeta, setVisibilityMeta] = useState({}); // list of {from: int, skipped: [int]}, all questions in skipped[] will be hidden

  function getSubsetQuestions(questions, index, skipped) {
    if (parseInt(index) >= 0) {
      const idx = parseInt(index);

      if (questions[idx].value.field_type !== "conditional") {
        return union(skipped, [parseInt(idx)]);
      }

      let combined = skipped;
      questions[idx].value.question_meta.forEach(({ child_sort_number }) => {
        if (parseInt(child_sort_number) >= 0) {
          combined = union(combined, [parseInt(child_sort_number)]);
        }
      });
      return combined;
    }

    // else, index is not a integer:
    return skipped;
  }

  function getVisibilityMeta(startIdx) {
    const newMeta = {};
    questions
      .filter(
        (qnObj) =>
          qnObj.value.field_type === "conditional" &&
          (parseInt(startIdx) >= 0 ? qnObj.key > startIdx : true)
      )
      .sort((a, b) => a.key - b.key)
      .forEach((qnObj) => {
        const { key } = qnObj;
        const index = parseInt(key);
        newMeta[index] = {
          from: index,
          skipped: getSubsetQuestions(questions, index, []),
        };
      });

    return newMeta;
  }

  function updateVisbilityMeta(item) {
    setVisibilityMeta((prevMeta) => {
      const { from: startIdx } = item;
      const metaAfterItem = getVisibilityMeta(startIdx);
      const newMeta = {
        ...prevMeta,
        ...metaAfterItem,
        [startIdx]: item,
      };

      return newMeta;
    });
  }

  function prefillVisibilityMeta() {
    setVisibilityMeta((prevMeta) => {
      return getVisibilityMeta();
    });
  }

  function shouldRenderQuestion({ key }) {
    // checks if key is between {from, to}
    // yes - don't render this qn
    // no - render this qn

    if (isEmpty(visibilityMeta)) {
      return true;
    }

    if (
      !isEmpty(
        find(
          visibilityMeta,
          (item) =>
            (key > item.from && item.to === "end") ||
            (item.skipped && item.skipped.includes(key))
        )
      )
    ) {
      return false;
    }

    return true;
  }

  useEffect(() => {
    prefillVisibilityMeta();
  }, []);

  return (
    <div className={`preview-container`}>
      <div className={` overflow-scroll`}>
        <div
          className={
            "flex flex-col justify-center h-100px px-5 border-b border-gray-300"
          }
        >
          <div className="preview-expand-button">
            <FontAwesomeIcon
              icon={["fas", "arrow-left"]}
              className="text-base text-gray-750"
            />
          </div>
          <h1 className={"text-black text-2xl"}>
            Preview of your Additional Requeriments
          </h1>
          <h3 className={"text-black text-sm mt-2"}>
            This information will be displayed in the application form.
          </h3>
        </div>
        <div className={"h-full flex flex-col px-6 py-5"}>
          <div className="flex flex-row justify-between">
            <span className="text-gray-750 text-base font-bold mb-6">
              {questions_title}{" "}
            </span>
            <span className="text-red-800 text-sm font-bold mb-6">
              Required *
            </span>
          </div>

          <div className="mt-4">
            {questions
              .sort((a, b) => a.key - b.key)
              .filter(shouldRenderQuestion)
              .map((question) => {
                switch (question.value.field_type) {
                  case "string":
                    return (
                      <ShortAnswer
                        key={question.value.id}
                        question={question.value}
                      />
                    );
                  case "text":
                    return (
                      <ParagraphAnswer
                        key={question.value.id}
                        question={question.value}
                      />
                    );
                  case "multiple_choice":
                    return (
                      <SingleChoice
                        key={question.value.id}
                        question={question.value}
                      />
                    );
                  case "conditional":
                    return (
                      <ConditionalQuestion
                        key={question.value.id}
                        question={question.value}
                        updateVisbilityMeta={(item) =>
                          updateVisbilityMeta(item)
                        }
                        index={question.key}
                      />
                    );
                  case "checkboxes":
                    return (
                      <MultipleChoice
                        key={question.value.id}
                        question={question.value}
                      />
                    );
                  case "date":
                    return (
                      <DateQuestion
                        key={question.value.id}
                        question={question.value}
                      />
                    );
                  case "linear_scale":
                    return (
                      <Range
                        key={question.value.id}
                        question={question.value}
                      />
                    );
                  case "file":
                    return (
                      <FileUpload
                        key={question.value.id}
                        question={question.value}
                      />
                    );
                  default:
                    return null;
                }
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalRequirements;
