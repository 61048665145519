import { createReducer } from "reduxsauce";
import { PartnerFeaturesTypes as Types, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.partnerFeatures;

export const clearPartnerFilters = (state, action) => ({
  ...state,
  partner: {},
  loading: false,
});

export const getPartnerFeaturesFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getPartnerFeaturesRequest = (state, action) => ({
  ...state,
  loading: true,
  partner: {},
});
export const getPartnerFeaturesSuccess = (state, action) => ({
  ...state,
  ...action.data,
  id: action.partnerId,
  loading: false,
});

export const updatePartnerFeaturesFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const updatePartnerFeaturesRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const updatePartnerFeaturesSuccess = (state, action) => ({
  ...state,
  loading: false,
});

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CLEAR_PARTNER_FEATURES]: clearPartnerFilters,

  [Types.GET_PARTNER_FEATURES_FAILURE]: getPartnerFeaturesFailure,
  [Types.GET_PARTNER_FEATURES_REQUEST]: getPartnerFeaturesRequest,
  [Types.GET_PARTNER_FEATURES_SUCCESS]: getPartnerFeaturesSuccess,

  [Types.UPDATE_PARTNER_FEATURES_FAILURE]: updatePartnerFeaturesFailure,
  [Types.UPDATE_PARTNER_FEATURES_REQUEST]: updatePartnerFeaturesRequest,
  [Types.UPDATE_PARTNER_FEATURES_SUCCESS]: updatePartnerFeaturesSuccess,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
