import EditProgramScreen from "./edit-program-screen";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { DirectApplyProgramCreators,MiscCreators } from "../../../redux/actions";
const informationSelector = formValueSelector('programInformationForm');


function mapStateToProps(state) {
  const {
    directApplyProgram: {
      isLoadingProgram,
      programLoadFailed,
      program,
      programTypes,
      programTypeContent,
      currencies,
      universities,
      brochures,
      intakeRounds,
      isDeleting,
      isActivating,
      isDeactivating,
      isApproving,
      isDeclining,
      deleteSuccess,
      activateSuccess,
      deactivateSuccess,
      approveSuccess,
      declineSuccess,
      updateSuccess,
      saveDraftSuccess,
      isFetchingProgramTypes,
      isFetchingProgramTypeContent,
      isFetchingCurrencies,
      isFetchingUniversities,
      isFetchingBrochures,
      isFetchingDocumentTypes,
      isFetchingBanners,
      isFetchingIntakeRounds,
    },
    // user: {
    //   data: { institute_type },
    // },
    // partner: {
    //   data: { name: partnerName },
    // }
    misc: { countries },
  } = state;

  return {
    isLoadingProgram,
    programLoadFailed,
    program,
    programTypes,
    programTypeContent,
    currencies,
    universities,
    brochures,
    intakeRounds,
    // isUniversity: institute_type === "University",
    // isUniversity: true,
    partnerName: "XYZ",
    isDeleting,
    isActivating,
    isDeactivating,
    isApproving,
    isDeclining,
    deleteSuccess,
    activateSuccess,
    deactivateSuccess,
    approveSuccess,
    declineSuccess,
    updateSuccess,
    saveDraftSuccess,
    isFetchingProgramTypes,
    isFetchingProgramTypeContent,
    isFetchingCurrencies,
    isFetchingUniversities,
    isFetchingBrochures,
    isFetchingDocumentTypes,
    isFetchingBanners,
    isFetchingIntakeRounds,
    program_type: informationSelector(
      state,
      'program_type',
    ),
    countries,
  };
}

const mapDispatchToProps = {
  getProgram: DirectApplyProgramCreators.getProgramRequest,
  deleteProgram: DirectApplyProgramCreators.deleteProgramRequest,
  activateProgram: DirectApplyProgramCreators.activateProgramRequest,
  deactivateProgram: DirectApplyProgramCreators.deactivateProgramRequest,
  approveProgram: DirectApplyProgramCreators.approveProgramRequest,
  declineProgram: DirectApplyProgramCreators.declineProgramRequest,
  editProgramStatus: DirectApplyProgramCreators.editProgramStatusRequest,
  getProgramTypes: DirectApplyProgramCreators.getProgramTypesOptionsRequest,
  getProgramTypeContent: DirectApplyProgramCreators.getProgramTypeContentRequest,
  getCurrencies: DirectApplyProgramCreators.getCurrencyOptionsRequest,
  getUniversities: DirectApplyProgramCreators.getUniversityOptionsRequest,
  getBrochures: DirectApplyProgramCreators.getBrochuresRequest,
  getDocumentTypes: DirectApplyProgramCreators.getDocumentTypesRequest,
  getBanners: DirectApplyProgramCreators.getBannersRequest,
  getIntakeRounds: DirectApplyProgramCreators.getIntakeRoundsRequest,
  getCountries: () => MiscCreators.getCountriesRequest(),
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProgramScreen);
export * from "./information";
export * from "./additional-requirements";
