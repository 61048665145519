import UsersPage from './users';
import { connect } from 'react-redux';
import { UsersCreators, PartnersCreators } from '../../redux/actions';

function mapStateToProps(state) {
  return {
    users: state.users.data,
    total: state.users.total,
    matched: state.users.matched,
    isNextPageLoading: state.users.loading,
    sso_url: state.users.data
  };
}

const mapDispatchToProps = {
  getUsers: UsersCreators.getUsersRequest,
  updateUsers: UsersCreators.updateUsersRequest,
  getPartners: PartnersCreators.getPartnersRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
