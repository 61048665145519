import CounselorsScreen from "./counselors";
import { connect } from "react-redux";
import { ClientsCreators } from "src/redux/actions";

function mapStateToProps(state) {
  return {
    counselors: state.clients.counselors.data,
    total: state.clients.counselors.total,
    matched: state.clients.counselors.matched,
    isNextPageLoading: state.clients.counselors.loading,
  };
}

const mapDispatchToProps = {
  getCounselors: ClientsCreators.getCounselorsRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CounselorsScreen);
