import { createReducer } from 'reduxsauce'
import { UsersTypes as Types, UserTypes } from '../actions';
import storeInitialState from '../store/initial-state';

export const INITIAL_STATE = storeInitialState.users;

export const clearUsers = (state, action) => ({ ...state, data: [] });

export const getUsersFailure = (state, action) => ({ ...state, loading: false });
export const getUsersRequest = (state, action) => ({ ...state, loading: true });
export const getUsersSuccess = (state, action) => {
  const {
    usersData: {
        users,
        matched,
        total
      },
      params
    } = action;

  return {
    ...state,
    data: params.page_no === 1 ? users : [...state.data, ...users],
    matched,
    total,
    params: { ...state.params, ...params },
    loading: false
  };
};

export const updateUsersFailure = (state, action) => ({ ...state, loading: false });
export const updateUsersRequest = (state, action) => ({ ...state, loading: true });
export const updateUsersSuccess = (state, action) => ({ ...state, loading: false });

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CLEAR_USERS]:                       clearUsers,

  [Types.GET_USERS_FAILURE]:                 getUsersFailure,
  [Types.GET_USERS_REQUEST]:                 getUsersRequest,
  [Types.GET_USERS_SUCCESS]:                 getUsersSuccess,

  [Types.UPDATE_USERS_FAILURE]:              updateUsersFailure,
  [Types.UPDATE_USERS_REQUEST]:              updateUsersRequest,
  [Types.UPDATE_USERS_SUCCESS]:              updateUsersSuccess,

  [UserTypes.LOGOUT]:                        logout
};

export default createReducer(INITIAL_STATE, HANDLERS);
