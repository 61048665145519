import React from 'react';

const AdminSubmitButton = (props) => {
    const { className, icon, text, onClick, type } = props;

    return (
      <button
        type={type ? type : 'button'}
        onClick={() => { onClick() }}
        className={"px-2 py-2 cursor-pointer flex items-center justify-center rounded ".concat(className)}>
        {icon && icon}
        <span className={icon ? "ml-1" : "" }>{text}</span>
      </button>
    );
}

export default AdminSubmitButton;
