import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = ({ t, setExpand, expand, taggingQuickSubmit }) => {
  const handleExpandToggle = () => {
    setExpand(!expand);
  };

  return (
    <div
      className={`flex flex-col display-section px-6 py-4 ${expand ? "rounded-b-none" : ""}`}
    >
      <div
        className="flex items-center justify-between w-full cursor-pointer"
        onClick={handleExpandToggle}
      >
        <div className="flex items-center">
          <span className="text-lg text-black font-bold">
            {t("direct_apply_apps.quick_submit.tagging_title")}
          </span>
          <FontAwesomeIcon
            icon={["far", "sparkles"]}
            className="pl-2"
          />
          <div className={`ml-4 ${taggingQuickSubmit?.status_badge}`}>
            {taggingQuickSubmit?.status}
          </div>
        </div>
        <FontAwesomeIcon
          icon={
            expand ? ["fas", "chevron-up"] : ["fas", "chevron-down"]
          }
          className="text-base text-gray-750"
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  taggingQuickSubmit: state.directApply.taggingQuickSubmit,
});

export default withTranslation()(connect(mapStateToProps)(Header));
