import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { eventBusMiddleware } from '../middlewares';
import { rootSaga } from '../sagas';

const middlewares = [eventBusMiddleware];
const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);
middlewares.push(logger);

export default function configureStore (initialState) {
  var store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middlewares)
    )
  );
  sagaMiddleware.run(rootSaga);

  return store;
};
