import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { find } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import cialfoLogo from "src/assets/cialfo-logo.svg";
import ProfileMenuComponent from "src/components/ProfileMenuComponent";
import "./style.css";
import {
  admin,
  cs,
  da_support,
} from "../../constants/user-roles.json";
import DAIconBlue from "../../assets/direct-apply-logo/DA.svg";
import DAIconBlack from "../../assets/direct-apply-logo/monoicon.svg";

const MenuItem = props => {
  const { item, selection, userRole, t, index } = props;
  const isTabSelected =
    selection === item.url ||
    find(item.subTabs || [], subTab => selection === subTab.url);

  const isOptionDirectApply =
    item.title === t("side_bar.direct_apply");

  if (!item.allowedRoles.includes(userRole)) {
    return null;
  }

  return (
    <div key={item.id}>
      <Link to={"/" + item.url} key={index}>
        <div
          className={`item side-bar-item cursor-pointer items-center mb-2 `}
        >
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-none flex-col items-center icon-side-bar">
              {!isOptionDirectApply && (
                <FontAwesomeIcon
                  icon={item.icon}
                  className={`text-xl ${
                    isTabSelected ? "text-blue-700" : "text-gray-750"
                  }`}
                />
              )}
              {isOptionDirectApply && (
                <img
                  className="-mt-1"
                  src={isTabSelected ? DAIconBlue : DAIconBlack}
                  alt="DA"
                />
              )}
            </div>
            <span
              className={`text-sm font-bold mt-2 mb-6 ${isTabSelected ? "text-blue-800" : "text-gray-600"}`}
            >
              {item.title}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

const PrimaryTabs = props => {
  const { t, userRole, selection } = props;
  const coreOptions = [
    // {
    //   id: 1,
    //   allowedRoles: [admin],
    //   url: "tenants",
    //   title: t("side_bar.clients"),
    //   icon: ["fad", "users"],
    // },
    // {
    //   id: 2,
    //   allowedRoles: [admin],
    //   url: "districts",
    //   title: t("side_bar.districts"),
    //   icon: ["fad", "building-user"],
    // },
  ];
  const exploreOptions = [
    {
      id: 1,
      allowedRoles: [admin, cs],
      url: "partners",
      title: t("side_bar.partners"),
      icon: ["fad", "graduation-cap"],
      subTabs: [
        {
          url: "universities",
        },
        {
          url: "schools",
        },
        {
          url: "other-partners",
        },
        {
          url: "new-requests",
        },
      ],
    },
    {
      id: 2,
      allowedRoles: [admin, da_support],
      url: "direct-apply",
      title: t("side_bar.direct_apply"),
      icon: ["fas", "file-invoice"],
      subTabs: [
        {
          url: "applications",
        },
        {
          url: "programs",
        },
      ],
    },
    {
      id: 3,
      allowedRoles: [admin, cs],
      url: "users",
      title: t("side_bar.users"),
      icon: ["fas", "users"],
    },
  ];

  return (
    <div className="flex flex-col items-center border-r border-gray-300">
      <div className={"relative flex flex-col"}>
        <div
          className="flex-1 flex flex-col overflow-x-hidden items-center"
          style={{ width: 104 }}
        >
          <img
            src={cialfoLogo}
            alt="Logo"
            className="mt-4"
            style={{ width: 39, height: 48 }}
          />
          <div className="flex-1 flex flex-col mt-6 items-center">
            {/* <span className="text-xs text-gray-600 mb-6">
              Cialfo Core
            </span> */}
            {coreOptions.map((option, index) => {
              return (
                <MenuItem
                  item={option}
                  userRole={userRole}
                  selection={selection}
                  t={t}
                  index={index}
                />
              );
            })}

            {/* <hr
              className="w-full text-gray-600 mb-6"
              style={{ width: 104 }}
            />

            <span className="text-xs text-gray-600 mb-6">
              Explore
            </span> */}

            {exploreOptions.map((option, index) => {
              return (
                <MenuItem
                  item={option}
                  userRole={userRole}
                  selection={selection}
                  t={t}
                  index={index}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex-1" />
      <ProfileMenuComponent />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userRole: state.user?.data?.role?.name,
  };
}

export default connect(mapStateToProps)(
  withTranslation()(PrimaryTabs),
);
