import React from "react";
import { resolveValueSelector } from "../helpers";

export const VerticalTwoValueColumn = props => {
  const { rowData, valueSelector } = props;
  const { topValue, bottomValue, icon } = resolveValueSelector(
    rowData,
    valueSelector,
  );

  return (
    <div className="flex flex-row">
      {icon ? <div className={"mr-2"}>{icon}</div> : null}
      <div className="flex flex-col overflow-hidden justify-center">
        <span className="text-xs font-normal text-gray-750 truncate">
          {topValue}
        </span>
        <span className="text-xs font-normal text-gray-750 mt-px truncate">
          {bottomValue}
        </span>
      </div>
    </div>
  );
};
