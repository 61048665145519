import axios from 'axios';
import _ from 'lodash';
import { Alert } from './';
import i18n from '../i18n';
import { APP_TOKEN } from '../config';
import EventBus from 'eventing-bus';

export class API {
  static headers() {
    return {
      "Content-Type": "application/json",
      'app_token':    APP_TOKEN
    }
  }

  static request(options) {
    if (options.mergeHeaders !== false)
      options.headers = _.merge(this.headers(), options.headers);

    return axios(options)
      .catch(error => {
        if (options.silent === true) {
        }
        else if (options.customMessagesForCodes && error.response && options.customMessagesForCodes[error.response.status]) {
          Alert.error(options.customMessagesForCodes[error.response.status]);
        }
        else {
          if (error.response && error.response.status === 401) {
            if (_.endsWith(options.url, '/sessions'))
              Alert.error(error.response.data.error);
            else if (_.endsWith(options.url, '/update_password')) {
              if (error.response && error.response.data.error)
                Alert.error(error.response.data.error);
            }
            else {
              EventBus.publish("session_expired");
              Alert.error(i18n.t('alerts.failure.session_expired'));
            }
          }
          else {
            var errorMessages = ""

            if (error.response && error.response.data.errors)
              errorMessages = _.join(error.response.data.errors, "\n")
            else if (error.response && error.response.data.error) {
              if (typeof error.response.data.error === "string")
                errorMessages = error.response.data.error;
              else if (error.response.data.error instanceof Object)
                errorMessages = _.join(_.map(error.response.data.error, (message, key) => message), "\n")
            }
            else if (error.message === "Network Error")
              errorMessages = i18n.t('alerts.failure.internet_connection');
            else
              errorMessages = i18n.t('alerts.failure.unknown');

            Alert.error(errorMessages)
          }
        }
        throw error;
      });
  }
}
