import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DirectApplyCreators } from "../../../redux/actions";
import { Format } from "../../../helpers";
import { RichTextInputField } from "../../../form-components";
import EventBus from "eventing-bus";

const NotesModal = ({
  visible,
  onClose,
  application,
  saveNotes,
  getApplication,
  id,
}) => {
  const [notes, setNotes] = useState("");
  const [state, setState] = useState("read");

  useEffect(() => {
    if (application && application.notes) {
      setNotes(application.notes);
      setState("read");
    } else setState("write");
  }, [application, visible]);

  useEffect(() => {
    const setApplicationStatusSubscription = EventBus.on(
      DirectApplyCreators.setApplicationStatusSuccess().type,
      _setStatusSuccess,
    );
    return () => {
      setApplicationStatusSubscription();
    };
  }, []);

  const _setStatusSuccess = () => {
    setState("read");
    getApplication(id);
    onClose(true);
  };

  const closeModal = () => {
    setState("read");
    onClose();
  };

  return (
    <ReactModal
      isOpen={visible}
      onRequestClose={() => {}}
      ariaHideApp={false}
      className="modal"
      overlayClassName="modalOverlay"
    >
      <div className="absolute left-0 right-0 top-0 bottom-0 bg-overlay-500 flex flex-col justify-center items-center z-20">
        <div className="my-20 rounded bg-white pt-6 pb-4 px-8 flex flex-col relative w-600px overflow-hidden">
          <div className={"flex flex-row justify-between"}>
            <span className="font-bold text-black mb-6 text-22">
              Note
            </span>

            <FontAwesomeIcon
              onClick={closeModal}
              icon={["fas", "times"]}
              className={
                "text-lg leading-none text-gray-750 cursor-pointer"
              }
            />
          </div>
          {state === "read" ? (
            <div className="flex flex-col">
              <div
                className={
                  "flex-1 flex flex-col overflow-y-auto  text-sm min-h-124px max-h-124px"
                }
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: Format.displayValue(notes),
                  }}
                  className={`mb-1  font-regular text-gray-750 gpa_description rich-text`}
                ></p>
              </div>
              <div class="h-px bg-gray-300 my-4"></div>
            </div>
          ) : (
            <RichTextInputField
              name="note"
              meta={{}}
              disabled={false}
              input={{
                name: "note",
                value: notes,
                onChange: event => {
                  setNotes(event);
                },
                onBlur: event => {},
              }}
            />
          )}
          <div className="flex ml-auto mt-4">
            {state === "read" ? (
              <button
                className="btn btn-primary"
                onClick={() => setState("write")}
              >
                <FontAwesomeIcon
                  icon={["fas", "edit"]}
                  className={
                    "text-sm leading-none text-white cursor-pointer mr-1"
                  }
                />
                Edit
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={() => saveNotes(application.id, { notes })}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

function mapStateToProps(state) {
  return {
    application: state.directApply.selected,
  };
}

const mapDispatchToProps = {
  getNotes: DirectApplyCreators.getDirectApplicationNotesRequest,
  saveNotes: DirectApplyCreators.setApplicationStatusRequest,
  getApplication: DirectApplyCreators.getApplicationRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(NotesModal));
