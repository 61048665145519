import React, { Component } from "react";
import Loader from "react-loader-spinner";
import { Filter, Table } from "../../../components";
import DirectApplyTransactionDetail from "../transaction-detail";
import RefundModal from "../transaction-detail/refund-modal";
import Empty from "./empty";
import NoResultsFound from "./no-results-found";
import { Format } from "../../../helpers";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import "react-virtualized/styles.css";

const sortableColumns = {
  students: "first_name",
  organization: "partners.name",
  amount: "paid_fee",
  created_at: "payment_transactions.created_at",
};

class DirectApplyTransactionsPage extends Component {
  state = {
    selectedTransaction: null,
    selectedApplicationName: "",
    isTransactionDetailVisible: false,
    isRefundModalVisible: false,
    selectedIndex: -1,
    searchText: "",
    prevParams: "",
    selectedFilters: {},
  };

  componentDidMount() {
    this.props.getFilterUniversities();
    this.setState({ selectedFilters: this.props.filters }, () =>
      this.load(),
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.filters !== this.props.filters &&
      !_.isEqual(prevProps.filters, this.props.filters)
    ) {
      if (
        prevProps.filters.searchText !== this.props.filters.searchText
      )
        this._searchLoadApplications();
      else this.load();
    }
  }

  closeViewTransactionScreen = () => {
    this.setState({ isTransactionDetailVisible: false });
  };

  load = loadMore => {
    return new Promise((resolve, reject) => {
      this.props.getTransactions(resolve, reject);
    }).catch(err => {});
  };

  _searchLoadApplications = _.debounce(this.load, 1000);

  _rowClassName = ({ index }) => {
    if (index < 0) {
      return "";
    } else {
      return (
        "app-table-row" +
        (index === this.state.selectedIndex ? " selected" : "")
      );
    }
  };

  _onClickHeader = ({ dataKey, sortingKey }) => {
    const {
      filters: {
        sortBy: { column: sortColumn, direction: sortDirection },
      },
    } = this.props;

    this.props.setSortBy({
      column: dataKey,
      identifier: sortingKey,
      direction:
        sortColumn === dataKey
          ? sortDirection === "desc"
            ? "asc"
            : "desc"
          : "asc",
    });
  };

  _loadMore = params => {
    return this.load(true);
  };

  _search = searchText => {
    this.props.setSearchText(searchText);
  };

  badgeClass = status => {
    const classes = {
      Paid: "badge-success",
      Failed: "badge-error",
      Refunded: "badge-warning",
      "Partially Refunded": "badge-warning",
      Inprogress: "badge-secondary",
    };
    return classes[status];
  };

  render() {
    const {
      transactions,
      matchedTransactions,
      totalTransactions,
      loading,
      setFilters,
      transactionsUniversities,
      filters,
      filters: {
        sortBy: { column: sortColumn, direction: sortDirection },
      },
      t,
    } = this.props;
    const {
      isTransactionDetailVisible,
      isRefundModalVisible,
      selectedTransaction,
      selectedApplicationName,
    } = this.state;
    const hasNextPage = matchedTransactions > transactions.length;
    const isRowLoaded = ({ index }) =>
      !hasNextPage || index < transactions.length;
    const loadMoreRows = !hasNextPage ? () => {} : this._loadMore;
    const filterOptions = [
      {
        id: "payments",
        title: "Payment Status",
        icon: ["fas", "location-arrow"],
        filterTypes: [
          {
            id: "payment_status",
            text: "Payment Status",
            isMulti: true,
            isSearchable: true,
            options: [
              { id: 1, text: "In Progress" },
              { id: 2, text: "Paid" },
              { id: 3, text: "Payment Failed" },
              { id: 4, text: "Refunded" },
              { id: 5, text: "Partially Refunded" },
            ],
          },
        ],
      },
      {
        id: "school",
        title: "School Education",
        filterTypes: [
          {
            id: "university",
            text: "University Name",
            icon: ["fas", "school"],
            isMulti: true,
            options:
              transactionsUniversities &&
              transactionsUniversities.map(university => ({
                id: university.id,
                text: university.name,
              })),
          },
        ],
      },
    ];

    return (
      <div className="flex flex-1 overflow-hidden">
        <div
          style={{ minWidth: "900px" }}
          className="flex-1 flex flex-col"
        >
          {totalTransactions === 0 && !loading ? (
            <Empty />
          ) : (
            <>
              <div className="flex flex-col pl-6">
                <Filter
                  searchPlaceholder={
                    "Search for name, email or program"
                  }
                  selectedFilters={filters.filters}
                  searchText={filters.searchText}
                  filterOptions={filterOptions}
                  onSelection={filters => {
                    setFilters(filters);
                  }}
                  onChangeSearchText={this._search}
                />
              </div>
              <div class="h-px bg-gray-300 mb-3"></div>
              <div className="applicants-list flex mt-2 items-center pl-6">
                {totalTransactions === 0 && loading ? null : (
                  <div className="font-bold">
                    {matchedTransactions} Applicants of{" "}
                    {totalTransactions}
                  </div>
                )}
              </div>

              {!loading && matchedTransactions === 0 ? (
                <NoResultsFound
                  title={"Search not found"}
                  detail={
                    "There are no transactions matching the search. Please try again with different keywords or filters"
                  }
                />
              ) : (
                <>
                  <div className={"h-5"} />
                  <Table
                    isRowLoaded={isRowLoaded}
                    loadMoreRows={loadMoreRows}
                    totalRowCount={matchedTransactions}
                    currentRowCount={transactions.length}
                    rows={transactions}
                    headerHeight={46}
                    rowHeight={62}
                    rowClassName={this._rowClassName}
                    onRowClick={({ rowData, event, index }) => {
                      this.setState(
                        {
                          selectedIndex: index,
                          selectedTransaction: rowData.id,
                          selectedApplicationName: `${rowData.first_name} ${rowData.last_name}`,
                        },
                        () => {
                          this.setState({
                            isTransactionDetailVisible: true,
                          });
                        },
                      );
                    }}
                    sortableColumns={sortableColumns}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    noRowsRenderer={() => {
                      return loading ? (
                        <div className="flex flex-col justify-center items-center h-full w-full">
                          <Loader
                            type="Oval"
                            color="grey"
                            height={50}
                            width={50}
                          />
                        </div>
                      ) : null;
                    }}
                    columns={[
                      // defines which column to be placed where and how each column looks like
                      {
                        dataKey: "students",
                        width: 300,
                        type: "VerticalTwoValueBlue",
                        disableSort: true,
                        tooltip: true,
                        valueSelector: rowData => ({
                          topValue:
                            rowData.first_name +
                            " " +
                            rowData.last_name,
                          bottomValue: rowData.email,
                        }),
                        header: {
                          title: t(
                            "direct_apply_transactions.table.header.student",
                          ),
                          onClick: this._onClickHeader,
                        },
                      },
                      {
                        dataKey: "organization",
                        width: 500,
                        flexGrow: 1,
                        type: "VerticalTwoValueBlack",
                        disableSort: true,
                        tooltip: true,
                        valueSelector: rowData => ({
                          topValue: rowData.university_name,
                          bottomValue: rowData.program_name,
                        }),
                        header: {
                          title: t(
                            "direct_apply_transactions.table.header.organization",
                          ),
                          onClick: this._onClickHeader,
                        },
                      },
                      {
                        dataKey: "round",
                        width: 175,
                        type: "Default",
                        disableSort: false,
                        valueSelector: rowData => ({
                          value: rowData.round,
                        }),
                        header: {
                          title: t(
                            "direct_apply_transactions.table.header.round",
                          ),
                          onClick: this._onClickHeader,
                        },
                      },
                      {
                        dataKey: "created_at",
                        width: 175,
                        type: "VerticalTwoValue",
                        disableSort: true,
                        valueSelector: rowData => ({
                          topValue: Format.date(rowData.date),
                          bottomValue: Format.time(rowData.date),
                        }),
                        header: {
                          title: t(
                            "direct_apply_transactions.table.header.date",
                          ),
                          onClick: this._onClickHeader,
                        },
                      },
                      {
                        dataKey: "amount",
                        width: 175,
                        type: "Default",
                        disableSort: true,
                        valueSelector: rowData => ({
                          value: `${rowData.currency} ${rowData.amount}`,
                        }),
                        header: {
                          title: t(
                            "direct_apply_transactions.table.header.amount",
                          ),
                          onClick: this._onClickHeader,
                        },
                      },
                      {
                        dataKey: "status",
                        width: 225,
                        type: "Tag",
                        disableSort: true,
                        valueSelector: rowData => ({
                          value: rowData.status.replace(
                            "Failed",
                            "Payment Failed",
                          ),
                        }),
                        className: rowData =>
                          this.badgeClass(rowData.status),
                        header: {
                          title: t(
                            "direct_apply_transactions.table.header.status",
                          ),
                          onClick: this._onClickHeader,
                        },
                      },
                      {
                        dataKey: "stripe_id",
                        width: 350,
                        type: "Blue",
                        disableSort: true,
                        tooltip: true,
                        valueSelector: rowData => ({
                          value: rowData.stripe_id,
                        }),
                        header: {
                          title: t(
                            "direct_apply_transactions.table.header.stripe_id",
                          ),
                          onClick: this._onClickHeader,
                        },
                      },
                    ]}
                  />
                </>
              )}
            </>
          )}
        </div>
        {loading && transactions.length > 0 ? (
          <div
            className={
              "absolute bottom-0 right-0 left-0 self-center pointer-events-none flex-none flex flex-col justify-center items-center p-1"
            }
          >
            <Loader
              type="ThreeDots"
              color="grey"
              height={75}
              width={75}
            />
          </div>
        ) : null}
        <div
          className={`fixed top-0 right-0 bottom-0 side-screen-container ${
            isTransactionDetailVisible ? "" : "side-screen-collapsed"
          }`}
        >
          {isTransactionDetailVisible ? (
            <DirectApplyTransactionDetail
              transactionId={selectedTransaction}
              closeButtonPressed={this.closeViewTransactionScreen}
              refundButtonPressed={() => {
                this.closeViewTransactionScreen();
                this.setState({ isRefundModalVisible: true });
              }}
            />
          ) : null}
        </div>
        <RefundModal
          visible={isRefundModalVisible}
          showLoader={false}
          transactionId={selectedTransaction}
          transactionName={selectedApplicationName}
          clearData={this.props.clearRefundActivities}
          refundRequest={this.props.postRefund}
          refreshTransactions={this.load}
          onClose={() => {
            this.setState({ isRefundModalVisible: false });
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(DirectApplyTransactionsPage);
