import React, { Component } from 'react';
import ImageQuoteComponent from '../../components/ImageQuoteComponent';
import LoginForm from './login-form';
import Loader from 'react-loader-spinner';

import EventBus from 'eventing-bus';
import { UserTypes } from '../../redux/actions';

class LoginPage extends Component {
  state = {
    loading: false
  }

  componentDidMount() {
    this.authenticationRequestSubscription = EventBus.on(UserTypes.AUTHENTICATION_REQUEST, () => this.setState({ loading: true }));
    this.authenticationSuccessSubscription = EventBus.on(UserTypes.AUTHENTICATION_SUCCESS, () => this.setState({ loading: false }));
    this.authenticationFailureSubscription = EventBus.on(UserTypes.AUTHENTICATION_FAILURE, () => this.setState({ loading: false }));
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _unsubscribeEvents = () => {
    this.authenticationRequestSubscription();
    this.authenticationSuccessSubscription();
    this.authenticationFailureSubscription();
  }

  _onSubmit = (values) => {
    const { authenticationRequest } = this.props;
    const params = new FormData();
    params.append("email", values.email);
    params.append("password", values.password);

    return new Promise(function(resolve, reject) {
      authenticationRequest(params, resolve, reject);
    });
  }

  render() {
    const { loading } = this.state;

    return <div className="flex h-full">
        <LoginForm
          onSubmit={this._onSubmit}
        />

        <div className="w-70per bg-blue-50 flex h-full relative">
            <div className="absolute bottom-0 left-0 right-0">
                <svg width={1203} height={602} viewBox="0 0 1203 602">
                    <defs>
                        <style>{".a{opacity:0.318;}.b{fill:#cfd9e4;}"}</style>
                    </defs>
                    <g className="a">
                        <path className="b" d="M0,159.137,1203,0V602H0Z" />
                    </g>
                </svg>
            </div>
            <div className="flex flex-col items-center justify-center flex-grow z-20 w-1/3">
                <ImageQuoteComponent quoteTitle="Quote from Karl Wirth"
                    bgColor="#AADDC7"
                    bgImage="person_first.svg"
                    designation="CEO and Founder Evergage"
                    quoteText="“Just one phenomenal customer experience can make a world of difference”"
                    designationwidth= "w-167px"/>
            </div>
            <div className="flex flex-col items-center justify-center flex-grow z-20 w-1/3">
                <ImageQuoteComponent quoteTitle="Quote from Bill Price"
                    bgColor="#DDAAC4"
                    bgImage="person_second.svg"
                    designation="President of Driva Solutions served on Amazon"
                    quoteText="“Customer loyalty is the most powerful sales and marketing tool”"
                    designationwidth= "w-214px"/>
            </div>
            <div className="flex flex-col items-center justify-center flex-grow z-20 w-1/3">
                <ImageQuoteComponent quoteTitle="Quote from Tom Krackeler."
                    bgColor="#CFD9E4"
                    bgImage="person_third.svg"
                    designation="Chief Customer Officer Zuora"
                    quoteText="“Make sure every single employee in your company knows precisely what value your customers are seeking”"
                    designationwidth= "w-167px"/>
            </div>
        </div>
        {
          loading ? (
            <div className="flex justify-center items-center z-20 absolute inset-0 bg-gray-200">
              <Loader
                type="Oval"
                color="grey"
                height={50}
                width={50}
              />
            </div>
          ) : null
        }
    </div>;
  }
}
export default LoginPage;
