import { put, takeEvery, select } from "redux-saga/effects";
import { ClientsCreators, ClientsTypes } from "../actions";
import _ from "lodash";

export function* watchClientsRequests() {
  yield takeEvery(
    ClientsTypes.GET_CLIENTS_REQUEST,
    requestGetClients,
  );
  yield takeEvery(
    ClientsTypes.GET_COUNSELORS_REQUEST,
    requestGetCounselors,
  );
  yield takeEvery(
    ClientsTypes.GET_GUARDIANS_REQUEST,
    requestGetGuardians,
  );
  yield takeEvery(
    ClientsTypes.GET_SCHOOL_STUDENTS_REQUEST,
    requestGetSchoolStudents,
  );
  yield takeEvery(
    ClientsTypes.GET_PRODUCTS_REQUEST,
    requestGetProducts,
  );
  yield takeEvery(
    ClientsTypes.GET_CONFIGURATION_REQUEST,
    requestConfiguration,
  );
}

const dummyResult = {
  details: {
    tenant_contracts: [
      {
        id: 1,
        is_active: true,
        start_date: "2017-10-04",
        end_date: "2080-12-31",
        remaining_contract_days: 20742,
        contract_type_id: 2,
        tenant_id: 1950,
        tenant_name: "AA",
        tenant_is_online: true,
        tenant_country: "Singapore",
        tenant_is_frozen: false,
        tenant_tenant_type_id: 1,
        tenant_sub_domain: "aa",
        tenant_owner_id: 1,
        tenant_owner_first_name: null,
        tenant_owner_last_name: null,
        tenant_joined_at: "2017-10-04T05:13:53.000Z",
        tenant_attribute_value_student_count: "-1",
        tenant_attribute_value_use_count: 13849,
        tenant_available_subscription_levels: [
          "basic",
          "professional",
          "access",
        ],
        tenant_subscription_level: "Professional",
        is_demo: 0,
        state: "Metro Manila",
        city: "Sample City",
        is_show_explore_page: 1,
        is_explore_activate: 1,
        tenant_account_status: "Live",
      },
      {
        id: 22,
        is_active: true,
        start_date: "2020-04-07",
        end_date: "2025-12-31",
        remaining_contract_days: 653,
        contract_type_id: 2,
        tenant_id: 30,
        tenant_name: "Companion free",
        tenant_is_online: true,
        tenant_country: "India",
        tenant_is_frozen: false,
        tenant_tenant_type_id: 1,
        tenant_sub_domain: "companion-free",
        tenant_owner_id: 1,
        tenant_owner_first_name: "DEMO FIRSTNAME",
        tenant_owner_last_name: "DEMO LAST NAME",
        tenant_joined_at: "2020-04-07T05:27:34.000Z",
        tenant_attribute_value_student_count: "-1",
        tenant_attribute_value_use_count: 306,
        tenant_available_subscription_levels: [
          "basic",
          "professional",
          "access",
        ],
        tenant_subscription_level: "Access",
        is_demo: 0,
        state: "Punjab",
        city: "Lahore",
        is_show_explore_page: 1,
        is_explore_activate: 1,
        tenant_account_status: "Live",
      },
      {
        id: 24,
        is_active: true,
        start_date: "2020-04-21",
        end_date: "2025-05-05",
        remaining_contract_days: 413,
        contract_type_id: 2,
        tenant_id: 33,
        tenant_name: "WARSAW",
        tenant_is_online: true,
        tenant_country: "Singapore",
        tenant_is_frozen: false,
        tenant_tenant_type_id: 1,
        tenant_sub_domain: "reserved_kzqbecho",
        tenant_owner_id: 1,
        tenant_owner_first_name: "DEMO FIRSTNAME",
        tenant_owner_last_name: "DEMO LAST NAME",
        tenant_joined_at: "2020-04-21T03:24:54.000Z",
        tenant_attribute_value_student_count: "-1",
        tenant_attribute_value_use_count: 4,
        tenant_available_subscription_levels: [
          "basic",
          "professional",
          "access",
        ],
        tenant_subscription_level: "Basic",
        is_demo: 0,
        state: "Punjab",
        city: "Lahore",
        is_show_explore_page: 1,
        is_explore_activate: 0,
        tenant_account_status: "Live",
      },
      {
        id: 32,
        is_active: true,
        start_date: "2020-06-12",
        end_date: "2025-06-30",
        remaining_contract_days: 469,
        contract_type_id: 2,
        tenant_id: 41,
        tenant_name: "Saint Thomas Academy",
        tenant_is_online: true,
        tenant_country: "United States",
        tenant_is_frozen: false,
        tenant_tenant_type_id: 1,
        tenant_sub_domain: "sta",
        tenant_owner_id: 1,
        tenant_owner_first_name: "Cialfo",
        tenant_owner_last_name: "Customer Success",
        tenant_joined_at: "2020-06-12T07:16:06.000Z",
        tenant_attribute_value_student_count: "-1",
        tenant_attribute_value_use_count: 4,
        tenant_available_subscription_levels: [
          "basic",
          "professional",
          "access",
        ],
        tenant_subscription_level: "Access",
        is_demo: 0,
        state: "State",
        city: "City",
        is_show_explore_page: 1,
        is_explore_activate: 1,
        tenant_account_status: "Live",
      },
      {
        id: 33,
        is_active: true,
        start_date: "2020-07-07",
        end_date: "2021-07-07",
        remaining_contract_days: -985,
        contract_type_id: 2,
        tenant_id: 42,
        tenant_name: "UX-Nav",
        tenant_is_online: true,
        tenant_country: "Singapore",
        tenant_is_frozen: false,
        tenant_tenant_type_id: 1,
        tenant_sub_domain: "ux-nav",
        tenant_owner_id: 1,
        tenant_owner_first_name: "Cialfo",
        tenant_owner_last_name: "Customer Success",
        tenant_joined_at: "2020-07-07T06:09:23.000Z",
        tenant_attribute_value_student_count: "-1",
        tenant_attribute_value_use_count: 15,
        tenant_available_subscription_levels: [
          "basic",
          "professional",
          "access",
        ],
        tenant_subscription_level: "Professional",
        is_demo: 0,
        state: null,
        city: null,
        is_show_explore_page: 1,
        is_explore_activate: 1,
        tenant_account_status: "Live",
      },
      {
        id: 34,
        is_active: true,
        start_date: "2020-07-29",
        end_date: "2025-06-30",
        remaining_contract_days: 469,
        contract_type_id: 2,
        tenant_id: 43,
        tenant_name: "Staging IEC",
        tenant_is_online: true,
        tenant_country: "Mainland China",
        tenant_is_frozen: false,
        tenant_tenant_type_id: 2,
        tenant_sub_domain: "companion-iec",
        tenant_owner_id: 1,
        tenant_owner_first_name: "Cialfo",
        tenant_owner_last_name: "Customer Success",
        tenant_joined_at: "2020-07-29T06:41:56.000Z",
        tenant_attribute_value_student_count: "-1",
        tenant_attribute_value_use_count: 54,
        tenant_available_subscription_levels: [
          "basic",
          "professional",
          "access",
        ],
        tenant_subscription_level: "Professional",
        is_demo: 0,
        state: "Singapore",
        city: "Singapore",
        is_show_explore_page: 0,
        is_explore_activate: 0,
        tenant_account_status: "Live",
      },
      {
        id: 35,
        is_active: true,
        start_date: "2020-11-03",
        end_date: "2050-01-01",
        remaining_contract_days: 9420,
        contract_type_id: 2,
        tenant_id: 44,
        tenant_name: "Staging Integration Tenant",
        tenant_is_online: true,
        tenant_country: "Afghanistan",
        tenant_is_frozen: false,
        tenant_tenant_type_id: 1,
        tenant_sub_domain: "staging-integration",
        tenant_owner_id: 1,
        tenant_owner_first_name: "Cialfo",
        tenant_owner_last_name: "Customer Success",
        tenant_joined_at: "2020-11-03T08:42:36.000Z",
        tenant_attribute_value_student_count: "-1",
        tenant_attribute_value_use_count: 0,
        tenant_available_subscription_levels: [
          "basic",
          "professional",
          "access",
        ],
        tenant_subscription_level: "Professional",
        is_demo: 0,
        state: null,
        city: null,
        is_show_explore_page: 1,
        is_explore_activate: 0,
        tenant_account_status: "Live",
      },
      {
        id: 37,
        is_active: true,
        start_date: "2020-12-08",
        end_date: "2030-12-12",
        remaining_contract_days: 2460,
        contract_type_id: 2,
        tenant_id: 46,
        tenant_name: "tenant-tracker",
        tenant_is_online: true,
        tenant_country: "Singapore",
        tenant_is_frozen: false,
        tenant_tenant_type_id: 1,
        tenant_sub_domain: "not-found",
        tenant_owner_id: 1,
        tenant_owner_first_name: "Cialfo",
        tenant_owner_last_name: "Customer Success",
        tenant_joined_at: "2020-12-08T09:07:56.000Z",
        tenant_attribute_value_student_count: "-1",
        tenant_attribute_value_use_count: 4,
        tenant_available_subscription_levels: [
          "basic",
          "professional",
          "access",
        ],
        tenant_subscription_level: "Access",
        is_demo: 0,
        state: null,
        city: null,
        is_show_explore_page: 1,
        is_explore_activate: 1,
        tenant_account_status: "Live",
      },
      {
        id: 38,
        is_active: true,
        start_date: "2021-01-22",
        end_date: "2022-01-22",
        remaining_contract_days: -786,
        contract_type_id: 2,
        tenant_id: 45,
        tenant_name: "DA Demo",
        tenant_is_online: true,
        tenant_country: "Singapore",
        tenant_is_frozen: true,
        tenant_tenant_type_id: 1,
        tenant_sub_domain: "da-demo",
        tenant_owner_id: 1,
        tenant_owner_first_name: null,
        tenant_owner_last_name: null,
        tenant_joined_at: "2020-11-13T07:46:15.000Z",
        tenant_attribute_value_student_count: "50",
        tenant_attribute_value_use_count: 0,
        tenant_available_subscription_levels: [
          "basic",
          "professional",
          "access",
        ],
        tenant_subscription_level: "Access",
        is_demo: 0,
        state: "Punjab",
        city: "Lahore",
        is_show_explore_page: 1,
        is_explore_activate: 0,
        tenant_account_status: "Frozen",
      },
      {
        id: 39,
        is_active: true,
        start_date: "2021-01-22",
        end_date: "2022-01-22",
        remaining_contract_days: -786,
        contract_type_id: 2,
        tenant_id: 47,
        tenant_name: "DA Demo New",
        tenant_is_online: true,
        tenant_country: "Singapore",
        tenant_is_frozen: false,
        tenant_tenant_type_id: 1,
        tenant_sub_domain: "da-demo-new",
        tenant_owner_id: 1,
        tenant_owner_first_name: "Admin",
        tenant_owner_last_name: "New",
        tenant_joined_at: "2021-01-22T07:47:46.000Z",
        tenant_attribute_value_student_count: "-1",
        tenant_attribute_value_use_count: 18,
        tenant_available_subscription_levels: [
          "basic",
          "professional",
          "access",
        ],
        tenant_subscription_level: "Professional",
        is_demo: 0,
        state: "da",
        city: "da",
        is_show_explore_page: 1,
        is_explore_activate: 1,
        tenant_account_status: "Live",
      },
    ],
    matched: 10,
    total: 10,
  },
};

const dummyCounselors = {
  details: {
    users: [
      {
        id: 693,
        is_active: true,
        first_name: "Abdul",
        last_name: "Haq",
        full_name: "Abdul Haq",
        email: "abdul.haq@cialfo.com.sg",
        mobile: "+14348253298",
        created_at: "2021-07-09T04:54:47.000Z",
        user_type_mask: 10,
        is_student: false,
        is_mentor: true,
        is_guardian: false,
        is_admin: true,
        is_teacher: false,
        last_login: null,
        user_type: ["mentor", "admin"],
        timezone: {
          id: 117,
          name: "Africa/Abidjan",
          offset: "+00:00",
          timezone_object: {
            id: 117,
            country_id: 55,
            name: "Africa/Abidjan",
            offset: "+00:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 1082,
        is_active: true,
        first_name: "Abhishek",
        last_name: "JHA",
        full_name: "Abhishek JHA",
        email: "abhishek.jha+admin2@cialfo.com.sg",
        mobile: "",
        created_at: "2022-08-12T06:58:00.000Z",
        user_type_mask: 10,
        is_student: false,
        is_mentor: true,
        is_guardian: false,
        is_admin: true,
        is_teacher: false,
        last_login: "2022-08-17T15:34:13.000Z",
        user_type: ["mentor", "admin"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 1250,
        is_active: true,
        first_name: "Access",
        last_name: "Counselor",
        full_name: "Access Counselor",
        email: "accesscoun@yopmail.com",
        mobile: "+12023332321",
        created_at: "2023-05-05T07:54:55.000Z",
        user_type_mask: 2,
        is_student: false,
        is_mentor: true,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["mentor"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 554,
        is_active: true,
        first_name: "Access Guardian Counselor",
        last_name: "Access Guardian Counselor",
        full_name:
          "Access Guardian Counselor Access Guardian Counselor",
        email: "charles.e.carrier+access+guardian+admin+2@gmail.com",
        mobile: "+14348253298",
        created_at: "2020-11-11T22:48:40.000Z",
        user_type_mask: 14,
        is_student: false,
        is_mentor: true,
        is_guardian: true,
        is_admin: true,
        is_teacher: false,
        last_login: "2021-07-30T02:14:20.000Z",
        user_type: ["mentor", "guardian", "admin"],
        timezone: {
          id: 163,
          name: "Africa/Accra",
          offset: "+00:00",
          timezone_object: {
            id: 163,
            country_id: 84,
            name: "Africa/Accra",
            offset: "+00:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 555,
        is_active: true,
        first_name: "Access Guardian Counselor ONLY",
        last_name: "Access Guardian Counselor ONLY",
        full_name:
          "Access Guardian Counselor ONLY Access Guardian Counselor ONLY",
        email: "charles.e.carrier+guardian+counselor+only@gmail.com",
        mobile: "+14348253298",
        created_at: "2020-11-11T22:53:57.000Z",
        user_type_mask: 6,
        is_student: false,
        is_mentor: true,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: "2021-02-05T07:42:39.000Z",
        user_type: ["mentor", "guardian"],
        timezone: {
          id: 117,
          name: "Africa/Abidjan",
          offset: "+00:00",
          timezone_object: {
            id: 117,
            country_id: 55,
            name: "Africa/Abidjan",
            offset: "+00:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 556,
        is_active: true,
        first_name: "Access Teacher Guardian",
        last_name: "Carrier",
        full_name: "Access Teacher Guardian Carrier",
        email: "charles.e.carrier+access+teacher+guardian@gmail.com",
        mobile: "+14348253298",
        created_at: "2020-11-11T23:01:16.000Z",
        user_type_mask: 6,
        is_student: false,
        is_mentor: false,
        is_guardian: true,
        is_admin: false,
        is_teacher: true,
        last_login: "2021-02-05T07:42:12.000Z",
        user_type: ["mentor", "guardian"],
        timezone: {
          id: 163,
          name: "Africa/Accra",
          offset: "+00:00",
          timezone_object: {
            id: 163,
            country_id: 84,
            name: "Africa/Accra",
            offset: "+00:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 1016,
        is_active: true,
        first_name: "Ahmed",
        last_name: "Admin",
        full_name: "Ahmed Admin",
        email: "ahmed.asif+freeadmin@conradlabs.com",
        mobile: "+11111",
        created_at: "2022-07-27T08:24:49.000Z",
        user_type_mask: 10,
        is_student: false,
        is_mentor: true,
        is_guardian: false,
        is_admin: true,
        is_teacher: false,
        last_login: "2022-07-27T11:14:33.000Z",
        user_type: ["mentor", "admin"],
        timezone: {
          id: 148,
          name: "Africa/Addis_Ababa",
          offset: "+03:00",
          timezone_object: {
            id: 148,
            country_id: 71,
            name: "Africa/Addis_Ababa",
            offset: "+03:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 127,
        is_active: true,
        first_name: "Alpha",
        last_name: "Admin",
        full_name: "Alpha Admin",
        email: "usamashahid+admin+upload@cialfo.com.sg",
        mobile: "6501234567",
        created_at: "2020-04-27T10:01:18.000Z",
        user_type_mask: 10,
        is_student: false,
        is_mentor: true,
        is_guardian: false,
        is_admin: true,
        is_teacher: false,
        last_login: "2020-04-27T10:03:01.000Z",
        user_type: ["mentor", "admin"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 124,
        is_active: true,
        first_name: "Alpha",
        last_name: "Teacher",
        full_name: "Alpha Teacher",
        email: "charles.e.carrier+april+26+uploader+test+1@gmail.com",
        mobile: "6501234567",
        created_at: "2020-04-26T06:58:15.000Z",
        user_type_mask: 10,
        is_student: false,
        is_mentor: true,
        is_guardian: false,
        is_admin: true,
        is_teacher: false,
        last_login: null,
        user_type: ["mentor", "admin"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 400,
        is_active: true,
        first_name: "Alpha",
        last_name: "Teacher",
        full_name: "Alpha Teacher",
        email: "alpha@staff.cialfo.sg",
        mobile: "6501234567",
        created_at: "2020-07-08T05:30:14.000Z",
        user_type_mask: 10,
        is_student: false,
        is_mentor: true,
        is_guardian: false,
        is_admin: true,
        is_teacher: false,
        last_login: null,
        user_type: ["mentor", "admin"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 456,
        is_active: true,
        first_name: "Anna",
        last_name: "T",
        full_name: "Anna T",
        email: "anna.t@gmail.com",
        mobile: "+182870081",
        created_at: "2020-08-28T02:28:05.000Z",
        user_type_mask: 10,
        is_student: false,
        is_mentor: true,
        is_guardian: false,
        is_admin: true,
        is_teacher: false,
        last_login: null,
        user_type: ["mentor", "admin"],
        timezone: {
          id: 117,
          name: "Africa/Abidjan",
          offset: "+00:00",
          timezone_object: {
            id: 117,
            country_id: 55,
            name: "Africa/Abidjan",
            offset: "+00:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 19,
        is_active: true,
        first_name: "Anna",
        last_name: "Tantushyan",
        full_name: "Anna Tantushyan",
        email: "anna@cialfo.com.sg",
        mobile: "+123456789",
        created_at: "2020-04-08T06:14:43.000Z",
        user_type_mask: 10,
        is_student: false,
        is_mentor: true,
        is_guardian: false,
        is_admin: true,
        is_teacher: false,
        last_login: "2021-04-05T06:02:41.000Z",
        user_type: ["mentor", "admin"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 585,
        is_active: true,
        first_name: "Antonius",
        last_name: "Wijaya",
        full_name: "Antonius Wijaya",
        email: "antonius@cialfo.com.sg",
        mobile: "+14348253298",
        created_at: "2020-11-26T06:05:06.000Z",
        user_type_mask: 10,
        is_student: false,
        is_mentor: true,
        is_guardian: false,
        is_admin: true,
        is_teacher: false,
        last_login: "2022-04-11T03:27:37.000Z",
        user_type: ["mentor", "admin"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 16,
        is_active: true,
        first_name: "Ather",
        last_name: "Counselor",
        full_name: "Ather Counselor",
        email: "ather+counselor@cialfo.com.sg",
        mobile: "+1234554433",
        created_at: "2020-04-07T07:47:34.000Z",
        user_type_mask: 2,
        is_student: false,
        is_mentor: true,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: "2020-04-29T08:42:48.000Z",
        user_type: ["mentor"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 17,
        is_active: true,
        first_name: "Ather",
        last_name: "Teacher",
        full_name: "Ather Teacher",
        email: "ather+teacher@cialfo.com.sg",
        mobile: "+1233332222",
        created_at: "2020-04-07T08:07:17.000Z",
        user_type_mask: 2,
        is_student: false,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: true,
        last_login: "2020-04-29T09:01:32.000Z",
        user_type: ["mentor"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 639,
        is_active: true,
        first_name: "Basharat",
        last_name: "Counselor",
        full_name: "Basharat Counselor",
        email: "basharat+counsler@cialfo.com.sg",
        mobile: "+923034843874",
        created_at: "2021-03-24T05:59:54.000Z",
        user_type_mask: 10,
        is_student: false,
        is_mentor: true,
        is_guardian: false,
        is_admin: true,
        is_teacher: false,
        last_login: "2021-03-24T06:01:14.000Z",
        user_type: ["mentor", "admin"],
        timezone: {
          id: 289,
          name: "Asia/Karachi",
          offset: "+05:00",
          timezone_object: {
            id: 289,
            country_id: 168,
            name: "Asia/Karachi",
            offset: "+05:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 634,
        is_active: true,
        first_name: "Basharat",
        last_name: "Ali",
        full_name: "Basharat Ali",
        email: "basharat@cialfo.com.sg",
        mobile: "+12345678910",
        created_at: "2021-03-16T08:58:37.000Z",
        user_type_mask: 10,
        is_student: false,
        is_mentor: true,
        is_guardian: false,
        is_admin: true,
        is_teacher: false,
        last_login: "2021-08-06T09:40:36.000Z",
        user_type: ["mentor", "admin"],
        timezone: {
          id: 289,
          name: "Asia/Karachi",
          offset: "+05:00",
          timezone_object: {
            id: 289,
            country_id: 168,
            name: "Asia/Karachi",
            offset: "+05:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 45,
        is_active: true,
        first_name: "Ben",
        last_name: "Graham",
        full_name: "Ben Graham",
        email: "ben@cialfo.com.sg",
        mobile: "",
        created_at: "2020-04-16T14:24:54.000Z",
        user_type_mask: 10,
        is_student: false,
        is_mentor: true,
        is_guardian: false,
        is_admin: true,
        is_teacher: false,
        last_login: null,
        user_type: ["mentor", "admin"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 128,
        is_active: true,
        first_name: "Beta",
        last_name: "Counselor",
        full_name: "Beta Counselor",
        email: "usamashahid+counselor+upload@cialfo.com.sg",
        mobile: "6501234567",
        created_at: "2020-04-27T10:01:20.000Z",
        user_type_mask: 2,
        is_student: false,
        is_mentor: true,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: "2020-04-27T10:05:49.000Z",
        user_type: ["mentor"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 401,
        is_active: true,
        first_name: "Beta",
        last_name: "Teacher",
        full_name: "Beta Teacher",
        email: "beta@staff.cialfo.sg",
        mobile: "6501234567",
        created_at: "2020-07-08T05:30:17.000Z",
        user_type_mask: 2,
        is_student: false,
        is_mentor: true,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["mentor"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
    ],
  },
};

const dummyStudents = {
  details: {
    users: [
      {
        id: 817,
        is_active: true,
        first_name: "a",
        last_name: "b",
        full_name: "a b",
        email: "rabika.iqbal+fs3@cialfo.com.sg",
        mobile: "64",
        created_at: "2021-12-22T10:21:09.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: "2022-02-09T11:03:48.000Z",
        user_type: ["student"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 824,
        is_active: true,
        first_name: "A",
        last_name: "Aa",
        full_name: "A Aa",
        email: "a@a.com",
        mobile: "56",
        created_at: "2022-01-14T06:53:21.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: "2022-01-14T06:53:22.000Z",
        user_type: ["student"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 1217,
        is_active: true,
        first_name: "aa",
        last_name: "bb",
        full_name: "aa bb",
        email: "aq@a.com",
        mobile: null,
        created_at: "2022-10-28T18:39:19.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: "2022-10-28T18:39:19.000Z",
        user_type: ["student"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 479,
        is_active: true,
        first_name: "Aalia",
        last_name: "Tan",
        full_name: "Aalia Tan",
        email: "astudentagbb@student.example.com",
        mobile: null,
        created_at: "2020-09-11T03:47:15.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["student"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 950,
        is_active: true,
        first_name: "ABC",
        last_name: "DEF",
        full_name: "ABC DEF",
        email: "ahmed.asif+freeqa@conradlabs.com",
        mobile: null,
        created_at: "2022-06-29T06:10:51.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: "2022-07-27T07:44:28.000Z",
        user_type: ["student"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 1266,
        is_active: true,
        first_name: "Abc",
        last_name: "Sign",
        full_name: "Abc Sign",
        email: "abc@onboard.com",
        mobile: null,
        created_at: "2023-11-01T18:52:56.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: "2023-11-01T19:07:11.000Z",
        user_type: ["student"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 1267,
        is_active: true,
        first_name: "abc",
        last_name: "free",
        full_name: "abc free",
        email: "abc1@onboard.com",
        mobile: null,
        created_at: "2023-11-01T19:00:20.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: "2023-11-01T19:00:20.000Z",
        user_type: ["student"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 664,
        is_active: true,
        first_name: "abc",
        last_name: "def",
        full_name: "abc def",
        email: "aajkdhsj@asdkjs.sdjkk",
        mobile: null,
        created_at: "2021-07-02T08:08:08.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["student"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 840,
        is_active: true,
        first_name: "Abc",
        last_name: "Sef",
        full_name: "Abc Sef",
        email: "b@b.com",
        mobile: null,
        created_at: "2022-02-23T07:29:15.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: "2023-02-15T04:50:55.000Z",
        user_type: ["student"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 235,
        is_active: false,
        first_name: "Accepted2525-1",
        last_name: "Ruth",
        full_name: "Accepted2525-1 Ruth",
        email: "accepted2525-1ruth@example.com",
        mobile: "92333014",
        created_at: "2020-06-18T14:29:57.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["student"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 236,
        is_active: false,
        first_name: "Accepted2525-2",
        last_name: "Ruth",
        full_name: "Accepted2525-2 Ruth",
        email: "accepted2525-2ruth@example.com",
        mobile: "92333014",
        created_at: "2020-06-18T14:29:58.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["student"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 237,
        is_active: false,
        first_name: "Accepted2525-3",
        last_name: "Ruth",
        full_name: "Accepted2525-3 Ruth",
        email: "accepted2525-3ruth@example.com",
        mobile: "92333014",
        created_at: "2020-06-18T14:29:59.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["student"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 238,
        is_active: false,
        first_name: "Accepted2525-4",
        last_name: "Ruth",
        full_name: "Accepted2525-4 Ruth",
        email: "accepted2525-4ruth@example.com",
        mobile: "92333014",
        created_at: "2020-06-18T14:29:59.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["student"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 239,
        is_active: false,
        first_name: "Accepted2525-5",
        last_name: "Ruth",
        full_name: "Accepted2525-5 Ruth",
        email: "accepted2525-5ruth@example.com",
        mobile: "92333014",
        created_at: "2020-06-18T14:30:00.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["student"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 240,
        is_active: false,
        first_name: "Accepted2525-6",
        last_name: "Ruth",
        full_name: "Accepted2525-6 Ruth",
        email: "accepted2525-6ruth@example.com",
        mobile: "92333014",
        created_at: "2020-06-18T14:30:01.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["student"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 241,
        is_active: false,
        first_name: "Accepted2525-7",
        last_name: "Ruth",
        full_name: "Accepted2525-7 Ruth",
        email: "accepted2525-7ruth@example.com",
        mobile: "92333014",
        created_at: "2020-06-18T14:30:01.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["student"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 242,
        is_active: false,
        first_name: "Accepted2525-8",
        last_name: "Ruth",
        full_name: "Accepted2525-8 Ruth",
        email: "accepted2525-8ruth@example.com",
        mobile: "92333014",
        created_at: "2020-06-18T14:30:02.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["student"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 226,
        is_active: false,
        first_name: "Accepted2569",
        last_name: "Ruth",
        full_name: "Accepted2569 Ruth",
        email: "acceptedruth@example.com",
        mobile: "92333014",
        created_at: "2020-06-15T18:29:42.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["student"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 244,
        is_active: false,
        first_name: "AcceptedConditional2525-10",
        last_name: "Ruth",
        full_name: "AcceptedConditional2525-10 Ruth",
        email: "acceptedconditional2525-10ruth@example.com",
        mobile: "92333014",
        created_at: "2020-06-18T14:30:03.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["student"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 245,
        is_active: false,
        first_name: "AcceptedConditional2525-11",
        last_name: "Ruth",
        full_name: "AcceptedConditional2525-11 Ruth",
        email: "acceptedconditional2525-11ruth@example.com",
        mobile: "92333014",
        created_at: "2020-06-18T14:30:04.000Z",
        user_type_mask: 1,
        is_student: true,
        is_mentor: false,
        is_guardian: false,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["student"],
        timezone: {
          id: 371,
          name: "America/New_York",
          offset: "-04:00",
          timezone_object: {
            id: 371,
            country_id: 236,
            name: "America/New_York",
            offset: "-04:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
    ],
  },
};

const dummyGuardian = {
  details: {
    users: [
      {
        id: 1115,
        is_active: true,
        first_name: null,
        last_name: null,
        full_name: " ",
        email: "ms1@yopmail.com",
        mobile: null,
        created_at: "2022-08-23T10:03:36.000Z",
        user_type_mask: 4,
        is_student: false,
        is_mentor: false,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["guardian"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 1089,
        is_active: true,
        first_name: null,
        last_name: null,
        full_name: " ",
        email: "janefonda@yopmail.com",
        mobile: null,
        created_at: "2022-08-18T04:10:03.000Z",
        user_type_mask: 4,
        is_student: false,
        is_mentor: false,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["guardian"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 1260,
        is_active: true,
        first_name: "123Guardian",
        last_name: "g",
        full_name: "123Guardian g",
        email: "rabika.iqbal+fg@cialfo.com.sg",
        mobile: null,
        created_at: "2023-07-11T03:42:44.000Z",
        user_type_mask: 4,
        is_student: false,
        is_mentor: false,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: "2023-07-11T03:46:57.000Z",
        user_type: ["guardian"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 841,
        is_active: true,
        first_name: "abc",
        last_name: "gah",
        full_name: "abc gah",
        email: "a@a.co",
        mobile: "666",
        created_at: "2022-02-24T10:29:07.000Z",
        user_type_mask: 4,
        is_student: false,
        is_mentor: false,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["guardian"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 883,
        is_active: true,
        first_name: "access",
        last_name: "guardian",
        full_name: "access guardian",
        email: "bryan.ling+accessguardian1@cialfo.com.sg",
        mobile: "+6511234567",
        created_at: "2022-03-11T10:25:15.000Z",
        user_type_mask: 4,
        is_student: false,
        is_mentor: false,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: "2022-03-11T10:32:03.000Z",
        user_type: ["guardian"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 553,
        is_active: true,
        first_name: "Access Guardian 2",
        last_name: "Access Guardian 2",
        full_name: "Access Guardian 2 Access Guardian 2",
        email: "charles.e.carrier+access+guardian+2@gmail.com",
        mobile: "+14348253298",
        created_at: "2020-11-11T22:46:07.000Z",
        user_type_mask: 4,
        is_student: false,
        is_mentor: false,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: "2020-11-11T22:51:31.000Z",
        user_type: ["guardian"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 554,
        is_active: true,
        first_name: "Access Guardian Counselor",
        last_name: "Access Guardian Counselor",
        full_name:
          "Access Guardian Counselor Access Guardian Counselor",
        email: "charles.e.carrier+access+guardian+admin+2@gmail.com",
        mobile: "+14348253298",
        created_at: "2020-11-11T22:48:40.000Z",
        user_type_mask: 14,
        is_student: false,
        is_mentor: true,
        is_guardian: true,
        is_admin: true,
        is_teacher: false,
        last_login: "2021-07-30T02:14:20.000Z",
        user_type: ["mentor", "guardian", "admin"],
        timezone: {
          id: 163,
          name: "Africa/Accra",
          offset: "+00:00",
          timezone_object: {
            id: 163,
            country_id: 84,
            name: "Africa/Accra",
            offset: "+00:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 555,
        is_active: true,
        first_name: "Access Guardian Counselor ONLY",
        last_name: "Access Guardian Counselor ONLY",
        full_name:
          "Access Guardian Counselor ONLY Access Guardian Counselor ONLY",
        email: "charles.e.carrier+guardian+counselor+only@gmail.com",
        mobile: "+14348253298",
        created_at: "2020-11-11T22:53:57.000Z",
        user_type_mask: 6,
        is_student: false,
        is_mentor: true,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: "2021-02-05T07:42:39.000Z",
        user_type: ["mentor", "guardian"],
        timezone: {
          id: 117,
          name: "Africa/Abidjan",
          offset: "+00:00",
          timezone_object: {
            id: 117,
            country_id: 55,
            name: "Africa/Abidjan",
            offset: "+00:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 556,
        is_active: true,
        first_name: "Access Teacher Guardian",
        last_name: "Carrier",
        full_name: "Access Teacher Guardian Carrier",
        email: "charles.e.carrier+access+teacher+guardian@gmail.com",
        mobile: "+14348253298",
        created_at: "2020-11-11T23:01:16.000Z",
        user_type_mask: 6,
        is_student: false,
        is_mentor: false,
        is_guardian: true,
        is_admin: false,
        is_teacher: true,
        last_login: "2021-02-05T07:42:12.000Z",
        user_type: ["mentor", "guardian"],
        timezone: {
          id: 163,
          name: "Africa/Accra",
          offset: "+00:00",
          timezone_object: {
            id: 163,
            country_id: 84,
            name: "Africa/Accra",
            offset: "+00:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 10,
        is_active: true,
        first_name: "Ahmed Asif",
        last_name: "Chia",
        full_name: "Ahmed Asif Chia",
        email: "valenciachia@example.com",
        mobile: "+6588292839",
        created_at: "2022-03-08T07:04:12.000Z",
        user_type_mask: 4,
        is_student: false,
        is_mentor: false,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["guardian"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 651,
        is_active: true,
        first_name: "AllenAccess",
        last_name: "Rose",
        full_name: "AllenAccess Rose",
        email: "memona+accessguardian+01@cialfo.com.sg",
        mobile: null,
        created_at: "2021-06-25T07:29:34.000Z",
        user_type_mask: 4,
        is_student: false,
        is_mentor: false,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: "2021-06-28T13:44:11.000Z",
        user_type: ["guardian"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 938,
        is_active: true,
        first_name: "Austin",
        last_name: "Guardian",
        full_name: "Austin Guardian",
        email: "austing@yopmail.com",
        mobile: null,
        created_at: "2022-06-13T09:39:44.000Z",
        user_type_mask: 4,
        is_student: false,
        is_mentor: false,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: "2022-06-14T06:24:04.000Z",
        user_type: ["guardian"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 637,
        is_active: true,
        first_name: "Basharat",
        last_name: "Gaurdian",
        full_name: "Basharat Gaurdian",
        email: "basharat+gaurdian@cialfo.com.sg",
        mobile: "1234567890",
        created_at: "2021-03-16T10:42:17.000Z",
        user_type_mask: 4,
        is_student: false,
        is_mentor: false,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["guardian"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 575,
        is_active: true,
        first_name: "CC-4201 Admin Guardian PW",
        last_name: "CC-4201 Admin Guardian PW",
        full_name:
          "CC-4201 Admin Guardian PW CC-4201 Admin Guardian PW",
        email: "charles.e.carrier+guardian+admin+pw@gmail.com",
        mobile: "+14348253298",
        created_at: "2020-11-13T06:26:46.000Z",
        user_type_mask: 14,
        is_student: false,
        is_mentor: true,
        is_guardian: true,
        is_admin: true,
        is_teacher: false,
        last_login: "2020-11-13T06:31:13.000Z",
        user_type: ["mentor", "guardian", "admin"],
        timezone: {
          id: 163,
          name: "Africa/Accra",
          offset: "+00:00",
          timezone_object: {
            id: 163,
            country_id: 84,
            name: "Africa/Accra",
            offset: "+00:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 574,
        is_active: true,
        first_name: "CC-4202 Access Guardian Counselor",
        last_name: "CC-4202 Access Guardian Counselor",
        full_name:
          "CC-4202 Access Guardian Counselor CC-4202 Access Guardian Counselor",
        email:
          "charles.e.carrier+cc-4201-guardian-counselor-pw-2@gmail.com",
        mobile: "+14348253298",
        created_at: "2020-11-13T06:21:57.000Z",
        user_type_mask: 6,
        is_student: false,
        is_mentor: true,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: "2020-11-13T06:25:21.000Z",
        user_type: ["mentor", "guardian"],
        timezone: {
          id: 163,
          name: "Africa/Accra",
          offset: "+00:00",
          timezone_object: {
            id: 163,
            country_id: 84,
            name: "Africa/Accra",
            offset: "+00:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 563,
        is_active: true,
        first_name: "CC-4202 Access Guardian Counselor",
        last_name: "CC-4202 Access Guardian Counselor",
        full_name:
          "CC-4202 Access Guardian Counselor CC-4202 Access Guardian Counselor",
        email:
          "charles.e.carrier+cc-4202-guardian-counselor@gmail.com",
        mobile: "+14348253298",
        created_at: "2020-11-12T11:54:28.000Z",
        user_type_mask: 6,
        is_student: false,
        is_mentor: true,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: "2020-11-13T01:58:54.000Z",
        user_type: ["mentor", "guardian"],
        timezone: {
          id: 117,
          name: "Africa/Abidjan",
          offset: "+00:00",
          timezone_object: {
            id: 117,
            country_id: 55,
            name: "Africa/Abidjan",
            offset: "+00:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 561,
        is_active: true,
        first_name: "CC-4202 Guardian",
        last_name: "CC-4202 Guardian",
        full_name: "CC-4202 Guardian CC-4202 Guardian",
        email: "charles.e.carrier+cc-4202-guardian@gmail.com",
        mobile: "+14348253298",
        created_at: "2020-11-12T11:44:37.000Z",
        user_type_mask: 4,
        is_student: false,
        is_mentor: false,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: "2020-11-13T01:55:09.000Z",
        user_type: ["guardian"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 570,
        is_active: true,
        first_name: "CC-4202 Guardian",
        last_name: "CC-4202 Guardian",
        full_name: "CC-4202 Guardian CC-4202 Guardian",
        email: "charles.e.carrier+cc-4201-guardian-pw@gmail.com",
        mobile: null,
        created_at: "2020-11-13T04:33:59.000Z",
        user_type_mask: 4,
        is_student: false,
        is_mentor: false,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: null,
        user_type: ["guardian"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 565,
        is_active: true,
        first_name: "CC-4202 Guardian Admin",
        last_name: "CC-4202 Guardian Admin",
        full_name: "CC-4202 Guardian Admin CC-4202 Guardian Admin",
        email: "charles.e.carrier+cc-4201-guardian-admin@gmail.com",
        mobile: "+14348253298",
        created_at: "2020-11-12T12:19:34.000Z",
        user_type_mask: 4,
        is_student: false,
        is_mentor: false,
        is_guardian: true,
        is_admin: false,
        is_teacher: false,
        last_login: "2020-11-13T02:04:20.000Z",
        user_type: ["guardian"],
        timezone: {
          id: 332,
          name: "Asia/Singapore",
          offset: "+08:00",
          timezone_object: {
            id: 332,
            country_id: 200,
            name: "Asia/Singapore",
            offset: "+08:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
      {
        id: 572,
        is_active: true,
        first_name: "CC-4202 Guardian Teacher",
        last_name: "CC-4202 Guardian Teacher",
        full_name:
          "CC-4202 Guardian Teacher CC-4202 Guardian Teacher",
        email:
          "charles.e.carrier+cc-4202-guardian-teacher-pw@gmail.com",
        mobile: "+14348253298",
        created_at: "2020-11-13T04:42:26.000Z",
        user_type_mask: 6,
        is_student: false,
        is_mentor: false,
        is_guardian: true,
        is_admin: false,
        is_teacher: true,
        last_login: "2020-11-13T06:12:52.000Z",
        user_type: ["mentor", "guardian"],
        timezone: {
          id: 148,
          name: "Africa/Addis_Ababa",
          offset: "+03:00",
          timezone_object: {
            id: 148,
            country_id: 71,
            name: "Africa/Addis_Ababa",
            offset: "+03:00",
            created_at: null,
            updated_at: null,
          },
        },
      },
    ],
  },
};

const dummyConfiguration = {
  details: {
    enable_stu_onboarding_phone_no_question: true,
    enable_guardian_info_onboarding: true,
    auto_recommendation_sending_allowed: false,
    allow_parchment_activation: false,
    allow_common_app_activation: false,
    enable_direct_apply_ucas: true,
    can_student_sign_up: true,
    enable_connections: true,
    enable_captcha: false,
    enable_cdocs: false,
    enable_onboarding_questions: true,
    enable_pa_authorization: true,
    enable_cialfo_scholarship: true,
    enable_bookmark_page: true,
    show_ucas_password_to_student: false,
    enable_surveys: true,
    enable_broadcast: true,
  },
};

const dummyProducts = {
  details: {
    products: [
      {
        id: 3,
        name: "AchieveWorks® Learning \u0026 Productivity",
        provider: "Human eSources",
        type: "Assessment",
        created_at: "2018-04-27T07:39:17.000Z",
        is_active: true,
      },
      {
        id: 2,
        name: "AchieveWorks® Personality",
        provider: "Human eSources",
        type: "Assessment",
        created_at: "2018-04-27T07:39:17.000Z",
        is_active: true,
      },
      {
        id: 4,
        name: "AchieveWorks® Personality Intelligences",
        provider: "Human eSources",
        type: "Assessment",
        created_at: "2018-04-27T07:39:17.000Z",
        is_active: true,
      },
      {
        id: 5,
        name: "Power School Integration",
        provider: "Power School",
        type: "Power school",
        created_at: "2018-07-24T14:12:07.000Z",
        is_active: true,
      },
      {
        id: 6,
        name: "Veracross",
        provider: "Veracross",
        type: "Veracross",
        created_at: "2018-07-24T14:12:07.000Z",
        is_active: true,
      },
      {
        id: 7,
        name: "ISAMS Integration",
        provider: "ISAMS",
        type: "Isams",
        created_at: "2018-08-17T08:44:34.000Z",
        is_active: true,
      },
      {
        id: 8,
        name: "Engage Integration",
        provider: "Engage",
        type: "Engage",
        created_at: "2018-08-17T08:46:02.000Z",
        is_active: true,
      },
      {
        id: 9,
        name: "Managebac Integration",
        provider: "Managebac",
        type: "Managebac",
        created_at: "2018-09-26T07:19:10.000Z",
        is_active: true,
      },
      {
        id: 12,
        name: "Parchment",
        provider: "Parchment",
        type: "Parchment",
        created_at: "2018-11-27T09:26:29.000Z",
        is_active: true,
      },
      {
        id: 13,
        name: "Zapier Integration",
        provider: "zapier",
        type: "Zapier",
        created_at: "2019-01-29T15:47:10.000Z",
        is_active: true,
      },
      {
        id: 14,
        name: "Common App",
        provider: "Common App",
        type: "Common app",
        created_at: "2019-07-15T09:39:02.000Z",
        is_active: true,
      },
      {
        id: 15,
        name: "Blackbaud Integration",
        provider: "Blackbaud",
        type: "Blackbaud",
        created_at: "2019-08-16T11:38:34.000Z",
        is_active: true,
      },
      {
        id: 16,
        name: "Sisense Integration",
        provider: "Sisense",
        type: "Sisense",
        created_at: "2021-03-18T03:14:02.000Z",
        is_active: true,
      },
    ],
  },
};

export function* requestGetClients(action) {
  try {
    const { params, resolve } = action;
    const clientsSelector = state => state.clients;
    const { data: currentClients, params: currentParams } =
      yield select(clientsSelector);
    const shouldLoadMore =
      currentClients.length > 0 &&
      _.isEqual(_.omit(currentParams, ["page_no"]), params);

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;

    const requestParams = { ...params };
    requestParams["page_no"] = page;
    const response = dummyResult;
    resolve && resolve();
    yield put(
      ClientsCreators.getClientsSuccess(
        response.details,
        requestParams,
      ),
    );
  } catch (error) {
    yield put(ClientsCreators.getClientsFailure());
  }
}

export function* requestGetCounselors(action) {
  try {
    const { params, resolve } = action;
    const counselorsSelector = state => state.clients.counselors;
    const { data: currentCounselors, params: currentParams } =
      yield select(counselorsSelector);
    const shouldLoadMore =
      currentCounselors.length > 0 &&
      _.isEqual(_.omit(currentParams, ["page_no"]), params);

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;

    const requestParams = { ...params };
    requestParams["page_no"] = page;
    const response = dummyCounselors;
    resolve && resolve();
    yield put(
      ClientsCreators.getCounselorsSuccess(
        response.details,
        requestParams,
      ),
    );
  } catch (error) {
    yield put(ClientsCreators.getCounselorsFailure());
  }
}

export function* requestGetGuardians(action) {
  try {
    const { params, resolve } = action;
    const guardiansSelector = state => state.clients.guardians;
    const { data: currentGuardians, params: currentParams } =
      yield select(guardiansSelector);
    const shouldLoadMore =
      currentGuardians.length > 0 &&
      _.isEqual(_.omit(currentParams, ["page_no"]), params);

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;

    const requestParams = { ...params };
    requestParams["page_no"] = page;
    const response = dummyGuardian;
    resolve && resolve();
    yield put(
      ClientsCreators.getGuardiansSuccess(
        response.details,
        requestParams,
      ),
    );
  } catch (error) {
    yield put(ClientsCreators.getGuardiansFailure());
  }
}

export function* requestGetSchoolStudents(action) {
  try {
    const { params, resolve } = action;
    const studentsSelector = state => state.clients.students;
    const { data: currentStudents, params: currentParams } =
      yield select(studentsSelector);
    const shouldLoadMore =
      currentStudents.length > 0 &&
      _.isEqual(_.omit(currentParams, ["page_no"]), params);

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;

    const requestParams = { ...params };
    requestParams["page_no"] = page;
    const response = dummyStudents;
    resolve && resolve();
    yield put(
      ClientsCreators.getSchoolStudentsSuccess(
        response.details,
        requestParams,
      ),
    );
  } catch (error) {
    yield put(ClientsCreators.getSchoolStudentsFailure());
  }
}

export function* requestGetProducts(action) {
  try {
    const { params, resolve } = action;
    const productsSelector = state => state.clients.products;
    const { data: currentProducts, params: currentParams } =
      yield select(productsSelector);
    const shouldLoadMore =
      currentProducts.length > 0 &&
      _.isEqual(_.omit(currentParams, ["page_no"]), params);

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;

    const requestParams = { ...params };
    requestParams["page_no"] = page;
    const response = dummyProducts;
    resolve && resolve();
    yield put(
      ClientsCreators.getProductsSuccess(
        response.details,
        requestParams,
      ),
    );
  } catch (error) {
    yield put(ClientsCreators.getProductsFailure());
  }
}

function* requestConfiguration() {
  try {
    const response = dummyConfiguration;

    yield put(
      ClientsCreators.getConfigurationSuccess(response.details),
    );
  } catch (error) {
    yield put(ClientsCreators.getConfigurationFailure());
  }
}
