import { all } from "redux-saga/effects";
import { watchClientsRequests } from "./clients.sagas";
import { watchDirectApplyRequests } from "./direct-apply.sagas";
import { watchUcasApplicationFormRequests } from "./uacs-application-form.sagas";
import { watchPartnersRequests } from "./partners.sagas";
import { watchNewPartnersRequests } from "./new-partners.sagas";
import { watchPartnerFeaturesRequests } from "./partner-features.sagas";
import { watchUniversitiesRequests } from "./universities.sagas";
import { watchUserRequests } from "./user.sagas";
import { watchUsersRequests } from "./users.sagas";
import { watchStudentsRequests } from "./students.sagas";
import { watchDirectApplyProgramRequests } from "./direct-apply-program.sagas";
import { watchTeamRequests } from "./team.sagas";
import { watchMiscRequests } from "./misc.sagas";
import { watchDistrictsRequests } from "./districts.sagas";

export function* rootSaga() {
  yield all([
    watchClientsRequests(),
    watchDirectApplyRequests(),
    watchUcasApplicationFormRequests(),
    watchPartnersRequests(),
    watchNewPartnersRequests(),
    watchPartnerFeaturesRequests(),
    watchUniversitiesRequests(),
    watchUserRequests(),
    watchUsersRequests(),
    watchStudentsRequests(),
    watchTeamRequests(),
    watchDirectApplyProgramRequests(),
    watchMiscRequests(),
    watchDistrictsRequests(),
  ]);
}
