import React from 'react';
import {
  Select
} from '../../components';

const SelectField = (props) => {
  const { input, meta, ...rest } = props;

  return (
    <Select
      {...input}
      {...rest}
      error={meta.touched && meta.error}
      onChange={(value) => input.onChange(value)}
      onBlur={(value) => input.onBlur()}
      onFocus={() => input.onFocus()}
    />
  );
}

export default SelectField;
