import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "src/components";

const DistrictDetailsScreen = props => {
  const { history } = props;
  const [districtName, setDistrictName] = useState("");
  const [logo, setLogo] = useState(null);
  const [tenants, setTenants] = useState([
    { label: "abc", value: "abc" },
  ]);

  return (
    <>
      <div className="flex bg-white px-6 py-4 items-center">
        <FontAwesomeIcon
          onClick={() => history.goBack()}
          icon={["fas", "chevron-left"]}
          className="text-base mr-4 cursor-pointer"
        />
        <div className="text-lg font-bold text-black">
          District name
        </div>
      </div>
      <div className="flex flex-col h-full bg-blue-50 items-center overflow-scroll">
        <div
          className="border border-gray-300 rounded-lg  my-6 bg-white px-6 py-4"
          style={{ width: 500 }}
        >
          <div className="text-base font-bold text-black pb-3">
            Overview
          </div>

          <div className="flex flex-col">
            <div className="text-sm text-gray-750 pb-2">Name</div>

            <input
              type="text"
              className={
                "input-field focus:outline-none focus:border-blue-700 placeholder-gray-500 "
              }
              onChange={event => {
                setDistrictName(event.target.value);
              }}
              value={districtName}
              maxLength={150}
            />
          </div>

          <div className="flex pb-4 pt-7 items-center">
            <div className="text-base font-bold text-black ">
              Logo
            </div>
            {logo && (
              <div
                className="text-sm text-blue-700 cursor-pointer pl-4 font-bold"
                onClick={() => setLogo(null)}
              >
                Edit
              </div>
            )}
          </div>

          <div>
            {logo ? (
              <div className="px-6 py-4 border border-gray-300 rounded-lg ">
                <img
                  src={URL.createObjectURL(logo)}
                  alt="logo"
                  className="w-16 h-16"
                />
              </div>
            ) : (
              <>
                <input
                  accept=".jpg,.jpeg,.png"
                  name="document"
                  id="document"
                  type="file"
                  className={"hidden"}
                  onChange={e => {
                    setLogo(e.target.files[0]);
                  }}
                />
                <label htmlFor="document" className="cursor-pointer">
                  <div
                    className={
                      "flex justify-center items-center h-32 bg-blue-50 border border-dashed border-blue-700 mt-3"
                    }
                  >
                    <div className={"btn-primary cursor-pointer"}>
                      <FontAwesomeIcon
                        icon={["fas", "plus"]}
                        className="mr-1"
                      />
                      Upload logo
                    </div>
                  </div>
                </label>
              </>
            )}
          </div>
          <div className="mt-4">
            <button className="btn-primary" onClick={() => {}}>
              Update
            </button>
          </div>
        </div>

        <div
          className="border border-gray-300 rounded-lg  my-6 bg-white px-6 py-4"
          style={{ width: 500 }}
        >
          <div className="text-base font-bold text-black pb-4">
            Clients
          </div>
          <div className="flex flex-col">
            <div className="text-sm text-gray-750 pb-2">
              Add a new client
            </div>
            <div className="flex items-center">
              <Select
                value={tenants}
                options={[
                  { label: "abc", value: "abc" },
                  { label: "def", value: "def" },
                ]}
                onChange={selectedTenants =>
                  setTenants(selectedTenants)
                }
                isMulti
              />
              <div className="pl-4">
                <button className="btn-secondary" onClick={() => {}}>
                  <FontAwesomeIcon
                    icon={["fas", "plus"]}
                    className="mr-1"
                  />
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(DistrictDetailsScreen);
