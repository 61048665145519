import DistrictsScreen from "./districts-screen";
import { connect } from "react-redux";
import { DistrictsCreators } from "../../redux/actions";

function mapStateToProps(state) {
  return {
    districts: state.districts.data,
    total: state.districts.total,
    matched: state.districts.matched,
    isNextPageLoading: state.districts.loading,
  };
}

const mapDispatchToProps = {
  getDistricts: DistrictsCreators.getDistrictsRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DistrictsScreen);
