const initialState = {
  directApply: {
    data: [],
    transactions: [],
    refunds: [],
    downloadUrl: null,
    loading: false,
    downloading: null,
    selected: null,
    selectedIndex: -1,
    loadingSelected: false,
    matched: 0,
    total: 0,
    pastTotal: 0,
    countries: [],
    courses: [],
    programs: [],
    shouldReloadPrograms: false,
    program: null,
    isLoadingProgram: false,
    programLoadFailed: false,
    isUpdating: false,
    isDeleting: false,
    isCreatingCopy: false,
    isSavingDraft: false,
    isActivating: false,
    isDeactivating: false,
    updateSuccess: false,
    deleteSuccess: false,
    createProgramCopySuccess: false,
    saveDraftSuccess: false,
    activateSuccess: false,
    deactivateSuccess: false,
    programTypes: [],
    programTypeContent: null,
    currencies: [],
    universities: [],
    brochures: [],
    documentTypes: [],
    banners: [],
    intakeRounds: [],
    isFetchingProgramTypes: false,
    isFetchingProgramTypeContent: false,
    isFetchingCurrencies: false,
    isFetchingUniversities: false,
    isFetchingBrochures: false,
    isFetchingDocumentTypes: false,
    isFetchingBanners: false,
    isFetchingIntakeRounds: false,
    results: [],
    status: [],
    feeStatus: [],
    feeWaived: [],
    schools: [],
    refundActivities: {},
    activities: [],
    isLoadingActivities: false,
    notes: "",
    isLoadingNotes: false,
    isSavingNotes: false,
    applicationResults: [],
    params: {
      page_no: 1,
    },
    transactionsParams: {
      page_no: 1,
    },
    transaction: null,
    isFetchingTransaction: false,
    transcationFetchSuccessful: false,
    programDownloadUrl: null,
    programsFilterUniversities: [],
    programsFilterStatuses: [],
    programsFilterRounds: [],
    programSubmissionTypes: [],
    statusList: [],
    resultList: [],
    programCountries: [],
    programCitizenships: [],
    programPartners: [],
    isUcasChoicesFilled: false,
    ucasQuickSubmit: {},
    taggingQuickSubmit: {},
    studentsCount: 0,
    documentsLIst: [],
    followUpsList: {},
    isFollowUpLoading: false,
  },
  partnerSearchState: {
    searchText: "",
    filters: {},
    set: false,
  },
  newPartnersSearchState: {
    searchText: "",
    filters: {},
    set: false,
  },
  directApplyListState: {
    transactionFilters: {
      searchText: "",
      sortBy: {
        column: "",
        identifier: "",
        direction: "",
      },
      filters: {},
    },
    applicationFilters: {
      searchText: "",
      sortBy: {
        column: "submitted_at",
        identifier: "submitted_at",
        direction: "desc",
      },
      tab: "submitted",
      filters: {},
      isPast: false,
    },
  },
  directApplyProgram: {
    program: null,
    isLoadingProgram: false,
    programLoadFailed: false,
    isUpdating: false,
    isDeleting: false,
    isCreatingCopy: false,
    isSavingDraft: false,
    isActivating: false,
    isDeactivating: false,
    isApproving: false,
    isDeclining: false,
    updateSuccess: false,
    deleteSuccess: false,
    createProgramCopySuccess: false,
    saveDraftSuccess: false,
    activateSuccess: false,
    deactivateSuccess: false,
    approveSuccess: false,
    declineSuccess: false,
    programTypes: [],
    programTypeContent: null,
    currencies: [],
    universities: [],
    brochures: [],
    documentTypes: [],
    banners: [],
    intakeRounds: [],
    isFetchingProgramTypes: false,
    isFetchingProgramTypeContent: false,
    isFetchingCurrencies: false,
    isFetchingUniversities: false,
    isFetchingBrochures: false,
    isFetchingDocumentTypes: false,
    isFetchingBanners: false,
    isFetchingIntakeRounds: false,
  },
  partners: {
    data: [],
    loading: false,
    selected: null,
    loadingSelected: false,
    isFetchingMergePartners: false,
    fetchMergePartnersSuccessful: false,
    isMergingPartners: false,
    mergePartnerSuccessful: false,
    mergeMessage: "",
    mergePartners: {
      originalPartner: null,
      duplicatePartner: null,
    },
    matched: 0,
    total: 0,
    params: {
      page_no: 1,
    },
    isFetchingProductOfferingOptions: false,
    productOfferingOptions: [],
    partnerCountries: [],
  },
  newPartners: {
    data: [],
    loading: false,
    selected: {},
    loadingSelected: false,
    matched: 0,
    total: 0,
    params: {
      page_no: 1,
    },
    newPartnersFilterStatuses: [],
    newPartnersFilterCountries: [],
  },
  partnerFeatures: {
    partner: {},
    users: [],
    ed_users: [],
    loading: false,
    loadingUsers: false,
  },
  team: {
    data: [],
    downloadUrl: null,
    loading: false,
    downloading: null,
    creatingTeamMember: false,
    updatingTeamMember: false,
    performingBulkAction: false,
    selected: null,
    loadingSelected: false,
    matched: 0,
    total: 0,
    params: {
      page_no: 1,
    },
  },
  users: {
    data: [],
    loading: false,
    selected: null,
    loadingSelected: false,
    matched: 0,
    total: 0,
    params: {
      page_no: 1,
    },
    editState: "view",
    editIndex: 10,
  },
  clients: {
    data: [],
    loading: false,
    selected: null,
    loadingSelected: false,
    matched: 0,
    total: 0,
    params: {
      page_no: 1,
    },

    counselors: {
      data: [],
      loading: false,
      selected: null,
      loadingSelected: false,
      matched: 0,
      total: 0,
      params: {
        page_no: 1,
      },
    },

    guardians: {
      data: [],
      loading: false,
      selected: null,
      loadingSelected: false,
      matched: 0,
      total: 0,
      params: {
        page_no: 1,
      },
    },

    students: {
      data: [],
      loading: false,
      selected: null,
      loadingSelected: false,
      matched: 0,
      total: 0,
      params: {
        page_no: 1,
      },
    },

    products: {
      data: [],
      loading: false,
      selected: null,
      loadingSelected: false,
      matched: 0,
      total: 0,
      params: {
        page_no: 1,
      },
    },

    configuration: {},
  },
  districts: {
    data: [],
    loading: false,
    selected: null,
    loadingSelected: false,
    matched: 0,
    total: 0,
    params: {
      page_no: 1,
    },
  },
  students: {
    data: [],
    loading: false,
    selected: null,
    loadingSelected: false,
    matched: 0,
    total: 0,
    params: {
      page_no: 1,
    },
  },
  universities: {
    data: [],
    representatives: [],
    fetching: false,
    fetchingRepresentatives: false,
  },
  user: {
    authToken: null,
    data: null,
    authenticating: false,
    is_admin: false,
  },
  ucasApplicationForm: {
    notes: "",
    reviewData: {},
    syncDate: "",
    loading: false,
    isEducationJobLoading: false,
    isFormJobLoading: false,
    isPersonalStatementJobLoading: false,
  },
  misc: {
    countries: [],
  },
};

export default initialState;
