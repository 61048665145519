import React, { useState } from "react";
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DocumentDownload = ({ documentName, id, fileName, downloadUrl }) => (
  <div className="flex-1 flex flex-row text-blue-700 overflow-hidden h-20">
    <div className="flex-none flex w-10 h-20 justify-center items-center bg-blue-50">
      <FontAwesomeIcon
        icon={['fas', 'file-pdf']}
        className={"text-2xl leading-none"}
      />
    </div>
    <div className="flex-1 flex flex-col ml-3 pt-2 overflow-hidden">
      <p className="text-sm text-black font-bold truncate">{documentName}</p>
      <p className="text-sm text-blue-700 font-bold truncate mt-px">{"ID"} {id}</p>
      <p className="text-xs text-blue-700 truncate">{fileName}</p>
    </div>
    {downloadUrl ? (
      <div
        onClick={() => {
          if (downloadUrl)
            window.open(downloadUrl);
        }}
        className="flex-none flex w-10 h-20 justify-center items-center cursor-pointer"
      >
        <FontAwesomeIcon
          icon={['fas', 'download']}
          className={"text-lg text-gray-750 leading-none"}
        />
      </div>
    ) : null}
  </div>
);


const BrochureSelectModal = ({ visible, onClose, onSave, brochures }) => {
  const [selectedIndex, setSelectedndex] = useState(-1);

  const onClickSave = () => {
    onSave && onSave(brochures[selectedIndex]);
  };

  return (
    <ReactModal
      isOpen={visible}
      onRequestClose={() => {}}
      ariaHideApp={false}
      className="modal"
      overlayClassName="modalOverlay"
    >
      <div className="absolute left-0 right-0 top-0 bottom-0 bg-overlay-500 flex justify-center items-center z-20">
        <div style={{width: 500}} className="rounded-lg bg-white p-3 flex flex-col relative w-460px">
          <div className={"flex flex-row justify-between"}>
            <span className="font-bold text-base text-gray-750 mb-6 pt-2 pl-2">
              {"Select an existing brochure"}
            </span>

            <FontAwesomeIcon
              onClick={onClose}
              icon={['fas', 'times']}
              className={"text-lg leading-none text-gray-750 cursor-pointer mt-2"}
            />
          </div>

          <div className={"flex flex-col pl-3"}>
            {brochures.map((brochure, index) => (
              <div
                key={index}
                onClick={() => setSelectedndex(index)}
                className={"relative flex flex-row items-center cursor-pointer mb-2"}
              >
                {selectedIndex === index ? (
                  <div className={`flex flex-none w-5 h-5 rounded-full bg-green-500 border border-green-500 justify-center items-center mr-2`}>
                    <FontAwesomeIcon icon={['fas', 'check']} className="text-white text-xs leading-none"/>
                  </div>
                ) : (
                  <div className={"w-5 h-5 rounded-full bg-white border border-black mr-2"} />
                )}
                <DocumentDownload
                  documentName={brochure.name.split(".")[0]}
                  id={brochure.id}
                  fileName={brochure.name}
                />
              </div>
            ))}
          </div>

          <div className={"flex flex-row justify-end mt-3"}>
            <button onClick={onClose} className={"btn-border-primary"}>{"Cancel"}</button>
            <div className={"w-3"} />
            <button
              onClick={onClickSave}
              className={"btn-primary"}
            >
              {"Save"}
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default BrochureSelectModal;
