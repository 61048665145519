import DirectApplyTransactionsPage from "./transactions";
import { connect } from "react-redux";
import {
  DirectApplyCreators,
  DirectApplyFiltersCreators,
} from "../../../redux/actions";

function mapStateToProps(state) {
  const {
    directApply: {
      transactions,
      matched: matchedTransactions,
      total: totalTransactions,
      pastTotal: totalPastTransactions,
      transactionsUniversities,
      loading,
    },
    directApplyListState: { transactionFilters: filters },
  } = state;

  return {
    transactions,
    matchedTransactions,
    totalTransactions,
    totalPastTransactions,
    loading,
    filters,
    transactionsUniversities,
  };
}

const mapDispatchToProps = {
  getTransactions: DirectApplyCreators.getTransactionsRequest,
  downloadList: DirectApplyCreators.getDirectApplyListDownloadUrlRequest,
  postRefund: DirectApplyCreators.refundRequest,
  clearRefundActivities: DirectApplyCreators.clearRefundActivities,
  setFilters: DirectApplyFiltersCreators.setTransactionFilters,
  setSearchText:
    DirectApplyFiltersCreators.setDirectApplyTransactionsSearchText,
  getFilterUniversities:
    DirectApplyCreators.getDirectApplyFilterTransactionsUniversitiesRequest,
  setSortBy: DirectApplyFiltersCreators.setDirectApplyTransactionsSortBy,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DirectApplyTransactionsPage);
