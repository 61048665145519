import React from "react";

const FlowChart = (props) => {
  switch(props.programTypeId) {
    case 1:
      return <FlowChartPSE />
    case 2:
      return <FlowChartInternationalOneYear />
    case 3:
      return <FlowChartFoundation />
    case 4:
      return <FlowChartDirectAdmission />
    default:
      return null;
  }
}

const FlowChartFoundation = (props) => {
  return(
    <div className={"flex flex-row items-center"}>

      <div style={{ marginRight: -27 }} className={"p-5 flex flex-col justify-center rounded blue-arrow-bg pr-8"}>
        <span className="text-xs font-bold text-dark-blue-800 uppercase">HIGH SCHOOL</span>
        <span className="text-xs text-black">Graduation</span>
      </div>

      <div className="w-3" />

      <div style={{maxWidth: 285}} className={"flex flex-col justify-center rounded relative green-arrow-bg px-10"}>
        <span className="text-sm font-bold text-dark-blue-800 uppercase">FOUNDATION</span>
        <div className="bg-green-400 flex-none h-px my-1" />
        <span className="text-xs text-black">Guaranteed entry into Bachelor’s Degree with pass & good attendance</span>
      </div>

      <div className={"flex flex-col justify-center rounded light-blue-arrow-bg px-8"}>
        <span className="text-sm font-bold text-dark-blue-800 uppercase">YEAR 1</span>
        <span className="text-xs text-black">Bachelor’s Degree</span>
      </div>

    </div>
  );
};

const FlowChartInternationalOneYear = (props) => {
  return(
    <div className={"flex flex-row items-center"}>
      <div style={{ marginRight: -27 }} className={"p-5 flex flex-col justify-center rounded blue-arrow-bg pr-8"}>
        <span className="text-xs font-bold text-dark-blue-800 uppercase">HIGH SCHOOL</span>
        <span className="text-xs text-black">Graduation</span>
      </div>

      <div className="w-3" />

      <div style={{maxWidth: 285, marginRight: -13}} className={"flex flex-col justify-center rounded relative green-arrow-bg px-10"}>
        <span className="text-sm font-bold text-dark-blue-800 uppercase">INTERNATIONAL YEAR 1</span>
        <div className="bg-green-400 h-px my-1" />
        <span className="text-xs text-black">Guaranteed entry into Bachelor’s Degree with pass & good attendance</span>
      </div>

      <div className="w-3" />

      <div className={"flex flex-col justify-center rounded light-blue-arrow-bg px-8"}>
        <span className="text-sm font-bold text-dark-blue-800 uppercase">YEAR 2</span>
        <span className="text-xs text-black">Bachelor’s Degree</span>
      </div>
    </div>
  );
};

const FlowChartDirectAdmission = (props) => {
  return(
    <div className={"flex flex-row items-center"}>
      <div style={{ marginRight: -27 }} className={"p-5 flex flex-col justify-center rounded blue-arrow-bg pr-8"}>
        <span className="text-xs font-bold text-dark-blue-800 uppercase">HIGH SCHOOL</span>
        <span className="text-xs text-black">Graduation</span>
      </div>

      <div className="w-3" />

      <div style={{maxWidth: 285, marginRight: -25}} className={"flex flex-col justify-center rounded relative green-arrow-bg px-10"}>
        <span className="text-sm font-bold text-dark-blue-800 uppercase">Year 1 Bachelor’s Degree</span>
        <div className="bg-green-400 h-px my-1" />
        <span className="text-xs text-black">Direct Admission</span>
      </div>
    </div>
  );
};

const FlowChartPSE = (props) => {
  return(
    <div className={"flex flex-row items-center"}>
      <div style={{ marginRight: -27 }} className={"p-5 flex flex-col justify-center rounded blue-arrow-bg pr-8"}>
        <span className="text-xs font-bold text-dark-blue-800 uppercase">HIGH SCHOOL</span>
        <span className="text-xs text-black">Graduation</span>
      </div>

      <div className="w-3" />

      <div style={{maxWidth: 285, marginRight: -25}} className={"flex flex-col justify-center rounded relative green-arrow-bg px-10"}>
        <span className="text-sm font-bold text-dark-blue-800 uppercase">Pre-sessional English</span>
        <div className="bg-green-400 h-px my-1" />
        <span className="text-xs text-black">Meet requirements</span>
      </div>

      <div className="w-3" />

      <div className={"flex flex-col justify-center rounded light-blue-big-arrow-bg px-10"}>
        <span className="text-sm font-bold text-dark-blue-800 uppercase">FOUNDATION/INTERNATIONAL YEAR 1</span>
        <div className="bg-dark-blue-800 h-px my-1" />
        <span className="text-xs text-black">Entry to program based on conditional offer</span>
      </div>
    </div>
  );
};

export default FlowChart;
