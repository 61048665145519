import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Format } from "src/helpers";
import EditAccountModal from "./edit-account";
import EditPlanModal from "./edit-plan";
import EditSubscriptionLevelModal from "./edit-subscription-level ";

const Cell = props => {
  const { label, value, hideBorder } = props;
  return (
    <div
      className={`${hideBorder ? "" : "border-b border-gray-300"} flex w-full`}
    >
      <div className="w-2/5 border-r border-gray-300 px-4 py-2 text-base text-gray-600">
        {label}
      </div>
      <div className="w-3/5 px-4 py-2 text-base font-bold">
        {value}
      </div>
    </div>
  );
};

const ClientOverviewPage = props => {
  const { clients, tenantId } = props;

  const [isEditAccountOpen, setIsEditAccountOpen] = useState(false);
  const [isEditPlanOpen, setIsEditPlanOpen] = useState(false);
  const [
    isEditSubscriptionLevelOpen,
    setIsEditSubscriptionLevelOpen,
  ] = useState(false);

  const selectedClient = clients.find(
    client => client.id === parseInt(tenantId, 10),
  );

  const {
    end_date,
    start_date,
    remaining_contract_days,
    tenant_attribute_value_use_count,
    tenant_attribute_value_student_count,
    tenant_account_status,
    tenant_name,
    tenant_subdomain,
    tenant_country,
    state,
    city,
    tenant_owner_first_name,
    tenant_owner_last_name,
    tenant_subscription_level,
    tenant_tenant_type_id,
  } = selectedClient;

  return (
    <div className="flex flex-col h-full bg-blue-50 items-center overflow-scroll">
      <div
        className="border border-gray-300 rounded-lg px-6 py-4 bg-white my-6"
        style={{ width: 500 }}
      >
        {" "}
        <div className="text-base font-bold mb-4">Overview</div>
        <div className="flex flex-col border border-gray-300 rounded-lg ">
          <Cell
            label="Subscription"
            value={`Renew : ${Format.date(end_date)} | ${remaining_contract_days}`}
          />
          <Cell
            label="Active"
            value={tenant_attribute_value_use_count}
          />
          <Cell
            label="Active Limit"
            value={
              tenant_attribute_value_student_count === "-1"
                ? "Unlimited"
                : tenant_attribute_value_student_count
            }
          />
          <Cell label="Inactive" value="" />
          <Cell label="Remaining" value="" />
          <Cell
            label="Account Status"
            value={tenant_account_status}
            hideBorder
          />
        </div>
      </div>

      <div
        className="border border-gray-300 rounded-lg px-6 py-4 bg-white my-6"
        style={{ width: 500 }}
      >
        {" "}
        <div className="flex items-center mb-4">
          <div className="text-base font-bold">Account</div>
          <div
            className="pl-4 text-blue-700 text-sm font-bold cursor-pointer"
            onClick={() => {
              setIsEditAccountOpen(true);
            }}
          >
            Edit
          </div>
        </div>
        <div className="flex flex-col border border-gray-300 rounded-lg ">
          <Cell label="Org type" value="" />
          <Cell label="Company" value={tenant_name} />
          <Cell label="Account url" value={tenant_subdomain} />
          <Cell label="Country" value={tenant_country} />
          <Cell label="State" value={state} />
          <Cell label="City" value={city} />
          <Cell
            label="Owner"
            value={
              <>
                <>{tenant_owner_first_name}</>
                <>{tenant_owner_last_name}</>
              </>
            }
          />
          <Cell label="Source" value="" hideBorder />
        </div>
      </div>

      <div
        className="border border-gray-300 rounded-lg px-6 py-4 bg-white my-6"
        style={{ width: 500 }}
      >
        {" "}
        <div className="flex items-center mb-4">
          <div className="text-base font-bold">
            Subscription Level
          </div>
          <div
            className="pl-4 text-blue-700 text-sm font-bold cursor-pointer"
            onClick={() => {
              setIsEditSubscriptionLevelOpen(true);
            }}
          >
            Edit
          </div>
        </div>
        <div className="flex flex-col border border-gray-300 rounded-lg ">
          <Cell
            label="Subscription "
            value={tenant_subscription_level}
            hideBorder
          />
        </div>
      </div>

      <div
        className="border border-gray-300 rounded-lg px-6 py-4 bg-white my-6"
        style={{ width: 500 }}
      >
        {" "}
        <div className="flex items-center mb-4">
          <div className="text-base font-bold">Plan</div>
          <div
            className="pl-4 text-blue-700 text-sm font-bold cursor-pointer"
            onClick={() => {
              setIsEditPlanOpen(true);
            }}
          >
            Edit
          </div>
        </div>
        <div className="flex flex-col border border-gray-300 rounded-lg ">
          <Cell label="Type" value={tenant_tenant_type_id} />
          <Cell label="Contract Status" value="" />
          <Cell
            label="Starting date"
            value={Format.date(start_date)}
          />
          <Cell label="Renewal date" value={Format.date(end_date)} />
          <Cell
            label="Active student limit"
            value={
              tenant_attribute_value_student_count === "-1"
                ? "Unlimited"
                : tenant_attribute_value_student_count
            }
            hideBorder
          />
        </div>
      </div>
      {isEditAccountOpen && (
        <EditAccountModal
          isOpen={isEditAccountOpen}
          onClose={() => setIsEditAccountOpen(false)}
        />
      )}
      {isEditPlanOpen && (
        <EditPlanModal
          isOpen={isEditPlanOpen}
          onClose={() => setIsEditPlanOpen(false)}
        />
      )}
      {isEditSubscriptionLevelOpen && (
        <EditSubscriptionLevelModal
          isOpen={isEditSubscriptionLevelOpen}
          onClose={() => setIsEditSubscriptionLevelOpen(false)}
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    clients: state.clients.data,
  };
}

export default connect(
  mapStateToProps,
  null,
)(withRouter(ClientOverviewPage));
