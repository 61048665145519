import React from "react";
import { Column, Table as VirtualizedTable } from "react-virtualized";
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import ReactTooltip from 'react-tooltip'
import 'react-virtualized/styles.css';
import { TableHeader } from './header'
import {
  BadgeColumn,
  BlueColumn,
  DefaultColumn,
  VerticalTwoValueColumn,
  VerticalTwoValueColumnBlack,
  VerticalTwoValueColumnBlue
} from './columns'


export const Table = (
  {
    children,
    currentRowCount,  // total number of rows currently fetched
    totalRowCount,  // total number of rows in server
    loadMoreRows, // function to be called when more rows are needed when bottom of the table is reached
    isRowLoaded,
    noRowsRenderer,
    rowClassName,
    headerHeight,
    rowHeight,
    rowGetter: rowGetterParam,
    onRowClick,
    rows, // actual data
    columns,
    sortableColumns,
    sortColumn,
    sortDirection,
  }
) => {
  const rowGetter = rowGetterParam ? rowGetterParam : ({ index }) => rows[index] || {};

  return (
    <div className="flex-1 h-full table-container">
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={totalRowCount}>
        {({ onRowsRendered, registerChild }) => {
          return (
            <AutoSizer>
              {
                ({ width, height }) => (
                  <VirtualizedTable
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    noRowsRenderer={noRowsRenderer}
                    headerHeight={46}
                    height={height}
                    rowClassName={rowClassName}
                    rowHeight={rowHeight}
                    rowGetter={rowGetter}
                    rowCount={currentRowCount}
                    onRowClick={onRowClick}
                    width={width}
                  >
                    {columns && columns.filter((column) => { return !column.exclude }).map((column, index) => {
                      const {
                        cellRenderer,
                        type,
                        valueSelector,
                        className,
                        header,
                        tooltip,
                        ...columnProps
                      } = column;

                      return (
                        <Column
                          {...columnProps}
                          headerRenderer={header.component ? header.component : (props) => {
                            const { dataKey } = props;
                            const { title, onClick } = header;
                            const isSortable = sortableColumns[dataKey] ? true : false;

                            return (
                              <TableHeader
                                {...props}
                                isSortable={isSortable}
                                isSorted={sortColumn === dataKey}
                                sortDirection={sortDirection}
                                title={title}
                                onClick={() => onClick({ dataKey, sortingKey: sortableColumns[dataKey] })}
                              />
                            );
                          }}
                          cellRenderer={cellRenderer ? cellRenderer : (props) => {
                            switch (type) {
                              case "VerticalTwoValue":
                                return <VerticalTwoValueColumn valueSelector={valueSelector} tooltip={tooltip} className={className} {...props} />
                              case "VerticalTwoValueBlack":
                                return <VerticalTwoValueColumnBlack valueSelector={valueSelector} tooltip={tooltip} className={className} {...props} />
                              case "VerticalTwoValueBlue":
                                return <VerticalTwoValueColumnBlue valueSelector={valueSelector} tooltip={tooltip} className={className} {...props} />
                              case "Tag":
                                return <BadgeColumn valueSelector={valueSelector} tooltip={tooltip} className={className} {...props} />
                              case "Blue":
                                return <BlueColumn valueSelector={valueSelector} tooltip={tooltip} className={className} {...props} />
                              case "Default": // fallthrough
                              default:
                                return <DefaultColumn valueSelector={valueSelector} tooltip={tooltip} className={className} {...props} />
                            }
                          }}
                        />
                      );
                    })}
                    {children}
                  </VirtualizedTable>
                )
              }
            </AutoSizer>
          )
        }}
      </InfiniteLoader>
      <ReactTooltip multiline={true} bodyMode />
    </div>
  );
}
