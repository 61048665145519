import React, { Component } from 'react';
import { Button, Wrapper, Menu, MenuItem, openMenu } from 'react-aria-menubutton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SectionFilter extends Component {
  componentDidMount() {
    const { openOnRender, id } = this.props;

    if (openOnRender && id)
      openMenu(id);
  }

  render() {
    const {
      filterSections,
      button,
      id,
      selectedFilterTypes
    } = this.props;

    return (
      <div className="flex-none flex flex-col">
        <Wrapper
          id={id}
          className="relative flex-1 flex"
          onSelection={this.props.onSelection}
        >
          <Button className="flex flex-row items-center focus:outline-none">
            {button}
          </Button>
          <Menu open={true} className="filter-menu py-2 w-64">
            {
              filterSections.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  {section.title ? (
                    <div className="flex flex-row items-center px-4 h-8">
                      <span className="text-sm text-gray-500">
                        {section.title}
                      </span>
                    </div>
                  ) : null}
                  <div className="flex flex-col">
                    {
                      section.filterTypes.map((filter, i) => (
                        <MenuItem key={i}
                          className={"filter-menu-item " + (selectedFilterTypes.find((selectedFilterType) => selectedFilterType.id === filter.id) ? 'active' : '')}
                          value={{...filter, icon: filter.icon || section.icon}}
                          text={filter.text}
                        >
                          <div className={"flex-none w-4 h-6 flex items-center"}>
                            <FontAwesomeIcon icon={filter.icon || section.icon} className="text-sm text-gray-750"/>
                          </div>
                          <div className={"flex-1"}>
                            <span className="text-sm text-gray-750 ml-2 leading-none">{filter.text}</span>
                          </div>
                        </MenuItem>
                      ))
                    }
                  </div>
                </div>
              ))
            }
          </Menu>
        </Wrapper>
      </div>
    );
  }
}

export default SectionFilter;
