import Testimonial from "./testimonial";
import { connect } from "react-redux";
import { formValueSelector } from 'redux-form';
const selector = formValueSelector('programContentForm');

function mapStateToProps(state) {
  const {
    directApplyProgram: {
      program: {
        student_testimonials,
      },
    },
  } = state;

  return {
    testimonial_image: selector(state, 'testimonial[image]'),
    testimonials: student_testimonials,
  };
};

export default connect(mapStateToProps)(Testimonial);
