import React from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reduxForm, Field } from "redux-form";
import { RadioBox } from "src/form-components";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

const EditSubscriptionLevelModal = props => {
  const { isOpen, onClose, handleSubmit, t } = props;

  const onSubmit = values => {
    console.log(values);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: {
          width: "550px",
          margin: "200px auto",
          outline: "none",
        },
      }}
      overlayClassName="modal-overlay"
      className="modal-content"
    >
      <div className="bg-white rounded-md py-8">
        <div
          className={
            "flex items-center flex-row justify-between mb-4 px-8"
          }
        >
          <span className="font-bold text-black text-xl">
            {t("clients.edit_subscription_level")}
          </span>

          <FontAwesomeIcon
            onClick={onClose}
            icon={["fas", "times"]}
            className={
              "text-lg leading-none text-gray-750 cursor-pointer"
            }
          />
        </div>
        <div className="px-8">
          <Field
            component={RadioBox}
            name="account_status"
            items={[
              { value: "basic", label: "Basic" },
              { value: "professional", label: "Professional" },
              { value: "access", label: "Access" },
            ]}
          />
        </div>

        <div className="flex justify-end pt-8 px-8 ">
          <button className="btn-primary ml-4" onClick={onClose}>
            {t("clients.cancel")}
          </button>
          <button
            className="btn-primary ml-4"
            type={"submit"}
            onClick={handleSubmit(onSubmit)}
          >
            {t("clients.save_changes")}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {};

export default withTranslation()(
  reduxForm({
    form: "editSubscriptionLevelForm",
    enableReinitialize: true,
  })(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(EditSubscriptionLevelModal),
  ),
);
