import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import RequestDocumentModal from "./request-document-modal";

const RequestDocument = (props) => {
  const { id, t } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="w-full pb-6 ">
        <div className="flex items-center justify-between">
          <div className="text-black text-xl font-bold ">
            {t("direct_apply.application.follow_ups.request_for_doc")}
          </div>
          <div
            className="btn-text-primary"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            {t("direct_apply.application.follow_ups.create_new")}
          </div>
        </div>
      </div>
      {isOpen && (
        <RequestDocumentModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          id={id}
        />
      )}
    </>
  );
};

export default withTranslation()(RequestDocument);
