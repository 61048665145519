import TestimonialSelectModal from "./testimonial-select-modal";
import { connect } from "react-redux";

function mapStateToProps(state) {
  const {
    directApplyProgram: {
      program: {
        student_testimonials,
      },
    },
  } = state;

  return {
    testimonials: student_testimonials,
  };
}

export default connect(mapStateToProps)(TestimonialSelectModal);
