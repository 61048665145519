import { createReducer } from 'reduxsauce'
import { UniversitiesTypes as Types, UserTypes } from '../actions';
import storeInitialState from '../store/initial-state';

export const INITIAL_STATE = storeInitialState.universities;

export const getUniversitiesFailure = (state, action) => ({ ...state, fetching: false });
export const getUniversitiesRequest = (state, action) => ({ ...state, fetching: true });
export const getUniversitiesSuccess = (state, action) => {
  const {
    univerities
  } = action;

  return {
    ...state,
    data: univerities,
    fetching: false
  };
};

export const getUniversityRepresentativesFailure = (state, action) => ({ ...state, fetchingRepresentatives: false });
export const getUniversityRepresentativesRequest = (state, action) => ({ ...state, fetchingRepresentatives: true });
export const getUniversityRepresentativesSuccess = (state, action) => {
  const {
    representatives
  } = action;

  return {
    ...state,
    representatives,
    fetchingRepresentatives: false
  };
};

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.GET_UNIVERSITIES_FAILURE]:       getUniversitiesFailure,
  [Types.GET_UNIVERSITIES_REQUEST]:       getUniversitiesRequest,
  [Types.GET_UNIVERSITIES_SUCCESS]:       getUniversitiesSuccess,

  [Types.GET_UNIVERSITY_REPRESENTATIVES_FAILURE]:       getUniversityRepresentativesFailure,
  [Types.GET_UNIVERSITY_REPRESENTATIVES_REQUEST]:       getUniversityRepresentativesRequest,
  [Types.GET_UNIVERSITY_REPRESENTATIVES_SUCCESS]:       getUniversityRepresentativesSuccess,

  [UserTypes.LOGOUT]:                     logout
};

export default createReducer(INITIAL_STATE, HANDLERS);
