import _ from 'lodash';

const resolveValueSelector = (data, selector) => {
  if (_.isString(selector)) {
    return data[selector];
  } else if (_.isFunction(selector)) {
    return selector(data);
  }
}

const getTooltipAttrs = (value = '', addTooltip = false) => {
  const obj = {};
  if (addTooltip) {
    obj['data-tip'] = value;
    obj['data-iscapture'] = true;
  }

  return obj;
}

export {
  resolveValueSelector,
  getTooltipAttrs
}