import { createReducer } from "reduxsauce";
import { ClientsTypes as Types } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.clients;

export const getClientsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getClientsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getClientsSuccess = (state, action) => {
  const {
    clientsData: { tenant_contracts, matched, total },
    params,
  } = action;

  return {
    ...state,
    data:
      params.page_no === 1
        ? tenant_contracts
        : [...state.data, ...tenant_contracts],
    matched,
    total,
    params: { ...state.params, ...params },
    loading: false,
  };
};

export const getCounselorsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getCounselorsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getCounselorsSuccess = (state, action) => {
  const {
    counselorsData: { users, matched, total },
    params,
  } = action;

  return {
    ...state,
    counselors: {
      data:
        params.page_no === 1
          ? users
          : [...state.counselors.data, ...users],
      matched,
      total,
      params: { ...state.counselors.params, ...params },
      loading: false,
    },
  };
};

export const getGuardiansFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getGuardiansRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getGuardiansSuccess = (state, action) => {
  const {
    guardiansData: { users, matched, total },
    params,
  } = action;

  return {
    ...state,
    guardians: {
      data:
        params.page_no === 1
          ? users
          : [...state.guardians.data, ...users],
      matched,
      total,
      params: { ...state.guardians.params, ...params },
      loading: false,
    },
  };
};

export const getSchoolStudentsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getSchoolStudentsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getSchoolStudentsSuccess = (state, action) => {
  const {
    studentsData: { users, matched, total },
    params,
  } = action;

  return {
    ...state,
    students: {
      data:
        params.page_no === 1
          ? users
          : [...state.students.data, ...users],
      matched,
      total,
      params: { ...state.students.params, ...params },
      loading: false,
    },
  };
};

export const getProductsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getProductsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getProductsSuccess = (state, action) => {
  const {
    productsData: { products, matched, total },
    params,
  } = action;

  return {
    ...state,
    products: {
      data:
        params.page_no === 1
          ? products
          : [...state.products.data, ...products],
      matched,
      total,
      params: { ...state.products.params, ...params },
      loading: false,
    },
  };
};

export const getConfigurationFailure = (state, action) => ({
  ...state,
});
export const getConfigurationRequest = (state, action) => ({
  ...state,
});
export const getConfigurationSuccess = (state, action) => {
  const { configuration } = action;

  return {
    ...state,
    configuration,
  };
};

export const HANDLERS = {
  [Types.GET_CLIENTS_FAILURE]: getClientsFailure,
  [Types.GET_CLIENTS_REQUEST]: getClientsRequest,
  [Types.GET_CLIENTS_SUCCESS]: getClientsSuccess,

  [Types.GET_COUNSELORS_FAILURE]: getCounselorsFailure,
  [Types.GET_COUNSELORS_REQUEST]: getCounselorsRequest,
  [Types.GET_COUNSELORS_SUCCESS]: getCounselorsSuccess,

  [Types.GET_GUARDIANS_FAILURE]: getGuardiansFailure,
  [Types.GET_GUARDIANS_REQUEST]: getGuardiansRequest,
  [Types.GET_GUARDIANS_SUCCESS]: getGuardiansSuccess,

  [Types.GET_SCHOOL_STUDENTS_FAILURE]: getSchoolStudentsFailure,
  [Types.GET_SCHOOL_STUDENTS_REQUEST]: getSchoolStudentsRequest,
  [Types.GET_SCHOOL_STUDENTS_SUCCESS]: getSchoolStudentsSuccess,

  [Types.GET_PRODUCTS_FAILURE]: getProductsFailure,
  [Types.GET_PRODUCTS_REQUEST]: getProductsRequest,
  [Types.GET_PRODUCTS_SUCCESS]: getProductsSuccess,

  [Types.GET_CONFIGURATION_FAILURE]: getConfigurationFailure,
  [Types.GET_CONFIGURATION_REQUEST]: getConfigurationRequest,
  [Types.GET_CONFIGURATION_SUCCESS]: getConfigurationSuccess,
};

export default createReducer(INITIAL_STATE, HANDLERS);
