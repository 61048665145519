import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getClientsFailure: null,
  getClientsRequest: ["params", "resolve", "reject"],
  getClientsSuccess: ["clientsData", "params"],

  getCounselorsFailure: null,
  getCounselorsRequest: ["params", "resolve", "reject"],
  getCounselorsSuccess: ["counselorsData", "params"],

  getGuardiansFailure: null,
  getGuardiansRequest: ["params", "resolve", "reject"],
  getGuardiansSuccess: ["guardiansData", "params"],

  getSchoolStudentsFailure: null,
  getSchoolStudentsRequest: ["params", "resolve", "reject"],
  getSchoolStudentsSuccess: ["studentsData", "params"],

  getProductsFailure: null,
  getProductsRequest: ["params", "resolve", "reject"],
  getProductsSuccess: ["productsData", "params"],


  getConfigurationFailure: null,
  getConfigurationRequest: null,
  getConfigurationSuccess: ["configuration"],
});

export const ClientsTypes = Types;
export const ClientsCreators = Creators;
