import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  clearUsers: null,
  getStudentsFailure: null,
  getStudentsRequest: ["params", "resolve", "reject"],
  getStudentsSuccess: ["studentsData", "params"],

  getStudentEmailFailure: null,
  getStudentEmailRequest: ["id"],
  getStudentEmailSuccess: ["studentData", "id"],

  createUcasAccountFailure: null,
  createUcasAccountRequest: ["id"],
  createUcasAccountSuccess: ["studentData", "id"],
});

export const StudentsTypes = Types;
export const StudentsCreators = Creators;
