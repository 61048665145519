import LoginPage from './login';
import { connect } from 'react-redux';
import { UserCreators } from '../../redux/actions';

function mapStateToProps(state) {
  return {
    authenticationInProgess: state.user.loading
  };
}

const mapDispatchToProps = {
  authenticationRequest: UserCreators.authenticationRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
