import React from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reduxForm, Field } from "redux-form";
import { RadioBox, TextInputField } from "src/form-components";
import { DatePickerField } from "src/components";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Checkbox } from "antd";

const CheckboxField = props => {
  const { input } = props;

  return (
    <Checkbox onChange={e => input.onChange(e.target.checked)}>
      Unlimited
    </Checkbox>
  );
};

const EditPlanModal = props => {
  const { isOpen, onClose, handleSubmit, t } = props;

  const onSubmit = values => {
    console.log(values);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: {
          width: "550px",
          margin: "200px auto",
          outline: "none",
        },
      }}
      overlayClassName="modal-overlay"
      className="modal-content"
    >
      <div className="bg-white rounded-md py-8">
        <div
          className={
            "flex items-center flex-row justify-between mb-4 px-8"
          }
        >
          <span className="font-bold text-black text-xl">
            {t("clients.edit_plan")}
          </span>

          <FontAwesomeIcon
            onClick={onClose}
            icon={["fas", "times"]}
            className={
              "text-lg leading-none text-gray-750 cursor-pointer"
            }
          />
        </div>
        <div className="px-8">
          <div className="flex flex-col">
            <div className="font-bold text-black text-base pb-4">
              {t("clients.plan_type")}
            </div>
            <Field
              component={RadioBox}
              name="account_status"
              items={[
                { value: "free", label: "Free" },
                { value: "subscription", label: "Subscription" },
              ]}
            />
          </div>

          <div className="flex flex-col">
            <div className="font-bold text-black text-base pt-4 pb-2">
              {t("clients.active_student_limit")}
            </div>

            <div className="text-base text-gray-750 pb-1">
              {t("clients.amount_of_student")}
            </div>
            <Field
              name="amount_of_student"
              component={TextInputField}
              type="number"
              className="pb-2"
            />

            <Field name="is_unlimited" component={CheckboxField} />
          </div>

          <div className="flex flex-col">
            <div className="font-bold text-black text-base py-4">
              {t("clients.time")}
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col ">
                <div className="text-sm text-gray-750 pb-1">
                  {t("clients.start_date")}
                </div>
                <Field
                  name="start_date"
                  component={DatePickerField}
                  placeholder={"Start date"}
                  readOnly
                />
              </div>
              <div className="flex flex-col pr-5">
                <div className="text-sm text-gray-750 pb-1">
                  {t("clients.end_date")}
                </div>
                <Field
                  name="end_date"
                  component={DatePickerField}
                  placeholder={"End date"}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end pt-8 px-8 ">
          <button className="btn-primary ml-4" onClick={onClose}>
            {t("clients.cancel")}
          </button>
          <button
            className="btn-primary ml-4"
            type={"submit"}
            onClick={handleSubmit(onSubmit)}
          >
            {t("clients.save_changes")}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {};

export default withTranslation()(
  reduxForm({
    form: "editPlanForm",
    enableReinitialize: true,
  })(connect(mapStateToProps, mapDispatchToProps)(EditPlanModal)),
);
