import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    getCountriesFailure: null,
    getCountriesRequest: [],
    getCountriesSuccess: ["countries"],
});

export const MiscTypes = Types;
export const MiscCreators = Creators;
