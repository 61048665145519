import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCoffee,
  faSignInAlt,
  faArrowLeft,
  faGraduationCap,
  faSearch,
  faSortDown,
  faSortUp,
  faEnvelope,
  faBan,
  faSave,
  faUser,
  faEye,
  faTimes,
  faChevronDown,
  faCheck,
  faUserCog,
  faSignOutAlt,
  faQuestionCircle,
  faAddressCard,
  faChevronUp,
  faPhone,
  faInfoCircle,
  faTimesCircle,
  faSchool,
  faExclamationCircle,
  faExclamationTriangle,
  faSearchMinus,
  faCheckCircle,
  faFileInvoice,
  faFile,
  faFileAlt,
  faPlus,
  faLocationArrow,
  faCalendarCheck,
  faCertificate,
  faUserCheck,
  faUsers,
  faDollarSign,
  faHourglassEnd,
  faUserClock,
  faCalendarWeek,
  faCoins,
  faCalendarAlt,
  faImages,
  faCircle,
  faPenAlt,
  faLanguage,
  faPassport,
  faDownload,
  faFilePdf,
  faGripHorizontal,
  faEdit,
  faExchangeAlt,
  faThumbsUp,
  faUpload,
  faChevronLeft,
  faChevronRight,
  faShoppingBag,
  faUniversity,
  faCalendarMinus,
  faClipboardCheck,
  faHandPointRight,
  faCodeBranch,
  faGripVertical,
  faGripLines,
  faAlignLeft,
  faMinus,
  faTrashCan,
  faCircleInfo,
  faFileLines,
  faUndo,
  faSpinner,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCircle as farCircle,
  faSquare,
  faCopy,
  faCircleXmark,
  faClock,
} from "@fortawesome/free-regular-svg-icons";

import {
  faSparkles,
  faFileEdit,
  faHandsHoldingDollar,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faEye as falEye,
  faEyeSlash as falEyeSlash,
  faUser as falUser,
  faUsers as falUsers,
  faArrowRightFromBracket as falArrowRightFromBracket,
} from "@fortawesome/pro-light-svg-icons";

import {
  faExclamationTriangle as farExclamationTriangle,
  faCircleInfo as farCircleInfo,
  faSparkles as farSparkles,
  faUniversity as farUniversity,
  faBars as farBars,
  faCheckCircle as farCheckCircle,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faGraduationCap as fadGraduationCap,
  faUsers as fadUsers,
  faBuildingUser as fadBuildingUser,
} from "@fortawesome/pro-duotone-svg-icons";

library.add(
  fab,
  faCheckSquare,
  faCoffee,
  faSignInAlt,
  faArrowLeft,
  faGraduationCap,
  faSearch,
  faSortDown,
  faSortUp,
  faEnvelope,
  faBan,
  faSave,
  faUser,
  faEye,
  faTimes,
  faChevronDown,
  faCheck,
  faUserCog,
  faSignOutAlt,
  faQuestionCircle,
  faAddressCard,
  faChevronUp,
  faPhone,
  faInfoCircle,
  faTimesCircle,
  faSchool,
  faExclamationCircle,
  faExclamationTriangle,
  faSearchMinus,
  faCheckCircle,
  faFileInvoice,
  faFile,
  faPlus,
  faLocationArrow,
  faFileAlt,
  faCalendarCheck,
  faCertificate,
  faUserCheck,
  faUsers,
  faDollarSign,
  faHourglassEnd,
  faUserClock,
  faCalendarWeek,
  faCoins,
  faCalendarAlt,
  faImages,
  faCircle,
  faPenAlt,
  faLanguage,
  faPassport,
  farCircle,
  faDownload,
  faFilePdf,
  faGripHorizontal,
  faSquare,
  faEdit,
  faExchangeAlt,
  faCopy,
  faThumbsUp,
  faUpload,
  faChevronLeft,
  faChevronRight,
  faShoppingBag,
  faUniversity,
  faCalendarMinus,
  faClipboardCheck,
  faHandPointRight,
  faCodeBranch,
  faGripVertical,
  faGripLines,
  faAlignLeft,
  faMinus,
  faSparkles,
  faFileEdit,
  faTrashCan,
  faHandsHoldingDollar,
  farExclamationTriangle,
  faXmark,
  faCircleXmark,
  faCircleInfo,
  farCircleInfo,
  falEye,
  falEyeSlash,
  faFileLines,
  faClock,
  farSparkles,
  farUniversity,
  faUndo,
  faSpinner,
  farBars,
  falUsers,
  falUser,
  falArrowRightFromBracket,
  fadGraduationCap,
  fadUsers,
  farCheckCircle,
  fadBuildingUser,
  faUserSecret,
);
