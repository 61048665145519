import React from "react";
import Loader from "react-loader-spinner";
import { Filter, Table } from "../../../components";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Format } from "../../../helpers";
import { isEqual, debounce } from "lodash";
import "react-virtualized/styles.css";

const sortableColumns = {
  name: "partner_requests.name",
  country: "countries.name",
  applied: "partner_requests.created_at",
  status: "partner_requests.status",
};

class NewRequestsPage extends React.Component {
  state = {
    searchText: "",
    sortColumn: "applied",
    sortDirection: "desc",
  };

  componentDidMount() {
    this.props.clearNewPartners();
    this._load();
    this._fetchFilters();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.newPartnersSearchState !==
        this.props.newPartnersSearchState &&
      !isEqual(
        prevProps.newPartnersSearchState,
        this.props.newPartnersSearchState,
      )
    ) {
      if (
        prevProps.newPartnersSearchState.searchText !==
        this.props.newPartnersSearchState.searchText
      ) {
        this._searchLoad();
      } else {
        this._load(true);
      }
    }
  }

  _fetchFilters = () => {
    const { getFilterCountries, getFilterStatuses } = this.props;
    getFilterCountries();
    getFilterStatuses();
  };

  _load = loadMore => {
    const { searchText, prevParams, sortColumn, sortDirection } =
      this.state;
    const { newPartnersSearchState } = this.props;

    let countryFilter =
      newPartnersSearchState.filters.country &&
      newPartnersSearchState.filters.country.length > 0
        ? {
            country_id: newPartnersSearchState.filters.country.map(
              country => country.id,
            ),
          }
        : {};

    let statusFilter =
      newPartnersSearchState.filters.statuses &&
      newPartnersSearchState.filters.statuses.length > 0
        ? {
            status: newPartnersSearchState.filters.statuses.map(
              status => status.name,
            ),
          }
        : {};

    const params = {
      search_text: searchText,
      conditions: {
        ...countryFilter,
        ...statusFilter,
      },
      sort_params: [
        {
          sort_by: sortableColumns[sortColumn],
          sort_direction: sortDirection,
        },
      ],
    };

    if (loadMore === true || !isEqual(params, prevParams)) {
      if (!loadMore)
        this.setState({ prevParams: params, isLoading: true });

      return new Promise((resolve, reject) => {
        this.props.getPartners(params, resolve, reject);
      });
    } else {
      return new Promise(function (resolve, reject) {
        resolve();
      });
    }
  };

  _loadMore = params => {
    return this._load(true);
  };

  _searchLoad = debounce(this._load, 1000);

  _search = searchText => {
    this.props.setSearchText(searchText);
    this.setState(
      {
        searchText,
      },
      this._searchLoad,
    );
  };

  _rowGetter = ({ index }) => this.props.partners[index] || {};

  _rowClassName = ({ index }) => {
    if (index < 0) {
      return "";
    } else {
      return "app-table-row";
    }
  };

  _onClickHeader = ({ dataKey }) => {
    const { sortColumn, sortDirection } = this.state;

    this.setState(
      {
        sortColumn: dataKey,
        sortDirection:
          sortColumn === dataKey
            ? sortDirection === "desc"
              ? "asc"
              : "desc"
            : "asc",
      },
      this._load,
    );
  };

  _getBadgeClass = status => {
    const statusClassNames = {
      approved: "badge-success",
      pending: "badge-warning",
      declined: "badge-error",
      new: "badge-primary",
    };
    return statusClassNames[status];
  };

  _openFeaturesScreen = institute => {
    this.setState({
      featuresScreenVisible: true,
      selectedInstitute: institute,
    });
  };

  _closeFeaturesScreen = () => {
    this.setState({ featuresScreenVisible: false });
  };

  render() {
    const {
      partners,
      matched,
      total,
      isNextPageLoading,
      setFilters,
      newPartnersFilterCountries,
      newPartnersFilterStatuses,
      newPartnersSearchState,
      history,
    } = this.props;
    const { sortColumn, sortDirection } = this.state;
    const hasNextPage = matched > partners.length;
    const isRowLoaded = ({ index }) =>
      !hasNextPage || index < partners.length;
    const loadMoreRows = isNextPageLoading
      ? () => {}
      : this._loadMore;

    const filterOptions = [
      {
        id: "filters",
        title: "",
        filterTypes: [
          {
            id: "statuses",
            text: "Status",
            icon: ["fas", "clipboard-check"],
            isMulti: true,
            options: newPartnersFilterStatuses.map(status => ({
              id: status.id,
              text: status.display_name,
              name: status.name,
            })),
          },
          {
            id: "country",
            text: "Country",
            icon: ["fas", "location-arrow"],
            isMulti: true,
            isSearchable: true,
            options: newPartnersFilterCountries.map(country => ({
              id: country.id,
              text: country.name,
            })),
          },
        ],
      },
    ];

    return (
      <div className="flex-1 flex flex-col">
        <div className="pl-6">
          <Filter
            searchPlaceholder="Search by organization name"
            selectedFilters={newPartnersSearchState.filters}
            searchText={newPartnersSearchState.searchText}
            filterOptions={filterOptions}
            onChangeSearchText={this._search}
            onSelection={filters => {
              setFilters(filters);
            }}
          />
        </div>

        <div class="h-px bg-gray-300 mb-3"></div>

        <div className="applicants-list flex items-center h-42px pl-6">
          {total === 0 && isNextPageLoading ? null : (
            <div className="flex-none font-bold mr-2">
              {matched} Applicants of {total}
            </div>
          )}
        </div>
        <div className="mt-2" />
        <Table
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          totalRowCount={matched}
          rows={partners}
          currentRowCount={partners.length}
          headerHeight={46}
          rowHeight={62}
          rowClassName={this._rowClassName}
          sortableColumns={sortableColumns}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onRowClick={({ rowData, event, index }) => {
            history.push({
              pathname: `/new-requests/${rowData.id}`,
              state: "new",
            });
          }}
          noRowsRenderer={() => {
            return isNextPageLoading ? (
              <div className="flex flex-col justify-center items-center h-full w-full">
                <Loader
                  type="Oval"
                  color="grey"
                  height={50}
                  width={50}
                />
              </div>
            ) : null;
          }}
          columns={[
            {
              dataKey: "name",
              width: 350,
              flexGrow: 1,
              type: "Blue",
              valueSelector: rowData => ({
                value: rowData.name,
              }),
              header: {
                title: "Organization",
                onClick: this._onClickHeader,
              },
            },
            {
              dataKey: "country",
              width: 200,
              flexGrow: 1,
              type: "Default",
              valueSelector: rowData => ({
                value: rowData.country,
              }),
              header: {
                title: "Country",
                onClick: this._onClickHeader,
              },
            },
            {
              dataKey: "applicant",
              width: 350,
              flexGrow: 1,
              type: "VerticalTwoValue",
              disableSort: true,
              valueSelector: rowData => ({
                topValue: rowData.applicant,
                bottomValue: rowData.applicant_email,
              }),
              header: {
                title: "Applicant",
                onClick: this._onClickHeader,
              },
            },
            {
              dataKey: "applied",
              width: 200,
              type: "Default",
              valueSelector: rowData => ({
                value: Format.date_d_m_y(rowData.sign_up),
              }),
              header: {
                title: "Applied Date",
                onClick: this._onClickHeader,
              },
            },
            {
              dataKey: "status",
              width: 200,
              type: "Tag",
              className: rowData =>
                this._getBadgeClass(rowData.status),
              valueSelector: rowData => ({
                value: rowData.status_name,
              }),
              header: {
                title: "Status",
                onClick: this._onClickHeader,
              },
            },
            {
              dataKey: "",
              width: 200,
              type: "Default",
              disableSort: true,
              cellRenderer: ({ rowData, cellData }) => {
                return (
                  <div className="flex justify-start items-center">
                    <div
                      onClick={event => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    >
                      <button
                        className="outline-none bg-transparent text-blue-700 font-bold"
                        onClick={() =>
                          this.props.history.push({
                            pathname: `/new-requests/${rowData.id}`,
                            state: "new",
                          })
                        }
                      >
                        View
                      </button>
                    </div>
                  </div>
                );
              },
              header: {
                title: "Action",
                onClick: this._onClickHeader,
              },
            },
          ]}
        />
        {isNextPageLoading && partners.length > 0 ? (
          <div
            className={
              "absolute bottom-0 right-0 left-0 self-center pointer-events-none flex-none flex flex-col justify-center items-center p-1"
            }
          >
            <Loader
              type="ThreeDots"
              color="grey"
              height={75}
              width={75}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(withRouter(NewRequestsPage));
