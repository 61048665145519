import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

const UcasCTA = ({
  t,
  application,
  ucasQuickSubmit,
  isUCASAccountCreated,
  openModal,
}) => {
  const {
    onboarding_complete: isOnboardingComplete,
    jobs_running: hasJobsRunning,
  } = ucasQuickSubmit || {};

  if (isOnboardingComplete) {
    return null;
  }

  const buttonClass = hasJobsRunning
    ? "btn-border-primary mb-4"
    : "button-outline-secondary mb-4";
  const buttonClickHandler = hasJobsRunning ? undefined : openModal;
  const buttonDisabled = hasJobsRunning;

  const renderTooltip = () => (
    <ReactTooltip
      id="tooltip-Completes-right"
      aria-haspopup="true"
      place="right"
      effect="solid"
      arrowColor="transparent"
    >
      <p>
        {
          "Student has indicated they want their own UCAS account. Click the checkbox 'I have entered the student's UCAS email and password against the student'"
        }
      </p>
    </ReactTooltip>
  );

  return (
    isUCASAccountCreated && (
      <>
        <button
          className={buttonClass}
          onClick={buttonClickHandler}
          disabled={buttonDisabled}
        >
          {t(
            "direct_apply_apps.quick_submit.account_and_onboarding.use_student_account_cta",
          )}
        </button>
        {!hasJobsRunning &&
          !application.is_ucas_credential_updated &&
          isUCASAccountCreated &&
          renderTooltip()}
      </>
    )
  );
};

const mapStateToProps = state => ({
  ucasQuickSubmit: state.directApply.ucasQuickSubmit,
  application: state.directApply.selected,
});

export default withTranslation()(connect(mapStateToProps)(UcasCTA));
