import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  clearPartnerFeatures:  null,

  getPartnerFeaturesFailure:  null,
  getPartnerFeaturesRequest:  ['partnerId'],
  getPartnerFeaturesSuccess:  ['data', 'partnerId'],

  updatePartnerFeaturesFailure:  null,
  updatePartnerFeaturesRequest:  ['partner', 'partnerId'],
  updatePartnerFeaturesSuccess:  null
});

export const PartnerFeaturesTypes = Types;
export const PartnerFeaturesCreators = Creators;