import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CompletedCTA from "../completed-cta";
import MarkAsCompleteCTA from "../mark-as-complete-cta";
import { APPLICATION_SECTION } from "src/constants/applications.constants";

const CompleteAutomation = ({
  t,
  taggingQuickSubmit: { tagging_complete, tagging_job_status },
  ucasQuickSubmit,
}) => {
  if (tagging_complete) {
    return (
      <CompletedCTA
        status={tagging_complete}
        type={APPLICATION_SECTION.TAGGING}
        tagging
      />
    );
  } else if (
    !tagging_job_status ||
    ["Pending", "Running"].includes(tagging_job_status)
  ) {
    return null;
  } else if (ucasQuickSubmit?.jobs_running) {
    return (
      <>
        <button disabled={true} className={"btn-border-primary mb-6"}>
          {t(
            "direct_apply_apps.quick_submit.account_and_onboarding.mark_as_complete_cta",
          )}
        </button>
      </>
    );
  } else {
    return (
      <MarkAsCompleteCTA
        status={tagging_complete}
        type={APPLICATION_SECTION.TAGGING}
        tagging
      />
    );
  }
};

const mapStateToProps = state => ({
  ucasQuickSubmit: state.directApply.ucasQuickSubmit,
  taggingQuickSubmit: state.directApply.taggingQuickSubmit,
  application: state.directApply.selected,
});

export default withTranslation()(
  connect(mapStateToProps)(CompleteAutomation),
);
