import { API } from '../helpers';
import { SERVER_URL } from '../config';
import axios from 'axios';

let uni_search = {cancelRequest: undefined}
const CancelToken = axios.CancelToken;

export const getUniversities = (authToken, searchText) => {
  if(uni_search.cancelRequest) {
    uni_search.cancelRequest() 
  }
  var q = API.request({
    method: 'get',
    url: `${SERVER_URL}/api/v1/admins/partners/search_partner?q=` + searchText,
    headers: {
      authentication_token: authToken,
    },
    cancelToken: new CancelToken((c) =>{
      uni_search.cancelRequest = c;
    }),
    silent: true
  });
  return q
}

export const getUniversiyRepresentatives = (authToken, universityId) => {
  return API.request({
    method: 'get',
    url: `${SERVER_URL}/api/v1/admins/partners/representatives?id=` + universityId,
    headers: {
      authentication_token: authToken
    }
  });
}
