import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DirectApplyCreators } from "src/redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Format } from "src/helpers";
import ReactModal from "react-modal";
import { Select } from "src/components";
import { DatePickerInput } from "rc-datepicker";
import { isEmpty } from "lodash";
import ReactTooltip from "react-tooltip";

const DOCUMENT_UPLOAD = "document_upload";

const requestedTypeHeading = (type) => {
  return type === DOCUMENT_UPLOAD
    ? "Documents requested"
    : "Information requested";
};

const FollowUp = (props) => {
  const {
    data,
    heading,
    badgeClassName,
    isOpen,
    openDetails,
    setSelectedFollowUpDetails,
  } = props;
  const [expand, setExpand] = useState(isOpen || false);
  return (
    <>
      <div
        className={`flex flex-row justify-between items-center pt-4 cursor-pointer ${
          expand ? "" : "pb-4 border-b border-gray-300"
        }`}
        onClick={() => setExpand(!expand)}
      >
        <div className={`${badgeClassName}`}>
          {heading} ({data.length})
        </div>

        <FontAwesomeIcon
          icon={expand ? ["fas", "chevron-up"] : ["fas", "chevron-down"]}
          className="text-base text-gray-750"
        />
      </div>
      <div className={`flex flex-col ${expand ? "" : "hidden"}`}>
        {data.map((d) => (
          <>
            <div
              className="flex flex-row py-2 border-b border-gray-300 cursor-pointer"
              onClick={() => {
                setSelectedFollowUpDetails(d);
                openDetails();
              }}
            >
              <div className="flex items-center bg-gray-50 px-2 mr-3">
                {d.type === DOCUMENT_UPLOAD ? (
                  <FontAwesomeIcon
                    icon={["fas", "file-lines"]}
                    className="text-base text-gray-500"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={["fas", "circle-info"]}
                    className="text-base text-gray-500"
                  />
                )}
              </div>
              <div className="flex flex-col w-full">
                <div className="flex justify-between items-center w-full">
                  <div className="flex items-center">
                    <span className="text-base font-bold">
                      {requestedTypeHeading(d.type)}
                    </span>
                    <div className="badge-yellow ml-2">
                      For {d.request_from}
                    </div>
                  </div>

                  {d.seen_at ? (
                    <div data-tip data-for="user_check">
                      <FontAwesomeIcon
                        icon={["fas", "user-check"]}
                        className="text-base text-gray-500"
                      />
                      <ReactTooltip
                        id="user_check"
                        aria-haspopup="true"
                        place="bottom"
                        effect={"solid"}
                        arrowColor={"transparent"}
                      >
                        <p className="text-xs mb-0">Viewed</p>
                      </ReactTooltip>
                    </div>
                  ) : (
                    <div data-tip data-for="clock">
                      <FontAwesomeIcon
                        icon={["far", "clock"]}
                        className="text-base text-gray-500"
                      />
                      <ReactTooltip
                        id="clock"
                        aria-haspopup="true"
                        place="bottom"
                        effect={"solid"}
                        arrowColor={"transparent"}
                      >
                        <p className="text-xs mb-0">Sent</p>
                      </ReactTooltip>
                    </div>
                  )}
                </div>
                <div className="text-gray-500 text-xs">
                  {Format.datetime(d.created_at, "DD MMM, YYYY")}
                </div>
                <div className="text-sm text-gray-750 line-clamp-2">
                  {d.note}
                </div>
                <div className="flex flex-wrap pt-2">
                  {d.document_types.map((document) => (
                    <div className="badge-info mr-2 mb-2">{document.name}</div>
                  ))}
                  {heading === "Pending" && (
                    <div className="badge-yellow mr-2 mb-2">
                      Due by {Format.datetime(d.due_date, "DD MMM, YYYY")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

const RequestedDocumentsListing = (props) => {
  const { getFollowUps, id, followUpsList, t, getDocumentList, documentsList } =
    props;
  const { Completed, Pending, Expired } = followUpsList;
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [selectedFollowUp, setSelectedFollowUp] = useState({});
  useEffect(() => {
    getFollowUps(id);
    getDocumentList();
  }, []);

  const isFollowUpsAvailable =
    !isEmpty(followUpsList) &&
    (Pending.length > 0 || Completed.length > 0 || Expired.length > 0);

  return (
    <>
      {isFollowUpsAvailable && (
        <>
          <div className="flex flex-col display-section pt-0 mb-8">
            {Pending && Pending.length > 0 && (
              <div>
                <FollowUp
                  data={Pending}
                  heading={"Pending"}
                  badgeClassName={"badge-warning"}
                  isOpen
                  t={t}
                  openDetails={() => setIsDetailsOpen(true)}
                  setSelectedFollowUpDetails={setSelectedFollowUp}
                />
              </div>
            )}
            {Completed && Completed.length > 0 && (
              <div>
                <FollowUp
                  data={Completed}
                  heading={"Completed"}
                  badgeClassName={"badge-success"}
                  t={t}
                  openDetails={() => setIsDetailsOpen(true)}
                  setSelectedFollowUpDetails={setSelectedFollowUp}
                />
              </div>
            )}
            {Expired && Expired.length > 0 && (
              <div>
                <FollowUp
                  data={Expired}
                  heading={"Expired"}
                  badgeClassName={"badge-secondary "}
                  t={t}
                  openDetails={() => setIsDetailsOpen(true)}
                  setSelectedFollowUpDetails={setSelectedFollowUp}
                />
              </div>
            )}
          </div>
          <ReactModal
            isOpen={isDetailsOpen}
            style={{
              content: {
                width: "500px",
                margin: "100px auto 0",
                outline: "none",
              },
            }}
            overlayClassName="modal-overlay"
            className="modal-content"
          >
            <div className="bg-white p-8 rounded-md">
              <div className={"flex items-center flex-row justify-between"}>
                <span className="font-bold text-black text-xl">
                  {requestedTypeHeading(selectedFollowUp.type)}
                </span>

                <FontAwesomeIcon
                  onClick={() => setIsDetailsOpen(false)}
                  icon={["fas", "times"]}
                  className={
                    "text-lg leading-none text-gray-750 cursor-pointer"
                  }
                />
              </div>

              <div className="flex flex-col">
                <div className="text-gray-500 text-sm">
                  {Format.datetime(selectedFollowUp.created_at, "DD MMM, YYYY")}
                </div>
                {selectedFollowUp.document_types?.length > 0 && (
                  <div>
                    <div className="text-sm text-gray-750 pb-1 pt-4">
                      {t("direct_apply.application.follow_ups.document_req")}
                    </div>
                    <Select
                      value={selectedFollowUp.document_types?.map(
                        (document) => ({
                          label: document.name,
                          value: document.id,
                        })
                      )}
                      options={documentsList?.map((document) => ({
                        label: document.name,
                        value: document.id,
                      }))}
                      isMulti
                      isDisabled
                    />
                  </div>
                )}
              </div>

              <div className="flex flex-col">
                <div className="text-sm text-gray-750 pb-1 pt-4">
                  {t("direct_apply.application.follow_ups.message_for_student")}
                </div>
                <textarea
                  className={`text-area-field bg-gray-100`}
                  value={selectedFollowUp.note}
                  readOnly
                />
              </div>

              <div className="flex flex-col">
                <div className="text-sm text-gray-750 pb-1 pt-4">
                  {t("direct_apply.application.follow_ups.due_date")}
                </div>
                <DatePickerInput
                  value={selectedFollowUp.due_date}
                  inputReadOnly={true}
                  disabled
                  displayFormat="DD MMM"
                />
              </div>

              {selectedFollowUp.answer && (
                <div className="flex flex-col">
                  <div className="text-sm text-gray-750 pb-1 pt-4">
                    Response from student
                  </div>
                  <textarea
                    className={`text-area-field bg-gray-100`}
                    value={selectedFollowUp.answer}
                    readOnly
                  />
                </div>
              )}
            </div>
          </ReactModal>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    followUpsList: state.directApply.followUpsList,
    documentsList: state.directApply.documentsList,
  };
};

const mapDispatchToProps = {
  getFollowUps: DirectApplyCreators.getFollowUpsRequest,
  getDocumentList: DirectApplyCreators.getDocumentListRequest,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(RequestedDocumentsListing)
);
