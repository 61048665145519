import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CompleteAutomation from "./complete-automation";
import FillFormCTA from "./fill-tagging-form-cta";
import ShowLastStatus from "../show-last-status";

const FillForm = ({ t, taggingQuickSubmit }) => {
  return (
    <div
      style={{ width: "20%" }}
      className=" flex flex-col border rounded bg-white mx-2 relative"
    >
      <div className="border-b text-base font-bold p-4 text-center truncate">
        {`${t("direct_apply_apps.quick_submit.fill_tagging_form.title")} - KC`}
      </div>
      <div className="flex flex-col items-center p-4">
        <CompleteAutomation />
        <FillFormCTA />
        <div className="mt-6" />
        <ShowLastStatus
          time={taggingQuickSubmit?.tagging_completed_at}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    taggingQuickSubmit: state.directApply.taggingQuickSubmit,
  };
}

export default withTranslation()(connect(mapStateToProps)(FillForm));
