/* eslint-disable react/prop-types */
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichTextInputField = props => {
  const {
    input,
    meta: { error, touched },
    placeHolder,
    readOnly,
  } = props;
  const showError = touched && error;

  return (
    <div className="flex flex-col flex-1">
      <div>
        <ReactQuill
          theme="snow"
          value={input.value}
          onChange={event => {
            input.onChange(event);
          }}
          readOnly={readOnly}
          placeholder={placeHolder}
        />
      </div>

      {showError ? (
        <span className="input-field-error-text">{error}</span>
      ) : null}
    </div>
  );
};

export { RichTextInputField };
