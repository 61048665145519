import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { DirectApplyCreators } from "../../../../redux/actions";
import TaggingHeader from "./tagging/tagging-header";
import FillTaggingForm from "./tagging/fill-tagging-form";

const QuickSubmit = props => {
  const { application, taggingQuickSubmit } = props;

  const [expand, setExpand] = useState(false);

  useEffect(() => {
    props.getTaggingQuickSubmit(application?.id);
  }, []);

  useEffect(() => {
    if (taggingQuickSubmit?.status === "Incomplete") {
      setExpand(true);
    } else {
      setExpand(false);
    }
  }, [taggingQuickSubmit]);

  return (
    <div className="my-5 mx-2">
      <TaggingHeader expand={expand} setExpand={setExpand} />
      <div
        className={`flex justify-center h-auto bg-blue-50 display-section p-6 ${
          expand ? "rounded-t-none" : "hidden"
        }`}
      >
        <FillTaggingForm />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    taggingQuickSubmit: state.directApply.taggingQuickSubmit,
    application: state.directApply.selected,
  };
};

const mapDispatchToProps = {
  getTaggingQuickSubmit: DirectApplyCreators.getTaggingQuickSubmit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuickSubmit);
