import PictureSelectModal from "./picture-select-modal";
import { connect } from "react-redux";

function mapStateToProps(state) {
  const {
    directApplyProgram: {
      banners,
    }
  } = state;

  return {
    banners,
  };
}

export default connect(mapStateToProps)(PictureSelectModal);
