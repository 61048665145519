import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import Loader from "react-loader-spinner";
import { Select, TextArea } from "../../../components";

class DeclinePartnerModal extends Component {
  state = {
    declineReason: null,
    declineNote: "",
    noteRequired: false,
  };

  _onDecline = () => {
    const { declineReason, declineNote } = this.state;
    if (declineNote === "") {
      this.setState({
        noteRequired: true,
      });
    } else {
      this.props.onDecline({
        declineReason,
        declineNote,
      });
    }
  };

  render() {
    const { visible, onClose, partner, showLoader } = this.props;
    const { declineReason } = this.state;

    if (!partner) return null;

    return (
      <Modal
        isOpen={visible}
        style={{
          content: {
            width: "600px",
            margin: "130px auto",
            outline: "none",
          },
        }}
        contentLabel="Example Modal"
        overlayClassName="modal-overlay"
        className="modal-content"
      >
        <div className="bg-white px-5 py-5 rounded-md">
          <div className="flex justify-between">
            <span className="font-bold text-xl">Decline Applicant</span>
            <div
              className="cursor-pointer"
              onClick={() => {
                onClose();
              }}
            >
              <FontAwesomeIcon icon="times" className="text-xl" />
            </div>
          </div>
          <div className="flex flex-col mt-5 w-full">
            <div className="flex flex-row bg-yellow-50 p-4">
              <FontAwesomeIcon
                icon={["fas", "exclamation-triangle"]}
                className="text-lg text-yellow-800 mr-2"
              />
              <p className="text-sm text-black">
                You are about to decline an applicant. This action is
                irreversible. A decline notification would be sent to the{" "}
                {partner.name}{" "}
              </p>
            </div>

            <div className="flex items-center mt-3">
              <div style={{ width: 150 }} className="">
                <p className="text-sm text-gray-750">Select Reason</p>
              </div>

              <div className="flex-1">
                <Select
                  options={[
                    { label: "Information mismatch", value: 1 },
                    { label: "Already a part of Explore", value: 2 },
                    { label: "Institution does not exist", value: 3 },
                  ]}
                  onChange={(selectedReason) =>
                    this.setState({ declineReason: selectedReason })
                  }
                  value={declineReason}
                />
              </div>
            </div>

            <div className="flex mt-3">
              <div style={{ width: 150 }} className="mt-2">
                <p className="text-sm text-gray-750">Add a note</p>
                {this.state.noteRequired ? (
                  <p
                    className="text-sm text-gray-750 note-required"
                    style={{ color: "red" }}
                  >
                    (required)
                  </p>
                ) : (
                  ""
                )}
              </div>

              <div className="flex-1">
                <TextArea
                  onChange={(event) =>
                    this.setState({ declineNote: event.target.value })
                  }
                  required
                />
              </div>
            </div>

            <div className="flex flex-row justify-end mt-4">
              <button
                onClick={this._onDecline}
                className="px-3 py-2 cursor-pointer flex items-center justify-center rounded h-36px text-white bg-red-800"
              >
                <FontAwesomeIcon icon={["fas", "envelope"]} className="mr-1" />
                Decline Applicant
              </button>
            </div>
          </div>
        </div>
        {showLoader ? (
          <div className="flex justify-center items-center z-20 absolute inset-0 bg-gray-200">
            <Loader type="Oval" color="grey" height={50} width={50} />
          </div>
        ) : null}
      </Modal>
    );
  }
}

DeclinePartnerModal.defaultProps = {
  onClose: () => {},
};

export default DeclinePartnerModal;
