import React, { Component } from "react";
import Switch from "react-switch";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PROGRAM_STATUS } from "../../constants";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { TextInput, Select } from "../";

const rangeOptions = _.range(1, 11).map((v) => ({
  label: v,
  value: v,
}));

const additionalRequirementsSelector = formValueSelector(
  "programAdditionalRequirementsForm"
);

const Tooltip = ({ title, text, tooltipId }) => {
  return (
    <span className="relative tool-tip-container">
      <div className="tool-tip-element">
        <FontAwesomeIcon
          data-tip
          data-for={tooltipId}
          icon={["fas", "question-circle"]}
          className="text-lg text-gray-750 leading-none"
        />
        <div className="tool-tip">
          <div className="flex flex-col my-2 max-w-64">
            {title ? (
              <span className="text-lg mb-4 text-white">{title}</span>
            ) : null}
            <p className="text-sm text-white">{text}</p>
          </div>
        </div>
      </div>
    </span>
  );
};

class FormQuestionComponent extends Component {
  state = {
    isLinkedToConditional: false,
  };

  componentDidMount() {
    this._setIsLinkedToConditional();
  }

  componentDidUpdate(prevProps, prevState) {
    const { additionalRequirementsQuestions } = this.props;
    if (
      !_.isEqual(
        prevProps.additionalRequirementsQuestions,
        additionalRequirementsQuestions
      )
    ) {
      this._setIsLinkedToConditional();
    }
  }

  _setIsLinkedToConditional = () => {
    const { additionalRequirementsQuestions, index } = this.props;
    const isLinkedToConditional =
      _.findIndex(
        additionalRequirementsQuestions.filter(
          (qn) =>
            qn.value.field_type === "conditional" && qn.key < parseInt(index)
        ),
        (item) => {
          return (
            _.findIndex(
              item.value.question_meta,
              (item) => parseInt(item.child_sort_number) === index
            ) >= 0
          );
        }
      ) >= 0;
    this.setState({ isLinkedToConditional });
  };

  _getGoToValue = (value) => {
    if (value === "end" || (parseInt(value) && parseInt(value) === -1)) {
      return { value: "end", label: "End form" };
    }

    if (value === "skip" || (parseInt(value) && parseInt(value) === -2)) {
      return { value: "skip", label: "Skip" };
    }

    if (parseInt(value) && parseInt(value) >= 0) {
      const v = parseInt(value);
      return { value: v, label: `Go to question ${v + 1}` };
    }

    return null;
  };

  _renderChoices = () => {
    const { index: questionIndex, value, onChange } = this.props;
    const question_meta = value ? value.question_meta : [];
    const field_type = value ? value.field_type : "";

    switch (field_type) {
      case "checkboxes":
      case "multiple_choice":
        return (
          <div className="flex flex-col pl-2 pr-16 mt-6">
            {question_meta.map((choice, index) => (
              <div className="flex items-center mb-6">
                <div className="mr-3">
                  <FontAwesomeIcon
                    icon={[
                      "far",
                      field_type === "checkboxes" ? "square" : "circle",
                    ]}
                    className="text-gray-750"
                  />
                </div>
                <TextInput
                  value={choice.text}
                  onChange={(e) => {
                    const newChoices = _.clone(value.question_meta);
                    newChoices[index] = { text: e.target.value };

                    onChange({
                      ...value,
                      question_meta: newChoices,
                    });
                  }}
                />

                <FontAwesomeIcon
                  icon={["fas", "times"]}
                  className="text-gray-500 ml-5 cursor-pointer"
                  onClick={() => {
                    const newChoices = _.clone(value.question_meta).filter(
                      (qm, i) => i !== index
                    );

                    onChange({
                      ...value,
                      question_meta: newChoices,
                    });
                    // const newChoices = _.filter(this.state.choices, (choice, i) => i !== index);
                    // this.setState({ choices: newChoices });
                  }}
                />
              </div>
            ))}

            <div
              className="flex flex text-blue-700 text-sm font-bold items-center cursor-pointer ml-2"
              onClick={() => {
                onChange({
                  ...value,
                  question_meta: [...value.question_meta, { text: "" }],
                });
              }}
            >
              <FontAwesomeIcon icon={["fas", "plus"]} className="mr-2" />
              <span>Add more choice</span>
            </div>
          </div>
        );
      case "range":
        return (
          <div className="flex flex-row mr-8 mt-6 items-center">
            <TextInput
              type="text"
              placeholder="Range Start"
              value={question_meta[0] ? question_meta[0].from_range_label : ""}
              onChange={(event) => {
                const meta = question_meta[0] || {};

                onChange({
                  ...value,
                  question_meta: [
                    {
                      ...meta,
                      from_range_label: event.target.value,
                    },
                  ],
                });
              }}
            />

            <div className="flex-none w-24 ml-4">
              <Select
                value={
                  question_meta[0]
                    ? {
                        label: question_meta[0].from_range,
                        value: question_meta[0].from_range,
                      }
                    : null
                }
                options={rangeOptions}
                onChange={(selectedOption) => {
                  const meta = question_meta[0] || {};

                  onChange({
                    ...value,
                    question_meta: [
                      {
                        ...meta,
                        from_range: selectedOption.value,
                      },
                    ],
                  });
                }}
              />
            </div>

            <span className="mx-6">to</span>

            <TextInput
              type="text"
              value={question_meta[0] ? question_meta[0].to_range_label : ""}
              placeholder="Range End"
              onChange={(event) => {
                const meta = question_meta[0] || {};

                onChange({
                  ...value,
                  question_meta: [
                    {
                      ...meta,
                      to_range_label: event.target.value,
                    },
                  ],
                });
              }}
            />

            <div className="flex-none w-24 ml-4">
              <Select
                value={
                  question_meta[0]
                    ? {
                        label: question_meta[0].to_range,
                        value: question_meta[0].to_range,
                      }
                    : null
                }
                options={rangeOptions}
                onChange={(selectedOption) => {
                  const meta = question_meta[0] || {};

                  onChange({
                    ...value,
                    question_meta: [
                      {
                        ...meta,
                        to_range: selectedOption.value,
                      },
                    ],
                  });
                }}
              />
            </div>
          </div>
        );
      case "linear_scale":
        return (
          <div className="flex flex-row mr-8 mt-6 items-center">
            <TextInput
              type="text"
              placeholder="Range Start"
              value={question_meta[0] ? question_meta[0].from_range_label : ""}
              onChange={(event) => {
                const meta = question_meta[0] || {};

                onChange({
                  ...value,
                  question_meta: [
                    {
                      ...meta,
                      from_range_label: event.target.value,
                    },
                  ],
                });
              }}
            />

            <div className="flex-none w-24 ml-4">
              <Select
                value={
                  question_meta[0]
                    ? {
                        label: question_meta[0].from_range,
                        value: question_meta[0].from_range,
                      }
                    : null
                }
                options={rangeOptions}
                onChange={(selectedOption) => {
                  const meta = question_meta[0] || {};

                  onChange({
                    ...value,
                    question_meta: [
                      {
                        ...meta,
                        from_range: selectedOption.value,
                      },
                    ],
                  });
                }}
              />
            </div>

            <span className="mx-6">to</span>

            <TextInput
              type="text"
              value={question_meta[0] ? question_meta[0].to_range_label : ""}
              placeholder="Range End"
              onChange={(event) => {
                const meta = question_meta[0] || {};

                onChange({
                  ...value,
                  question_meta: [
                    {
                      ...meta,
                      to_range_label: event.target.value,
                    },
                  ],
                });
              }}
            />

            <div className="flex-none w-24 ml-4">
              <Select
                value={
                  question_meta[0]
                    ? {
                        label: question_meta[0].to_range,
                        value: question_meta[0].to_range,
                      }
                    : null
                }
                options={rangeOptions}
                onChange={(selectedOption) => {
                  const meta = question_meta[0] || {};

                  onChange({
                    ...value,
                    question_meta: [
                      {
                        ...meta,
                        to_range: selectedOption.value,
                      },
                    ],
                  });
                }}
              />
            </div>
          </div>
        );
      case "conditional":
        const { additionalRequirementsQuestions } = this.props;
        const questionsAfter = additionalRequirementsQuestions
          .filter((obj) => obj["key"] > questionIndex)
          .sort((a, b) => a.key - b.key)
          .map((obj) => this._getGoToValue(obj["key"]));
        const conditionalOptions =
          questionsAfter.length > 0
            ? [
                ...questionsAfter,
                this._getGoToValue("skip"),
                this._getGoToValue("end"),
              ]
            : [];

        return (
          <div className="flex flex-col pl-2 pr-4 mt-6">
            {question_meta.map((choice, index) => (
              <div className="flex items-center mb-6" key={index}>
                <div className="mr-3">
                  <FontAwesomeIcon
                    icon={[
                      "far",
                      field_type === "checkboxes" ? "square" : "circle",
                    ]}
                    className="text-gray-750"
                  />
                </div>

                <div className="flex flex-row w-full">
                  <TextInput
                    value={choice.text}
                    onChange={(e) => {
                      const newChoices = _.clone(value.question_meta);
                      newChoices[index] = {
                        ...newChoices[index],
                        text: e.target.value,
                      };

                      onChange({
                        ...value,
                        question_meta: newChoices,
                      });
                    }}
                  />
                  <div className="w-48 ml-6">
                    <Select
                      value={this._getGoToValue(choice.child_sort_number)}
                      options={conditionalOptions}
                      onChange={(e) => {
                        const newChoices = _.clone(value.question_meta);
                        newChoices[index] = {
                          ...newChoices[index],
                          child_sort_number: e.value,
                        };
                        onChange({
                          ...value,
                          question_meta: newChoices,
                        });
                      }}
                    />
                  </div>
                </div>

                <FontAwesomeIcon
                  icon={["fas", "times"]}
                  className="text-gray-500 ml-5 cursor-pointer"
                  onClick={() => {
                    const newChoices = _.clone(value.question_meta).filter(
                      (qm, i) => i !== index
                    );

                    onChange({
                      ...value,
                      question_meta: newChoices,
                    });
                  }}
                />
              </div>
            ))}

            <div
              className="flex text-blue-700 text-sm font-bold items-center cursor-pointer ml-2"
              onClick={() => {
                onChange({
                  ...value,
                  question_meta: [
                    ...value.question_meta,
                    { text: "", child_sort_number: null },
                  ],
                });
              }}
            >
              <FontAwesomeIcon icon={["fas", "plus"]} className="mr-2" />
              <span>Add more choice</span>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { value, onChange, programStatus } = this.props;
    const { isLinkedToConditional } = this.state;
    const options = [
      {
        label: "Short Answer",
        value: "string",
        icon: {
          icon: ["fas", "grip-lines"],
          className: "text-gray-750",
        },
      },
      {
        label: "Paragraph",
        value: "text",
        icon: {
          icon: ["fas", "align-left"],
          className: "text-gray-750",
        },
      },
      {
        label: "Multiple Choice",
        value: "multiple_choice",
        icon: {
          icon: ["fas", "check-circle"],
          className: "text-gray-750",
        },
      },
      {
        label: "Conditional Question",
        value: "conditional",
        icon: {
          icon: ["fas", "code-branch"],
          className: "text-gray-750",
        },
      },
      {
        label: "Checkboxes",
        value: "checkboxes",
        icon: {
          icon: ["fas", "check-square"],
          className: "text-gray-750",
        },
      },
      {
        label: "Linear Scale",
        value: "linear_scale",
        icon: {
          icon: ["fas", "grip-vertical"],
          className: "text-gray-750",
        },
      },
      {
        label: "Date",
        value: "date",
        icon: {
          icon: ["fas", "calendar-alt"],
          className: "text-gray-750",
        },
      },
      {
        label: "File Upload",
        value: "file",
        icon: {
          icon: ["fas", "file"],
          className: "text-gray-750",
        },
      },
    ];
    const toolTipData = {
      string: {
        title: "Short Answer",
        text: 'Used for asking open ended questions where you expect the recipient to only give a brief response. For example: "What courses are you signed up for next semester?" (Character Limit: 1000)',
      },
      date: {
        title: "Date",
        text: 'Used for asking recipients to select a date. For example; "What date is most convenient for us to schedule a meeting?"',
      },
      single_choice: {
        title: "Single Choice",
        text: 'Used for asking questions where you expect user to select one choice out of 2 options. For example: "Have you been refused VISA entry before?"',
      },
      multiple_choice: {
        title: "Multiple Choice",
        text: 'Used for asking questions where you expect the recipient to select one or many answer choices from many options. For example: "Which is your favourite university from the list below?"',
      },
      conditional: {
        title: "Conditional question",
        text: "Used when visibility of subsequent questions depend on the answer to the current question. Choose 'Skip' to go to the next regular question. Note that questions linked to conditional questions can not be set as required.",
      },
      linear_scale: {
        title: "Linear Scale",
        text: 'Used for asking questions where you expect the recipient to give their opinion. For example: "How would you rate our schools counselling surveys?"',
      },
      range: {
        title: "Range",
        text: 'Used for asking questions where you expect the recipient to give their opinion. For example: "How would you rate our schools counselling surveys?"',
      },
      text: {
        title: "Paragraph",
        text: 'Used for asking open ended questions or giving prompts where you expect the recipient to give a long detailed response. For example: "Please briefly elaborate on an extracurricular activity or work experience of particular significance to you." (Character Limit: 10,000)',
      },
      file: {
        title: "File Upload",
        text: 'Used for questions where students have to upload documents. Students are able to upload files of up to 5MB at a time. Only PDF documents are accepted. For example: "Please provide proof of alternative insurance coverage."',
      },
      checkboxes: {
        title: "Checkboxes",
        text: 'Used for asking questions where you expect the recipient to select one or many answer choices from many options. For example: "Which is your favourite university from the list below?"',
      },
    };
    const selectedOption = value
      ? options.find((option) => option.value === value.field_type)
      : null;

    return (
      <div className="flex flex-col border border-gray-300 rounded-lg px-8 pb-5 mb-4">
        <div className="flex flex-row justify-center">
          <div className="self-center mt-2 cursor-move">
            <FontAwesomeIcon
              icon={["fas", "grip-horizontal"]}
              className="text-gray-300"
            />
          </div>
        </div>

        <span className="text-gray-900 font-bold text-sm mt-2">
          Question {this.props.index + 1}
        </span>

        <div className="flex flex-row mt-3">
          <TextInput
            type="text"
            value={value && value.title ? value.title : ""}
            placeholder="Question Title"
            onChange={(event) => {
              onChange({
                ...value,
                title: event.target.value,
              });
            }}
          />

          <div
            className={`w-56 ml-5 ${
              programStatus !== PROGRAM_STATUS.DRAFT &&
              typeof value.id !== "undefined"
                ? "opacity-50 select-none pointer-events-none"
                : ""
            }`}
          >
            <Select
              value={selectedOption}
              options={options}
              onChange={(selectedType) => {
                if (value.field_type !== selectedType.value) {
                  onChange({
                    ...value,
                    question_meta: [],
                    field_type: selectedType.value,
                  });
                }
              }}
            />
          </div>

          <div className="self-center ml-2">
            {selectedOption ? (
              <Tooltip
                tooltipId={String(value.id)}
                title={toolTipData[selectedOption.value]?.title}
                text={toolTipData[selectedOption.value]?.text}
              />
            ) : null}
          </div>
        </div>

        {this._renderChoices()}

        <span className="h-px bg-gray-300 my-4" />

        <div className="flex justify-end items-center">
          <span className="text-sm text-black mr-3">Required</span>
          <Switch
            checked={isLinkedToConditional ? false : value.is_required}
            disabled={isLinkedToConditional}
            onChange={(isRequired) =>
              onChange({ ...value, is_required: isRequired })
            }
            onColor={"#B5C2D0"}
            onHandleColor="#007BE2"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
            height={10}
            width={30}
            className="react-switch"
            id="material-switch"
          />
          <FontAwesomeIcon
            icon={["fas", "trash-can"]}
            className="text-gray-500 cursor-pointer ml-8"
            onClick={this.props.onRemove}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const additionalRequirementsQuestions = additionalRequirementsSelector(
    state,
    "questions"
  );
  return {
    additionalRequirementsQuestions,
  };
};

const FormQuestion = connect(mapStateToProps)(FormQuestionComponent);

export { FormQuestion };
