import DirectApplicationsPage from "./applications";
import { connect } from "react-redux";
import {
  DirectApplyCreators,
  DirectApplyFiltersCreators,
} from "../../../redux/actions";

function mapStateToProps(state) {
  const {
    directApply: {
      data: applications,
      loading: isNextPageLoading,
      matched: matchedApplications,
      total: totalApplications,
      studentsCount,
      pastTotal: totalPastApplications,
      countries,
      courses,
      programs,
      results,
      status,
      feeWaived,
      schools,
      downloadUrl,
      universities,
      partners,
      programTypes,
      programSubmissionTypes,
      feeStatus,
    },
    directApplyListState: { applicationFilters: pageState },
    user: { data: userData },
  } = state;
  return {
    applications,
    matchedApplications,
    studentsCount,
    totalApplications,
    totalPastApplications,
    countries,
    courses,
    programs,
    results,
    status,
    feeWaived,
    schools,
    isNextPageLoading,
    downloadUrl,
    pageState,
    universities,
    partners,
    programTypes,
    programSubmissionTypes,
    userData,
    feeStatus,
  };
}

const mapDispatchToProps = {
  clearApplications: DirectApplyCreators.clearApplications,
  getApplications: DirectApplyCreators.getApplicationsRequest,
  getFilterCountries: DirectApplyCreators.getDirectApplyFilterCountriesRequest,
  getFilterCourses: DirectApplyCreators.getDirectApplyFilterCoursesRequest,
  getFilterPrograms: DirectApplyCreators.getDirectApplyFilterProgramsRequest,
  getFilterResults: DirectApplyCreators.getDirectApplyFilterResultsRequest,
  getFilterStatus: DirectApplyCreators.getDirectApplyFilterStatusRequest,
  getFilterFeeWaived: DirectApplyCreators.getDirectApplyFilterFeeWaivedRequest,
  getFilterSchools: DirectApplyCreators.getDirectApplyFilterSchoolsRequest,
  getProgramTypes: DirectApplyCreators.getDirectApplyFilterProgramTypesRequest,
  getProgramSubmissionTypes:
    DirectApplyCreators.getDirectApplyFilterProgramSubmissionTypesRequest,
  downloadList: DirectApplyCreators.getDirectApplyListDownloadUrlRequest,
  setTab: DirectApplyFiltersCreators.setDirectApplyTab,
  setFilters: DirectApplyFiltersCreators.setApplicationFilters,
  clearFilters: DirectApplyFiltersCreators.clearApplicationFilters,
  setSearchText:
    DirectApplyFiltersCreators.setDirectApplyApplicationsSearchText,
  setSortBy: DirectApplyFiltersCreators.setDirectApplySortBy,
  getFilterUniversities:
    DirectApplyCreators.getDirectApplyFilterUniversitiesRequest,
  getPartners: DirectApplyCreators.getDirectApplyFilterPartnersRequest,
  getPartnerSso: DirectApplyCreators.getDirectApplySsoRequest,
  getFilterFeeStatus: DirectApplyCreators.getDirectApplyFilterFeeStatusRequest,
  setIsPast: DirectApplyFiltersCreators.setDirectApplyIsPast,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DirectApplicationsPage);
