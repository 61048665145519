import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import EventBus from 'eventing-bus';
import { withTranslation } from 'react-i18next';
import { UserTypes } from '../../redux/actions';
import ResetPasswordForm from './reset-password-form';
import queryString from 'query-string';
import ImageQuoteComponent from '../../components/ImageQuoteComponent';

class ResetPasswordScreen extends Component {
  state = {
    passwordResetSuccessful: false,
    loading: false,
  }

  componentDidMount() {
    this.resetPasswordRequestSubscription = EventBus.on(UserTypes.RESET_PASSWORD_REQUEST, () => this.setState({ loading: true }));
    this.resetPasswordSuccessSubscription = EventBus.on(UserTypes.RESET_PASSWORD_SUCCESS, () => this.setState({ loading: false, passwordResetSuccessful: true }));
    this.resetPasswordFailureSubscription = EventBus.on(UserTypes.RESET_PASSWORD_FAILURE, () => this.setState({ loading: false }));
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _unsubscribeEvents = () => {
    this.resetPasswordRequestSubscription();
    this.resetPasswordSuccessSubscription();
    this.resetPasswordFailureSubscription();
  }

  _onSubmit = (values) => {
    const { password } = values;
    const queryParams = queryString.parse(this.props.location.search);
    const params = {
      reset_password_token: queryParams.reset_password_token,
      password
    };

    this.props.resetPassword(params);
  }

  render() {
    const { passwordResetSuccessful, loading } = this.state;

    if (passwordResetSuccessful)
      return <Redirect to="/sign-in" />


    return (
      <div className="flex h-full">
        <ResetPasswordForm
          onSubmit={this._onSubmit}
        />

        <div className="w-70per bg-blue-50 flex h-full relative">
          <div className="absolute bottom-0 left-0 right-0">
            <svg width={1203} height={602} viewBox="0 0 1203 602">
              <defs>
                <style>{".a{opacity:0.318;}.b{fill:#cfd9e4;}"}</style>
              </defs>
              <g className="a">
                <path className="b" d="M0,159.137,1203,0V602H0Z" />
              </g>
            </svg>
          </div>
          <div className="flex flex-col items-center justify-center flex-grow z-20 w-1/3">
            <ImageQuoteComponent quoteTitle="Quote from Karl Wirth"
              bgColor="#AADDC7"
              bgImage="person_first.svg"
              designation="CEO and Founder Evergage"
              quoteText="“Just one phenomenal customer experience can make a world of difference”"
              designationwidth="w-167px" />
          </div>
          <div className="flex flex-col items-center justify-center flex-grow z-20 w-1/3">
            <ImageQuoteComponent quoteTitle="Quote from Bill Price"
              bgColor="#DDAAC4"
              bgImage="person_second.svg"
              designation="President of Driva Solutions served on Amazon"
              quoteText="“Customer loyalty is the most powerful sales and marketing tool”"
              designationwidth="w-214px" />
          </div>
          <div className="flex flex-col items-center justify-center flex-grow z-20 w-1/3">
            <ImageQuoteComponent quoteTitle="Quote from Tom Krackeler."
              bgColor="#CFD9E4"
              bgImage="person_third.svg"
              designation="Chief Customer Officer Zuora"
              quoteText="“Make sure every single employee in your company knows precisely what value your customers are seeking”"
              designationwidth="w-167px" />
          </div>
        </div>
        {
          loading ? (
            <div className="flex justify-center items-center z-20 absolute inset-0 bg-gray-200">
              <Loader
                type="Oval"
                color="grey"
                height={50}
                width={50}
              />
            </div>
          ) : null
        }
      </div>
    );
  }
}

export default withTranslation()(ResetPasswordScreen);
