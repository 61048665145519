import NewPartnerDetailPage from "./new-request-detail";
import { connect } from "react-redux";
import { NewPartnersCreators, PartnersCreators } from "../../../redux/actions";

function mapStateToProps(state) {
  return {
    partner: state.newPartners.selected || {},
    fetchingNewPartner: state.newPartners.loadingSelected,
    existingPartner: state.partners.selected,
    fetchingExistingPartner: state.partners.loadingSelected,
  };
}

const mapDispatchToProps = {
  getNewPartnerRequest: NewPartnersCreators.getNewPartnerRequest,
  clearNewPartners: NewPartnersCreators.clearNewPartners,
  approvePartner: NewPartnersCreators.approveNewPartnerRequest,
  declinePartner: NewPartnersCreators.declineNewPartnerRequest,
  getExistingPartnerRequest: PartnersCreators.getPartnerRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPartnerDetailPage);
