import { createReducer } from "reduxsauce";
import { DirectApplyFiltersTypes as Types, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.directApplyListState;

export const clearApplicationFilters = (state, action) => INITIAL_STATE;

export const setApplicationFilters = (state, action) => {
  return {
    ...state,
    applicationFilters: {
      ...state.applicationFilters,
      filters: action.filters,
    },
  };
};

export const clearTransactionFilters = (state, action) => ({
  ...state,
  transactionFilters: {},
});

export const setTransactionFilters = (state, action) => {
  return {
    ...state,
    transactionFilters: {
      ...state.transactionFilters,
      filters: action.filters,
    },
  };
};

export const setDirectApplyApplicationsSearchText = (state, action) => ({
  ...state,
  applicationFilters: {
    ...state.applicationFilters,
    searchText: action.text,
  },
});

export const setDirectApplyTransactionsSearchText = (state, action) => ({
  ...state,
  transactionFilters: {
    ...state.transactionFilters,
    searchText: action.text,
  },
});

export const setDirectApplySortBy = (state, action) => ({
  ...state,
  applicationFilters: {
    ...state.applicationFilters,
    sortBy: action.sortBy,
  },
});

export const setDirectApplyTransactionsSortBy = (state, action) => ({
  ...state,
  transactionFilters: {
    ...state.transactionFilters,
    sortBy: action.sortBy,
  },
});

export const setDirectApplyTab = (state, action) => ({
  ...INITIAL_STATE,
  applicationFilters: {
    ...state.applicationFilters,
    tab: action.tab,
  },
});

export const setDirectApplyIsPast = (state, action) => ({
  ...state,
  applicationFilters: {
    ...state.applicationFilters,
    isPast: action.isPast,
  },
});

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CLEAR_APPLICATION_FILTERS]: clearApplicationFilters,

  [Types.SET_APPLICATION_FILTERS]: setApplicationFilters,

  [Types.CLEAR_TRANSACTION_FILTERS]: clearTransactionFilters,

  [Types.SET_TRANSACTION_FILTERS]: setTransactionFilters,

  [Types.SET_DIRECT_APPLY_APPLICATIONS_SEARCH_TEXT]:
    setDirectApplyApplicationsSearchText,

  [Types.SET_DIRECT_APPLY_SORT_BY]: setDirectApplySortBy,

  [Types.SET_DIRECT_APPLY_TRANSACTIONS_SORT_BY]:
    setDirectApplyTransactionsSortBy,

  [Types.SET_DIRECT_APPLY_TAB]: setDirectApplyTab,

  [Types.SET_DIRECT_APPLY_TRANSACTIONS_SEARCH_TEXT]:
    setDirectApplyTransactionsSearchText,

  [UserTypes.LOGOUT]: logout,

  [Types.SET_DIRECT_APPLY_IS_PAST]: setDirectApplyIsPast,
};

export default createReducer(INITIAL_STATE, HANDLERS);
