import React, { useEffect, useState } from "react";
import { ScrollSync } from "react-virtualized";
import "react-virtualized/styles.css";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import InfiniteLoader from "react-virtualized/dist/commonjs/InfiniteLoader";
import Loader from "react-loader-spinner";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VirtualizedTable, Filter } from "../../components";
import { Format } from "src/helpers";

const sortableColumns = {
  name: "name",
  country: "country",
  tenant_attribute_value_use_count:
    "tenant_attribute_value_use_count",
  owner: "owner",
  end_date: "end_date",
  joined_at: "joined_at",
  remaining: "remaining",
  account_status: "account_status",
  is_explore_activate: "is_explore_activate",
  tenant_sub_domain: "tenant_sub_domain",
  tenant_subscription_level: "tenant_subscription_level",
};
const ClientsScreen = props => {
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [prevParams, setPrevParams] = useState({});
  const [activeTab, setActiveTab] = useState("paid");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    load();
  }, []);

  const load = loadMore => {
    const params = {
      search_text: "",
      search_email: "",
      sort_params: [
        {
          sort_by: sortableColumns[sortColumn],
          sort_direction: sortDirection,
        },
      ],
    };

    if (loadMore === true || !_.isEqual(params, prevParams)) {
      if (!loadMore) setPrevParams(params);

      return new Promise((resolve, reject) => {
        props.getClients(params, resolve, reject);
      });
    } else {
      return new Promise(function (resolve) {
        resolve();
      });
    }
  };

  const loadMore = () => {
    return load(true);
  };

  const onClickHeader = ({ dataKey }) => {
    setSortColumn(dataKey);
    setSortDirection(
      sortColumn === dataKey
        ? sortDirection === "desc"
          ? "asc"
          : "desc"
        : "asc",
    );
  };

  const rowClassName = ({ index }) => {
    if (index < 0) {
      return "";
    } else {
      return "app-table-row";
    }
  };

  const getWidth = () => {
    const width = window.screen.width;

    const scrollableWidth = (width - 104) * 0.85;
    if (scrollableWidth > 1450) {
      return scrollableWidth;
    }
    return 1450;
  };

  const { clients, matched, total, isNextPageLoading } = props;
  const hasNextPage = matched > clients.length;
  const isRowLoaded = ({ index }) =>
    !hasNextPage || index < clients.length;

  const loadMoreRows = isNextPageLoading ? () => {} : loadMore;

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <div className="flex  px-6 py-4 items-center">
        <span className="text-xl font-bold text-black pr-6">
          Clients
        </span>
        <button
          className={`px-4 py-2 text-sm border rounded-20px font-bold ${activeTab === "paid" ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300"}`}
          onClick={() => setActiveTab("paid")}
        >
          Paid
        </button>

        <button
          className={`ml-4 px-4 py-2 text-sm border rounded-20px font-bold ${activeTab === "trails" ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300"}`}
          onClick={() => setActiveTab("trails")}
        >
          Trails
        </button>
      </div>

      <div className="px-6">
        <Filter
          searchPlaceholder="Search for Client name"
          selectedFilters={{}}
          searchText={searchText}
          filterOptions={[]}
          onChangeSearchText={setSearchText}
        />
      </div>

      <div className="h-px bg-gray-300 mb-3" />

      <div className="applicants-list flex my-2 items-center pl-6">
        {total === 0 && isNextPageLoading ? null : (
          <div className="font-bold">
            {matched} Clients of {total}
          </div>
        )}
      </div>

      <ScrollSync>
        {({ onScroll, scrollLeft, scrollTop }) => (
          <div
            className="flex"
            style={{ display: "flex", height: "100%" }}
          >
            <div className="table-container" style={{ width: "15%" }}>
              <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={() => {}}
                rowCount={total}
              >
                {({ onRowsRendered, registerChild }) => {
                  return (
                    <AutoSizer>
                      {({ width, height }) => (
                        <>
                          <VirtualizedTable
                            isRowLoaded={isRowLoaded}
                            totalRowCount={total}
                            rows={clients}
                            currentRowCount={clients.length}
                            headerHeight={46}
                            rowHeight={62}
                            rowClassName={index => {
                              return `${rowClassName(index)} border-r`;
                            }}
                            sortableColumns={sortableColumns}
                            sortColumn={sortColumn}
                            sortDirection={sortDirection}
                            onRowsRendered={onRowsRendered}
                            registerChild={registerChild}
                            width={width}
                            height={height}
                            onScroll={onScroll}
                            scrollTop={scrollTop}
                            scrollLeft={scrollLeft}
                            gridStyle={{ "scrollbar-width": "none" }}
                            noRowsRenderer={() => {
                              return isNextPageLoading ? (
                                <div className="flex flex-col justify-center items-center  h-full w-full">
                                  <Loader
                                    type="Oval"
                                    color="grey"
                                    height={50}
                                    width={50}
                                  />
                                </div>
                              ) : null;
                            }}
                            onRowClick={({ rowData }) => {
                              props.history.push({
                                pathname: `/tenant/${rowData.id}`,
                              });
                            }}
                            columns={[
                              {
                                dataKey: "name",
                                width: 250,
                                type: "Default",
                                disableSort: true,
                                tooltip: true,
                                valueSelector: rowData => ({
                                  value: rowData.tenant_name,
                                }),
                                header: {
                                  title: "Name",
                                  onClick: onClickHeader,
                                },
                              },
                            ]}
                          ></VirtualizedTable>
                        </>
                      )}
                    </AutoSizer>
                  );
                }}
              </InfiniteLoader>
            </div>
            <div
              className="overflow-scroll table-container"
              style={{ width: "85%" }}
            >
              <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={loadMoreRows}
                rowCount={total}
              >
                {({ onRowsRendered, registerChild }) => {
                  return (
                    <AutoSizer>
                      {({ width, height }) => (
                        <VirtualizedTable
                          isRowLoaded={isRowLoaded}
                          totalRowCount={total}
                          rows={clients}
                          currentRowCount={clients.length}
                          headerHeight={46}
                          rowHeight={62}
                          rowClassName={rowClassName}
                          headerClassName="border-l"
                          sortableColumns={sortableColumns}
                          sortColumn={sortColumn}
                          sortDirection={sortDirection}
                          onRowsRendered={onRowsRendered}
                          registerChild={registerChild}
                          width={getWidth()}
                          height={height}
                          onScroll={onScroll}
                          scrollTop={scrollTop}
                          scrollLeft={scrollLeft}
                          noRowsRenderer={() => {
                            return isNextPageLoading ? (
                              <div className="flex flex-col justify-center items-center  h-full w-full">
                                <Loader
                                  type="Oval"
                                  color="grey"
                                  height={50}
                                  width={50}
                                />
                              </div>
                            ) : null;
                          }}
                          onRowClick={({ rowData }) => {
                            props.history.push({
                              pathname: `/tenant/${rowData.id}`,
                            });
                          }}
                          columns={[
                            {
                              dataKey: "country",
                              width: 150,
                              type: "Default",
                              disableSort: true,
                              tooltip: true,
                              valueSelector: rowData => ({
                                value: rowData.tenant_country,
                              }),
                              header: {
                                title: "Country",
                                onClick: onClickHeader,
                              },
                            },

                            {
                              dataKey: "owner",
                              width: 250,
                              type: "Default",
                              disableSort: true,
                              tooltip: true,

                              cellRenderer: ({
                                rowData,
                                cellData,
                              }) => {
                                return (
                                  <div className="flex justify-start items-center">
                                    <div
                                      className="text-xs font-normal text-gray-750 truncate"
                                      data-tip={
                                        rowData.tenant_owner_first_name
                                      }
                                      data-iscapture={true}
                                    >
                                      {
                                        rowData.tenant_owner_first_name
                                      }{" "}
                                      {rowData.tenant_owner_last_name}
                                    </div>
                                  </div>
                                );
                              },
                              header: {
                                title: "Owner",
                                onClick: onClickHeader,
                              },
                            },
                            {
                              dataKey: "account_status",
                              width: 100,
                              type: "Default",
                              disableSort: true,
                              valueSelector: rowData => ({
                                value: rowData.tenant_account_status,
                              }),
                              header: {
                                title: "Status",
                                onClick: onClickHeader,
                              },
                            },
                            {
                              dataKey:
                                "tenant_attribute_value_use_count",
                              width: 130,
                              type: "Default",
                              disableSort: true,
                              valueSelector: rowData => ({
                                value:
                                  rowData.tenant_attribute_value_use_count,
                              }),
                              header: {
                                title: "Active",
                                onClick: onClickHeader,
                              },
                            },
                            {
                              dataKey: "remaining",
                              width: 120,
                              type: "Default",
                              disableSort: true,
                              valueSelector: rowData => ({
                                value:
                                  rowData.remaining_contract_days,
                              }),
                              header: {
                                title: "Remaining",
                                onClick: onClickHeader,
                              },
                            },
                            {
                              dataKey: "is_explore_activate",
                              width: 100,
                              type: "Default",
                              disableSort: true,
                              cellRenderer: ({
                                rowData,
                                cellData,
                              }) => {
                                return rowData.is_explore_activate ? (
                                  <FontAwesomeIcon
                                    icon={["far", "circle-check"]}
                                    className="text-base leading-none text-green-700"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={["far", "circle-xmark"]}
                                    className="text-base text-red-800 leading-none"
                                  />
                                );
                              },
                              valueSelector: rowData => ({
                                value: rowData.is_explore_activate,
                              }),
                              header: {
                                title: "Explore",
                                onClick: onClickHeader,
                              },
                            },
                            {
                              dataKey: "tenant_sub_domain",
                              width: 200,
                              type: "Default",
                              disableSort: true,
                              valueSelector: rowData => ({
                                value: rowData.tenant_sub_domain,
                              }),
                              header: {
                                title: "Subdomain",
                                onClick: onClickHeader,
                              },
                            },
                            {
                              dataKey: "tenant_subscription_level",
                              width: 200,
                              type: "Default",
                              disableSort: true,
                              valueSelector: rowData => ({
                                value:
                                  rowData.tenant_subscription_level,
                              }),
                              header: {
                                title: "Subscription",
                                onClick: onClickHeader,
                              },
                            },
                            {
                              dataKey: "end_date",
                              width: 100,
                              type: "Default",
                              disableSort: true,
                              valueSelector: rowData => ({
                                value: Format.date(rowData.end_date),
                              }),
                              header: {
                                title: "Renew",
                                onClick: onClickHeader,
                              },
                            },
                            {
                              dataKey: "joined_at",
                              width: 100,
                              type: "Default",
                              disableSort: true,
                              valueSelector: rowData => ({
                                value: Format.date(
                                  rowData.tenant_joined_at,
                                ),
                              }),
                              header: {
                                title: "Joined",
                                onClick: onClickHeader,
                              },
                            },
                          ]}
                        ></VirtualizedTable>
                      )}
                    </AutoSizer>
                  );
                }}
              </InfiniteLoader>
            </div>
          </div>
        )}
      </ScrollSync>
      {isNextPageLoading && clients.length > 0 ? (
        <div
          className={
            "absolute bottom-0 self-center pointer-events-none flex-none flex flex-col justify-center items-center p-1"
          }
        >
          <Loader
            type="ThreeDots"
            color="grey"
            height={75}
            width={75}
          />
        </div>
      ) : null}
    </div>
  );
};

export default withTranslation()(withRouter(ClientsScreen));
