import React from "react";
import { resolveValueSelector,getTooltipAttrs } from '../helpers';

export const BadgeColumn = (props) => {
  const { rowData, valueSelector, className, tooltip } = props;
  const value = resolveValueSelector(rowData, valueSelector).value;
  const tooltipAttrs = getTooltipAttrs(value, tooltip);

  return value ? (
    <div className="flex overflow-hidden">
      <span className={`${className(rowData)} truncate block`} {...tooltipAttrs}>{value}</span>
    </div>
  ) : null;
};