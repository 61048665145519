import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInput } from '../../../../../components';

const Courses = (props) => {
  const {
    courses,
    onBackClick,
    onCloseClick,
    partnerName,
  } = props;
  const [searchText, setSearchText] = useState("");
  const [filteredCourses, setFilteredCourses] = useState(courses);

  useEffect(() => {
    setFilteredCourses(
      searchText ?
        courses.filter(course => course.toLowerCase().includes(searchText.toLowerCase()))
      : courses
    );
  }, [searchText, courses]);

  return (
    <div style={{width: 800}} className={`fixed top-0 right-0 bottom-0 side-screen-container bg-white overflow-hidden flex flex-col`}>
      <div className={"flex-none flex flex-col justify-center h-100px px-5 border-b border-gray-500"}>
        <h1 className={"text-black text-2xl"}>Preview of your program</h1>
        <h3 className={"text-black text-sm mt-2"}>As you add and customise information, you can see the preview of how it will look like in your univsersity's program profile</h3>
      </div>

      <div className="flex flex-row h-20 items-center border-b border-gray-500">
        <div className="px-5">
          <FontAwesomeIcon onClick={onBackClick} icon={['fas', 'arrow-left']} className="text-lg text-gray-750 leading-none cursor-pointer"/>
        </div>
        <div className="flex-1 flex flex-col">
          <span className="text-lg font-bold text-gray-750">Courses</span>
          <span className="text-sm font-regular text-gray-750">{partnerName}</span>
        </div>
        <div className="px-5">
          <FontAwesomeIcon onClick={onCloseClick} icon={['fas', 'times']} className="text-lg text-gray-750 leading-none cursor-pointer"/>
        </div>
      </div>


      <div className={"flex-1 flex flex-col px-6 py-5 overflow-scroll"}>
        <span className="text-lg font-bold text-gray-750">{courses.length} Courses and Majors are available</span>
        <span className="text-xs font-regular text-black mt-2">*If you are sure about the course/major you want to take, check with the university rep to confirm your eligibility.</span>
        <div className="flex-none mt-5 mb-2 max-w-sm">
          <SearchInput
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            placeholder={"Search for a courses/major"}
          />
        </div>
        {/*Content UI*/}
        <div className="flex flex-col">

            <div className="flex flex-row flex-wrap mt-3">
              {filteredCourses.map((course, index) => (
                <div key={index} style={{ flex: "0 50%"}} className={"text-sm text-gray-750 font-bold leading-none mb-4 pr-2"}>{course}</div>
              ))}
            </div>
        </div>

      </div>
    </div>
  );
};

export default Courses;
