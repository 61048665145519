import React, { Component } from 'react';

class FileInputField extends Component {
  _onChange = (e) => {
    const { input: { onChange } } = this.props
    onChange(e.target.files[0])
  }

  render(){
    const {
      input,
      meta: { error, touched },
      error: oldError,
      ...rest
    } = this.props
    const showError = touched && error;
    return(
      <div className="flex">
        <input
          accept='.jpg, .png, .jpeg'
          {...rest}
          type='file'
          onChange={this._onChange}
        />
        {
          showError ? (
            <span className="input-field-error-text">
              {error}
            </span>
          ) : null
        }
      </div>
    )
  }
}

export default FileInputField
