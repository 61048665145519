import { call, put, takeEvery } from "redux-saga/effects";
import { MiscCreators, MiscTypes } from "../actions";
import { getCountries } from "../../web-services";

export function* watchMiscRequests() {
  yield takeEvery(MiscTypes.GET_COUNTRIES_REQUEST, requestGetCountries);
}

export function* requestGetCountries(action) {
  try {
    const { params } = action;
    const response = yield call(getCountries, params);
    yield put(MiscCreators.getCountriesSuccess(response.data.countries));
  } catch (error) {
    yield put(MiscCreators.getCountriesFailure());
  }
}
