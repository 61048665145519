import React from "react";
import Loader from "react-loader-spinner";
import { Filter, Table, Checkbox, Select } from "../../../components";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Format, Notification } from "../../../helpers";
import { isEqual, omit, debounce, uniqBy } from "lodash";
import "react-virtualized/styles.css";
import MergePartners from "../merge-partners";
import FeaturesScreen from "../features";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import PlaceholderLogo from "../../../assets/direct-apply/university-placeholder.png";
import {
  INSTITUTE_TYPE,
  PARTNER_STATE,
  PARTNER_LINK,
} from "../../../constants/partner";

const DEFAULT_SELECTED_FILTERS = {
  status: [{ text: "Pending Approval", id: "pending" }],
};

const sortableColumns = {
  name: "partners.name",
  institute_type: "partners.institute_type",
  applied: "partners.created_at",
  status: "partners.status",
  country: "countries.id",
};

class ApplicantsPage extends React.Component {
  state = {
    searchText: "",
    sortColumn: "applied",
    sortDirection: "desc",
    selectedFilters: {},
    selectedBulkAction: null,
    selectAll: false,
    selectedListItems: {},
    mergeScreenVisible: false,
    shouldDisplayProductFilter: [
      "/partners/universities",
      "/partners/other-partners",
    ].includes(this.props.location.pathname),
    shouldDisplayCountryFilter: ["/partners/universities"].includes(
      this.props.location.pathname,
    ),
  };

  componentDidMount() {
    const { filtersChanged, filters, searchText } =
      this.props.partnerSearchState;
    this.setState(
      {
        selectedFilters: filtersChanged
          ? filters
          : DEFAULT_SELECTED_FILTERS,
        searchText,
      },
      () => this._load(),
    );
    this.props.getProductOfferingOptions();
    this.props.getCountries();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // reload if different path is used

    if (
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      this.setState(
        {
          shouldDisplayProductFilter: [
            "/partners/universities",
            "/partners/other-partners",
          ].includes(this.props.location.pathname),
          shouldDisplayCountryFilter: [
            "/partners/universities",
          ].includes(this.props.location.pathname),
        },
        () => this._load(),
      );
    }

    if (
      prevProps.isFetchingMergePartners === false &&
      this.props.isFetchingMergePartners === true
    ) {
      this.setState({ mergeScreenVisible: true });
    }

    if (
      prevProps.mergePartnerSuccessful === false &&
      this.props.mergePartnerSuccessful === true
    ) {
      this.setState({
        mergeScreenVisible: false,
        selectAll: false,
        selectedListItems: {},
        selectedBulkAction: null,
      });
      this.props.clearPartners();
      this._load(true);
      Notification.success(this.props.mergeMessage);
    }
  }

  _load = loadMore => {
    const {
      searchText,
      prevParams,
      sortColumn,
      sortDirection,
      selectedFilters,
      shouldDisplayProductFilter,
      shouldDisplayCountryFilter,
    } = this.state;
    const status =
      selectedFilters?.status?.length > 0
        ? selectedFilters.status[0].id
        : null;
    const product =
      selectedFilters?.product?.length > 0
        ? selectedFilters.product[0].id
        : null;

    const country =
      selectedFilters?.country?.length > 0
        ? selectedFilters.country[0].id
        : null;

    let countryFilter =
      selectedFilters.country && selectedFilters.country.length > 0
        ? selectedFilters.country.map(country => country.id)
        : [];

    const params = {
      search_text: searchText,
      sort_params: [],
      conditions: {},
    };

    // updated params to request for type of institute corresponding to the path
    switch (this.props.location.pathname) {
      case "/partners/schools":
        if (
          params.conditions?.partners?.institute_type !== "school"
        ) {
          params["conditions"]["partners"] = {
            institute_type: "school",
          };
          this.setState({ instituteType: "school" });
        }
        break;
      case "/partners/universities":
        if (
          params.conditions?.partners?.institute_type !== "university"
        ) {
          params["conditions"]["partners"] = {
            institute_type: "university",
          };
          this.setState({ instituteType: "university" });
        }
        break;
      case "/partners/other-partners":
        if (
          params.conditions?.partners?.institute_type !==
          "partner_client"
        ) {
          params["conditions"]["partners"] = { institute_type: 3 };
          this.setState({ instituteType: "partner_client" });
        }
        break;
      default: // no extra params
    }

    if (status) {
      params["conditions"]["partners"] = {
        ...params.conditions.partners,
        status: status,
      };
    }

    if (shouldDisplayProductFilter && product) {
      params["conditions"]["partner_configurations"] = {
        [product]: true,
      };
    }

    if (shouldDisplayCountryFilter && country) {
      params["conditions"]["country_id"] = countryFilter;
    }

    if (sortColumn && sortableColumns[sortColumn])
      params["sort_params"].push({
        sort_by: sortableColumns[sortColumn],
        sort_direction: sortDirection,
      });

    if (loadMore === true || !isEqual(params, prevParams)) {
      if (!loadMore)
        this.setState({ prevParams: params, isLoading: true });

      return new Promise((resolve, reject) => {
        this.props.getPartners(params, resolve, reject);
      });
    } else {
      return new Promise(function (resolve, reject) {
        resolve();
      });
    }
  };

  _loginSuperUser = id => {
    this.props.getPartnerSso(id);
  };

  _loadMore = params => {
    return this._load(true);
  };

  _searchLoad = debounce(this._load, 1000);

  _search = searchText => {
    this.props.setSearchText(searchText);
    this.setState(
      {
        searchText,
      },
      this._searchLoad,
    );
  };

  _rowGetter = ({ index }) => this.props.partners[index] || {};

  _rowClassName = ({ index }) => {
    if (index < 0) {
      return "";
    } else {
      return "app-table-row";
    }
  };

  _onClickHeader = ({ dataKey }) => {
    const { sortColumn, sortDirection } = this.state;

    this.setState(
      {
        sortColumn: dataKey,
        sortDirection:
          sortColumn === dataKey
            ? sortDirection === "desc"
              ? "asc"
              : "desc"
            : "asc",
      },
      this._load,
    );
  };

  _getStatusName = status => {
    const statusNames = {
      approved: "Approved",
      pending: "Pending Approval",
      declined: "Declined",
    };
    return statusNames[status];
  };

  _getBadgeClass = status => {
    const statusClassNames = {
      approved: "badge-success",
      pending: "badge-warning",
      declined: "badge-error",
    };
    return statusClassNames[status];
  };

  _onRowSelect = (id, checked) => {
    const { selectedListItems } = this.state;
    let updatedselectedListItems = { ...selectedListItems };

    if (checked) {
      updatedselectedListItems[id] = true;
    } else {
      delete updatedselectedListItems[id];
    }

    this.setState({ selectedListItems: updatedselectedListItems });
  };

  _openFeaturesScreen = institute => {
    this.setState({
      featuresScreenVisible: true,
      selectedInstitute: institute,
    });
  };

  _closeFeaturesScreen = () => {
    this.setState({ featuresScreenVisible: false });
  };

  render() {
    const {
      partners,
      matched,
      total,
      isNextPageLoading,
      setFilters,
      isFetchingMergePartners,
      isFetchingProductOfferingOptions,
      productOfferingOptions,
      partnerCountries,
      t,
      is_admin,
      history,
    } = this.props;
    const {
      searchText,
      selectedFilters,
      sortColumn,
      sortDirection,
      selectAll,
      selectedListItems,
      mergeScreenVisible,
      featuresScreenVisible,
      selectedInstitute,
      shouldDisplayProductFilter,
      shouldDisplayCountryFilter,
    } = this.state;
    const hasNextPage = matched > partners.length;
    const isRowLoaded = ({ index }) =>
      !hasNextPage || index < partners.length;
    const loadMoreRows = isNextPageLoading
      ? () => {}
      : this._loadMore;

    const listPath = this.props.location.pathname.split("/")[1];

    const filterOptions = [
      {
        id: "payments",
        title: "Payment Status",
        icon: ["fas", "location-arrow"],
        filterTypes: [
          {
            id: "status",
            text: "Status",
            isMulti: false,
            isSearchable: false,
            options: [
              { text: "Pending Approval", id: "pending" },
              { text: "Approved", id: "approved" },
            ],
          },
        ],
      },
    ];

    const productFilterOptions = {
      id: "product_offerings",
      title: "Product Offering",
      icon: ["fas", "shopping-bag"],
      filterTypes: [
        {
          id: "product",
          text: "Product",
          isMulti: false,
          isSearchable: false,
          options: productOfferingOptions,
        },
      ],
    };

    const countryFilterOptions = {
      id: "country",
      title: "Country",
      icon: ["fas", "location-arrow"],
      filterTypes: [
        {
          id: "country",
          text: "Country",
          isMulti: true,
          isSearchable: true,
          options: partnerCountries.map(country => ({
            id: country.id,
            text: country.name,
          })),
        },
      ],
    };

    if (shouldDisplayProductFilter) {
      filterOptions.push(productFilterOptions);
    }

    if (shouldDisplayCountryFilter) {
      filterOptions.push(countryFilterOptions);
    }

    return (
      <div className="flex-1 flex flex-col">
        <div className="pl-6">
          <Filter
            searchPlaceholder="Search by organization name"
            selectedFilters={
              shouldDisplayProductFilter && shouldDisplayCountryFilter
                ? selectedFilters
                : shouldDisplayProductFilter
                  ? omit(selectedFilters, "country")
                  : omit(selectedFilters, ["country", "product"])
            }
            searchText={searchText}
            filterOptions={filterOptions}
            onChangeSearchText={this._search}
            onSelection={filters => {
              let uniqFilters = {};
              if (Object.keys(filters).length > 0) {
                if (filters.status) {
                  uniqFilters["status"] = uniqBy(
                    filters.status,
                    "id",
                  );
                }
                if (shouldDisplayProductFilter && filters.product) {
                  uniqFilters["product"] = uniqBy(
                    filters.product,
                    "id",
                  );
                }
                if (shouldDisplayCountryFilter && filters.country) {
                  uniqFilters["country"] = uniqBy(
                    filters.country,
                    "id",
                  );
                }
              }
              this.setState({ selectedFilters: uniqFilters }, () => {
                setFilters(uniqFilters);
                this._load();
              });
            }}
          />
        </div>

        <div class="h-px bg-gray-300 mb-3"></div>

        <div className="applicants-list flex items-center h-42px pl-6">
          {total === 0 && isNextPageLoading ? null : (
            <div className="flex-none font-bold mr-2">
              {matched} Applicants of {total}
            </div>
          )}
          {Object.keys(selectedListItems).length > 0 || selectAll ? (
            <>
              <div className="flex-1 max-w-2xl">
                <Select
                  placeholder={t(
                    "common.placeholders.apply_bulk_action",
                  )}
                  options={[
                    {
                      label: t("common.labels.merge_accounts"),
                      value: 1,
                    },
                  ]}
                  onChange={selectedOption => {
                    this.setState({
                      selectedBulkAction: selectedOption,
                    });
                  }}
                />
              </div>
              <button
                onClick={() => {
                  if (this.state.selectedBulkAction) {
                    if (Object.keys(selectedListItems).length !== 2) {
                      Notification.error(
                        "Only 2 accounts can be merged at a time",
                      );
                    } else {
                      const partnerIds =
                        Object.keys(selectedListItems);
                      this.props.getMergePartners(
                        partnerIds[0],
                        partnerIds[1],
                      );
                    }
                  }
                }}
                className="btn-primary ml-3"
              >
                {t("common.buttons.apply")}
              </button>
            </>
          ) : null}
          {this.state.instituteType === "partner_client" ? (
            <Link to="/add-partner" className="ml-auto mr-8">
              {" "}
              <button onClick={() => {}} className="btn-primary ">
                <FontAwesomeIcon
                  icon={["fas", "plus"]}
                  className="mr-1"
                />
                Add Partner
              </button>
            </Link>
          ) : null}
        </div>
        <div className="mt-2" />
        <Table
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          totalRowCount={matched}
          rows={partners}
          currentRowCount={partners.length}
          headerHeight={46}
          rowHeight={62}
          rowClassName={this._rowClassName}
          sortableColumns={sortableColumns}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onRowClick={({ rowData, event, index }) => {
            let link = PARTNER_LINK.UNIVERSITY;

            if (rowData.institute_type === INSTITUTE_TYPE.SCHOOL) {
              link = PARTNER_LINK.SCHOOL;
            } else if (
              rowData.institute_type ===
              INSTITUTE_TYPE.APPLICATON_CLIENT
            ) {
              link = PARTNER_LINK.APPLICATON_CLIENT;
            }

            history.push({
              pathname: `/${link}/${rowData.id}`,
              state: PARTNER_STATE.EXISTING,
            });
          }}
          noRowsRenderer={() => {
            return isNextPageLoading ? (
              <div className="flex flex-col justify-center items-center h-full w-full">
                <Loader
                  type="Oval"
                  color="grey"
                  height={50}
                  width={50}
                />
              </div>
            ) : null;
          }}
          columns={[
            {
              dataKey: "id",
              width: 50,
              type: "Default",
              disableSort: true,
              cellRenderer: ({ rowData, cellData }) => {
                return (
                  <div className="p-2">
                    <Checkbox
                      checked={
                        selectedListItems[cellData] || selectAll
                      }
                      onChange={checked => {
                        this._onRowSelect(cellData, checked);
                      }}
                      id="select_checkbox"
                    />
                  </div>
                );
              },
              header: {
                title: "",
                onClick: this._onClickHeader,
                component: params => {
                  return (
                    <div className="p-2">
                      <Checkbox
                        checked={selectAll}
                        onChange={selectAll =>
                          this.setState({
                            selectAll: !this.state.selectAll,
                            selectedListItems: [],
                          })
                        }
                        colorClass="text-gray-900"
                      />
                    </div>
                  );
                },
              },
            },
            {
              dataKey: "institute_type",
              width: 100,
              type: "Default",
              disableSort: true,
              cellRenderer: ({ rowData, cellData }) => {
                return (
                  <div className="flex justify-start items-center">
                    <span className="text-xs font-normal text-gray-750 capitalize">
                      {rowData.institute_type === "application_client"
                        ? "Partner"
                        : rowData.institute_type}
                    </span>
                  </div>
                );
              },
              header: {
                title: "Type",
                onClick: this._onClickHeader,
              },
              exclude: this.state.instituteType === "university",
            },
            {
              dataKey: "name",
              width: 300,
              flexGrow: 1,
              type: "VerticalTwoValueBlue",
              tooltip: true,
              disableSort: true,
              valueSelector: rowData => ({
                topValue: rowData.name,
                bottomValue:
                  this.state.instituteType !== "university" &&
                  (rowData.city === null ? "" : rowData.city + ",") +
                    " " +
                    rowData.country,
                icon: this.state.instituteType === "university" && (
                  <img
                    src={rowData.avatar || PlaceholderLogo}
                    alt="Uni"
                    className="table-user-image object-cover min-w-10"
                  />
                ),
              }),
              header: {
                title: "Organization",
                onClick: this._onClickHeader,
              },
            },

            {
              dataKey: "country",
              width: 150,
              type: "Default",
              disableSort: true,
              valueSelector: rowData => ({
                value: rowData.country,
              }),
              header: {
                title: "Country",
                onClick: this._onClickHeader,
              },
              exclude: this.state.instituteType !== "university",
            },
            {
              dataKey: "applicant",
              width: 350,
              flexGrow: 1,
              type: "VerticalTwoValue",
              disableSort: true,
              valueSelector: rowData => ({
                topValue: !rowData.applicant
                  ? ""
                  : `${rowData.applicant?.first_name} ${rowData.applicant?.last_name}`,
                bottomValue: rowData.applicant?.email,
              }),
              header: {
                title: "Owner",
                onClick: this._onClickHeader,
              },
            },
            {
              dataKey: "applied",
              width: 150,
              type: "Default",
              disableSort: true,
              valueSelector: rowData => ({
                value: Format.date_d_m_y(rowData.applied),
              }),
              header: {
                title: "Applied",
                onClick: this._onClickHeader,
              },
            },
            {
              dataKey: "status",
              width: 200,
              type: "Tag",
              disableSort: true,
              className: rowData =>
                this._getBadgeClass(rowData.status),
              valueSelector: rowData => ({
                value: this._getStatusName(rowData.status),
              }),
              header: {
                title: "Status",
                onClick: this._onClickHeader,
              },
            },
            {
              dataKey: "user",
              width: 150,
              type: "Default",
              disableSort: true,
              valueSelector: rowData => ({
                value: rowData.total_users,
              }),
              header: {
                title: "User",
                onClick: this._onClickHeader,
              },
              exclude: this.state.instituteType !== "university",
            },
            {
              dataKey: "explore_id",
              width: 150,
              type: "Default",
              disableSort: true,
              valueSelector: rowData => ({
                value: rowData.id,
              }),
              header: {
                title: "Explore Id",
                onClick: this._onClickHeader,
              },
              exclude: this.state.instituteType !== "university",
            },
            {
              dataKey: "",
              width: 150,
              type: "Default",
              disableSort: true,
              cellRenderer: ({ rowData, cellData }) => {
                return (
                  <div className="flex justify-start items-center">
                    <div
                      onClick={event => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    >
                      <button
                        className="outline-none bg-transparent text-blue-700 font-bold"
                        onClick={() =>
                          this._openFeaturesScreen(rowData)
                        }
                      >
                        View
                      </button>
                    </div>
                  </div>
                );
              },
              header: {
                title: "Products",
                onClick: this._onClickHeader,
              },
              exclude: this.state.instituteType === "school",
            },
            {
              dataKey: "",
              width: 100,
              type: "Default",
              disableSort: true,
              cellRenderer: ({ rowData, cellData }) => {
                return (
                  <div className="flex justify-start items-center">
                    <div
                      onClick={event => {
                        event.stopPropagation();
                        event.preventDefault();
                        this._loginSuperUser(rowData.id);
                      }}
                    >
                      <button className="btn-primary-alt">
                        Sign In
                      </button>
                    </div>
                  </div>
                );
              },
              header: {
                title: "",
                onClick: this._onClickHeader,
              },
            },
          ]}
        />
        {mergeScreenVisible ? (
          <div
            style={{ width: 800 }}
            className={`fixed top-0 right-0 bottom-0 side-screen-container bg-white overflow-scroll`}
          >
            <MergePartners
              onClickClose={() => {
                this.setState({ mergeScreenVisible: false });
              }}
            />
          </div>
        ) : null}
        {isFetchingMergePartners ||
        isFetchingProductOfferingOptions ? (
          <div
            className={
              "absolute bottom-0 right-0 left-0 self-center pointer-events-none flex-none flex flex-col justify-center items-center p-1"
            }
          >
            <Loader
              type="ThreeDots"
              color="grey"
              height={75}
              width={75}
            />
          </div>
        ) : null}
        {isNextPageLoading && partners.length > 0 ? (
          <div
            className={
              "absolute bottom-0 right-0 left-0 self-center pointer-events-none flex-none flex flex-col justify-center items-center p-1"
            }
          >
            <Loader
              type="ThreeDots"
              color="grey"
              height={75}
              width={75}
            />
          </div>
        ) : null}
        <div
          className={`fixed top-0 right-0 bottom-0 side-screen-container ${
            featuresScreenVisible ? "" : "side-screen-collapsed"
          }`}
        >
          {featuresScreenVisible ? (
            <FeaturesScreen
              partnerId={selectedInstitute.id}
              partnerName={selectedInstitute.name}
              partnerCountry={selectedInstitute.country}
              closeButtonPressed={() => {
                this._closeFeaturesScreen();
              }}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ApplicantsPage));
