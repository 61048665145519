import moment from "moment";
// import { week } from '../constants/server.constants';
import i18n from "../i18n";
import _ from "lodash";

export class Format {
  static date(d, format = "MMM DD, YYYY") {
    return d ? moment(d).format(format) : i18n.t("-");
  }

  static date_d_m_y(d, format = "DD-MM-YYYY") {
    return moment(d).format(format);
  }

  static datetime(d, format = "MMM DD, YYYY hh:mma") {
    return moment(d).format(format);
  }

  static time(d, format = "hh:mma") {
    const createFromFormat = "hh:mma";
    const date = new Date(d);
    return moment(date, createFromFormat).format(format);
  }

  static month(d, format = "MMMM") {
    const createFromFormat = "DD-MM-YYYY";
    return moment(d, createFromFormat).format(format);
  }

  static timeRange(start, end, format = "hh:mma") {
    const createFromFormat = "hh:mma";
    return start && end
      ? moment(start, createFromFormat).format(format) +
          " - " +
          moment(end, createFromFormat).format(format)
      : i18n.t("common.labels.blank");
  }

  static phoneNumber(phoneNumber) {
    return phoneNumber.replace(" ", "");
  }

  // static week(daysOfWeek) {
  //   return daysOfWeek instanceof Array ? daysOfWeek.map((day) => week[day]).join(', ') : i18n.t('common.labels.blank');
  // }

  static displayValue(value, ifNull, formatFunction) {
    ifNull = ifNull || i18n.t("common.labels.blank");
    return value ? (formatFunction ? formatFunction(value) : value) : ifNull;
  }

  static timezone(timezone, timezoneOffset) {
    return (
      timezone + (timezoneOffset > 0 ? " UTC +" : " UTC ") + timezoneOffset
    );
  }

  static address(address, city, state, country) {
    return _.join(_.compact([address, city, state, country]), ", ");
  }

  static titleCase(string) {
    var sentence = _.lowerCase(string).split(" ");

    for (var i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i]
        ? sentence[i][0].toUpperCase() + sentence[i].slice(1)
        : "";
    }

    return sentence.join(" ");
  }

  static bytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
