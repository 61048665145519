import { call, put, takeEvery, select } from 'redux-saga/effects';
import { TeamCreators, UserCreators, TeamTypes } from '../actions';
import {
  bulkRequest,
  getTeam,
  getTeamMember,
  createTeamMember,
  updateTeamMember
} from '../../web-services';
import {
  Notification,
} from '../../helpers';
import { SubmissionError } from 'redux-form';
import _ from 'lodash';

export function* watchTeamRequests() {
  yield takeEvery(TeamTypes.DOWNLOAD_TEAM_REQUEST, requestDownloadTeam);
  yield takeEvery(TeamTypes.GET_TEAM_REQUEST, requestGetTeam);
  yield takeEvery(TeamTypes.BULK_ACTION_REQUEST, requestBulkAction);
  yield takeEvery(TeamTypes.GET_TEAM_MEMBER_REQUEST, requestGetTeamMember);
  yield takeEvery(TeamTypes.CREATE_TEAM_MEMBER_REQUEST, requestCreateTeamMember);
  yield takeEvery(TeamTypes.UPDATE_TEAM_MEMBER_REQUEST, requestUpdateTeamMember);
}

export function* requestDownloadTeam(action) {
  try {
    const { params } = action;
    const authTokenSelector = (state) => state.user.authToken;
    const authToken = yield select(authTokenSelector);

    const response = yield call(getTeam, authToken, params);
    yield put(TeamCreators.downloadTeamSuccess(response.data.csv_url));
  } catch (error) {
    yield put(TeamCreators.downloadTeamFailure());
  }
}

export function* requestGetTeam(action) {
  try {
    const { params, resolve } = action;
    const authTokenSelector = (state) => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const teamSelector = (state) => state.team;
    const {
      data: currentTeam,
      params: currentParams
    } = yield select(teamSelector);

    const shouldLoadMore = currentTeam.length > 0 && _.isEqual(_.omit(currentParams, ['page_no']), params);

    if (!shouldLoadMore)
      yield put(TeamCreators.clearTeam());

    const page = shouldLoadMore ? (currentParams.page_no + 1) : 1;
    const requestParams = {...params};
    requestParams['page_no'] = page;

    const response = yield call(getTeam, authToken, requestParams);
    resolve();
    yield put(TeamCreators.getTeamSuccess(response.data, requestParams));
  } catch (error) {
    yield put(TeamCreators.getTeamFailure());
  }
}

export function* requestBulkAction(action) {
  try {
    const { params } = action;
    const authTokenSelector = (state) => state.user.authToken;
    const authToken = yield select(authTokenSelector);

    yield call(bulkRequest, authToken, params);
    yield put(TeamCreators.bulkActionSuccess());
  } catch (error) {
    yield put(TeamCreators.bulkActionFailure());
  }
}

export function* requestGetTeamMember(action) {
  try {
    const { params } = action;
    const authTokenSelector = (state) => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const id = params['member_id'];
    const response = yield call(getTeamMember, authToken, id);
    yield put(TeamCreators.getTeamMemberSuccess(response.data));
  } catch (error) {
    yield put(TeamCreators.getTeamMemberFailure());
  }
}

export function* requestCreateTeamMember(action) {
  try {
    const { params, resolve } = action;
    const authTokenSelector = (state) => state.user.authToken;
    const authToken = yield select(authTokenSelector);

    const response = yield call(createTeamMember, authToken, params);
    resolve();
    yield put(TeamCreators.createTeamMemberSuccess(response.data));
  } catch (error) {
    const { reject } = action;
    // let errorMessages = {};
    // _.each(error.response.data.error, (error, key) => {
    //   errorMessages[key] = error instanceof Array ? error : [error];
    // });
    const errorMessage = error?.response?.data?.error || "Something went wrong";
    Notification.error(errorMessage);
    reject(new SubmissionError(errorMessage));
    yield put(TeamCreators.createTeamMemberFailure());
  }
}

export function* requestUpdateTeamMember(action) {
  try {
    const { params, resolve } = action;
    const userSelector = (state) => state.user;
    const { authToken, data: { id: currentUserId } } = yield select(userSelector);

    const response = yield call(updateTeamMember, authToken, Number(params.get('id')), params);
    resolve();
    yield put(TeamCreators.updateTeamMemberSuccess(response.data));

    if (currentUserId === Number(params.get('id')))
      yield put(UserCreators.checkSessionRequest());
  } catch (error) {
    const { reject } = action;
    let errorMessages = {};
    _.each(error.response.data.error, (error, key) => {
      errorMessages[key] = error;
    });

    reject(new SubmissionError(errorMessages));
    yield put(TeamCreators.updateTeamMemberFailure());
  }
}
