import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RadioBox = (props) => {
  const {
      input,
      items,
      meta: { touched, error }
    } = props;

    return (
      <div className="flex-1 flex flex-col">
        <div className="flex-none flex flex-row items-center">
          {items.map((item) => (
            <div className="cursor-pointer text-gray-750 text-sm" onClick={() => input.onChange(item.value)} key={item.value}>
              <FontAwesomeIcon
                icon={input.value === item.value ? ['fas', 'check-circle'] : ['far', 'circle']}
                className={"text-sm " + (input.value === item.value ? "text-blue-700"  : "")}
              />
              <span className={"ml-2 mr-8 " + (input.value === item.value && props.className) }>{item.label}</span>
            </div>
          ))}
        </div>
        {
          touched && error ? (
            <span className="input-field-error-text flex-none mt-px">{error}</span>
          ) : null
        }
      </div>
    )
}

export { RadioBox };
