import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import BaseLayout from "../layouts/base";
import Loader from "react-loader-spinner";
import DEFAULT_ROUTES from "../constants/user-roles-default-routes.json";
import { ROUTES } from "../constants/Constants";

const ProtectedRoute = ({
  component: Component,
  userRole,
  allowedRoles,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (!rest.user.authToken) {
        return (
          <Redirect
            to={`${ROUTES.LOGIN}?redirectTo=${props.history.location.pathname}`}
          />
        );
      }

      if (!allowedRoles.includes(userRole)) {
        return <Redirect to={DEFAULT_ROUTES[userRole]} />;
      }

      if (rest.loading)
        return (
          <div className="flex-1 h-full bg-gray-200 flex justify-center items-center">
            <Loader type="Oval" color="grey" height={50} width={50} />
          </div>
        );

      return (
        <BaseLayout {...props}>
          <Component {...props} />
        </BaseLayout>
      );
    }}
  />
);

function mapStateToProps(state) {
  return {
    user: state.user,
    userRole: state.user?.data?.role?.name,
  };
}

export default connect(mapStateToProps)(ProtectedRoute);
