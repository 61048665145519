import React, { Component } from "react";
import { ConfirmationModal } from "../../../../components";
import {
  RadioBox,
  TextInputField,
  TextAreaField,
  RichTextInputField,
} from "../../../../form-components";
import { ActionBar } from "../common";
import { Notification } from "../../../../helpers";
import _ from "lodash";
import { PROGRAM_STATUS } from "../../../../constants";
import { FormField } from "../information/form-fields";

class Tests extends Component {
  state = {
    isSaveAsDraftModalVisible: false,
  };

  isSavedAndValid = event => {
    return (
      this.props.valid &&
      (this.props.dirty ? this.props.submitSucceeded : true)
    );
  };

  _showValidationErros = errors => {
    if (errors && Object.keys(errors).length > 0) {
      const errorMessages = _.map(errors, error => error);
      const errorMessagesComponent = _.map(errors, (error, index) => (
        <div className="h-5" key={index}>
          {error}
        </div>
      ));

      if (errors) {
        Notification.error(
          <div className="flex flex-col">
            {errorMessagesComponent}
          </div>,
          {
            customFields: {
              title: `${errorMessages.length} error${errorMessages.length > 1 ? "s" : ""} in this section`,
            },
          },
        );
      }
    }
  };

  _onSubmit = event => {
    const errors = this.props.handleSubmit(this._save)(event);
    this._showValidationErros(errors);
  };

  _onSubmitDraft = event => {
    const errors = this.props.handleSubmit(
      this._saveAsDraftShowModal,
    )(event);
    this._showValidationErros(errors);
  };

  _save = values => {
    this._submit(values);
  };

  _saveAsDraftShowModal = values => {
    return new Promise((resolve, reject) => {
      this._showSaveAsDraftModal();
      reject();
    });
  };

  _confirmSaveAsDraft = event => {
    const errors = this.props.handleSubmit(values => {
      this._submit(values, true);
    })(event);
    this._showValidationErros(errors);
    this._hideSaveAsDraftModal();
  };

  _submit = (values, saveAsDraft) => {
    const {
      test_description,
      is_eng_test_req,
      eng_test_desc,
      is_gpa_required,
      toefl,
      ielts,
      duolingo,
      gaokao,
      other_english_test_names,
      other_english_test_scores,
      gpa_4,
      gpa_100,
    } = values;
    const { id } = this.props;
    const formData = new FormData();

    formData.append(
      "partner_program[test_description]",
      test_description || "",
    );
    formData.append(
      "partner_program[is_english_language_test_required]",
      is_eng_test_req,
    );
    formData.append(
      "partner_program[english_language_requirement]",
      eng_test_desc || "",
    );
    formData.append(
      "partner_program[is_gpa_required]",
      is_gpa_required,
    );
    formData.append("partner_program[toefl_score]", toefl || "");
    formData.append("partner_program[ielts_score]", ielts || "");
    formData.append(
      "partner_program[duolingo_score]",
      duolingo || "",
    );
    formData.append("partner_program[gaokao_score]", gaokao || "");
    formData.append(
      "partner_program[other_english_test]",
      other_english_test_names || "",
    );
    formData.append(
      "partner_program[other_english_test_score]",
      other_english_test_scores || "",
    );
    formData.append("partner_program[gpa_out_of_4]", gpa_4 || "");
    formData.append("partner_program[gpa_out_of_100]", gpa_100 || "");

    if (saveAsDraft) {
      formData.append(
        "partner_program[status]",
        PROGRAM_STATUS.DRAFT,
      );
      this.props.saveProgramDraft(id, formData);
    } else this.props.updateProgram(id, formData);
  };

  _showSaveAsDraftModal = () => {
    this.setState({ isSaveAsDraftModalVisible: true });
  };

  _hideSaveAsDraftModal = () => {
    this.setState({ isSaveAsDraftModalVisible: false });
  };

  render() {
    const {
      selectedFormValues: { is_eng_test_req, is_gpa_required },
      status,
      isUpdating,
      isSavingDraft,
      t,
      testChanges,
    } = this.props;
    const { isSaveAsDraftModalVisible } = this.state;

    return (
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex flex-1 flex-col overflow-scroll pr-4">
          <div className="flex flex-col">
            <div className="pointer-events-none">
              <span className="text-gray-750 text-lg font-bold mb-6">
                English Language Requirements
              </span>

              {/*English Test Req?*/}
              <FormField
                disabled={!is_eng_test_req}
                label={"English Test Required?"}
                component={RadioBox}
                className={
                  testChanges.is_eng_test_req && "text-yellow-800"
                }
                name="is_eng_test_req"
                items={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
              />

              <div className="h-8" />

              {/*Eng Test Description*/}
              <FormField
                disabled={!is_eng_test_req}
                label={"English Language Requirements"}
                component={TextAreaField}
                props={{
                  classForBorder:
                    testChanges.eng_test_desc && "border-yellow-800",
                }}
                name="eng_test_desc"
                placeholder={
                  "TOEFL: 100 (min. 70 in Reading) | IELTS: 6 (min. 5.5 in all sections) | Duolingo: 120"
                }
                tooltip={{
                  title: "English language requirements",
                  text: "Key in specific requirements of sections of tests, if applicable (e.g Reading, Writing for TOEFL, IELTS, etc)",
                }}
              />

              <div className="h-8" />

              {/*TOEFL*/}
              <FormField
                disabled={!is_eng_test_req}
                label={"TOEFL"}
                name="toefl"
                type="number"
                component={TextInputField}
                classForBorder={
                  testChanges.toefl && "border-yellow-800"
                }
                placeholder={"0-120"}
              />

              <div className="h-8" />

              {/*IELTS*/}
              <FormField
                disabled={!is_eng_test_req}
                label={"IELTS"}
                name="ielts"
                type="number"
                component={TextInputField}
                classForBorder={
                  testChanges.ielts && "border-yellow-800"
                }
                placeholder={"0-9"}
              />

              <div className="h-8" />

              {/*Duolingo*/}
              <FormField
                disabled={!is_eng_test_req}
                label={"Duolingo"}
                name="duolingo"
                type="number"
                component={TextInputField}
                classForBorder={
                  testChanges.duolingo && "border-yellow-800"
                }
                placeholder={"0-160"}
              />

              <div className="h-8" />

              {/*Gaokao test*/}
              <FormField
                disabled={!is_eng_test_req}
                label={"Gaokao"}
                name="gaokao"
                type="number"
                component={TextInputField}
                classForBorder={
                  testChanges.gaokao && "border-yellow-800"
                }
                placeholder={"0-750"}
              />

              <div className="h-8" />

              {/*Other English Test Name*/}
              <FormField
                disabled={!is_eng_test_req}
                label={"Other English Tests Name"}
                name="other_english_test_names"
                type="text"
                component={TextInputField}
                classForBorder={
                  testChanges.other_english_test_names &&
                  "border-yellow-800"
                }
                placeholder={"Other English Tests Name"}
              />

              <div className="h-8" />

              {/*Other English Test score*/}
              <FormField
                disabled={!is_eng_test_req}
                label={"Other English Tests Score"}
                name="other_english_test_scores"
                type="text"
                component={TextInputField}
                classForBorder={
                  testChanges.other_english_test_scores &&
                  "border-yellow-800"
                }
                placeholder={"0"}
              />

              <div className="h-8" />
              <div className="h-8" />

              <span className="text-gray-750 text-lg font-bold mb-6">
                Academic Requirements
              </span>

              <FormField
                label={"GPA required?"}
                name="is_gpa_required"
                component={RadioBox}
                className={
                  testChanges.is_gpa_required && "text-yellow-800"
                }
                items={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
              />

              <div className="h-8" />

              {/*GPA 4.0*/}
              <FormField
                disabled={!is_gpa_required}
                label={"GPA 4.0"}
                name="gpa_4"
                type="number"
                component={TextInputField}
                classForBorder={
                  testChanges.gpa_4 && "border-yellow-800"
                }
                placeholder={"0-4"}
                min="0"
                max="4"
              />

              <div className="h-8" />

              <FormField
                disabled={!is_gpa_required}
                label={"GPA 100"}
                name="gpa_100"
                type="number"
                component={TextInputField}
                classForBorder={
                  testChanges.gpa_100 && "border-yellow-800"
                }
                placeholder={"0-100"}
              />

              <div className="h-8" />
            </div>

            <FormField
              disabled={!is_gpa_required}
              readOnly
              label={"Description"}
              component={RichTextInputField}
              labelColor={
                testChanges.test_description && "text-yellow-800"
              }
              name="test_description"
              placeHolder={
                "Australia: Completion of year 11 with 5 subjects grades A-C (min. 60%) (including relevant subjects)\n\n AS Level: 24 UCAS points (native language accepted)"
              }
              tooltip={{
                title: "Specific academic requirements",
                text: `Key in your specific academic requirements for international examinations like A Levels, IB, ICGSE, GCSE. Key in localised entry requirements for India, China, South Korea, Japan and Southeast Asian Countries.`,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export function validate(values, props) {
  const errors = {};
  const {
    eng_test_desc,
    test_description,
    toefl,
    ielts,
    duolingo,
    gaokao,
    other_english_test_scores,
    gpa_4,
    gpa_100,
  } = values;

  if (toefl && (Number(toefl) < 0 || Number(toefl) > 120))
    errors.toefl = "Score should be between 0 and 120";

  if (eng_test_desc && eng_test_desc.length > 130)
    errors.eng_test_desc =
      "English Language Requirements should be less than 130 characters";

  if (ielts && (Number(ielts) < 0 || Number(ielts) > 9))
    errors.ielts = "Score should be between 0 and 9";

  if (duolingo && (Number(duolingo) < 0 || Number(duolingo) > 160))
    errors.duolingo = "Score should be between 0 and 160";

  if (gaokao && (Number(gaokao) < 0 || Number(gaokao) > 750))
    errors.gaokao = "Score should be between 0 and 750";

  if (
    other_english_test_scores &&
    Number(other_english_test_scores) < 0
  )
    errors.other_english_test_scores =
      "Score should be 0 or more than 0";

  if (gpa_4 && (Number(gpa_4) < 0 || Number(gpa_4) > 4))
    errors.gpa_4 = "GPA 4 should be between 0 and 4";
  else if (
    gpa_4 &&
    !Number.isInteger(gpa_4) &&
    String(gpa_4).split(".")[1]?.length > 2
  )
    errors.gpa_4 = "GPA 4 can only have value up to 2 decimal places";

  if (gpa_100 && (Number(gpa_100) < 0 || Number(gpa_100) > 100))
    errors.gpa_100 = "GPA 100 should be between 0 and 100";
  else if (!Number.isInteger(Number(gpa_100)))
    errors.gpa_100 = "GPA 100 should be an integer value";

  if (test_description && test_description.length > 256)
    errors.test_description =
      "Description should be less than 256 characters";

  return errors;
}

export default Tests;
