import ReactAlert from 'react-s-alert';

const configuration = {
  position: 'top',
  effect: 'stackslide',
  timeout: 3000
};

export class Alert {
  static info(msg, config) {
    ReactAlert.info(msg, { ...configuration, ...config });
  }

  static success(msg, config) {
    ReactAlert.success(msg, { ...configuration, ...config });
  }

  static warning(msg, config) {
    ReactAlert.warning(msg, { ...configuration, ...config });
  }

  static error(msg, config) {
    ReactAlert.error(msg, { ...configuration, ...config });
  }
}
