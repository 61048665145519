import { API } from "../helpers";
import { SERVER_URL } from "../config";

export function getCountries(params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/countries`,
    params,
  });
}
