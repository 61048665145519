import ClientsScreen from "./clients-screen";
import { connect } from "react-redux";
import { ClientsCreators } from "../../redux/actions";

function mapStateToProps(state) {
  return {
    clients: state.clients.data,
    total: state.clients.total,
    matched: state.clients.matched,
    isNextPageLoading: state.clients.loading,
  };
}

const mapDispatchToProps = {
  getClients: ClientsCreators.getClientsRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientsScreen);
