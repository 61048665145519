import React, { useState, useEffect, useRef } from "react";
import ReactModal from "react-modal";
import { Radio } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import cialfoLogo from "src/assets/qr-code/cialfo_logo.png";
import leftTop from "src/assets/qr-code/left-top.png";
import rightTop from "src/assets/qr-code/right-top.png";
import leftBottom from "src/assets/qr-code/left-bottom.png";
import rightBottom from "src/assets/qr-code/right-bottom.png";
import Loader from "react-loader-spinner";
import { ENVIRONMENT } from "src/config";
import "./print-screen.css";

const QrCodeModal = props => {
  const { isOpen, onClose } = props;
  const [value, setValue] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onChange = e => {
    setValue(e.target.value);
  };

  const [elements, setElements] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const printableRef = useRef(null);

  useEffect(() => {
    if (elements.length > 0 && imagesLoaded === elements.length) {
      setIsLoading(false);
      printDiv();
    }
  }, [imagesLoaded, elements.length]);

  const printDiv = () => {
    setIsLoading(true);

    var printContents =
      document.getElementById("printableArea").innerHTML;

    document.body.innerHTML = printContents;

    const images = document.body.querySelectorAll("img");
    const imagePromises = Array.from(images).map(
      image =>
        new Promise((resolve, reject) => {
          image.onload = resolve;
          image.onerror = reject;
        }),
    );

    Promise.all(imagePromises)
      .then(() => {
        // Images are loaded, proceed with printing
        setIsLoading(false);
        window.print();
        window.location.reload();
      })
      .catch(error => {
        console.error("Error loading images:", error);
      });
  };

  const handleImageLoad = () => {
    setImagesLoaded(prev => prev + 1);
  };

  const handleAddElements = () => {
    const newElements = [];
    for (let i = 0; i < value; i++) {
      const connectionId = btoa(`explore-${uuidv4()}-${Date.now()}`);
      const baseUrl =
        ENVIRONMENT === "production"
          ? "https://explore.cialfo.co"
          : "https://explore-beta.cialfo.link";
      const url = `${baseUrl}/events-list?redirect_to=events-list&connectionId=${connectionId}`;
      newElements.push(
        <div
          className="newBox"
          style={{
            height: 500,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            overflow: "visible",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              position: "absolute",
              left: "0px",
            }}
          >
            <img src={leftBottom} alt="left-top" />
            <img src={rightBottom} alt="left-top" />
          </div>
          <div
            className="box"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="font-bold text-black pb-4 text-xs">
              {" "}
              Present this QR code to universities whom you wish to
              stay connected with
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px 0px",
                  width: "60%",
                }}
              >
                <div>First Name</div>
                <hr className="mt-7 mb-1 border-gray-300" />
                <div>Last Name</div>
                <hr className="mt-7 mb-1 border-gray-300" />
                <div>School email</div>
                <hr className="mt-7 mb-1 border-gray-300" />
                <div>Graduation year</div>
                <hr className="mt-7 mb-1 border-gray-300" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "40%",
                  alignItems: "center",
                }}
              >
                <img
                  src={cialfoLogo}
                  alt="cialfo_logo"
                  className="mb-4"
                  style={{ width: "73px", height: "23px" }}
                />
                <img
                  src={`https://qr-code.cialfo.co/?content=${btoa(url)}`}
                  style={{ width: "150px", height: "150px" }}
                  alt="qr-code"
                  onLoad={() => handleImageLoad()}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              position: "absolute",
              right: "0px",
            }}
          >
            <img src={leftTop} alt="left-top" />
            <img src={rightTop} alt="left-top" />
          </div>
        </div>,
      );
    }

    setElements([...elements, ...newElements]);
  };

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        style={{
          content: {
            width: "500px",
            margin: "200px auto",
            outline: "none",
          },
        }}
        overlayClassName="modal-overlay"
        className="modal-content"
      >
        <div
          className="bg-white p-8 rounded-md"
          style={{ height: 260 }}
        >
          <div
            className={
              "flex items-center flex-row justify-between mb-4"
            }
          >
            <span className="font-bold text-black text-xl">
              Print QR code
            </span>

            <FontAwesomeIcon
              onClick={onClose}
              icon={["fas", "times"]}
              className={
                "text-lg leading-none text-gray-750 cursor-pointer"
              }
            />
          </div>

          <div className="mb-8">How many QR codes do you need?</div>

          <div>
            <Radio.Group onChange={onChange} value={value}>
              <Radio style={{ width: 100 }} value={50}>
                50
              </Radio>
              <Radio style={{ width: 100 }} value={100}>
                100
              </Radio>
              <Radio style={{ width: 100 }} value={200}>
                200
              </Radio>
              <Radio style={{ width: 100 }} value={500}>
                500
              </Radio>
            </Radio.Group>
          </div>

          <div className="flex justify-end pt-10">
            <button
              className="btn-secondary ml-4"
              type={"submit"}
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="btn-primary ml-4"
              disabled={!value || isLoading}
              type={"submit"}
              onClick={() => {
                setIsLoading(true);
                handleAddElements();
              }}
            >
              Okay
              {isLoading && (
                <Loader
                  className={"ml-2"}
                  type="Oval"
                  height={15}
                  width={15}
                  color="white"
                />
              )}
            </button>

            <div
              id="printableArea"
              className="invisible"
              ref={printableRef}
            >
              <div className="printable-area ">{elements}</div>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default QrCodeModal;
