import { createReducer } from "reduxsauce";
import { NewPartnersTypes as Types, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.newPartners;

export const clearNewPartners = (state, action) => ({ ...state, data: [] });

export const getNewPartnersFailure = (state, action) => ({
  ...state,
  loading: false,
});

export const getNewPartnersRequest = (state, action) => {
  return { ...state, loading: true };
};

export const getNewPartnersSuccess = (state, action) => {
  const {
    partnersData: { partners, matched, total },
    params,
  } = action;

  return {
    ...state,
    data: params.page_no === 1 ? partners : [...state.data, ...partners],
    matched,
    total,
    params: { ...state.params, ...params },
    loading: false,
  };
};

export const getNewPartnersFilterCountriesFailure = (state, action) => ({
  ...state,
});

export const getNewPartnersFilterCountriesRequest = (state, action) => ({
  ...state,
});

export const getNewPartnersFilterCountriesSuccess = (state, action) => ({
  ...state,
  newPartnersFilterCountries: action.countries,
});

export const getNewPartnersFilterStatusesFailure = (state, action) => ({
  ...state,
});

export const getNewPartnersFilterStatusesRequest = (state, action) => ({
  ...state,
});

export const getNewPartnersFilterStatusesSuccess = (state, action) => ({
  ...state,
  newPartnersFilterStatuses: action.statuses,
});

export const getNewPartnerFailure = (state, action) => ({
  ...state,
  loadingSelected: false,
  selected: {},
});

export const getNewPartnerRequest = (state, action) => ({
  ...state,
  loadingSelected: true,
});

export const getNewPartnerSuccess = (state, action) => ({
  ...state,
  loadingSelected: false,
  selected: action.partner,
});

export const approveNewPartnerFailure = (state, action) => ({
  ...state,
});

export const approveNewPartnerRequest = (state, action) => ({
  ...state,
});

export const approveNewPartnerSuccess = (state, action) => ({
  ...state,
});

export const declineNewPartnerFailure = (state, action) => ({
  ...state,
});

export const declineNewPartnerRequest = (state, action) => ({
  ...state,
});

export const declineNewPartnerSuccess = (state, action) => ({
  ...state,
});

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CLEAR_NEW_PARTNERS]: clearNewPartners,

  [Types.GET_NEW_PARTNERS_FAILURE]: getNewPartnersFailure,
  [Types.GET_NEW_PARTNERS_REQUEST]: getNewPartnersRequest,
  [Types.GET_NEW_PARTNERS_SUCCESS]: getNewPartnersSuccess,

  [Types.GET_NEW_PARTNERS_FILTER_COUNTRIES_FAILURE]:
    getNewPartnersFilterCountriesFailure,
  [Types.GET_NEW_PARTNERS_FILTER_COUNTRIES_REQUEST]:
    getNewPartnersFilterCountriesRequest,
  [Types.GET_NEW_PARTNERS_FILTER_COUNTRIES_SUCCESS]:
    getNewPartnersFilterCountriesSuccess,

  [Types.GET_NEW_PARTNERS_FILTER_STATUSES_FAILURE]:
    getNewPartnersFilterStatusesFailure,
  [Types.GET_NEW_PARTNERS_FILTER_STATUSES_REQUEST]:
    getNewPartnersFilterStatusesRequest,
  [Types.GET_NEW_PARTNERS_FILTER_STATUSES_SUCCESS]:
    getNewPartnersFilterStatusesSuccess,

  [Types.GET_NEW_PARTNER_FAILURE]: getNewPartnerFailure,
  [Types.GET_NEW_PARTNER_REQUEST]: getNewPartnerRequest,
  [Types.GET_NEW_PARTNER_SUCCESS]: getNewPartnerSuccess,

  [Types.APPROVE_NEW_PARTNER_FAILURE]: approveNewPartnerFailure,
  [Types.APPROVE_NEW_PARTNER_REQUEST]: approveNewPartnerRequest,
  [Types.APPROVE_NEW_PARTNER_SUCCESS]: approveNewPartnerSuccess,

  [Types.DECLINE_NEW_PARTNER_FAILURE]: declineNewPartnerFailure,
  [Types.DECLINE_NEW_PARTNER_REQUEST]: declineNewPartnerRequest,
  [Types.DECLINE_NEW_PARTNER_SUCCESS]: declineNewPartnerSuccess,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
