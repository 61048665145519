import { API } from "../helpers";
import { SERVER_URL } from "../config";

export const getUsers = (authToken, data) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/users`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const updateUsers = (authToken, data) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/users`,
    headers: {
      authentication_token: authToken,
    },
    data: data,
  });
};
