import React from "react";
import {
  Link,
  Switch,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";
import ClientOverviewPage from "src/views/clients/overview/client-overview-page";
import CounselorsScreen from "src/views/clients/counselors";
import StudentsScreen from "src/views/clients/students";
import GuardiansScreen from "src/views/clients/guardian";
import ConfigurationScreen from "src/views/clients/configuration";
import IntegrationScreen from "src/views/clients/integration";

const ClientDetailsScreen = props => {
  const { pathname, search } = props.location;
  const rootUrl = props.match.url;
  const tenantId = props.match.params.id;

  const checkActiveRoute = route => {
    return route === pathname + search;
  };

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="flex items-center px-6 py-4">
        <span className="text-xl font-bold text-black mr-6">
          School Name
        </span>

        <Link
          to={`${rootUrl}/overview`}
          replace
          className="pr-4"
          exact
        >
          <button
            className={`px-4 py-2 text-sm border rounded-20px font-bold ${checkActiveRoute(`${rootUrl}/overview`) ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300"}`}
          >
            Overview
          </button>
        </Link>

        <Link to={`${rootUrl}/counselors`} replace className="pr-4">
          <button
            className={`px-4 py-2 text-sm border rounded-20px font-bold ${checkActiveRoute(`${rootUrl}/counselors`) ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300"}`}
          >
            Counselor
          </button>
        </Link>

        <Link to={`${rootUrl}/students`} replace className="pr-4">
          <button
            className={`px-4 py-2 text-sm border rounded-20px font-bold ${checkActiveRoute(`${rootUrl}/students`) ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300"}`}
          >
            Students
          </button>
        </Link>

        <Link to={`${rootUrl}/guardians`} replace className="pr-4">
          <button
            className={`px-4 py-2 text-sm border rounded-20px font-bold ${checkActiveRoute(`${rootUrl}/guardians`) ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300"}`}
          >
            Guardians
          </button>
        </Link>

        <Link to={`${rootUrl}/integration`} replace className="pr-4">
          <button
            className={`px-4 py-2 text-sm border rounded-20px font-bold ${checkActiveRoute(`${rootUrl}/integration`) ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300"}`}
          >
            Integration
          </button>
        </Link>

        <Link
          to={`${rootUrl}/configuartion`}
          replace
          className="pr-4"
        >
          <button
            className={`px-4 py-2 text-sm border rounded-20px font-bold ${checkActiveRoute(`${rootUrl}/configuartion`) ? "text-blue-700 bg-blue-100 border-blue-700" : "text-gray-600 border-gray-300"}`}
          >
            Configuartion
          </button>
        </Link>
      </div>

      <Switch>
        <Route
          path="/tenant/:id/overview"
          render={() => <ClientOverviewPage tenantId={tenantId} />}
          exact
        />
        <Route
          path="/tenant/:id/counselors"
          render={() => <CounselorsScreen tenantId={tenantId} />}
          exact
        />
        <Route
          path="/tenant/:id/students"
          render={() => <StudentsScreen tenantId={tenantId} />}
          exact
        />
        <Route
          path="/tenant/:id/guardians"
          render={() => <GuardiansScreen tenantId={tenantId} />}
          exact
        />
        <Route
          path="/tenant/:id/integration"
          render={() =><IntegrationScreen tenantId={tenantId} />}
          exact
        />
        <Route
          path="/tenant/:id/configuartion"
          render={() => <ConfigurationScreen tenantId={tenantId} />}
          exact
        />
        <Redirect
          from="/tenant/:id"
          to="/tenant/:id/overview"
          render={() => <ClientOverviewPage tenantId={tenantId} />}
          exact
        />
      </Switch>
    </div>
  );
};

export default withRouter(ClientDetailsScreen);
