import React, { Component } from "react";
import ThirdPartySelect, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import countryPhoneCodes from "../constants/country-phone-codes";
import _ from "lodash";
import i18n from "../i18n";
const flags = _.sortBy(
  _.map(countryPhoneCodes, (countryPhoneCode, countryCode) => {
    return {
      value: countryPhoneCode,
      label: countryPhoneCode,
      flag: require(`../assets/flags-png/${countryCode}.png`),
    };
  }),
  option => option.label,
);

const singleOption = (props: OptionProps<any>) => (
  <components.Option {...props}>
    <div className="flex flex-row items-center">
      <img
        className={"w-6 h-6 object-contain"}
        alt="flag"
        src={props.data.flag}
      />
      <span className="ml-2 text-sm text-gray-750 leading-none">
        {props.data.label}
      </span>
    </div>
  </components.Option>
);

const singleValue = (props: any) => (
  <components.SingleValue {...props}>
    <div className="ml-1 flex flex-row items-center">
      <img
        className={"w-6 h-6 object-contain"}
        alt="flag"
        src={props.data.flag}
      />
      <span className="ml-2 text-sm text-gray-750 leading-none">
        {props.data.label}
      </span>
    </div>
  </components.SingleValue>
);

class CountryCodeSelect extends Component {
  constructor(props) {
    super(props);

    this.customStyles = {
      control: (provided, state) => {
        return {
          ...provided,
          borderWidth: props.borderNone ? 0 : 1,
          borderColor: state.isFocused ? "#017BE2" : "#D8D8D8",
          boxShadow: "none",
          minHeight: 40,
          flex: 1,
        };
      },
      menu: (provided, state) => ({
        ...provided,
        fontFamily: props.fontFamily,
        fontSize: props.fontSize || 14,
        color: props.textColor || "#1E1E1E",
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        fontFamily: props.fontFamily,
        fontSize: props.fontSize || 14,
        paddingLeft: props.paddingLeft || undefined,
        color: props.textColor || "#1E1E1E",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        fontFamily: props.fontFamily,
        color: props.textColor || "#1E1E1E",
      }),
      multiValue: (provided, state) => ({
        ...provided,
        fontFamily: props.fontFamily,
        backgroundColor: "#EAF5FF",
        color: "#FFFFFF",
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        color: "#0B3664",
        fontFamily: props.fontFamily,
        fontSize: 14,
      }),
      multiValueRemove: (provided, state) => ({
        ...provided,
        color: "#0B3664",
        ":hover": {
          backgroundColor: "#EAF5FF",
        },
      }),
      placeholder: (provided, state) => ({
        ...provided,
        color: "#979797",
        fontFamily: props.fontFamily,
      }),
    };
    this.customTheme = {
      borderRadius: 4,
    };
  }

  render() {
    const {
      error,
      borderNone,
      value: valueFromProp,
      dropdownIndicator,
      height,
    } = this.props;
    let value = valueFromProp;

    if (value && !value.flag)
      value = _.find(flags, flag => flag.value === value.value);

    this.customStyles.control = (provided, state) => {
      return {
        ...provided,
        borderWidth: borderNone ? 0 : 1,
        borderColor: error
          ? "#D0021B"
          : state.isFocused
          ? "#017BE2"
          : "#979797",
        // backgroundColor: error ? '#FFE1E4' : '#FFFFFF',
        boxShadow: "none",
        minHeight: height || 42,
        flex: 1,
      };
    };

    return (
      <div className="flex flex-col">
        <ThirdPartySelect
          isSearchable={true}
          placeholder={i18n.t("common.placeholders.code")}
          options={flags}
          styles={this.customStyles}
          theme={this.customTheme}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => (
              <div className="px-4">{dropdownIndicator}</div>
            ),
            SingleValue: singleValue,
            Option: singleOption,
          }}
          {...this.props}
          value={value}
        />
        {error ? (
          <span className="input-field-error-text">{error}</span>
        ) : null}
      </div>
    );
  }
}

CountryCodeSelect.defaultProps = {
  dropdownIndicator: (
    <FontAwesomeIcon
      icon={["fas", "sort-down"]}
      className="text-sm text-gray-500 leading-none mb-2px"
    />
  ),
};

export default CountryCodeSelect;
