import React, { Component } from "react";
import { Checkbox, Filter, Select } from "../../components";
import { Format, Notification } from "../../helpers";
import AddUserScreen from "../add-user";
import EditUserScreen from "../edit-user";
import { Column, Table } from "react-virtualized";
import "react-virtualized/styles.css";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import InfiniteLoader from "react-virtualized/dist/commonjs/InfiniteLoader";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { TeamTypes } from "../../redux/actions";
import EventBus from "eventing-bus";

const sortableColumns = {
  name: "first_name",
  role: "role_name",
  is_active: "is_active",
};

class TeamScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addScreenVisible: false,
      editScreenVisible: false,
      viewScreenVisible: false,
      searchText: "",
      sortColumn: null,
      sortDirection: "asc",
      isLoading: true,
      filters: {},
      prevParams: {},
      states: [],
      cities: [],
      selectedMember: null,
      selectedMemberIndex: null,
      selectedMembers: {},
      downloadingTeam: false,
      selectedBulkAction: null,
      selectAll: false,
    };
  }

  componentDidMount() {
    this._loadUsers();
    this._subscribeEvents();
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _subscribeEvents = () => {
    this.downloadRequestSubscription = EventBus.on(
      TeamTypes.DOWNLOAD_TEAM_REQUEST,
      () => this.setState({ downloadingTeam: true }),
    );
    this.downloadSuccessSubscription = EventBus.on(
      TeamTypes.DOWNLOAD_TEAM_SUCCESS,
      () =>
        this.setState({ downloadingTeam: false }, this._downloadTeam),
    );
    this.downloadFailureSubscription = EventBus.on(
      TeamTypes.DOWNLOAD_TEAM_FAILURE,
      () => this.setState({ downloadingTeam: false }),
    );
    this.bulkActionSuccessSubscription = EventBus.on(
      TeamTypes.BULK_ACTION_SUCCESS,
      this._bulkActionSuccess,
    );
    this.createSuccessSubscription = EventBus.on(
      TeamTypes.UPDATE_TEAM_MEMBER_SUCCESS,
      this._teamMemberUpdatedSuccessfully,
    );
    this.createSuccessSubscription = EventBus.on(
      TeamTypes.CREATE_TEAM_MEMBER_SUCCESS,
      this._teamMemberCreatedSuccessfully,
    );
  };

  _unsubscribeEvents = () => {
    this.downloadRequestSubscription();
    this.downloadSuccessSubscription();
    this.downloadFailureSubscription();
    this.bulkActionSuccessSubscription();
  };

  _bulkActionSuccess = () => {
    const { selectedBulkAction } = this.state;
    const { t } = this.props;

    this.props.clearTeam();
    setTimeout(() => {
      this._loadUsers(true);
    }, 200);
    this.setState({
      selectedMembers: {},
      selectAll: false,
      selectedBulkAction: null,
    });
    Notification.success(
      t(`alerts.success.bulk_${selectedBulkAction.value}`),
    );
  };

  _teamMemberCreatedSuccessfully = () => {
    this.props.clearTeam();
    setTimeout(() => {
      this._loadUsers(true);
    }, 200);
  };

  _teamMemberUpdatedSuccessfully = () => {
    this.props.clearTeam();
    setTimeout(() => {
      this._loadUsers(true);
    }, 200);
  };

  _loadUsers = loadMore => {
    const {
      filters,
      searchText,
      prevParams,
      sortColumn,
      sortDirection,
    } = this.state;
    let accountStatusFilter =
      filters.is_active && filters.is_active.length > 0
        ? { is_active: filters.is_active[0].id }
        : {};

    const params = {
      search_text: searchText,
      filters: {
        ...accountStatusFilter,
      },
      sort_params: [
        { sort_by: "admins.first_name", sort_direction: "asc" },
      ],
    };

    if (sortColumn && sortableColumns[sortColumn]) {
      params["sort_params"] = [
        {
          sort_by: "admins." + sortableColumns[sortColumn],
          sort_direction: sortDirection,
        },
      ];
    }

    if (loadMore === true || !_.isEqual(params, prevParams)) {
      if (!loadMore)
        this.setState({ prevParams: params, isLoading: true });

      return new Promise((resolve, reject) => {
        this.props.getTeam(params, resolve);
      });
    } else {
      return new Promise(function (resolve, reject) {
        resolve();
      });
    }
  };

  _loadMore = params => {
    return this._loadUsers(true);
  };

  _openAddUserScreen = () => {
    this.setState({
      addScreenVisible: true,
      editScreenVisible: false,
      viewScreenVisible: false,
    });
  };

  _openViewUserScreen = (user, index) => {
    this.setState({
      addScreenVisible: false,
      editScreenVisible: false,
      viewScreenVisible: true,
      selectedMember: user,
      selectedMemberIndex: index,
    });
  };

  _closeViewUserScreen = () => {
    this.setState({
      viewScreenVisible: false,
      selectedMemberIndex: -1,
    });
  };

  _openEditUserScreen = (user, index) => {
    this.setState(
      {
        addScreenVisible: false,
        viewScreenVisible: false,
        editScreenVisible: true,
        selectedMember: user,
        selectedMemberIndex: index,
      },
      () => {},
    );
  };

  _closeEditUserScreen = () => {
    this.setState({
      editScreenVisible: false,
      selectedMemberIndex: -1,
    });
  };

  _downloadTeamRequest = () => {
    const { filters, searchText, sortColumn, sortDirection } =
      this.state;
    const { downloadTeam } = this.props;
    let accountStatusFilter =
      filters.is_active && filters.is_active.length > 0
        ? { is_active: filters.is_active[0].id }
        : {};

    const params = {
      request_type: "csv",
      search_text: searchText,
      filters: {
        ...accountStatusFilter,
      },
      sort_params: [
        { sort_by: "admins.first_name", sort_direction: "asc" },
      ],
    };

    if (sortColumn && sortableColumns[sortColumn]) {
      params["sort_params"] = [
        {
          sort_by: "admins." + sortableColumns[sortColumn],
          sort_direction: sortDirection,
        },
      ];
    }

    downloadTeam(params);
  };

  _downloadTeam = () => {
    const { downloadUrl } = this.props;

    if (downloadUrl) window.open(downloadUrl);
  };

  _rowGetter = ({ index }) => this.props.team[index] || {};

  _searchLoadUsers = _.debounce(this._loadUsers, 1000);

  _search = searchText => {
    this.setState(
      {
        searchText,
      },
      this._searchLoadUsers,
    );
  };

  _headerRenderer = (params, title) => {
    const { sortColumn, sortDirection } = this.state;
    const { dataKey } = params;
    const isSortable = sortableColumns[dataKey] ? true : false;

    return (
      <div
        onClick={
          isSortable
            ? () => {
                this.setState(
                  {
                    sortColumn: dataKey,
                    sortDirection:
                      sortColumn === dataKey
                        ? sortDirection === "desc"
                          ? "asc"
                          : "desc"
                        : "asc",
                  },
                  this._loadUsers,
                );
              }
            : () => {}
        }
        className="flex flex-row normal-case items-center cursor-pointer"
      >
        {isSortable ? (
          <div className="flex flex-col">
            <FontAwesomeIcon
              icon={["fas", "sort-up"]}
              className={`text-sm ${
                sortColumn === dataKey && sortDirection === "desc"
                  ? "text-gray-900"
                  : "text-gray-500"
              } mt-px leading-none mr-1`}
              style={{ marginBottom: -15 }}
            />
            <FontAwesomeIcon
              icon={["fas", "sort-down"]}
              className={`text-sm ${
                sortColumn === dataKey && sortDirection === "asc"
                  ? "text-blue-800"
                  : "text-gray-500"
              } mt-px leading-none mr-1`}
            />
          </div>
        ) : null}
        <span
          className={`flex flex-1 text-sm ${
            sortColumn === dataKey
              ? "text-gray-900 font-bold"
              : "text-gray-900 font-bold"
          } leading-none`}
        >
          {title}
        </span>
      </div>
    );
  };

  _rowClassName = ({ index }) => {
    if (index < 0) {
      return "";
    } else {
      return (
        "app-table-row" +
        (index === this.state.selectedMemberIndex ? " selected" : "")
      );
    }
  };

  _onRowSelect = (memberId, checked) => {
    const { selectedMembers } = this.state;
    let updatedSelectedMembers = { ...selectedMembers };

    if (checked) updatedSelectedMembers[memberId] = true;
    else delete updatedSelectedMembers[memberId];

    this.setState({ selectedMembers: updatedSelectedMembers });
  };

  render() {
    const {
      addScreenVisible,
      editScreenVisible,
      selectedMember,
      selectedMembers,
      downloadingTeam,
      selectAll,
      searchText,
      filters,
    } = this.state;
    const {
      matchedMembers,
      totalMembers,
      t,
      team,
      isNextPageLoading,
    } = this.props;
    const accountStatusOptions = [
      { id: true, text: t("common.labels.active") },
      { id: false, text: t("common.labels.inactive") },
    ];

    const hasNextPage = matchedMembers > team.length;
    const isRowLoaded = ({ index }) =>
      !hasNextPage || index < team.length;
    const loadMoreRows = isNextPageLoading
      ? () => {}
      : this._loadMore;
    const filterOptions = [
      {
        id: "user",
        title: t("common.labels.user"),
        icon: ["fas", "location-arrow"],
        filterTypes: [
          {
            id: "is_active",
            text: t("common.labels.account_status"),
            isMulti: false,
            options: accountStatusOptions,
          },
        ],
      },
    ];

    return (
      <div className="flex flex-1 flex-col overflow-hidden">
        <span className="text-xl font-bold text-black px-6 pt-4">
          Team
        </span>
        <div
          style={{ minWidth: "900px" }}
          className="flex-1 flex flex-col"
        >
          <div className="pl-6 pt-4">
            <Filter
              searchPlaceholder={t("common.placeholders.search")}
              selectedFilters={filters}
              searchText={searchText}
              filterOptions={filterOptions}
              onSelection={filters => {
                this.setState({ filters }, this._loadUsers);
              }}
              onChangeSearchText={this._search}
            />
          </div>

          <div className="h-px bg-gray-300 mb-3" />

          {_.keys(selectedMembers).length > 0 || selectAll ? (
            <div className="flex flex-row mb-3 max-w-2xl items-center border-b-px border-gray-300 pl-6">
              <span className="flex-none text-sm text-gray-900 font-bold mr-2">{`${matchedMembers} ${t(
                "team.labels.team_match_of",
              )} ${totalMembers}`}</span>
              <div className="w-full">
                <Select
                  placeholder={t(
                    "team.placeholders.apply_bulk_action",
                  )}
                  options={[
                    {
                      label: t("team.bulk_actions.activate"),
                      value: "activate",
                    },
                    {
                      label: t("team.bulk_actions.deactivate"),
                      value: "deactivate",
                    },
                    {
                      label: t("team.bulk_actions.reset_password"),
                      value: "reset_password",
                    },
                  ]}
                  onChange={selectedOption => {
                    this.setState({
                      selectedBulkAction: selectedOption,
                    });
                  }}
                />
              </div>
              <button
                onClick={() => {
                  if (this.state.selectedBulkAction) {
                    this.props.bulkAction({
                      ids: selectAll ? [] : _.keys(selectedMembers),
                      is_all: selectAll,
                      opt_type: this.state.selectedBulkAction.value,
                    });
                  }
                }}
                className="btn-primary ml-3"
              >
                {t("common.buttons.apply")}
              </button>
            </div>
          ) : (
            <div className="flex flex-row mb-3 max-w-2xl items-center pl-6">
              <span className="flex-none text-sm text-gray-900 font-bold mr-2">{`${matchedMembers} ${t(
                "team.labels.team_match_of",
              )} ${totalMembers}`}</span>
              <button
                onClick={this._downloadTeamRequest}
                className="btn-secondary"
              >
                {downloadingTeam ? (
                  <Loader
                    type="Oval"
                    color="grey"
                    height={18}
                    width={18}
                  />
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={["fas", "download"]}
                      className="mr-2"
                    />
                    {t("common.buttons.download_teams")}
                  </>
                )}
              </button>
              {this.props.isAdmin ? (
                <div
                  onClick={this._openAddUserScreen}
                  className="flex-none flex flex-row items-center cursor-pointer bg-blue-700 rounded h-36px px-2 ml-3"
                >
                  <FontAwesomeIcon
                    icon={["fas", "plus"]}
                    className="text-sm text-white mr-2"
                  />
                  <span className="text-sm font-bold text-white leading-none ">
                    {t("common.buttons.add_member")}
                  </span>
                </div>
              ) : null}
            </div>
          )}

          <div className="flex-1 h-full table-container">
            <InfiniteLoader
              isRowLoaded={isRowLoaded}
              loadMoreRows={loadMoreRows}
              rowCount={matchedMembers}
            >
              {({ onRowsRendered, registerChild }) => {
                return (
                  <AutoSizer>
                    {({ width, height }) => (
                      <Table
                        ref={registerChild}
                        onRowsRendered={onRowsRendered}
                        noRowsRenderer={() => {
                          return isNextPageLoading ? (
                            <div className="flex flex-col justify-center items-center  h-full w-full">
                              <Loader
                                type="Oval"
                                color="grey"
                                height={50}
                                width={50}
                              />
                            </div>
                          ) : null;
                        }}
                        headerHeight={46}
                        height={height}
                        rowClassName={this._rowClassName}
                        rowHeight={62}
                        rowGetter={this._rowGetter}
                        rowCount={team.length}
                        width={width}
                      >
                        <Column
                          dataKey="id"
                          disableSort
                          headerRenderer={params => {
                            return (
                              <div className="p-2">
                                <Checkbox
                                  checked={selectAll}
                                  onChange={selectAll =>
                                    this.setState({
                                      selectAll:
                                        !this.state.selectAll,
                                      selectedMembers: [],
                                    })
                                  }
                                  colorClass="text-gray-900"
                                />
                              </div>
                            );
                          }}
                          width={30}
                          cellRenderer={({ cellData }) => (
                            <div className="p-2">
                              <Checkbox
                                checked={
                                  selectedMembers[cellData] ||
                                  selectAll
                                }
                                onChange={checked =>
                                  this._onRowSelect(cellData, checked)
                                }
                                id="select_checkbox"
                                colorClass={`${
                                  selectedMembers[cellData] ||
                                  selectAll
                                    ? "text-blue-700"
                                    : "text-gray-750"
                                }`}
                              />
                            </div>
                          )}
                        />
                        <Column
                          dataKey="name"
                          headerRenderer={params =>
                            this._headerRenderer(
                              params,
                              t("team.labels.name"),
                            )
                          }
                          width={width / 6 + 100}
                          cellRenderer={params => {
                            const { rowData } = params;
                            return (
                              <div className="flex flex-row items-center">
                                <div className="flex flex-col">
                                  <span className="font-bold overflow-hidden text-gray-750 truncate text-xs">
                                    {(rowData["first_name"]
                                      ? rowData["first_name"]
                                      : "") +
                                      " " +
                                      (rowData["last_name"]
                                        ? rowData["last_name"]
                                        : "")}
                                  </span>
                                </div>
                              </div>
                            );
                          }}
                        />
                        <Column
                          dataKey="is_active"
                          headerRenderer={params =>
                            this._headerRenderer(
                              params,
                              t("team.labels.status"),
                            )
                          }
                          width={10}
                          flexGrow={1}
                          cellRenderer={({ cellData }) => {
                            return (
                              <div className="table-name-column">
                                <span className="font-normal text-gray-750 text-xs leading-none">
                                  {cellData ? (
                                    <span className="text-green-500">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="text-gray-500">
                                      Inactive
                                    </span>
                                  )}
                                </span>
                              </div>
                            );
                          }}
                        />
                        <Column
                          dataKey="role"
                          headerRenderer={params =>
                            this._headerRenderer(
                              params,
                              t("common.labels.role"),
                            )
                          }
                          width={50}
                          flexGrow={1}
                          cellRenderer={({ cellData }) => {
                            return (
                              <div className="flex flex-row">
                                <div className={cellData.role_class}>
                                  <span>
                                    {Format.titleCase(cellData.title)}
                                  </span>
                                </div>
                              </div>
                            );
                          }}
                        />
                        <Column
                          dataKey="email"
                          disableSort
                          headerRenderer={params =>
                            this._headerRenderer(
                              params,
                              t("team.labels.email"),
                            )
                          }
                          width={(width - 100) / 6}
                          flexGrow={1}
                          cellRenderer={({ cellData }) => {
                            return (
                              <div className="table-name-column">
                                <span className="font-normal text-gray-750 text-xs leading-none">
                                  {Format.displayValue(cellData)}
                                </span>
                              </div>
                            );
                          }}
                        />
                        <Column
                          dataKey="last_login"
                          disableSort
                          headerRenderer={params =>
                            this._headerRenderer(
                              params,
                              t("team.labels.last_login"),
                            )
                          }
                          width={(width - 100) / 6 - 50}
                          cellRenderer={({ cellData }) => {
                            return (
                              <div className="table-name-column">
                                <span className="font-normal text-xs leading-none text-gray-750">
                                  {Format.displayValue(
                                    cellData,
                                    null,
                                    Format.date,
                                  )}
                                </span>
                              </div>
                            );
                          }}
                        />
                        {this.props.isAdmin && (
                          <Column
                            dataKey="id"
                            width={(width - 100) / 6 - 50}
                            cellRenderer={({ rowData, rowIndex }) => {
                              return (
                                <div className="table-name-column">
                                  <button
                                    className="text-blue-700"
                                    onClick={() =>
                                      this._openEditUserScreen(
                                        rowData,
                                        rowIndex,
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={["fas", "edit"]}
                                      className="text-xs mr-2"
                                    />
                                    <span className="font-bold">
                                      Edit
                                    </span>
                                  </button>
                                </div>
                              );
                            }}
                          />
                        )}
                      </Table>
                    )}
                  </AutoSizer>
                );
              }}
            </InfiniteLoader>
          </div>
          {isNextPageLoading && team.length > 0 ? (
            <div
              className={
                "absolute bottom-0 self-center pointer-events-none flex-none flex flex-col justify-center items-center p-1"
              }
            >
              <Loader
                type="ThreeDots"
                color="grey"
                height={75}
                width={75}
              />
            </div>
          ) : null}
        </div>
        <div
          className={`fixed top-0 right-0 bottom-0 side-screen-container ${
            addScreenVisible ? "" : "side-screen-collapsed"
          }`}
        >
          {addScreenVisible ? (
            <AddUserScreen
              closeButtonPressed={() =>
                this.setState({ addScreenVisible: false })
              }
            />
          ) : null}
        </div>
        <div
          className={`fixed top-0 right-0 bottom-0 side-screen-container ${
            editScreenVisible ? "" : "side-screen-collapsed"
          }`}
        >
          {editScreenVisible ? (
            <EditUserScreen
              teamMember={selectedMember}
              closeButtonPressed={this._closeEditUserScreen}
              onPressBackButton={() => {
                this._closeEditUserScreen();
              }}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withTranslation()(TeamScreen);
