import Alert from 'react-s-alert';

const configuration = {
  position: 'top',
  effect: 'stackslide',
  timeout: 3000
};

export class Notification {
  static info(msg, config) {
    Alert.info(msg, { ...configuration, ...config });
  }

  static success(msg, config) {
    Alert.success(msg, { ...configuration, ...config });
  }

  static warning(msg, config) {
    Alert.warning(msg, { ...configuration, ...config });
  }

  static error(msg, config) {
    Alert.error(msg, { ...configuration, ...config });
  }
}
