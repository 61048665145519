import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-virtualized/styles.css';
import ReactTooltip from "react-tooltip";

export const TableHeader = (props) => {
  const { title, isSortable, isSorted, sortDirection, onClick } = props;

  return (
    <button
      disabled={!isSortable}
      onClick={onClick}
      className={`flex flex-row normal-case items-center w-full focus:outline-none ${isSortable ? "cursor-pointer" : "cursor-default"}`}
    >
      {
        isSortable ? (
          <div className="flex flex-col">
            <FontAwesomeIcon
              icon={['fas', 'sort-up']}
              className={`text-sm ${isSorted && sortDirection === 'asc' ? 'text-dark-blue-800' : 'text-gray-500'} mt-px leading-none mr-1`}
              style={{ marginBottom: -15 }}
            />
            <FontAwesomeIcon
              icon={['fas', 'sort-down']}
              className={`text-sm ${isSorted && sortDirection === 'desc' ? 'text-dark-blue-800' : 'text-gray-500'} mt-px leading-none mr-1`}
            />
          </div>
        ) : null
      }
      <span
        className={`text-sm text-gray-900 font-bold leading-none truncate`}
        data-tip
        data-for={`edit_information${title}`}
      >
        {title}
      </span>
      <ReactTooltip
        id={`edit_information${title}`}
        aria-haspopup="true"
        place="bottom"
        effect={"solid"}
        arrowColor={"transparent"}
      >
        <p className={"text-xs"}>{title}</p>
      </ReactTooltip>
    </button>
  )
};
