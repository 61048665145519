const INSTITUTE_TYPE = {
  APPLICATON_CLIENT: "application_client",
  UNIVERSITY: "university",
  SCHOOL: "school",
};

const PARTNER = {
  APPLICATON_CLIENT: "Application Client",
  UNIVERSITY: "University",
  SCHOOL: "School",
};

const PARTNER_STATE = {
  NEW: "new",
  EXISTING: "existing",
};

const PARTNER_LINK = {
  APPLICATON_CLIENT: "other-partners",
  UNIVERSITY: "universities",
  SCHOOL: "schools",
};

export { INSTITUTE_TYPE, PARTNER_STATE, PARTNER, PARTNER_LINK };
