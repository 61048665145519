import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DirectApplyCreators } from '../../../redux/actions';
import Loader from 'react-loader-spinner';
import { Format } from "../../../helpers";

const Activity = ({activity, isLast}) => (
  <div className={"flex flex-row"} style={{minHeight: 85}}>
    <div className={"flex-none flex flex-col items-center"}>
      <div className={"flex-none flex justify-center items-center w-8 h-8 rounded-full bg-blue-700"}>
        <span className={"text-xs text-white font-bold"}>{activity.user_short_name}</span>
      </div>
      {
        isLast ? null : <div className={"flex-1 flex w-2px bg-gray-300 my-3"} />
      }
    </div>
    <div className={"flex-1 flex flex-col ml-2"}>
      <p className={"text-sm text-black mb-1"} dangerouslySetInnerHTML={{ __html: activity.activity }} />
      <p className={"text-xs text-gray-600"}>{Format.date(activity.created_at, "MMM DD, YYYY @ HH:mm")}</p>
    </div>
  </div>
);

class ActivityHistoryModal extends Component {
  componentDidUpdate(prevProps) {
    const { application } = this.props;

    if (!prevProps.visible && this.props.visible) {
      this.props.getActivities(application.id);
    }
  }

  render() {
    const {
      t,
      visible,
      onClose,
      activities,
      isLoadingActivities,
    } = this.props;

    return (
      <ReactModal
        isOpen={visible}
        onRequestClose={() => {}}
        ariaHideApp={false}
        className="modal"
        overlayClassName="modalOverlay"
      >
        <div className="absolute left-0 right-0 top-0 bottom-0 bg-overlay-500 flex flex-col justify-center items-center z-20">
          <div className="my-20 rounded bg-white pt-6 pb-4 px-8 flex flex-col relative w-500px overflow-hidden">
            <div className={"flex flex-row justify-between"}>
              <span className="font-bold text-base text-black mb-6">
                {t("direct_apply.application.activity_information")}
              </span>

              <FontAwesomeIcon
                onClick={onClose}
                icon={['fas', 'times']}
                className={"text-lg leading-none text-gray-750 cursor-pointer"}
              />
            </div>
            {
              isLoadingActivities ? (
                <div className={"h-20 flex justify-center items-center"}>
                  <Loader
                    type="Oval"
                    color={"#007BE2"}
                    height={40}
                    width={40}
                  />
                </div>
              ) : (
                <div className={"flex-1 flex flex-col overflow-y-scroll"}>
                  {
                    activities.map((activity, index, list) => (
                      <Activity key={index} activity={activity} isLast={index === list.length - 1} />
                    ))
                  }
                  {
                    activities.length === 0 ? (
                      <span className="font-bold text-base text-black mb-6">
                        {t("direct_apply.application.no_activity_found")}
                      </span>
                    ) : null
                  }
                </div>
              )
            }
          </div>
        </div>
      </ReactModal>
    )
  }
};

function mapStateToProps(state) {
  const { directApply: { isLoadingActivities, activities } } = state;
  return {
    activities,
    isLoadingActivities,
  };
}

const mapDispatchToProps = {
  getActivities: DirectApplyCreators.getDirectApplicationActivitiesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ActivityHistoryModal));
