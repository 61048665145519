import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EventBus from 'eventing-bus';
import Loader from 'react-loader-spinner';
import { DirectApplyTypes } from '../../../redux/actions';
import NoResultsFound from "../transactions/no-results-found";
import { Format } from '../../../helpers';

const DisplayRow = ({ children, isLast }) => {
  return (
    <div className={`flex flex-1 flex-row ${isLast ? "" : "mb-8"}`}>
      {children}
    </div>
  );
};

const DisplayField = ({ label, value, isEmail }) => {
  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <span className="text-sm text-gray-750 font-normal mb-1 leading-none">{label}</span>
      <span className={`text-sm ${isEmail ? "text-blue-700 cursor-pointer" : "text-gray-750"} font-bold mt-1`}>{value}</span>
    </div>
  );
};

function badgeClass(status){
  const classes = {
    "Paid": "badge-success",
    "Failed": "badge-error",
    "Refunded": "badge-warning",
    "Partially Refunded": "badge-warning"
  }
  return classes[status]
}

const FieldSeparator = () => (
  <div className={"w-4"}/>
);

const ActivityCard = ({activity}) => {
  return (
    <div className={"flex flex-col rounded-6px shadow-card px-4 py-3"}>
      <div className={"flex flex-row items-center"}>
        <div className={"text-xs font-bold text-gray-750 mr-3"}>{`${Format.date(activity.created_at)}, ${Format.time(activity.created_at)}`}</div>
        <div className={badgeClass(activity.status)}>{activity.status}</div>
      </div>
      <div className={"text-sm font-bold text-black mt-1"}>{activity.applicant_name}</div>
      <div className={"text-sm font-normal text-gray-750 mt-1"}>{activity.activity_type}</div>
      <div className={"text-sm font-bold text-blue-700 mt-1"}>Transaction ID: {activity.transaction_id}</div>
      <div className={"text-sm font-normal text-gray-750 mt-1"}>{"Amount: "}<span className={"font-bold ml-px"}>{activity.currency} {activity.amount}</span></div>
    </div>
  );
};

class DirectApplyTransactionDetail extends Component {
  state = {
    loading: true,
    loadingLocation: false,
    mapScreenVisible: false,
    errorMessages: {},
    locationText: ""
  }

  componentDidMount() {
    const { transactionId } = this.props;
    this._subscribeEvents();

    this.props.getTransaction(transactionId);
  }

  componentDidUpdate(prevProps){
    if (this.props.transactionId && (prevProps.transactionId !== this.props.transactionId)){
      this.props.getTransaction(this.props.transactionId);
    }

    if (prevProps.isFetchingTransaction !== this.props.isFetchingTransaction){
      this.setState({ loading: this.props.isFetchingTransaction });
    }

    if (prevProps.isFetchingTransaction && !this.props.isFetchingTransaction && !this.props.transcationFetchSuccessful) {
      this.props.history.push({
        pathname: `/transactions`,
      });
    }
  }


  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _subscribeEvents = () => {
    this.fetchRequestSubscription = EventBus.on(DirectApplyTypes.GET_Transaction_REQUEST, () => this.setState({ loading: true }));
    this.fetchSuccessSubscription = EventBus.on(DirectApplyTypes.GET_Transaction_SUCCESS, () => this.setState({ loading: false }));
    this.fetchFailureSubscription = EventBus.on(DirectApplyTypes.GET_Transaction_FAILURE, () => this.setState({ loading: false }));
  }

  _unsubscribeEvents = () => {
    this.fetchRequestSubscription();
    this.fetchSuccessSubscription();
    this.fetchFailureSubscription();
  }

  _closeScreen = () => {
    this.props.closeButtonPressed && this.props.closeButtonPressed();
  }

  _refundButtonPressed = () => {
    this.props.refundButtonPressed && this.props.refundButtonPressed();
  }

  render() {
    const {
      t,
      transaction,
    } = this.props;
    const { loading } = this.state;

    return (
      <div className="h-screen flex overflow-hidden">
        <div className="flex-1 flex flex-col side-screen bg-white border border-gray-300 overflow-hidden">
          <header className="h-70px flex flex-none px-5 border-b border-gray-300 items-center">
            <div className="flex-1 flex flex-col">
              <span className="text-lg font-bold text-gray-750">{t('direct_apply_transaction_details.header.title')}</span>
            </div>
            <div onClick={this._closeScreen} className="flex flex-col justify-center cursor-pointer">
              <FontAwesomeIcon icon={['fas', "times"]} className="text-xl text-gray-750"/>
            </div>
          </header>

          {
            loading &&
            <div className="flex flex-col justify-center items-center h-full w-full">
              <Loader
                type="Oval"
                color="grey"
                height={50}
                width={50}
              />
            </div>
          }
          {
            !loading && transaction ? (
              <div className="flex-1 flex flex-col bg-white overflow-y-scroll px-5">
                <div className="flex flex-none flex-col my-5">
                <>
                  <DisplayRow>
                    <DisplayField
                      label={t('direct_apply_transaction_details.labels.name')}
                      value={`${transaction.first_name} ${transaction.last_name}`}
                    />
                    <FieldSeparator />
                    <DisplayField
                      label={t('direct_apply_transaction_details.labels.school')}
                      value={transaction.school_name}
                    />
                  </DisplayRow>

                  <DisplayRow>
                    <DisplayField
                      label={t('direct_apply_transaction_details.labels.country')}
                      value={transaction.country}
                    />
                    <FieldSeparator />
                    <DisplayField
                      label={t('direct_apply_transaction_details.labels.phone')}
                      value={transaction.phone}
                    />
                  </DisplayRow>

                  <DisplayRow>
                    <DisplayField
                      label={t('direct_apply_transaction_details.labels.program')}
                      value={transaction.program_name}
                    />
                    <FieldSeparator />
                    <DisplayField
                      label={t('direct_apply_transaction_details.labels.university')}
                      value={transaction.university_name}
                    />
                  </DisplayRow>
                  <DisplayRow isLast>
                    <DisplayField
                      isEmail
                      label={t('direct_apply_transaction_details.labels.email')}
                      value={transaction.email}
                    />
                  </DisplayRow>
                </>
              </div>

                <div className={"h-px min-h-px bg-gray-300"}/>

              < div className="flex flex-none flex-col py-5">
                <div className="flex items-center">
                  <span className="text-xl text-gray-750 font-bold">{t('common.labels.activity')}</span>
                </div>

                {
                  transaction.activities.length === 0 ? (
                    <div className="mt-5">
                      <NoResultsFound
                        title={"No Activities Found"}
                        detail={"There are no activities available for this application"}
                      />
                    </div>
                  )
                :
                  transaction.activities.map(activity => {
                    return <div className={"mt-5"}>
                      <ActivityCard activity={activity}/>
                    </div>
                  })
                }
              </div>
                <div className={"h-px bg-gray-300"}/>
                {(transaction.status === "Paid" || transaction.status === "Partially Refunded") ? (
                  <div className={"h-70px flex-none flex flex-row px-5 border-b border-gray-300 items-center justify-end"}>
                    <button onClick={this._refundButtonPressed} className={"btn-primary"}>
                      {"Refund"}
                    </button>
                  </div>
                ) : null}
              </div>
            ) : null
          }
        </div>
      </div>
    );
  }
}

export default withTranslation()(DirectApplyTransactionDetail);
