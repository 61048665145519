import React, { Component } from "react";
import {
  Button,
  Wrapper,
  Menu,
  MenuItem,
  openMenu,
} from "react-aria-menubutton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import memoize from "memoize-one";

class ExpandedFlatFilter extends Component {
  state = {
    searchText: "",
  };

  componentDidMount() {
    const { openOnRender, id, selectedFilters } = this.props;

    if (openOnRender && id && selectedFilters.length === 0) openMenu(id);
  }

  _triggerUpdate = _.debounce((selectedFilters) => {
    this.props.onSelection(selectedFilters);
  }, 200);

  _handleSelection = (value, event) => {
    const { selectedFilters } = this.props;

    if (!this._found(value, selectedFilters)) {
      if (this.props.isMulti) {
        this._triggerUpdate([...selectedFilters, value], event);
      } else {
        this._triggerUpdate([value], event);
      }
    } else {
      const selectedCopy = [...selectedFilters];
      _.remove(selectedCopy, (selectedItem) => selectedItem.id === value.id);

      this.props.onSelection(selectedCopy);
    }
  };

  _handleDeselection = (value) => {
    const { selectedFilters } = this.props;
    const selectedCopy = [...selectedFilters];
    _.remove(selectedCopy, (selectedItem) => selectedItem === value);

    if (selectedFilters.includes(value)) {
      this.props.onSelection(selectedCopy);
    }
  };

  _handleFilterToggle = ({ isOpen }) => {
    const { selectedFilters } = this.props;

    if (!isOpen && selectedFilters.length === 0) {
      this._triggerUpdate(selectedFilters);
    }
  };

  _filter = memoize(_.filter);

  _search = _.debounce((searchText) => {
    this.setState({ searchText: searchText });
  }, 500);

  _found = (filter, selected) => {
    var index = selected.findIndex((o) => o.id === filter.id);
    return index >= 0 ? true : false;
  };

  render() {
    const {
      filterOptions: filterSource,
      button,
      id,
      isSearchable,
      emptyText,
      selectedFilters,
    } = this.props;
    const { searchText } = this.state;
    const filters = this._filter(filterSource, (filter, index) =>
      filter?.text?.toLowerCase().includes(searchText)
    );

    return (
      <div className="flex-none flex flex-col">
        <Wrapper
          id={id}
          className="relative flex-1 flex"
          onSelection={this._handleSelection}
          onMenuToggle={this._handleFilterToggle}
        >
          <Button className="flex flex-row items-center focus:outline-none">
            {button}
          </Button>
          <Menu className="filter-menu py-2 w-64">
            {isSearchable ? (
              <>
                <div className="flex">
                  <input
                    placeholder="Search"
                    type="text"
                    className="search-field flex-1"
                    onChange={(event) =>
                      this._search(event.target.value.toLowerCase())
                    }
                  />
                </div>
                <div className="h-px bg-gray-300 my-2" />
              </>
            ) : null}

            <div className="flex flex-col max-h-64 overflow-y-scroll">
              {filters.length > 0 ? (
                filters.map((filter, i) => (
                  <MenuItem
                    key={i}
                    className={
                      "filter-menu-item " +
                      (i !== filters.length - 1 ? "mb-4 " : "") +
                      "flex-none flex px-4 focus:outline-none cursor-pointer " +
                      (this._found(filter, selectedFilters) ? "bg-blue-50" : "")
                    }
                    value={filter}
                    text={filter.text}
                  >
                    <span className="flex text-sm text-gray-750 ml-2 leading-snug">
                      {this.props.isMulti ? (
                        <FontAwesomeIcon
                          icon={[
                            this._found(filter, selectedFilters)
                              ? "fas"
                              : "far",
                            this._found(filter, selectedFilters)
                              ? "check-square"
                              : "square",
                          ]}
                          className={`text-sm self-center mr-2 ${
                            this._found(filter, selectedFilters)
                              ? "text-blue-700"
                              : "text-gray-750"
                          }`}
                        />
                      ) : null}
                      {filter.text}
                    </span>
                  </MenuItem>
                ))
              ) : (
                <div className={"flex items-center h-8 px-4 flex-none"}>
                  <span className="text-sm text-gray-500 ml-2 leading-none">
                    {searchText ? "Filter not found" : emptyText}
                  </span>
                </div>
              )}
            </div>
          </Menu>
        </Wrapper>
      </div>
    );
  }
}

ExpandedFlatFilter.defaultProps = {
  selectedFilters: [],
};

export default ExpandedFlatFilter;
