import { UserForm, userFormValidation } from '../../forms';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { formValueSelector } from 'redux-form';
const selector = formValueSelector('addUserForm')

function mapStateToProps(state) {  
  return {
    avatar: selector(state, 'avatar')
  }
}

export default withTranslation()(reduxForm({
  form: 'addUserForm',  // a unique identifier for this form
  validate: userFormValidation
})(connect(mapStateToProps)(UserForm)));
