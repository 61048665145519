import { put, takeEvery, select } from "redux-saga/effects";
import { DistrictsCreators, DistrictsTypes } from "../actions";
import _ from "lodash";

export function* watchDistrictsRequests() {
  yield takeEvery(
    DistrictsTypes.GET_DISTRICTS_REQUEST,
    requestGetDistricts,
  );
}

const dummyResult = {
  details: {
    districts: [
      {
        id: 1,
        name: "District 9",
        logo_url: null,
        tenants: [
          {
            id: 1,
            name: "Cialfo test dev",
          },
          {
            id: 30,
            name: "Companion free",
          },
          {
            id: 1950,
            name: "AA",
          },
        ],
      },
      {
        id: 3,
        name: "Paya Lebar",
        logo_url:
          "https://cialfo-attachments.s3.amazonaws.com/uploads/d41d8cd98f00b204e9800998ecf8427e/e0bb2f66e5b61be3235bf8969979de19/96d6f2e7e1f705ab5e59c84a6dc009b2/018b6e5cdf44ec3c0d9119fa4cf862b0/locmap_PAYALEBAR_103.7225556X1.2338889X104.0585556X1.4738889.png",
        tenants: [
          {
            id: 90,
            name: "Punjab",
          },
        ],
      },
      {
        id: 2,
        name: "Sentosa",
        logo_url:
          "https://cialfo-attachments.s3.amazonaws.com/uploads/d41d8cd98f00b204e9800998ecf8427e/e0bb2f66e5b61be3235bf8969979de19/96d6f2e7e1f705ab5e59c84a6dc009b2/a968498607f9217aa3519b60f3520c15/Core-Central-Region-Map.png",
        tenants: [],
      },
    ],
    matched: 3,
    total: 3,
  },
};

export function* requestGetDistricts(action) {
  try {
    const { params, resolve } = action;
    const districtsSelector = state => state.districts;
    const { data: currentDistricts, params: currentParams } =
      yield select(districtsSelector);
    const shouldLoadMore =
      currentDistricts.length > 0 &&
      _.isEqual(_.omit(currentParams, ["page_no"]), params);

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;

    const requestParams = { ...params };
    requestParams["page_no"] = page;
    const response = dummyResult;
    resolve && resolve();
    yield put(
      DistrictsCreators.getDistrictsSuccess(
        response.details,
        requestParams,
      ),
    );
  } catch (error) {
    yield put(DistrictsCreators.getDistrictsFailure());
  }
}
