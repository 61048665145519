import React, { Component } from "react";
import { TextInputField } from "../../form-components";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import validator from "validator";

class ForgotPasswordForm extends Component {
  render() {
    const { handleSubmit, onSubmit } = this.props;

    return (
      <form
        className="w-30per bg-white flex flex-col items-center z-10 shadow-xl h-full pl-6 pr-6 "
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="relative mt-16">
          <div
            style={{
              width: "254px",
              height: "62px",
              background: "url(/images/login_logo.png)",
            }}
            className="logo my-3"
          ></div>
        </div>

        <div className="mt-4">
          <div className="text-center text-gray-800 font-normal font-roboto text-2xl">
            Forgot your password?
          </div>
          <div className="text-center text-gray-800 font-normal font-roboto text-base mt-4">
            Do not worry! Submit your email address and we'll send a link to
            reset your password.
          </div>
        </div>

        <div className="w-full mt-4">
          <div className={"flex flex-col"}>
            <div className={"text-gray-750 font-light"}>Your Email</div>
            <Field
              className="mt-2"
              name="email"
              component={TextInputField}
              type="text"
              placeholder="Email"
            />
          </div>
          <div className="flex flex-row mt-5">
            <button className="w-64 px-2 py-2 cursor-pointer flex items-center justify-center rounded h-36px text-white bg-blue-700">
              Reset Password
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  const { email } = values;
  const { t } = props;

  if (!email) errors["email"] = `Email ${t("validation.error.required")}`;
  else if (!validator.isEmail(email))
    errors["email"] = t("validation.error.email_invalid");

  return errors;
};

export default withTranslation()(
  reduxForm({
    form: "forgotPasswordForm",
    validate: validate,
  })(ForgotPasswordForm)
);
