import React, { Component } from 'react';
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Checkbox extends Component {
  _toggleSelection = (event) => {
    event.stopPropagation();
    this.props.onChange(!this.props.checked);
  }

  render() {
    const { id, colorClass, checked } = this.props;

    return (
      <div id={id} className="cursor-pointer" onClick={this._toggleSelection}>
        <FontAwesomeIcon icon={[checked ? 'fas' : 'far', checked ? 'check-square' : 'square']} className={`text-sm ${colorClass}`}/>
      </div>
    );
  }
}

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
}

Checkbox.defaultProps = {
  onChange: () => {},
  checked: false,
  colorClass: "text-blue-700"
}

export default Checkbox;
