import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactAlert from 'react-s-alert';

class Alert extends Component {
  handleConfirm() {
    ReactAlert.close(this.props.id);
  }

  render() {
    const { handleClose, message, condition, styles, customFields: { title, messageBold, btnText } } = this.props;
    let icon = null;
    let btnClass = "";

    switch(condition) {
      case "error":
        icon = ['fas', 'exclamation-circle'];
        btnClass = "error";
        break
      case "warning":
        icon = ['fas', 'exclamation-triangle'];
        btnClass = "warning";
        break
      case "info":
        icon = ['fas', 'info-circle'];
        btnClass = "primary";
        break
      case "search":
        icon = ['fas', 'search-minus'];
        break
      case "success":
      default:
        icon = ['fas', 'check-circle'];
        btnClass = "success";
        break
    }

    return (
      <div className={this.props.classNames + " flex flex-row"} id={this.props.id} style={styles}>
        <div className="flex-none mr-5">
          <FontAwesomeIcon icon={icon} className="alert-icon mt-1"/>
        </div>
        <div className='s-alert-box-inner flex-1 flex flex-col'>
          <span className="text-xl text-black font-bold leading-nnone">{title}</span>
          <div className="flex flex-row items-center">
            <div className="mt-1">
              <span className="text-sm text-black font-normal leading-none">{message}</span>
              <span className="text-sm text-black font-bold leading-none">{messageBold}</span>
            </div>

            {
              btnText ? (
                <button
                  onClick={this.handleConfirm.bind(this)}
                  className={"ml-5 btn-" + btnClass}
                >
                  {btnText}
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="flex-none flex flex-col">
          <FontAwesomeIcon onClick={handleClose} icon={['fas', "times"]} className="text-xl text-gray-750 cursor-pointer"/>
        </div>
      </div>
    );
  }
}

Alert.defaultProps = {
  handleClose: () => {}
}

export default Alert;
