import React from "react";
import { withTranslation } from "react-i18next";
import { Format } from "../../../../helpers";

const ShowLastStatus = ({ time }) => {
  if (!time) return null;

  const formattedDate = Format.date(time);
  const formattedTime = Format.time(time);

  return (
    <div className="absolute bottom-0 left-0 w-full pb-2">
      <span className="block font-body text-xs font-normal leading-6 tracking-normal text-center text-black-900 px-8">
        {`Last updated : ${formattedDate} · ${formattedTime}`}
      </span>
    </div>
  );
};

export default withTranslation()(ShowLastStatus);
