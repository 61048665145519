import React from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reduxForm, Field } from "redux-form";
import { RadioBox, TextInputField } from "src/form-components";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import SwitchField from "src/components/switch-field";

const EditAccountModal = props => {
  const { isOpen, onClose, handleSubmit, t } = props;

  const onSubmit = values => {
    console.log(values);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: {
          width: "500px",
          margin: "50px auto",
          outline: "none",
        },
      }}
      overlayClassName="modal-overlay"
      className="modal-content"
    >
      <div
        className="bg-white rounded-md py-8"
        style={{ height: "calc(100vh - 100px)" }}
      >
        <div
          className={
            "flex items-center flex-row justify-between mb-4 px-8"
          }
        >
          <span className="font-bold text-black text-xl">
            {t("clients.edit_account")}
          </span>

          <FontAwesomeIcon
            onClick={onClose}
            icon={["fas", "times"]}
            className={
              "text-lg leading-none text-gray-750 cursor-pointer"
            }
          />
        </div>
        <div
          className="overflow-scroll  px-8"
          style={{ height: "calc(100vh - 270px)" }}
        >
          <div className="flex flex-col">
            <div className="text-base text-gray-750 pb-1">
              {t("clients.name")}
            </div>
            <Field
              name="name"
              component={TextInputField}
              type="text"
            />
          </div>

          <div className="flex flex-col">
            <div className="text-base text-gray-750 pb-1 pt-4">
              {t("clients.state")}
            </div>
            <Field
              name="state"
              component={TextInputField}
              type="text"
            />
          </div>

          <div className="flex flex-col">
            <div className="text-base text-gray-750 pb-1 pt-4">
              {t("clients.city")}
            </div>
            <Field
              name="city"
              component={TextInputField}
              type="text"
            />
          </div>

          <div className="flex flex-col">
            <div className="text-base text-gray-750 pb-1 pt-4">
              {t("clients.country")}
            </div>
            <Field
              name="country"
              component={TextInputField}
              type="text"
            />
          </div>

          <div className="flex flex-col">
            <div className="font-bold text-black text-base pt-8 pb-4">
              {t("clients.account_status")}
            </div>
            <Field
              component={RadioBox}
              name="account_status"
              items={[
                { value: "live", label: "Live" },
                { value: "freeze_account", label: "Frezze account" },
              ]}
            />
            <div className="flex bg-blue-100 items-center px-3 py-2 rounded mt-4">
              <FontAwesomeIcon
                icon={["far", "circle-info"]}
                className={
                  "text-base leading-none text-blue-800 pr-2"
                }
              />
              <div className="text-sm text-gray-750">
                Freezing the account will prevent their staff and
                customer from using companion.
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="font-bold text-black text-base pt-8 pb-4">
              {t("clients.org_type")}
            </div>
            <Field
              component={RadioBox}
              name="org_type"
              items={[
                { value: "school", label: "School" },
                { value: "consultant", label: "Consultant" },
              ]}
            />
          </div>

          <div className="flex flex-col">
            <div className="font-bold text-black text-base pt-8 pb-4">
              {t("clients.misc")}
            </div>
            <div className="flex justify-between border-b border-gray-300 pb-3">
              <span className="text-sm text-gray-750">
                Is this demo account?
              </span>
              <Field component={SwitchField} name="is_demo" />
            </div>
          </div>

          <div className="flex justify-between border-b border-gray-300 py-3">
            <span className="text-sm text-gray-750">
              Show explore page
            </span>
            <Field component={SwitchField} name="show_explore_page" />
          </div>

          <div className="flex justify-between pt-3">
            <span className="text-sm text-gray-750">
              Explore activated
            </span>
            <Field
              component={SwitchField}
              name="is_explore_activated"
            />
          </div>
        </div>

        <div className="flex justify-end pt-8 px-8 ">
          <button className="btn-primary ml-4" onClick={onClose}>
            {t("clients.cancel")}
          </button>
          <button
            className="btn-primary ml-4"
            type={"submit"}
            onClick={handleSubmit(onSubmit)}
          >
            {t("clients.save_changes")}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {};

export default withTranslation()(
  reduxForm({
    form: "editAccountForm",
    enableReinitialize: true,
  })(connect(mapStateToProps, mapDispatchToProps)(EditAccountModal)),
);
